/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';
import validationBRExportButtonImg from './img/validation-br-export-button.png';
import validationBRExportOptionsButtonImg from './img/validation-br-export-options-button.png';

const { t } = i18next;

const SectionValidationBRExport = () => (
  <div className="subSection">
    <p>
      To export a business rules, the user must click on the &quot;
      {t(tSchema.common.exportAs)}
      &quot;
      button displayed below the results grid, on the bottom right corner.
    </p>
    <p>
      <img src={validationBRExportButtonImg} alt="validationBRExportButton" />
    </p>
    <p>
      CSV option is only available for the time being.
    </p>
    <p>
      <img src={validationBRExportOptionsButtonImg} alt="validationBRExportOptionsButton" />
    </p>
    <p>
      An excel file containing all the business rules
      is downloaded when the user clicks on the selected option.
    </p>
  </div>
);

export default SectionValidationBRExport;
