import actions from './img/actions.JPG';

const SectionExecuteQuery = () => (
  <div className="SectionQueryAcceptance">
    <p>
      To execute a Query,
      the user has to click on the &quot;Actions&quot; button in the row of the query,
      and then select the &quot;Execute&quot; Action.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
  </div>
);

export default SectionExecuteQuery;
