import addNewLine from './img/add_new_line.png';
import newRow from './img/new_row_added.png';

const SectionReferenceDataAdd = () => (
  <div className="SectionReferenceDataAdd">
    <p>
      Users can add new attributes to Reference Data, to do so, in the Reference Data edition form,
      below the attributes grid on the bottom right corner,
      the user shall click on &quot;Add new line&quot;.
      A modal window is shown containing the creation form.
    </p>
    <p>
      <img src={addNewLine} alt="Add new line" />
    </p>
    <p>
      Once the form has been filled in,
      the user shall click on &quot;Add&quot; to save the new element.
      Then the attributes grid is automatically updated to include the new attribute.
    </p>
    <p>
      <img src={newRow} alt="New row added" />
    </p>
  </div>
);

export default SectionReferenceDataAdd;
