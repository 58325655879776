import { post, get } from '../utils';

function apiFetchVessel(searchFilter, params = {}) {
  const filter = searchFilter ? `?${searchFilter}` : '';

  return post()(`vrService/vessel/getVessels${filter}`, params);
}

function apiFetchSearchableAttributes() {
  return get()('vrService/getTemplate');
}

function apiFetchFormDataVesselSearch() {
  return get()('vrService/getFormData');
}

export { apiFetchVessel, apiFetchSearchableAttributes, apiFetchFormDataVesselSearch };
