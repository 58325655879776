import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import grid from './img/grid.png';
import gridISR from './img/gridISR.png';
import action from './img/row_actions.png';

import { DOMAIN } from '@common/utils';

const SectionQueryAcceptance = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <>
      {domain === DOMAIN.FA
        ? (
          <div className="SectionQueryAcceptance">
            <p>
              The results grid displays the following columns:
              <ul>
                <li>Sender</li>
                <li>Data Scope from</li>
                <li>Data Scope to</li>
                <li>Valid from</li>
                <li>Valid to</li>
                <li>Flag State</li>
                <li>Active</li>
              </ul>
            </p>
            <p>
              <img src={grid} alt="grid " />
            </p>
            <p>
              In addition, a column &quot;Actions&quot; is also displayed.
              Clicking in the button available in this column for each row,
              a list of operations is made available for the user,
              so each Query Acceptance can be edited, visualized in detail, or deleted.
            </p>
            <p>
              <img src={action} alt="action" />
            </p>
          </div>
        ) : (
          <div className="SectionQueryAcceptance">
            <p>
              The results grid displays the following columns:
              <ul>
                <li>ISR Party</li>
                <li>Valid from</li>
                <li>Valid to</li>
                <li>Query Context</li>
                <li>IS Programme</li>
                <li>Active</li>
              </ul>
            </p>
            <p>
              <img src={gridISR} alt="grid " />
            </p>
            <p>
              In addition, a column &quot;Actions&quot; is also displayed.
              Clicking in the button available in this column for each row,
              a list of operations is made available for the user,
              so each Query Acceptance can be edited, visualized in detail, or deleted.
            </p>
            <p>
              <img src={action} alt="action" />
            </p>
            <p>
              The Query Acceptance Business Rules access and
              modification are only available the FIS ISR Party Admin.
            </p>
          </div>
        )}
    </>
  );
};

export default SectionQueryAcceptance;
