/* eslint-disable linebreak-style */
import tSchema from '@lang/schema';

const schema = tSchema.isrViewer;

export function getReceivedTableConfig(t) {
  return [
    { text: t(schema.species), code: 'species', render: (row) => `${row.speciesName || ''} (${row.speciesCode})` },
    { text: t(schema.weight), code: 'weight' },
    { text: t(schema.numberFish), code: 'numberOfFish' },
    { text: t(schema.sizeClass), code: 'sizeClass' },
    { text: t(schema.presentation), code: 'presentation' },
    { text: t(schema.preservation), code: 'preservation' },
    {
      text: t(schema.catchArea),
      code: 'catchArea',
      render: (row) => row.catchArea.map((area) => `${area.schemeID}: ${area.value}`).join(', '),
    },
  ];
}

export function getDonorTableConfig(t) {
  return [
    { text: t(schema.flag), code: 'flag' },
    { text: t(schema.name), code: 'name' },
    { text: t(schema.type), code: 'type' },
    { text: t(schema.ircs), code: 'ircs' },
    { text: t(schema.imo), code: 'imo' },
    { text: t(schema.cfr), code: 'cfr' },
  ];
}
