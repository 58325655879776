import PropType from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import VesselSearchGrid from '.';
import './style.scss';

function VesselSearchModal({
  show, setShow, maxSelectable, onAcceptSelected, minRequired, defaultSelected,
  selectedRowsLabelMapper, emptyOnInit,
}) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);
  const [selectedValuesComponent, setSelectedValuesComponent] = useState(null);
  const handleClose = useCallback(() => setShow(false), []);
  const onAcceptSelectedHandler = useCallback(() => { onAcceptSelected(selected); handleClose(); },
    [onAcceptSelected, selected, handleClose]);
  const acceptDisabled = useMemo(
    () => (!selected || selected.length < 1)
    || (minRequired && selected?.length < minRequired),
    [minRequired, selected],
  );
  return (
    <Modal
      backdrop="static"
      className="vessel-search-modal"
      show={show}
      animation={false}
      onHide={handleClose}
      centered
      size="xl"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Vessel Selector</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VesselSearchGrid
          emptyOnInit={emptyOnInit}
          maxSelectable={maxSelectable}
          onSelectRow={setSelected}
          defaultSelected={defaultSelected}
          onValuesComponentChanged={setSelectedValuesComponent}
          selectedRowsLabelMapper={selectedRowsLabelMapper}
          disableRowCheck={false}
        />
      </Modal.Body>
      <Modal.Footer className="vessel-search-footer">
        <div className="footer-values">
          {selectedValuesComponent}
        </div>
        <div className="footer-actions">
          <Button variant="primary" onClick={handleClose}>
            <span className="symbol-cross" />
            {t(tSchema.common.cancel)}
          </Button>
          <Button variant="primary" onClick={onAcceptSelectedHandler} disabled={acceptDisabled}>
            <span className="symbol-tick" />
            Accept
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

VesselSearchModal.propTypes = {
  emptyOnInit: PropType.bool,
  show: PropType.bool.isRequired,
  setShow: PropType.func.isRequired,
  onAcceptSelected: PropType.func.isRequired,
  maxSelectable: PropType.number,
  minRequired: PropType.number,
  defaultSelected: PropType.arrayOf(PropType.shape({
    cfr: PropType.string.isRequired,
    etalon_id: PropType.string.isRequired,
    uvi: PropType.string.isRequired,
  })),
  selectedRowsLabelMapper: PropType.func,
};

VesselSearchModal.defaultProps = {
  emptyOnInit: false,
  minRequired: undefined,
  maxSelectable: undefined,
  defaultSelected: undefined,
  selectedRowsLabelMapper: undefined,
};

export default VesselSearchModal;
