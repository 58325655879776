import PropTypes from 'prop-types';
import {
  any,
  isEmpty, propEq, remove,
} from 'ramda';
import React, {
  useEffect, useState, useRef, useCallback, useMemo,
} from 'react';
import {
  Button,
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import { formatBody } from '@services/vessel_record/actions/vessel/crud';
import { apiFetchVessel } from '@services/vessels_search/api';

import MultiSelectValues from '../../components/MultiSelectValues';
import Filters from '../../componentsRecord/Filters';

import CheckAll from './components/CheckAll';
import CheckComponent from './components/CheckBoxComponent';
import useSearchableAttributes from './hooks/useSearchableAttributes';

import {
  getTableConfig,
} from './config';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { formatHeaderSort } from '@common/utils';

import './style.scss';

const MAX_PERFORMANCE_SELECTABLE = 20000;
const basicRowData = ({
  etalon_id: etalonId, uvi, cfr, ircs, name,
}) => ({
  etalon_id: etalonId, uvi, cfr, ircs, name,
});

const isSameRow = (a, b) => a.etalon_id === b.etalon_id;
/**
 * VesselSearchGrid component needs to be passed the following props:
 * @param {Function} onSelectRow triggered when selectedRows changes
 * @param {Array} defaultSelected Array of default selected rows
 * @param {Number} maxSelectable Number of max selectable rows. If 1, the selected row will change
 * on row check automatically
 * @param {Boolean} disableRowCheck Disable row selection when row is clicked
 *  and enable selection only when click checkbox
 * @param {String} onValuesComponentChanged Used to get the values component in the parent
 * component. If it has a value, the values component will not display in this component.
 */
const VesselSearchGrid = ({
  onSelectRow,
  defaultSelected,
  onValuesComponentChanged,
  maxSelectable,
  disableRowCheck,
  selectedRowsLabelMapper,
  saving,
  emptyOnInit,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(saving);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [vessels, setVessels] = useState([]);
  const [searchFilters, setSearchFilters] = useState();
  const [selectedRows, setSelectedRows] = useState(defaultSelected || []);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPages = useRef(0);
  const totalResults = useRef(0);
  const downloadRef = useRef();
  const dataTableRef = useRef();

  const { searchableAttributes, formData, getOptionsFilter } = useSearchableAttributes();

  useEffect(() => { onSelectRow && onSelectRow(selectedRows); }, [selectedRows]);
  useEffect(() => { getOptionsFilter(); }, []);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    setCurrentPage(1);
  };
  const requestWithLoading = useRequestWithLoading();

  const pageFilter = useMemo(() => {
    const pageQuery = `page=${currentPage}&count=${pageSize}`;
    return [pageQuery].filter(Boolean).join('&');
  },
  [currentPage, pageSize, orderBy]);

  const loadVessels = useCallback(() => {
    requestWithLoading(async () => {
      const params = orderBy ? {
        sortFields: [
          {
            field: orderBy?.field,
            order: orderBy?.order?.toUpperCase(),
          },
        ],
        ...searchFilters,
      } : searchFilters;
      const query = await apiFetchVessel(pageFilter, params);
      if (query.ok) {
        const data = await query.json();
        totalResults.current = data.totalCount;
        maxPages.current = data.totalPages || Math.ceil(totalResults.current / pageSize);
        setVessels(data.Vessels || []);
      } else {
        showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  },
  [requestWithLoading, pageFilter, searchFilters, pageSize, orderBy]);

  const maxSelectableItems = useMemo(
    () => Math.min(totalResults.current, maxSelectable
        || MAX_PERFORMANCE_SELECTABLE), [totalResults.current],
  );

  const handleSelectAllResults = useCallback(
    () => {
      requestWithLoading(async () => {
        const query = await apiFetchVessel(`page=${0}&count=${maxSelectableItems}`, searchFilters);
        if (query.ok) {
          const data = await query.json();
          setSelectedRows(data.Vessels.map(basicRowData));
        } else {
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    },
    [requestWithLoading, totalResults, searchFilters, maxSelectableItems],
  );
  const handleClearSelection = useCallback(
    () => setSelectedRows([]),
    [],
  );

  useEffect(() => {
    loadVessels();
  }, [orderBy, searchFilters, pageSize, currentPage]);

  useEffect(() => {
    !emptyOnInit && loadVessels();
  }, []);

  const isSelected = useCallback((row) => any(propEq('etalon_id', row.etalon_id))(selectedRows), [selectedRows]);

  const selectionEnabled = useMemo(() => !maxSelectableItems
    || selectedRows?.length < maxSelectableItems, [selectedRows, maxSelectableItems]);

  const handleOnSelectRow = useCallback((row, checked) => {
    if (maxSelectable === 1) {
      setSelectedRows(checked ? [row] : []);
    } else {
      setSelectedRows((prevState) => (checked
        ? [...prevState, ...(selectionEnabled ? [row] : [])]
        : prevState.filter((item) => !isSameRow(item, row))));
    }
  }, [selectionEnabled, maxSelectable]);

  const unSelectRowIndex = useCallback((index) => {
    setSelectedRows((prevState) => (
      remove(index, 1, prevState)
    ));
  }, []);

  const basicDataVessels = useMemo(() => vessels.map(basicRowData), [vessels, basicRowData]);

  const handleSelectAll = useCallback((checked) => {
    setSelectedRows(
      (prevState) => (
        checked
          ? [
            ...prevState,
            ...basicDataVessels?.filter((row) => !isSelected(row)),
          ].splice(0, maxSelectableItems)
          : prevState.filter((prevRow) => !basicDataVessels.some(
            (row) => isSameRow(row, prevRow),
          ))
      ),
    );
  },
  [basicDataVessels, isSelected, maxSelectableItems]);

  const selectedAll = useMemo(() => basicDataVessels.length > 0
  && basicDataVessels.every((e) => isSelected(e)),
  [isSelected, vessels]);

  const tableColumns = useMemo(() => [
    {
      text: '',
      header: (!maxSelectable || maxSelectable !== 1)
        && <CheckAll isSelected={selectedAll} onSelect={handleSelectAll} />,
      disabled: true,
      code: 'check',
      render: (row) => {
        const basicRowDataRow = basicRowData(row);
        const rowSelected = isSelected(basicRowDataRow);
        const disabled = maxSelectable !== 1 && !rowSelected && !selectionEnabled;
        return (
          <CheckComponent
            row={basicRowDataRow}
            onSelectRowCheck={disableRowCheck && handleOnSelectRow}
            isSelected={rowSelected}
            disabled={disabled}
          />
        );
      }
      ,
    },
    ...getTableConfig(t),
  ],
  [
    getTableConfig,
    isSelected,
    selectionEnabled,
    handleOnSelectRow,
    disableRowCheck,
    maxSelectable,
    handleSelectAll, selectedAll, t,
  ]);

  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      resetPagination();
      setOrderBy(formatHeaderSort(header, orderBy));
    }
  }, [loading, orderBy, resetPagination]);

  const rowClickHandler = useCallback((row) => {
    const basicRowDataRow = basicRowData(row);
    const rowSelected = isSelected(basicRowDataRow);
    handleOnSelectRow(basicRowDataRow, !rowSelected);
  }, [basicRowData, isSelected, handleOnSelectRow]);

  const pageSizeChangeHandler = useCallback((size) => {
    setCurrentPage(1);
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const { permissions } = useSelector((state) => state.authentication);
  const { pagination } = useSelector((state) => state.vessel);

  const handleFilter = useCallback((filters) => {
    resetPagination();
    const formatedFilters = formatBody(null, filters.filtersBody);
    setSearchFilters(formatedFilters);
  }, [resetPagination]);

  const selectedRowsLabels = useMemo(
    () => selectedRows.map(selectedRowsLabelMapper),
    [selectedRows, selectedRowsLabelMapper],
  );
  const multiSelectValuesLabel = useMemo(
    () => (isEmpty(selectedRows) ? null : [
      'Selected vessel',
      selectedRows.length > 1 && 's',
      selectedRows.length > 10 && ` (${selectedRows.length})`,
    ].filter(Boolean).join('')),
    [selectedRows],
  );

  const valuesComponent = useMemo(() => (
    <MultiSelectValues
      values={selectedRowsLabels}
      onRemoveSelection={unSelectRowIndex}
      label={multiSelectValuesLabel}
    />
  ),
  [selectedRowsLabels, unSelectRowIndex, multiSelectValuesLabel]);
  useEffect(() => {
    onValuesComponentChanged && onValuesComponentChanged(valuesComponent);
  }, [valuesComponent]);

  const handleOnRowSelected = useCallback((row) => {
    !disableRowCheck && rowClickHandler(row);
  },
  [disableRowCheck, rowClickHandler]);

  return (
    <Container fluid id="vesselGroupViewer">
      <a href="/" ref={downloadRef} hidden>
        Download
      </a>
      <Filters
        permissions={permissions}
        searchableAttributes={searchableAttributes}
        formDataList={formData}
        pagination={pagination}
        onUpdateSearchFilters={handleFilter}
        hasDefaultFilters={false}
      />
      <Row>
        <Col>
          {vessels && (
            <DataTable
              idColumn="etalon_id"
              ref={dataTableRef}
              onRowSelected={handleOnRowSelected}
              tableId="VesselSearchGrid"
              totalResults={totalResults.current}
              showTotalResults
              tableConfig={tableColumns}
              orderBy={orderBy}
              onTableHeaderClick={tableHeaderClickHandler}
              rowData={vessels}
              defaultPageSize={pageSize}
              onPageSizeChangeHandler={pageSizeChangeHandler}
              onPageChangeHandler={pageChangeHandler}
              loading={loading}
              maxPages={maxPages.current}
              tableActions={(
                <>
                  <Button
                    disabled={loading || selectedRows?.length < 1}
                    onClick={handleClearSelection}
                  >
                    <span className="symbol-cross" />
                    Clear selection
                  </Button>
                  {(!maxSelectable || maxSelectable !== 1) && (
                  <Button
                    disabled={loading}
                    onClick={handleSelectAllResults}
                  >
                    <span className="symbol-tick" />
                    Select all results (
                    {maxSelectableItems !== totalResults.current
                      ? `${maxSelectableItems}/${totalResults.current}`
                      : totalResults.current}
                    )
                  </Button>
                  )}
                </>
            )}
            />
          )}
        </Col>
      </Row>
      {!onValuesComponentChanged && valuesComponent}
    </Container>
  );
};

VesselSearchGrid.propTypes = {
  emptyOnInit: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onValuesComponentChanged: PropTypes.func,
  maxSelectable: PropTypes.number,
  saving: PropTypes.bool,
  disableRowCheck: PropTypes.bool,
  defaultSelected: PropTypes.arrayOf(PropTypes.object),
  selectedRowsLabelMapper: PropTypes.func,
};

VesselSearchGrid.defaultProps = {
  emptyOnInit: false,
  onSelectRow: null,
  onValuesComponentChanged: null,
  maxSelectable: null,
  saving: false,
  disableRowCheck: false,
  defaultSelected: [],
  selectedRowsLabelMapper: (e) => e.cfr || e.uvi,
};

export default VesselSearchGrid;
