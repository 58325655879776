import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row } from 'react-bootstrap';

import FieldCheckBoxWrapper from '../FieldCheckBoxWrapper/FieldCheckBoxWrapper';

const BaseFilter = ({
  label,
  name,
  nameCheckbox,
  error,
  checked,
  onFieldChecked: handleFieldChecked,
  children,
}) => (
  <div className="d-flex justify-content-between align-items-center flex-wrap">
    <Form.Group as={Row} className="d-flex align-items-center justify-content-between m-0 flex-grow-1" controlId={name}>
      <FieldCheckBoxWrapper
        checked={checked}
        name={nameCheckbox}
        onFieldChecked={handleFieldChecked}
      >
        <Form.Label className="m-0 mr-1">{label}</Form.Label>
      </FieldCheckBoxWrapper>
      <div style={{ minWidth: '150px' }}>
        {children}
      </div>
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  </div>
);

BaseFilter.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameCheckbox: PropTypes.string.isRequired,
  error: PropTypes.string,
  checked: PropTypes.bool,
  onFieldChecked: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

BaseFilter.defaultProps = {
  error: null,
  checked: undefined,
};

export default BaseFilter;
