import actions from './img/actions.JPG';
import table from './img/table.JPG';

const SectionScenariosTable = () => (
  <div className="SectionScenariosTable">
    <p>
      In the Scenarios tab, it is displayed a grid containing the
      list of all the Scenarios that are available in the system.
    </p>
    <p>
      <img src={table} alt="Scenarios list" />
    </p>
    <p>
      For each row there is an &quot;Actions&quot; button displayed in the last column of the grid.
      Clicking it displays a list of options that allow the user to Edit,
      View or Delete the Scenario.
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
    <p>
      The Scenario access and modification are only available to the FIS ISR Party Admin.
    </p>
  </div>
);

export default SectionScenariosTable;
