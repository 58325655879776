import serviceNameImage from './img/services_name.png';
import servicesStatusDownImage from './img/services_status_down.png';
import serviceStatusImage from './img/services_status_up.png';
import serviceImage from './img/services_table.png';

const SectionServicesMonitoringTable = () => (
  <div className="subSection">
    <p>
      This grid shows a list of services where users can check if the services are up or down.
    </p>
    <p>
      <img src={serviceImage} alt="Services grid" />
    </p>
    <p>
      The column &quot;Services name&quot; shows exactly the name of the service:
    </p>
    <p>
      <img src={serviceNameImage} alt="Services name column" />
    </p>
    <p>
      The &quot;Status&quot; column shows the current status of the service:
    </p>
    <p>
      <img src={serviceStatusImage} alt="Services status column" />
    </p>
    <p>
      If there are any services that are not working, their status is &quot;Down&quot;:
    </p>
    <p>
      <img src={servicesStatusDownImage} alt="Services status column" />
    </p>

  </div>
);

export default SectionServicesMonitoringTable;
