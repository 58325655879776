import actions from './img/actions.JPG';
import editForm from './img/edit_form.JPG';

const SectionEditQuery = () => (
  <div className="SectionQueryAcceptance">
    <p>
      For editing a Query,
      in the results grid the user has to click on the &quot;Actions&quot; button
      in the row of the corresponding query, and then on the &quot;Edit&quot; Action button.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal window opens displaying all the information about the query:
    </p>
    <p>
      <img src={editForm} alt="editForm" />
    </p>
    <p>
      After editing the Query Acceptance,
      the user shall click on &quot;Save&quot; button to save the changes.
      Once done, the results grid is updated with the changes performed in the grid.
    </p>
  </div>
);

export default SectionEditQuery;
