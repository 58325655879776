import addVesselsTable from './img/add_vessels_table.JPG';
import createButton from './img/create_button.JPG';
import dynamicGroupCriteria from './img/create_dynamic_group_modal.JPG';
import staticGroupModal from './img/create_static_group_modal.JPG';
import groupType from './img/group_type.JPG';
import loadVesselsFromFile from './img/load_vessels_from_file.JPG';

const SectionVesselGroupCreate = () => (
  <div className="SectionVesselGroupCreate">
    <p>
      To create a new vessel group, click on the &quot;Create Vessel Group&quot;
      button available below the results grid, one the bottom right corner.
    </p>
    <p>
      <img src={createButton} alt="Create button" />
    </p>
    <p>
      This opens a modal window containing the creation form with the following related fields:
    </p>
    <p>
      <img src={staticGroupModal} alt="Static " />
    </p>
    <p>
      The user can choose the way of adding vessels to the group
    </p>
    <p>
      <img src={groupType} alt="Group Type" />
    </p>
    <p>
      <b>Add Vessels Manually</b>
    </p>
    <p>
      If the selected method of adding vessels is static,
      the user can search vessels through the vessel
      search form, and also load the vessels from a file
    </p>
    <p>
      <p>
        <b>Load Vessels from a File: </b>
        Using the &quot;Select from&quot; button
        (located on Load Vessels from File section), the user can add at
        once multiple vessels to the group from a file. By clicking the button, a list of valid
        types of files appears.
      </p>
      <p>
        Once the user has selected the desired type,
        a file explorer opens so the user can select the file to upload.
      </p>
      <p>
        And when the upload of the file is finished, vessels found are shown on a
        grid displayed below &quot;Select from&quot; button.
      </p>
    </p>
    <p>
      <img src={loadVesselsFromFile} alt="Load Vessels From File" />
    </p>
    <p>
      <b>Vessel Search Form: </b>
      It is possible to search the vessels by filtering
      in the same way than it is done in the Vessel Viewer
      search.
    </p>
    <p>
      <img src={addVesselsTable} alt="Add Vessels Grid" />
    </p>
    <p>
      For adding the vessels to the group, the user has to click on the corresponding
      check button one by one, or selecting all the visible vessels using the check
      button displayed on the header of the column labelled as &quot;Add&quot;.
    </p>
    <p>
      <b>Add Vessels By Criteria</b>
    </p>
    <p>
      If the selected way of adding vessels is Dynamic,
      a combo with the list of possible criteria is displayed in the creation form.
    </p>
    <p>
      <img src={dynamicGroupCriteria} alt="Criteria" />
    </p>
  </div>
);

export default SectionVesselGroupCreate;
