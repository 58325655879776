import React from 'react';
import {
  Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import tSchema from '@lang/schema';

import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import './domainSelectorStyle.scss';

import { REACT_SELECT } from '@common/utils';

const DomainSelector = () => {
  const { t } = useTranslation();

  const { userPreferences, setUserPreferences } = useUserPreferences();

  const { domain, domains, loadingDomains } = userPreferences;

  const handleChange = (option) => {
    const { value } = option;
    setUserPreferences((prevState) => ({ ...prevState, domain: value }));
  };

  return (

    <Row
      className="flex-nowrap "
      style={{
        marginLeft: '30px',
        paddingRight: '0.625rem',
        maxWidth: '275px',
      }}
    >
      <Form.Group className="domain-selector">
        <Form.Label htmlFor="basic-url">{t(tSchema.menu.domain)}</Form.Label>
        <Form.Group
          as={Row}
          className="d-flex align-items-center m-0 my-0"
          style={{ maxWidth: '100%', width: domain ? '7em' : '10.5em' }}
        >
          <ReactSelect
            isLoading={loadingDomains}
            placeholder={!loadingDomains && t(tSchema.menu.domainPlaceHolder)}
            styles={REACT_SELECT.customStyles}
            className="domain-selector-select"
            options={(domains || []).map(({ code }) => ({ label: code, value: code }))}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onChange={handleChange}
            value={domain && { code: domain, label: domain }}
          />
        </Form.Group>
      </Form.Group>
    </Row>
  );
};

export default DomainSelector;
