import i18next from 'i18next';
import { prop } from 'ramda';
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';

import tSchema from '@lang/schema';

import { formatDate, postProcessDate } from '@common/dateUtils';
import { DOMAIN, formatSelectOption } from '@common/utils';

const schema = tSchema.dmaAdmin.acceptanceBR.query.queryAcceptance;
const { t } = i18next;

export const tableConfig = (getValue, domain) => {
  const isISRDomain = domain === DOMAIN.ISR;
  const configTable = [...((!isISRDomain) ? [{
    text: t(schema.sender),
    code: 'sender',
    sortable: true,
    render: (row) => getValue('fluxParties', row.sender, 'name', 'code'),
  },
  {
    text: t(schema.dataStartDate),
    code: 'startDateData',
    sortable: true,
    render: (row) => formatDate(row.startDateData) || '-',
  },
  {
    text: t(schema.dataEndDate),
    code: 'endDateData',
    sortable: true,
    render: (row) => formatDate(row.endDateData, undefined, '-'),
  },
  {
    text: t(schema.validFrom),
    code: 'activityStartDate',
    sortable: true,
    render: (row) => formatDate(row.activityStartDate, undefined, '-'),
  },
  {
    text: t(schema.validTo),
    code: 'activityEndDate',
    sortable: true,
    render: (row) => formatDate(row.activityEndDate, undefined, '-'),
  },
  {
    text: t(schema.vesselGroups),
    code: 'vesselGroups',
    render: (row) => row.vesselGroups && (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="line-4-tooltip">{row.vesselGroups.map((vsg) => getValue('vesselGroups', vsg, 'name')).join(', ')}</Tooltip>}
    >
      <p className="limit-text">{row.vesselGroups.map((vsg) => getValue('vesselGroups', vsg, 'name')).join(', ')}</p>
    </OverlayTrigger>
    ),
  },
  {
    text: t(schema.active),
    code: 'status',
    sortable: true,
    render: (row) => (row.status || row.status === 0) && (
    <Badge className="status" variant={row.status === 0 ? 'success' : 'danger'} pill />
    ),
  }] : []),
  ...((isISRDomain) ? [
    {
      text: t(schema.isrParty),
      code: 'isrParty',
      sortable: true,
      render: (row) => getValue('fluxParties', row.isrParty, 'name', 'code'),
    },
    {
      text: t(schema.validFrom),
      code: 'startActiveDate',
      sortable: true,
      render: (row) => formatDate(row.startActiveDate, undefined, '-'),
    },
    {
      text: t(schema.validTo),
      code: 'endActiveDate',
      sortable: true,
      render: (row) => formatDate(row.endActiveDate, undefined, '-'),
    },
    {
      text: t(schema.queryContext),
      code: 'queryContexts',
      render: (row) => (row.queryContexts ? <p className="limit-text">{row.queryContexts.join(', ')}</p> : '-'),
    },
    {
      text: t(schema.isProgramme),
      code: 'isProgrammes',
      render: (row) => (row.isProgrammes ? <p className="limit-text">{row.isProgrammes.join(', ')}</p> : '-'),
    },
    {
      text: t(schema.active),
      code: 'status',
      sortable: true,
      render: (row) => (row.status || row.status === 0) && (
      <Badge className="status" variant={row.status === 0 ? 'success' : 'danger'} pill />
      ),
    },
  ] : []),
  ];
  return configTable;
};

export const configureFilters = (
  options, defaultValues, domain,
) => {
  const isFADomain = domain === DOMAIN.FA;
  const arrayFilters = [
    ...((isFADomain) ? [
      {
        code: 'sender',
        label: t(schema.sender),
        type: 'multichoice',
        idProp: 'code',
        options: prop('fluxParties')(options).map((option) => formatSelectOption(option, 'code', 'name')),
        defaultValue: prop('fluxParties')(defaultValues),
      },
      {
        code: 'vesselGroups',
        label: t(schema.vesselGroups),
        type: 'multichoice',
        textProp: 'name',
        options: prop('vesselGroups')(options),
        defaultValue: prop('vesselGroups')(defaultValues),
      },
      {
        code: 'dataScope',
        label: t(schema.filters.requestedDataDate),
        type: 'simpledate',
        // This should be temporal, and the default format of the dates
        // when the filters are no longer passed in the URI
        postProcess: postProcessDate,
      },
      {
        code: 'activityTimeframe',
        label: t(schema.filters.validOn),
        type: 'simpledate',
        // This should be temporal, and the default format of the dates
        // when the filters are no longer passed in the URI
        postProcess: postProcessDate,
      },
      {
        code: 'status',
        label: t(schema.filters.status),
        type: 'select',
        options: prop('brstatus')(options),
        defaultValue: prop('status')(defaultValues),
      }] : [
      {
        code: 'isrParty',
        label: t(schema.isrParty),
        type: 'multichoice',
        idProp: 'code',
        options: prop('fluxParties')(options).map((option) => formatSelectOption(option, 'code', 'name')),
        defaultValue: prop('fluxParties')(defaultValues),
      },
      {
        code: 'activityTimeframe',
        label: t(schema.filters.validOn),
        type: 'simpledate',
        // This should be temporal, and the default format of the dates
        // when the filters are no longer passed in the URI
        postProcess: postProcessDate,
      },
      {
        code: 'status',
        label: t(schema.filters.status),
        type: 'select',
        options: prop('brstatus')(options),
        defaultValue: prop('status')(defaultValues),
      },
      {
        code: 'queryContexts',
        label: t(schema.filters.queryContext),
        type: 'multichoice',
        textProp: 'code',
        idProp: 'code',
        options: prop('queryContexts')(options),
      },
      {
        code: 'isProgrammes',
        label: t(schema.filters.isProgramme),
        type: 'multichoice',
        textProp: 'code',
        idProp: 'code',
        options: prop('inspectionProgrammes')(options),
      },
    ]),

  ];
  return arrayFilters;
};

export const searchOptions = [
  { label: 'contains', value: '=-', type: 'text' },
  { label: 'equal', value: '==' },
  { label: 'not contains', value: '!-', type: 'text' },
  { label: 'begins with', value: '*=', type: 'text' },
  { label: 'ends with', value: '=*', type: 'text' },
  {
    label: 'is null', value: '=null', type: 'text', single: true,
  },
  { label: 'before', value: '<', type: 'date' },
  { label: 'after', value: '>', type: 'date' },
  { label: 'not equals', value: '!-', type: 'complex-select' },
  { label: 'contains', value: '=-', type: 'complex-select' },
];

export const valueOptions = [
  { option: 'vesselGroups', entity: 'logbook' },
  { option: 'fluxParties', entity: 'businessrules' },
  { option: 'brstatus', path: 'brstatus/get' },
  { option: 'queryContexts', path: 'isrService/queryContexts' },
  { option: 'inspectionProgrammes', path: 'isrService/inspectionProgrammes' },
];
