/* eslint-disable import/order */
import EDITOR_MODE from '@pages/QuerySubscriptions/config';
import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import {
  apiDownloadTableData,
  apiFetchQueries,
  apiFetchQueriesOptions,
  apiExecuteReportSubscription,
  apiDeleteReportSubscription,
  apiSaveReport,
} from '@services/queries/api';
import DataTable from '@components/DataTable';
import Filters from '@components/Filters';
import IconSvg from '@components/IconSvg';
import tSchema from '@lang/schema';
import PropTypes from 'prop-types';
import {
  isEmpty,
} from 'ramda';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Container,
  Col,
  Dropdown,
  Row,
  Modal,
  Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import QueryEditor from '../QueryEditor';

import {
  configureFilters,
  searchOptions,
  getTableConfig,
  valueOptions,
} from './config';

import './style.scss';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';
import { currentISO8601Date } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import { isDataOrSystemAdmin } from '@common/keycloakFunctions';
import {
  requestsWithMessage, requestWithMessage, showErrorNotification, showOkNotification,
} from '@common/utilities/Notification';
import { DOMAIN, formatHeaderSort } from '@common/utils';

const schema = tSchema.dmaAdmin.queryReportMGMT.report;

const ReportSubscriptions = ({ maximumDaysToQuery }) => {
  const { t } = useTranslation();

  const [types, setTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [querySubscription, setQuerySubscription] = useState([]);
  const [editorMode, setEditorMode] = useState('');
  const [selectedId, setSelectedId] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [initialFilters, setInitialFilters] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const pageRequest = useRef(1);
  const maxPages = useRef(0);
  const totalResults = useRef(0);
  const downloadRef = useRef();
  const dataTableRef = useRef();

  const { keycloak } = useKeyCloak();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };
  const { userPreferences: { domain } } = useUserPreferences();

  const isVessel = useMemo(() => domain === DOMAIN.VESSEL, [domain]);

  const requestWithLoading = useRequestWithLoading();

  const downloadCsv = () => {
    requestWithLoading(async () => {
      const query = await apiDownloadTableData(searchFilters, 'csv', 'report', isVessel);

      if (query.ok) {
        const csv = await query.text();
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        downloadRef.current.href = url;
        downloadRef.current.download = `br_${currentISO8601Date()}.csv`;
        downloadRef.current.click();
        downloadRef.current.href = '/';
      } else {
        console.error("The download didn't succeded", `Error code: ${query.status}`);
        showErrorNotification(t(tSchema.notifications.common.exportCsv));
      }
    }, setLoading);
  };

  const fetchOptions = useCallback(() => {
    requestsWithMessage({
      requests: valueOptions.map(apiFetchQueriesOptions),
      errorMessage: 'An error while loading the filter options has occurred',
      onSuccess: async (responses) => {
        const optionsJson = await Promise.all(responses.map((x) => x.json()));
        const newTypes = {};
        optionsJson.forEach((x, i) => {
          newTypes[valueOptions[i].option] = x;
        });
        setTypes(newTypes);
      },
      setLoading,
    });
  }, [valueOptions]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  useEffect(() => {
    if (types) {
      const defaultFilters = {};
      searchFilters && setSearchFilters(null);
      setInitialFilters(defaultFilters);
      setFiltersConfig(configureFilters(types, defaultFilters, t));
    }
  }, [types]);

  const fetchReports = useCallback(() => {
    setSelectedId(null);
    requestWithMessage({
      request: async () => apiFetchQueries(true)(
        searchFilters,
        {
          page: pageRequest.current - 1,
          size: pageSize,
          sort: orderBy && orderBy.field,
          direction: orderBy && orderBy.order,
        },
      ),
      onSuccess: async (response) => {
        const data = await response.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setQuerySubscription(data.content || []);
      },
      errorMessage: t(tSchema.notifications.common.fetchData),
      setLoading,
    });
  }, [searchFilters, pageRequest, pageSize, orderBy]);

  useEffect(() => {
    !loading && fetchReports();
  }, [
    pageSize,
    orderBy,
    searchFilters,
  ]);

  const executeHandler = useCallback((id) => {
    requestWithMessage({
      request: () => apiExecuteReportSubscription(id),
      successMessage: 'Successfully executed',
      errorMessage: 'An error occurred during execution',
      setLoading,
    });
  });

  const showDeleteModal = useCallback((id) => {
    setSelectedId(id);
    setShowDelete(true);
    document.body.click();
  }, []);

  const deleteHandler = useCallback(() => {
    setSelectedId(null);
    requestWithMessage({
      request: () => apiDeleteReportSubscription(selectedId),
      successMessage: 'Successfully deleted',
      errorMessage: 'An error occurred during deletion',
      onSuccess: () => {
        fetchReports();
        setShowDelete(false);
      },
      setLoading,
    });
  }, [selectedId, fetchReports]);

  const cancelDeleteHandler = useCallback(() => {
    setShowDelete(false);
    setSelectedId(null);
  }, []);

  const onEditViewClick = useCallback((id, edit) => {
    setEditorMode(edit ? EDITOR_MODE.EDIT : EDITOR_MODE.VIEW);
    setSelectedId(id);
    setShowEditor(true);
    document.body.click();
  }, []);

  const executeRowHandler = useCallback((row) => executeHandler(row.id), [executeHandler]);
  const editRowHandler = useCallback((row) => onEditViewClick(row.id, true), [onEditViewClick]);
  const viewRowHandler = useCallback((row) => onEditViewClick(row.id, false), [onEditViewClick]);
  const deleteRowHandler = useCallback((row) => showDeleteModal(row.id), [showDeleteModal]);
  const actions = useMemo(() => [
    { label: t(tSchema.common.execute), action: executeRowHandler },
    { label: t(tSchema.common.edit), action: editRowHandler },
    { label: t(tSchema.common.view), action: viewRowHandler },
    { label: t(tSchema.common.delete), action: deleteRowHandler },
  ], [
    executeRowHandler,
    editRowHandler,
    viewRowHandler,
    deleteRowHandler,
  ]);

  const isDataAdmin = isDataOrSystemAdmin(keycloak);

  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      pageRequest.current = 1;
      setOrderBy(formatHeaderSort(header, orderBy));
      resetPagination();
    }
  }, [loading, pageRequest, orderBy, resetPagination]);

  const updateSearchFiltersHandler = useCallback((filters) => {
    if (pageRequest.current > 1) {
      resetPagination();
    }
    setSearchFilters(filters);
  }, [pageRequest, resetPagination]);

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    fetchReports();
  }, []);

  const createQueryButtonClickHandler = useCallback(() => {
    setEditorMode(EDITOR_MODE.CREATE);
    setShowEditor(true);
    setSelectedId(-1);
  }, []);
  const createQueryButtonDisabled = useMemo(
    () => loading
    || (
      selectedId
      && isEmpty(selectedId)
    ),
    [loading, selectedId],
  );
  const createQueryRuleButton = useMemo(() => (
    <Button
      id="new-query"
      className="symbol-plus"
      onClick={createQueryButtonClickHandler}
      disabled={createQueryButtonDisabled}
      hidden={!isDataAdmin}
    >
      {t(schema.createReport)}
    </Button>
  ), [createQueryButtonClickHandler, createQueryButtonDisabled, isDataAdmin]);

  const exportAsDropdownSelectHandler = useCallback((key) => {
    if (key === 'csv') {
      downloadCsv();
    }
  }, [downloadCsv]);
  const exportAsDropdown = useMemo(() => (
    <>
      <Dropdown
        onSelect={exportAsDropdownSelectHandler}
      >
        <Dropdown.Toggle
          id="download-querySubscriptions"
          variant="primary"
          disabled={createQueryButtonDisabled}
          placement="top-start"
        >
          {t(tSchema.common.exportAs)}
          <IconSvg name="caretDown" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="csv">{t(tSchema.common.csv)}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    </>
  ), [exportAsDropdownSelectHandler, createQueryButtonDisabled]);

  const queryEditorSaveHandler = useCallback((query) => {
    requestWithLoading(async () => {
      const response = await apiSaveReport(query);
      if (response.ok) {
        showOkNotification(t(tSchema.notifications.dmaAdmin.queryReportMGMT.reportSubscription.ok));
        setShowEditor(false);
        fetchReports();
      } else if (response.status !== 500) {
        const resp = await response.json();
        showErrorNotification(resp?.message);
      } else {
        showErrorNotification();
        setShowEditor(false);
      }
    }, setLoading);
  }, [fetchReports]);

  const queryEditorCancelHandler = useCallback(() => {
    setSelectedId(null);
    setShowEditor(false);
  }, []);

  const editorTitle = useMemo(() => {
    const editOrViewTitle = editorMode === EDITOR_MODE.EDIT
      ? t(tSchema.common.edit)
      : t(tSchema.common.view);
    return (selectedId && selectedId > 0 ? `${editOrViewTitle} Report` : 'New Report Subscription');
  },
  [selectedId, editorMode]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && viewRowHandler(row), // Double Click
    [viewRowHandler],
  );

  return (
    <Container fluid id="reportSubscriptionsViewer">
      <a href="/" ref={downloadRef} hidden>
        Download
      </a>
      <Filters
        filters={filtersConfig}
        searchOptions={searchOptions}
        onUpdateSearchFilters={updateSearchFiltersHandler}
        isUIBlocked={loading}
        initialFilters={initialFilters}
      />
      <Row>
        <Col>
          <DataTable
            actions={actions}
            rowSelected={selectedId}
            ref={dataTableRef}
            tableId="reportSubscriptions"
            totalResults={totalResults.current}
            tableConfig={tableConfig}
            orderBy={orderBy}
            onTableHeaderClick={tableHeaderClickHandler}
            onRowSelected={handleRowSelected}
            rowData={querySubscription}
            defaultPageSize={pageSize}
            onPageSizeChangeHandler={pageSizeChangeHandler}
            onPageChangeHandler={pageChangeHandler}
            loading={loading}
            maxPages={maxPages.current}
            tableActions={(
              <>
                {createQueryRuleButton}
                {exportAsDropdown}
              </>
                )}
          />
        </Col>
      </Row>
      <Modal
        backdrop="static"
        show={showEditor}
        onHide={() => { setSelectedId(null); setShowEditor(false); }}
        size="xl"
      >
        <Modal.Header closeButton className="pb-0">
          <Modal.Title>{editorTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <QueryEditor
            key={`e${selectedId}${editorMode === EDITOR_MODE.EDIT ? 'edit' : 'view'}`}
            isDataAdmin={isDataAdmin}
            queryId={selectedId}
            mode={editorMode}
            onSave={queryEditorSaveHandler}
            onCancel={queryEditorCancelHandler}
            loading={loading}
            options={types}
            isReport
            maximumDaysToQuery={maximumDaysToQuery}
          />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={showDelete}
        onHide={cancelDeleteHandler}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tSchema.common.delete)}
            {' '}
            Case
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            Are you sure you want to delete this case?
          </Alert>
          <QueryEditor
            key={`e${selectedId}${editorMode === EDITOR_MODE.EDIT ? 'edit' : 'view'}`}
            isDataAdmin={isDataAdmin}
            queryId={selectedId}
            mode={EDITOR_MODE.VIEW}
            onSave={queryEditorSaveHandler}
            onCancel={queryEditorCancelHandler}
            loading={loading}
            options={types}
            isReport
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={cancelDeleteHandler}>
            {t(tSchema.common.cancel)}
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            {t(tSchema.common.delete)}
          </Button>
        </Modal.Footer>
      </Modal>
      <br />
    </Container>
  );
};

ReportSubscriptions.propTypes = {
  maximumDaysToQuery: PropTypes.number.isRequired,
};

export default ReportSubscriptions;
