/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  Row, Tooltip, OverlayTrigger,
} from 'react-bootstrap';

import BRStatusButton from './BRStatusButton';

const ResponseStatusButton = ({
  message, domain,
}) => {
  const CHOICE_CLASS_DOT_RESPONSE = {
    WAITING: 'dot-waiting',
    TIMEOUT: 'dot-timeout',
    ERRORS: 'dot-error',
    WARNINGS: 'dot-warning',
    OK: 'dot-ok',
  };
  const SHOW_MESSAGE_TOOLTIP_RESPONSE = {
    WAITING: 'Waiting for response',
    TIMEOUT: 'Response timeout',
    OK: 'Response OK',
  };

  const getResponseStatusIndicator = (status) => {
    switch (status) {
      case 'ERROR':
      case 'WARNING':
        return (
          <div style={{ marginLeft: '30px' }}>
            <BRStatusButton
              statusResult={status}
              message={message}
              isResponseStatus
              domain={domain}
            />
          </div>

        );
      case 'WAITING':
      case 'TIMEOUT':
      case 'OK':
      default:
        return (
          <span className={`ml-1 ${
            status
              ? CHOICE_CLASS_DOT_RESPONSE[status]
              : 'dot-without-response'
          } align-self-end my-auto`}
          />
        );
    }
  };

  return (
    <>
      {(message?.type?.description.includes('Report') || message?.type?.description.includes('Query'))
      && (
        <Row className="d-flex justify-content-end align-content-end align-content-between ">
          <OverlayTrigger
            overlay={(
              <Tooltip>
                {SHOW_MESSAGE_TOOLTIP_RESPONSE[message?.responseStatus?.status] || '-'}
              </Tooltip>
                    )}
            placement="left"
          >
            <span className="my-auto mr-1 p-1">
              Response status
            </span>
          </OverlayTrigger>
            {
                getResponseStatusIndicator(message?.responseStatus?.status)
            }
        </Row>
      )}
    </>
  );
};

ResponseStatusButton.propTypes = {
  message: PropTypes.shape({
    creationDate: PropTypes.string,
    dataDomain: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
    id: PropTypes.number,
    idType: PropTypes.number,
    identification: PropTypes.string,
    isIncoming: PropTypes.bool,
    message: PropTypes.string,
    recipient: PropTypes.string,
    relatedMessages: PropTypes.arrayOf(PropTypes.any),
    reportStatus: null,
    responseStatus: null,
    sender: PropTypes.string,
    status: null,
    type: PropTypes.shape({ id: PropTypes.number, description: PropTypes.string }),
    uuid: PropTypes.string,
    validationResults: PropTypes.string,
  }).isRequired,
  domain: PropTypes.number.isRequired,
};

export default ResponseStatusButton;
