export function formatIds(selectedIds) {
  const formattedIds = [];
  if (selectedIds && selectedIds.length > 0) {
    for (let i = 0; i < selectedIds.length; i += 1) {
      formattedIds.push(selectedIds[i].row_id);
    }
  }
  return formattedIds;
}

export function formatReports(selectedIds) {
  const formattedReports = [];
  if (selectedIds && selectedIds.length > 0) {
    for (let i = 0; i < selectedIds.length; i += 1) {
      formattedReports.push({
        uuid: selectedIds[i].uuid,
        recipient: selectedIds[i].recipient,
      });
    }
  }
  return formattedReports;
}
