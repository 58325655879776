import Tab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import {
  useMemo,
  useCallback, useState, useEffect,
} from 'react';

import './style.scss';

const CustomTabs = ({
  sections, defaultSectionIndex, onCurrentSectionChangeCallback,
  className, tabsClassName, contentClassName,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultSectionIndex);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
    onCurrentSectionChangeCallback && onCurrentSectionChangeCallback(newValue);
  }, [onCurrentSectionChangeCallback]);

  useEffect(() => {
    setSelectedTab(defaultSectionIndex);
  }, [defaultSectionIndex]);

  const contentClassNames = useMemo(() => ['tabs-efca-content', contentClassName].filter(Boolean).join(' '), [contentClassName]);

  return (
    <div className={`tabs-efca ${className}`}>
      <MuiTabs
        className={tabsClassName}
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {sections.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </MuiTabs>

      {sections.map(({ content, label }, index) => (
        <div key={`${label}-content`} className={contentClassNames} hidden={selectedTab !== index}>
          {content}
        </div>
      ))}
    </div>
  );
};

CustomTabs.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    }),
  ).isRequired,
  defaultSectionIndex: PropTypes.number,
  onCurrentSectionChangeCallback: PropTypes.func,
  className: PropTypes.string,
  tabsClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};

CustomTabs.defaultProps = {
  defaultSectionIndex: 0,
  onCurrentSectionChangeCallback: () => null,
  className: 'tabs',
  tabsClassName: 'default',
  contentClassName: null,
};

export default CustomTabs;
