import PropTypes from 'prop-types';
import {
  isEmpty, compose, map, sort,
} from 'ramda';
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';

import FieldCheckBoxWrapper from '../FieldCheckBoxWrapper/FieldCheckBoxWrapper';

import { REACT_SELECT } from '@common/utils';

import './style.scss';

const MAX_ITEMS = 500;

const Select = ({
  controlId,
  label,
  name,
  value,
  options,
  setFieldValue,
  checked,
  onFieldChecked: handleFieldChecked,
  isMulti,
}) => {
  const [isAllValues, setIsAllValues] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const allValue = { value: 'all', label: 'All' };

  useEffect(() => {
    if (value?.length === options?.length) {
      setIsAllValues(true);
    } else {
      setIsAllValues(false);
    }
  }, [value, options]);

  const DEFAULT_GROUP_LABEL = 'OTHER';

  const getGroupedOptions = (valueOptions, customOrder) => {
    const groupByObject = valueOptions.reduce(
      (previousValue, currentValue) => (currentValue.groupBy
        ? {
          ...previousValue,
          [currentValue.groupBy]: [
            ...(previousValue[currentValue.groupBy] || []),
            currentValue,
          ],
        }
        : previousValue),
      {},
    );
    const returnGrouped = Object.keys(groupByObject).length > 0;
    const noGroupOptions = returnGrouped && valueOptions.filter((v) => !v.groupBy);
    const defaultGroup = (returnGrouped && noGroupOptions.length > 0) ? [{
      label: DEFAULT_GROUP_LABEL,
      options: noGroupOptions,
    }] : [];
    const groupedOptions = returnGrouped && compose(
      map((k) => ({ label: k, options: groupByObject[k] })),
      sort(customOrder || ((a, b) => a?.localeCompare(b))),
    )(Object.keys(groupByObject));

    return returnGrouped
      ? groupedOptions.concat(defaultGroup)
      : valueOptions;
  };

  const isLargeOptions = useMemo(() => options?.length > MAX_ITEMS, [options]);
  const handleInputChange = useCallback((input, ev) => {
    ev.action && setInputValue(input);
  }, []);
  const optionsSpliced = useMemo(() => ((isLargeOptions && isEmpty(inputValue))
    ? [...options].splice(0, MAX_ITEMS).concat(
      [{ label: '...Type text to show more results', value: null, isDisabled: true }],
    ) : getGroupedOptions(options)), [isLargeOptions, inputValue, options]);

  const formatGroupLabel = (option) => (
    <div className="selectGroup">
      {option.label}
    </div>
  );

  const formatGroupLabelWithCount = useCallback(
    (option) => formatGroupLabel(option),
    [],
  );
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <FieldCheckBoxWrapper checked={checked} name={name} onFieldChecked={handleFieldChecked}>
        <Form.Label className="multi-choice-label m-0">{label}</Form.Label>
      </FieldCheckBoxWrapper>
      <Form.Group
        as={Row}
        className="d-flex align-items-center m-0 my-2"
        controlId={controlId}
        style={{ maxWidth: '100%' }}
      >
        <ReactSelect
          styles={REACT_SELECT.customStylesDefault()}
          onInputChange={handleInputChange}
          name={name}
          className="multi-choice-select form-control-sm h-100 m-0 p-0"
          placeholder=""
          isMulti={isMulti}
          formatGroupLabel={formatGroupLabelWithCount}
          closeMenuOnSelect={!isMulti}
          value={isAllValues ? allValue : value}
          noOptionsMessage={() => null}
          options={isAllValues ? [] : optionsSpliced}
          onChange={(option) => setFieldValue(name, isMulti ? option : [option])}
          menuPortalTarget={document.body}
          classNamePrefix="full-width"
        />
      </Form.Group>
    </div>
  );
};

Select.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  setFieldValue: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onFieldChecked: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
};

Select.defaultProps = {
  isMulti: false,
  options: undefined,
};

export default Select;
