/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  Row, Tooltip, OverlayTrigger,
} from 'react-bootstrap';

import './style.scss';

const ExchangeStatusButton = ({
  message,
}) => {
  const CHOICE_CLASS_DOT_EXCHANGE = {
    SENDING: 'dot-waiting',
    TECHNICAL_FAILURE: 'dot-timeout',
    SENT: 'dot-ok',
  };
  const SHOW_MESSAGE_TOOLTIP_EXCHANGE = {
    SENDING: 'Sending',
    TECHNICAL_FAILURE: 'Exchange timeout',
    SENT: 'Sent',
  };
  return (
    <>

      <Row className="d-flex justify-content-end align-content-between align-items-end ">
        <OverlayTrigger
          overlay={(
            <Tooltip>
              {SHOW_MESSAGE_TOOLTIP_EXCHANGE[message?.exchangeStatus?.status]}
            </Tooltip>
                )}
          placement="left"
        >
          <p className="my-auto mr-1 p-1">Exchange status</p>
        </OverlayTrigger>
        <span className={`ml-1 ${
          message?.exchangeStatus?.status
            ? CHOICE_CLASS_DOT_EXCHANGE[message?.exchangeStatus?.status]
            : 'dot-without-response'

        } align-self-end my-auto`}
        />
      </Row>
    </>
  );
};

ExchangeStatusButton.propTypes = {
  message: PropTypes.shape({
    creationDate: PropTypes.string,
    dataDomain: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
    id: PropTypes.number,
    idType: PropTypes.number,
    identification: PropTypes.string,
    isIncoming: PropTypes.bool,
    message: PropTypes.string,
    recipient: PropTypes.string,
    relatedMessages: PropTypes.arrayOf(PropTypes.any),
    reportStatus: null,
    exchangeStatus: null,
    responseStatus: null,
    sender: PropTypes.string,
    status: null,
    type: PropTypes.shape({ id: PropTypes.number, description: PropTypes.string }),
    uuid: PropTypes.string,
    validationResults: PropTypes.string,
  }).isRequired,
};

export default ExchangeStatusButton;
