import i18next from 'i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import tSchema from '@lang/schema';

import { formatDate } from '@common/dateUtils';

const schema = tSchema.dmaAdmin.scenarioMGMT.scenarios;

const { t } = i18next;

export const getTableConfig = () => [
  {
    text: t(schema.name),
    code: 'name',
    sortable: true,
    className: 'text-left',
  },
  {
    text: t(schema.description),
    code: 'description',
    className: 'text-left',
    sortable: true,
    render: (row) => (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="description-tooltip">{row.description}</Tooltip>}
      >
        <p className="limit-text">{row.description}</p>
      </OverlayTrigger>
    ),
  },
  {
    text: t(schema.status),
    code: 'isActive',
    sortable: true,
    render: (row) => (row.isActive ? 'Active' : 'Inactive'),
  },
  {
    text: t(schema.dataAccessibilityFrom),
    code: 'startDataPeriod',
    sortable: true,
    render: (row) => formatDate(row.startDataPeriod, undefined, 'Indefinite'),
  },
  {
    text: t(schema.dataAccessibilityTo),
    code: 'endDataPeriod',
    sortable: true,
    render: (row) => formatDate(row.endDataPeriod, undefined, 'Indefinite'),
  },
];

export const configureFilters = () => [
  {
    code: 'name',
    label: t(schema.name),
    type: 'text',
  },
  {
    code: 'description',
    label: t(schema.description),
    type: 'text',
  },
  {
    code: 'isActive',
    label: t(schema.status),
    type: 'select',
    options: [{ id: true, description: 'Active' }, { id: false, description: 'Inactive' }],
    previewProcess: (v) => (v ? 'Active' : 'Inactive'),
  },
];

export const searchOptions = [
  { label: 'contains', value: '=-', type: 'text' },
  { label: 'equal', value: '==', type: 'text' },
  { label: 'not contains', value: '!-', type: 'text' },
  { label: 'before', value: '<', type: 'date' },
  { label: 'after', value: '>', type: 'date' },
  { label: 'not equals', value: '!-', type: 'complex-select' },
  { label: 'contains', value: '=-', type: 'complex-select' },
];

export const scenariosValueOptions = [
  { option: 'vesselGroups', entity: 'logbook' },
  { option: 'programmes', endpoint: 'faService/formData/isrInspectionProgrammes' },
  { option: 'vesselFlags', entity: 'logbook' },
  { option: 'controlParties', endpoint: 'faService/formData/isrControlParties' },
  { option: 'isrParties', endpoint: 'faService/formData/fluxGpParties' },
  { option: 'memberStates', entity: 'logbook' },
];
