/* eslint-disable import/order */

import i18next from 'i18next';

import AccordionSection from '@components/UserManual/AccordionSection';
import tSchema from '@lang/schema';

import chooseFilterFilter from './img/choose-filter-filter.png';
import chooseFilter from './img/choose-filter.png';
import filterFisheryTypePng from './img/filter-fishery_type.png';
import filterFlagstate from './img/filter-flagstate.png';
import filterMeshSizePng from './img/filter-mesh-size.png';
import filtersTripidInput from './img/filter-tripid-input.png';
import filtersTripidSelection from './img/filter-tripid-selection.png';
import filterActivityTypeAndPng from './img/filter_activity_type_and.png';
import filterActivityTypeAndSelection from './img/filter_activity_type_and_selection.JPG';
import filterActivityTypeOrPng from './img/filter_activity_type_or.png';
import filterActivityTypeOrSelection from './img/filter_activity_type_or_selection.JPG';
import filterGearTypePng from './img/filter_gear_type.png';
import filterReportTypePng from './img/filter_report_type.png';
import filterSpeciesPng from './img/filter_species.png';
import filterSpeciesDropDownPng from './img/filter_species_dropdown.png';
import filterSpeciesDropDownFilteredPng from './img/filter_species_dropdown_filtered.png';
import filterVesselGroupPng from './img/filter_vessel_group.png';
import filtersVesselSearch from './img/filter_vessel_search.png';
import filtersTimeframe from './img/filters-timeframe.png';
import vesselSelector1 from './img/vessel_selector_1.png';
import vesselSelector2 from './img/vessel_selector_2.png';
import vesselSelector3 from './img/vessel_selector_3.png';
import vesselSelector4 from './img/vessel_selector_4.gif';
import vesselSelector5 from './img/vessel_selector_5.gif';
import vesselSelector6 from './img/vessel_selector_6.gif';
import vesselSelector7 from './img/vessel_selector_7.png';
import vesselSelector8 from './img/vessel_selector_8.png';
import vesselSelector9 from './img/vessel_selector_9.png';

const { t } = i18next;

export const FILTERS = {
  ONLY_LAST_TRIP: {
    title: 'Only last trip',
    body: (
      <p>
        When the &quot;Only last trip&rdquo; filter is selected,
        the data is filtered to show only the last trip.
      </p>
    ),
  },
  TIME_FRAME: {
    title: 'Time Frame',
    body: (
      <>
        <p>
          When the &quot;Time frame&rdquo; filter is selected,
          the data is filtered to show only the trips within the selected time frame.
        </p>
        <p>
          <img src={filtersTimeframe} alt="filters-timeframe" />
        </p>
        <p>
          Leave the &quot;To&rdquo; field empty to show all the trips with a date equal or
          later than the one indicated in the &quot;From&rdquo; field.
        </p>
      </>
    ),
  },
  TRIP_ID: {
    title: 'Trip Id',
    body: (
      <>
        <p>
          When the &quot;Trip Id&rdquo; filter is selected,
          the data is filtered to show only those trips whose Trip Id
          is equal to one of the trip ids listed in the filter.
        </p>
        <p>
          <img src={filtersTripidInput} alt="filters-tripid-input" />
        </p>
        <p>
          Type the trip id in the &quot;Trip Id&rdquo; field and
          click on the &quot;+&rdquo; button to add the trip id to the filter.
        </p>
        <p>
          <img src={filtersTripidSelection} alt="filters-tripid-selection" />
        </p>
        <p>
          Once a trip id has been added to the filter,
          you can remove that trip id by clicking on the &quot;x&rdquo;
          button besides the trip id.
        </p>
      </>
    ),
  },
  VESSEL_GROUP: {
    title: 'Vessel group',
    body: (
      <>
        <p>
          When the &quot;Vessel group&rdquo; filter is selected,
          the data is filtered to show only the trips with the selected vessel group.
        </p>
        <p>
          User can select the vessel group from the dropdown list.
        </p>
        <p>
          <img src={filterVesselGroupPng} alt="Filter vessel group" />
        </p>

      </>
    ),
  },
  VESSEL_SEARCH: {
    title: 'Vessel search',
    body: (
      <>
        <p>
          When the &quot;Vessel search&rdquo; filter is selected,
          the data is filtered to show only those trips corresponding to the selected vessels.
        </p>
        <p>
          <img src={filtersVesselSearch} alt="Filters vesselsearch" />
        </p>
        <p>
          Clicking on &quot;Select&rdquo; opens the &quot;Vessel selector&rdquo;.
        </p>
        <p>
          <img src={vesselSelector1} alt="Vessel selector 1" />
        </p>
        <p>
          By default, the &quot;Vessel selector&rdquo; shows no vessels.
          You can apply any filter to the &quot;Vessel selector&rdquo;
          to define the list of vessels of interest.
        </p>
        <p>
          <img src={vesselSelector2} alt="Vessel selector 2" />
        </p>
        <p>
          Once the enabled filters are applied,
          a list of vessels matching the filter criteria is displayed.
        </p>
        <p>
          <img src={vesselSelector3} alt="Vessel selector 3" />
        </p>
        <p>
          User can select/unselect all the vessels displayed in the current page
          by clicking on the checkbox on the &quot;Add&rdquo; column header.
        </p>
        <p>
          <img src={vesselSelector4} alt="vessel selector 4" />
        </p>
        <p>
          User can select any row in the list of results of the &quot;Vessel selector&rdquo;
          by clicking on the corresponding checkbox.
        </p>
        <p>
          <img src={vesselSelector5} alt="vessel selector 5" />
        </p>
        <p>
          Additionally, below the grid containing the list of results,
          the set of selected vessels is displayed.
          User can unselect a specific vessel by clicking on
          the &quot;X&rdquo; icon besides that vessel.
        </p>
        <p>
          <img src={vesselSelector6} alt="vessel selector 6" />
        </p>
        <p>
          User can select all the vessels from all the pages by
          clicking on the &quot;Select all results&rdquo; button.
        </p>
        <p>
          <img src={vesselSelector7} alt="vessel selector 7" />
        </p>
        <p>
          User can select up to 140 vessels.
        </p>
        <p>
          User can clear all the selected vessels by clicking on the
          &quot;Clear all results&rdquo; button.
        </p>
        <p>
          <img src={vesselSelector8} alt="vessel selector 8" />
        </p>
        <p>
          Once user has selected the vessels of interest, user can click the &quot;Accept&rdquo;
          button to apply the selected vessels to the filter, or the &quot;
          {t(tSchema.common.cancel)}
          &rdquo;
          button to close the &quot;Vessel selector&rdquo;.
        </p>
        <p>
          The selected vessels are displayed below the &quot;Vessel search&rdquo; filter.
        </p>
        <p>
          <img src={vesselSelector9} alt="vessel selector 9" />
        </p>
        <p>
          Here user can quickly remove any selected vessels by
          clicking on their corresponding &quot;X&rdquo; button.
        </p>
      </>
    ),
  },
  SPECIES: {
    title: 'Species',
    body: (
      <>
        <p>
          When the &quot;Species&rdquo; filter is selected,
          the data is filtered to show only the trips with the selected Species.
        </p>
        <p>
          User can select one of the Species from the dropdown list.
        </p>
        <p>
          <img src={filterSpeciesPng} alt="Filter species" />
        </p>
        <p>
          Due to the large amount of existing species,
          the number of options shown in the dropdown list is limited to its first items,
          but the user can type a species name in the &quot;Species&rdquo;
          field to search for that specific species.
        </p>
        <p>
          <img src={filterSpeciesDropDownPng} alt="Filter species dropdown" />
        </p>
        <p>
          <img src={filterSpeciesDropDownFilteredPng} alt="Filter species dropdown filtered" />
        </p>
      </>
    ),
  },
  FISHERY_TYPE: {
    title: 'Fishery Type',
    body: (
      <>
        <p>
          When the &quot;Fishery Type&rdquo; filter is selected,
          the data is filtered to show only the trips with the selected Fishery Type.
        </p>
        <p>
          User can select one of the Fishery Type from the dropdown list.
        </p>
        <p>
          <img src={filterFisheryTypePng} alt="Filter fishery type" />
        </p>
      </>
    ),
  },
  GEAR_TYPE: {
    title: 'Gear Type',
    body: (
      <>
        <p>
          When the &quot;Gear Type&rdquo; filter is selected,
          the data is filtered to show only the trips with the selected Gear Type.
        </p>
        <p>
          User can select one of the Gear Type from the dropdown list.
        </p>
        <p>
          <img src={filterGearTypePng} alt="Filter gear type" />
        </p>
      </>
    ),
  },
  MESH_SIZE: {
    title: 'Mesh Size',
    body: (
      <>
        <p>
          When the &quot;Mesh Size&rdquo; filter is selected,
          the data is filtered to show only the trips that meet the size restrictions defined.
        </p>
        <p>
          User can leave the &quot;To&rdquo; or &quot;From&rdquo; fields empty
          to filter the data without a restriction for that value.
        </p>
        <p>
          <img src={filterMeshSizePng} alt="Filter mesh size" />
        </p>
      </>
    ),
  },
  ACTIVITY_TYPE_AND: {
    title: 'Activity type (AND)',
    body: (
      <>
        <p>
          When the &quot;Activity Type (AND)&quot; filter is selected,
          the data is filtered to show only the trips containing all the activity
          types listed in the filter
        </p>
        <p>
          <img src={filterActivityTypeAndPng} alt="Filter activity type and" />
        </p>
        <p>
          User can select as many Activity Types as desired
        </p>
        <p>
          <img src={filterActivityTypeAndSelection} alt="Filter activity type and selection" />
        </p>
        <p>
          Once an Activity Type has been added to the filter, it can be removed
          by clicking on the &quot;x&quot; button besides
          that activity type
        </p>
      </>
    ),
  },
  ACTIVITY_TYPE_OR: {
    title: 'Activity type (OR)',
    body: (
      <>
        <p>
          When the &quot;Activity Type (OR)&quot; filter is selected,
          the data is filtered to show only the trips containing
          {' '}
          <b>at least one</b>
          {' '}
          of the activity types listed in the filter
        </p>
        <p>
          <img src={filterActivityTypeOrPng} alt="Filter activity type or" />
        </p>
        <p>
          User can select as many Activity Types as desired
        </p>
        <p>
          <img src={filterActivityTypeOrSelection} alt="Filter activity type or selection" />
        </p>
        <p>
          Once an Activity Type has been added to the filter, it can be removed
          by clicking on the &quot;x&quot; button besides
          that activity type
        </p>
      </>
    ),
  },
  REPORT_TYPE: {
    title: 'Report type',
    body: (
      <>
        <p>
          When the &quot;Report type&rdquo; filter is selected,
          the data is filtered to show only the trips with the selected Report type.
        </p>
        <p>
          User can select one of the Report types from the dropdown list.
        </p>
        <p>
          <img src={filterReportTypePng} alt="Filter report type" />
        </p>
      </>
    ),
  },
};

export const SEARCH = (
  <div className="subSection">
    <p>
      On the &quot;SEARCH&rdquo; tab, click on the &quot;Add Filter&rdquo;
      dropdown to display the list of available filters.
    </p>
    <p>
      <img src={chooseFilter} alt="chooseFilterFilter" />
    </p>
    <p>
      User can type the filter name in the search box to narrow down the list of available filters.
    </p>
    <p>
      <img src={chooseFilterFilter} alt="chooseFilterFilter" />
    </p>
    <p>
      Once a filter is selected, the filter is added to the list of enabled filters.
    </p>
    <p>
      <img src={filterFlagstate} alt="filterFlagstate" />
    </p>
    <p>
      To apply the enabled filters, click on the &quot;Submit&rdquo; button.
    </p>
    <p>
      To clear a filter, click on the checkbox next to the filter;
      an unchecked filter means that it is disabled.
      When clicking on the &quot;Submit&rdquo; button,
      disabled filter is not included in the search parameters,
      and they are cleared from the list of filters displayed in this panel.
    </p>
    <p>
      To clear all the filters, click on the &quot;Reset&rdquo; button.
    </p>
    <h5>Types of filters</h5>

    <AccordionSection
      sections={
        [
          FILTERS.ONLY_LAST_TRIP,
          FILTERS.TIME_FRAME,
          FILTERS.TRIP_ID,
          FILTERS.VESSEL_GROUP,
          FILTERS.VESSEL_SEARCH,
          FILTERS.SPECIES,
          FILTERS.FISHERY_TYPE,
          FILTERS.GEAR_TYPE,
          FILTERS.MESH_SIZE,
          FILTERS.ACTIVITY_TYPE_AND,
          FILTERS.ACTIVITY_TYPE_OR,
          FILTERS.REPORT_TYPE,
        ]
      }
    />
  </div>
);

export default SEARCH;
