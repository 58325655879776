import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import {
  FISHING_OPERATION_SUBACTIVITIES_PROPTYPES,
} from '../../constants';
import WrapperModal from '../Modals/components/WrapperModal';
import BasicTable from '../tables/BasicTable';

import Catch from './Catches';
import FishingGear from './FishingGears';
import GearProblems from './GearProblems';
import Location from './Location';
import OtherCharacteristics from './OtherCharacteristics';
import RelatedVessel from './RelatedVessels';

import { formatDateTime } from '@common/dateUtils';
import { isNilOrEmpty } from '@common/utils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const getTableHeaders = (t) => [
  { text: t(schema.identification), code: 'identification', sortable: false },
  { text: t(schema.type), code: 'type', sortable: false },
  {
    text: t(schema.ocurrence), code: 'ocurrence', render: (row) => (formatDateTime(row.ocurrence)), sortable: false,
  },
  {
    text: t(schema.start), code: 'start', render: (row) => (formatDateTime(row.start)), sortable: false,
  },
  {
    text: t(schema.end), code: 'end', render: (row) => (formatDateTime(row.end)), sortable: false,
  },
  {
    text: t(schema.duration), code: 'duration', render: (row) => (row.duration ? `${row.duration} (${row.unitcode})` : ''), sortable: false,
  },
];

const getSubActivitiesTableHeaders = (t) => [
  { text: t(schema.type), code: 'type', sortable: false },
];

const SubActivities = ({ subActivities, reportType = '' }) => {
  const { t } = useTranslation();
  const tableHeaders = useMemo(() => getTableHeaders(t), [t]);
  const subActivitiesTableHeaders = useMemo(() => getSubActivitiesTableHeaders(t), [t]);
  const [location, setLocation] = useState(null);
  const [fishingGear, setFishingGear] = useState(null);
  const [gearProblems, setGearProblems] = useState(null);
  const [characteristics, setCharacteristics] = useState(null);
  const [relatedVessel, setRelatedVessel] = useState(null);
  const [catches, setCatches] = useState(null);
  const [otherCharacteristics, setOtherCharacteristics] = useState(null);

  const locationHandler = useCallback((row) => {
    setLocation(row?.location);
  }, []);
  const fishingGearHandler = useCallback((row) => {
    setFishingGear(row?.fishingGear);
  }, []);
  const gearProblemsHandler = useCallback((row) => {
    setGearProblems(row?.gearProblems);
  }, []);
  const characteristicsHandler = useCallback((row) => {
    setCharacteristics(row?.otherCharacteristics);
  }, []);

  const relatedVesselHandler = useCallback((row) => {
    setRelatedVessel(row?.relatedVessel);
  }, []);
  const catchHandler = useCallback((row) => {
    setCatches(row?.catches);
  }, []);
  const otherCharacteristicsHandler = useCallback((row) => {
    setOtherCharacteristics(row?.otherCharacteristics);
  }, []);

  const fishingOperationActions = useMemo(() => [
    { label: 'Location', action: locationHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.location) },
    { label: 'Fishing Gear', action: fishingGearHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.fishingGear) },
    { label: 'Gear Problems', action: gearProblemsHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.gearProblems) },
    { label: 'Characteristics', action: characteristicsHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.otherCharacteristics) },
  ], []);

  const joinFishingOperationActions = useMemo(() => [
    { label: 'Related Vessel', action: relatedVesselHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.relatedVessel) },
    { label: 'Catch', action: catchHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.catches) },
    { label: 'Other Characteristics', action: otherCharacteristicsHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.otherCharacteristics) },
  ], []);

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <BasicTable
            data={subActivities}
            headers={reportType === 'fishingOperation'
              ? tableHeaders
              : subActivitiesTableHeaders}
            actions={reportType === 'fishingOperation'
              ? fishingOperationActions
              : joinFishingOperationActions}
          />
        </Col>
      </Row>

      <WrapperModal
        id="wrapper-modal-location"
        showModal={location}
        setShowModal={setLocation}
        headerTitle="Location"
        centered
        animation={false}
        size="xl"
      >
        {location && <Location location={location} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-fishingGear"
        showModal={fishingGear}
        setShowModal={setFishingGear}
        headerTitle="Fishing Gear"
        centered
        animation={false}
        size="xl"
      >
        {fishingGear && <FishingGear gears={fishingGear} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-gearProblems"
        showModal={gearProblems}
        setShowModal={setGearProblems}
        headerTitle="Gear Problems"
        centered
        animation={false}
        size="xl"
      >
        {gearProblems && <GearProblems gearProblems={gearProblems} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-characteristics"
        showModal={characteristics}
        setShowModal={setCharacteristics}
        headerTitle="Characteristics"
        centered
        animation={false}
        size="xl"
      >
        {characteristics && <OtherCharacteristics characteristics={characteristics} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-relatedVessel"
        showModal={relatedVessel}
        setShowModal={setRelatedVessel}
        headerTitle="Related Vessel"
        centered
        animation={false}
        size="xl"
      >
        {relatedVessel && <RelatedVessel relatedVessels={relatedVessel} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-catch"
        showModal={catches}
        setShowModal={setCatches}
        headerTitle="Catch"
        centered
        animation={false}
        size="xl"
      >
        {catches && <Catch catches={catches} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-otherCharacteristics"
        showModal={otherCharacteristics}
        setShowModal={setOtherCharacteristics}
        headerTitle="Other Characteristics"
        centered
        animation={false}
        size="xl"
      >
        {otherCharacteristics && <OtherCharacteristics characteristics={otherCharacteristics} />}
      </WrapperModal>
    </Container>
  );
};

SubActivities.propTypes = {
  subActivities: FISHING_OPERATION_SUBACTIVITIES_PROPTYPES.isRequired,
  reportType: PropTypes.string,
};

SubActivities.defaultProps = {
  reportType: '',
};

export default SubActivities;
