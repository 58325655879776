import exportButton from './img/export_button.png';
import modalExport from './img/modal_export.png';

const SectionExport = () => (
  <div className="subSection">
    <p>
      Using the &quot;Export to excel&quot; button
      (located below the vessels grid, at the bottom right corner of the screen),
      an Excel file is generated with all the vessels resulting from the search performed.
    </p>
    <p>
      <img src={exportButton} alt="exportButton" />
    </p>
    <p>
      While the file of vessels is being generated,
      the task can be forced to be ran in background
      by clicking the button &quot;Run this task in background&quot;.
      This unblocks the modal window so the user can perform other actions in the application.
    </p>
    <p>
      <img src={modalExport} alt="modalExport" />
    </p>
  </div>
);

export default SectionExport;
