import PropTypes from 'prop-types';
import { createContext, useState, useContext } from 'react';

const SideFilterContext = createContext();

function SideFilterContextProvider({ children }) {
  const [sideFilterState, setSideFilterState] = useState({
    choosedFilters: [],
    activeFilters: [],
    submitedFilters: {},
    resetFilters: false,
  });
  const value = {
    sideFilterState, setSideFilterState,
  };
  return (
    <SideFilterContext.Provider value={value}>
      {children}
    </SideFilterContext.Provider>
  );
}

function useSideFilterContext() {
  const context = useContext(SideFilterContext);

  if (context === undefined) {
    throw new Error('sideFilterState must be used within a SideFilterContextProvider');
  }

  return context;
}

SideFilterContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export {
  useSideFilterContext,
  SideFilterContextProvider,
};
