import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { TRANSHIPMENT_PROPTYPES, TRANSHIPMENT_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const Transhipment = ({ transhipment, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(TRANSHIPMENT_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentTranshipment, setCurrentTranshipment] = useState([]);

  useEffect(() => setCurrentTranshipment((transhipment && transhipment[pageNumber - 1])
    || TRANSHIPMENT_SCHEMA),
  [transhipment, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        versions={currentTranshipment}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={transhipment}
        onSelectPagination={setPageNumber}
      />

      <Row>
        <Col md={3} sm={4} xs={12}>
          <TextInputField label={t('FLAP Document')} value={currentVersion?.flapDocument} />
          <TextInputField label={t(schema.identification)} value={currentVersion?.identification} />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <TextInputField
            label={t(schema.start)}
            value={currentVersion?.startDate && `${formatDateTime(currentVersion?.startDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <TextInputField
            label={t(schema.end)}
            value={currentVersion?.endDate && `${formatDateTime(currentVersion?.endDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <TextInputField label={t(schema.duration)} value={currentVersion?.duration} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

Transhipment.propTypes = {
  transhipment: TRANSHIPMENT_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default Transhipment;
