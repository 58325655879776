import actions from './img/actions.png';
import oneVersion from './img/one_version.JPG';
import selectVersion from './img/select_version.JPG';
import twoVersions from './img/two_versions.JPG';

const SectionCompareVersions = () => (
  <div className="subSection">
    <p>
      To compare two versions of a vessel, from the grid of vessels,
      in the column ‘Actions’ it is available the option with name &quot;Show historical&quot;.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      If the option &quot;Show historical&quot; is clicked,
      a new modal window opens containing a selector of versions.
      The user can select a version of the vessel by the date it was updated:
    </p>
    <p>
      <img src={selectVersion} alt="Select Version" />
    </p>
    <p>
      When clicking on a version, below it is displayed the information
      of the vessel corresponding to the version selected.
      The information is shown as a list to enable the comparison between consecutive versions.
      Also, it is shown the source of the update.
    </p>
    <p>
      <img src={oneVersion} alt="One Version" />
    </p>
    <p>
      By clicking the “Compare to another version” button,
      another column is displayed in the modal window so the
      user can compare two consecutive moments of a vessel.
      Attributes that have different values between versions are highlighted.
      For simplicity, two arrows besides the versions compared allow the user
      to browse between consecutive versions (previous or next depending on the arrow selected)
      of the currently compared ones. If there are no more versions available for comparison,
      the corresponding arrow is shadowed in grey.
    </p>
    <p>
      <img src={twoVersions} alt="Two Versions" />
    </p>
  </div>
);

export default SectionCompareVersions;
