/* eslint-disable import/order */

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom';
import { showErrorNotification } from '@common/utilities/Notification';
import { useBaseMessageService } from '@services/messages/BaseMessageContext';
import ModalComponent from '../ModalComponent/ModalComponent';
import ModalResponseDetails from '../ModalResponseDetails/ModalResponseDetails';

const BRStatusButton = ({
  message, isIncoming, statusResult, isResponseStatus, domain,
}) => {
  const [show, setShow] = useState(false);
  const [validationDetails, setValidationDetails] = useState([]);
  const { services: Services } = useBaseMessageService();
  const typeMessage = isIncoming === true ? 'incoming' : 'outgoing';

  const statusConditionModal = ['ERROR', 'WARNING', 'NOK', 'WOK', 'FAILED', 'OK'];

  const handleShowModal = useCallback(async () => {
    if (statusResult === 'WARNING' || statusResult === 'ERROR' || statusResult === 'FAILED' || statusResult === 'OK') {
      let query = null;
      if (isResponseStatus) {
        query = await Services.apiFetchMessageResponseDetails(typeMessage, message.id, domain);
      } else {
        query = await Services.apiFetchMessageValidationDetails(typeMessage, message.id);
      }
      if (query.ok) {
        const data = await query.json();
        unstableBatchedUpdates(() => {
          setValidationDetails(data);
          setShow(true);
        });
      } else {
        showErrorNotification();
      }
    }
  }, [show]);

  const CHOICE_COLOR_BUTTON = {
    ERROR: 'dot-error',
    NOK: 'dot-waiting',
    WARNING: 'dot-warning',
    WOK: 'dot-warning',
    OK: 'dot-ok',
    FAILED: 'dot-error',
    PENDING: 'dot-waiting',
    INACTIVE: 'dot-inactive',
  };

  return (
    <Row className="justify-content-end w-100">
      <Card
        variant="secondary"
        size="sm"
        className="p-1 flex-row"
        as={Row}
        onClick={() => handleShowModal()}
      >
        <p className="my-auto mr-1">BR</p>
        <span className={`ml-1 ${CHOICE_COLOR_BUTTON[statusResult]} align-self-end m-auto`} />
      </Card>
      {(statusConditionModal.includes(statusResult))
            && (isResponseStatus
              ? (
                <ModalResponseDetails
                  show={show}
                  setShow={setShow}
                  message={{ ...message, validationDetails }}
                />
              )
              : (
                <ModalComponent
                  show={show}
                  setShow={setShow}
                  message={{ ...message, validationDetails }}
                />
              )
            )}
    </Row>
  );
};
BRStatusButton.propTypes = {
  statusResult: PropTypes.string.isRequired,
  isIncoming: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    creationDate: PropTypes.string,
    dataDomain: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
    id: PropTypes.number,
    idType: PropTypes.number,
    identification: PropTypes.string,
    isIncoming: PropTypes.bool,
    message: PropTypes.string,
    recipient: PropTypes.string,
    relatedMessages: PropTypes.arrayOf(PropTypes.any),
    reportStatus: null,
    responseStatus: null,
    sender: PropTypes.string,
    status: null,
    type: PropTypes.shape({ id: PropTypes.number, description: PropTypes.string }),
    uuid: PropTypes.string,
    validationResults: PropTypes.string,
  }).isRequired,
  isResponseStatus: PropTypes.bool,
  domain: PropTypes.number,
};

BRStatusButton.defaultProps = {
  isResponseStatus: false,
  domain: null,
};

export default BRStatusButton;
