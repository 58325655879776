import {
  compose, map, prop,
} from 'ramda';

export const mapOptions = ({ options = [], labelPropName = 'description', idName = 'id' }) => (options ? compose(
  map((x) => (
    {
      label: prop(labelPropName)(x),
      value: prop(idName)(x),
    }
  )),
)(options) : []);

export const mapOptionsGroupBy = ({
  options = [], labelPropName = 'description', idName = 'id',
}) => {
  const groups = [...new Set(options.map((obj) => obj.groupBy))];
  return groups.map((group) => {
    const itemsFilteredByGroup = options.filter((option) => option.groupBy === group);
    return {
      label: group,
      options: compose(
        map((x) => (
          {
            label: prop(labelPropName)(x),
            value: prop(idName)(x),
          }
        )),
      )(itemsFilteredByGroup),
    };
  });
};

export const mapOptionsTrips = ({ options = [], idName = 'id' }) => compose(
  map((x) => (
    {
      label: x.euTripId || x.neafcTripId,
      value: prop(idName)(x),
    }
  )),
)(options || []);

export const optionToValue = (option) => +option.value;
export const optionsValues = (multiSelectOptions) => multiSelectOptions
    && multiSelectOptions.map(optionToValue);

export const selectedOptions = (
  options, selectedIds,
) => options.filter(
  (option) => selectedIds && selectedIds.includes(option.value),
);

export const selectedOptionsGroupBy = (
  options, selectedIds,
) => options && options.length === 2 && [...options[0].options, ...options[1].options].filter(
  (option) => selectedIds && selectedIds.includes(option.value),
);

export const VALID_COLOR = '#28a745';
export const INVALID_COLOR = '#dc3545';
export const selectStyles = (valid) => ({
  control: (provided, state) => {
    const color = valid ? VALID_COLOR : INVALID_COLOR;
    return {
    // none of react-select's styles are passed to <Control />
      ...provided,
      borderColor: color,
      '&:hover': { borderColor: `${color}99` },
      ...(state.isFocused && { boxShadow: `0 0 0 0.2rem ${color}3d` }),
    };
  },

});

export const defaultStyles = () => ({
  groupHeading: (provided) => ({
    ...provided,
    color: 'white',
    background: 'grey',
    lineHeight: '30px',
    height: '30px',
  }),
});

export const selectUtils = {
  mapOptions,
  optionsValues,
  selectedOptions,
  selectStyles,
  mapOptionsTrips,
  defaultStyles,
  mapOptionsGroupBy,
  selectedOptionsGroupBy,
};
