import PropTypes from 'prop-types';
import React from 'react';

const If = ({ conditional, children }) => React.Children.map(children,
  (child) => React.cloneElement(child, { conditional }));

const Then = ({ conditional, children }) => (conditional ? <>{children}</> : <></>);

const Else = ({ conditional, children }) => (!conditional ? <>{children}</> : <></>);

If.propTypes = {
  conditional: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.shape({})]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Then.propTypes = {
  conditional: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.shape({})]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
Then.defaultProps = {
  conditional: true,
};

Else.propTypes = {
  conditional: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.shape({})]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
Else.defaultProps = {
  conditional: true,
};

export { If, Else, Then };
