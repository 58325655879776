import PropTypes from 'prop-types';
import React from 'react';
import {
  Form, OverlayTrigger, Tooltip,
} from 'react-bootstrap';

import './style.scss';

const CheckAll = ({
  onSelect,
  isSelected,
  disabled,
  label,
}) => {
  const handleChange = (event) => {
    const { checked } = event.target;
    onSelect && onSelect(checked);
  };
  return (
    <Form.Group className="my-auto d-flex">
      <OverlayTrigger
        delay={{ show: 500 }}
        placement="top"
        overlay={(
          <Tooltip id="tooltip">
            {isSelected ? 'Deselect' : 'Select'}
            {' '}
            all items on this page
          </Tooltip>
)}
      >
        <>
          <Form.Label className="m-0" style={{ color: 'white' }}>{label}</Form.Label>
          <Form.Check type="checkbox" onChange={handleChange} checked={isSelected} disabled={disabled} />
        </>
      </OverlayTrigger>
    </Form.Group>
  );
};
CheckAll.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
CheckAll.defaultProps = {
  onSelect: null,
};

export default CheckAll;
