import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';
import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';

import {
  addFormatDate, BACK_DATE_FORMAT, subtractFormatDate,
} from '@common/dateUtils';
import { RealmRole } from '@common/keycloakFunctions';
import { DOMAIN } from '@common/utils';

const schema = tSchema.dmaAdmin.queryReportMGMT.createEditView;

const QueryParametersFieldGroup = ({
  isTouched, isReport, disabled,
  errors, handleChange, values,
  fluxPartiesOptions, selectedDomain,
  vesselQueryTypeOptions, vesselStatusOptions,
  maximumDaysToQuery,
}) => {
  const { t } = useTranslation();
  const { keycloak } = useKeyCloak();
  const isVesselQueryTypeNormal = useMemo(
    () => +values?.vesselQueryType === 2,
    [values?.vesselQueryType],
  );
  const dataScopeFromTo = values.dataScopeType === 'fromTo';
  const dataScopeLastDays = values.dataScopeType === 'lastDays';

  const getMaxDateFrom = () => {
    const maximumDateToQuery = addFormatDate(dayjs(), BACK_DATE_FORMAT, maximumDaysToQuery, 'days');
    if (!values.dataScopeFromTo?.to
      || dayjs(maximumDateToQuery).isBefore(dayjs(values.dataScopeFromTo?.to))) {
      return maximumDateToQuery;
    }
    return values.dataScopeFromTo?.to;
  };

  const getMinDateFrom = () => {
    const minimumDateToQuery = subtractFormatDate(dayjs(), BACK_DATE_FORMAT, maximumDaysToQuery, 'days');
    return minimumDateToQuery;
  };

  const getMaxDateTo = () => {
    const maximumDateToQuery = addFormatDate(dayjs(), BACK_DATE_FORMAT, maximumDaysToQuery, 'days');
    return maximumDateToQuery;
  };

  const getMinDateTo = () => {
    const minimumDateToQuery = subtractFormatDate(dayjs(), BACK_DATE_FORMAT, maximumDaysToQuery, 'days');
    if (!values.dataScopeFromTo?.from
      || dayjs(minimumDateToQuery).isAfter(dayjs(values.dataScopeFromTo?.from))) {
      return minimumDateToQuery;
    }
    return values.dataScopeFromTo?.from;
  };

  const isDataAdmin = useMemo(() => keycloak.token && keycloak.hasRealmRole(RealmRole.DATA_ADMIN),
    [keycloak]);
  const isSystemAdmin = useMemo(() => keycloak.token
    && keycloak.hasRealmRole(RealmRole.SYSTEM_ADMIN), [keycloak]);

  return (
    <FieldGroup label={t(schema.queryParameters)}>
      <Form.Group as={Col} className="p-0" hidden={isVesselQueryTypeNormal}>
        <Form.Label>
          {t(schema.dataScope)}
        </Form.Label>
        <Form.Row>
          <Form.Group>
            <Form.Check
              inline
              id="dataScopeType-option-1"
              value="fromTo"
              disabled={disabled}
              checked={dataScopeFromTo}
              name="dataScopeType"
              type="radio"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formAccessPeriod">

            <Form.Row>
              <Form.Group as={Col} controlId="formDataScopeFromTo.from">
                <Form.Label>
                  {t(schema.from)}
                </Form.Label>
                <InputDate
                  name="dataScopeFromTo.from"
                  id="dataScopeFromTo.from"
                  value={values.dataScopeFromTo?.from}
                  disabled={!dataScopeFromTo || disabled}
                  isValid={isTouched && dataScopeFromTo
                    ? !errors.dataScopeFromTo
                    || (errors.dataScopeFromTo && !errors.dataScopeFromTo?.from) : undefined}
                  maxDate={getMaxDateFrom()}
                  minDate={getMinDateFrom()}
                  onChange={(id, value) => handleChange({ target: { name: id, value } })}
                  complex={false}
                  hasAction={false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dataScopeFromTo?.from}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formDataScopeFromTo.to">
                <Form.Label>{t(schema.to)}</Form.Label>
                <InputDate
                  name="dataScopeFromTo.to"
                  id="dataScopeFromTo.to"
                  value={values.dataScopeFromTo?.to}
                  disabled={!dataScopeFromTo || disabled}
                  isValid={isTouched && dataScopeFromTo
                    ? !errors.dataScopeFromTo
                    || (errors.dataScopeFromTo && !errors.dataScopeFromTo?.to) : undefined}
                  minDate={getMinDateTo()}
                  maxDate={getMaxDateTo()}
                  onChange={(id, value) => handleChange({ target: { name: id, value } })}
                  complex={false}
                  hasAction={false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dataScopeFromTo?.to}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <InputGroup as={Col} className="d-inline-flex ml-0" controlId="formLastDays">
            <Form.Check
              inline
              id="dataScopeType-option-2"
              value="lastDays"
              checked={dataScopeLastDays}
              name="dataScopeType"
              type="radio"
              onChange={handleChange}
            />
            <InputGroup.Text style={{ borderRadius: '.25rem 0 0 .25rem' }}>
              {t(schema.last)}
            </InputGroup.Text>
            <Form.Control
              required
              type="number"
              isValid={!dataScopeFromTo && isTouched && !errors.dataScopeLastDays}
              name="dataScopeLastDays"
              isInvalid={isTouched && errors.dataScopeLastDays}
              value={values.dataScopeLastDays}
              disabled={dataScopeFromTo || disabled}
              onChange={handleChange}
            />
            <InputGroup.Text style={{ borderRadius: '0 .25rem .25rem 0' }}>
              {t(schema.days)}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.dataScopeLastDays}
            </Form.Control.Feedback>
          </InputGroup>

        </Form.Row>

      </Form.Group>

      { !isReport && selectedDomain === DOMAIN && (
      <Form.Row className="ml-1">
        <Form.Group as={Col} controlId="formBasicCheckbox" className="justify-content-center pl-0">
          <Form.Row controlId="formConsolidatedQuery">
            <Form.Check
              disabled={disabled}
              type="checkbox"
              name="consolidated"
              checked={values.consolidated}
              label={t(schema.consolidatedQuery)}
              onChange={handleChange}
            />
          </Form.Row>
          <Form.Control.Feedback type="invalid">
            {errors.consolidated}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      )}
      {isReport && selectedDomain === DOMAIN.FA && (
        <Form.Group as={Col} controlId="formOriginalSender" className="pl-0 pr-0">
          <Form.Label>
            {t(schema.originalSender)}
          </Form.Label>
          <Form.Control
            required
            as="select"
            name="originalSender"
            isValid={isTouched && !errors.originalSender}
            isInvalid={isTouched && errors.originalSender}
            value={values.originalSender}
            disabled={disabled}
            onChange={handleChange}
          >
            {fluxPartiesOptions}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors?.originalSender}
          </Form.Control.Feedback>
        </Form.Group>
      )}
      {selectedDomain === DOMAIN.VESSEL && (
        <>
          <Form.Group as={Col} controlId="formVesselQueryType" className="pl-0 pr-0">
            <Form.Label>
              {t(schema.queryType)}
            </Form.Label>
            <Form.Control
              required
              as="select"
              name="vesselQueryType"
              isValid={isTouched && !errors.vesselQueryType}
              isInvalid={isTouched && errors.vesselQueryType}
              value={values.vesselQueryType}
              disabled={(!isDataAdmin && !isSystemAdmin) || disabled}
              onChange={handleChange}
            >
              {vesselQueryTypeOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors?.vesselQueryType}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formVesselStatus" className="pl-0 pr-0">
            <Form.Label>
              {t(schema.vesselStatus)}
            </Form.Label>
            <Form.Control
              required
              as="select"
              name="vesselStatus"
              isValid={isTouched && !errors.vesselStatus}
              isInvalid={isTouched && errors.vesselStatus}
              value={values.vesselStatus}
              disabled={disabled}
              onChange={handleChange}
            >
              {vesselStatusOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors?.vesselStatus}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}
      <Form.Group as={Col} controlId="formReceiver" className="pl-0 pr-0">
        <Form.Label>
          {t(schema.receiver)}
        </Form.Label>
        <Form.Control
          required
          as="select"
          name="receiver"
          isValid={isTouched && !errors.receiver}
          isInvalid={isTouched && errors.receiver}
          value={values.receiver}
          disabled={disabled || selectedDomain === DOMAIN.VESSEL}
          onChange={handleChange}
        >
          { selectedDomain === DOMAIN.VESSEL
            ? (
              <option>
                {t(tSchema.dmaAdmin.queryReportMGMT.query.commission)}
              </option>
            )
            : fluxPartiesOptions}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors?.receiver}
        </Form.Control.Feedback>
      </Form.Group>
    </FieldGroup>
  );
};

const arrayIdDescriptionPropType = PropTypes.arrayOf(PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}));

QueryParametersFieldGroup.defaultProps = {
  isTouched: false,
  isReport: false,
  maximumDaysToQuery: null,
};

QueryParametersFieldGroup.propTypes = {
  fluxPartiesOptions: arrayIdDescriptionPropType.isRequired,
  vesselQueryTypeOptions: arrayIdDescriptionPropType.isRequired,
  vesselStatusOptions: arrayIdDescriptionPropType.isRequired,
  errors: PropTypes.shape({
    vesselIdType: PropTypes.string,
    originalSender: PropTypes.string,
    receiver: PropTypes.string,
    vesselQueryType: PropTypes.string,
    vesselCountry: PropTypes.string,
    vesselStatus: PropTypes.string,
    dataScopeType: PropTypes.string,
    dataScopeFromTo: PropTypes.shape(
      {
        from: PropTypes.string,
        to: PropTypes.string,
      },
    ),
    dataScopeLastDays: PropTypes.string,
    consolidated: PropTypes.string,

  }).isRequired,
  values: PropTypes.shape({
    type: PropTypes.number,
    vesselIdType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    receiver: PropTypes.string,
    vesselQueryType: PropTypes.string,
    vesselCountry: PropTypes.string,
    vesselStatus: PropTypes.string,
    queryType: PropTypes.string,
    dataScopeType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    originalSender: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })), PropTypes.string]),
    dataScopeFromTo: PropTypes.shape(
      {
        from: PropTypes.string,
        to: PropTypes.string,
      },
    ),
    dataScopeLastDays: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    consolidated: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  isTouched: PropTypes.bool,
  isReport: PropTypes.bool,
  selectedDomain: PropTypes.string.isRequired,
  maximumDaysToQuery: PropTypes.number,
};

export default QueryParametersFieldGroup;
