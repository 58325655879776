import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import * as failoverApiService from '@services/failover/api';

import ConfigContext from '@contexts/config';

const ConfigLoaderProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [config, setConfig] = useState({ timeOut: 10000 });
  const { keycloak } = useKeyCloak();

  useEffect(() => {
    if (keycloak.token) {
      failoverApiService.getParameter({ parameter: 'customUserTimeout' }).then((res) => {
        setConfig((prevConfig) => ({ ...prevConfig, timeOut: res.data.value }));
        setLoaded(true);
      });
    }
  }, [keycloak]);

  useEffect(() => {
    axios.defaults.timeout = config.timeOut;
  }, [config.timeOut]);

  const setConfigValue = useCallback(
    (attribute, value) => {
      setConfig((prevConfig) => ({ ...prevConfig, [attribute]: value }));
    },
    [],
  );

  if (!loaded) return null;

  return (
    <ConfigContext.Provider value={{ config, setConfigValue }}>
      {children}
    </ConfigContext.Provider>
  );
};

ConfigLoaderProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ConfigLoaderProvider;
