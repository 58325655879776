import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Col } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';
import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

import { millisToHourMinutes } from '@common/dateUtils';

const schema = tSchema.dmaAdmin.queryReportMGMT.createEditView;

const TypeFieldGroup = ({
  isTouched, isScheduled, errors, values, handleChange, setFieldValue, disabled,
  type, recurrenceOptions, statusOptions,
}) => {
  const { t } = useTranslation();
  const onTypeChangeHandler = useCallback((event) => {
    const value = +event.currentTarget?.value;
    // When type is scheduled, set queryType to Vessel
    value === 1 && setFieldValue('queryType', 1);
    // When type is scheduled, set dataScopeType to 'lastDays'
    value === 1 && setFieldValue('dataScopeType', 'lastDays');
    setFieldValue('type', value);
  });
  return (
    <FieldGroup label={t(schema.type)}>
      <Form.Row>
        <Form.Group as={Col}>
          {type
          && type.map((e) => (
            <Form.Check
              inline
              key={`key-query-${e.description}`}
              id={`type-option-${e.description}`}
              label={t(schema.typeOptions[e.id])}
              value={e.id}
              checked={values.type === e.id}
              name="type"
              type="radio"
              onChange={onTypeChangeHandler}
              disabled={disabled}
            />
          ))}
        </Form.Group>
      </Form.Row>
      {isScheduled && (
      <>
        <Form.Row>
          <Form.Group as={Col} controlId="schedulingOptions.startDate">
            <Form.Label>
              {t(schema.startingDate)}
            </Form.Label>
            <InputDate
              name="schedulingOptions.startDate"
              id="schedulingOptions.startDate"
              value={values.schedulingOptions?.startDate}
              disabled={disabled}
              isValid={isTouched ? !errors.schedulingOptions?.startDate : undefined}
              maxDate={values.schedulingOptions?.endDate}
              onChange={(id, value) => handleChange({ target: { name: id, value } })}
              complex={false}
              hasAction={false}
            />
            <Form.Control.Feedback type="invalid">
              {errors.schedulingOptions?.startDate}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formSchedulingOptionsExecutionTime">
            <Form.Label>
              {t(schema.executionTime)}
            </Form.Label>
            <TimePicker
              className={isTouched && (errors.schedulingOptions?.executionTime ? 'is-invalid' : 'is-valid')}
              name="schedulingOptions.executionTime"
              initialValue="00:00"
              value={values.schedulingOptions?.executionTime}
              start="00:00"
              end="23:59"
              step={15}
              disabled={disabled}
              onChange={(value) => setFieldValue('schedulingOptions.executionTime', millisToHourMinutes(value))}
            />
            <Form.Control.Feedback type="invalid">
              {errors.schedulingOptions?.executionTime}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row className="inline">
          <Form.Label column lg={3}>
            {t(schema.recurrence)}
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              isValid={isTouched
                                && !errors.schedulingOptions?.recurrence}
              name="schedulingOptions.recurrence"
              isInvalid={isTouched
                                && errors.schedulingOptions?.recurrence}
              value={values.schedulingOptions?.recurrence}
              disabled={disabled}
              onChange={handleChange}
            >
              {recurrenceOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.schedulingOptions?.recurrence}</Form.Control.Feedback>
          </Col>
        </Form.Row>
        <Form.Row className="inline">
          <Form.Label column lg={3}>
            {t(schema.endDate)}
          </Form.Label>
          <Col>
            <InputDate
              name="schedulingOptions.endDate"
              id="schedulingOptions.endDate"
              value={values.schedulingOptions?.endDate}
              disabled={disabled}
              isValid={isTouched ? !errors.schedulingOptions?.endDate : undefined}
              minDate={values.schedulingOptions?.startDate}
              onChange={(id, value) => handleChange({ target: { name: id, value } })}
              complex={false}
              hasAction={false}
            />
            <Form.Control.Feedback type="invalid">
              {errors.schedulingOptions?.endDate}
            </Form.Control.Feedback>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Label column lg={3}>
            {t(schema.status)}
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              isValid={isTouched
                                && !errors.schedulingOptions?.status}
              name="schedulingOptions.status"
              isInvalid={isTouched
                                && errors.schedulingOptions?.status}
              value={values.schedulingOptions?.status}
              disabled={disabled}
              onChange={handleChange}
            >
              {statusOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.schedulingOptions?.status}</Form.Control.Feedback>
          </Col>
        </Form.Row>

      </>
      )}
    </FieldGroup>
  );
};

TypeFieldGroup.defaultProps = {
  isTouched: false,
  isScheduled: false,
};

TypeFieldGroup.propTypes = {
  isTouched: PropTypes.bool,
  isScheduled: PropTypes.bool,
  errors: PropTypes.shape({
    schedulingOptions: PropTypes.shape(
      {
        startDate: PropTypes.string,
        executionTime: PropTypes.string,
        recurrence: PropTypes.string,
        endDate: PropTypes.string,
        status: PropTypes.string,
      },
    ),
    type: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    schedulingOptions: PropTypes.shape(
      {
        startDate: PropTypes.string,
        executionTime: PropTypes.string,
        recurrence: PropTypes.string,
        endDate: PropTypes.string,
        status: PropTypes.string,
      },
    ),
    type: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  type: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
  })).isRequired,
  statusOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
  recurrenceOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  })).isRequired,
};

export default TypeFieldGroup;
