import SectionMessagesBR from './SectionMessagesBR/SectionMessagesBR';
import SectionMessagesContent from './SectionMessagesContent';
import SectionMessagesListIncoming from './SectionMessagesListIncoming';
import SectionMessagesListOutgoing from './SectionMessagesListOutgoing';
import SectionMessagesRelated from './SectionMessagesRelated';

const MESSAGES = {
  BR: {
    title: 'BR Validation Details',
    body: <SectionMessagesBR />,
  },
  CONTENT: {
    title: 'Message Content',
    body: <SectionMessagesContent />,
  },
  LIST_INCOMING: {
    title: 'Messages List',
    body: <SectionMessagesListIncoming />,
  },
  LIST_OUTGOING: {
    title: 'Messages List',
    body: <SectionMessagesListOutgoing />,
  },
  RELATED: {
    title: 'Related Messages List',
    body: <SectionMessagesRelated />,
  },
};

export default MESSAGES;
