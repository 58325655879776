import { valueOptions, valueOptionsLogBook } from '../../../config';

const callsMessageOptions = async ({ domainId, services }) => {
  const optionCalls = valueOptions.map(services?.apiFetchMessageFilterOptions);
  const optionCallsFromLogBook = valueOptionsLogBook.map(services?.apiFetchLogBookOptions);
  const options = await Promise.all([
    ...optionCalls,
    ...optionCallsFromLogBook,
    services?.apiFetchMessagePartiesFilterOptions(domainId),
    services?.apiFetchMessageStatusOptions('incoming'),
    services?.apiFetchMessageStatusOptions('outcoming'),
    services?.apiFetchMessageExchangeStatusOptions('outcoming'),
    services?.apiFetchMessageReportStatusOptions('outcoming'),
    services?.apiFetchMessageResponseStatusOptions('outcoming'),
    services?.apiFetchMessageTypeOption(domainId),
  ]);

  return options;
};

export default callsMessageOptions;
