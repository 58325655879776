import actions from './img/actions.png';
import reportTable from './img/reportTable.png';

const SectionReportViewer = () => (
  <div className="SectionReportViewer">
    <p>
      The grid shown should display the following columns:
      <ul>
        <li>Program: the ISR Program of the report</li>
        <li>Report ID: the human readable of the report</li>
        <li>Module: the module of the report</li>
        <li>Control Party:  the control party of the report</li>
        <li>Infringement: if there is an infringement suspicion with this report</li>
        <li>Attachments: the number of attachments linked to the report</li>
        <li>DateTime: the reference date and time of the report</li>
        <li>Sent: if the report created by the FIS party has already been sent</li>
        <li>Actions: the different possible action to trigger on this report</li>
      </ul>
    </p>
    <p>
      <img src={reportTable} alt="query grid " />
    </p>
    <p>
      <p>
        Each Report can be viewed, deleted or its object reference can be deleted.
        This latest option is only available when a Vessel Record is associated to a report,
        in which case column &quot;Object&quot; is displayed in blue.
      </p>
      <p>
        It is also possible to go directly to the attachment section using the
        {' '}
        &quot;Attach File&quot; option.
        <p>
          The attachment cannot be deleted by Reader user.
          Only Editor and Approver could add a new attachment.
        </p>
        <p>
          Another option is View Message,
          this one allows to show the message report on a modal.
        </p>
      </p>
      <p>
        To do so, the user shall select the desired option from the list provided
        when clicking the button in the column &quot;Actions&quot;.
      </p>
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
  </div>
);

export default SectionReportViewer;
