import dataActivityHistoricalCompareButtonImg from './img/data-activity-historical-compare-button.png';
import dataActivityHistoricalCompareVersionsSelectorImg from './img/data-activity-historical-compare-versions-selector.png';
import dataActivityHistoricalComparedImg from './img/data-activity-historical-compared.png';
import dataActivityHistoricalHideButtonImg from './img/data-activity-historical-hide-button.png';
import dataActivityHistoricalSelectedImg from './img/data-activity-historical-selected.png';
import dataActivityHistoricalSelectorImg from './img/data-activity-historical-selector.png';
import dataActivityHistoricalImg from './img/data-activity-historical.png';

const SectionDataActivityHistorical = () => (
  <div className="subSection">
    <p>
      In the Data Activity Grid,
      when the user clicks on &quot;Show Historical&quot; button
      (available in the &quot;Actions&quot; column for every row),
      a modal window with the corresponding vessel information is opened.
    </p>
    <p>
      <img src={dataActivityHistoricalImg} alt="dataActivityHistorical" />
    </p>
    <p>
      The user can visualize the available versions of the selected vessel
      through the &quot;Versions history&quot; selector.
    </p>
    <p>
      <img src={dataActivityHistoricalSelectorImg} alt="dataActivityHistoricalSelector" />
    </p>
    <p>
      Selecting one of the options in the &quot;Select historical&quot; dropdown
      displays the corresponding vessel version information.
      The information is displayed in several tabs.
    </p>
    <p>
      <img src={dataActivityHistoricalSelectedImg} alt="dataActivityHistoricalSelected" />
    </p>
    <p>
      If a vessel has more than one version,
      the user can compare versions through the &quot;Compare to another version&quot; button.
    </p>
    <p>
      <img src={dataActivityHistoricalCompareButtonImg} alt="dataActivityHistoricalCompareButton" />
    </p>
    <p>
      On clicking that button, the Vessel selector changes,
      allowing the user to search for the two versions of the vessel to be compared.
    </p>
    <p>
      <img src={dataActivityHistoricalCompareVersionsSelectorImg} alt="dataActivityHistoricalCompareVersionsSelector" />
    </p>
    <p>
      For every field, the values corresponding to both compared versions are displayed.
      Fields with different values are highlighted in yellow.
    </p>
    <p>
      <img src={dataActivityHistoricalComparedImg} alt="dataActivityHistoricalCompared" />
    </p>
    <p>
      To come back to the unique version selector,
      click on the &quot;Hide second version&quot; button.
    </p>
    <p>
      <img src={dataActivityHistoricalHideButtonImg} alt="dataActivityHistoricalHideButton" />
    </p>
  </div>
);

export default SectionDataActivityHistorical;
