export const businessRulesValueOptions = [
  { code: 'domain', name: 'brdomain' },
  { code: 'level', name: 'brlevel' },
  { code: 'sequence', name: 'brsubgroup' },
  { code: 'subLevel', name: 'brsublevel' },
  { code: 'typeOfEntity', name: 'brtypeofentity' },
  { code: 'status', name: 'brstatus' },
  { code: 'severity', name: 'brseverity' },
  { code: 'executionOrder', name: 'executionorder' },
  { code: 'context', name: 'brcontext' },
  { code: 'owner', name: 'browner' },
];

export default {
  businessRulesValueOptions,
};
