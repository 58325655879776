/* eslint-disable no-nested-ternary */
import xml from 'highlight.js/lib/languages/xml';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Button,
  Col, Container, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import HighlightText from '@components/HighlightText';
import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';

import tSchema from '@lang/schema';

import { useBaseMessageService } from '@services/messages/BaseMessageContext';

import { formatDateTime } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';

import './style.scss';

const formatXml = (xmlStr, tab = '\t') => {
  let formatted = '';
  let indent = '';
  xmlStr.split(/>\s*</).forEach((node) => {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length);
    formatted += `${indent}<${node}>\r\n`;
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab;
  });
  return formatted.substring(1, formatted.length - 3);
};

const flagAsText = (flag) => {
  switch (flag) {
    case 'NOK':
      return 'VALIDATING';
    case 'WARNING':
      return 'OK (with warnings)';
    case 'FAILED':
      return 'ERROR';
    default:
      return flag;
  }
};

const flagAsMessage = (flag) => {
  if (flag === 'WARNING') {
    return (
      <>
        OK
        <br />
        (with warnings)
      </>
    );
  }
  return flagAsText(flag);
};
let messagesController;
const MessageOverview = ({
  messageId,
  messageType,
  onMessageLoaded,
  onLoad,
  loading,
}) => {
  const { t } = useTranslation();
  const [messageData, setMessageData] = useState({});
  const [isLongMessage, setIsLongMessage] = useState(false);
  const requestWithLoading = useRequestWithLoading();
  const { services: Services } = useBaseMessageService();

  const downloadMessage = async () => {
    const query = await Services.apiDownloadXML(messageId, messageType);
    if (query.ok) {
      const data = await query.json();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Message.xml');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      showErrorNotification();
    }
  };

  useEffect(() => {
    messagesController && messagesController.abort();
    messagesController = new AbortController();
    requestWithLoading(async () => {
      onLoad && onLoad(true);
      const query = await Services.apiFetchMessageById(
        messageType,
        messageId,
        { signal: messagesController.signal },
      );
      if (query.ok) {
        const message = await query.json();
        setMessageData(message);
        setIsLongMessage(query.data.isTooLarge);

        onLoad && onLoad(false);
      } else if (query?.message !== 'canceled') {
        setMessageData({});
        showErrorNotification();
        onLoad && onLoad(false);
      }
    });
  }, [messageType, messageId, requestWithLoading]);

  useEffect(() => {
    onMessageLoaded && onMessageLoaded(messageData);
  }, [messageData]);

  return (
    <div className="message-overview">
      <Container fluid className="p-0">
        {messageData.validationResults && (messageData.validationResults !== 'OK' && messageData.validationResults !== 'NOK') && (
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip>
                This message has a flag
                {' '}
                <br />
                <strong>{flagAsText(messageData.validationResults)}</strong>
              </Tooltip>
            )}
          >
            <div className="not-ok-flag">
              <IconSvg
                name={`${
                  messageData.validationResults !== 'WARNING' ? 'danger' : 'warning'
                }-triangle`}
                viewBox="0 0 26 26"
              />
              <div>{flagAsMessage(messageData.validationResults)}</div>
            </div>
          </OverlayTrigger>
        )}
        {messageData.validationResults && messageData.validationResults === 'NOK' && (
          <div className="validating-flag">
            <div>{flagAsMessage(messageData.validationResults)}</div>
          </div>
        )}
        <Row>
          <Col xs={6}>
            <span className="label">{t(tSchema.dem.trafficMonitoring.sender)}</span>
            {messageData.sender}
          </Col>
          <Col xs={6}>
            <span className="label">{t(tSchema.dem.trafficMonitoring.recipient)}</span>
            {messageData.recipient}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className="label">{t(tSchema.dem.trafficMonitoring.dataDomain)}</span>
            {messageData.dataDomain && messageData.dataDomain.code}
          </Col>
          <Col xs={6}>
            <span className="label">{t(tSchema.dem.trafficMonitoring.type)}</span>
            {messageData.type && messageData.type.description}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <span className="label">
              {t(
                messageType === 'incoming' ? tSchema.dem.trafficMonitoring.dateOfReception : tSchema.dem.trafficMonitoring.sendingDate,
              )}
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>
              {messageData.creationDate
              && formatDateTime(messageData.creationDate, 'DD/MM/YYYY HH:mm:ss', true)}
            </span>
          </Col>
          <Col xs={6}>
            <span className="label">{t(tSchema.dem.trafficMonitoring.id)}</span>
            {messageData.identification}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="label">{t(tSchema.dem.trafficMonitoring.message)}</span>
            {loading ? (
              <div>
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: 1,
                  }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only">{t(tSchema.common.loading)}</span>
                  </div>
                </div>
              </div>
            ) : (
              isLongMessage
                ? (
                  <div className="toolarge-container">

                    <span>
                      The XML message is more than 1Mo and will not be displayed.
                      You can download it.

                    </span>
                    <div className="button-download">
                      <Button
                        style={{ width: '147px' }}
                        id="export-to-excel"
                        className="symbol-download"
                        onClick={downloadMessage}
                      >
                        <IconSvg name={ICON.DOWNLOAD} />
                        Download message
                      </Button>
                    </div>
                  </div>
                ) : (
                  <HighlightText language={xml} className="message">
                    {messageData.message ? formatXml(messageData.message, '    ') : ''}
                  </HighlightText>
                )

            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

MessageOverview.propTypes = {
  messageType: PropTypes.string.isRequired,
  messageId: PropTypes.number.isRequired,
  onMessageLoaded: PropTypes.func,
  onLoad: PropTypes.func,
  loading: PropTypes.bool,
};

MessageOverview.defaultProps = {
  onMessageLoaded: identity,
  onLoad: identity,
  loading: false,
};

export default MessageOverview;
