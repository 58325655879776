import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { RELOCATION_PROPTYPES, RELOCATION_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const Relocation = ({ relocation, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(RELOCATION_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentRelocation, setCurrentRelocation] = useState([]);

  useEffect(() => setCurrentRelocation((relocation && relocation[pageNumber - 1])
    || RELOCATION_SCHEMA), [relocation, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        versions={currentRelocation}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={relocation}
        onSelectPagination={setPageNumber}
      />
      <Row>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t('FLAP Document')} value={currentVersion?.flapDocument} />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t(schema.identification)} value={currentVersion?.identification} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

Relocation.propTypes = {
  relocation: RELOCATION_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default Relocation;
