import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

const schema = tSchema.dmaAdmin.queryReportMGMT.createEditView;

const GeneralFieldGroup = ({
  touched, errors, handleChange, values, disabled,
}) => {
  const { t } = useTranslation();
  return (
    <FieldGroup label={t(schema.general)}>
      <Form.Row>
        <Form.Group as={Col} controlId="formName">
          <Form.Label>
            {t(schema.name)}
          </Form.Label>
          <Form.Control
            required
            type="text"
            isValid={touched.name && !errors.name}
            name="name"
            isInvalid={touched.name && errors.name}
            value={values.name}
            disabled={disabled}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formDescription">
          <Form.Label>
            {t(schema.description)}
          </Form.Label>
          <Form.Control
            required
            type="text"
            isValid={touched.description && !errors.description}
            name="description"
            isInvalid={touched.description && errors.description}
            onChange={handleChange}
            value={values.description}
            disabled={disabled}
          />
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </FieldGroup>
  );
};
GeneralFieldGroup.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    name: PropTypes.bool,
    description: PropTypes.bool,
  }).isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default GeneralFieldGroup;
