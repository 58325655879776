import { actionCreator, get } from '../utils';

import { dispatchPromiseWithMessage } from '@common/utilities/Notification';

const createActionType = actionCreator('EXTERNAL');

export const FETCH_EXTERNAL_SOURCES = createActionType('FETCH_EXTERNAL_SOURCES');

export const fetchExternalSources = () => (
  dispatchPromiseWithMessage({
    type: FETCH_EXTERNAL_SOURCES,
    promise: get()('vrService/externalSources/externalSources'),
    errorMessage: 'Error loading the external sources',
  })
);
