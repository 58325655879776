import { Actions } from '../../actions/activityReports';

const fetchDataActivityPending = (state) => ({
  ...state,
  dataActivity: [],
  loadingDataActivity: true,
  errorDataActivity: false,
});

const fetchDataActivityFulfilled = (state, { payload }) => ({
  ...state,
  dataActivity: payload,
  loadingDataActivity: false,
  errorDataActivity: false,
});

const fetchDataActivityRejected = (state) => ({
  ...state,
  dataActivity: [],
  loadingDataActivity: false,
  errorDataActivity: true,
});

const fetchSourcesListFulfilled = (state, { payload }) => ({
  ...state,
  sourcesList: payload,
});

const downloadValidationReportPending = (state) => ({
  ...state,
  isExporting: true,
});

const downloadValidationReport = (state) => ({
  ...state,
  isExporting: false,
});

const changeAttributeFilters = (state, { payload }) => ({
  ...state,
  attributeFilters: payload,
});

const Crud = {
  [Actions.FETCH_DATA_ACTIVITY.PENDING]: fetchDataActivityPending,
  [Actions.FETCH_DATA_ACTIVITY.FULFILLED]: fetchDataActivityFulfilled,
  [Actions.FETCH_DATA_ACTIVITY.REJECTED]: fetchDataActivityRejected,
  [Actions.FETCH_SOURCES_LIST.FULFILLED]: fetchSourcesListFulfilled,
  [Actions.DOWNLOAD_REPORT.PENDING]: downloadValidationReportPending,
  [Actions.DOWNLOAD_REPORT.FULFILLED]: downloadValidationReport,
  [Actions.DOWNLOAD_REPORT.REJECTED]: downloadValidationReport,
  [Actions.CHANGE_ATTRIBUTE_FILTERS]: changeAttributeFilters,
};

export default Crud;
