import download from './img/download.png';
import file from './img/file.png';
import form from './img/form.png';
import formFill from './img/form_fill.png';
import result from './img/result.png';

const SectionValidationReport = () => (
  <div className="subSection">
    <p>
      To download the validation report, the user only needs to select a date range.
    </p>
    <p>
      <img src={form} alt="form" />
    </p>
    <p>
      <img src={formFill} alt="form" />
    </p>
    <p>
      Upon clicking on &quot;Download Report&quot;,
      a pop up window informs the user that the export is ongoing.
    </p>
    <p>
      <img src={download} alt="download" />
    </p>
    <p>
      When the pop up window is closed, the report would have been downloaded.
    </p>
    <p>
      <img src={file} alt="file" />
    </p>
    <p>
      <img src={result} alt="result" />
    </p>
  </div>
);

export default SectionValidationReport;
