import React, { useCallback, useEffect } from 'react';

import * as apiService from '@services/failover/api';

import { requestWithMessage } from '@common/utilities/Notification';

const useFailoverParameter = ({ parameter }) => {
  const [editFailOver, setEditFailOver] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchFailOverParameter = useCallback(async () => {
    await requestWithMessage({
      errorMessage: 'Failed to fetch failover parameter',
      request: () => apiService.getParameter({ parameter }),
      onSuccess: async (response) => {
        const data = await response.json();
        setEditFailOver(data);
      },
      setLoading: setIsLoading,
    });
  }, [parameter]);

  useEffect(() => {
    fetchFailOverParameter();
  }, []);

  return { editFailOver, isLoading };
};

export default useFailoverParameter;
