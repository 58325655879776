import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Container,
  Col,
  Row,
  Modal,
} from 'react-bootstrap';
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';

import ImportVesselGroups from '@pages/VesselGroupsViewer/components/ImportVesselGroups';
import VesselGroupsEditor from '@pages/VesselGroupsViewer/components/VesselGroupsEditor';

import DataTable from '@components/DataTable';
import Filters from '@components/Filters';

import tSchema from '@lang/schema';

import { apiDeleteVesselGroup, apiExportVesselGroupExcelById, apiFetchVesselsGroups } from '@services/vessels/api';

import {
  getTableConfig,
  getFiltersConfig,
} from './config';

import './style.scss';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification, showOkNotification } from '@common/utilities/Notification';
import { formatHeaderSort } from '@common/utils';

const MODE = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

const getTitleFromMode = (modalVisualizationForm, t) => {
  switch (modalVisualizationForm) {
    case MODE.CREATE:
      return t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.createVesselGroup);
    case MODE.EDIT:
      return t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.editVesselGroup);
    default:
    case MODE.VIEW:
      return t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.viewVesselGroup);
  }
};

export const VesselGroupsViewer = ({
  saving,
}) => {
  const { t } = useTranslation();
  const [types] = useState(null);
  const [loading, setLoading] = useState(saving);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('id,desc');
  const [vessels, setVessels] = useState([]);
  const [selectedVesselGroup, setSelectedVesselGroup] = useState(false);
  const [editVesselGroup, setEditVesselGroup] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showConfirmationDeletePanel, setShowConfirmationDeletePanel] = useState(false);
  const pageRequest = useRef(1);
  const maxPages = useRef(0);
  const totalResults = useRef(0);
  const downloadRef = useRef();
  const dataTableRef = useRef();
  const [modalVisualizationForm, setModalVisualizationForm] = useState(null);
  const tableConfig = useMemo(() => getTableConfig(t), [t]);
  const filtersConfig = useMemo(() => getFiltersConfig(t), [t]);

  const [uploadingGroups, setUploadingGroups] = useState(false);

  const triggerDataFetch = () => setForceUpdateFlag({});

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };
  const requestWithLoading = useRequestWithLoading();

  const handleDeleteVesselGroup = useCallback(() => {
    requestWithLoading(async () => {
      const response = await apiDeleteVesselGroup(selectedVesselGroup?.id);
      if (response.ok) {
        setLoading(true);
        setShowConfirmationDeletePanel(false);
        triggerDataFetch();
        showOkNotification(t(tSchema.notifications.dmaAdmin.vesselGroupMGMT.deleteVesselGroups.ok));
      } else if (response.response.status === 400) {
        setLoading(true);
        setShowConfirmationDeletePanel(false);
        triggerDataFetch();
        showErrorNotification(response.response.data.message);
      }
    });
  }, [selectedVesselGroup, requestWithLoading, showConfirmationDeletePanel]);

  useEffect(() => {
    requestWithLoading(async () => {
      const query = await apiFetchVesselsGroups(searchFilters,
        {
          page: pageRequest.current - 1,
          size: pageSize,
          sort: orderBy && orderBy.field,
          direction: orderBy && orderBy.order,
        });
      if (query.ok) {
        const data = await query.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setVessels(data.content || []);
      } else {
        showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  }, [requestWithLoading, pageSize, forceUpdateFlag, searchFilters, orderBy]);

  const editRow = useCallback(
    (row) => {
      unstableBatchedUpdates(() => {
        setEditVesselGroup(true);
        setSelectedVesselGroup(row);
        setShowModalCreate(true);
      });
      setModalVisualizationForm(MODE.EDIT);
    }, [],
  );
  const viewRow = useCallback(
    (row) => {
      unstableBatchedUpdates(() => {
        setEditVesselGroup(false);
        setSelectedVesselGroup(row);
        setShowModalCreate(true);
      });
      setModalVisualizationForm(MODE.VIEW);
    }, [],
  );
  const deleteRow = useCallback(
    (row) => {
      unstableBatchedUpdates(() => {
        setSelectedVesselGroup(row);
        setShowConfirmationDeletePanel(true);
      });
    }, [],
  );
  const exportRow = useCallback(
    (row) => {
      apiExportVesselGroupExcelById(row?.id);
    }, [],
  );
  const actions = useMemo(() => [
    { label: t(tSchema.common.edit), action: editRow, disabled: loading },
    { label: t(tSchema.common.view), action: viewRow, disabled: loading },
    { label: t(tSchema.common.delete), action: deleteRow, disabled: loading },
    { label: t(tSchema.common.exportToExcel), action: exportRow, disabled: loading },
  ], [
    editRow,
    viewRow,
    deleteRow,
    loading,
  ]);

  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      setOrderBy(
        formatHeaderSort(header, orderBy),
      );
      resetPagination();
    }
  }, [loading, pageRequest, orderBy, resetPagination]);

  const updateSearchFiltersHandler = useCallback((filters) => {
    if (pageRequest.current > 1) {
      resetPagination();
    }
    setSearchFilters(filters);
  }, [pageRequest, resetPagination]);

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    triggerDataFetch();
  }, []);

  const createVesselGroupClickHandler = useCallback(() => {
    setEditVesselGroup(true);
    setSelectedVesselGroup(null);
    setShowModalCreate(true);
    setModalVisualizationForm(MODE.CREATE);
  }, []);

  const importVesselGroupsClickHandler = useCallback(() => {
    setEditVesselGroup(true);
    setSelectedVesselGroup(null);
    setShowModalImport(true);
  }, []);

  const onHideExitModalHandler = useCallback(() => {
    setShowModalImport(false);
    setShowModalCreate(false);
    triggerDataFetch();
  }, []);

  const createVesselGroupButton = useMemo(() => (
    <Button
      id="new-business-rule"
      className="symbol-plus"
      onClick={createVesselGroupClickHandler}
    >
      {t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.createVesselGroup)}
    </Button>
  ), [createVesselGroupClickHandler]);

  const importVesselGroupsButton = useMemo(() => (
    <Button
      className="symbol-plus"
      onClick={importVesselGroupsClickHandler}
      style={{ display: 'none' }} // temporary hide import button
    >
      {t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.importVesselGroups)}
    </Button>
  ), [importVesselGroupsClickHandler]);

  const businessRuleEditorCancelHandler = useCallback(() => {
    setSelectedVesselGroup(null);
  }, []);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && viewRow(row), // Double Click
    [viewRow],
  );

  const editorTitle = useMemo(() => getTitleFromMode(
    modalVisualizationForm, t,
  ), [modalVisualizationForm]);

  return (
    <Container fluid id="vesselGroupViewer">
      <>
        <a href="/" ref={downloadRef} hidden>
          Download
        </a>
        <Filters
          filters={filtersConfig}
          onUpdateSearchFilters={updateSearchFiltersHandler}
          isUIBlocked={loading}
        />
        <Row>
          <Col>
            {vessels && (
              <DataTable
                actions={actions}
                ref={dataTableRef}
                tableId="VesselGroups"
                tableConfig={tableConfig}
                orderBy={orderBy}
                onTableHeaderClick={tableHeaderClickHandler}
                rowData={vessels}
                defaultPageSize={pageSize}
                onPageSizeChangeHandler={pageSizeChangeHandler}
                onPageChangeHandler={pageChangeHandler}
                onRowSelected={handleRowSelected}
                loading={loading}
                maxPages={maxPages.current}
                errorMessageLoadingData="No groups found"
                totalResults={totalResults.current}
                showTotalResults
                tableActions={(
                  <>
                    {importVesselGroupsButton}
                    {createVesselGroupButton}
                  </>
                                )}
              />
            )}
          </Col>
        </Row>
        <Modal
          backdrop="static"
          show={showConfirmationDeletePanel}
          onHide={() => {
            setShowConfirmationDeletePanel(false);
          }}
          dialogClassName="modal-90w"
        >
          <Modal.Body className="mt-3 d-flex justify-content-center align-content-center">
            <p>
              {t(
                tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.deleteConfirmation,
                { vesselGroupName: selectedVesselGroup?.name },
              )}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                unstableBatchedUpdates(() => {
                  setShowConfirmationDeletePanel(false);
                  setSelectedVesselGroup(null);
                });
              }}
            >
              {t(tSchema.common.cancel)}
            </Button>
            <Button variant="danger" onClick={handleDeleteVesselGroup}>
              {t(tSchema.common.delete)}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          backdrop="static"
          show={showModalImport}
          onHide={onHideExitModalHandler}
          onExit={onHideExitModalHandler}
          size="xl"
        >
          <Modal.Header closeButton className="pb-0" />
          <Modal.Body className="pt-0">
            <ImportVesselGroups
              onCancel={businessRuleEditorCancelHandler}
              loading={loading}
              options={types}
              setShowModal={setShowModalImport}
            />
          </Modal.Body>
        </Modal>

        <Modal
          backdrop="static"
          show={showModalCreate}
          onHide={onHideExitModalHandler}
          onExit={onHideExitModalHandler}
          size="xl"
        >
          <Modal.Header closeButton={!uploadingGroups} className="pb-0">
            <Modal.Title>
              {editorTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <VesselGroupsEditor
              key={`e${selectedVesselGroup?.id}${editVesselGroup ? 'edit' : 'view'}`}
              vesselGroup={selectedVesselGroup || {}}
              edit={editVesselGroup}
              onCancel={businessRuleEditorCancelHandler}
              loading={loading}
              options={types}
              setShowModal={setShowModalCreate}
              modalVisualizationForm={modalVisualizationForm}
              onUploading={setUploadingGroups}
            />
          </Modal.Body>
        </Modal>
        <br />
      </>
    </Container>
  );
};

VesselGroupsViewer.propTypes = {
  saving: PropTypes.bool,
};
VesselGroupsViewer.defaultProps = {
  saving: false,
};

export default VesselGroupsViewer;
