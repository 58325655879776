import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconSvg, { ICON } from '@components/IconSvg/IconSvg';

import tSchema from '@lang/schema';

import * as failoverApiService from '@services/failover/api';
import {
  exportReports,
  updateReferences,
} from '@services/isr/reports/api';

import {
  formatReports,
  formatIds,
} from './utils';

import { Permissions, hasPermission } from '@common/keycloakFunctions';
import { requestWithMessage, showWarningNotification } from '@common/utilities/Notification';

const IsrViewerTableButtons = ({
  setIsShowingExport,
  setIsShowingUpdate,
  totalResults,
  selectedRows,
  searchFilters,
  idScenario,
  triggerDataFetch,
}) => {
  const schema = tSchema.isrViewer;
  const permissions = useSelector((state) => state.authentication.permissions);
  const { t } = useTranslation();
  const [maxISRUpdateReferencesSize, setMaxISRUpdateReferencesSize] = useState(null);

  async function exportIsrReports() {
    exportReports({
      ids: formatIds(selectedRows),
      searchFilters,
      idScenario,
    }, setIsShowingExport, t);
  }

  async function updateIsrReferences() {
    updateReferences(formatReports(selectedRows), setIsShowingUpdate, t, triggerDataFetch);
  }

  const getMaxUpdateReferencesSize = useCallback(() => {
    requestWithMessage({
      errorMessage: t(schema.errorFailoverParam),
      request: () => failoverApiService.getParameter({ parameter: 'ISRMaxVRUpdateReferences' }),
      onSuccess: async (response) => {
        const data = await response.json();
        setMaxISRUpdateReferencesSize(data);
      },
    });
  });

  useEffect(() => {
    getMaxUpdateReferencesSize();
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'right' }}>
      {hasPermission(permissions, Permissions.ISR_UPDATE_REFERENCES) && (
        <Button
          id="update-references"
          className="symbol-upload"
          onClick={() => {
            if (selectedRows.length <= parseInt(`${maxISRUpdateReferencesSize.value}`, 10)) {
              setIsShowingUpdate(true);
              updateIsrReferences();
            } else {
              const errMsg = t(schema.errorManySelections,
                {
                  value: maxISRUpdateReferencesSize.value,
                });
              showWarningNotification(errMsg);
            }
          }}
          disabled={selectedRows.length === 0}
        >
          {t(schema.updateReferences)}
        </Button>
      )}
      {hasPermission(permissions, Permissions.ISR_EXPORT_EXCEL) && (
      <Button
        id="export-to-excel"
        style={{ display: 'flex', marginLeft: '0.313rem' }}
        className="symbol-download"
        onClick={() => {
          setIsShowingExport(true);
          exportIsrReports();
        }}
        disabled={!totalResults > 0}
      >
        <IconSvg name={ICON.DOWNLOAD} />
        {t(schema.exportToExcel)}
      </Button>
      )}
    </div>
  );
};

IsrViewerTableButtons.propTypes = {
  setIsShowingExport: PropTypes.func.isRequired,
  setIsShowingUpdate: PropTypes.func.isRequired,
  totalResults: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedRows: PropTypes.objectOf(PropTypes.any).isRequired,
  searchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  idScenario: PropTypes.objectOf(PropTypes.any).isRequired,
  triggerDataFetch: PropTypes.func.isRequired,
};

export default IsrViewerTableButtons;
