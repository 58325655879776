import PropTypes from 'prop-types';
import {
  useState, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/DataTable';

import { tableConfig } from './config';

import { isNilOrEmpty } from '@common/utils';

const TripViewTable = ({
  tripsData,
  loading,
  filterOptions,
  rowSelected,
  dataTableRef = {},
  onViewReportDetails,
  onRowSelected: handleRowSelected,
  subRowRender,
  ...rest
}) => {
  const { t } = useTranslation();
  const { totalElements, totalPages, content: tripsContent } = tripsData;

  const [toggleValue, setToggleValue] = useState(false);

  // TODO this functionality needs to be defined by client
  const handleRelatedFishingTrips = useCallback(
    () => null, [],
  );
    // TODO this functionality needs to be defined by client
  const relatedFishingTripsConditionallyDisabled = useCallback(
    () => true, [],
  );
  const handleViewReportDetails = useCallback(
    (row) => onViewReportDetails(row?.id), [onViewReportDetails],
  );
  const viewReportDetailsConditionallyDisabled = useCallback(
    (row) => isNilOrEmpty(row?.id), [],
  );
  const actions = useMemo(() => [
    { label: 'View Catch Composition', action: handleRelatedFishingTrips, conditionallyDisabled: relatedFishingTripsConditionallyDisabled },
    { label: 'View Trip Report Details', action: handleViewReportDetails, conditionallyDisabled: viewReportDetailsConditionallyDisabled },
  ], [
    handleRelatedFishingTrips,
    relatedFishingTripsConditionallyDisabled,
    handleViewReportDetails,
    viewReportDetailsConditionallyDisabled,
  ]);

  const getTableConfig = useMemo(() => tableConfig(t, setToggleValue), []);
  const tableColumns = useMemo(() => getTableConfig(toggleValue), [toggleValue]);

  const preHandleRowSelected = useCallback(
    (row, event) => {
      switch (event.detail) {
        case 1:
          handleRowSelected && handleRowSelected(row, event);
          break;
        case 2:
          // Double click
          !viewReportDetailsConditionallyDisabled(row) && handleViewReportDetails(row);
          break;
        default:
      }
    },
    [handleRowSelected, handleViewReportDetails, viewReportDetailsConditionallyDisabled],
  );

  return (
    <DataTable
      actions={actions}
      ref={dataTableRef}
      tableId="trip-view-table"
      totalResults={totalElements}
      stickyHeader={false}
      tableConfig={tableColumns}
      rowData={tripsContent || []}
      loading={loading}
      maxPages={totalPages || 1}
      rowSelected={rowSelected}
      onRowSelected={preHandleRowSelected}
      subRowRender={subRowRender}
      {...rest}
    />
  );
};

TripViewTable.propTypes = {
  tripsData: PropTypes.shape({
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  filterOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  rowSelected: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  onViewReportDetails: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  dataTableRef: PropTypes.shape({}).isRequired,
  subRowRender: PropTypes.func.isRequired,
};

TripViewTable.defaultProps = {
  filterOptions: null,
  rowSelected: null,
};

export default TripViewTable;
