import PropTypes from 'prop-types';
import {
  compose, find, lensPath, map, path, prepend, prop, propEq, set,
} from 'ramda';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  DomainSelectField,
  LevelFormField,
  SequenceFormField,
  SubLevelFormField,
  StatusFormField,
  ContextSelectFormField,
  SeverityFormField,
  OwnerFormField,
} from '@pages/BusinessRules/components/BusinessRuleEditor/businessRuleFormComponents';
import DatesFormField from '@pages/BusinessRules/components/BusinessRuleEditor/businessRuleFormComponents/DatesFormField';
import submitForm from '@pages/BusinessRules/components/BusinessRuleEditor/utils/submitForm';
import { padLeft } from '@pages/BusinessRules/config';

import tSchema from '@lang/schema';

import { apiFetchBusinessRule } from '@services/businessRules/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import { businessRulesValueOptions } from './config';

import {
  formatDateTime,
  dayjsDate, getDateForInput,
} from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { DOMAIN } from '@common/utils';

import './style.scss';

const BusinessRuleEditor = ({
  isDataAdmin,
  ruleId,
  edit,
  onSave,
  onCancel,
  options,
  loading: parentLoading,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(parentLoading);
  const [validated, setValidated] = useState(false);
  const [rule, setRule] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [originalSequence, setOriginalSequence] = useState(null);
  const [originalCode, setOriginalCode] = useState({});

  const { validation: valSchema } = tSchema.dmaAdmin.brEngine.createEditView;

  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  const requestWithLoading = useRequestWithLoading();

  useEffect(() => {
    if (ruleId !== null && ruleId >= 0) {
      requestWithLoading(async () => {
        const query = await apiFetchBusinessRule()(ruleId);

        if (query.ok) {
          const data = await query.json();
          setRule(data);
          setStartDate(dayjsDate(data.startDate));
          setEndDate(dayjsDate(data.endDate));
          setOriginalSequence(data.sequence);
          setOriginalCode({
            sequence: data.sequence,
            domain: data.domain.id,
            level: data.level.id,
            sublevel: data.subLevel.id,
          });
        } else {
          setRule({});
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    }
  }, [ruleId, requestWithLoading]);

  useEffect(() => {
    setErrors((prevState) => ({ ...prevState, sequenceError: null }));
  }, [rule.domain, rule.level, rule.subLevel, rule.sequence]);

  const getOptions = (option, idName = 'id', customText = false, allowEmpty = true) => compose(
    prepend(
      allowEmpty && (
      <option key="empty" value="">
        {' '}
      </option>
      ),
    ),
    map((x) => (
      <option key={prop(idName)(x)} value={prop(idName)(x)}>
        {customText ? customText(x) : prop('description')(x)}
      </option>
    )),
    prop(option),
  )(options);

  const getOptionObj = (option, id, idName = 'id') => compose(
    find(propEq(idName, id)),
    prop(compose(prop('name'), find(propEq('code', option)))(businessRulesValueOptions)),
  );

  const dateChangeHandler = useCallback(
    (event) => {
      let dateEndError = null;
      let dateStartError = null;
      const { value, name } = event.target;

      const isStartDate = name === 'startDate';
      const isEndDate = name === 'endDate';

      const start = isStartDate ? dayjsDate(value) : startDate;
      const end = isEndDate ? dayjsDate(value) : endDate;

      isStartDate && setStartDate(start);
      isEndDate && setEndDate(end);

      setRule({
        ...rule,
        ...(isStartDate ? { startDate: start } : {}),
        ...(isEndDate ? { endDate: end } : {}),
      });

      if (value && start && end) {
        if (end.isBefore(start)) {
          dateEndError = t(valSchema.dateEnd);
          dateStartError = t(valSchema.dateStart);
        }
        setErrors((prev) => ({ ...prev, dateEndError, dateStartError }));
      }
    },
    [startDate, endDate],
  );

  const onSubmitHandler = useCallback(
    async (event) => {
      const responseError = await submitForm(event, {
        setValidated,
        loading,
        errors,
        originalCode,
        getOptionObj,
        options,
        rule,
        onSave,
      });
      responseError && setErrors((prevState) => ({ ...prevState, ...responseError }));
    },
    [loading, errors, getOptionObj, options, rule, onSave],
  );
  const onChangeCodeError = () => errors.codeError
  && setErrors((prevState) => ({ ...prevState, codeError: null }));
  const lensPathSequenceCode = lensPath(['sequence']);

  const onChangeSequenceField = (formattedValue) => {
    setRule(set(lensPathSequenceCode, formattedValue, rule));
    onChangeCodeError();
  };

  const isNewBR = useMemo(() => ruleId < 0, [ruleId]);
  const disabled = useMemo(() => {
    switch (domainCode) {
      case DOMAIN.ISR:
        return !edit;
      default:
        return !(edit && isDataAdmin);
    }
  }, [edit, isDataAdmin]);
  const disabledOnNew = useMemo(() => (isNewBR ? disabled : true), [disabled, isNewBR]);

  const propsToChildSequenceFormField = useMemo(() => ({
    edit,
    isDataAdmin,
    rule,
    options,
    originalSequence,
    disabled,
  }), [edit, isDataAdmin, rule, options, originalSequence, disabled]);

  const propsToChildrenFields = useMemo(() => ({
    getOptions,
    disabled,
    rule,
  }), [getOptions, disabled, rule]);

  return (
    <Form
      key={rule.id}
      id="business-rule-editor"
      noValidate
      validated={validated}
      onSubmit={onSubmitHandler}
    >
      <div className="editor-fields">
        {loading && (
          <div className="loading-div">
            <div className="spinner-border" role="status">
              <span className="sr-only">{t(tSchema.common.loading)}</span>
            </div>
          </div>
        )}
        <Form.Row>
          <Col xs={6}>
            <Form.Row>
              <DomainSelectField
                {...propsToChildrenFields}
                onChange={onChangeCodeError}
              />
              <LevelFormField
                {...propsToChildrenFields}
                padLeft={padLeft}
                onChange={onChangeCodeError}
              />
              <SubLevelFormField
                {...propsToChildrenFields}
                padLeft={padLeft}
                onChange={onChangeCodeError}
              />
              <SequenceFormField
                {...propsToChildSequenceFormField}
                onChange={onChangeSequenceField}
                valueDefault={path(['sequence'])(rule)}
              />
            </Form.Row>
            {errors.codeError && (
              <Form.Control.Feedback style={{ display: 'block' }} className="invalid-feedback">
                {errors.codeError}
              </Form.Control.Feedback>
            )}
          </Col>
          <StatusFormField rule={rule} disabled={disabled} getOptions={getOptions} />
          <ContextSelectFormField {...propsToChildrenFields} />
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Row>
              <Col>
                <Form.Row>
                  <SeverityFormField {...propsToChildrenFields} />
                  <OwnerFormField {...propsToChildrenFields} />
                  <Form.Group as={Col} controlId="typeOfEntity">
                    <Form.Label>
                      {t(tSchema.dmaAdmin.brEngine.createEditView.typeOfMessage)}
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name="typeOfEntity"
                      defaultValue={path(['typeOfEntity', 'id'])(rule)}
                      disabled={disabledOnNew}
                    >
                      {getOptions('brtypeofentity')}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {t(valSchema.emptyTypeOfMessage)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col>
                <DatesFormField
                  getDateForInput={getDateForInput}
                  disabled={disabled}
                  dateChangeHandler={dateChangeHandler}
                  rule={rule}
                  errors={errors}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formMessage">
            <Form.Label>
              {t(tSchema.dmaAdmin.brEngine.createEditView.message)}
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              rows="4"
              name="messageFail"
              defaultValue={rule.messageFail}
              disabled={disabled}
            />
            <Form.Control.Feedback type="invalid">
              {t(valSchema.emptyMessage)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formDescription">
            <Form.Label>
              {t(tSchema.dmaAdmin.brEngine.createEditView.description)}
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              rows="4"
              name="description"
              defaultValue={rule.description}
              disabled={disabled}
            />
            <Form.Control.Feedback type="invalid">
              {t(valSchema.emptyDescription)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formDefinition">
            <Form.Label>
              {t(tSchema.dmaAdmin.brEngine.createEditView.definition)}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="8"
              name="whenCondition"
              defaultValue={rule.whenCondition}
              disabled={disabledOnNew}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={3} controlId="formField" className="mr-2">
            <Form.Label>
              {t(tSchema.dmaAdmin.brEngine.createEditView.entityToApplyBR)}
            </Form.Label>
            <Form.Control
              type="text"
              name="field"
              defaultValue={rule.field}
              disabled={disabled}
            />
          </Form.Group>
          <Form.Group as={Col} md={3} controlId="formExecutionOrder">
            <Form.Label>
              {t(tSchema.dmaAdmin.brEngine.createEditView.executionOrder)}
            </Form.Label>
            <Form.Control
              required
              as="select"
              name="executionOrder"
              defaultValue={path(['executionOrder', 'id'])(rule)}
              disabled={disabled}
            >
              {getOptions('executionorder')}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {t(tSchema.dmaAdmin.brEngine.createEditView.validation.emptyExecutionOrder)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      </div>

      <Form.Row className="actions justify-content-end ">
        <Col>
          {rule.lastModifier && (
            <p className="text-muted">
              {
                t(tSchema.dmaAdmin.brEngine.createEditView.lastModified,
                  {
                    lastModifier: rule.lastModifier,
                    lastUpdate: formatDateTime(rule.lastUpdate, '-', true),
                  })
              }
            </p>
          )}
        </Col>

        <Col>
          {edit && (
            <Form.Row className="justify-content-end mr-3">
              <Button
                className="mr-3 symbol-cross"
                variant="primary"
                onClick={onCancel}
              >
                {t(tSchema.common.cancel)}
              </Button>
              <Button
                className="symbol-tick"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {t(tSchema.common.save)}
              </Button>
            </Form.Row>
          )}
        </Col>
      </Form.Row>
    </Form>
  );
};

BusinessRuleEditor.propTypes = {
  isDataAdmin: PropTypes.bool,
  ruleId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  options: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
        }),
        PropTypes.shape({
          id_field: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ]),
    ),
  ).isRequired,
  edit: PropTypes.bool,
  loading: PropTypes.bool,
};

BusinessRuleEditor.defaultProps = {
  ruleId: null,
  isDataAdmin: false,
  edit: false,
  loading: false,
};
export default BusinessRuleEditor;
