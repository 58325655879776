import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import IconSvg from '@components/IconSvg';
import TableCheckColumn from '@components/TableCheckColumn';

import tSchema from '@lang/schema';

import { CURRENT, CORRECTED, DELETED } from './constants';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.isrViewer;

const sentIcon = (status) => (
  <OverlayTrigger placement="right" overlay={<Tooltip>{status}</Tooltip>}>
    <div className="sentIcon">
      {status === 'Yes' ? <IconSvg name="email" /> : <span />}
    </div>
  </OverlayTrigger>
);

const attachmentsIcon = (t) => (
  <OverlayTrigger placement="right" overlay={<Tooltip>{t(schema.attachments)}</Tooltip>}>
    <div className="attachmentsIcon">
      <IconSvg name="paperclip" />
    </div>
  </OverlayTrigger>
);

function formatTableField(val, status, purpose, field, vesselEtalonId) {
  const value = val || '';
  switch (status) {
    case CURRENT:
      if (field === 'identifier' && purpose === '5') {
        return (
          <div>
            {`${value} (*)`}
          </div>
        );
      }
      if (field === 'objectName') {
        return (
          <div className={vesselEtalonId ? 'vesselEtalonId' : ''}>
            {`${value}`}
          </div>
        );
      }
      return (
        <div>
          {`${value}`}
        </div>
      );
    case CORRECTED:
      if (field === 'identifier') {
        return (
          <div>
            {`${value} (*)`}
          </div>
        );
      }
      if (field === 'objectName') {
        return (
          <div className={vesselEtalonId ? 'vesselEtalonId' : ''}>
            {`${value}`}
          </div>
        );
      }
      return (
        <div>
          {`${value}`}
        </div>
      );

    case DELETED:
      if (field === 'identifier') {
        return (
          <div style={{ color: '#c1c1c1' }}>
            {`${value} (D)`}
          </div>
        );
      }
      return (
        <div style={{ color: '#c1c1c1' }}>
          {`${value}`}
        </div>
      );

    default:
      return null;
  }
}

export const getTableConfig = (t) => [
  {
    text: t(schema.programme),
    code: 'programme',
    sortable: true,
    render: (row) => formatTableField(row.programme, row.reportStatus, row.purpose, 'programme'),
  },
  {
    text: t(schema.identifier),
    code: 'identifier',
    sortable: true,
    render: (row) => formatTableField(row.identifier, row.reportStatus, row.purpose, 'identifier'),
  },
  {
    text: t(schema.module),
    code: 'reportType',
    sortable: true,
    render: (row) => formatTableField(row.reportType, row.reportStatus, row.purpose, 'reportType'),
  },
  {
    text: t(schema.controlParty),
    code: 'controlParty',
    sortable: true,
    render: (row) => formatTableField(row.controlParty, row.reportStatus, row.purpose, 'controlParty'),
  },
  {
    text: t(schema.infringement),
    code: 'infringement',
    sortable: true,
    render: (row) => formatTableField(row.infringement, row.reportStatus, row.purpose, 'infringement'),
  },
  {
    text: attachmentsIcon(t),
    code: 'attachments',
    sortable: true,
    render: (row) => formatTableField(row.attachments, row.reportStatus, row.purpose, 'attachments'),
  },
  {
    text: t(schema.object),
    code: 'objectName',
    sortable: true,
    render: (row) => formatTableField(row.objectName, row.reportStatus, row.purpose, 'objectName', row.object),
  },
  {
    text: t(schema.dateTime),
    code: 'dateTime',
    sortable: true,
    render: (row) => formatTableField(row.dateTime, row.reportStatus, row.purpose, 'dateTime'),
  },
  {
    text: t(schema.sent),
    code: 'sentStatus',
    sortable: true,
    render: (row) => sentIcon(row.sentStatus),
  },
];

export const tableCheckConfig = ({
  reports, selectedRows, onSelectedRows: handleSelectedRows, checkAdded, t,
}) => [
  TableCheckColumn({
    rows: reports,
    selectedRows,
    onSelectedRows: handleSelectedRows,
    checkAdded,
    idName: 'id',
    attributes: ['identifier', 'recipient', 'uuid'],
    label: '',
  }),
  ...getTableConfig(t)];

export function formatIsrReports(reportList) {
  return reportList.map((report) => ({
    ...report,
    dateTime: `${formatDateTime(report.dateTime.split('+')[0])}, UTC`,
  }));
}

export function handleScroll() {
  const popUps = document.getElementsByClassName('isrReportActions');
  for (let i = 0; i < popUps.length; i += 1) {
    popUps[i].style.display = 'none';
  }
}
