import { toSchema } from '@lang/schema';

const en = require('@lang/en.json');

const SchemaPage = () => (
  <div id="schema">
    <pre>{JSON.stringify(toSchema(en), null, 2)}</pre>
  </div>
);

export default SchemaPage;
