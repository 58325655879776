import { get } from '../utils';

const apiGetVesselHistoric = (id) => get()(`vrService/elastic/getHistoricVessel/${id}`);

const apiGetVersion = (vesselId, version) => get()(`vrService/vessel/${vesselId}/changes-history/${version}`);

export {
  apiGetVesselHistoric,
  apiGetVersion,
};
