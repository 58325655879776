/* eslint-disable linebreak-style */
import actions from './img/actions.JPG';

const SectionExecuteSource = () => (
  <div className="SectionExecuteSource">
    <p>
      From the external sources grid,
      an external source can be executed by clicking on the corresponding
      button of the column &quot;Actions&quot;,
      and then selecting &quot;Execute&quot;.
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
  </div>
);

export default SectionExecuteSource;
