/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';
import failoverParametersTableActionImg from './img/failover-parameters-table-action.png';
import failoverParametersTableImg from './img/failover-parameters-table.png';

const { t } = i18next;

const SectionFailoverParametersTable = () => (
  <div className="subSection">
    <p>
      Each event is displayed in a grid with the following columns:
      <ul>
        <li>Parameter</li>
        <li>Description</li>
        <li>Value</li>
        <li>Unit</li>
      </ul>
    </p>
    <p>
      <img src={failoverParametersTableImg} alt="failoverParametersTable" />
    </p>
    <p>
      In the &quot;Action&quot; column, the button in each row shows the
      &quot;
      {t(tSchema.common.edit)}
      &quot;
      parameter button. Please refer to the next section of this manual
      to get more information on this action.
    </p>
    <img src={failoverParametersTableActionImg} alt="failoverParametersTableAction" />
  </div>
);

export default SectionFailoverParametersTable;
