import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  compose,
  map,
} from 'ramda';
import React, { useCallback, useState, useEffect } from 'react';
import {
  Button, Form, Row, Container,
} from 'react-bootstrap';

import VesselSearchModal from '@pages/VesselSearchGrid/VesselSearchModal';

import AddFilter from './components/AddFilter';
import FavouriteModal from './components/FavouriteModal';

import { filterTypes } from './config';
import './style.scss';

const SideFilters = ({
  initialFilters,
  schemaValidation,
  activeFilters,
  choosedFilters,
  onResetFilter: handleResetSideFilter,
  onAddFilter: handleAddFilter,
  onSubmit: handleSubmitForm,
  onFieldChecked: handleFieldChecked,
  onSaveFavourite: handleSaveFavourite,
  formikRef,
}) => {
  const handleResetFilter = useCallback(
    (handleReset) => {
      handleReset();
      handleResetSideFilter();
    },
    [handleResetSideFilter],
  );

  const [favourite, setFavourite] = useState(null);
  const [isShowVesselsModal, setShowVesselsModal] = useState(false);

  const [initialValues, setInitialValues] = useState(initialFilters);

  useEffect(() => {
    setInitialValues({ ...initialFilters, fakeId: Date.now() });
  }, [initialFilters]);

  const handleFavouriteClick = useCallback(() => setFavourite(true), []);

  const handleSaveasFavourite = useCallback(
    (label, values) => {
      handleSaveFavourite(label, values, activeFilters);
    },
    [activeFilters, handleSaveFavourite],
  );

  const handleVesselButtonClick = useCallback(
    () => setShowVesselsModal(true),
    [],
  );

  return (
    <Container fluid className="my-4 p-0">
      <AddFilter filters={choosedFilters} onAddFilter={handleAddFilter} />
      <hr />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schemaValidation}
        onSubmit={handleSubmitForm}
        innerRef={formikRef}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          errors,
          setFieldValue,
          handleReset,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit} className="px-3">
              {compose(
                map((f) => (
                  <div key={f.code}>
                    {filterTypes[f.type].render(
                      f, values, handleChange, handleFieldChecked,
                      errors, setFieldValue, handleVesselButtonClick,
                    )}
                  </div>
                )),
              )(activeFilters || [])}
              {activeFilters.length > 0 && (
              <Row className="d-flex my-4 mx-0 justify-content-between">
                <Button type="submit">
                  Submit
                </Button>
                <Button onClick={handleFavouriteClick}>
                  Add as Favourite
                </Button>
                <Button
                  type="reset"
                  onClick={() => handleResetFilter(handleReset)}
                >
                  Reset
                </Button>
              </Row>
              )}
            </Form>
            <FavouriteModal
              showModal={favourite}
              setShowModal={setFavourite}
              onSaveFavourite={(label) => handleSaveasFavourite(label, values)}
            />
            <VesselSearchModal
              emptyOnInit
              show={isShowVesselsModal}
              onHide={() => setShowVesselsModal(false)}
              setShow={setShowVesselsModal}
              onAcceptSelected={(vessels) => setFieldValue('vesselId', vessels.map(
                // set value for use on api filtersFormatted
                (e) => ({ ...e, value: e.etalon_id }),
              ))}
              defaultSelected={values?.vesselId}
              // the maximum items to the filter to work
              maxSelectable={140}
            />
          </>
        )}
      </Formik>
    </Container>
  );
};

SideFilters.propTypes = {
  initialFilters: PropTypes.shape({
    onlyLastTrip: PropTypes.bool.isRequired,
    timeframe: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
    tripIdInput: PropTypes.string.isRequired,
    tripIds: PropTypes.arrayOf(PropTypes.string),
    flagState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    vesselIdInput: PropTypes.string.isRequired,
    vesselId: PropTypes.arrayOf(PropTypes.string),
    vesselGroup: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    species: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fisheryType: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    gearType: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    meshSize: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
    activityTypeAnd: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    activityTypeOr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    reportType: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    vesselIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    vesselName: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  schemaValidation: PropTypes.shape({}),
  activeFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  choosedFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onResetFilter: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFieldChecked: PropTypes.func.isRequired,
  onSaveFavourite: PropTypes.func.isRequired,
  formikRef: PropTypes.shape({}).isRequired,
};

SideFilters.defaultProps = {
  initialFilters: null,
  schemaValidation: null,
};

export default SideFilters;
