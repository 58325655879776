import PropTypes from 'prop-types';
import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import {
  Button, Modal, Row, Col, Form, Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import './style.scss';

const FavouriteModal = ({
  showModal,
  setShowModal,
  onSaveFavourite: handleSaveFavourite,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleClose = () => setShowModal(false);

  const [favouriteInputValue, setFavouriteInputValue] = useState('');

  const handleChangeFavouriteInput = useCallback(
    (e) => setFavouriteInputValue(e.target.value),
    [],
  );

  const inputRef = useRef();

  useEffect(() => { inputRef.current && inputRef.current.focus(); });

  const handleSave = useCallback(
    () => {
      handleSaveFavourite(favouriteInputValue);
      setFavouriteInputValue('');
      setShowModal(false);
    },
    [favouriteInputValue, handleSaveFavourite],
  );

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onHide={handleClose}
      id="modal-favourite"
      centered
      {...rest}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark my-auto">Add as Favourite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="my-2 align-items-center">
            <Col className="p-0 form-col-label">
              <Form.Label className="pt-1">Name</Form.Label>
            </Col>
            <Col className="p-0">
              <Form.Control type="text" ref={inputRef} value={favouriteInputValue} onChange={handleChangeFavouriteInput} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          {t(tSchema.common.save)}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t(tSchema.common.close)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

FavouriteModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func.isRequired,
  onSaveFavourite: PropTypes.func.isRequired,
};

FavouriteModal.defaultProps = {
  showModal: null,
};

export default FavouriteModal;
