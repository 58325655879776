import { any, equals, isEmpty } from 'ramda';

import { apiToCheckCode } from '@services/businessRules/api';

import { BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';

export default async function submitForm(ev, {
  setValidated, loading, errors, getOptionObj, options, rule, onSave, originalCode,
}) {
  ev.preventDefault();

  setValidated(true);
  const form = ev.currentTarget;
  if (!loading && form.checkValidity() && !Object.values(errors).some(Boolean)) {
    const formData = {};
    (new FormData(ev.target)).forEach((value, key) => {
      if (!isEmpty(value)) {
        if (
          any(equals(key))([
            'startDate',
            'endDate',
            'description',
            'messageFail',
            'whenCondition',
            'field',
            'sequence',
          ])
        ) {
          formData[key] = value;
        } else {
          const option = getOptionObj(key, Number.parseInt(value, 10))(options);
          formData[key] = option;
        }
      }
    });

    const objectCodeToCheck = {
      domain: formData.domain.id,
      level: formData.level.id,
      sublevel: formData.subLevel.id,
      sequence: formData.sequence,
    };
    const isCodeModified = !equals(originalCode, objectCodeToCheck);
    const isCodeUnique = !isCodeModified || await (async () => {
      const resp = await apiToCheckCode()(objectCodeToCheck, formData.startDate, formData.endDate);
      return resp.json();
    });

    if (!isCodeUnique) {
      return {
        codeError: 'Domain, Level, Sub-Level and Sequence fields combination must be unique.',
      };
    }

    if (rule.id) formData.id = rule.id;
    if (formData.startDate) {
      formData.startDate = formatDate(formData.startDate, BACK_DATE_FORMAT);
    }
    if (formData.endDate) {
      formData.endDate = formatDate(formData.endDate, BACK_DATE_FORMAT);
    }
    onSave && onSave(formData);
  }
  return null;
}
