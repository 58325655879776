import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

const BatchModal = ({ isShowing, setIsShowing, title }) => {
  const schema = tSchema.isrViewer;
  const { t } = useTranslation();
  return (
    <Modal
      backdrop="static"
      show={isShowing}
      onHide={() => {
        setIsShowing(false);
      }}
      size="xl"
      centered
    >
      <Modal.Body>
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '40px', height: '40px' }} />
          <div className="label" style={{ fontSize: '1.188rem' }}>{title}</div>
        </div>
        <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
          {t(schema.takeMinutes)}
        </div>
        <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
          <Button
            id="close-modal"
            style={{ display: 'flex', marginTop: '1.25rem' }}
            onClick={() => {
              setIsShowing(false);
            }}
          >
            {t(schema.continueBackground)}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

BatchModal.propTypes = {
  isShowing: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsShowing: PropTypes.func.isRequired,
  title: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BatchModal;
