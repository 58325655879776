import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import { Else, If, Then } from '@components/Conditionals/If';
import IconSvg from '@components/IconSvg';

import './style.scss';

const MAX_ITEMS_TO_RENDER = 100;

const MultiSelectValues = ({
  values = [],
  onRemoveSelection,
  label,
  disabled,
  placeholder,
  onClickPlaceholder,
}) => (
  <div className={`multi-select-values ${disabled ? 'disabled' : 'enabled'}`}>
    {label && (
      <span className="label">
        {label}
        :
      </span>
    )}
    <If conditional={Boolean(placeholder && isEmpty(values))}>
      <Then>
        <div
          className="placeholder-container"
          onClick={onClickPlaceholder}
          onKeyDown={onClickPlaceholder}
          role="button"
          tabIndex="0"
        >
          <span className="placeholder">{placeholder}</span>
        </div>
      </Then>
      <Else>
        {values.slice(0, MAX_ITEMS_TO_RENDER + 1).map((addedValue, index) => (
          index < MAX_ITEMS_TO_RENDER ? (
            <div className="selection-button" key={`${addedValue}`}>
              <div className="selection-value">
                <span>{addedValue}</span>
              </div>
              <If conditional={!disabled}>
                <Then>
                  <Button
                    className="remove-item-button"
                    onClick={() => onRemoveSelection(index)}
                  >
                    <IconSvg
                      name="close"
                      className="remove-item-icon"
                    />
                  </Button>
                </Then>
              </If>
            </div>
          ) : <div className="selection-button"><span>...</span></div>
        ))}
      </Else>
    </If>
  </div>
);

MultiSelectValues.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemoveSelection: PropTypes.func.isRequired,
  onClickPlaceholder: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

MultiSelectValues.defaultProps = {
  label: 'User selection',
  placeholder: null,
  onClickPlaceholder: undefined,
  disabled: false,
};

export default MultiSelectValues;
