/* eslint-disable import/order */

import { prop } from 'ramda';
import { DOMAIN, formatSelectOption } from '@common/utils';

export const valueOptions = [
  'dataDomain',
];

export const valueOptionsLogBook = [
  'declarationTypes',
];

export const configureFilters = (types, nameTypeOfMessage, domain, isSystemAdmin) => {
  const statusOptions = prop(`validationStatus${nameTypeOfMessage}`)(types);
  const isIncoming = nameTypeOfMessage === 'incoming';
  const isFADomain = domain === DOMAIN.FA;

  return ([
    {
      code: 'type', label: 'Type', type: 'select', options: prop('type')(types),
    },
    { code: 'identification', label: 'ID', type: 'text' },
    ...((!isIncoming) ? [
      {
        code: 'reportstatus',
        label: 'Report Status',
        type: 'multichoice',
        options: prop('reportStatus', types).map(({ id, status }) => ({ id, description: status?.replace('_', ' ') })),
      }, {
        code: 'exchangestatus',
        label: 'Exchange Status',
        type: 'multichoice',
        options: prop('exchangeStatus', types).map(({ id, status }) => ({ id, description: status?.replace('_', ' ') })),
      },
      {
        code: 'responsestatus',
        label: 'Response Status',
        type: 'multichoice',
        options: prop('responseStatus', types).map(({ id, status }) => ({ id, description: status?.replace('_', ' ') })),
      },
      {
        code: 'subscriber',
        label: 'Recipient',
        type: 'select',
        options: prop('parties', types).map((option) => formatSelectOption(option, 'id', 'description')),
      },
      ...((isSystemAdmin) ? [
        {
          code: 'flagState',
          label: 'Sender',
          type: 'select',
          options: prop('parties', types).filter((option) => option.id === 'XEU' || option.id === 'XFA').map((option) => formatSelectOption(option, 'id', 'description')),
        },
      ] : []),
      {
        code: 'status',
        label: 'Validation Status',
        type: 'multichoice',
        options: statusOptions
        && statusOptions?.map(({ id, status }) => ({ id, description: status?.replace('_', ' ') })),
      }] : []),
    ...((isFADomain) ? [{
      code: 'declarationType',
      label: 'Declaration Type',
      type: 'select',
      fieldToShow: 'id',
      options: prop('declarationTypes', types),
    }] : []),
    ...((isIncoming) ? [{
      code: 'flagState',
      label: 'Sender',
      type: 'select',
      options: prop('parties', types).map((option) => formatSelectOption(option, 'id', 'description')),
    },
    ...((isSystemAdmin) ? [
      {
        code: 'subscriber',
        label: 'Recipient',
        type: 'select',
        options: prop('parties', types).filter((option) => option.id === 'XEU' || option.id === 'XFA').map((option) => formatSelectOption(option, 'id', 'description')),
      }] : []),
    {
      code: 'status',
      label: 'Validation Status',
      type: 'select',
      options: statusOptions
      && statusOptions?.map(({ id, status }) => ({ id, description: status?.replace('_', ' ') })),
    }] : []),
    {
      code: 'creationDate',
      label: 'Creation Timeframe',
      type: 'date-range',
      maxDate: new Date(),
    },
    {
      code: 'receptionDate',
      label: isIncoming ? 'Reception Timeframe' : 'Sending Timeframe',
      type: 'date-range',
    },
  ]);
};

export const searchOptions = [
  { label: 'contains', value: '=-', type: 'text' },
  { label: 'equal', value: '==', type: 'text' },
];

export const typeMessage = {
  FAQuery: 1,
  FAReport: 2,
  FAResponse: 3,
  VesselQuery: 4,
  VesselReport: 5,
  VesselResponse: 6,
  ISReport: 7,
  ISQuery: 8,
  ISResponse: 9,
};
