/* eslint-disable import/order */

import { identity } from 'ramda';
import { useTranslation } from 'react-i18next';
import tSchema from '@lang/schema';
import AccordionSection from '../../../AccordionSection';
import PAGES from '../../pages';

import apply from './img/apply.png';
import clear from './img/clear.png';
import expand from './img/expand.png';
import addFilter from './img/filter-add-filter.png';
import newFilter from './img/filter-new-filter.png';
import selectFiltered from './img/filter-select-filtered.png';
import select from './img/filter-select.png';
import selectISR from './img/filter-select-isr.png';
import filtersExcerpt from './img/filters-excerpt.png';
import filtersExpanded from './img/filters-expanded.png';
import singleFilter from './img/single-filter.png';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';
import { DOMAIN } from '@common/utils';
import PropTypes from 'prop-types';
import filtersExcerptISR from './img/filters-excerpt-isr.png';
import filtersExpandedISR from './img/filters-expanded-isr.png';
import selectFilteredISR from './img/filter-select-filtered-isr.png';
import newFilterISR from './img/filter-new-filter-isr.png';
import singleFilterISR from './img/single-filter-isr.png';
import selectBR from './img/filter-select-br.png';
import filtersExcerptBR from './img/filters-excerpt-br.png';
import filtersExpandedBR from './img/filters-expanded-br.png';
import selectFilteredBR from './img/filter-select-filtered-br.png';
import newFilterBR from './img/filter-new-filter-br.png';
import singleFilterBR from './img/single-filter-br.png';
import selectTraffic from './img/filter-select-traffic.png';
import filtersExcerptTraffic from './img/filters-excerpt-traffic.png';
import filtersExpandedTraffic from './img/filters-expanded-traffic.png';
import selectFilteredTraffic from './img/filter-select-filtered-traffic.png';
import newFilterTraffic from './img/filter-new-filter-traffic.png';
import singleFilterTraffic from './img/single-filter-traffic.png';
import selectScenarios from './img/filter-select-scenarios.png';
import filtersExcerptScenarios from './img/filters-excerpt-scenarios.png';
import filtersExpandedScenarios from './img/filters-expanded-scenarios.png';
import selectFilteredScenarios from './img/filter-select-filtered-scenarios.png';
import newFilterScenarios from './img/filter-new-filter-scenarios.png';
import singleFilterScenarios from './img/single-filter-scenarios.png';
import filtersExcerptDMA from './img/filters-excerpt-dma.png';
import filtersExpandedDMA from './img/filters-expanded-dma.png';
import newFilterDMA from './img/filter-new-filter-dma.png';
import singleFilterDMA from './img/single-filter-dma.png';

const getImage = (domain, section, images) => {
  if (section && images[section]) {
    return images[section];
  }

  if (domain === DOMAIN.ISR && images.DEFAULT) {
    return images.ISR;
  }

  return images.DEFAULT;
};

const general = (domain, section) => ({
  title: 'General',
  body: (
    <>
      <p>
        Filters are used to narrow down the data displayed by default in the grid,
        or to search for specific data.
      </p>
      <p>
        The filters panel is displayed above the grid.
      </p>
      <p>
        When filters are collapsed, an excerpt of the current applied filters is displayed.
      </p>
      <p>
        {getImage(
          domain,
          section,
          {
            [PAGES.BR]: <img src={filtersExcerptBR} alt="filtersExcerptBR" />,
            [PAGES.TRAFFIC]: <img src={filtersExcerptTraffic} alt="filtersExcerptTraffic" />,
            [PAGES.MESSAGE_VIEWER]: <img src={filtersExcerptTraffic} alt="filtersExcerptMessageViewer" />,
            [PAGES.SCENARIOS]: <img src={filtersExcerptScenarios} alt="filtersExcerptScenarios" />,
            [PAGES.DMA]: <img src={filtersExcerptDMA} alt="filtersExcerptDMA" />,
            ISR: <img src={filtersExcerptISR} alt="filtersExcerptISR" />,
            DEFAULT: <img src={filtersExcerpt} alt="filtersExcerpt" />,
          },
        )}
      </p>
      <p>
        User can expand the filters panel to add,
        remove or edit the enabled filters and their values,
        by clicking on the expand icon.
      </p>
      <p>
        <img src={expand} alt="expand" />
      </p>
      <p>
        When filters are expanded,
        a list of applied filters is displayed,
        and their values can be edited.
      </p>
      <p>
        {getImage(
          domain,
          section,
          {
            [PAGES.BR]: <img src={filtersExpandedBR} alt="filtersExpandedBR" />,
            [PAGES.TRAFFIC]: <img src={filtersExpandedTraffic} alt="filtersExpandedTraffic" />,
            [PAGES.MESSAGE_VIEWER]: <img src={filtersExpandedTraffic} alt="filtersExpandedMessageViewer" />,
            [PAGES.SCENARIOS]: <img src={filtersExpandedScenarios} alt="filtersExpandedScenarios" />,
            [PAGES.DMA]: <img src={filtersExpandedDMA} alt="filtersExpandedDMA" />,
            ISR: <img src={filtersExpandedISR} alt="filtersExpandedISR" />,
            DEFAULT: <img src={filtersExpanded} alt="filtersExpanded" />,
          },
        )}
      </p>
      <p>
        The &quot;Search Period&quot; filter cannot be used alone,
        it needs at least one more filter to which apply the criteria selected
        in the search period filter.
      </p>
    </>
  ),
});

const addNewFilterDMA = () => ({
  title: 'Add new filter',
  body: (
    <>
      <p>
        At the moment Reference Data Management only allows filtering by name,
        additional filters are not supported.
      </p>
      <p>
        <img src={newFilterDMA} alt="newFilterDMA" />
      </p>
    </>
  ),
});

const addNewFilterCommon = (t = identity, domain, section) => ({
  title: 'Add new filter',
  body: (
    <>
      <p>
        Click on the &quot;
        {t(tSchema.common.addFilter)}
        &quot; dropdown to display the list of
        available filters.
      </p>
      <p>
        <img src={addFilter} alt="addFilter" />
      </p>
      <p>
        Select the filter you want to add to the list of applied filters.
      </p>
      <p>
        {getImage(
          domain,
          section,
          {
            [PAGES.BR]: <img src={selectBR} alt="selectBR" />,
            [PAGES.TRAFFIC]: <img src={selectTraffic} alt="selectTraffic" />,
            [PAGES.MESSAGE_VIEWER]: <img src={selectTraffic} alt="selectMessageViewer" />,
            [PAGES.SCENARIOS]: <img src={selectScenarios} alt="selectScenarios" />,
            ISR: <img src={selectISR} alt="selectISR" />,
            DEFAULT: <img src={select} alt="select" />,
          },
        )}
      </p>
      <p>
        You can type the filter name in the search box to narrow down the
        list of available filters.
      </p>
      <p>
        {getImage(
          domain,
          section,
          {
            [PAGES.BR]: <img src={selectFilteredBR} alt="selectFilteredBR" />,
            [PAGES.TRAFFIC]: <img src={selectFilteredTraffic} alt="selectFilteredTraffic" />,
            [PAGES.MESSAGE_VIEWER]: <img src={selectFilteredTraffic} alt="selectFilteredMessageViewer" />,
            [PAGES.SCENARIOS]: <img src={selectFilteredScenarios} alt="selectFilteredScenarios" />,
            ISR: <img src={selectFilteredISR} alt="selectFilteredISR" />,
            DEFAULT: <img src={selectFiltered} alt="selectFiltered" />,
          },
        )}
      </p>
      <p>
        The new filter is added to the list of applied filters.
      </p>
      <p>
        {getImage(
          domain,
          section,
          {
            [PAGES.BR]: <img src={newFilterBR} alt="newFilterBR" />,
            [PAGES.TRAFFIC]: <img src={newFilterTraffic} alt="newFilterTraffic" />,
            [PAGES.MESSAGE_VIEWER]: <img src={newFilterTraffic} alt="newFilterMessageViewer" />,
            [PAGES.SCENARIOS]: <img src={newFilterScenarios} alt="newFilterScenarios" />,
            [PAGES.DMA]: <img src={newFilterDMA} alt="newFilterDMA" />,
            ISR: <img src={newFilterISR} alt="newFilterISR" />,
            DEFAULT: <img src={newFilter} alt="newFilter" />,
          },
        )}
      </p>
      <p>
        Multiple filters can be added to the search.
      </p>
    </>
  ),
});

const addNewFilter = (t = identity, domain, section) => {
  if (section !== PAGES.DMA) {
    return addNewFilterCommon(t, domain, section);
  }

  return addNewFilterDMA();
};

const clearFilters = (t = identity) => ({
  title: 'Clear filters',
  body: (
    <>
      <p>
        <img src={clear} alt="clear" />
      </p>
      <p>
        Click on the &quot;
        {t(tSchema.common.clearFilters)}
        &quot; button to clear all filters currently applied.
      </p>
    </>
  ),
});
const applyFilters = (t = identity) => ({
  title: 'Apply filters',
  body: (
    <>
      <p>
        <img src={apply} alt="apply" />
      </p>
      <p>
        Click on the &quot;
        {t(tSchema.common.applyFilters)}
        &quot; button to update the list of filters applied.
      </p>
    </>
  ),
});
const removeFilter = (t = identity, domain, section) => ({
  title: 'Remove filter',
  body: (
    <>
      <p>
        To remove a filter, uncheck the checkbox next to the filter name,
        and click on the &quot;
        {t(tSchema.common.applyFilters)}
        &quot; button.
      </p>
      {getImage(
        domain,
        section,
        {
          [PAGES.BR]: <img src={singleFilterBR} alt="singleFilterBR" />,
          [PAGES.TRAFFIC]: <img src={singleFilterTraffic} alt="singleFilterTraffic" />,
          [PAGES.MESSAGE_VIEWER]: <img src={singleFilterTraffic} alt="singleFilterMessageViewer" />,
          [PAGES.SCENARIOS]: <img src={singleFilterScenarios} alt="singleFilterScenarios" />,
          [PAGES.DMA]: <img src={singleFilterDMA} alt="singleFilterDMA" />,
          ISR: <img src={singleFilterISR} alt="singleFilterISR" />,
          DEFAULT: <img src={singleFilter} alt="singleFilter" />,
        },
      )}
    </>
  ),
});

const Filters = ({ section }) => {
  const { t } = useTranslation();

  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="subSection">
      <AccordionSection sections={[
        general(domain, section),
        addNewFilter(t, domain, section),
        removeFilter(t, domain, section),
        applyFilters(t),
        clearFilters(t),
      ]}
      />
    </div>
  );
};

Filters.propTypes = {
  section: PropTypes.string,
};

Filters.defaultProps = {
  section: false,
};

export default Filters;
