import { get, put } from '../utils';

const getParametersList = () => get()('failover/get');

const updateParameter = ({ value, parameter }) => put()(`failover/${parameter}/${value}`);

const getParameter = ({ parameter }) => get()(`failover/get/${parameter}`);

export {
  getParametersList,
  updateParameter,
  getParameter,
};
