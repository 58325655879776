import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import buttonCreate from './img/action_create_acceptance.png';
import form from './img/create_acceptance_query.png';
import formISR from './img/create_acceptance_query_isr.png';

import { DOMAIN } from '@common/utils';

const SectionAddQueryAcceptance = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="SectionAddAcceptance">
      <p>
        Below the results grid, the user shall click on the “Create Query Acceptance Case” button.
      </p>
      <p>
        <img src={buttonCreate} alt="Button create" />
      </p>
      <p>
        When users click on the button, the creation form is displayed.
      </p>
      {domain === DOMAIN.FA
        ? (
          <p>
            <img src={form} alt="Form create" />
          </p>
        ) : (
          <p>
            <img src={formISR} alt="Form create" />
          </p>
        )}
      <p>
        Once all the fields have been filled in,
        {' '}
        the user shall click on the &quot;Save&quot; button.
        Then the modal form is closed and the grid displayed in the central panel of the
        application is updated with the new query created.
      </p>
    </div>
  );
};

export default SectionAddQueryAcceptance;
