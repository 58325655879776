import { useEffect, useState, useCallback } from 'react';

import * as serviceApi from '@services/externalSources/api';

import { requestWithMessage } from '@common/utilities/Notification';

const useSource = (externalSource) => {
  const [source, setSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchSource = useCallback(async () => {
    await requestWithMessage({
      errorMessage: 'Error fetching source',
      request: () => serviceApi.getExternalSource(externalSource),
      onSuccess: async (response) => {
        const data = await response.json();
        setSource(data);
      },
      setLoading: setIsLoading,
    });
  }, []);

  useEffect(() => {
    fetchSource();
  }, [fetchSource]);

  return { source, isLoading };
};

export default useSource;
