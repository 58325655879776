import PropTypes from 'prop-types';
import {
  any, isEmpty, propEq,
} from 'ramda';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Container,
  Col,
  Dropdown,
  Row,
  Modal,
  Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DataTable from '@components/DataTable';
import Filters from '@components/Filters';
import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import {
  apiDownloadTableData,
  apiFetchScenarios,
  apiFetchOptions,
  apiSaveScenario,
  apiDeleteScenario,
} from '@services/scenarios/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import ScenarioEditor from '../ScenarioEditor';

import {
  configureFilters,
  searchOptions,
  getTableConfig,
  scenariosValueOptions,
} from './config';

import { currentISO8601Date } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import {
  Permissions, hasPermission, isDataOrSystemAdmin, isXEUorXFA,
} from '@common/keycloakFunctions';
import { requestWithMessage, showErrorNotification } from '@common/utilities/Notification';
import { DOMAIN, formatHeaderSort } from '@common/utils';
import './style.scss';

const schema = tSchema.dmaAdmin.scenarioMGMT;
export const ScenariosListSection = ({ domain }) => {
  const { t } = useTranslation();
  const permissions = useSelector((state) => state.authentication.permissions);
  const [types, setTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [scenarios, setScenarios] = useState([]);
  const [editMode, setEditScenario] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [initialFilters, setInitialFilters] = useState(null);
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});
  const pageRequest = useRef(1);
  const maxPages = useRef(0);
  const totalResults = useRef(0);
  const downloadRef = useRef();
  const dataTableRef = useRef();
  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;

  const { keycloak } = useKeyCloak();

  const triggerDataFetch = () => setForceUpdateFlag({});

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };

  const requestWithLoading = useRequestWithLoading();

  const downloadCsv = () => {
    requestWithLoading(async () => {
      const query = await apiDownloadTableData(searchFilters, 'csv', domain);

      if (query.ok) {
        const csv = await query.text();
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        downloadRef.current.href = url;
        downloadRef.current.download = `br_${currentISO8601Date()}.csv`;
        downloadRef.current.click();
        downloadRef.current.href = '/';
      } else {
        console.error("The download didn't succeded", `Error code: ${query.status}`);
        showErrorNotification(t(tSchema.notifications.common.exportCsv));
      }
    }, setLoading);
  };

  useEffect(() => {
    requestWithLoading(async () => {
      const optionCalls = scenariosValueOptions.map(apiFetchOptions);
      const options = await Promise.all(optionCalls);

      const allOk = !any(propEq('ok', false))(options);

      if (allOk) {
        const optionsJson = await Promise.all(options.map((x) => x.json()));
        const valueOptions = {};
        optionsJson.forEach((x, i) => {
          valueOptions[scenariosValueOptions[i].option] = x;
        });
        setTypes(valueOptions);
      } else {
        showErrorNotification('An error while loading the filter options has occurred');
      }
    }, setLoading);
  }, [requestWithLoading]);

  const isDataAdmin = isDataOrSystemAdmin(keycloak);
  const isXEUorXFAUser = isXEUorXFA(keycloak);

  useEffect(() => {
    if (types) {
      const defaultFilters = {};
      searchFilters && setSearchFilters(null);
      setInitialFilters(defaultFilters);
      setFiltersConfig(configureFilters(types, isDataAdmin, isXEUorXFAUser));
    }
  }, [types]);

  const fetchScenarios = useCallback(async () => {
    await requestWithMessage({
      request: () => apiFetchScenarios(
        searchFilters,
        {
          page: pageRequest.current - 1,
          size: pageSize,
          sort: orderBy && orderBy.field,
          direction: orderBy && orderBy.order,
        },
        domain,
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setScenarios(data.content || []);
      },
      setLoading,
    });
  }, [searchFilters, pageRequest.current, pageSize, orderBy, domain]);

  useEffect(() => {
    fetchScenarios();
  }, [
    forceUpdateFlag,
    pageSize,
    orderBy,
    searchFilters,
    domain,
  ]);

  const editRow = useCallback(
    (row) => {
      setEditScenario(true);
      setShowEditor(true);
      setSelectedId(row.id);
    }, [],
  );
  const viewRow = useCallback(
    (row) => {
      setEditScenario(false);
      setShowEditor(true);
      setSelectedId(row.id);
    }, [],
  );
  const deleteRow = useCallback(
    (row) => {
      setSelectedId(row.id);
      setShowDelete(true);
    }, [],
  );
  const actions = useMemo(() => [
    {
      label: t(tSchema.common.edit),
      action: editRow,
      disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions, Permissions.EDIT_SCENARIO),
    },
    { label: t(tSchema.common.view), action: viewRow },
    {
      label: t(tSchema.common.delete),
      action: deleteRow,
      disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions, Permissions.EDIT_SCENARIO),
    },
  ], [
    editRow,
    viewRow,
    deleteRow,
  ]);

  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      pageRequest.current = 1;
      setOrderBy(formatHeaderSort(header, orderBy));
      resetPagination();
    }
  }, [loading, pageRequest, orderBy, resetPagination]);

  const updateSearchFiltersHandler = useCallback((filters) => {
    if (pageRequest.current > 1) {
      resetPagination();
    }
    setSearchFilters(filters);
  }, [pageRequest, resetPagination]);

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    triggerDataFetch();
  }, []);

  const createScenarioButtonClickHandler = useCallback(() => {
    setEditScenario(true);
    setShowEditor(true);
    setSelectedId(-1);
  }, []);
  const createScenarioButtonDisabled = useMemo(
    () => loading || (selectedId && isEmpty(selectedId)), [loading, selectedId],
  );
  const createScenarioRuleButton = useMemo(() => (
    <Button
      id="new-scenario"
      className="symbol-plus"
      onClick={createScenarioButtonClickHandler}
      disabled={createScenarioButtonDisabled}
      hidden={!(
        (domainCode === DOMAIN.ISR && hasPermission(permissions, Permissions.EDIT_SCENARIO))
         || isDataAdmin)}
    >
      {t(schema.scenarios.createScenario)}
    </Button>
  ), [createScenarioButtonClickHandler, createScenarioButtonDisabled, isDataAdmin]);

  const exportAsDropdownSelectHandler = useCallback((key) => {
    if (key === 'csv') {
      downloadCsv();
    }
  }, [downloadCsv]);
  const exportAsDropdown = useMemo(() => (
    <>
      <Dropdown
        onSelect={exportAsDropdownSelectHandler}
        style={{ display: 'none' }}
      >
        <Dropdown.Toggle
          id="download-scenarios"
          variant="primary"
          disabled={createScenarioButtonDisabled}
          placement="top-start"
        >
          {t(tSchema.common.exportAs)}
          <IconSvg name="caretDown" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="csv">{t(tSchema.common.csv)}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    </>
  ), [exportAsDropdownSelectHandler, createScenarioButtonDisabled]);

  const scenarioEditorSaveHandler = useCallback((scenario) => {
    setShowEditor(false);
    requestWithMessage({
      request: () => apiSaveScenario(scenario, domain),
      successMessage: 'The scenario was succesfully saved',
      errorMessage: 'There was an error saving the scenario',
      setLoading,
      onSuccess: fetchScenarios,
    });
  }, [fetchScenarios]);
  const scenarioEditorCancelHandler = useCallback(() => {
    setShowEditor(false);
    setSelectedId(null);
  }, []);

  const deleteHandler = useCallback(() => {
    setShowDelete(false);
    requestWithMessage({
      request: () => apiDeleteScenario(selectedId, domain),
      successMessage: 'The scenario was succesfully deleted',
      errorMessage: 'There was an error deleting the scenario',
      setLoading,
      onSuccess: fetchScenarios,
    });
  }, [selectedId]);
  const cancelDeleteHandler = useCallback(() => {
    setShowDelete(false);
    setSelectedId(null);
  }, []);

  const editorTitle = useMemo(() => {
    const editOrViewTitle = editMode
      ? t(tSchema.common.edit)
      : t(tSchema.common.view);
    return (selectedId && selectedId > 0
      ? `${editOrViewTitle} Scenario`
      : t(schema.createEditView.newScenario));
  },
  [selectedId, editMode]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && viewRow(row), // Double Click
    [viewRow],
  );

  return (
    <Container fluid id="scenariosViewer">
      <>
        <a href="/" ref={downloadRef} hidden>
          Download
        </a>
        <Filters
          filters={filtersConfig}
          searchOptions={searchOptions}
          onUpdateSearchFilters={updateSearchFiltersHandler}
          isUIBlocked={loading}
          initialFilters={initialFilters}
        />
        <Row>
          <Col>
            <DataTable
              actions={actions}
              rowSelected={selectedId}
              ref={dataTableRef}
              tableId="scenarios"
              totalResults={totalResults.current}
              tableConfig={getTableConfig(isDataAdmin, isXEUorXFAUser)}
              orderBy={orderBy}
              onTableHeaderClick={tableHeaderClickHandler}
              onRowSelected={handleRowSelected}
              rowData={scenarios}
              defaultPageSize={pageSize}
              onPageSizeChangeHandler={pageSizeChangeHandler}
              onPageChangeHandler={pageChangeHandler}
              loading={loading}
              maxPages={maxPages.current}
              tableActions={(
                <>
                  {createScenarioRuleButton}
                  {exportAsDropdown}
                </>
                )}
            />
          </Col>
        </Row>
        <Modal
          backdrop="static"
          show={showEditor}
          onHide={scenarioEditorCancelHandler}
          size="xl"
        >
          <Modal.Header closeButton className="pb-0">
            <Modal.Title>{editorTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <ScenarioEditor
              key={`e${selectedId}${editMode ? 'edit' : 'view'}`}
              isDataAdmin={isDataAdmin}
              scenarioId={selectedId}
              edit={editMode}
              onSave={scenarioEditorSaveHandler}
              onCancel={scenarioEditorCancelHandler}
              loading={loading}
              options={types}
              domain={domain}
            />
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          show={showDelete}
          onHide={cancelDeleteHandler}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t(tSchema.common.delete)}
              {' '}
              Scenario
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning">
              Are you sure you want to delete this scenario?
            </Alert>
            <ScenarioEditor
              key={`e${selectedId}-delete`}
              isDataAdmin={false}
              scenarioId={selectedId}
              edit={false}
              onSave={null}
              onCancel={null}
              loading={loading}
              options={types}
              domain={domain}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={cancelDeleteHandler}>
              {t(tSchema.common.cancel)}
            </Button>
            <Button variant="danger" onClick={deleteHandler}>
              {t(tSchema.common.delete)}
            </Button>
          </Modal.Footer>
        </Modal>
        <br />
      </>
    </Container>
  );
};

ScenariosListSection.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default ScenariosListSection;
