import dataActivityTableActionImg from './img/data-activity-table-action.png';
import dataActivityTableImg from './img/data-activity-table.png';

const SectionDataActivityTable = () => (
  <div className="subSection">
    <p>
      The Data activity grid shows the list of created or modified vessels,
      ordered by the column &quot;Date of action&quot; (descendent order by default).
    </p>
    <p>
      <img src={dataActivityTableImg} alt="dataActivityTable" />
    </p>
    <p>
      In the column &quot;Actions&quot;,
      for each row the user can access to additional operations on the vessel.
      For the time being, only the &quot;Show historical&quot; button is available:
    </p>
    <p>
      <img src={dataActivityTableActionImg} alt="dataActivityTableAction" />
    </p>
    <p>
      Clicking on this button opens a modal
      window containing historical data related to selected vessel.
    </p>
  </div>
);

export default SectionDataActivityTable;
