import { useLayoutEffect, useRef, useState } from 'react';

const useWidthContainer = () => {
  const [width, setWidth] = useState(0);
  const ref = useRef();

  useLayoutEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.clientWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);

  return [width, ref];
};

export default useWidthContainer;
