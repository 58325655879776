import ACCEPTANCE_BR from './acceptanceBR';
import COMMON from './common';
import DATA_ADMIN_REPORTING from './dataAdministrationReporting';
import EXTERNAL_SOURCES from './externalSources';
import FAILOVER from './failoverParameters';
import LOGBOOK from './logbook';
import MESSAGES from './messages';
import QUERY_REPORT from './queryReport';
import REFERENCE_DATA from './referenceData';
import REPORT_VIEWER from './reportViewer';
import SCENARIOS from './scenarioMGMT';
import SERVICE_MONITORING from './servicesMonitoring';
import VALIDATION_BR from './validationBR';
import VESSEL_GROUP from './vesselGroup';
import VESSEL_MGMT from './vesselMGMT';

const SECTIONS = {
  COMMON,
  MESSAGES,
  LOGBOOK,
  FAILOVER,
  DATA_ADMIN_REPORTING,
  VESSEL_MGMT,
  VALIDATION_BR,
  ACCEPTANCE_BR,
  SERVICE_MONITORING,
  REFERENCE_DATA,
  SCENARIOS,
  QUERY_REPORT,
  VESSEL_GROUP,
  EXTERNAL_SOURCES,
  REPORT_VIEWER,
};

export default SECTIONS;
