/* eslint-disable no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import {
  DEPARTURE_CATCHES_PROPTYPES,
} from '../../constants';
import AapProcesses from '../Modals/AapProcesses';
import WrapperModal from '../Modals/components/WrapperModal';
import RelatedFishingTrip from '../Modals/RelatedFishingTrip';
import BasicTable from '../tables/BasicTable';

import FishingGears from './FishingGears';
import Location from './Location';

import { isNilOrEmpty } from '@common/utils';

const schema = tSchema.fishingActivities.logbookViewer.details;

export const getTableHeaders = (t) => [
  { text: t(schema.type), code: 'type', sortable: false },
  { text: t(schema.species), code: 'species', sortable: false },
  { text: t(schema.quantity), code: 'unitQuantity', sortable: false },
  { text: t(schema.weightKmg), code: 'weight', sortable: false },
  { text: t(schema.weightMeans), code: 'weightingMeans', sortable: false },
  { text: t(schema.sizeClass), code: 'sizeClass', sortable: false },
  { text: t(schema.sizeCategory), code: 'sizeCategory', sortable: false },
];

const Catches = ({ catches }) => {
  const { t } = useTranslation();
  const tableHeaders = useMemo(() => getTableHeaders(t), [t]);
  const [relatedFissingTrip, setRelatedFissingTrip] = useState(null);
  const [destination, setDestination] = useState(null);
  const [location, setLocation] = useState(null);
  const [fishingGears, setFishingGears] = useState(null);
  const [aapProcesses, setAapProcesses] = useState(null);

  const relatedFissingTripHandler = useCallback((row) => {
    setRelatedFissingTrip(row?.relatedFishingTrip);
  }, []);
  const destinationHandler = useCallback((row) => {
    setDestination(row?.destination);
  }, []);
  const locationHandler = useCallback((row) => {
    setLocation(row?.location);
  }, []);
  const fishingGearsHandler = useCallback((row) => {
    setFishingGears(row?.gears);
  }, []);
  const aapProcessesHandler = useCallback((row) => {
    setAapProcesses(row?.aapProcesses);
  }, []);

  const actions = useMemo(() => [
    { label: 'See Related Fishing Trip', action: relatedFissingTripHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.relatedFishingTrip) },
    { label: 'See Destination', action: destinationHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.destination) },
    { label: 'See Location', action: locationHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.location) },
    { label: 'See Fishing Gears', action: fishingGearsHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.gears) },
    { label: 'See APP Processes', action: aapProcessesHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.aapProcesses) },
  ], []);
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <BasicTable data={catches} headers={tableHeaders} actions={actions} />
        </Col>
      </Row>
      <WrapperModal
        id="wrapper-modal-relatedFissingTrip"
        showModal={relatedFissingTrip}
        setShowModal={setRelatedFissingTrip}
        headerTitle="Related Fishing Trip"
        centered
        animation={false}
        size="xl"
      >
        {relatedFissingTrip
      && <RelatedFishingTrip relatedFishingTrip={relatedFissingTrip} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-destination"
        showModal={destination}
        setShowModal={setDestination}
        headerTitle="Destination Location"
        centered
        animation={false}
        size="xl"
      >
        {destination && <Location location={destination} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-locations"
        showModal={location}
        setShowModal={setLocation}
        headerTitle="Location"
        centered
        animation={false}
        size="xl"
      >
        {location && <Location location={location} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-fishingGears"
        showModal={fishingGears}
        setShowModal={setFishingGears}
        headerTitle="Fishing Gears"
        centered
        animation={false}
        size="xl"
      >
        {fishingGears && <FishingGears gears={fishingGears} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-aapProcesses"
        showModal={aapProcesses}
        setShowModal={setAapProcesses}
        headerTitle="AAP Processes"
        centered
        animation={false}
        size="xl"
      >
        {aapProcesses && <AapProcesses aapProcesses={aapProcesses} />}
      </WrapperModal>
    </Container>
  );
};

Catches.propTypes = {
  catches: DEPARTURE_CATCHES_PROPTYPES.isRequired,
};

export default Catches;
