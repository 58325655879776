import logout from './img/logout.JPG';

const LogOut = (
  <div className="subSection">
    <p>
      For logging out of the application, the user can do it with the
      &quot;Log Out&quot; link, located on the top right corner of the application panel. This link
      is available in every page of the application, in the same position.
    </p>
    <img src={logout} alt="Log Out button" />
  </div>
);

export default LogOut;
