import Api from '@common/utilities/api';

const api = () => new Api();

const TypeActionsAttributes = {
  FETCH_ALL_ATTRIBUTES: 'FETCH_ALL_ATTRIBUTES',
  FETCH_ATTRIBUTE: 'FETCH_ATTRIBUTE',
  EDIT_ATTRIBUTE: 'EDIT_ATTRIBUTE',
  CREATE_ATTRIBUTE: 'CREATE_ATTRIBUTE',
  REMOVE_ATTRIBUTE: 'REMOVE_ATTRIBUTE',
};

const Status = {
  OK: 'OK',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};

const fetchAllAttributes = () => ({
  type: TypeActionsAttributes.FETCH_ALL_ATTRIBUTES,
  payload: api().get('/vrService/vessel/getAttributes'),
});

const fetchAttribute = (attribute) => ({
  type: TypeActionsAttributes.FETCH_ATTRIBUTE,
  payload: attribute,

});

const putAttribute = (name, body) => ({
  type: TypeActionsAttributes.EDIT_ATTRIBUTE,
  payload: api().put(`/vrService/vessel/putAttribute/${name}`, { body }),
});

const postAttribute = (body) => ({
  type: TypeActionsAttributes.CREATE_ATTRIBUTE,
  payload: api().post('/vrService/vessel/postAttribute', { body }),
});

const removeAttribute = (name) => ({
  type: TypeActionsAttributes.CREATE_ATTRIBUTE,
  payload: api().delete(`/vrService/vessel/removeAttribute/${name}`),
});

export {
  TypeActionsAttributes,
  Status,
  fetchAllAttributes,
  fetchAttribute,
  putAttribute,
  postAttribute,
  removeAttribute,
};
