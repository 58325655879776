import actions from './img/actions.png';
import successDelete from './img/successDelete.png';

const SectionDeleteReport = () => (
  <div className="SectionDeleteReport">
    <p>
      Only the System Admin and FIS ISR Party Admin are allowed to delete an IS Report.
    </p>
    <p>
      To delete a report,
      the user has to select &quot;Delete&quot; in the &quot;Actions&quot;
      button for the corresponding report.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A confirmation popup asks for confirmation:
    </p>
    <p>
      If the report is successfully deleted, a notification appears.
    </p>
    <p>
      <img src={successDelete} alt="Delete Report" />
    </p>
    <p>
      After deleting a report, the results grid is updated.
    </p>
  </div>
);

export default SectionDeleteReport;
