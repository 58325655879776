import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useLogbookTypes from '@pages/FishingTripsLogbook/hooks/useLogbookTypes';
import { useSideFilterContext } from '@pages/FishingTripsLogbook/SideFilterContext/SideFilterContext';

import tSchema from '@lang/schema';

import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import SearchFavouriteSections from '../SearchFavouriteSections';
import { configureFilters } from '../viewConfig';

import ReportViewTable from './components/ReportViewTable';
import { fetchDeclarationList } from './services/api';

import './style.scss';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';

const ReportViewSection = ({
  onViewReportDetails: handleViewReportDetails,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [tripsData, setTripsData] = useState({});
  const [sort, setSort] = useState({ field: 'activityDate', order: 'DESC' });
  const [size, setSize] = useState(10);
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});
  const [initialChoosedFilters, setInitialChoosedFilters] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const page = useRef(1);
  const totalResults = useRef(0);
  const maxPages = useRef(0);
  const dataTableRef = useRef();

  const {
    setSideFilterState,
    sideFilterState: { resetFilters, submitedFilters },
  } = useSideFilterContext();

  const triggerDataFetch = () => setForceUpdateFlag({});
  const { userPreferences } = useUserPreferences();
  const idScenarioLastActive = userPreferences.lastActiveFa.id;

  const [submitedFiltersState, setSubmitedFiltersState] = useState(submitedFilters);

  const resetPagination = useCallback(() => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    page.current = 1;
  }, [dataTableRef, page]);

  const requestWithLoading = useRequestWithLoading();

  const types = useLogbookTypes({ setLoadingTypes });

  const fetchReports = useCallback(({ pageSearch }) => {
    requestWithLoading(async () => {
      const filterWithScenario = idScenarioLastActive
        ? { scenario: idScenarioLastActive, ...submitedFilters } : submitedFilters;
      const query = await fetchDeclarationList(filterWithScenario, {
        page: pageSearch,
        size,
        sort: sort && sort.field,
        direction: sort && sort.order,
      });

      if (query.ok) {
        const data = await query.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setTripsData(data || {});
      } else {
        showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  }, [
    forceUpdateFlag,
    submitedFilters,
    idScenarioLastActive,
    size,
    sort,
    requestWithLoading,
    showErrorNotification,
    page,
  ]);

  useEffect(() => {
    if (submitedFiltersState !== submitedFilters) {
      resetPagination();
      fetchReports({ pageSearch: 0 });
      setSubmitedFiltersState(submitedFilters);
    } else { fetchReports({ pageSearch: page.current - 1 }); }
  }, [
    forceUpdateFlag,
    size,
    sort,
    idScenarioLastActive,
    requestWithLoading,
    page,
    submitedFilters,
  ]);

  const toggleVesselModal = useCallback(() => {
    setSideFilterState((prevState) => ({
      ...prevState,
      isShowVesselsModal: !prevState.isShowVesselsModal,
    }));
  }, [setSideFilterState]);

  useEffect(() => {
    if (!isEmpty(types)) {
      setInitialChoosedFilters(configureFilters(types, null, false));
      const getInitialChoosedFilters = (prevState) => configureFilters(
        types,
        toggleVesselModal,
        false,
      )
        .filter((filter) => !prevState.activeFilters
          .some((activeFilter) => activeFilter.code === filter.code))
        .map((filter) => ({ ...filter, checked: true }));

      setSideFilterState((prevState) => ({
        ...prevState,
        choosedFilters: getInitialChoosedFilters(prevState),
      }));
    }
  }, [types]);

  useEffect(() => {
    if (resetFilters) {
      if (!isEmpty(types)) {
        setInitialChoosedFilters(configureFilters(types, null, false));
        page.current > 1 && resetPagination();
        setSideFilterState({
          choosedFilters: configureFilters(types, toggleVesselModal, false)
            .map((filter) => ({ ...filter, checked: true })),
          activeFilters: [],
          submitedFilters: {},
          resetFilters: false,
        });
      } else {
        setSideFilterState((prevState) => ({ ...prevState, resetFilters: false }));
      }
    }
  }, [resetFilters, page.current]);

  const handleRowSelected = useCallback(
    (row) => selectedTrip !== row.id && setSelectedTrip(row.id),
    [selectedTrip],
  );

  const handlePageSizeChange = useCallback((limit) => {
    page.current = 1;
    setSize(Number.parseInt(limit, 10));
  }, []);

  const handlePageChange = useCallback((newPage) => {
    page.current = newPage;
    triggerDataFetch();
  }, [triggerDataFetch]);

  const handleHeaderClick = useCallback((header) => {
    if (header.code && !loading) {
      page.current = 1;
      setSort({
        field: header.code,
        order: sort && (sort.field === header.code && sort.order) === 'asc' ? 'desc' : 'asc',
      });
      resetPagination();
    }
  }, [loading, sort, resetPagination]);

  return (
    <Container fluid className="report-view-section p-0">
      <Row
        className="w-100 m-0 pb-3 d-flex flex-nowrap"
        style={{
          minHeight: '100%',
          height: 'auto',
        }}
      >
        <SearchFavouriteSections
          types={types}
          isUIBlocked={loadingTypes}
          initialChoosedFilters={initialChoosedFilters}
          viewSection="reportView"
        />
        <Col className="p-0 pl-4 h-100">
          <ReportViewTable
            dataTableRef={dataTableRef}
            tripsData={tripsData}
            filterOptions={types}
            onViewReportDetails={handleViewReportDetails}
            rowSelected={selectedTrip}
            onRowSelected={handleRowSelected}
            onTableHeaderClick={handleHeaderClick}
            orderBy={sort}
            setLoading={setLoading}
            defaultPageSize={size}
            onPageSizeChangeHandler={handlePageSizeChange}
            onPageChangeHandler={handlePageChange}
            loading={loading}
          />
        </Col>
      </Row>
    </Container>
  );
};

ReportViewSection.propTypes = {
  onViewReportDetails: PropTypes.func.isRequired,
};

export default ReportViewSection;
