import { FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import './style.scss';
import { Row } from 'react-bootstrap';

import { STATUS_CODE, classNames } from '../../constants';

import { formatDateTime } from '@common/dateUtils';

const addIndexToVersion = (version, index) => ({ ...version, index });

const VersionSelector = ({
  versions, onSelectVersion,
}) => {
  const [currentVersionIndex, setCurrentVersionIndex] = useState(null);
  const [sortedVersions, setSortedVersions] = useState([]);

  useEffect(() => {
    setSortedVersions(versions);
  });

  const hasDeleted = useMemo(() => sortedVersions.some(
    ({ status }) => R.includes(STATUS_CODE.DELETED, status.code),
  ), [sortedVersions]);

  const hasCancel = useMemo(() => sortedVersions.some(
    ({ status }) => R.includes(STATUS_CODE.CANCELLED, status.code),
  ), [sortedVersions]);

  const versionFormat = (e) => (
    <div className={`menu-item-container ${classNames[e.status?.code]}`}>
      <div className="label">{`${e.status?.description || ''} Version`}</div>
      <div className="occurrence">{`${formatDateTime(e.creationDate.split('+')[0])}, UTC`}</div>
    </div>
  );

  const versionMenuItemFormat = (e) => (
    <MenuItem value={e.index} key={e.occurrence}>
      {versionFormat(e)}
    </MenuItem>
  );
  const handleChange = useCallback((event) => {
    setCurrentVersionIndex(event.target.value);
    onSelectVersion(sortedVersions[event.target.value]);
  }, [onSelectVersion, sortedVersions]);

  const versionsFiltered = useMemo(() => sortedVersions
    .map(addIndexToVersion),
  [sortedVersions]);

  const menuItems = useMemo(
    () => versionsFiltered.map(versionMenuItemFormat),
    [versionsFiltered],
  );

  const onlyOneVersion = useMemo(() => versions && versions.length === 1);

  useEffect(() => {
    setCurrentVersionIndex(0);
    onSelectVersion(sortedVersions[0]);
  }, [sortedVersions]);

  const disabled = useMemo(() => versions.length <= 1, [versions]);

  return (
    <FormControl id="version-select" variant="outlined">
      <Row className={onlyOneVersion && 'no-wrap'}>
        {onlyOneVersion && sortedVersions.length > 0 ? versionFormat(sortedVersions[0])
          : (
            <Select
              labelId="version-select"
              value={currentVersionIndex}
              onChange={handleChange}
              disabled={disabled}
            >
              {menuItems}

            </Select>
          )}
        {hasCancel && <div className="tag-selector cancelled-tag-version-selector">CANCELLED</div>}
        {hasDeleted && <div className="tag-selector deleted-tag-version-selector">DELETED</div>}
      </Row>
    </FormControl>
  );
};

VersionSelector.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectVersion: PropTypes.func.isRequired,
};

export default VersionSelector;
