import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { DEPARTURE_PROPTYPES, DEPARTURE_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const ReportDetailsDeparture = ({ departure, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(DEPARTURE_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentDeparture, setCurrentDeparture] = useState([]);

  useEffect(() => setCurrentDeparture((departure && departure[pageNumber - 1]) || DEPARTURE_SCHEMA),
    [departure, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        title="Departure"
        versions={currentDeparture}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={departure}
        onSelectPagination={setPageNumber}
      />
      <Row>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField
            label={t(schema.fisheryType)}
            value={`${currentVersion?.fisheryType || ''}  ${currentVersion?.fisheryTypeDescription || ''}`}
          />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t(schema.reasonCode)} value={currentVersion?.reason || ''} />
          <TextInputField label={t(schema.reasonDescription)} value={currentVersion?.reasonDescription || ''} />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t('Species Target (FAO)')} value={currentVersion?.speciesTarget} />
          <TextInputField label={t('Species Target (GROUP)')} value={currentVersion?.speciesTargetGroup} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

ReportDetailsDeparture.propTypes = {
  departure: DEPARTURE_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default ReportDetailsDeparture;
