/* eslint-disable linebreak-style */
import moreColumns from './img/more_columns.JPG';
import reportsInTrip from './img/reports_in_trip.JPG';
import table from './img/table.JPG';
import tripActions from './img/trip_actions.JPG';
import tripDetails from './img/trip_details.JPG';

export const TRIP_DATA_TABLE = (
  <div className="subSection">
    <p>
      The grid displayed on the Trip tab displays the following columns:
      <ul>
        <li>Trip ID</li>
        <li>Flag State</li>
        <li>Name</li>
        <li>CFR</li>
        <li>Started On</li>
        <li>Departure port</li>
        <li>Last Activity</li>
        <li>Type</li>
        <li>Last Activity Date</li>
        <li>Last Activity Received on</li>
        <li>On going</li>
        <li>Days at the sea</li>
        <li>Actions</li>
      </ul>
    </p>
    <p>
      <img src={table} alt="trip grid " />
    </p>
    <p>
      Also, in the header of the grid,
      there is a + button that allows the user to see the additional columns listed below:
      <ul>
        <li>UVI</li>
        <li>EXT</li>
        <li>IRCS</li>
        <li>ICCAT</li>
        <li>GFCM</li>
      </ul>
    </p>
    <p>
      <img src={moreColumns} alt="More columns" />
    </p>
    <p>
      In every row, by pressing the button (with an arrow icon) on the left,
      the reports associated to the trip are displayed.
    </p>
    <p>
      <img src={reportsInTrip} alt="Reports in trip" />
    </p>
    <p>
      To get more information about the trip or its reports,
      the user shall click on the corresponding button in the &quot;Actions&quot; column,
      and then select the option &quot;View Trip Report Details&quot; or
      &quot;View Report Details&quot; respectively.
      Alternatively, the user can double click on the trip.
    </p>
    <p>
      <img src={tripActions} alt="Trip Actions" />
    </p>
    <p>
      A modal window shows the trip details:
    </p>
    <p>
      <img src={tripDetails} alt="Trip Details" />
    </p>
  </div>
);

export default TRIP_DATA_TABLE;
