import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const InputText = ({
  id,
  value,
  readOnly,
  onChange,
  setIsValid,
  isValid = true,
  required,
  className,
  maxLength,
  minLength,
  complex = false,
  hasAction = true,
  // errorsDrools = [],
  ...props
}) => {
  const dispatch = useDispatch();
  const debouncedSave = (valueInput) => {
    if (!complex) {
      dispatch(onChange(id, valueInput));
    } else {
      dispatch(onChange(props.complexName, id, props.index, valueInput));
    }
    if (required && !isValid && valueInput !== '') {
      setIsValid(id, true);
    }
  };
  const debounceFn = useCallback(debounce(debouncedSave, 500), []);

  const handleChange = useCallback((e) => (
    hasAction ? debounceFn(e.target.value) : onChange(id, e.target.value)
  ), [hasAction]);

  return (
    <div className={className}>
      <Form.Control
        id={id}
        type="text"
        title={value !== null ? value : ''}
        disabled={readOnly}
        maxLength={maxLength}
        minLength={minLength}
        defaultValue={value !== null ? value : ''}
        onChange={handleChange}
        style={{
          border: isValid ? '' : '0.125rem solid red',
        }}
        {...props}
      />
    </div>
  );
};
InputText.defaultProps = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  complex: false,
  complexName: PropTypes.string,
  index: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  hasAction: true,
};

InputText.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  complex: PropTypes.bool,
  complexName: PropTypes.string,
  index: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  hasAction: PropTypes.bool,
};

export default InputText;
