import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { Form, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import DragAndDropFile from '@components/DragAndDropFile/DragAndDropFile';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { apiFetchVesselGroupsFromFile } from '@services/vessels/api';

import { dispatchErrorMessage, dispatchSuccessMessage } from '../../../../common/utilities/Notification';

import './style.scss';

const ImportVesselGroups = ({ setShowModal }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleDataFromFile = async ({ fileName }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      'file',
      fileName,
    );
    const groupsFromFile = await apiFetchVesselGroupsFromFile(formData);
    if (groupsFromFile.ok) {
      const groupsArray = await groupsFromFile.json();
      dispatchSuccessMessage(`${groupsArray.length} vessel groups have been created`);
    } else {
      dispatchErrorMessage('An error has ocurred uploading the file');
    }
    setLoading(false);
    setShowModal(false);
  };

  const onChangeDragDropHandler = useCallback(
    (event, setFieldValue, values) => handleDataFromFile({
      fileName: event.target.files[0],
      setFieldValue,
      vessels: values.vessels,
    }),
    [],
  );

  return (
    <Container fluid id="vessel-groups-importer">
      <FieldGroup label={t(tSchema.dmaAdmin.vesselGroupMGMT.import.loadVesselGroupsFromFile)}>
        <Formik
          enableReinitialize
          initialValues={{}}
        >
          {({
            values,
            setFieldValue,
          }) => (
            <Form>
              <DragAndDropFile
                onChange={(event) => {
                  onChangeDragDropHandler(event, setFieldValue, values);
                }}
              />
            </Form>
          )}
        </Formik>
      </FieldGroup>
      {loading && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">{t(tSchema.common.loading)}</span>
        </div>
      </div>
      )}
    </Container>
  );
};
ImportVesselGroups.propTypes = {
  vesselGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
  }),
  setShowModal: PropTypes.func.isRequired,
};

ImportVesselGroups.defaultProps = {
  vesselGroup: null,
};

export default ImportVesselGroups;
