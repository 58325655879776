import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';
import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { DEPARTURE_LOCATION_PROPTYPES } from '../../constants';

const schema = tSchema.fishingActivities.logbookViewer.details;

const Location = ({ location }) => {
  const { t } = useTranslation();
  return (
    <Container fluid id="report-details-departure-locations">
      <Row>
        <Col md={3} sm={6} xs={12}>
          <TextInputField label={t(schema.port)} value={location?.port} />
          <TextInputField label={t(schema.country)} value={location?.country} />
          <TextInputField label={t(schema.farm)} value={location?.farm} />
          {location?.characteristics?.length > 0 && (
          <TextInputField
            label={t(schema.characteristicsDescription)}
            value={location?.characteristics[0]?.code}
          />
          )}
        </Col>
        <Col md={3} sm={6} xs={12}>
          {location?.characteristics?.length > 0 && (
          <TextInputField
            label={t(schema.characteristicsValue)}
            value={location?.characteristics[0]?.value}
          />
          )}
          <FieldGroup label={t(schema.position)}>
            <TextInputField label={t(schema.latitude)} value={location?.latitude} />
            <TextInputField label={t(schema.longitude)} value={location?.longitude} />
          </FieldGroup>
        </Col>
        <Col md={6} sm={12}>
          <FieldGroup label={t(schema.area)}>
            <Row>
              <Col md={6}>
                <TextInputField label={t(schema.faoArea)} value={location?.faoArea} />
                <TextInputField label={t(schema.statRectangle)} value={location?.statRectangle} />
                <TextInputField label={t(schema.effortZone)} value={location?.effortZone} />
              </Col>
              <Col md={6}>
                <TextInputField label={t(schema.territoryEez)} value={location?.territoryEez} />
                <TextInputField label={t(schema.managementArea)} value={location?.managementArea} />
                <TextInputField label={t(schema.rfmo)} value={location?.rfmo} />
              </Col>
            </Row>
          </FieldGroup>
        </Col>
      </Row>
    </Container>
  );
};

Location.propTypes = {
  location: DEPARTURE_LOCATION_PROPTYPES.isRequired,
};

export default Location;
