import actions from './img/actions.JPG';
import deleteGroup from './img/delete_group.JPG';

const SectionVesselGroupDelete = () => (
  <div className="SectionVesselGroupDelete">
    <p>
      To delete a vessel group, click on the &quot;Actions&quot; button
      of the corresponding row and then select the &quot;Delete&quot; action.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal window asks the user for confirmation on deleting the vessel group.
    </p>
    <img src={deleteGroup} alt="Delete Group" />
    <p>
      After deleting the vessel group,
      the group is removed from the list displayed in the results grid.
    </p>
  </div>
);

export default SectionVesselGroupDelete;
