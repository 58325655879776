import actions from './img/actions.png';
import availableGroups from './img/available_groups.png';
import currentGroups from './img/current_groups.png';
import groups from './img/groups_of_vessel.png';

const SectionVesselGroups = () => (
  <div className="subSection">
    <p>
      To see the vessel groups in which a vessel is included,
      in every row of the vessels grid, from the column ‘Actions’
      it is available the option with name &quot;Vessel groups&quot;
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      If the option &quot;Vessel groups&quot; is clicked,
      a new modal window opens containing the information of
      the corresponding vessel groups.
    </p>
    <p>
      <img src={groups} alt="groups" />
    </p>
    <p>
      In the list on the left, the user can see the list of groups of
      vessels in which the selected vessel is included.
      In the list displayed on the right, there are displayed
      the existing groups of vessels to which the user can add the selected vessel.
    </p>
    <p>
      In the list of the available groups,
      on the right of every group there is a button that
      allows the user to add the selected vessel to that group.
    </p>
    <p>
      <img src={availableGroups} alt="availableGroups" />
    </p>
    <p>
      In the list of the current groups (groups in which the selected vessel is currently included),
      on the right of some group names,
      there is a button to remove the selected vessel from that group.
    </p>
    <p>
      <img src={currentGroups} alt="currentGroups" />
    </p>
    <p>
      Those groups in the list of current groups,
      that do not display a button on the right,
      it means that those groups are dynamic and so vessels cannot be manually added
      or removed from them.
    </p>
  </div>
);

export default SectionVesselGroups;
