import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Collapse, Nav,
} from 'react-bootstrap';

import CategoryLinkName from './CategoryLinkName';

const MenuItem = ({
  url, activeKey, text, icon, links, topLevel, isOpen: defaultOpened,
}) => {
  const [isOpen, setIsOpen] = useState(topLevel || defaultOpened);

  // The top elements are not collapsable and allways opened
  useEffect(() => setIsOpen(topLevel || defaultOpened), [topLevel, defaultOpened]);

  const toggle = () => setIsOpen(!isOpen);

  const tabIndex = url ? '0' : null;
  const ariaCurrent = url && activeKey === url ? 'true' : null;
  // const collapsable = !topLevel && !url && links && links.length > 0;
  const collapsable = !url && links && links.length > 0;
  const collapserClassName = isOpen ? 'collapsed' : 'notcollapsed';
  const menuOptionClassName = !collapsable ? 'menu-option' : `menu-option collapsable ${collapserClassName}`;
  const className = topLevel ? `category ${menuOptionClassName}` : menuOptionClassName;
  return (
    <>
      <Nav.Link
        as="div"
        className={className}
        eventKey={url}
        key={url}
        tabIndex={tabIndex}
        aria-current={ariaCurrent}
        onClick={collapsable && toggle}
      >
        <CategoryLinkName icon={icon} text={text} />
      </Nav.Link>
      {links && (
        <Collapse in={isOpen}>
          <div className="links">
            {links.map((link) => <MenuItem key={link.text} {...link} activeKey={activeKey} />)}
          </div>
        </Collapse>
      )}
    </>
  );
};

MenuItem.propTypes = {
  topLevel: PropTypes.bool,
  isOpen: PropTypes.bool,
  activeKey: PropTypes.string.isRequired,
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  })),
};

MenuItem.defaultProps = {
  links: null,
  topLevel: false,
  isOpen: false,
  url: null,
};
export default MenuItem;
