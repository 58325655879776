import {
  TypeActionsAttributes,
  Status,
  fetchAllAttributes,
  fetchAttribute,
  putAttribute,
  postAttribute,
  removeAttribute,
} from './attributes';
import {
  TypeActionsForm,
  changeAttributeInformation,
  getTabs,
  cleanForm,
  validateForm,
  setIsValidAttributeField,
  openForm,
} from './form';

const Actions = {
  ...TypeActionsAttributes,
  ...TypeActionsForm,
};

export {
  Actions,
  Status,
  fetchAllAttributes,
  fetchAttribute,
  changeAttributeInformation,
  putAttribute,
  getTabs,
  postAttribute,
  cleanForm,
  validateForm,
  setIsValidAttributeField,
  removeAttribute,
  openForm,

};
