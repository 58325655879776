import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { DISCARD_AT_SEA_SCHEMA, DISCARD_AT_SEA_PROPTYPES } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const DiscardAtSea = ({ declarations, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(DISCARD_AT_SEA_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentDiscardAtSea, setCurrentDiscardAtSea] = useState([]);

  useEffect(() => setCurrentDiscardAtSea(
    (declarations && declarations[pageNumber - 1])
    || DISCARD_AT_SEA_SCHEMA,
  ),
  [declarations, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        title="Discard at Sea"
        versions={currentDiscardAtSea}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={declarations}
        onSelectPagination={setPageNumber}
      />
      <Row>
        <Col md={4} sm={6} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField label={t(schema.identification)} value={currentVersion?.identification} />
        </Col>
        <Col md={4} sm={6} xs={12}>
          <TextInputField label={t(schema.reasonCode)} value={currentVersion?.reason} />
          <TextInputField label={t(schema.reasonDescription)}>
            {currentVersion?.reasonDescription}
          </TextInputField>
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

DiscardAtSea.propTypes = {
  declarations: DISCARD_AT_SEA_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default DiscardAtSea;
