import { useEffect, useState, useCallback } from 'react';

import * as serviceApi from '@services/externalSources/api';

import { requestWithMessage } from '@common/utilities/Notification';

const useRecurrence = () => {
  const [recurrence, setRecurrence] = useState(null);

  const fetchRecurrence = useCallback(async () => {
    await requestWithMessage({
      errorMessage: 'Error fetching recurrence',
      request: () => serviceApi.getRecurrencesOption(),
      onSuccess: async (response) => {
        const data = await response.json();
        setRecurrence(data);
      },
    });
  }, []);

  useEffect(() => {
    fetchRecurrence();
  }, [fetchRecurrence]);

  return { recurrence };
};

export default useRecurrence;
