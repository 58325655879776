import { actionCreator } from '../utils';

const createActionType = actionCreator('AUTHENTICATION');

export const CHECK_LOGIN_SESSION = createActionType('CHECK_LOGIN_SESSION').REQUEST;
export const checkLoginSession = (externalLogin) => ({
  type: CHECK_LOGIN_SESSION,
  payload: { externalLogin },
});

export const UPDATE_LOGED_IN = createActionType('UPDATE_LOGED_IN').REQUEST;
export const updateLogedIn = (logedIn) => ({
  type: UPDATE_LOGED_IN,
  payload: logedIn,
});

export const USER_LOGIN = createActionType('USER_LOGIN').REQUEST;
export const userLogin = () => ({
  type: USER_LOGIN,
});

export const USER_LOGOUT = createActionType('USER_LOGOUT').REQUEST;
export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const LOGOUT = createActionType('LOGOUT').REQUEST;
export const logout = () => ({
  type: LOGOUT,
});

export const SET_USERNAME = createActionType('SET_USERNAME').REQUEST;
export const setUserName = (userName) => ({
  type: SET_USERNAME,
  payload: userName,
});

export const SET_ROLES = createActionType('SET_ROLES').REQUEST;
export const setRoles = (roles) => ({
  type: SET_ROLES,
  payload: roles,

});
export const SET_PERMISSIONS = createActionType('SET_PERMISSIONS').REQUEST;
export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});
