import { mapObjIndexed, mergeAll, sort } from 'ramda';

import { sortCountries } from '@common/utils';

export const COUNTRIES_GROUP_BY = {
  EUROPEAN_COUNTRIES: 'EUROPEAN COUNTRIES',
  REST_OF_THE_WORLD: 'REST OF THE WORLD',
};
export const addGroupByToCountries = (countries, { memberState }) => {
  let identifier = 'id';
  if (memberState.length > 0) { identifier = memberState[0].id ? 'id' : 'code'; }
  const results = memberState && countries.map((country) => ({
    ...country,
    groupBy: memberState.some((element) => element[identifier] === country[identifier])
      ? COUNTRIES_GROUP_BY.EUROPEAN_COUNTRIES : COUNTRIES_GROUP_BY.REST_OF_THE_WORLD,
  }));
  return results ? [...results].sort(sortCountries) : countries;
};

export const FORM_DATA_TYPES = {
  VESSEL_PORT: 'vesselPort',
  BOOLEAN_TYPE: 'booleanType',
  GEAR_TYPE: 'gearType',
  VESSEL_TYPE: 'vesselType',
  VESSEL_EXPORT_TYPE: 'vesselExportType',
  VESSEL_PUBLIC_AID_TYPE: 'vesselPublicAidType',
  VESSEL_SEGMENT: 'vesselSegment',
  VESSEL_EVENT: 'vesselEvent',
  VESSEL_TERRITORY: 'vesselTerritory',
  VESSEL_THIRD_PARTY_CODE: 'vesselThirdPartyCode',
  VESSEL_STATUS: 'vesselStatus',
};
export const LABEL_SEPARATION = ' - ';
export const formatLabels = (labels) => labels.filter(Boolean).join(LABEL_SEPARATION) || '-';
export const formDataFieldFormat = (value, key) => {
  switch (key) {
    case FORM_DATA_TYPES.GEAR_TYPE:
    case FORM_DATA_TYPES.VESSEL_EVENT:
    case FORM_DATA_TYPES.VESSEL_TERRITORY:
    case FORM_DATA_TYPES.VESSEL_THIRD_PARTY_CODE:
    case FORM_DATA_TYPES.VESSEL_TYPE:
    case FORM_DATA_TYPES.VESSEL_EXPORT_TYPE:
    case FORM_DATA_TYPES.VESSEL_PUBLIC_AID_TYPE:
      return value.map((item) => ({
        ...item,
        comboLabel: formatLabels([item.code, item.description]),
        comboValue: item.code,
      }));
    case FORM_DATA_TYPES.VESSEL_PORT:
      return value.map((item) => ({
        ...item,
        comboLabel: formatLabels([item.country, item.code, item.description]),
        comboValue: item.code,
      }));
    case FORM_DATA_TYPES.VESSEL_SEGMENT:
      return value.map((item) => ({
        ...item,
        comboLabel: formatLabels([item.groupBy, item.description]),
        comboValue: item.id,
      }));
    case FORM_DATA_TYPES.BOOLEAN_TYPE:
      return value.map((item) => ({
        ...item,
        comboLabel: item.code,
        comboValue: item.code,
      }));
    case FORM_DATA_TYPES.VESSEL_STATUS:
      return value.map((item) => ({
        ...item,
        comboLabel: item.code,
        comboValue: item.code,
      }));
    default:
      return value.map((item) => ({
        ...item,
        comboLabel: item.description,
        comboValue: item.code,
      }));
  }
};

export const vesselTypeSortCode = (item) => formatLabels(
  [item.parentVessel, item.code],
);
export const vesselTypeCustomSort = (a, b) => {
  const [aVal, bVal] = [a, b].map(vesselTypeSortCode);
  return aVal.localeCompare(bVal);
};

const deleteNullsValues = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

/*
  Some Form Data needs an special format. This function is for formatting those formData.
*/
export const formatFormData = (formData) => {
  const formattedWithoutNull = deleteNullsValues(formData);

  const vesselTerritory = addGroupByToCountries(
    formattedWithoutNull.vesselTerritory,
    formattedWithoutNull,
  );
  const vesselType = sort(vesselTypeCustomSort, formattedWithoutNull.vesselType);

  const formDataFormatted = {
    ...formattedWithoutNull,
    vesselTerritory,
    vesselType,
  };

  return mapObjIndexed(formDataFieldFormat, formDataFormatted);
};

export const BOOLEAN_VALUES = {
  TRUE: 'true',
  FALSE: 'false',
};

export const trueFalseVal = (value) => {
  const lowerValue = value.toLowerCase();
  return Object.values(BOOLEAN_VALUES).includes(lowerValue)
    ? lowerValue === BOOLEAN_VALUES.TRUE : value;
};

export const customPropertiesMapper = (customProperties) => mergeAll(customProperties
  .map((cp) => ({ [cp.name]: trueFalseVal(cp.value) })));
