import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

const PaginationReceived = ({
  setCatches, setDonorVessels, receivedData, loading,
}) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState(1);
  const maxPosition = useMemo(() => receivedData && receivedData.length, [receivedData]);

  const handlerChangePosition = useCallback((newPosition) => {
    setCatches(receivedData[newPosition - 1].catchReceived);
    setDonorVessels(receivedData[newPosition - 1].donorVessels);
    setPosition(newPosition);
  }, []);

  const changePage = (i) => () => {
    if (!loading) {
      setPosition(i);
      handlerChangePosition(i);
    }
  };

  return (
    <div className="d-flex">
      <Pagination className="m-0">
        <Pagination.Item
          disabled={position === 1}
          onClick={changePage(1)}
          className="first fast-navigation"
        >
          &laquo;
          {' '}
          {t(tSchema.isrViewer.firstPage)}
        </Pagination.Item>

        <Pagination.Item
          disabled={position === 1}
          onClick={changePage(position - 1)}
          className="previous fast-navigation"
        >
          {`‹ ${t(tSchema.isrViewer.previousPage)}`}
        </Pagination.Item>
        <Pagination.Item
          disabled={position === maxPosition}
          onClick={changePage(position + 1)}
          className="next fast-navigation"
        >
          {`${t(tSchema.isrViewer.nextPage)} ›`}
        </Pagination.Item>
        <Pagination.Item
          displayName="Last"
          disabled={position === maxPosition}
          onClick={changePage(maxPosition)}
          className="last fast-navigation"
        >
          {t(tSchema.isrViewer.lastPage)}
          {' '}
          &raquo;
        </Pagination.Item>
      </Pagination>
    </div>
  );
};

PaginationReceived.propTypes = {
  setCatches: PropTypes.func.isRequired,
  setDonorVessels: PropTypes.func.isRequired,
  receivedData: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PaginationReceived;
