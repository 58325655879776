/* eslint-disable linebreak-style */
/* eslint-disable import/order */
import '../style.scss';

import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import {
  Button, Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '@common/dateUtils';
import { hasPermission, Permissions } from '@common/keycloakFunctions';
import { APPROVER_STATUS, getApproverStatus } from '@common/utils';
import IconSvg from '@components/IconSvg';
import tSchema from '@lang/schema';
import {
  approveVessel, cleanHistoric, cleanVesselForm, fetchAllVessels, setPagination,
} from '@services/vessel_record/actions/vessel';
import Flag from '../../../componentsRecord/Flags';
import VesselEditor from '../../../componentsRecord/VesselEditor';
import VesselHistoric from '../../../componentsRecord/VesselHistoric';

const VesselViewerModal = ({
  showModal,
  visualizationForm,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const entityVessel = useSelector((state) => state.vessel.entity || []);
  const selectedVessel = useSelector((state) => state.vessel.vessel || {});
  const searchableAttributes = useSelector((state) => state.vessel.searchableAttributes);
  const pagination = useSelector((state) => state.vessel.pagination);
  const historic = useSelector((state) => state.vessel.vesselHistoric?.content);
  const loadingHistoric = useSelector((state) => state.vessel.loadingHistoric);
  const userName = useSelector((state) => state.authentication.userName);
  const permissions = useSelector((state) => state.authentication.permissions);
  const isLoading = useSelector((state) => state.vessel.isLoading);
  const [showWarningHistoric, setShowWarningHistoric] = useState(false);

  const getVessels = async () => {
    await dispatch(fetchAllVessels({
      page: pagination.page,
      count: pagination.size,
      filtersBody: pagination.filtersBody,
    }));
    dispatch(setPagination({
      page: pagination.page,
      size: pagination.size,
      filtersBody: pagination.filtersBody,
    }));
  };

  const loadVessels = () => {
    if (searchableAttributes?.length > 0) {
      getVessels();
    }
  };

  const showHeader = useMemo(() => (visualizationForm !== 'CREATE' && Object.entries(selectedVessel).length !== 0),
    [visualizationForm, selectedVessel]);

  /**
   * When there is only one version of a vessel,
   * Show Historical button shows a warning modal.
   */
  useEffect(() => {
    if (visualizationForm === 'HISTORIC' && historic.length === 1) {
      setShowWarningHistoric(true);
    }
  }, [historic, visualizationForm]);

  return (
    <Modal
      backdrop="static"
      className="vessel-viewer-modal"
      show={showModal}
      onHide={() => {
        dispatch(cleanVesselForm());
        dispatch(cleanHistoric());
        setShowWarningHistoric(false);
        onClose();
      }}
      size="xl"
    >
      {loadingHistoric
        ? (
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
          </div>
        )
        : (
          <>
            {showWarningHistoric
              ? (
                <Modal.Header closeButton>
                  <Modal.Title>
                    This vessel does not have history
                  </Modal.Title>
                </Modal.Header>
              )
              : (
                <>
                  <Modal.Header className={['pb-0', !showHeader && 'no-header'].filter(Boolean).join(' ')} closeButton>
                    {
                showHeader && (
                  <Container
                    fluid
                    style={{
                      paddingBottom: '0.625rem',
                    }}
                  >
                    <Row
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <Col>
                        <Flag country={selectedVessel?.countryOfRegistration} />
                      </Col>
                      <Col>
                        <div className="header-columns">
                          <Form.Label>Name</Form.Label>
                          <p>{selectedVessel?.name || historic.vessel || ''}</p>
                        </div>
                        <div className="header-columns">
                          <Form.Label>IRCS</Form.Label>
                          <p>{selectedVessel?.ircs || ''}</p>
                        </div>
                      </Col>
                      <Col>
                        <div className="header-columns">
                          <Form.Label>CFR</Form.Label>
                          <p>{selectedVessel?.cfr || ''}</p>
                        </div>
                        <div
                          className="header-columns"
                        >
                          <Form.Label>External Marking</Form.Label>
                          <p>{selectedVessel?.externalMarking || ''}</p>
                        </div>

                      </Col>

                      <Col>
                        <div className="header-columns">
                          <Form.Label>UVI</Form.Label>
                          <p>{selectedVessel?.uvi || ''}</p>
                        </div>
                        <div className="header-columns">
                          <Form.Label>Validity Date</Form.Label>
                          <p>{formatDate(selectedVessel?.validityDate, undefined, '', true)}</p>
                        </div>

                      </Col>

                      <Col xs={3}>
                        <div className="header-columns">
                          <Form.Label>Reported Event</Form.Label>
                          <p>{selectedVessel?.reportedEvent || ''}</p>
                        </div>
                        <div className="header-columns">
                          <Form.Label>
                            {
                            getApproverStatus(selectedVessel?.approverStatus)
}
                          </Form.Label>
                        </div>
                      </Col>
                    </Row>
                    {
                    ['EDIT', 'VIEW'].includes(visualizationForm)
                    && hasPermission(permissions, Permissions.APPROVE_MODIFICATIONS)
                    && getApproverStatus(selectedVessel?.approverStatus)
                    === APPROVER_STATUS.PENDING_CONFIRMATION
                    && (
                    <Row style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '0.938rem',
                      marginBottom: '0.938rem',
                    }}
                    >
                      <div style={{ marginTop: '50px' }}>
                        <Col>
                          <Row>
                            <Form.Label>Are you sure you want to confirm this vessel?</Form.Label>
                          </Row>
                          <Row style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          >
                            <div>
                              <Button
                                style={{ height: '35px' }}
                                onClick={() => {
                                  dispatch(approveVessel(
                                    {
                                      userName,
                                      vesselId: selectedVessel.etalonId,
                                      validFrom: selectedVessel.validFrom,
                                      validTo: selectedVessel.validTo,
                                    },
                                  ));
                                  //   selectedVessel.etalonId, true);
                                }}
                              >
                                Confirm
                                <IconSvg name="check-thin " />
                              </Button>
                            </div>
                          </Row>
                        </Col>
                      </div>
                    </Row>
                    )
                  }
                  </Container>
                )
                }
                  </Modal.Header>
                  <Modal.Body className="pt-0">
                    {
        entityVessel.length !== 0 && (
        <VesselEditor
          loading={isLoading}
          visualization={visualizationForm}
          searchableAttributes={searchableAttributes}
          onSavedEdition={loadVessels}
          onClose={() => { onClose(); dispatch(cleanHistoric()); }}
        />
        )
}
                    {
          historic.length !== 0 && entityVessel.length === 0 && (
          <>
            <VesselHistoric etalonId={selectedVessel?.etalonId} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ marginRight: '0.375rem' }}
                onClick={onClose}
              >
                {t(tSchema.common.close)}
              </Button>
            </div>
          </>

          )
      }
                  </Modal.Body>
                </>
              )}
          </>
        )}
    </Modal>
  );
};

VesselViewerModal.defaultProps = {
  showModal: PropTypes.instanceOf(Object),
  visualizationForm: PropTypes.string,
  onClose: null,

};

VesselViewerModal.propTypes = {
  showModal: PropTypes.instanceOf(Object),
  visualizationForm: PropTypes.string,
  onClose: PropTypes.func,
};

export default VesselViewerModal;
