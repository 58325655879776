import PropTypes from 'prop-types';
import { path, prop } from 'ramda';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

export default function DomainSelectField({
  getOptions, disabled, rule, onChange,
}) {
  const { t } = useTranslation();

  const domainOptions = (domainOption) => prop('code', domainOption);
  const { createEditView: schema } = tSchema.dmaAdmin.brEngine;
  return (
    <Form.Group as={Col} controlId="formDomain">
      <Form.Label>
        {t(schema.domain)}
      </Form.Label>
      <Form.Control
        required
        as="select"
        name="domain"
        defaultValue={path(['domain', 'id'])(rule)}
        disabled={disabled}
        onChange={onChange}
      >
        {getOptions('brdomain', 'id', domainOptions)}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(schema.validation.emptyDomain)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

DomainSelectField.propTypes = {
  getOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired,
};
