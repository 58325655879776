import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import createButton from './img/createButton.JPG';
import createScenarioModal from './img/createScenarioModal.JPG';
import createScenarioModalIsr from './img/createScenarioModalIsr.JPG';
import error from './img/error.JPG';

import { DOMAIN } from '@common/utils';

const SectionScenariosCreate = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <>
      <div className="SectionScenariosCreate">
        <p>
          To create a new scenario, the user must click on the &quot;Create scenario&quot; button,
          displayed on the bottom right corner of the central panel.
        </p>
        <p>
          <img src={createButton} alt="Create button" />
        </p>
        <p>
          This button opens a modal with the following related fields:
        </p>
        {domain === DOMAIN.FA
          ? (
            <p>
              <img src={createScenarioModal} alt="Scenario create modal" />
            </p>
          )
          : (
            <p>
              <img src={createScenarioModalIsr} alt="Scenario create modal" />
            </p>
          )}
        <p>
          Once the form is filled and the user clicks the &quot;Save&quot; button,
          if there are any errors in the form, they are highlighted in red.
        </p>
        <p>
          <img src={error} alt="Error in the form" />
        </p>
      </div>
    </>
  );
};

export default SectionScenariosCreate;
