import * as yup from 'yup';

const schemaValidatation = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  searchVessel: yup.object().shape({
    cfr: yup.string()
      .matches(/^[A-Za-z]{3,3}[0-9]{9,9}/, 'Format must be AAA0000001'),

    uvi: yup.string()
      .matches(/^[0-9]{2,12}/, 'UVI must be exactly 12 numbers'),
  }),
});

export default schemaValidatation;
