import { formatDate, valueOfDate } from '@common/dateUtils';

const postProcessDateMillis = ({ from, to, ...filterProps }) => (
  {
    ...filterProps,
    value: from && to ? `${valueOfDate(from)}/${valueOfDate(to)}` : null,
  }
);

const previewProcessDateMillis = (filterProps) => {
  const dates = filterProps
    .split('/')
    .map((date) => (formatDate(+date, undefined, date, true)));
  return `from ${dates[0]} to ${dates[1]}`;
};

const isReportCancelled = ({ version, purposeVersion }) => (version === '2' && purposeVersion === '9')
|| (version === '2' && purposeVersion === '5');
const isReportDeleted = ({ version, purposeVersion }) => (version === '3' && purposeVersion === '9')
|| (version === '3' && purposeVersion === '5');
const isReportEdited = ({ version, purposeVersion }) => ((version === '4' && purposeVersion === '5') || (version === '1' && purposeVersion === '5'));
const isOriginalReport = ({ version, purposeVersion }) => ((version === '4' && purposeVersion === '9') || (version === '1' && purposeVersion === '9'));

export {
  postProcessDateMillis,
  previewProcessDateMillis,
  isReportCancelled,
  isReportDeleted,
  isReportEdited,
  isOriginalReport,
};
