import xml from 'highlight.js/lib/languages/xml';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import HighlightText from '@components/HighlightText';

import './style.scss';

const formatXml = (xmlStr, tab = '\t') => {
  let formatted = '';
  let indent = '';
  xmlStr.split(/>\s*</).forEach((node) => {
    if (node.match(/^\/\w/)) indent = indent.substring(tab.length);
    formatted += `${indent}<${node}>\r\n`;
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab;
  });
  return formatted.substring(1, formatted.length - 3);
};

const ReportMessageModal = ({ data, onClose }) => {
  const [show, setShow] = useState(null);

  useEffect(() => {
    setShow(data !== null);
  }, [data]);

  return data && (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        onClose();
        setShow(false);
      }}
      size="xl"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-0">
        <HighlightText language={xml}>
          {data.message ? formatXml(data.message, '    ') : ''}
        </HighlightText>
      </Modal.Body>
    </Modal>
  );
};

ReportMessageModal.propTypes = {
  data: PropTypes.shape({ message: PropTypes.string.isRequired }),
  onClose: PropTypes.func,
};

ReportMessageModal.defaultProps = {
  data: null,
  onClose: () => {},
};

export default ReportMessageModal;
