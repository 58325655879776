import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import RowFieldTable from './components/RowFieldTable';

const RowTable = ({
  rowData,
  tableColumns,
  isHoverRow,
  onRowSelected: handleRowSelected,
  isHiddenData,
  showCancelledDeleted,
  isCancelledDeleted,
  versionSymbol,
  isTripReports,
}) => {
  const renderRowContent = () => {
    if (isTripReports) {
      if (showCancelledDeleted) {
        return (
          tableColumns.map((columnConfig, index) => (
            <RowFieldTable
              key={columnConfig.name}
              isFirstField={index === 0}
              columnConfig={columnConfig}
              rowData={rowData}
              isCancelledDeleted={isCancelledDeleted}
              versionSymbol={versionSymbol}
            />
          ))
        );
      }
      return (
        tableColumns.filter(() => !isCancelledDeleted)
          .map((columnConfig, index) => (
            <RowFieldTable
              key={columnConfig.name}
              isFirstField={index === 0}
              columnConfig={columnConfig}
              rowData={rowData}
              versionSymbol={versionSymbol}
            />
          ))
      );
    }

    return (
      tableColumns.map((columnConfig) => (
        <RowFieldTable
          key={columnConfig.name}
          columnConfig={columnConfig}
          rowData={rowData}
          isFieldHidden={columnConfig.hidden && isHiddenData}
        />
      ))
    );
  };

  const handleClickRow = useCallback(
    (event) => handleRowSelected && handleRowSelected(rowData, event),
    [rowData],
  );

  return (
    <tr
      key={`row${rowData.id}`}
      className={`cursor-pointer ${isHoverRow ? 'active' : ''}`}
      onClick={handleClickRow}
    >
      {renderRowContent()}
    </tr>
  );
};

RowTable.propTypes = {
  rowData: PropTypes.PropTypes.shape({ id: PropTypes.string }).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHoverRow: PropTypes.bool,
  onRowSelected: PropTypes.func,
  isHiddenData: PropTypes.bool,
  showCancelledDeleted: PropTypes.bool,
  isCancelledDeleted: PropTypes.bool,
  versionSymbol: PropTypes.string,
  isTripReports: PropTypes.bool,
};

RowTable.defaultProps = {
  isHoverRow: false,
  onRowSelected: null,
  isHiddenData: false,
  showCancelledDeleted: false,
  isCancelledDeleted: false,
  isTripReports: false,
  versionSymbol: '',
};

export default RowTable;
