import Attributes from './attributes';
import Form from './form';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  attributes: [],
  attributeOriginal: {},
  attributeSelected: {

    customProperties: {},
    description: '',
    displayName: '',
    name: '',
    rights: {
      createdAt: '',
      updatedAt: '',
    },
    searchable: false,
    simpleDataType: '',
    unique: false,

  },
  responseModification: '',
  tabs: [],
  isValidForm: false,
  inputFieldsValidate: { name: { valid: true, error: '' }, displayName: { valid: true, error: '' } },
  modalShow: false,
};

const Managers = {
  ...Attributes,
  ...Form,
};

export default (state = initialState, action = { type: '' }) => {
  const finded = Managers[action.type];
  return finded ? finded(state, action) : { ...state };
};
