import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

function DatesFormField({
  getDateForInput, rule, disabled, dateChangeHandler, errors, startDate, endDate,
}) {
  const { t } = useTranslation();
  return (
    <Form.Row>
      <Form.Group as={Col} controlId="formStartDate">
        <Form.Label>
          {t(tSchema.dmaAdmin.brEngine.createEditView.startDate)}
        </Form.Label>
        <InputDate
          name="startDate"
          id="startDate"
          className="timeframe-control"
          required
          value={startDate || getDateForInput(rule.startDate) || ''}
          disabled={disabled}
          isInvalid={errors.dateStartError}
          maxDate={endDate}
          onChange={(id, value) => dateChangeHandler({ target: { name: id, value } })}
          complex={false}
          hasAction={false}
        />
        <Form.Control
          required
          name="startDate"
          value={getDateForInput(startDate || rule.startDate) || ''}
          disabled={disabled}
          isValid={!errors.dateStartError}
          hidden
        />
        <Form.Control.Feedback type="invalid">
          {errors.dateStartError}
          {t(tSchema.dmaAdmin.brEngine.createEditView.validation.emptyStartDate)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} controlId="formEndDate">
        <Form.Label>
          {t(tSchema.dmaAdmin.brEngine.createEditView.endDate)}
        </Form.Label>
        <InputDate
          name="endDate"
          id="endDate"
          className="timeframe-control"
          required
          value={endDate || getDateForInput(rule.endDate) || ''}
          disabled={disabled}
          minDate={startDate}
          isValid={!errors.dateEndError}
          show
          onChange={(id, value) => dateChangeHandler({ target: { name: id, value } })}
          complex={false}
          hasAction={false}
        />
        <Form.Control
          required
          name="endDate"
          value={getDateForInput(endDate || rule.endDate) || ''}
          disabled={disabled}
          isInvalid={errors.dateEndError}
          onChange={dateChangeHandler}
          hidden
        />
        <Form.Control.Feedback type="invalid">
          {errors.dateEndError}
          {t(tSchema.dmaAdmin.brEngine.createEditView.validation.emptyEndDate)}
        </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
}

DatesFormField.propTypes = {
  getDateForInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  dateChangeHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,

};

export default DatesFormField;
