import SectionAddQueryAcceptance from './SectionAddQueryAcceptance';
import SectionAddReportAcceptance from './SectionAddReportAcceptance';
import SectionDeleteQueryAcceptance from './SectionDeleteQueryAcceptance';
import SectionDeleteReportAcceptance from './SectionDeleteReportAcceptance';
import SectionEditQueryAcceptance from './SectionEditQueryAcceptance';
import SectionEditReportAcceptance from './SectionEditReportAcceptance';
import SectionQueryAcceptance from './SectionQueryAcceptance';
import SectionReportAcceptance from './SectionReportAcceptance';
import SectionViewQueryAcceptance from './SectionViewQueryAcceptance';
import SectionViewReportAcceptance from './SectionViewReportAcceptance';

const ACCEPTANCE_BR = {
  TABLE_QUERY: {
    title: 'Query Acceptance Grid',
    body: <SectionQueryAcceptance />,
  },
  TABLE_REPORT: {
    title: 'Report Acceptance Grid',
    body: <SectionReportAcceptance />,
  },
  CREATE_QUERY: {
    title: 'Create Query Acceptance',
    body: <SectionAddQueryAcceptance />,
  },
  CREATE_REPORT: {
    title: 'Create Report Acceptance',
    body: <SectionAddReportAcceptance />,
  },
  EDIT_QUERY: {
    title: 'Edit Query Acceptance',
    body: <SectionEditQueryAcceptance />,
  },
  EDIT_REPORT: {
    title: 'Edit Report Acceptance',
    body: <SectionEditReportAcceptance />,
  },
  DELETE_QUERY: {
    title: 'Delete Query Acceptance',
    body: <SectionDeleteQueryAcceptance />,
  },
  DELETE_REPORT: {
    title: 'Delete Report Acceptance',
    body: <SectionDeleteReportAcceptance />,
  },
  VIEW_QUERY: {
    title: 'View Query Acceptance',
    body: <SectionViewQueryAcceptance />,
  },
  VIEW_REPORT: {
    title: 'View Report Acceptance',
    body: <SectionViewReportAcceptance />,
  },

};

export default ACCEPTANCE_BR;
