import getRoutePaths from '@pages/App/utils/routePaths';

import { faCoordinators, vrCoordinators, irCoordinators } from './UserCategories/coordinators';
import { faDataAdmin, vrDataAdmin, irDataAdmin } from './UserCategories/dataAdmin';
import systemAdmin from './UserCategories/systemAdmin';

import { DOMAIN } from '@common/utils';

const getDataAdminCategories = ({ domain, routes }) => {
  switch (domain) {
    case DOMAIN.FA:
      return faDataAdmin({ routes });
    case DOMAIN.VESSEL:
      return vrDataAdmin({ domain, routes });
    case DOMAIN.ISR:
      return irDataAdmin({ routes });
    default:
      return [];
  }
};

const getCoordinatorsCategories = ({ domain, routes }) => {
  switch (domain) {
    case DOMAIN.FA:
      return faCoordinators({ routes });
    case DOMAIN.VESSEL:
      return vrCoordinators({ routes });
    case DOMAIN.ISR:
      return irCoordinators({ routes });
    default:
      return [];
  }
};

const categories = (domain, roles, t) => {
  const routes = getRoutePaths(t);
  switch (true) {
    case domain && (roles.isSuperUser || roles.isSystemAdmin):
      return [
        ...systemAdmin({ routes }),
        ...getDataAdminCategories({ domain, routes }),
        ...getCoordinatorsCategories({ domain, routes }),
      ];
    case domain && roles.isDataAdmin:
      return [
        ...systemAdmin({ routes, isDataAdmin: roles.isDataAdmin }),
        ...getDataAdminCategories({ domain, routes }),
        ...getCoordinatorsCategories({ domain, routes })];
    case domain && roles.isISRSystemAdmin:
      return [
        ...systemAdmin({ routes }),
        ...getDataAdminCategories({ domain, routes }),
        ...getCoordinatorsCategories({ domain, routes }),
      ];
    case domain && roles.isISRDataAdmin:
      return [
        ...systemAdmin({ routes, isISRDataAdmin: roles.isISRDataAdmin }),
        ...getDataAdminCategories({ domain, routes }),
        ...getCoordinatorsCategories({ domain, routes }),
      ];
    case domain && roles.isCoordinators:
      return getCoordinatorsCategories({ domain, routes });
    default:
      return [];
  }
};

export default categories;
