import PropTypes from 'prop-types';
import React, {
  useRef, useState, useCallback, useMemo,
  useEffect,
} from 'react';
import {
  Container, Form, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';
import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import * as apiService from '@services/failover/api';
import { getReportTabs, uploadAttachment } from '@services/isr/reports/api';

import getTableConfig from './utils';

import { Permissions, hasPermission } from '@common/keycloakFunctions';
import { requestWithMessage, showErrorNotification, showWarningNotification } from '@common/utilities/Notification';
import {
  clearEventTargetValue, convertSizeStringToBytes,
} from '@common/utils';

const IsrViewerDetailsTabAttachments = ({
  selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
}) => {
  const attachmentsTableRef = useRef();
  const [attachmentsLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const [descriptionFile, setDescriptionFile] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [maxISRAttachmentSize, setMaxISRAttachmentSize] = useState(null);
  const permissions = useSelector((state) => state.authentication.permissions);
  const { t } = useTranslation();
  const inputOpenFileRef = React.createRef();
  const [loading, setLoading] = useState(false);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['attachmentsTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.attachmentsTab);
        const versionData = getVersionData(data.attachmentsTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  const onSuccessDelete = useCallback(async () => {
    triggerDataFetch();
  });

  const attachmentsTableConfig = useMemo(
    () => getTableConfig(t, onSuccessDelete, permissions), [t],
  );

  const handlerUploadAttachment = useCallback(async () => {
    await requestWithMessage({
      request: () => uploadAttachment(attachment, selectedUuid, selectedRecipient, descriptionFile),
      showCustomResponseErrorMessage: false,
      onError: async (response) => {
        if (response?.response?.data === 'file type not allowed') {
          showErrorNotification('The file extension of the uploaded file is not supported');
        }
      },
      onSuccess: async () => {
        triggerDataFetch();
        setDescriptionFile('');
        setAttachment(null);
      },
    });
  });

  const fetchMaxAttachmentSize = useCallback(() => {
    requestWithMessage({
      errorMessage: t(tSchema.isrViewer.errorFailoverParam),
      request: () => apiService.getParameter({ parameter: 'ISRMaxAttachmentSize' }),
      onSuccess: async (response) => {
        const data = await response.json();
        setMaxISRAttachmentSize(data);
      },
    });
  });

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) {
      triggerDataFetch();
      fetchMaxAttachmentSize();
    }
  }, [
    selectedUuid, selectedRecipient, isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  return (
    <>

      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <div>
          <FieldGroup label={t(tSchema.isrViewer.attachments)}>
            <Container fluid className="isrAttachments">
              <DataTable
                rowData={tabData?.attachments}
                idColumn="validityDate"
                tableId="isrInspectorsTable"
                ref={attachmentsTableRef}
                tableConfig={attachmentsTableConfig}
                loading={attachmentsLoading}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
          <FieldGroup label={t(tSchema.isrViewer.addAttachments)}>
            <div className="isrAddAttachments">
              <div className="descriptionAddFile">
                <Form.Label>{t(tSchema.isrViewer.descriptionFile)}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={descriptionFile}
                  disabled={!hasPermission(permissions, Permissions.ISR_UPDATE_DATA)}
                  onChange={({ target: { value } }) => setDescriptionFile(value)}
                />
              </div>
              <div className="buttonsAddFile">
                <Form.Control
                  type="file"
                  ref={inputOpenFileRef}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    if (e.target.files[0] != null) {
                      if (e.target.files[0].size > convertSizeStringToBytes(`${maxISRAttachmentSize.value} ${maxISRAttachmentSize.unit}`)) {
                        const errMsg = t(tSchema.isrViewer.errorFileBig,
                          {
                            value: maxISRAttachmentSize.value,
                            unit: maxISRAttachmentSize.unit,
                          });
                        showWarningNotification(errMsg);
                      } else {
                        setAttachment(e.target.files[0]);
                      }
                    }
                  }}
                  onClick={clearEventTargetValue}
                />
                {attachment?.name
                  ? (
                    <div title={`${attachment.name}`} className="inline-filename">
                      <b>
                        {attachment.name}
                      </b>
                    </div>
                  ) : null}
                <Button
                  onClick={() => inputOpenFileRef.current.click()}
                  className="inline-button"
                  disabled={!hasPermission(permissions, Permissions.ISR_UPDATE_DATA)}
                >
                  <IconSvg name="upload" className="no-margin" />
                </Button>
                <Button
                  onClick={() => handlerUploadAttachment()}
                  className="inline-button"
                  id="submitAttachment"
                  disabled={!attachment || !hasPermission(permissions, Permissions.ISR_UPDATE_DATA)}
                >
                  Add
                </Button>
              </div>
            </div>
          </FieldGroup>
        </div>
      )}
    </>
  );
};

IsrViewerDetailsTabAttachments.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabAttachments;
