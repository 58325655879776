import PropTypes from 'prop-types';
import { equals, find, propEq } from 'ramda';

import CheckAll from '@pages/VesselSearchGrid/components/CheckAll';
import CheckComponent from '@pages/VesselSearchGrid/components/CheckBoxComponent';

const TableCheckColumn = ({
  vessels,
  selectedRows,
  onSelectedRows: setSelectedRows,
  checkAdded,
}) => {
  const basicRowData = ({
    etalon_id: etalonId, uvi, cfr, ircs, name,
  }) => ({
    etalon_id: etalonId, uvi, cfr, ircs, name,
  });

  const basicDataVessels = () => vessels.map(basicRowData);

  const isSelected = (row) => Boolean(find(propEq('etalon_id', row.etalon_id))(selectedRows));

  const selectedAll = () => basicDataVessels().every((e) => isSelected(e));

  const handleSelectRow = (row, checked) => {
    setSelectedRows((prevState) => (checked
      ? [...prevState, ...([row])]
      : prevState.filter((item) => !equals(item, row))));
  };

  const handleSelectAll = (checked) => {
    setSelectedRows(
      (prevState) => (
        checked
          ? [...prevState, ...(basicDataVessels()?.filter((row) => !isSelected(row)))]
          : prevState.filter((prevRow) => !basicDataVessels().some((row) => equals(row, prevRow)))
      ),
    );
  };

  return {
    text: '',
    header: <CheckAll
      isSelected={selectedAll()}
      onSelect={handleSelectAll}
    />,
    disabled: true,
    code: 'check',
    render: (row) => {
      const basicRowDataRow = basicRowData(row);
      const rowSelected = isSelected(basicRowDataRow);
      const disabled = checkAdded(row);
      return (
        <CheckComponent
          row={basicRowDataRow}
          onSelectRowCheck={handleSelectRow}
          isSelected={disabled || rowSelected}
          disabled={disabled}
        />
      );
    },
  };
};

TableCheckColumn.propTypes = {
  vessels: PropTypes.arrayOf(PropTypes.shape({ })).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({ })).isRequired,
  onSelectedRows: PropTypes.func.isRequired,
  checkAdded: PropTypes.func.isRequired,
};

export default TableCheckColumn;
