import i18next from 'i18next';
import { useCallback } from 'react';

import tSchema from '@lang/schema';

import { showErrorNotification } from '@common/utilities/Notification';

const { t } = i18next;

export const useRequestWithLoading = () => {
  const requestWithLoading = useCallback(async (fn, loadingSetter) => {
    try {
      loadingSetter && loadingSetter(true);
      return await fn();
    } catch (err) {
      console.error(err);
      showErrorNotification(t(tSchema.notifications.common.error));
      return err;
    } finally {
      loadingSetter && loadingSetter(false);
    }
  }, []);

  return requestWithLoading;
};

export default {};
