import PropTypes from 'prop-types';
import { compose, map, sort } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';

import { DEFAULT_GROUP_LABEL } from '../../../componentsRecord/Filters/FiltersUtils';

import { REACT_SELECT } from '@common/utils';

import './style.scss';

const getOptionLabel = (option) => option.comboLabel
|| option.label || option.description || option.code || option.value;
const formatGroupLabel = (option, showGroupCount) => (
  <div className="selectGroup">
    {option.label}
    {showGroupCount && <span className="count">{option.options.length}</span>}
  </div>
);

export const getGroupedOptions = (valueOptions, customOrder) => {
  const groupByObject = valueOptions.reduce(
    (previousValue, currentValue) => (currentValue.groupBy
      ? {
        ...previousValue,
        [currentValue.groupBy]: [
          ...(previousValue[currentValue.groupBy] || []),
          currentValue,
        ],
      }
      : previousValue),
    {},
  );
  const returnGrouped = Object.keys(groupByObject).length > 0;
  const noGroupOptions = returnGrouped && valueOptions.filter((v) => !v.groupBy);
  const defaultGroup = (returnGrouped && noGroupOptions.length > 0) ? [{
    label: DEFAULT_GROUP_LABEL,
    options: noGroupOptions,
  }] : [];
  const groupedOptions = returnGrouped && compose(
    map((k) => ({ label: k, options: groupByObject[k] })),
    sort(customOrder || ((a, b) => a?.localeCompare(b))),
  )(Object.keys(groupByObject));

  return returnGrouped
    ? groupedOptions.concat(defaultGroup)
    : valueOptions;
};

const InputSelect = ({
  id,
  options,
  readOnly = false,
  value,
  onChange,
  isValid,
  isInvalid,
  showValidation,
  isLoading,
  setIsValid,
  required,
  className,
  complex = false,
  hasAction = true,
  placeholder,
  disabledPlaceholder,
  showGroupCount,
  customOrder,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleChange = useCallback((option) => {
    const { value: opValue } = option || {};
    if (hasAction) {
      !complex
        ? dispatch(onChange(id, opValue === undefined ? null : opValue))
        : dispatch(onChange(props.complexName, id, props.index, opValue));
      if (required && !isValid && opValue !== '') {
        dispatch(setIsValid(id, true));
      }
    } else {
      onChange(id, opValue === undefined ? null : opValue);
    }
  }, [hasAction, complex, required, isValid, id, props]);

  const valueOptions = useMemo(
    () => options.map((currentValue) => {
      const val = currentValue.comboValue || currentValue.code;
      // Acceps 0 as valid value
      const optionValue = val || val === 0 ? val : currentValue;
      return {
        ...currentValue,
        value: optionValue,
      };
    }),
    [options],
  );

  const groupedOptions = useMemo(() => getGroupedOptions(valueOptions, customOrder),
    [valueOptions, customOrder]);

  const selectedOption = useMemo(
    () => valueOptions.find((option) => `${option.value}` === `${value}`) || value,
    [valueOptions, value],
  );

  const formatGroupLabelWithCount = useCallback(
    (option) => formatGroupLabel(option, showGroupCount),
    [showGroupCount],
  );

  return (
    <div className={className}>
      <ReactSelect
        name={id}
        styles={REACT_SELECT.customStylesDefault(
          showValidation ? isValid : undefined, showValidation ? isInvalid : undefined,
        )}
        placeholder={(readOnly && disabledPlaceholder) ? disabledPlaceholder : placeholder}
        isDisabled={readOnly}
        isMulti={false}
        closeMenuOnSelect
        options={groupedOptions}
        getOptionLabel={getOptionLabel}
        formatGroupLabel={formatGroupLabelWithCount}
        value={selectedOption}
        onChange={handleChange}
        style={{
          border: isValid ? '' : '0.125rem solid red',
        }}
        isClearable={!required}
        isLoading={isLoading}
      />
    </div>
  );
};

InputSelect.defaultProps = {
  id: undefined,
  options: [],
  showGroupCount: false,
  // readOnly: false,
  value: undefined,
  onChange: () => null,
  isValid: undefined,
  isInvalid: undefined,
  showValidation: true,
  isLoading: undefined,
  setIsValid: () => null,
  required: undefined,
  className: undefined,
  complex: false,
  complexName: undefined,
  index: undefined,
  placeholder: undefined,
  disabledPlaceholder: undefined,
  customOrder: undefined,
};

InputSelect.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabledPlaceholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  showGroupCount: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  showValidation: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  complex: false,
  complexName: PropTypes.string,
  index: PropTypes.number,
  hasAction: PropTypes.bool.isRequired,
  customOrder: PropTypes.func,
};

export default InputSelect;
