/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { formatDateTime } from '@common/dateUtils';

import ToggleTable from '@components/DataTable/components/ToggleTable';

import tSchema from '@lang/schema';
import {
  isOriginalReport, isReportCancelled, isReportDeleted, isReportEdited,
} from '@pages/FishingTripsLogbook/common/utils';

const schema = tSchema.fishingActivities.logbookViewer.reportView;

const vesselFields = [
  'vesselFlag',
  'vesselName',
  'cfr',
  'uvi',
  'extMark',
  'ircs',
  'iccat',
  'gfcm',
];

function formatTableField(row, value, field) {
  let result = null;
  if (row) {
    result = value;
    if (row.version === '3' || row.version === '2') {
      result = (
        <div style={{ color: '#c1c1c1' }}>
          {`${value}`}
        </div>
      );
    } else if (row.isLinked) {
      if (vesselFields.includes(field)) {
        result = (
          <div style={{ color: '#01b4ec' }}>
            {`${value}`}
          </div>
        );
      }
    }
  }
  return result;
}

/* eslint-disable */
const formatActivityType = (faType, version, purposeVersion) => {
  if (isReportCancelled({version, purposeVersion})) {
    return (
      `${faType} (C)`
    );
  } else if (isReportDeleted({version, purposeVersion})) {
    return (
      `${faType} (D)`
    );
  } else if (isReportEdited({version, purposeVersion})) {
    return (
      <div>
        {`${faType} (*)`}
      </div>
    );
  } else if (isOriginalReport({version, purposeVersion})) {
    return (
      <div>
        {faType}
      </div>);
  } else {
    return null;
  }
};
/* eslint-enable */

export const tableConfig = (t, onToggle) => (toggled) => [
  {
    text: t(schema.name),
    code: 'vesselName',
    sortable: true,
    render: (row) => formatTableField(row, row.vesselName, 'vesselName'),
  },
  {
    text: t(schema.flagState), code: 'vesselFlag', sortable: true, render: (row) => formatTableField(row, row.vesselFlag, 'vesselFlag'),
  },
  {
    text: t(schema.cfr), code: 'cfr', sortable: true, render: (row) => formatTableField(row, row.vesselId, 'cfr'),
  },
  {
    text: t(schema.uvi),
    code: 'uvi',
    sortable: true,
    render: (row) => formatTableField(row, row.uvi, 'uvi'),
    hidden: !toggled,
  },
  {
    text: t(schema.ext),
    code: 'ext',
    sortable: true,
    render: (row) => formatTableField(row, row.extMark, 'extMark'),
    hidden: !toggled,
  },
  {
    text: t(schema.ircs),
    code: 'ircs',
    sortable: true,
    render: (row) => formatTableField(row, row.ircs, 'ircs'),
    hidden: !toggled,
  },
  {
    text: t(schema.iccat),
    code: 'iccat',
    sortable: true,
    render: (row) => formatTableField(row, row.iccat, 'iccat'),
    hidden: !toggled,
  },
  {
    text: t(schema.gfcm),
    code: 'gfcm',
    sortable: true,
    render: (row) => formatTableField(row, row.gfcm, 'gfcm'),
    hidden: !toggled,
  },
  {
    text: t(schema.toggle), header: <ToggleTable value={toggled} onClick={onToggle} />, code: 'toggle', render: () => '',
  },
  {
    text: t(schema.activityType),
    code: 'activityType',
    sortable: true,
    render: (row) => formatTableField(row,
      formatActivityType(row.faType, row.version, row.purposeVersion), 'purposeVersion'),
  },
  {
    text: t(schema.activityDate),
    code: 'activityDate',
    sortable: true,
    render: (row) => formatTableField(row, (formatDateTime(row.occurrenceDate) !== '-' ? `${formatDateTime(row.occurrenceDate)}, UTC` : '-'), 'occurrenceDate'),
  },
  {
    text: t(schema.reportType),
    code: 'reportType',
    sortable: true,
    render: (row) => formatTableField(row, row.reportType, 'reportType'),
  },
  {
    text: t(schema.receivedOn),
    code: 'receivedOn',
    sortable: true,
    render: (row) => formatTableField(row, (formatDateTime(row.notificationDate) !== '-' ? `${formatDateTime(row.notificationDate)}, UTC` : '-'), 'notificationDate'),
  },
  {
    text: t(schema.tripId),
    code: 'tripId',
    sortable: true,
    render: (row) => formatTableField(row, row.fishingTripId, 'fishingTripId'),
  },
  {
    text: t(schema.daysAtSea),
    code: 'daysAtSea',
    sortable: true,
    render: (row) => formatTableField(row, row.daysAtSea, 'daysAtSea'),
  },
];

export default tableConfig;
