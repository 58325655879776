import { identity } from 'ramda';

import tSchema from '@lang/schema';

const getRoutePaths = (t = identity) => ({
  trafficMonitoring: {
    url: '/DEM/traffic-monitoring',
    sections: {
      INCOMING: {
        url: 'incoming',
        title: t(tSchema.dem.trafficMonitoring.incoming),
      },
      OUTGOING: {
        url: 'outgoing',
        title: t(tSchema.dem.trafficMonitoring.outgoing),
      },
    },
  },
  failOverParameters: { url: '/DEM/failover-parameters' },
  dbAdministration: { url: '/db-administration' },
  servicesMonitoring: { url: '/services-monitoring' },
  operationalProcedure: { url: '/operation-procedure' },

  messageViewer: {
    url: '/DMA/message-viewer',
    sections: {
      INCOMING: {
        url: 'incoming',
        title: t(tSchema.dmaAdmin.messageViewer.incoming),
      },
      OUTGOING: {
        url: 'outgoing',
        title: t(tSchema.dmaAdmin.messageViewer.outgoing),
      },
    },
  },
  scenarioMGMT: {
    url: '/DMA/scenario-management',
    sections: {
      SCENARIOS: {
        url: 'scenarios',
        title: t(tSchema.dmaAdmin.scenarioMGMT.scenarios.scenarios),
      },
      USERS: {
        url: 'users',
        title: t(tSchema.dmaAdmin.scenarioMGMT.users.users),
      },
    },
  },
  validationBR: { url: '/DMA/BRE/validation-br' },
  acceptanceBR: { url: '/DMA/BRE/acceptance-br' },
  queryReportMGMT: {
    url: '/DMA/query-report-management',
    sections: {
      QUERY_SUBCRIPTIONS: {
        url: 'query-subscriptions',
        title: 'Query Subscription',
      },
      REPORT_SUBCRIPTIONS: {
        url: 'report-subscriptions',
        title: 'Report Subscription',
      },
    },
  },
  referenceDataMGMT: { url: '/DMA/reference-data-management' },
  vesselGroupMGMT: { url: '/DMA/vessel-group-management' },
  externalSourcesMGMT: { url: '/DMA/external-sources-management' }, // TODO: Preguntar
  dataAdministrationReporting: {
    url: '/DMA/data-administration-reporting',
    sections: {
      VALIDATION_REPORTS: {
        url: 'validation-reports',
        title: 'Validation Reports',
      },
      DATA_ACTIVITY: {
        url: 'data-activity',
        title: 'Data Activity',
      },
    },
  }, // TODO: Preguntar

  vesselMGMT: { url: '/vessel-management' },
  isrViewer: { url: '/ISR/report-viewer' },
  logbookViewer: {
    url: '/FA/logbook-viewer',
    sections: {
      REPORT_VIEW: {
        url: 'report-view',
        title: t(tSchema.fishingActivities.logbookViewer.reportView.reportView),
      },
      TRIP_VIEW: {
        url: 'trip-view',
        title: t(tSchema.fishingActivities.logbookViewer.tripView.tripView),
      },
    },
  },
  inspectionReportValidation: { url: '/ISR/inspection-report-validation' },
  inspectionReportQuery: { url: '/ISR/inspection-report-query' },
});

export default getRoutePaths;
