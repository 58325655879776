import { isEmpty } from 'ramda';
import { toast } from 'react-toastify';

import {
  put, post, formatSortParams, get,
} from '../utils';

export const editCurrentVersion = ({ vessel, confirm }) => {
  const param = { ...vessel };
  delete param.active;
  delete param.createDate;
  delete param.updateDate;

  const baseUrl = `vrService/vessel/version/${param.etalonId}`;
  const validToFromUrl = `validTo=${param.validTo}&validFrom=${param.validFrom}`;
  const url = confirm ? `${baseUrl}?${validToFromUrl}&statusType=CONFIRM` : `${baseUrl}?${validToFromUrl}`;
  return put()(url, param)
    .then((res) => {
      if (res.status === 200) {
        toast.success('Vessel version successfully updated');
      } else {
        toast.error('The vessel version could not be updated');
      }
    });
};

export const createNewVersion = ({ vessel, confirm }) => {
  const param = { ...vessel };
  delete param.active;
  delete param.createDate;
  delete param.updateDate;

  const baseUrl = `vrService/vessel/version/${param.etalonId}`;

  const url = confirm ? `${baseUrl}?statusType=CONFIRM` : baseUrl;
  return post()(url, param)
    .then((res) => {
      if (res.status === 200) {
        toast.success('Vessel version successfully created');
      } else {
        toast.error('The vessel could not be created');
      }
    });
};

export function postVessel({ vessel, confirm }) {
  const param = { ...vessel };
  delete param.active;
  delete param.createDate;
  delete param.updateDate;
  delete param.etalonId;
  if (!vessel.status) {
    param.status = 'Active';
  }
  const baseUrl = 'vrService/vessel';

  const url = confirm ? `${baseUrl}?statusType=CONFIRM` : baseUrl;

  return post()(url, param)
    .then((res) => {
      if (res.status === 200) {
        toast.success('The vessel has been created');
      } else {
        toast.error('The vessel could not be created');
      }
    });
}

/*
  Filtra los atributos complejos que se hayan creado en el formulario y no se hayan rellenado
  para que estos no se envíen.
*/
export function filterEmptyComplexAttr(vessel) {
  const filtVessel = { ...vessel };

  Object.keys(filtVessel).forEach(
    (key) => {
      if (filtVessel[key] == null || filtVessel[key] === undefined || key[0] === '$') {
        delete filtVessel[key];
      }
    },
  );

  filtVessel.thirdPartyVessel = filtVessel.thirdPartyVessel?.filter(
    (th) => th.identifier || th.code,
  );

  filtVessel.subsidiaryFishingGears = filtVessel.subsidiaryFishingGears?.filter((sf) => sf.value);

  filtVessel.vesselImage = filtVessel.vesselImage?.filter(
    (sf) => sf.photoType || sf.photoId || sf.photo || sf.description || sf.date,
  );

  return {
    ...filtVessel,
  };
}

export const apiFetchDataActivity = (searchFilters, params) => {
  const filter = searchFilters && !isEmpty(searchFilters) ? `/${searchFilters}` : '';
  return get()(`vrService/activity${filter}`, formatSortParams(params));
};
