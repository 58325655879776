import { useMemo } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import {
  DEPARTURE_GEARS_PROPTYPES,
  DEPARTURE_GEAR_PROPTYPES,
} from '../../constants';
import BasicTable from '../tables/BasicTable';

const schema = tSchema.fishingActivities.logbookViewer.details;

export const getGearsTableHeaders = (t) => [
  { text: t(schema.characteristic), code: 'characteristicType', sortable: false },
  { text: t(schema.quantity), code: 'quantity', sortable: false },
  { text: t(schema.measureMmt), code: 'measureMMT', sortable: false },
  { text: t(schema.measureMtr), code: 'measureMTR', sortable: false },
  { text: t(schema.description), code: 'text', sortable: false },
  { text: t(schema.code), code: 'code', sortable: false },
];

const FishingGear = ({ gear }) => {
  const { t } = useTranslation();
  const gearsTableHeaders = useMemo(() => getGearsTableHeaders(t), [t]);
  return (
    <div className="mb-3">
      <Row>
        <Col sm="auto" xs={12}>
          <TextInputField label="Type Code">{gear?.type}</TextInputField>
        </Col>
        <Col sm="auto" xs={12}>
          <TextInputField label="Type Description">{gear?.typeDescription}</TextInputField>
        </Col>
        <Col sm="auto" xs={12}>
          <TextInputField label="Role">{gear?.role}</TextInputField>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <BasicTable
            data={gear?.gearCharacteristics || []}
            headers={gearsTableHeaders}
          />
        </Col>
      </Row>
    </div>
  );
};

FishingGear.propTypes = {
  gear: DEPARTURE_GEAR_PROPTYPES.isRequired,
};

const FishingGears = ({ gears }) => {
  const multipleItems = !!(gears && gears?.length);
  return (
    <Container fluid id="report-details-fishing-gears">
      {multipleItems ? gears.map((e, index) => (
        <>
          <h6 className="title">
            {`Fishing gear ${index + 1}`}
          </h6>
          <FishingGear gear={e} />
        </>
      )) : (
        <FishingGear gear={gears} />
      )}
    </Container>
  );
};

FishingGears.propTypes = {
  gears: DEPARTURE_GEARS_PROPTYPES.isRequired,
};
export default FishingGears;
