import SectionAddVessel from './SectionAddVessel';
import SectionCompareVersions from './SectionCompareVersions';
import SectionEditVessel from './SectionEditVessel';
import SectionExport from './SectionExport';
import SectionImportFrom from './SectionImportFrom';
import SectionVesselGroups from './SectionVesselGroups';
import SectionViewVessel from './SectionViewVessel';

const VESSEL_MGMT = {
  ADD_VESSEL: {
    title: 'Add new vessel',
    body: <SectionAddVessel />,
  },
  EDIT_VESSEL: {
    title: 'Edit vessel',
    body: <SectionEditVessel />,
  },
  EXPORT: {
    title: 'Export to excel',
    body: <SectionExport />,
  },
  IMPORT_FROM: {
    title: 'Import from file',
    body: <SectionImportFrom />,
  },
  COMPARE: {
    title: 'Compare Versions',
    body: <SectionCompareVersions />,
  },
  VESSEL_GROUPS: {
    title: 'Vessel Groups',
    body: <SectionVesselGroups />,
  },
  VESSEL_VIEW: {
    title: 'View vessel',
    body: <SectionViewVessel />,
  },
};

export default VESSEL_MGMT;
