import SectionReferenceData from './SectionReferenceData';
import SectionReferenceDataAdd from './SectionReferenceDataAdd';
import SectionReferenceDataDelete from './SectionReferenceDataDelete';
import SectionReferenceDataEdit from './SectionReferenceDataEdit';
import SectionReferenceDataEditRow from './SectionReferenceDataEditRow';
import SectionReferenceDataImport from './SectionReferenceDataImport/SectionReferenceDataImport';

const REFERENCE_DATA = {
  REFERENCE_DATA_SECTION: {
    title: 'Reference Data Grid',
    body: <SectionReferenceData />,
  },
  ADD: {
    title: 'Add Attributes to Reference Data',
    body: <SectionReferenceDataAdd />,
  },
  IMPORT: {
    title: 'Import Attributes to Reference Data',
    body: <SectionReferenceDataImport />,
  },
  EDIT: {
    title: 'Edit Reference Data',
    body: <SectionReferenceDataEdit />,
  },
  EDIT_ROW: {
    title: 'Edit Attributes in Reference Data',
    body: <SectionReferenceDataEditRow />,
  },
  DELETE: {
    title: 'Delete Attributes from Reference Data',
    body: <SectionReferenceDataDelete />,
  },

};

export default REFERENCE_DATA;
