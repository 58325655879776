import { formatSortParams, get } from '@services/utils';

/**
 * @param {String|Number} id The fishing trip ID
 * @param {{ filters: Array, sort: String, direction: String,
 *    page: Number, limit: Number }} query The query params
 * @returns {Promise<Response>}
 */
export function fetchTripReports(id, query) {
  return get()(`logbook/fishing-trips/${id}/declarations`, formatSortParams(query));
}

/**
 * @param {String|Number} xmlId The value of declaration's xmlIncomingID
 * @returns {Promise<Response>}
 */
export function fetchTripReportsMessage(xmlId) {
  return get()(`messages/incoming/id/${xmlId}`);
}

export default {
  fetchTripReports,
  fetchTripReportsMessage,
};
