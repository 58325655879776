import PropTypes from 'prop-types';
import {
  useMemo,
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import './style.scss';

const sectionRender = (section, hidden) => section && (
  <div key={section.key} className="body" hidden={hidden}>
    {section.render ? section.render() : section.component}
  </div>
);

const Section = ({
  title,
  children,
  sections = [],
  defaultSection,
  tabTitleGetter,
  onCloseSectionCallback,
  onCurrentSectionChangeCallback,
  isSameSection,
  loading,
  className,
  preserveTabContent,
}) => {
  const { t } = useTranslation();
  const [currentSection, setCurrentSection] = useState({ render: () => <></> });
  const keyPressHold = useRef(false);

  useEffect(() => {
    defaultSection && setCurrentSection(defaultSection);
  }, [defaultSection]);

  const onSectionChangeHandler = useCallback(
    (section) => {
      if (loading) return;
      setCurrentSection(section);
      onCurrentSectionChangeCallback && onCurrentSectionChangeCallback(section);
    },
    [onCurrentSectionChangeCallback],
  );

  const contentRendered = useMemo(() => (children || preserveTabContent
    ? sections?.map((section) => sectionRender(section, !isSameSection(currentSection, section)))
    : sectionRender(currentSection)), [children, preserveTabContent, sections, currentSection]);

  return (
    <div className={`section ${loading ? 'loading' : ''} ${className || ''}`}>
      <div className={`header ${children ? '' : 'menu'}`}>
        {children ? (
          <span className="title">{title}</span>
        ) : (
          <>
            {sections.map((section, index) => (
              <div
                onClick={() => onSectionChangeHandler(section)}
                onKeyPress={(ev) => {
                  if (ev.key === ' ' || ev.key === 'Enter') {
                    ev.preventDefault();
                    !keyPressHold.current && onSectionChangeHandler();
                  }
                  keyPressHold.current = true;
                }}
                onKeyUp={() => { keyPressHold.current = false; }}
                onAuxClick={(ev) => {
                  if (loading || !onCloseSectionCallback) return;
                  ev.button === 1 && onCloseSectionCallback(section);
                  ev.stopPropagation();
                }}
                className={isSameSection(section, currentSection) ? 'active' : null}
                role="button"
                tabIndex={0}
                key={section.key}
              >
                {tabTitleGetter(section, index)}
                {onCloseSectionCallback && !isSameSection(section, sections[0]) && (
                  <span
                    className="close-button"
                    onClick={(ev) => {
                      if (loading) return;
                      onCloseSectionCallback(section);
                      ev.stopPropagation();
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === ' ' || ev.key === 'Enter') {
                        ev.preventDefault();
                        ev.stopPropagation();
                        !keyPressHold.current && onCloseSectionCallback(section);
                      }
                      keyPressHold.current = true;
                    }}
                    onKeyUp={() => { keyPressHold.current = false; }}
                    role="button"
                    tabIndex={0}
                  >
                    &times;
                  </span>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      {sections?.length ? contentRendered : children}

      {loading && (
        <div>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 1,
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">{t(tSchema.common.loading)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const sectionPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  // Require one of render or component
  render: PropTypes.func,
  component: PropTypes.element,
});

Section.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  sections: PropTypes.arrayOf(sectionPropType),
  defaultSection: sectionPropType,
  tabTitleGetter: PropTypes.func,
  onCloseSectionCallback: PropTypes.func,
  onCurrentSectionChangeCallback: PropTypes.func,
  isSameSection: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  // Preserve the tab content on tab change
  preserveTabContent: PropTypes.bool,
};

Section.defaultProps = {
  children: null,
  title: null,
  sections: null,
  defaultSection: null,
  onCloseSectionCallback: null,
  onCurrentSectionChangeCallback: null,
  tabTitleGetter: null,
  isSameSection: (a, b) => a.id === b.id,
  loading: false,
  className: null,
  preserveTabContent: false,
};

export default Section;
