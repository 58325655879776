import { get, put } from '../utils';

import { DOMAIN } from '@common/utils';

const BaseURL = 'ums';

export function apiFetchUserPreferences() {
  return get()(`${BaseURL}/preferences`);
}
export function apiFetchUserScenarios(domain) {
  let entity = '';
  if (domain === DOMAIN.FA) {
    entity = 'logbook';
  } else if (domain === DOMAIN.ISR) {
    entity = 'isrService';
  } else entity = '';
  return get()(`${entity}/options/userScenarios`);
}

export function apiSaveOnSelectScenario() {
  return (scenariosSelected) => put()(`${BaseURL}/setPreferences`, scenariosSelected);
}

export function apiSaveOnSelectFavourite() {
  return (favourite) => put()(`${BaseURL}/setPreferences`, favourite);
}

export function apiFetchDomainOptions() {
  return get()('brdomain/get');
}
