import * as PropTypes from 'prop-types';
import * as React from 'react';

const BaseMessageContext = React.createContext({ services: {} });

const BaseMessageProvider = ({ children, services }) => (
  <BaseMessageContext.Provider value={{ services }}>
    {children}
  </BaseMessageContext.Provider>
);

function useBaseMessageService() {
  const context = React.useContext(BaseMessageContext);
  if (context === undefined) {
    throw new Error('useBaseMessageService must be used within a BaseMessageProvider');
  }

  return context;
}

BaseMessageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  services: PropTypes.shape({
    apiFetchMessages: PropTypes.func.isRequired,
    apiFetchMessageById: PropTypes.func.isRequired,
    apiFetchMessageValidationDetails: PropTypes.func.isRequired,
    apiFetchMessageStatusOptions: PropTypes.func.isRequired,
    apiFetchMessageFilterOptions: PropTypes.func.isRequired,
    apiFetchMessageTypeOption: PropTypes.func.isRequired,
    apiFetchMessagePartiesFilterOptions: PropTypes.func.isRequired,
    apiFetchLogBookOptions: PropTypes.func.isRequired,
    apiDownloadXML: PropTypes.func.isRequired,
    apiFetchMessageResponseDetails: PropTypes.func.isRequired,
  }).isRequired,
};

export {
  BaseMessageProvider,
  useBaseMessageService,
};
