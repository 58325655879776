import {
  del,
  formatSortParams,
  get, post, put,
} from '../utils';

import { DOMAIN } from '@common/utils';

const baseURLquery = 'businessrules/acceptanceQuery/';
const baseURLqueryISR = 'businessrules/isrAcceptanceQuery/';
const baseURLreport = 'businessrules/acceptanceReport/';
const baseURLforwarding = 'businessrules/acceptanceForwarding/';

export const apiFetchEfcaRules = (isReport, isForwarding, domain) => {
  const isISRDomain = domain === DOMAIN.ISR;
  let url = isISRDomain ? baseURLqueryISR : baseURLquery;
  if (isReport) {
    url = baseURLreport;
  }
  if (isForwarding) {
    url = baseURLforwarding;
  }
  return (searchFilters, params) => {
    const filter = searchFilters ? `/${searchFilters}` : '';
    return get()(`${url}getAll${filter}`, formatSortParams(params));
  };
};

export const apiFetchAcceptanceQuery = (id, domain) => get()(`${domain === DOMAIN.ISR ? baseURLqueryISR : baseURLquery}get/${id}`);

export const apiFetchAcceptanceReport = (id) => get()(`${baseURLreport}get/${id}`);

export const apiDeleteAcceptanceQuery = (id, domain) => del()(`${domain === DOMAIN.ISR ? baseURLqueryISR : baseURLquery}delete/${id}`);

export const apiDeleteAcceptanceReport = (id) => del()(`${baseURLreport}delete/${id}`);

export const apiSaveQueryAcceptanceBR = (rule, domain) => (rule.id
  ? put()(`${domain === DOMAIN.ISR ? baseURLqueryISR : baseURLquery}update`, rule)
  : post()(`${domain === DOMAIN.ISR ? baseURLqueryISR : baseURLquery}create`, rule));

export const apiSaveReportAcceptanceBR = (rule) => (rule.id
  ? put()(`${baseURLreport}update`, rule)
  : post()(`${baseURLreport}create`, rule));

export const apiFetchEfcaRulesOptions = (optionObj) => {
  const { option, entity = 'acceptanceQuery', path } = optionObj;
  return get()(path || `${entity}/options/${option}`);
};

export const apiFetchAcceptanceForwarding = (id) => get()(`${baseURLforwarding}get/${id}`);

export const apiDeleteAcceptanceForwarding = (id) => del()(`${baseURLforwarding}delete/${id}`);

export const apiSaveForwardingAcceptanceBR = (rule) => (rule.id
  ? put()(`${baseURLforwarding}update`, rule)
  : post()(`${baseURLforwarding}create`, rule));
