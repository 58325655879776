import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { FISHING_OPERATION_PROPTYPES, FISHING_OPERATION_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const ReportDetailsFishingOperation = ({ fishingOperation, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(FISHING_OPERATION_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentFishingOperation, setCurrentFishingOperation] = useState([]);

  useEffect(() => {
    setCurrentFishingOperation(
      (fishingOperation && fishingOperation[pageNumber - 1])
    || FISHING_OPERATION_SCHEMA,
    );
  },
  [fishingOperation, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container id="report-details-departure" fluid>
      <ReportHeader
        title="Fishing Operation"
        versions={currentFishingOperation}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={fishingOperation}
        onSelectPagination={setPageNumber}
      />
      <Row>
        <Col md={3} sm={3} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField
            label={t('Vessel Related Activity')}
            value={`${currentVersion?.relatedVesselActivity || ''}  ${currentVersion?.relatedVesselActivityDescription || ''}`}
          />
          <TextInputField
            label={t(schema.identification)}
            value={currentVersion?.identification}
          />
        </Col>
        <Col md={3} sm={3} xs={12}>
          <TextInputField
            label={t(schema.start)}
            value={currentVersion?.startDate && `${formatDateTime(currentVersion?.startDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />

          <TextInputField
            label={t(schema.fisheryType)}
            value={`${currentVersion?.fisheryType || ''}  ${currentVersion?.fisheryTypeDescription || ''}`}
          />
        </Col>
        <Col md={3} sm={3} xs={12}>
          <TextInputField
            label={t(schema.end)}
            value={currentVersion?.endDate && `${formatDateTime(currentVersion?.endDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField
            label={t(schema.species)}
            value={currentVersion?.speciesTarget}
          />
        </Col>
        <Col md={3} sm={3} xs={12}>

          <TextInputField
            label={t(schema.duration)}
            value={currentVersion?.duration}
          />
          <TextInputField
            label={t('Quantity')}
            value={currentVersion?.operations}
          />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} reportType="fishingOperation" />
    </Container>
  );
};

ReportDetailsFishingOperation.propTypes = {
  fishingOperation: FISHING_OPERATION_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default ReportDetailsFishingOperation;
