import PropTypes from 'prop-types';
import { path } from 'ramda';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

const StatusFormField = ({ rule, disabled, getOptions }) => {
  const { t } = useTranslation();

  const { createEditView: schema } = tSchema.dmaAdmin.brEngine;

  return (
    <Form.Group as={Col} controlId="formStatus">
      <Form.Label>
        {t(schema.status)}
      </Form.Label>
      <Form.Control
        required
        as="select"
        name="status"
        defaultValue={path(['status', 'id'])(rule)}
        disabled={disabled}
      >
        {getOptions('brstatus', undefined, undefined, false)}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(schema.validation.emptyStatus)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

StatusFormField.propTypes = {
  getOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired,
};

StatusFormField.defaultProps = {
  disabled: undefined,
};

export default StatusFormField;
