import {
  TypeActionsCrud,
  fetchDataActivity,
  fetchSourcesList,
  downloadValidationReport,
} from './crud';

const Actions = {
  ...TypeActionsCrud,
};

export {
  Actions,
  // CRUD
  fetchDataActivity,
  fetchSourcesList,
  downloadValidationReport,
};
