import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import {
  Dropdown, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import { clearEventTargetValue } from '@common/utils';

const DragAndDropFile = ({ onChange: handleChangeFile }) => {
  const { t } = useTranslation();
  const handleClickSelectFile = (key) => {
    if (key === 'csv') {
      document.querySelector('#vesselsFromFile').click();
    } else {
      document.querySelector('#vesselsFromFileXLS').click();
    }
  };
  const [csvFile, setCsvFile] = useState(null);

  const handleChangeVesselsFromFile = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setCsvFile(file);
      handleChangeFile(file);
    }
  }, [handleChangeFile]);

  return (
    <Form.Group
      className="mb-3"
    >
      <Row
        className="justify-content-center m-auto h-100 w-100 p-4"
      >
        {
        }
        <Dropdown
          style={{ marginRight: '0.313rem' }}
          onSelect={(key) => {
            handleClickSelectFile(key);
          }}
        >
          <Dropdown.Toggle
            id="download-rules"
            variant="primary"
            placement="top-start"
          >
            {t(tSchema.common.selectFrom)}
            <IconSvg name="caretDown" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="csv">{t(tSchema.common.csv)}</Dropdown.Item>
            <Dropdown.Item eventKey="excel">{t(tSchema.common.excel)}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <IconSvg name="caretDown" />
        {csvFile && (
          <p className="my-auto ml-1">
            {`${csvFile.name}`}
          </p>
        )}
      </Row>
      <Form.Control
        type="file"
        accept=".csv"
        id="vesselsFromFile"
        name="vesselsFile"
        onChange={handleChangeVesselsFromFile}
          // Allow to select the same file twice
        onClick={clearEventTargetValue}
        style={{ display: 'none' }}
      />
      <Form.Control
        type="file"
        accept=".xlsx, .xls"
        id="vesselsFromFileXLS"
        name="vesselsFile"
        onChange={handleChangeVesselsFromFile}
          // Allow to select the same file twice
        onClick={clearEventTargetValue}
        style={{ display: 'none' }}
      />
    </Form.Group>
  );
};

DragAndDropFile.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DragAndDropFile;
