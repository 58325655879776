import React, { useMemo } from 'react';
import {
  Col, Container, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import {
  FISHING_OPERATION_OTHER_CHARACTERISTICS_PROPTYPES,
} from '../../constants';
import BasicTable from '../tables/BasicTable';

const schema = tSchema.fishingActivities.logbookViewer.details;

export const getTableHeaders = (t) => [
  {
    text: t(schema.code),
    code: 'code',
    sortable: false,
    render: (row) => {
      if (row.description === undefined) return <span>{row.code}</span>;
      return (
        <OverlayTrigger placement="top" overlay={<Tooltip>{row.description}</Tooltip>}>
          <span>{row.code}</span>
        </OverlayTrigger>
      );
    },
  },
  { text: t(schema.value), code: 'value', sortable: false },
];

const OtherCharacteristics = ({ characteristics }) => {
  const { t } = useTranslation();
  const tableHeaders = useMemo(() => getTableHeaders(t), [t]);
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <BasicTable
            data={characteristics}
            headers={tableHeaders}
          />
        </Col>
      </Row>
    </Container>
  );
};

OtherCharacteristics.propTypes = {
  characteristics: FISHING_OPERATION_OTHER_CHARACTERISTICS_PROPTYPES.isRequired,
};

export default OtherCharacteristics;
