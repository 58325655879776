import PropTypes from 'prop-types';
import { useRef } from 'react';
import { NavLink } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';

import tSchema from '@lang/schema';

import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import SelectMultiChoicesScenario from './components/SelectMultiChoicesScenario';

import './Header.scss';
import { DOMAIN } from '@common/utils';

const Header = ({
  onLogout, isLogedIn, username, dummyLogin, setLoading,
}) => {
  const { t } = useTranslation();
  const keyPressHold = useRef(false);
  const history = useHistory();
  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;
  const isOnLogbookOrReportViewer = history.location.pathname.includes('logbook-viewer') || history.location.pathname.includes('report-viewer');

  return (
    <header className="d-flex flex-space-between align-items-center p-2">
      <div className="flex-grow-1 h-100">
        <img src="/logo.png" alt="logo" id="logo" />
      </div>
      {isLogedIn && (
      <>
        {isOnLogbookOrReportViewer && DOMAIN.VESSEL !== domainCode ? (
          <SelectMultiChoicesScenario
            dummyLogin={dummyLogin}
            setLoading={setLoading}
          />
        ) : <></>}
        <div id="username">{username}</div>
        <div id="separator" className="ml-3" />
        {dummyLogin ? (
          <LinkContainer to="/logout" className="ml-3 mr-3">
            <NavLink id="logout-action">{t(tSchema.common.logOut)}</NavLink>
          </LinkContainer>
        ) : (
          <div
            type="link"
            id="logout-action"
            className="ml-3 mr-3 cursor-pointer"
            onClick={() => onLogout && onLogout()}
            onKeyPress={(ev) => {
              if (ev.key === ' ' || ev.key === 'Enter') {
                !keyPressHold.current && onLogout && onLogout();
                ev.preventDefault();
              }
              keyPressHold.current = true;
            }}
            onKeyUp={() => { keyPressHold.current = false; }}
            tabIndex={0}
            role="button"
          >
            {t(tSchema.common.logOut)}
          </div>
        )}
      </>
      )}
    </header>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
  isLogedIn: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  dummyLogin: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
};

Header.defaultProps = {
  dummyLogin: false,
};

export default withRouter(Header);
