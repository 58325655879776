import PropTypes from 'prop-types';

const baseType = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  source: PropTypes.string,
};

const booleanTypeProptype = {
  ...baseType,
  code: PropTypes.string.isRequired,
};

const gearTypeProptype = {
  ...booleanTypeProptype,
  parent: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  subCategory: PropTypes.string.isRequired,
  gearActivity: PropTypes.string.isRequired,
  isscfgCode: PropTypes.string.isRequired,

};

const vesselEventTypes = {
  ...booleanTypeProptype,
  type: PropTypes.string.isRequired,
};
const vesselPortTypes = {
  ...booleanTypeProptype,
  country: PropTypes.string.isRequired,
  outermostRegionIndicator: PropTypes.string,
  nuts: PropTypes.string,
};

const vesselSegmentTypes = {
  ...booleanTypeProptype,
  vesselPgm: PropTypes.string,
  segClass: PropTypes.string,
  country: PropTypes.string,
  codouterInde: PropTypes.string,
};

const territoryTypes = {
  ...booleanTypeProptype,
  landlock: PropTypes.string,
};

const vesselTypes = {
  ...booleanTypeProptype,
  fishingVessel: PropTypes.string,
  vesselCat: PropTypes.string,
  parentVessel: PropTypes.string,
  isscfvCode: PropTypes.string,
};

export {
  baseType,
  booleanTypeProptype,
  gearTypeProptype,
  vesselEventTypes,
  vesselPortTypes,
  vesselSegmentTypes,
  territoryTypes,
  vesselTypes,
};
