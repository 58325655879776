import tSchema from '@lang/schema';

import { formatDate } from '@common/dateUtils';

const schema = tSchema.dmaAdmin.externalSources.main;

export const getTableConfig = (t) => [
  {
    text: t(schema.externalSource),
    code: 'externalSource',
    sortable: true,
  },
  {
    text: t(schema.status),
    code: 'status',
    sortable: true,
  },
  {
    text: t(schema.priority),
    code: 'priority',
    sortable: true,
  },
  {
    text: t(schema.lastExecutionTime),
    code: 'lastExecutionDateTime',
    sortable: true,
    render: (row) => [formatDate(row.lastExecutionDate, undefined, undefined, true), row.lastExecutionTime].filter(Boolean).join(' '),
  },
];

export const vesselsValueOptions = [
  'getRefData',
];

export const vesselsValueOptionsx = [
  'countries',
];
