import PropTypes from 'prop-types';
import * as React from 'react';
import {
  Row, Tooltip, OverlayTrigger,
} from 'react-bootstrap';

const ReportStatusButton = ({
  message,
}) => {
  const CHOICE_CLASS_DOT_REPORT = {
    WAITING: 'dot-waiting',
    TIMEOUT: 'dot-timeout',
    OK: 'dot-ok',
  };
  const SHOW_MESSAGE_TOOLTIP_REPORT = {
    WAITING: 'Waiting for report',
    TIMEOUT: 'Report timeout',
    OK: 'Report OK',
  };

  return (
    <>
      {(message?.type?.description.includes('Query'))
        && (
        <Row className="d-flex justify-content-end align-content-end align-content-between ">
          <OverlayTrigger
            overlay={(
              <Tooltip>
                {SHOW_MESSAGE_TOOLTIP_REPORT[message?.reportStatus?.status]}
              </Tooltip>
                )}
            placement="left"
          >
            <p className="my-auto mr-1 p-1">Report status</p>
          </OverlayTrigger>
          <span className={`ml-1 ${
            message?.reportStatus?.status
              ? CHOICE_CLASS_DOT_REPORT[message?.reportStatus?.status]
              : 'dot-without-response'

          } align-self-end my-auto`}
          />
        </Row>
        )}
    </>
  );
};

ReportStatusButton.propTypes = {
  message: PropTypes.shape({
    creationDate: PropTypes.string,
    dataDomain: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
    id: PropTypes.number,
    idType: PropTypes.number,
    identification: PropTypes.string,
    isIncoming: PropTypes.bool,
    message: PropTypes.string,
    recipient: PropTypes.string,
    relatedMessages: PropTypes.arrayOf(PropTypes.any),
    reportStatus: null,
    responseStatus: null,
    sender: PropTypes.string,
    status: null,
    type: PropTypes.shape({ id: PropTypes.number, description: PropTypes.string }),
    uuid: PropTypes.string,
    validationResults: PropTypes.string,
  }).isRequired,
};

export default ReportStatusButton;
