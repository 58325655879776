import { concat, isNil } from 'ramda';

import { Actions } from '../../actions/vessel';

import { customPropertiesMapper, formatFormData } from './utils';

const Status = {
  0: 'OK',
  1: 'KO',
  2: 'PENDING',
};

/*
    Vessel's status modification when the action
    fetchAllVessels is launched but didn't get response yet
*/
const fetchAllVesselsPending = (state) => ({
  ...state,
  isLoading: true,
  error: false,
});

/*
    Vessel's status modification when the action
    fetchAllVessels gets status 200
*/

const calculateMaxPages = (totalCount, pageSize) => {
  let maxPages = 0;
  if (totalCount < pageSize) {
    maxPages = 1;
  } else {
    maxPages = Math.trunc(totalCount / pageSize);
    if (totalCount % pageSize > 0) {
      maxPages += 1;
    }
  }
  return maxPages;
};

const fetchAllVesselsFullfilled = (state, { payload }) => {
  const vessels = [];
  Array.isArray(payload.Vessels) && payload.Vessels.forEach((v) => {
    const vessel = v;
    vessel.status = Status[vessel.status];
    vessels.push(vessel);
  });

  return {
    ...state,
    vessels,
    isLoading: false,
    pagination: {
      ...state.pagination,
      totalCount: payload.totalCount,
      maxPages: calculateMaxPages(payload.totalCount, state.pagination.size),
    },
    modified: false,
    validated: false,
    error: false,
  };
};

/*
    Vessel's status modification when the action
    fetchAllVessels gets an error or doesn't get response
*/
const fetchAllVesselsRejected = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: true,
  errorMessage: payload,
});

/*
    Vessel's status modification when the action
    fetchVesselById is launched but didn't get response yet
*/
const fetchVesselByIdPending = (state) => ({
  ...state,
  isLoadingVessel: true,
});

/*
    Vessel's status modification when the action
    fetchVesselById gets status 200
*/
const fetchVesselByIdFullFilled = (state, { payload }) => {
  const vessel = payload;
  const vesselEntity = state.entity.map((tab) => ({
    ...tab,
    attributes: tab.attributes.map((attr) => ({
      ...attr,
      value: isNil(payload[attr.name]) ? null : payload[attr.name],
    })),
  }));
  return {
    ...state,
    isLoadingVessel: false,
    entity: vesselEntity,
    vessel,
    oldVessel: { ...payload },
    selectedVesselId: payload.etalonId,
    modified: false,
    validated: false,
  };
};

/*
    Vessel's status modification when the action
    fetchVesselById gets an error or doesn't get response
*/
const fetchVesselByIdRejected = (state) => ({
  ...state,
  isLoadingVessel: false,
});

const editVesselFullfilled = (state, { payload }) => ({
  ...state,
  vessel: { ...payload },
  modified: false,
  validated: false,
});

const editVesselRejected = (state) => ({
  ...state,
});

const approveVesselPending = (state) => ({
  ...state,
  isLoadingVessel: true,
});

const approveVesselFullfilled = (state) => ({
  ...state,
  vessel: { ...state.vessel, approverStatus: 'CONFIRMED' },
  modified: false,
  validated: false,
  isLoadingVessel: false,
});

const approveVesselRejected = (state) => ({
  ...state,
  isLoadingVessel: false,
});

const getSearchableAttributesFullfilled = (state, { payload }) => {
  const registerEntities = payload.registerEntities.map(
    (e) => ({
      ...e,
      simpleAttributes: e.simpleAttributes.map(
        (attr) => ({ ...attr, customProperties: customPropertiesMapper(attr.customProperties) }),
      ),
      complexAttributes: e.complexAttributes.map(
        (attr) => ({ ...attr, customProperties: customPropertiesMapper(attr.customProperties) }),
      ),
    }),
  );

  const vesselSimpleAttributes = registerEntities[0].simpleAttributes;
  const vesselComplexAttributes = registerEntities[0].complexAttributes
    .map(({ simpleAttributes }) => simpleAttributes)
    .flat(Infinity);
  const searchableSimpleAttributes = vesselSimpleAttributes.filter((attr) => attr.searchable);
  const searchableComplexAttributes = vesselComplexAttributes
    .filter((attr) => attr.searchable)
    .map((e) => ({ ...e, customProperties: customPropertiesMapper(e.customProperties) }));
  const searchableAttributes = concat(searchableSimpleAttributes, searchableComplexAttributes);

  return {
    ...state,
    searchableAttributes,
    template: {
      ...state.template,
      ...payload,
      registerEntities,
    },
  };
};

const getFormDataFullfilled = (state, { payload }) => ({
  ...state,
  template: {
    ...state.template,
    formData: {
      ...state.template.formData,
      ...formatFormData(payload),
    },
  },
});

const getVesselPortsFullfilled = (state, { payload }) => ({
  ...state,
  template: {
    ...state.template,
    formData: {
      ...state.template.formData,
      vesselPort: payload,
    },
  },
});

const setPagination = (state, { payload }) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page: payload.page,
    size: payload.size,
    maxPages: calculateMaxPages(state.pagination.totalCount, payload.size),
    filtersBody: payload.filtersBody,
  },
});

const uploadVesselsPending = (state) => ({
  ...state,
  uploadVessels: {
    reports: null,
    isUploadingVessels: true,
    error: null,
  },
});

const uploadVesselsFullfilled = (state, { payload }) => ({
  ...state,
  uploadVessels: {
    reports: payload,
    isUploadingVessels: false,
    error: null,
  },
});

const uploadVesselsRejected = (state, { payload }) => ({
  ...state,
  uploadVessels: {
    reports: null,
    isUploadingVessels: false,
    error: payload,
  },
});

const cleanUploadVesselsReports = (state) => ({
  ...state,
  uploadVessels: {
    isUploadingVessels: false,
    reports: null,
    error: null,
  },
});

const exportAllVesselsPending = (state) => ({
  ...state,
  isExporting: true,
});

const exportAllVessels = (state) => ({
  ...state,
  isExporting: false,
});

const fetchVesselGroupsPending = (state) => ({
  ...state,
  isLoadingGroups: true,
});

const fetchVesselGroupsFulfilled = (state, { payload }) => ({
  ...state,
  vesselGroups: payload,
  isLoadingGroups: false,
});

const fetchVesselGroupsRejected = (state) => ({
  ...state,
  isLoadingGroups: false,
});

const fetchConcreteVesselGroupsPending = (state) => ({
  ...state,
  isLoadingGroups: true,
});

const fetchConcreteVesselGroupsFulFilled = (state, { payload }) => {
  // eslint-disable-next-line max-len
  payload.sort((a, b) => ((a.vesselGroupRecordCriteriaEntityList.length > b.vesselGroupRecordCriteriaEntityList.length)
    ? 1 : -1));

  return {
    ...state,
    currentGroups: payload,
    isLoadingGroups: false,
  };
};

const fetchConcreteVesselGroupsRejected = (state) => ({
  ...state,
  isLoadingGroups: false,
});

const updateVesselGroupsPending = (state) => ({
  ...state,
  isSavingGroups: true,
});

const updateVesselGroupsFulFilled = (state) => ({
  ...state,
  isSavingGroups: false,
});

const updateVesselGroupsRejected = (state) => ({
  ...state,
  isSavingGroups: false,
});

const clearVessels = (state) => ({
  ...state,
  vessels: [],
  pagination: {
    page: 1,
    size: 10,
    totalCount: 0,
    maxPages: 1,
    filtersBody: {},
  },
});

const swapPertenency = (state, { payload }) => {
  const currentGroups = Object.assign([], state.currentGroups);
  if (payload.destination === 'AVAILABLE') {
    currentGroups.splice(currentGroups.indexOf(payload.group), 1);
  } else {
    currentGroups.unshift(payload.group);
  }
  return {
    ...state,
    currentGroups,
  };
};

const loadVesselFromState = (state, { payload }) => {
  const vesselEntity = state.entity.map((tab) => ({
    ...tab,
    attributes: tab.attributes.map((attr) => ({
      ...attr,
      value: payload[attr.name] ? payload[attr.name] : null,
    })),
  }));
  return ({
    ...state,
    vessel: payload,
    oldVessel: payload,
    entity: vesselEntity,
  });
};

const Crud = {
  [Actions.FETCH_ALL_VESSELS.PENDING]: fetchAllVesselsPending,
  [Actions.FETCH_ALL_VESSELS.FULFILLED]: fetchAllVesselsFullfilled,
  [Actions.FETCH_ALL_VESSELS.REJECTED]: fetchAllVesselsRejected,
  [Actions.FETCH_VESSEL_BY_ID.PENDING]: fetchVesselByIdPending,
  [Actions.FETCH_VESSEL_BY_ID.FULFILLED]: fetchVesselByIdFullFilled,
  [Actions.FETCH_VESSEL_BY_ID.REJECTED]: fetchVesselByIdRejected,
  [Actions.EDIT_VESSEL.FULFILLED]: editVesselFullfilled,
  [Actions.EDIT_VESSEL.REJECTED]: editVesselRejected,
  [Actions.APPROVE_VESSEL.PENDING]: approveVesselPending,
  [Actions.APPROVE_VESSEL.FULFILLED]: approveVesselFullfilled,
  [Actions.APPROVE_VESSEL.REJECTED]: approveVesselRejected,
  [Actions.GET_SEARCHEABLE_ATTRIBUTES.FULFILLED]: getSearchableAttributesFullfilled,
  [Actions.GET_FORM_DATA.FULFILLED]: getFormDataFullfilled,
  [Actions.GET_VESSEL_PORTS.FULFILLED]: getVesselPortsFullfilled,
  [Actions.SET_PAGINATION]: setPagination,
  [Actions.UPLOAD_VESSELS.PENDING]: uploadVesselsPending,
  [Actions.UPLOAD_VESSELS.FULFILLED]: uploadVesselsFullfilled,
  [Actions.UPLOAD_VESSELS.REJECTED]: uploadVesselsRejected,
  [Actions.CLEAN_VESSELS_UPLOAD_REPORTS]: cleanUploadVesselsReports,
  [Actions.EXPORT_VESSELS.PENDING]: exportAllVesselsPending,
  [Actions.EXPORT_VESSELS.FULFILLED]: exportAllVessels,
  [Actions.EXPORT_VESSELS.REJECTED]: exportAllVessels,
  [Actions.FETCH_ALL_GROUPS.PENDING]: fetchVesselGroupsPending,
  [Actions.FETCH_ALL_GROUPS.FULFILLED]: fetchVesselGroupsFulfilled,
  [Actions.FETCH_ALL_GROUPS.REJECTED]: fetchVesselGroupsRejected,
  [Actions.FETCH_GROUPS_FROM_VESSEL.PENDING]: fetchConcreteVesselGroupsPending,
  [Actions.FETCH_GROUPS_FROM_VESSEL.FULFILLED]: fetchConcreteVesselGroupsFulFilled,
  [Actions.FETCH_GROUPS_FROM_VESSEL.REJECTED]: fetchConcreteVesselGroupsRejected,
  [Actions.UPDATE_VESSEL_GROUPS.PENDING]: updateVesselGroupsPending,
  [Actions.UPDATE_VESSEL_GROUPS.FULFILLED]: updateVesselGroupsFulFilled,
  [Actions.UPDATE_VESSEL_GROUPS.REJECTED]: updateVesselGroupsRejected,
  [Actions.SWAP_GROUP_PERTENENCY]: swapPertenency,
  [Actions.LOAD_VESSEL_FROM_STATE]: loadVesselFromState,
  [Actions.CLEAR_VESSELS]: clearVessels,
};

export default Crud;
