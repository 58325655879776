import PropTypes, { objectOf } from 'prop-types';
import { useState, useCallback, useMemo } from 'react';
import { Col, Row, Button } from 'react-bootstrap';

import { If, Then } from '@components/Conditionals/If';
import './style.scss';

const PaginationHeader = ({ versions, onSelectPagination, pagination }) => {
  const [actualPage, setActualPage] = useState(pagination);

  const prevEnabled = useMemo(() => actualPage > 1, [actualPage]);
  const nextEnabled = useMemo(() => versions && actualPage < versions.length,
    [actualPage, pagination]);

  const handleNextPage = useCallback(() => {
    if (nextEnabled) {
      setActualPage((prevState) => prevState + 1);

      onSelectPagination(actualPage + 1);
    }
  }, [actualPage, nextEnabled, onSelectPagination]);

  const handlePrevPage = useCallback(() => {
    if (prevEnabled) {
      setActualPage((prevState) => prevState - 1);
      onSelectPagination(actualPage - 1);
    }
  }, [actualPage, prevEnabled, onSelectPagination]);

  return (
    <Row className="report-details-header">
      <Col xs={12} style={{ display: 'flex' }}>

        <If conditional={versions?.length > 1}>
          <Then>
            <Col sm={2} id="pagination_report" className="my-auto">
              <Row className="pagination_content flex-nowrap align-content-center">
                <Button disabled={!prevEnabled} variant="link" onClick={handlePrevPage}>{'<'}</Button>
                <div className="d-flex flex-nowrap">
                  <p className="mr-1 my-auto">
                    {' '}
                    {actualPage}
                    {' '}
                    of
                    {' '}
                    {versions?.length}
                  </p>
                </div>
                <Button disabled={!nextEnabled} variant="link" onClick={handleNextPage}>{'>'}</Button>
              </Row>
            </Col>
          </Then>
        </If>
      </Col>
    </Row>
  );
};

PaginationHeader.propTypes = {
  versions: PropTypes.arrayOf(objectOf({})).isRequired,
  onSelectPagination: PropTypes.func.isRequired,
  pagination: PropTypes.number.isRequired,
};

export default PaginationHeader;
