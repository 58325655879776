import { useCallback, useMemo, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import {
  FISHING_OPERATION_GEAR_PROBLEMS_PROPTYPES,
} from '../../constants';
import WrapperModal from '../Modals/components/WrapperModal';
import BasicTable from '../tables/BasicTable';

import FishingGears from './FishingGears';
import Location from './Location';

import { isNilOrEmpty } from '@common/utils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const getTableHeaders = (t) => [
  { text: t(schema.type), code: 'type', sortable: false },
  { text: t(schema.affectedQuantity), code: 'affectedQuantity', sortable: false },
  { text: t(schema.recoveryMeasure), code: 'recoveryMeasure', sortable: false },
];

const GearProblems = ({ gearProblems }) => {
  const { t } = useTranslation();
  const tableHeaders = useMemo(() => getTableHeaders(t), [t]);
  const [location, setLocation] = useState(null);
  const [fishingGears, setFishingGears] = useState(null);

  const locationHandler = useCallback((row) => {
    setLocation(row?.location);
  }, []);
  const fishingGearsHandler = useCallback((row) => {
    setFishingGears(row?.relatedFishingGears);
  }, []);

  const actions = useMemo(() => [
    { label: 'See Location', action: locationHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.location) },
    { label: 'See Related Fishing Gears', action: fishingGearsHandler, conditionallyDisabled: (row) => isNilOrEmpty(row?.relatedFishingGears) },
  ], []);

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <BasicTable
            data={gearProblems}
            headers={tableHeaders}
            actions={actions}
          />
        </Col>
      </Row>

      <WrapperModal
        id="wrapper-modal-locations"
        showModal={location}
        setShowModal={setLocation}
        headerTitle="Location"
        centered
        animation={false}
        size="xl"
      >
        {location && <Location location={location} />}
      </WrapperModal>
      <WrapperModal
        id="wrapper-modal-fishingGears"
        showModal={fishingGears}
        setShowModal={setFishingGears}
        headerTitle="Related Fishing Gear"
        centered
        animation={false}
        size="xl"
      >
        {fishingGears && <FishingGears gears={fishingGears} />}
      </WrapperModal>
    </Container>
  );
};

GearProblems.propTypes = {
  gearProblems: FISHING_OPERATION_GEAR_PROBLEMS_PROPTYPES.isRequired,
};

export default GearProblems;
