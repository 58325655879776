import SectionVesselGroupCreate from './SectionVesselGroupCreate';
import SectionVesselGroupDelete from './SectionVesselGroupDelete';
import SectionVesselGroupEdit from './SectionVesselGroupEdit';
import SectionVesselGroupTable from './SectionVesselGroupTable';
import SectionVesselGroupView from './SectionVesselGroupView';

const VESSEL_GROUP = {
  GROUP_TABLE: {
    title: 'Vessel Group Grid',
    body: <SectionVesselGroupTable />,
  },
  CREATE_GROUP: {
    title: 'Create Vessel Group',
    body: <SectionVesselGroupCreate />,
  },
  EDIT_GROUP: {
    title: 'Edit Vessel Group',
    body: <SectionVesselGroupEdit />,
  },
  VIEW_GROUP: {
    title: 'View Vessel Group',
    body: <SectionVesselGroupView />,
  },
  DELETE_GROUP: {
    title: 'Delete Vessel Group',
    body: <SectionVesselGroupDelete />,
  },
};

export default VESSEL_GROUP;
