/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { Image } from 'react-bootstrap';

import { get } from '../../../services/utils';

const RemoteImage = ({
  src, fetchFunction, setData, data, alt, ...imageAttrs
}) => {
  useEffect(() => {
    get()(`vrService/image/download/${src}`, {}, {
      responseType: 'arraybuffer',
    }).then(async (response) => {
      const imageStr = await Buffer.from(response.data, 'binary').toString('base64');
      const type = response.headers['content-type'];
      const base64Flag = `data:${type};base64,`;
      setData(base64Flag + imageStr);
    });
  }, [src]);

  return data ? <Image src={data} alt={alt || ''} {...imageAttrs} thumbnail /> : null;
};

export default RemoteImage;
