/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';
import validationBREditModalImg from './img/validation-br-edit-modal.png';

const { t } = i18next;

const SectionValidationBREdit = () => (
  <div className="subSection">
    <p>
      To edit a business rule, the user must click on the row &quot;Actions&quot;
      button corresponding to the row of that specific business rule, and then click on &quot;
      {t(tSchema.common.edit)}
      &quot; button.
    </p>
    <p>
      A modal shows the
      information related to the selected business rule, in editable format:
    </p>
    <p>
      <img src={validationBREditModalImg} alt="validationBREditModal" />
    </p>
    <p>
      At the bottom of the modal window,
      it is displayed the last business rule editor (in read-only format).
    </p>
    <p>
      When the user has finished editing the business rule, clicking on &quot;
      {t(tSchema.common.save)}
      &quot;
      button applies the changes.
    </p>
  </div>
);

export default SectionValidationBREdit;
