import PropTypes from 'prop-types';
import { useRef, useCallback, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import categories from '@pages/App/components/MainMenu/categories';

import './style.scss';
import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import DomainSelector from './components/DomainSelector';
import MenuItem from './components/MenuItem';

import { RealmRole } from '@common/keycloakFunctions';

const MainMenu = ({ collapse }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const keyPressHold = useRef(false);

  const firstLink = location.pathname.split('/')[1];
  const secondLink = location.pathname.split('/')[2];

  const activeKey = secondLink ? `/${firstLink}/${secondLink}` : `/${firstLink}`;

  const { userPreferences } = useUserPreferences();
  const { keycloak } = useKeyCloak();

  const isDataAdmin = useMemo(() => keycloak.token
    && keycloak.hasRealmRole(RealmRole.DATA_ADMIN), [keycloak]);

  const isSystemAdmin = useMemo(() => keycloak.token
        && keycloak.hasRealmRole(RealmRole.SYSTEM_ADMIN), [keycloak]);

  const isCoordinators = useMemo(() => keycloak.token
        && keycloak.hasRealmRole(RealmRole.APP_USER), [keycloak]);

  const isSuperUser = useMemo(() => (isDataAdmin && isSystemAdmin), [isDataAdmin, isSystemAdmin]);

  const isISRSystemAdmin = useMemo(() => keycloak.token
  && keycloak.hasRealmRole(RealmRole.ISR_SYSTEM_ADMINISTRATOR), [keycloak]);

  const isISRDataAdmin = useMemo(() => keycloak.token
  && keycloak.hasRealmRole(RealmRole.ISR_DATA_ADMINISTRATOR), [keycloak]);

  const handleEventKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (!keyPressHold.current) {
          const url = event.target.getAttribute('data-rb-event-key');
          //  onSelectItemMenu();
          url && history.push(url);
        }
      }
      keyPressHold.current = true;
    },
    [keyPressHold],
  );

  const categoriesByDomain = useMemo(
    () => categories(
      userPreferences && userPreferences.domain,
      {
        isDataAdmin, isSystemAdmin, isSuperUser, isCoordinators, isISRSystemAdmin, isISRDataAdmin,
      },
      t,
    ),
    [userPreferences.domain, keycloak, t],
  );

  const handleNavSelect = useCallback((url) => {
    url.startsWith('http')
      ? window.open(url, '_blank').focus()
      : history.push(url);
  }, [history]);

  return (

    <Nav
      id="navigation-menu"
      defaultActiveKey="/home"
      className={`flex-column${collapse ? ' collapsed' : ''}`}
      onSelect={handleNavSelect}
      activeKey={activeKey}
      onKeyPress={handleEventKeyPress}
      onKeyUp={() => { keyPressHold.current = false; }}
      style={{ fontSize: '.9rem' }}
    >
      <div id="navigation-menu-content">
        <DomainSelector />
        {categoriesByDomain.map((cat) => (
          <MenuItem
            activeKey={activeKey}
            topLevel
            key={cat.text}
            isCoordinators
            {...cat}
          />
        ))}
      </div>
    </Nav>
  );
};

MainMenu.propTypes = {
  collapse: PropTypes.bool,
};

MainMenu.defaultProps = {
  collapse: true,
};

export default MainMenu;
