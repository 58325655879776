import actions from './img/actions.png';
import successDelete from './img/successDelete.png';

const SectionRemoveObjRef = () => (
  <div className="SectionRemoveObjRef">
    <p>
      To remove Vessel Record reference of a IS Report,
      the user has to select &quot;Remove Object Reference&quot; in the &quot;Actions&quot;
      button for the corresponding report.
      This option is only available if a Vessel Record is associated to the IS Report.
    </p>
    <p>
      Editor, Approver and FIS ISR Party Admin can Update References of Vessel for an IS Report.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      If Object Reference has been removed successfully, a notification appears.
    </p>
    <p>
      <img src={successDelete} alt="Delete Object Reference" />
    </p>
    <p>
      After removing the Object Reference,
      the results grid is updated showing the value of column Object in black.
    </p>
  </div>
);

export default SectionRemoveObjRef;
