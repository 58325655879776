import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

const ConfirmationModal = ({
  isShowing, setIsShowing, msg, actionBtnLabel, handleAction, id,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      backdrop="static"
      show={isShowing}
      onHide={() => {
        setIsShowing(false);
      }}
      dialogClassName="modal-90w"
    >
      <Modal.Body className="mt-3 d-flex justify-content-center align-content-center">
        <p>
          {msg}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            unstableBatchedUpdates(() => {
              setIsShowing(false);
            });
          }}
        >
          {t(tSchema.common.cancel)}
        </Button>
        <Button variant="danger" onClick={() => { handleAction(id); }}>
          {actionBtnLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isShowing: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsShowing: PropTypes.func.isRequired,
  msg: PropTypes.objectOf(PropTypes.any).isRequired,
  actionBtnLabel: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAction: PropTypes.func.isRequired,
  id: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ConfirmationModal;
