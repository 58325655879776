import PropTypes from 'prop-types';
import {
  head, isEmpty, last, range,
} from 'ramda';
import React, {
  forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState,
} from 'react';
import {
  Button,
  Col, Container, Form, OverlayTrigger, Pagination, Row, Table, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ActionsPopOver from '@components/ActionsPopOver';
import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';

import tSchema from '@lang/schema';

import ColumnsManager from './components/ColumnsManager/ColumnManager';

import './style.scss';

export const limitMin = (num, min) => (num < min ? min : num);
export const limitMax = (num, max) => (num > max ? max : num);

const MIN_COLUMNS_VISIBLE = 1;
export const DATA_TABLE_ACTIONS_CODE = 'DATA_TABLE_ACTIONS_CODE';
export const SUB_ROW_CODE = 'SUB_ROW_CODE';
const actionsConfig = (t) => (loading) => (actions) => ({
  text: t(tSchema.common.actions),
  code: DATA_TABLE_ACTIONS_CODE,
  disabled: true,
  visible: true,
  render: (row) => <ActionsPopOver actions={actions} row={row} loading={loading} />,
});
const subRowConfig = ({
  text: '',
  code: SUB_ROW_CODE,
  sortable: false,
  disabled: true,
  visible: true,
  hideOnManageColumns: true,
  render: (row, id, subRowId, setSubRowId) => {
    const isSame = subRowId === id;
    return (
      <OverlayTrigger
        placement="right"
        overlay={(props) => (
          <Tooltip id="sub-row-tooltip" {...props}>
            {isSame ? 'Collapse' : 'Expand'}
          </Tooltip>
        )}
      >
        <Button className="sub-row-toggle" variant="primary" onClick={() => setSubRowId(isSame ? null : id)}>
          <IconSvg
            name={isSame ? ICON.CHEVRON_UP : ICON.CHEVRON_DOWN}
            className="sub-row-toggle-icon"
          />
        </Button>
      </OverlayTrigger>
    );
  },
});

const getErrorTbody = (errorMessage) => (
  <tr id="error-data">
    <td style={{ textAlign: 'center' }} colSpan="13">{errorMessage}</td>
  </tr>
);

const ascSortIcon = <IconSvg name={ICON.CHEVRON_UP} className="sort-button asc" />;
const descSortIcon = <IconSvg name={ICON.CHEVRON_DOWN} className="sort-button desc" />;
const notSortedIcon = (
  <div className="sort-button not-sorted">
    {ascSortIcon}
    {descSortIcon}
  </div>
);
const renderTableHeader = (orderBy, stickyHeader, onTableHeaderClick) => (header) => {
  const isSorted = orderBy;
  const isSortedColumn = isSorted && orderBy.field === header.code;
  const sortedIcon = isSortedColumn && orderBy.order && ((orderBy.order.toLowerCase() === 'asc' && ascSortIcon) || (orderBy.order.toLowerCase() === 'desc' && descSortIcon));
  const sortIcon = isSortedColumn ? sortedIcon : notSortedIcon;

  const isHeaderCenter = header.code === DATA_TABLE_ACTIONS_CODE || header.text === 'toggle' || header.code === 'controlParty' || header.code === 'infringement' || header.code === 'attachments';

  return (
    <th
      key={`table-header-${header.code || header.text}`}
      id={`table-header-${header.code}`}
      className={[
        'table-header',
        'align-middle',
        header.sortable && 'cursor-pointer',
        stickyHeader && 'sticky',
      ].filter(Boolean).join(' ')}
      onClick={(header.onClick)
      || ((e) => header.sortable && onTableHeaderClick && onTableHeaderClick(header, e))}
    >

      <div className={[`d-flex align-items-center ${isHeaderCenter && 'justify-content-center'}`, header.headerClassName].filter(Boolean).join(' ')}>

        {header.header || header.text}

        {header.sortable && (
          (sortIcon) && (
            <div className="ml-2 d-flex flex-column">
              {sortIcon}
            </div>
          )
        )}
      </div>

    </th>
  );
};

const DataTable = forwardRef(({
  tableId,
  tableConfig,
  actions,
  rowSelected,
  onRowSelected: handleRowSelected,
  rowData,
  rowRender,
  loading,
  onTableHeaderClick,
  onPageSizeChangeHandler,
  onPageChangeHandler,
  tableActions,
  orderBy,
  pageSize,
  defaultPageSize,
  maxPages,
  pagination,
  stickyHeader,
  pageSizeOptions,
  error,
  errorMessageLoadingData,
  errorMessageEmptyArray,
  idColumn,
  showColumnsManager,
  children,
  showTotalResults,
  totalResults,
  showPagination = true,
  subRowRender,
  footerContent,
  margin,
}, ref) => {
  const { t } = useTranslation();

  const [pageRequest, setPageRequest] = useState(1);
  const [arrayTableHeader, setArrayTableHeader] = useState(tableConfig);

  const filterHeadersColumns = (headersColumns) => headersColumns.filter(
    (obj) => (!obj.hidden || !obj.checked),
  );

  const actionsConfigWithLoading = useMemo(() => actionsConfig(t)(loading), [loading]);

  useEffect(() => {
    setArrayTableHeader([
      ...(subRowRender ? [subRowConfig] : []),
      ...filterHeadersColumns(tableConfig),
      ...(actions
        ? [actionsConfigWithLoading(actions)]
        : []),
    ]);
  }, [actions, tableConfig, actionsConfigWithLoading]);

  const changePage = (i) => () => {
    if (!loading) {
      setPageRequest(i);
      onPageChangeHandler(i);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      resetPagination: (page = 1, triggerHandler = true) => {
        triggerHandler ? changePage(page)() : setPageRequest(page);
      },
    }),
  );

  const visiblePaginationPages = range(
    limitMin(
      (pageRequest - 2 < 1 ? 1 : pageRequest - 2)
        - (pageRequest + 3 > maxPages ? 2 - (maxPages - pageRequest) : 0),
      1,
    ),
    limitMax(
      (pageRequest + 3 > maxPages ? maxPages + 1 : pageRequest + 3)
        + (pageRequest - 2 < 1 ? -(pageRequest - 3) : 0),
      maxPages + 1,
    ),
  );

  const [columnsCheck, setColumnsCheck] = useState(arrayTableHeader);
  const [subRowId, setSubRowId] = useState(null);
  useEffect(() => setColumnsCheck(arrayTableHeader), [arrayTableHeader]);
  const selectedColumns = useMemo(() => columnsCheck.filter((col) => !col.hidden), [columnsCheck]);
  const visibleColumnsNumber = useMemo(() => selectedColumns?.length || 0, [selectedColumns]);
  const handleChangeCheckColumn = useCallback((code) => (event) => {
    const { checked } = event.target;
    if (checked || selectedColumns.length - 1 > MIN_COLUMNS_VISIBLE) {
      const newSelectedColums = columnsCheck.map((col) => (
        { ...col, ...(col.code === code && { hidden: !checked }) }
      ));
      setColumnsCheck(newSelectedColums);
    }
  },
  [columnsCheck, selectedColumns]);

  return (
    <Container fluid className="data-table" id={tableId}>
      {margin && <Row className="justify-content-between mb-2" />}
      <Row>
        <Col>
          {children}
          <Row className="overflow">
            <Table hover id={tableId}>
              {showColumnsManager && (
              <caption className="columns-manager">
                <ColumnsManager
                  columnsCheck={columnsCheck}
                  handleChangeCheckColumn={handleChangeCheckColumn}
                />
              </caption>
              )}
              {loading && (
                <caption id="data-table-spinner">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">{t(tSchema.common.loading)}</span>
                  </div>
                </caption>
              )}

              <thead>
                <tr>
                  {selectedColumns.map(renderTableHeader(
                    orderBy,
                    stickyHeader,
                    onTableHeaderClick,
                  ))}
                </tr>
              </thead>

              <tbody className={[loading && 'loading'].filter(Boolean).join(' ')}>
                {!loading && (!rowData?.length || error) && (
                  getErrorTbody(error ? errorMessageLoadingData : errorMessageEmptyArray)
                )}

                {(rowData && !isEmpty(rowData)) && rowData.map((row) => {
                  const id = row[idColumn || row.name];
                  return (
                    <>
                      {rowRender({
                        row,
                        id,
                        cols: selectedColumns,
                        selectedId: rowSelected,
                        onClick: handleRowSelected,
                        subRowId,
                        setSubRowId,
                      })}
                      {subRowRender && subRowId === id && (
                      <tr
                        key={`subrow-${id}`}
                        className="sub-row"
                      >
                        <td
                          colSpan={visibleColumnsNumber}
                        >
                          {subRowRender(row)}
                        </td>
                      </tr>
                      )}
                    </>
                  );
                })}
              </tbody>

              <tfoot hidden={!loading || !isEmpty(rowData)}>
                <tr>
                  {range(loading ? 1 : 0, visibleColumnsNumber || 1).map((i) => (
                    <th
                      key={i}
                      style={{
                        height: 100, // 500 - 50 * rowData.length,
                      }}
                    >
                      &nbsp;
                    </th>
                  ))}
                </tr>
              </tfoot>

            </Table>
          </Row>
          { showPagination && (
          <Row className="justify-content-between" style={{ overflow: 'inherit' }}>
            <div className={`d-flex justify-content-between ${footerContent && 'w-100'}`}>
              {pagination && !isEmpty(rowData) && (
                <>
                  <div className="pagination d-flex">
                    {
                    showTotalResults && (
                      <div className="d-flex">
                        {t(tSchema.common.results, totalResults, { count: totalResults })}
                      </div>
                    )
                }
                    {
                    (pageSizeOptions && totalResults !== 0
                      && totalResults > pageSizeOptions[0]) && (
                      <>
                        <div className="d-flex">
                          <div className="">{t(tSchema.common.resultsPerPage)}</div>
                          <Form.Control
                            as="select"
                            className="page-size-selector"
                            onChange={(ev) => {
                              setPageRequest(1);
                              onPageSizeChangeHandler
                            && onPageSizeChangeHandler(ev.target.value || 0);
                            }}
                            value={pageSize || defaultPageSize}
                            disabled={loading}
                          >
                            {pageSizeOptions.map((size) => <option key={`pageSize-${size}`}>{size}</option>)}
                          </Form.Control>
                        </div>
                        <div className="d-flex">
                          <Pagination className="m-0">
                            <Pagination.First
                              disabled={pageRequest === 1}
                              onClick={changePage(1)}
                              className="first fast-navigation"
                            />
                            <Pagination.Prev
                              disabled={pageRequest === 1}
                              onClick={changePage(pageRequest - 1)}
                              className="previous fast-navigation"
                            />
                            {head(visiblePaginationPages) !== 1 && (
                            <>
                              <Pagination.Item onClick={changePage(1)}>{1}</Pagination.Item>
                              <Pagination.Ellipsis disabled />
                            </>
                            )}
                            {visiblePaginationPages.map((i) => (
                              <Pagination.Item
                                disabled={pageRequest === i}
                                active={pageRequest === i}
                                onClick={changePage(i)}
                                key={`Pagination.Item${i}`}
                              >
                                {i}
                              </Pagination.Item>
                            ))}
                            {last(visiblePaginationPages) !== maxPages && (
                            <>
                              <Pagination.Ellipsis disabled />
                              <Pagination.Item onClick={changePage(maxPages)}>
                                {maxPages}
                              </Pagination.Item>
                            </>
                            )}
                            <Pagination.Next
                              disabled={pageRequest === maxPages}
                              onClick={changePage(pageRequest + 1)}
                              className="next fast-navigation"
                            />
                            <Pagination.Last
                              disabled={pageRequest === maxPages}
                              onClick={changePage(maxPages)}
                              className="last fast-navigation"
                            />
                          </Pagination>
                        </div>
                      </>
                    )
                }
                  </div>
                  <div>
                    {footerContent && (
                    <div>
                      {footerContent}
                    </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {tableActions && <div className="table-actions">{tableActions}</div>}
          </Row>
          ) }
        </Col>
      </Row>
    </Container>
  );
});

const defaultRowRender = ({
  row, id, cols, selectedId, onClick, subRowId, setSubRowId,
}) => (
  <tr
    key={`row-${id}`}
    className={selectedId && id === selectedId ? 'selected-row' : null}
    onClick={(event) => onClick && onClick(row, event)}
  >
    {cols.map((columnConfig) => (
      <td key={`row-${id}-column-${columnConfig.text}`} className={[columnConfig.code, columnConfig.className].filter(Boolean).join(' ')}>
        {columnConfig.render
          ? columnConfig.render(row, id, subRowId, setSubRowId)
          : row[columnConfig.code]}

      </td>
    ))}
  </tr>
);

DataTable.propTypes = {
  showColumnsManager: PropTypes.bool,
  tableId: PropTypes.string.isRequired,
  tableConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object),
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowRender: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number,
  onTableHeaderClick: PropTypes.func,
  onPageSizeChangeHandler: PropTypes.func,
  onPageChangeHandler: PropTypes.func,
  tableActions: PropTypes.element,
  orderBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  defaultPageSize: PropTypes.number,
  maxPages: PropTypes.number,
  pagination: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onRowSelected: PropTypes.func,
  rowSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  errorMessageLoadingData: PropTypes.string,
  errorMessageEmptyArray: PropTypes.string,
  idColumn: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showTotalResults: PropTypes.bool,
  totalResults: PropTypes.number,
  showPagination: PropTypes.bool,
  subRowRender: PropTypes.func,
  footerContent: PropTypes.element,
  margin: PropTypes.bool,
};
DataTable.defaultProps = {
  showColumnsManager: false,
  pagination: true,
  actions: null,
  onTableHeaderClick: null,
  onPageSizeChangeHandler: null,
  onPageChangeHandler: null,
  pageSize: null,
  tableActions: null,
  orderBy: null,
  defaultPageSize: null,
  maxPages: null,
  stickyHeader: true,
  pageSizeOptions: [10, 25, 50, 100],
  onRowSelected: null,
  rowSelected: '',
  error: false,
  errorMessageLoadingData: 'Data cannot be loaded',
  errorMessageEmptyArray: 'No results found',
  rowRender: defaultRowRender,
  idColumn: 'id',
  children: null,
  showTotalResults: true,
  totalResults: 0,
  showPagination: true,
  subRowRender: null,
  footerContent: null,
  margin: true,
};

DataTable.displayName = 'DataTable';

export default DataTable;
