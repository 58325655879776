import PropTypes from 'prop-types';

export const STATUS_CODE = Object.freeze({
  REPLACED: '1',
  CANCELLED: '2',
  DELETED: '3',
  ORIGINAL: '4',
});

export const classNames = {
  [STATUS_CODE.REPLACED]: 'Replaced',
  [STATUS_CODE.CANCELLED]: 'Cancelled',
  [STATUS_CODE.DELETED]: 'Deleted',
  [STATUS_CODE.ORIGINAL]: 'Original',
};

export const DEPARTURE_LOCATION_PROPTYPES = PropTypes.shape(
  {
    port: PropTypes.string,
    country: PropTypes.string,
    farm: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    faoArea: PropTypes.string,
    statRectangle: PropTypes.string,
    effortZone: PropTypes.string,
    territoryEez: PropTypes.string,
    managementArea: PropTypes.string,
    rfmo: PropTypes.string,
    characteristics: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
    })),
  },
);

export const RELATED_FISHING_TRIP_PROPTYPES = PropTypes.shape(
  {
    identification: PropTypes.string,
    type: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    duration: PropTypes.string,
  },
);

export const PRODUCT_PROPTYPES = PropTypes.shape({
  packagingType: PropTypes.string,
  packagingUnit: PropTypes.string,
  packagingUnitAvgWeight: PropTypes.string,
  weight: PropTypes.string,
  unit: PropTypes.string,
});
export const PRODUCTS_PROPTYPES = PropTypes.arrayOf(PRODUCT_PROPTYPES);
export const AAP_PROCESS_PROPTYPES = PropTypes.shape(
  {
    presentation: PropTypes.string,
    preservation: PropTypes.string,
    freshness: PropTypes.string,
    conversionFactor: PropTypes.string,
    products: PRODUCTS_PROPTYPES,
  },
);
export const AAP_PROCESSES_PROPTYPES = PropTypes.arrayOf(AAP_PROCESS_PROPTYPES);

export const DEPARTURE_CATCH_PROPTYPES = PropTypes.shape(
  {
    type: PropTypes.string,
    species: PropTypes.string,
    unitQuantity: PropTypes.string,
    weight: PropTypes.string,
    weightingMeans: PropTypes.string,
    sizeClass: PropTypes.string,
    sizeCategory: PropTypes.string,
    destination: PropTypes.string,
    relatedFishingTrip: RELATED_FISHING_TRIP_PROPTYPES,
    aapProcesses: AAP_PROCESSES_PROPTYPES,
    location: DEPARTURE_LOCATION_PROPTYPES,
    gears: [],
  },
);
export const DEPARTURE_CATCHES_PROPTYPES = PropTypes.arrayOf(DEPARTURE_CATCH_PROPTYPES);

export const DEPARTURE_GEAR_PROPTYPES = PropTypes.shape(
  {
    type: PropTypes.string,
    typeDescription: PropTypes.string,
    role: PropTypes.string,
    gearCharacteristics: PropTypes.arrayOf(PropTypes.shape(
      {
        type: PropTypes.string,
        measure: PropTypes.string,
        measureUnit: PropTypes.string,
        date: PropTypes.string,
        indicator: PropTypes.string,
        code: PropTypes.string,
        text: PropTypes.string,
        quantity: PropTypes.string,
      },
    )),
  },
);
export const DEPARTURE_GEARS_PROPTYPES = PropTypes.arrayOf(DEPARTURE_GEAR_PROPTYPES);

export const DEPARTURE_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
    ocurrence: PropTypes.string,
    reason: PropTypes.string,
    reasonDescription: PropTypes.string,
    fisheryType: PropTypes.string,
    speciesTarget: PropTypes.string,
    speciesTargetGroup: PropTypes.string,
    status: PropTypes.string,
    location: DEPARTURE_LOCATION_PROPTYPES,
    catches: DEPARTURE_CATCHES_PROPTYPES,
    gears: DEPARTURE_GEARS_PROPTYPES,
  },
));
export const STORAGE_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
    type: PropTypes.string,
    identification: PropTypes.string,
  },
));
export const DISCARD_AT_SEA_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const JOIN_FISHING_OPERATION_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const RELOCATION_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const TRANSHIPMENT_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const EXIT_FROM_AREA_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const ARRIVAL_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const LANDING_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
  },
));
export const PRIOR_NOTIFICATION_ARRIVAL_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
    ocurrence: PropTypes.number,
    reason: PropTypes.string,
    reasonDescription: PropTypes.string,
    state: PropTypes.string,
    date: PropTypes.number,
    location: DEPARTURE_LOCATION_PROPTYPES,
    catches: DEPARTURE_CATCHES_PROPTYPES,
  },
));

export const ENTRYINTOAREA_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
    ocurrence: PropTypes.string,
    reason: PropTypes.string,
    reasonDescription: PropTypes.string,
    fisheryType: PropTypes.string,
    speciesTarget: PropTypes.string,
    speciesTargetGroup: PropTypes.string,
    location: DEPARTURE_LOCATION_PROPTYPES,
    catches: DEPARTURE_CATCHES_PROPTYPES,
    gears: DEPARTURE_GEARS_PROPTYPES,
  },
));

export const FISHING_OPERATION_GEAR_PROBLEM_PROPTYPES = PropTypes.shape({
  recoveryMeasure: PropTypes.string,
  type: PropTypes.string,
  affectedQuantity: PropTypes.string,
  location: DEPARTURE_LOCATION_PROPTYPES,
  relatedFishingGears: [{
    type: PropTypes.string,
    typeDescription: PropTypes.string,
    role: PropTypes.string,
    gearCharacteristics: [{
      type: PropTypes.string,
      measure: PropTypes.string,
      measureUnit: PropTypes.string,
      date: PropTypes.number,
      indicator: PropTypes.string,
      code: PropTypes.string,
      text: PropTypes.string,
      quantity: PropTypes.string,
    }],
  }],
});

export const FISHING_OPERATION_GEAR_PROBLEMS_PROPTYPES = PropTypes.arrayOf(
  FISHING_OPERATION_GEAR_PROBLEM_PROPTYPES,
);

export const FISHING_OPERATION_PROPTYPES = PropTypes.arrayOf(PropTypes.shape(
  {
    ocurrence: PropTypes.number,
    relatedVesselActivity: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    duration: PropTypes.string,
    operations: PropTypes.string,
    fisheryType: PropTypes.string,
    fisheryTypeDescription: PropTypes.string,
    speciesTarget: PropTypes.string,
    speciesTargetGroup: PropTypes.string,
    identification: PropTypes.string,
    state: PropTypes.string,
    date: PropTypes.number,
    location: DEPARTURE_LOCATION_PROPTYPES,
    catches: DEPARTURE_CATCHES_PROPTYPES,
    gears: DEPARTURE_GEARS_PROPTYPES,
    gearProblems: FISHING_OPERATION_GEAR_PROBLEMS_PROPTYPES,
  },
));

export const FISHING_OPERATION_OTHER_CHARACTERISTIC_PROPTYPES = PropTypes.shape({
  code: PropTypes.string,
  value: PropTypes.string,
});

export const FISHING_OPERATION_OTHER_CHARACTERISTICS_PROPTYPES = PropTypes.arrayOf(
  FISHING_OPERATION_OTHER_CHARACTERISTIC_PROPTYPES,
);

export const FISHING_OPERATION_SUBACTIVITY_PROPTYPES = PropTypes.shape({
  type: PropTypes.string,
  ocurrence: PropTypes.number,
  identification: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number,
  duration: PropTypes.string,
  location: DEPARTURE_LOCATION_PROPTYPES,
  fishingGear: DEPARTURE_GEAR_PROPTYPES,
  gearProblems: FISHING_OPERATION_GEAR_PROBLEMS_PROPTYPES,
  otherCharacteristics: FISHING_OPERATION_OTHER_CHARACTERISTICS_PROPTYPES,
});

export const CONTACT_INFO_PROPTYPES = PropTypes.shape({
  givenName: PropTypes.string,
  middleName: PropTypes.string,
  role: PropTypes.string,
  alias: PropTypes.string,
  familyNamePrefix: PropTypes.string,
  nameSuffix: PropTypes.string,
  gender: PropTypes.string,
  country: PropTypes.string,
  countrySubdivision: PropTypes.string,
  cityName: PropTypes.string,
  citySubdivision: PropTypes.string,
  streetName: PropTypes.string,
  blockName: PropTypes.string,
  buildingName: PropTypes.string,
  plotIdentification: PropTypes.string,
  postOfficeBox: PropTypes.string,
  postalArea: PropTypes.string,
});

export const FISHING_OPERATION_SUBACTIVITIES_PROPTYPES = PropTypes.arrayOf(
  FISHING_OPERATION_SUBACTIVITY_PROPTYPES,
);

export const FISHING_OPERATION_RELATED_VESSEL_PROPTYPES = PropTypes.shape({
  role: PropTypes.string,
  name: PropTypes.string,
  country: PropTypes.string,
  cfr: PropTypes.string,
  uvi: PropTypes.string,
  extMark: PropTypes.string,
  iccat: PropTypes.string,
  gfcm: PropTypes.string,
  contactInfo: CONTACT_INFO_PROPTYPES,
});

export const FISHING_OPERATION_RELATED_VESSELS_PROPTYPES = PropTypes.arrayOf(
  FISHING_OPERATION_RELATED_VESSEL_PROPTYPES,
);

export const JOIN_FISHING_OPERATION_SUBACTIVITY_PROPTYPES = PropTypes.shape({
  type: PropTypes.string,
  relatedVessel: FISHING_OPERATION_RELATED_VESSEL_PROPTYPES,
  catches: DEPARTURE_CATCHES_PROPTYPES,
  otherCharacteristics: FISHING_OPERATION_OTHER_CHARACTERISTICS_PROPTYPES,
});

export const JOIN_FISHING_OPERATION_SUBACTIVITIES_PROPTYPES = PropTypes.arrayOf(
  JOIN_FISHING_OPERATION_SUBACTIVITY_PROPTYPES,
);

export const REPORT_DETAILS_PROPTYPES = PropTypes.shape({
  vesselName: PropTypes.string,
  vesselFlag: PropTypes.string,
  cfr: PropTypes.string,
  uvi: PropTypes.string,
  extMark: PropTypes.string,
  iccat: PropTypes.string,
  gfcm: PropTypes.string,
  fishingTrip: {
    type: PropTypes.string,
    euTripId: PropTypes.string,
    neafcTripId: PropTypes.string,
    givenName: PropTypes.string,
    role: PropTypes.string,
    middleName: PropTypes.string,
  },
  contactInfo: {
    givenName: PropTypes.string,
    middleName: PropTypes.string,
    role: PropTypes.string,
    alias: PropTypes.string,
    familyNamePrefix: PropTypes.string,
    nameSuffix: PropTypes.string,
    gender: PropTypes.string,
    country: PropTypes.string,
    countrySubdivision: PropTypes.string,
    cityName: PropTypes.string,
    citySubdivision: PropTypes.string,
    streetName: PropTypes.string,
    blockName: PropTypes.string,
    buildingName: PropTypes.string,
    plotIdentification: PropTypes.string,
    postOfficeBox: PropTypes.string,
    postalArea: PropTypes.string,
  },
  departure: DEPARTURE_PROPTYPES,
});

export const DEPARTURE_LOCATION_SCHEMA = {
  port: '',
  country: '',
  farm: '',
  latitude: '',
  longitude: '',
  faoArea: '',
  statRectangle: '',
  effortZone: '',
  territoryEez: '',
  managementArea: '',
  rfmo: '',
  characteristics: [{
    code: null,
    value: null,
  }],
};
export const DEPARTURE_LOCATIONS_SCHEMA = [DEPARTURE_LOCATION_SCHEMA];

export const DISCARD_AT_SEA_SCHEMA = [{
  ocurrence: null,
  identification: null,
  reason: null,
  reasonDescription: null,
  state: null,
  date: null,
  location: {
    port: null,
    country: null,
    farm: null,
    latitude: null,
    longitude: null,
    faoArea: null,
    statRectangle: null,
    effortZone: null,
    territoryEez: null,
    managementArea: null,
    rfmo: null,
    characteristics: [{
      code: null,
      value: null,
    }],
  },
  catches: [{
    type: null,
    species: null,
    unitQuantity: null,
    weight: null,
    weightingMeans: null,
    sizeClass: null,
    sizeCategory: null,
    destination: {
      port: null,
      country: null,
      farm: null,
      latitude: null,
      longitude: null,
      faoArea: null,
      statRectangle: null,
      effortZone: null,
      territoryEez: null,
      managementArea: null,
      rfmo: null,
      characteristics: [{
        code: null,
        value: null,
      }],
    },
    relatedFishingTrip: {
      euTripId: null,
      neafcTripId: null,
      type: null,
      startDate: null,
      endDate: null,
      duration: null,
    },
    aapProcesses: [{
      presentation: null,
      preservation: null,
      freshness: null,
      conversionFactor: null,
      products: [{
        packagingType: null,
        packagingUnit: null,
        packagingUnitAvgWeight: null,
        weight: null,
        fishQuantity: null,
        weightMeans: null,
      }],
    }],
    location: {
      port: null,
      country: null,
      farm: null,
      latitude: null,
      longitude: null,
      faoArea: null,
      statRectangle: null,
      effortZone: null,
      territoryEez: null,
      managementArea: null,
      rfmo: null,
      characteristics: [{
        code: null,
        value: null,
      }],
    },
    gears: [{
      type: null,
      typeDescription: null,
      role: null,
      gearCharacteristics: [{
        characteristicType: null,
        characteristicDescription: null,
        measureMMT: null,
        measureMTR: null,
        measureUnit: null,
        date: null,
        indicator: null,
        code: null,
        text: null,
        quantity: null,
      }],
    }],
  }],
  discardReasons: [{
    description: null,
    value: null,
  }],
}];

export const RELOCATION_SCHEMA = [{}];
export const JOIN_FISHING_OPERATION_SCHEMA = [{}];
export const EXIT_FROM_AREA_SCHEMA = [{}];
export const ARRIVAL_SCHEMA = [{}];
export const LANDING_SCHEMA = [{}];
export const TRANSHIPMENT_SCHEMA = [{}];
export const DEPARTURE_SCHEMA = [
  {
    ocurrence: '',
    reason: '',
    reasonDescription: null,
    fisheryType: null,
    speciesTarget: null,
    speciesTargetGroup: '',
    status: '',
    location: {
      port: '',
      country: '',
      farm: null,
      latitude: null,
      longitude: null,
      faoArea: null,
      statRectangle: null,
      effortZone: null,
      territoryEez: null,
      managementArea: null,
      rfmo: null,
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    catches: [
      {
        type: '',
        species: '',
        unitQuantity: '',
        weight: '',
        weightingMeans: null,
        sizeClass: '',
        sizeCategory: null,
        destination: null,
        relatedFishingTrip: {
          identification: null,
          type: null,
          startDate: null,
          endDate: null,
          duration: null,
        },
        aapProcesses: [
          {
            presentation: null,
            preservation: null,
            freshness: null,
            conversionFactor: '',
            products: [
              {
                packagingType: '',
                packagingUnit: '',
                packagingUnitAvgWeight: '',
                weight: '',
                unit: '',
              },
            ],
          },
        ],
        location: {
          port: '',
          country: '',
          farm: null,
          latitude: null,
          longitude: null,
          faoArea: null,
          statRectangle: null,
          effortZone: null,
          territoryEez: null,
          managementArea: null,
          rfmo: null,
          characteristics: [{
            code: '',
            value: '',
          }],
        },
        gears: [],
      },
    ],
    gears: [
      {
        type: '',
        role: '',
        gearCharacteristics: [
          {
            type: '',
            measure: '',
            measureUnit: null,
            date: null,
            indicator: null,
            code: null,
            text: '',
            quantity: '',
          },
          {
            type: '',
            measure: '',
            measureUnit: '',
            date: null,
            indicator: null,
            code: null,
            text: null,
            quantity: '',
          },
          {
            type: '',
            measure: '',
            measureUnit: null,
            date: null,
            indicator: null,
            code: null,
            text: '',
            quantity: '',
          },
        ],
      },
      {
        type: '',
        role: '',
        gearCharacteristics: [
          {
            type: '',
            measure: '',
            measureUnit: '',
            date: null,
            indicator: null,
            code: null,
            text: null,
            quantity: '',
          },
          {
            type: '',
            measure: '',
            measureUnit: '',
            date: null,
            indicator: null,
            code: null,
            text: null,
            quantity: '',
          },
          {
            type: '',
            measure: '',
            measureUnit: null,
            date: null,
            indicator: null,
            code: null,
            text: '',
            quantity: '',
          },
        ],
      },
    ],
  },
];

export const FISHING_OPERATION_SCHEMA = {
  ocurrence: null,
  relatedVesselActivity: '',
  relatedVesselActivityDescription: '',
  identification: '',
  startDate: null,
  endDate: null,
  duration: '',
  fisheryType: '',
  fisheryTypeDescription: '',
  operations: '',
  speciesTarget: '',
  speciesTargetGroup: '',
  state: '',
  date: null,
  location: {
    port: '',
    country: '',
    farm: '',
    latitude: '',
    longitude: '',
    faoArea: '',
    statRectangle: '',
    effortZone: '',
    territoryEez: '',
    managementArea: '',
    rfmo: '',
    characteristics: [{
      code: '',
      value: '',
    }],
  },
  catches: [{
    type: '',
    species: '',
    unitQuantity: '',
    weight: '',
    weightingMeans: '',
    sizeClass: '',
    sizeCategory: '',
    destination: {
      port: '',
      country: '',
      farm: '',
      latitude: '',
      longitude: '',
      faoArea: '',
      statRectangle: '',
      effortZone: '',
      territoryEez: '',
      managementArea: '',
      rfmo: '',
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    relatedFishingTrip: {
      euTripId: '',
      neafcTripId: '',
      type: '',
      startDate: null,
      endDate: null,
      duration: null,
    },
    aapProcesses: [{
      presentation: '',
      preservation: '',
      freshness: '',
      conversionFactor: '',
      products: [{
        packagingType: '',
        packagingUnit: '',
        packagingUnitAvgWeight: '',
        weight: '',
        fishQuantity: '',
        weightMeans: '',
      }],
    }],
    location: {
      port: '',
      country: '',
      farm: '',
      latitude: '',
      longitude: '',
      faoArea: '',
      statRectangle: '',
      effortZone: '',
      territoryEez: '',
      managementArea: '',
      rfmo: '',
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    gears: [{
      type: '',
      typeDescription: '',
      role: '',
      gearCharacteristics: [{
        characteristicType: '',
        characteristicDescription: '',
        measureMMT: '',
        measureMTR: '',
        measureUnit: '',
        date: null,
        indicator: '',
        code: '',
        text: '',
        quantity: '',
      }],
    }],
  }],
  gears: [{
    type: '',
    typeDescription: '',
    role: '',
    gearCharacteristics: [{
      characteristicType: '',
      characteristicDescription: '',
      measureMMT: '',
      measureMTR: '',
      measureUnit: '',
      date: null,
      indicator: '',
      code: '',
      text: '',
      quantity: '',
    }],
  }],
  gearProblems: [{
    type: '',
    affectedQuantity: '',
    recoveryMeasure: '',
    location: {
      port: '',
      country: '',
      farm: '',
      latitude: '',
      longitude: '',
      faoArea: '',
      statRectangle: '',
      effortZone: '',
      territoryEez: '',
      managementArea: '',
      rfmo: '',
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    relatedFishingGears: [{
      type: '',
      typeDescription: '',
      role: '',
      gearCharacteristics: [{
        characteristicType: '',
        characteristicDescription: '',
        measureMMT: '',
        measureMTR: '',
        measureUnit: '',
        date: null,
        indicator: '',
        code: '',
        text: '',
        quantity: '',
      }],
    }],
  }],
  relatedVessels: [{
    role: '',
    name: '',
    country: '',
    cfr: '',
    uvi: '',
    extMark: '',
    iccat: '',
    gfcm: '',
    contactInfo: {
      givenName: '',
      middleName: '',
      role: '',
      alias: '',
      familyNamePrefix: '',
      nameSuffix: '',
      gender: '',
      country: '',
      countrySubdivision: '',
      cityName: '',
      citySubdivision: '',
      streetName: '',
      blockName: '',
      buildingName: '',
      plotIdentification: '',
      postOfficeBox: '',
      postalArea: '',
    },
  }],
  subActivities: [{
    identification: '',
    type: '',
    ocurrence: null,
    start: null,
    end: null,
    duration: '',
    location: {
      port: '',
      country: '',
      farm: '',
      latitude: '',
      longitude: '',
      faoArea: '',
      statRectangle: '',
      effortZone: '',
      territoryEez: '',
      managementArea: '',
      rfmo: '',
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    fishingGear: {
      type: '',
      typeDescription: '',
      role: '',
      gearCharacteristics: [{
        characteristicType: '',
        characteristicDescription: '',
        measureMMT: '',
        measureMTR: '',
        measureUnit: '',
        date: null,
        indicator: '',
        code: '',
        text: '',
        quantity: '',
      }],
    },
    gearProblems: [{
      type: '',
      affectedQuantity: '',
      recoveryMeasure: '',
      location: {
        port: '',
        country: '',
        farm: '',
        latitude: '',
        longitude: '',
        faoArea: '',
        statRectangle: '',
        effortZone: '',
        territoryEez: '',
        managementArea: '',
        rfmo: '',
        characteristics: [{
          code: '',
          value: '',
        }],
      },
      relatedFishingGears: [{
        type: '',
        typeDescription: '',
        role: '',
        gearCharacteristics: [{
          characteristicType: '',
          characteristicDescription: '',
          measureMMT: '',
          measureMTR: '',
          measureUnit: '',
          date: null,
          indicator: '',
          code: '',
          text: '',
          quantity: '',
        }],
      }],
    }],
    characteristics: [{
      code: '',
      value: '',
    }],
  }],
  characteristics: [{
    code: '',
    value: '',
  }],
};

export const REPORT_DETAILS_SCHEMA = {
  vesselName: '',
  vesselFlag: '',
  cfr: '',
  uvi: '',
  extMark: '',
  iccat: '',
  gfcm: '',
  fishingTrip: {
    type: '',
    euTripId: '',
    neafcTripId: '',
    givenName: '',
    role: '',
    middleName: '',
  },
  contactInfo: {
    givenName: '',
    middleName: '',
    role: '',
    alias: '',
    familyNamePrefix: '',
    nameSuffix: '',
    gender: '',
    country: '',
    countrySubdivision: '',
    cityName: '',
    citySubdivision: '',
    streetName: '',
    blockName: '',
    buildingName: '',
    plotIdentification: '',
    postOfficeBox: '',
    postalArea: '',
  },
  departure: DEPARTURE_SCHEMA,
};

export const PRIOR_NOTIFICATION_ARRIVAL_SCHEMA = {
  ocurrence: null,
  reason: '',
  reasonDescription: '',
  state: '',
  date: null,
  location: {
    port: '',
    country: '',
    farm: '',
    latitude: '',
    longitude: '',
    faoArea: '',
    statRectangle: '',
    effortZone: '',
    territoryEez: '',
    managementArea: '',
    rfmo: '',
    characteristics: [{
      code: '',
      value: '',
    }],
  },
  catches: [{
    type: '',
    species: '',
    unitQuantity: '',
    weight: '',
    weightingMeans: '',
    sizeClass: '',
    sizeCategory: '',
    destination: {
      port: '',
      country: '',
      farm: '',
      latitude: '',
      longitude: '',
      faoArea: '',
      statRectangle: '',
      effortZone: '',
      territoryEez: '',
      managementArea: '',
      rfmo: '',
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    relatedFishingTrip: {
      euTripId: '',
      neafcTripId: '',
      type: '',
      startDate: null,
      endDate: null,
      duration: null,
    },
    aapProcesses: [{
      presentation: '',
      preservation: '',
      freshness: '',
      conversionFactor: '',
      products: [{
        packagingType: '',
        packagingUnit: '',
        packagingUnitAvgWeight: '',
        weight: '',
        fishQuantity: '',
        weightMeans: '',
      }],
    }],
    location: {
      port: '',
      country: '',
      farm: '',
      latitude: '',
      longitude: '',
      faoArea: '',
      statRectangle: '',
      effortZone: '',
      territoryEez: '',
      managementArea: '',
      rfmo: '',
      characteristics: [{
        code: '',
        value: '',
      }],
    },
    gears: [{
      type: '',
      typeDescription: '',
      role: '',
      gearCharacteristics: [{
        characteristicType: '',
        characteristicDescription: '',
        measureMMT: '',
        measureMTR: '',
        measureUnit: '',
        date: null,
        indicator: '',
        code: '',
        text: '',
        quantity: '',
      }],
    }],
  }],
};
