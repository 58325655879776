/* eslint-disable import/order */

import tSchema from '@lang/schema';
import { formatDateTime } from '@common/dateUtils';
import './style.scss';

const schema = tSchema.dmaAdmin.dataAdminReporting.dataActivity;

export const getTableConfig = (t) => [
  {
    text: t(schema.cfr),
    code: 'cfr',
    sortable: true,
  },
  {
    text: t(schema.uvi),
    code: 'uvi',
    sortable: true,
  },
  { text: t(schema.source), code: 'source', sortable: true },
  {
    text: t(schema.typeOfAction),
    code: 'action',
    sortable: true,
  },
  {
    text: t(schema.dateOfAction), code: 'dateOfAction', sortable: true, render: (row) => formatDateTime(row.dateOfAction, null, true),
  },
];

const actionOptions = [
  { id: 'CONFIRMATION', description: 'Confirmation' },
  { id: 'CHANGE STATUS', description: 'Change status' },
  { id: 'MODIFICATION', description: 'Modification' },
  { id: 'CREATION', description: 'Creation' },
];

export const filtersList = [
  {
    code: 'dateOfAction',
    label: 'Date of Action',
    type: 'date',
  },
  {
    code: 'cfr',
    label: 'CFR',
    type: 'text',
  },
  {
    code: 'uvi',
    label: 'UVI',
    type: 'text',
  },
  {
    code: 'source',
    label: 'Source',
    type: 'text',
  },
  {
    code: 'typeOfAction',
    label: 'Type of Action',
    type: 'select',
    options: actionOptions,
  },

];

export const searchOptions = [
  { label: 'Smaller than', value: '=*', type: 'date' },
  { label: 'Greater than', value: '*=', type: 'date' },
  { label: 'Equals', value: '==', type: 'date' },
  { label: 'Range', value: '*', type: 'date' },
];

export const searchableAttributesLocal = ['cfr', 'uvi', 'source', 'typeOfAction', 'dateOfAction'];
