import PropTypes from 'prop-types';
import { useState } from 'react';
import { Popover, OverlayTrigger, Collapse } from 'react-bootstrap';
import {
  ExclamationTriangleFill, ExclamationCircleFill, DashCircleFill, InfoCircleFill,
} from 'react-bootstrap-icons';

const ValidationWarning = ({ messages }) => {
  const classifiedMessages = [
    messages.filter((message) => message.resultState === 'ERROR'),
    messages.filter((message) => message.resultState === 'WARNING'),
    messages.filter((message) => message.resultState === 'EXCEPTION'),
  ];

  const maxErrorColor = () => {
    if (classifiedMessages[0].length !== 0) { return '#ff6161'; }
    if (classifiedMessages[1].length !== 0) { return 'orange'; }
    return '#d2ad46';
  };

  function KindValidation(kind) {
    switch (kind) {
      case 'Rejection':
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <DashCircleFill color="#ad2828" style={{ marginRight: '0.5rem' }} />
            <h7 style={{ marginRight: '1.25rem' }}>Error</h7>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{classifiedMessages[0].length}</span>
          </div>
        );
      case 'Major error':
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <ExclamationCircleFill color="orange" style={{ marginRight: '0.5rem' }} />
            <h7 style={{ marginRight: '1.25rem' }}>Warning</h7>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{classifiedMessages[1].length}</span>
          </div>
        );

      case 'Minor error':
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InfoCircleFill color="#d2ad46" style={{ marginRight: '0.5rem' }} />
            <h7 style={{ marginRight: '1.25rem' }}>Exception</h7>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{classifiedMessages[2].length}</span>
          </div>
        );

      default:
    }
  }

  const ErrorStructure = ({
    kind,
    specificMessages,
  }) => {
    const [open, setOpen] = useState(false);
    return (
      <div>
        <Popover.Title onClick={() => {
          setOpen(!open);
        }}
        >
          {KindValidation(kind)}
        </Popover.Title>
        <Collapse in={open}>
          <Popover.Content>
            {specificMessages.map((message) => (
              <div id="example-collapse-text">
                <b>{message.ruleName}</b>
                <br />
                {message.description}
              </div>
            ))}

          </Popover.Content>

        </Collapse>
      </div>

    );
  };

  ErrorStructure.defaultProps = {
    kind: PropTypes.string,
    specificMessages: PropTypes.arrayOf(PropTypes.object),
  };

  ErrorStructure.propTypes = {
    kind: PropTypes.string,
    specificMessages: PropTypes.arrayOf(PropTypes.object),
  };

  const popover = (
    <Popover id="popover-basic" rootClose="false">
      {classifiedMessages[0].length !== 0 && <ErrorStructure kind="Rejection" specificMessages={classifiedMessages[0]} />}
      {classifiedMessages[1].length !== 0 && <ErrorStructure kind="Major error" specificMessages={classifiedMessages[1]} />}
      {classifiedMessages[2].length !== 0 && <ErrorStructure kind="Minor error" specificMessages={classifiedMessages[2]} />}
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" rootClose overlay={popover} placement="bottom">
      <ExclamationTriangleFill color={maxErrorColor()} />
    </OverlayTrigger>
  );
};

ValidationWarning.defaultProps = {
  messages: PropTypes.arrayOf(PropTypes.object),
};

ValidationWarning.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
};

export default ValidationWarning;
