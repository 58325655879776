/* eslint-disable linebreak-style */
import { get } from '@services/utils';

/**
 * @param {String|Number} xmlId The value of declaration's xmlIncomingID
 * @returns {Promise<Response>}
 */
export function fetchReportViewSectionMessage(xmlId) {
  return get()(`messages/incoming/id/${xmlId}`);
}
export default { fetchReportViewSectionMessage };
