import PropTypes from 'prop-types';
import { createContext, useState, useContext } from 'react';

const UserPreferencesContext = createContext();

function UserContextProvider({ children }) {
  const [userPreferences, setUserPreferences] = useState(
    {
      userScenario: [],
      lastActiveFa: {},
      lastActiveIsr: {},
      domainOptions: [],
      domain: '',
      domains: [],
      loadingDomains: true,
      logbookFavFilters: [],
      userScenariosFa: [],
      userScenariosIsr: [],
    },
  );
  const value = { userPreferences, setUserPreferences };
  return (
    <UserPreferencesContext.Provider value={value}>
      {children}
    </UserPreferencesContext.Provider>
  );
}

function useUserPreferences() {
  const context = useContext(UserPreferencesContext);

  if (context === undefined) {
    throw new Error('useUserPreferences must be used within a UserContextProvider');
  }

  return context;
}

UserContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export {
  useUserPreferences,
  UserContextProvider,
};
