import actions from './img/actions.JPG';
import table from './img/source_table.JPG';

const SectionSourceTable = () => (
  <div className="SectionSourceTable">
    <p>
      The grid displays all the External Sources available at the system
    </p>
    <p>
      <img src={table} alt="External Source table" />
    </p>
    <p>
      For each row, there is a button in the &quot;Actions&quot; column that makes
      available for the user a series of operations.
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
  </div>
);

export default SectionSourceTable;
