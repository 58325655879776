import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const IsrViewerDetailsTabPanel = ({
  children, value, index, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={index}
    {...other}
  >
    {value === index && (
    <Typography component="span">{children}</Typography>
    )}
  </div>
);
IsrViewerDetailsTabPanel.defaultProps = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};
IsrViewerDetailsTabPanel.propTypes = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};

export default IsrViewerDetailsTabPanel;
