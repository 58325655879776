import {
  any, compose, find, isEmpty, pathEq, prop, propEq,
} from 'ramda';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Col, Container, Dropdown, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DataTable from '@components/DataTable';
import Filters from '@components/Filters';
import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import {
  apiSaveBusinessRule,
  apiToggleRuleActivation,
  apiDownloadTableData,
  apiFetchBusinessRules,
  apiFetchBusinessRulesOptions,
} from '@services/businessRules/api';
import useDomainId from '@services/userPreferences/hooks/useDomainId';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import BusinessRuleEditor from './components/BusinessRuleEditor';

import {
  businessRulesValueOptions,
  configureFilters,
  searchOptions,
  tableConfig,
} from './config';

import { currentISO8601Date } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import {
  Permissions, hasPermission, isDataOrSystemAdmin, isIsrDataAdmin,
} from '@common/keycloakFunctions';
import { requestWithMessage, showErrorNotification } from '@common/utilities/Notification';
import { DOMAIN, formatHeaderSort } from '@common/utils';
import './style.scss';

export const BusinessRulesViewer = () => {
  const { t } = useTranslation();
  const [types, setTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [businessRules, setBusinessRules] = useState([]);
  const [selectedId, setSelectedId] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [initialFilters, setInitialFilters] = useState(null);
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});
  const pageRequest = useRef(1);
  const maxPages = useRef(0);
  const totalResults = useRef(0);
  const downloadRef = useRef();
  const dataTableRef = useRef();
  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;
  const domain = useDomainId();
  const permissions = useSelector((state) => state.authentication.permissions);
  const { keycloak } = useKeyCloak();

  const triggerDataFetch = () => setForceUpdateFlag({});

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };

  const requestWithLoading = useRequestWithLoading();

  const downloadCsv = () => {
    requestWithLoading(async (token) => {
      const query = await apiDownloadTableData(token)(`domain==${domain},${searchFilters && searchFilters}`, 'csv');

      if (query.ok) {
        const csv = await query.text();
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);
        downloadRef.current.href = url;
        downloadRef.current.download = `br_${currentISO8601Date()}.csv`;
        downloadRef.current.click();
        downloadRef.current.href = '/';
      } else {
        console.error("The download didn't succeded", `Error code: ${query.status}`);
        showErrorNotification(t(tSchema.notifications.common.exportCsv));
      }
    }, setLoading);
  };

  const getTypes = () => {
    if (domain !== '') {
      const optionsWithDomain = businessRulesValueOptions(domain);
      requestWithLoading(async (token) => {
        const optionCalls = optionsWithDomain.map(
          apiFetchBusinessRulesOptions(token),
        );
        const options = await Promise.all(optionCalls);

        const allOk = !any(propEq('ok', false))(options);

        if (allOk) {
          const optionsJson = await Promise.all(options.map((x) => x.json()));
          const valueOptions = {};
          optionsJson.forEach((x, i) => {
            const option = optionsWithDomain[i];
            const optionName = typeof option === 'string' ? option : option.option;
            valueOptions[optionName] = x;
          });
          setTypes(valueOptions);
        } else {
          showErrorNotification('An error while loading the filter options has occurred');
        }
      }, setLoading);
    }
  };

  useEffect(() => {
    getTypes();
  }, [domain]);

  useEffect(() => {
    if (types) {
      const defaultFilters = {
        domain,
      };

      const context = types.brcontext;
      if (context && context.length) {
        defaultFilters.context = context[0].id;
      }

      setSearchFilters(null);
      setInitialFilters(defaultFilters);
      setFiltersConfig(configureFilters(types, defaultFilters));
    }
  }, [domain, types]);

  const fetchBusinessRules = useCallback(() => {
    if (domain !== '' && types) {
      const context = types?.brcontext;
      const filtersWithDomain = searchFilters
        ? `domain==${domain},${searchFilters}`
        : `domain==${domain},context==${context[0].id}`;
      requestWithLoading(async () => {
        setSelectedId(null);
        const rules = await apiFetchBusinessRules()(
          pageRequest.current - 1,
          pageSize,
          filtersWithDomain,
          orderBy,
        );

        if (rules.ok) {
          const data = await rules.json();
          totalResults.current = data.totalElements;
          maxPages.current = data.totalPages || 1;
          setBusinessRules(data.content || []);
        } else {
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    }
  }, [domain, types, searchFilters, pageRequest.current, pageSize, orderBy]);

  useEffect(() => {
    fetchBusinessRules();
  }, [
    forceUpdateFlag,
    domain,
    pageSize,
    orderBy,
    types,
    searchFilters,
  ]);

  const getValueFromId = useMemo(() => (option, id) => types && compose(prop('description'), find(propEq('id', id)), prop(option))(types),
    [types]);

  const handleEditRow = useCallback((row) => {
    setEditMode(true);
    setSelectedId(row.id);
  }, []);
  const handleViewRow = useCallback((row) => {
    setEditMode(false);
    setSelectedId(row.id);
  }, []);
  const handleActivateRow = useCallback((row) => {
    setEditMode(false);
    requestWithMessage({
      request: () => apiToggleRuleActivation(row),
      onSuccess: () => {
        fetchBusinessRules();
        setSelectedId(null);
      },
      successMessage: `The rule was ${pathEq(['status', 'id'], 0)(row) ? 'deactivated' : 'activated'}`,
      errorMessage: `There was an error ${pathEq(['status', 'id'], 0)(row) ? 'deactivating' : 'activating'} the rule`,
      setLoading,
    });
  }, [fetchBusinessRules]);
  const actions = useMemo(
    () => [
      {
        label: t(tSchema.common.edit),
        action: handleEditRow,
        disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions,
          Permissions.EDIT_VALIDATION_BR),
      },
      {
        label: t(tSchema.common.view),
        action: handleViewRow,
      },
      {
        label: 'Activate/Deactivate',
        dinamicLabel: (row) => (pathEq(['status', 'id'], 0)(row) ? 'Deactivate' : 'Activate'),
        action: handleActivateRow,
        disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions,
          Permissions.EDIT_VALIDATION_BR),
      },
    ],
    [handleEditRow, handleViewRow, handleActivateRow],
  );
  const tableColumns = useMemo(() => tableConfig(getValueFromId), [getValueFromId]);

  const isDataAdmin = isDataOrSystemAdmin(keycloak);
  const isIsrDataAdminC = isIsrDataAdmin(keycloak);

  const tableHeaderClickHandler = useCallback(
    (header) => {
      const field = header.orderByCode || header.code;
      if (field && !loading) {
        pageRequest.current = 1;
        setOrderBy(formatHeaderSort(header, orderBy));
        resetPagination();
      }
    },
    [loading, pageRequest, orderBy, resetPagination],
  );

  const updateSearchFiltersHandler = useCallback(
    (filters) => {
      if (pageRequest.current > 1) {
        resetPagination();
      }
      setSearchFilters(filters);
    },
    [pageRequest, resetPagination],
  );

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    triggerDataFetch();
  }, []);

  const createBussinesRuleButtonClickHandler = useCallback(() => {
    setEditMode(true);
    setSelectedId(-1);
  }, []);
  const createBussinesRuleButtonDisabled = useMemo(
    () => loading || (selectedId && isEmpty(selectedId)),
    [loading, selectedId],
  );
  const isHidden = useMemo(
    () => {
      switch (domainCode) {
        case DOMAIN.ISR:
          return !isIsrDataAdminC && !isDataAdmin;
        default:
          return !isDataAdmin;
      }
    }, [domainCode, permissions, isDataAdmin],
  );
  const createBussinesRuleButton = useMemo(
    () => (
      <Button
        id="new-business-rule"
        className="symbol-plus"
        onClick={createBussinesRuleButtonClickHandler}
        disabled={createBussinesRuleButtonDisabled}
        hidden={isHidden}
      >
        {t(tSchema.dmaAdmin.brEngine.validationBR.createBR)}
      </Button>
    ),
    [createBussinesRuleButtonClickHandler, createBussinesRuleButtonDisabled, isDataAdmin],
  );

  const exportAsDropdownSelectHandler = useCallback(
    (key) => {
      if (key === 'csv') {
        downloadCsv();
      }
    },
    [downloadCsv],
  );
  const exportAsDropdown = useMemo(
    () => hasPermission(permissions, Permissions.EXPORT_VALIDATION_BR) && (
      <Dropdown onSelect={exportAsDropdownSelectHandler}>
        <Dropdown.Toggle
          id="download-rules"
          variant="primary"
          disabled={createBussinesRuleButtonDisabled}
          placement="top-start"
        >
          {t(tSchema.common.exportAs)}
          <IconSvg name="caretDown" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="csv">{t(tSchema.common.csv)}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
    [exportAsDropdownSelectHandler, createBussinesRuleButtonDisabled],
  );

  const businessRuleEditorSaveHandler = useCallback(
    (rule) => {
      requestWithMessage({
        request: () => apiSaveBusinessRule(rule),
        onSuccess: () => { fetchBusinessRules(); setSelectedId(null); },
        successMessage: 'The rule was succesfully saved',
        errorMessage: 'There was an error saving the rule',
        setLoading,
      });
    },
    [apiSaveBusinessRule, searchFilters, types],
  );
  const businessRuleEditorCancelHandler = useCallback(() => {
    setSelectedId(null);
  }, []);

  const editorTitle = useMemo(() => {
    const editOrViewTitle = editMode ? t(tSchema.common.edit) : t(tSchema.common.view);
    return selectedId && selectedId > 0 ? `${editOrViewTitle}  Business Rule` : 'New Business Rule';
  }, [selectedId, editMode]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && handleViewRow(row), // Double Click
    [handleViewRow],
  );

  return (
    <Container fluid id="businessRulesViewer">
      {domain !== '' ? (
        <>
          <a href="/" ref={downloadRef} hidden>
            Download
          </a>
          <Filters
            filters={filtersConfig}
            searchOptions={searchOptions}
            onUpdateSearchFilters={updateSearchFiltersHandler}
            isUIBlocked={loading}
            initialFilters={initialFilters}
          />
          <Row>
            <Col>
              <DataTable
                actions={actions}
                ref={dataTableRef}
                tableId="businessRules"
                totalResults={totalResults.current}
                tableConfig={tableColumns}
                orderBy={orderBy}
                onTableHeaderClick={tableHeaderClickHandler}
                onRowSelected={handleRowSelected}
                rowData={businessRules}
                defaultPageSize={pageSize}
                onPageSizeChangeHandler={pageSizeChangeHandler}
                onPageChangeHandler={pageChangeHandler}
                loading={loading}
                maxPages={maxPages.current}
                tableActions={(
                  <>
                    {createBussinesRuleButton}
                    {exportAsDropdown}
                  </>
                )}
              />
            </Col>
          </Row>
          <Modal
            backdrop="static"
            show={Number.isInteger(selectedId)}
            onHide={() => setSelectedId(null)}
            size="xl"
          >
            <Modal.Header closeButton className="pb-0">
              <Modal.Title>{editorTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <BusinessRuleEditor
                key={`e${selectedId}${editMode ? 'edit' : 'view'}`}
                isDataAdmin={isDataAdmin}
                ruleId={selectedId}
                edit={editMode}
                onSave={businessRuleEditorSaveHandler}
                onCancel={businessRuleEditorCancelHandler}
                loading={loading}
                options={types}
              />
            </Modal.Body>
          </Modal>
          <br />
        </>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <h3>Must be select a domain.</h3>
        </div>
      )}
    </Container>
  );
};

export default BusinessRulesViewer;
