import {
  Container,
} from 'react-bootstrap';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

import { M1, M3 } from '@pages/IsrViewer/commons/constants';

import tSchema from '@lang/schema';

import IsrViewerDetailsTabAttachments from './components/tabs/attachments';
import IsrViewerDetailsTabCatchProducts from './components/tabs/catchProducts';
import IsrViewerDetailsTabDocuments from './components/tabs/documents';
import IsrViewerDetailsTabGear from './components/tabs/gear';
import IsrViewerDetailsTabInfringements from './components/tabs/infringements';
import IsrViewerDetailsTabInspectedVessel from './components/tabs/inspectedVessel';
import IsrViewerDetailsTabInspectionClosure from './components/tabs/inspectionClosure';
import IsrViewerDetailsTabInspectionMeans from './components/tabs/inspectionMeans';
import IsrViewerDetailsTabPatrolVessel from './components/tabs/patrolVessel';
import {
  CATCH_PRODUCTS,
  DOCUMENTS,
  GEAR,
  INFRINGEMENTS,
  INSPECTED_VESSEL,
  INSPECTION_CLOSURE,
  INSPECTION_MEANS,
  PATROL_VESSEL,
  ATTACHMENTS,
  CURRENT_STATE,
  CORRECTED_STATE,
  DELETED_STATE,
} from './constants';

import { formatDateTime } from '@common/dateUtils';

export function exclamationPicker(level) {
  if (level === 'error') {
    return <ExclamationTriangleFill color="#ff6161" />;
  }
  if (level === 'warning') {
    return <ExclamationTriangleFill color="orange" />;
  }

  return <ExclamationTriangleFill hidden />;
}

export function formatVersions(versions, t) {
  const result = [];
  if (versions) {
    for (let i = 0; i < versions.length; i += 1) {
      let label = '';
      if (versions[i].version === CURRENT_STATE) {
        label += `${t(tSchema.isrViewer.currentVersion)}: `;
      }
      if (versions[i].version === CORRECTED_STATE) {
        label += `${t(tSchema.isrViewer.correctedVersion)}: `;
      }
      if (versions[i].version === DELETED_STATE) {
        label += `${t(tSchema.isrViewer.deletedVersion)}: `;
      }
      label += formatDateTime(versions[i].date.split('+')[0]);
      result.push({
        value: versions[i].uuid,
        label,
      });
    }
  }
  return result;
}

export const switchTabs = (
  tab, module, selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
) => {
  switch (tab.code) {
    case INSPECTION_MEANS:
      return (
        <Container fluid className="inspection_means">
          <IsrViewerDetailsTabInspectionMeans
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case PATROL_VESSEL:
      return (
        <Container fluid className="patrol_vessel">
          <IsrViewerDetailsTabPatrolVessel
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case INSPECTED_VESSEL:
      return (
        <Container fluid className="inspected_vessel">
          <IsrViewerDetailsTabInspectedVessel
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case DOCUMENTS:
      return (
        <Container fluid className="documents">
          <IsrViewerDetailsTabDocuments
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case GEAR:
      return (
        <Container fluid className="gear">
          <IsrViewerDetailsTabGear
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case CATCH_PRODUCTS:
      return (
        <Container fluid className="catch_products">
          <IsrViewerDetailsTabCatchProducts
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case INFRINGEMENTS:
      return (
        <Container fluid className="infringements">
          <IsrViewerDetailsTabInfringements
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case INSPECTION_CLOSURE:
      return (
        <Container fluid className="inspection_closure">
          <IsrViewerDetailsTabInspectionClosure
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    case ATTACHMENTS:
      return (
        <Container fluid className="attachments">
          <IsrViewerDetailsTabAttachments
            module={module}
            selectedUuid={selectedUuid}
            selectedVersion={selectedVersion}
            selectedRecipient={selectedRecipient}
            isHiddenISRTab={isHiddenISRTab}
          />
        </Container>
      );
    default:
      return null;
  }
};

export function getTabs(module, t) {
  let result = [];
  if (module === M1) {
    result = [
      { code: PATROL_VESSEL, label: t(tSchema.isrViewer.patrolVessel) },
      { code: INSPECTED_VESSEL, label: t(tSchema.isrViewer.inspectedVessel) },
      { code: DOCUMENTS, label: t(tSchema.isrViewer.documentsAuthorizations) },
      { code: GEAR, label: t(tSchema.isrViewer.gear) },
      { code: CATCH_PRODUCTS, label: t(tSchema.isrViewer.catchProducts) },
      { code: INFRINGEMENTS, label: t(tSchema.isrViewer.infringements) },
      { code: INSPECTION_CLOSURE, label: t(tSchema.isrViewer.inspectionClosure) },
      { code: ATTACHMENTS, label: t(tSchema.isrViewer.attachments) },
    ];
  }
  if (module === M3) {
    result = [
      { code: INSPECTION_MEANS, label: t(tSchema.isrViewer.inspectionMeans) },
      { code: INSPECTED_VESSEL, label: t(tSchema.isrViewer.inspectedVessel) },
      { code: DOCUMENTS, label: t(tSchema.isrViewer.documentsAuthorizations) },
      { code: GEAR, label: t(tSchema.isrViewer.gear) },
      { code: CATCH_PRODUCTS, label: t(tSchema.isrViewer.catchProducts) },
      { code: INFRINGEMENTS, label: t(tSchema.isrViewer.infringements) },
      { code: INSPECTION_CLOSURE, label: t(tSchema.isrViewer.inspectionClosure) },
      { code: ATTACHMENTS, label: t(tSchema.isrViewer.attachments) },
    ];
  }
  return result;
}
