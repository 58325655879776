import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';

import tSchema from '../../lang/schema';

import './style.scss';

/**
 * A component to show the breadcrumbs based on the navigator route
 */
const UserManualModal = ({ onClose, showModal, section = {} }) => {
  const { t } = useTranslation();
  const { content, title, description } = section;
  return (
    <Modal
      backdrop="static"
      id="user-manual-modal"
      show={showModal}
      onHide={onClose}
      size="xl"
      scrollable
    >
      <Modal.Header closeButton>
        <IconSvg name={ICON.HELP} />
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="description">
          {description}
        </div>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="symbol-cross"
          onClick={onClose}
        >
          {t(tSchema.common.close)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UserManualModal.defaultProps = {
  showModal: false,

};

UserManualModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
  }).isRequired,
};

export default UserManualModal;
