import { Actions } from '../../actions/administration';

const changeAttributeInformation = (state, { payload }) => {
  const attributeSelected = { ...state.attributeSelected };
  if (payload.name === 'tab') {
    attributeSelected.customProperties.tab = payload.value;
  } else {
    attributeSelected[payload.name] = payload.value;
  }

  return {
    ...state,
    attributeSelected,
  };
};

const getTabs = (state) => {
  const tabs = [];
  state.attributes?.forEach((x) => {
    x.customProperties?.filter((y) => y.name === 'tab')
      .forEach((z) => {
        if (tabs?.filter((w) => w === z.value).length === 0) {
          tabs.push(z.value);
        }
      });
  });
  return {
    ...state,
    tabs,
  };
};

const cleanForm = (state) => ({
  ...state,
  isValidForm: false,
  inputFieldsValidate: { name: { valid: true, error: '' }, displayName: { valid: true, error: '' } },
  attributeOriginal: {},
  attributeSelected: {
    customProperties: {},
    description: '',
    displayName: '',
    name: '',
    rights: {
      createdAt: '',
      updatedAt: '',
    },
    searchable: false,
    simpleDataType: '',
    unique: false,

  },

});
const validateForm = (state, payload) => {
  const attributeSelected = { ...state.attributeSelected };
  let attributes = [...state.attributes];
  const inputFieldsValidate = { ...state.inputFieldsValidate };
  let isValidForm = true;
  const attributeOriginal = { ...state.attributeOriginal };

  if (payload.visualization === 'CREATE') {
    attributes.push(attributeSelected);
  } else {
    const attributesCopy = [...attributes];
    attributes = [];
    attributesCopy.forEach((x) => {
      if (x.name === attributeOriginal.name) {
        attributes.push(attributeSelected);
      } else {
        attributes.push(x);
      }
    });
  }

  if (attributes.filter((x) => x.name === attributeSelected.name).length > 1) {
    inputFieldsValidate.name.valid = false;
    inputFieldsValidate.name.error = 'There is another attribute with the same name';
    isValidForm = false;
  }
  if (attributeSelected.name === '') {
    inputFieldsValidate.name.valid = false;
    inputFieldsValidate.name.error = 'Is empty';
    isValidForm = false;
  }
  if (attributeSelected.displayName === '') {
    inputFieldsValidate.displayName.valid = false;
    inputFieldsValidate.displayName.error = 'Is empty';
    isValidForm = false;
  }

  return {
    ...state,
    isValidForm,
    inputFieldsValidate,

  };
};

const setIsValidAttributeField = (state, { payload }) => {
  const inputFieldsValidate = { ...state.inputFieldsValidate };
  inputFieldsValidate[payload.field].valid = payload.value;
  inputFieldsValidate[payload.field].error = '';
  return {
    ...state,
    inputFieldsValidate,

  };
};

const openForm = (state, payload) => {
  let { modalShow } = state;
  modalShow = payload.value;
  return {
    ...state,
    modalShow,
  };
};
const Form = {
  [Actions.CHANGE_ATTRIBUTE_INFORMATION]: changeAttributeInformation,
  [Actions.GET_TABS]: getTabs,
  [Actions.CLEAN_ATTRIBUTE_FORM]: cleanForm,
  [Actions.VALIDATE_FORM]: validateForm,
  [Actions.SET_IS_VALID_FIELD]: setIsValidAttributeField,
  [Actions.OPEN_FORM]: openForm,
};

export default Form;
