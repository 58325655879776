import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { userLogout } from '@services/authentication/actions';

const Logout = ({ userLogout: userLogoutFn }) => {
  useEffect(() => {
    userLogoutFn();
  });

  return <Redirect to="/home" />;
};

Logout.propTypes = {
  userLogout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  userLogout,
};

export default connect(null, mapDispatchToProps)(Logout);
