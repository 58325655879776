export function apiFetchService() {
  return (service) => fetch(
    `${window.env.REACT_APP_NODE_SERVER_URL}/api/consul/health/${service}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('keycloak_token')}`,
      },
    },
  );
}

export default {
  apiFetchService,
};
