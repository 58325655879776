import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Row, Button } from 'react-bootstrap';

import IconSvg from '@components/IconSvg';

import MultiSelectValues from '../../../MultiSelectValues';
import FieldCheckBoxWrapper from '../FieldCheckBoxWrapper/FieldCheckBoxWrapper';

import './style.scss';

const InputAdd = ({
  label,
  placeholder,
  name,
  nameCheckbox,
  onChange: handleChange,
  value,
  error,
  setFieldValue,
  arrayAddedName,
  arrayAddedValues,
  classNameControl,
  checked,
  onFieldChecked: handleFieldChecked,
}) => {
  const handleClickAddButton = useCallback(() => {
    if (!error && value !== '') {
      setFieldValue(name, '');
      setFieldValue(arrayAddedName, [...arrayAddedValues, value]);
    }
  }, [name, error, value, arrayAddedName, setFieldValue]);

  const handleCloseSelection = useCallback((index) => {
    const newArrayAddedValues = [...arrayAddedValues];
    newArrayAddedValues.splice(index, 1);
    setFieldValue(arrayAddedName, newArrayAddedValues);
  }, [arrayAddedValues, arrayAddedName, setFieldValue]);

  return (
    <FieldCheckBoxWrapper
      checked={checked}
      name={nameCheckbox}
      onFieldChecked={handleFieldChecked}
    >
      <Form.Group as={Row} className={`input-add-form d-flex align-items-center justify-content-between m-0 flex-grow-1 ${arrayAddedValues.length === 0 && 'my-2'}`} controlId={name}>
        <Form.Label className="m-0">{label}</Form.Label>
        <Row className="mx-0">
          <Form.Control
            type="text"
            placeholder={placeholder}
            name={name}
            className={classNameControl}
            onChange={handleChange}
            value={value}
            isInvalid={!!error}
          />
          <Button
            className="add-button d-flex align-items-center justify-content-center"
            onClick={handleClickAddButton}
          >
            <IconSvg name="add" className="add-icon" />
          </Button>
        </Row>
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </Form.Group>
      {
      arrayAddedValues.length > 0 && (
        <Row className="d-flex m-0 mt-1 mb-2 align-items-center multi-select-values">
          <MultiSelectValues
            values={arrayAddedValues}
            onRemoveSelection={handleCloseSelection}
          />
        </Row>
      )
      }
    </FieldCheckBoxWrapper>
  );
};

InputAdd.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  nameCheckbox: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  arrayAddedName: PropTypes.string.isRequired,
  arrayAddedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  classNameControl: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onFieldChecked: PropTypes.func.isRequired,
};

InputAdd.defaultProps = {
  placeholder: '',
  error: null,
  checked: undefined,
};

export default InputAdd;
