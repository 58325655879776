import adAsFavouritePng from './img/add_as_favourite.png';
import addToSearchPng from './img/add_to_search.png';
import favouriteNamePng from './img/favourite_name.png';
import removeConfirmationPng from './img/remove_confirmation.png';
import removeFromFavouritesPng from './img/remove_from_favourites.png';

export const FAVOURITES = (
  <div className="subSection">
    <p>
      Once you have selected a set of filters,
      you can save them as a favourite by clicking on &quot;Add to Favourite&quot; button.
    </p>
    <p>
      <img
        src={adAsFavouritePng}
        alt="Add as favourite"
        className="img-fluid"
      />
    </p>
    <p>
      A modal prompts the user to enter a name for the favourite.
    </p>
    <p>
      <img
        src={favouriteNamePng}
        alt="Favourite name"
        className="img-fluid"
      />
    </p>
    <p>
      Once user has entered a name, it can be saved the favourite
      by clicking on &quot;Save&quot; button.
    </p>
    <p>
      Click on the &quot;Favourite&quot; tab to see all your favourites.
    </p>
    <p>
      For every favourite, it is displayed an &quot;Add to search&quot; button.
      If pressed, the filters currently enabled in the &quot;Search&quot; section,
      are replaced by the set of filters defined in the favourite.
    </p>
    <p>
      <img
        src={addToSearchPng}
        alt="Add to search"
        className="img-fluid"
      />
    </p>
    <p>
      Additionally, a favourite can be removed by clicking on &quot;Remove&quot; button.
    </p>
    <p>
      <img
        src={removeFromFavouritesPng}
        alt="Remove from favourites"
        className="img-fluid"
      />
    </p>
    <p>
      A confirmation modal asks to confirm the action.
      The remove action is irreversible.
    </p>
    <p>
      <img
        src={removeConfirmationPng}
        alt="Remove confirmation"
        className="img-fluid"
      />
    </p>
  </div>
);

export default FAVOURITES;
