import PropTypes from 'prop-types';
import { map } from 'ramda';
import React from 'react';
import {
  Accordion, Button, Card,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import BasicTable from '@pages/FishingTripsLogbook/components/ReportDetailsSection/components/tables/BasicTable';

import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';

const ReportCard = ({
  reports = [], index = 0, tTitle = '', icon = ICON.CHECKED,
}) => {
  const { t } = useTranslation();
  const mappedReports = reports.map(({ row: Row, ...e }) => (
    {
      // remove the values of type object (can not be rendered in the table)
      ...map(
        (prop) => (prop && typeof prop === 'object' ? '-'
          : prop),
      )(e),
      ...(Row && { Row }),
    }
  ));
  // object with all array objects properties
  const headerObj = {};
  reports.length && Object.assign(headerObj, ...mappedReports);
  return reports && (
  <Card>
    <Card.Header>
      <Accordion.Toggle
        className="accordion-toggle"
        as={Button}
        disabled={!reports.length}
        variant="link"
        eventKey={`${index}`}
      >
        <IconSvg name={icon} className="mr-2" />
        {t(tTitle, {
          n: reports?.length || 0,
        })}
      </Accordion.Toggle>
    </Card.Header>
    {Boolean(reports.length) && (
    <Accordion.Collapse eventKey={`${index}`}>
      <Card.Body>
        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
          <BasicTable
            headers={Object.keys(headerObj).map((key) => ({
              text: key,
              code: key,
              sortable: true,
            })).sort((a) => (a.code === 'Row' ? -1 : 0))}
            data={mappedReports}
            idColumn="Code"
          />
        </div>
      </Card.Body>
    </Accordion.Collapse>
    )}
  </Card>
  );
};

ReportCard.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.number.isRequired,
  tTitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

const UploadReport = ({
  reports, error,
}) => {
  const { t } = useTranslation();
  return error || !reports
    ? (
      <div
        className="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconSvg name={ICON.DANGER_TRIANGLE} />
        <div className="label" style={{ fontSize: '1.188rem' }}>{t(error)}</div>
      </div>
    )
    : (
      reports && (
        <Accordion>
          {reports.map((r, index) => (
            <ReportCard
              index={index}
              reports={r.data}
              icon={r.icon}
              tTitle={r.title}
            />
          ))}
        </Accordion>
      )
    );
};

UploadReport.defaultProps = {
  error: false,
};
UploadReport.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape(
    {
      data: PropTypes.arrayOf(PropTypes.string).isRequired,
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    },
  )).isRequired,
  error: PropTypes.string,
};

export default UploadReport;
