import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import { apiDeleteVesselFromGroup, apiFetchVesselsFromVesselGroup } from '@services/vessels/api';

import { getTableConfig } from '../../config';

import { useRequestWithLoading } from '@common/hooks/request';
import { formatHeaderSort } from '@common/utils';

const TableSummary = ({
  setVesselLoading,
  isEdit,
  values,
  isLoading,
  onFetchVessels: handleFetchVessels,
  vesselId,
}) => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const [orderBy, setOrderBy] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const dataTableRef = useRef();

  const requestWithLoading = useRequestWithLoading();

  const getGroupVessels = async (pageRequest, sizeRequest) => requestWithLoading(async () => {
    const { field, order } = orderBy || {};
    const vesselsFromGroup = await apiFetchVesselsFromVesselGroup(
      vesselId, {
        page: pageRequest - 1, size: sizeRequest, sort: [field, order && order.toUpperCase()].filter(Boolean).join(','),
      },
    );
    if (vesselsFromGroup.ok) {
      const vesselsArray = await vesselsFromGroup.json();
      handleFetchVessels(vesselsArray.content);
      setTotalPages(vesselsArray.totalPages);
      setTotalElements(vesselsArray.totalElements);
    }
  }, setVesselLoading);

  useEffect(() => {
    getGroupVessels(page, pageSize);
  }, [page, pageSize, orderBy]);

  const handleChangePageSize = useCallback((size) => {
    setPageSize(size);
    setPage(1);
  }, []);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleDeleteRow = useCallback(
    async (vessel) => {
      if (values.vessels.length !== 1) {
        const deleteVessel = await apiDeleteVesselFromGroup(values.id, vessel.etalon_id);
        if (deleteVessel.ok) {
          getGroupVessels(page, pageSize);
        }
      } else {
        const deleteVessel = await apiDeleteVesselFromGroup(values.id, vessel.etalon_id);
        if (deleteVessel.ok) {
          setPage((currentPage) => currentPage - 1);
        }
      }
    },
    [values, page, pageSize],
  );

  const actions = useMemo(() => [
    {
      label: t(tSchema.common.delete),
      action: handleDeleteRow,
    },
  ], [handleDeleteRow]);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    setPage(1);
  };
  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code) {
      setPage(1);
      setOrderBy(formatHeaderSort(header, orderBy));
      resetPagination();
    }
  }, [page, orderBy]);

  return (
    <DataTable
      tableId="groupVessels"
      ref={dataTableRef}
      tableConfig={tableConfig}
      orderBy={orderBy}
      rowData={values.vessels}
      onPageSizeChangeHandler={handleChangePageSize}
      onPageChangeHandler={handleChangePage}
      loading={isLoading}
      maxPages={totalPages}
      pageSize={pageSize}
      totalResults={totalElements}
      onTableHeaderClick={tableHeaderClickHandler}
      actions={isEdit && actions}
      tableActions={false}
      error={false}
      errorMessageEmptyArray="No vessels found"
      errorMessageLoadingData="An error has occurred loading vessels summary"
    />
  );
};

TableSummary.propTypes = {
  setVesselLoading: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    id: PropTypes.number,
    vessels: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFetchVessels: PropTypes.func.isRequired,
  vesselId: PropTypes.number.isRequired,
};

export default TableSummary;
