import SectionScenariosCreate from './SectionScenariosCreate';
import SectionScenariosEdit from './SectionScenariosEdit';
import SectionScenariosTable from './SectionScenariosTable';
import SectionUsersTable from './SectionUsersTable';

const SCENARIOS = {
  SCENARIOS_TABLE: {
    title: 'Scenarios Grid',
    body: <SectionScenariosTable />,
  },
  CREATE_SCENARIOS: {
    title: 'Create Scenario',
    body: <SectionScenariosCreate />,
  },
  USERS_TABLE: {
    title: 'Users Grid',
    body: <SectionUsersTable />,
  },
  EDIT_SCENARIOS: {
    title: 'Edit Scenario',
    body: <SectionScenariosEdit />,
  },
};

export default SCENARIOS;
