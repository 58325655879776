import {
  TypeActionsCrud,
  fetchAllReferenceData,
  fetchReferenceAttributesById,
  updateReferenceData,
  setPagination,
  setFilter,
  createReferenceAttribute,
  deleteReferenceAttribute,
  updateReferenceAttribute,
  clearReferenceAttributes,
  uploadReferenceAttributes,
  cleanUploadReferenceReports,
} from './crud';

const Actions = {
  ...TypeActionsCrud,
};

export {
  Actions,
  // CRUD
  fetchAllReferenceData,
  fetchReferenceAttributesById,
  updateReferenceData,
  setPagination,
  setFilter,
  createReferenceAttribute,
  deleteReferenceAttribute,
  updateReferenceAttribute,
  clearReferenceAttributes,
  uploadReferenceAttributes,
  cleanUploadReferenceReports,
};
