import { getTableConfig } from '../../config';
import TableCheckColumn from '../TableCheckColumn';

const tableCheckConfig = ({
  vessels, selectedRows, onSelectedRows: handleSelectedRows, checkAdded, t,
}) => [
  TableCheckColumn({
    vessels,
    selectedRows,
    onSelectedRows: handleSelectedRows,
    checkAdded,
  }),
  ...getTableConfig(t)];

export default tableCheckConfig;
