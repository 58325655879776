import tSchema from '@lang/schema';

import { flagStyle } from './VesselHistoricModal.module.scss';

import { formatDate } from '@common/dateUtils';

const getCountryISO2 = require('country-iso-3-to-2');

const schema = tSchema.vesselMGMT.historical.table;

const getTableConfig = (t) => [
  {
    text: t(schema.flagState),
    code: 'countryOfRegistration',
    sortable: true,
    render: (row) => (
      <>
        <img
          className={flagStyle}
          src={`${process.env.PUBLIC_URL}/assets/flags/${getCountryISO2(row.countryOfRegistration)?.toLowerCase()}.svg`}
          alt={`${getCountryISO2(row.countryOfRegistration)} flag`}
        />
        {' '}
        {row.countryOfRegistration}
      </>
    )
    ,
  },
  {
    text: t(schema.reportEvent),
    code: 'reportedEvent',
    sortable: true,
  },
  {
    text: t(schema.validityDate),
    code: 'validityDate',
    sortable: true,
    render: (row) => formatDate(row.validityDate, undefined, 'Indefinite', true),
  },
  {
    text: t(schema.source),
    code: 'source',
    sortable: true,
  },
  {
    text: t(schema.vesselType),
    code: 'vesselType',
    sortable: true,
  },
  {
    text: t(schema.ircs),
    code: 'ircs',
    sortable: true,
  },
  {
    text: t(schema.externalMarking),
    code: 'externalMarking',
    sortable: true,
  },
  {
    text: t(schema.name),
    code: 'name',
    sortable: true,
  },
  {
    text: t(schema.transactionDate),
    code: 'transactionDate',
    sortable: true,
    render: (row) => formatDate(row.transactionDate, undefined, 'Indefinite', true),
  },
];

export default getTableConfig;
