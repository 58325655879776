import { Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import { M1 } from '@pages/IsrViewer/commons/constants';

import tSchema from '@lang/schema';

import {
  getReportTabs,
} from '@services/isr/reports/api';

import IsrViewerDetailsHeader from './components/header';
import IsrViewerDetailsTabPanel from './components/tabPanel/IsrViewerDetailsTabPanel';
import {
  exclamationPicker,
  switchTabs,
  getTabs,
  formatVersions,
} from './utils';

import './style.scss';

import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerDetails = ({
  selectedUuid,
  selectedRecipient,
  module,
  setSelectedTab,
  selectedTab,
  isHiddenISRTab,
}) => {
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const { t } = useTranslation();
  const title = module === M1 ? t(tSchema.isrViewer.m1Title) : t(tSchema.isrViewer.m3Title);
  const currentTabs = getTabs(module, t);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [versionData, setVersionData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchVersionTab = useCallback(async (uuid, recipient) => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        uuid,
        recipient,
        ['versionTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        const versionsArray = formatVersions(data.versionTab, t);
        setVersionData(versionsArray);
        setSelectedVersion(versionsArray[0]);
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient) {
      fetchVersionTab(selectedUuid, selectedRecipient);
    }
  }, [
    selectedUuid,
    selectedRecipient,
  ]);

  const selectVersionHandler = (option) => {
    setSelectedVersion(option);
  };

  return (
    <Container
      className="isr-viewer-modal"
      fluid
    >
      <div closeButton>
        {loading ? (
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
          </div>
        )
          : (
            <div>
              <h4 style={{
                display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: '20px', marginBottom: '20px',
              }}
              >
                <Col sm={8}>
                  <span className="mr-1" style={{ color: '#003882', fontWeight: 'bold' }}>
                    {title}
                  </span>
                </Col>
                {versionData?.length !== 1
                && (
                  <Col sm={3}>
                    <ReactSelect
                      options={versionData}
                      name="version"
                      className="version-select multi-choice-select form-control-sm h-100 m-0 p-0"
                      value={selectedVersion}
                      onChange={selectVersionHandler}
                      isDisabled={false}
                    />
                  </Col>
                )}
              </h4>
            </div>
          )}
      </div>
      <div>
        <IsrViewerDetailsHeader
          key={`${selectedUuid}-${selectedRecipient}`}
          module={module}
          selectedUuid={selectedUuid}
          selectedVersion={selectedVersion}
          selectedRecipient={selectedRecipient}
        />
        <Tabs
          className="isrReportsTabs"
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {currentTabs && currentTabs?.map((r) => (
            <Tab label={(
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textTransform: 'none',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
              >
                <span>{r.label}</span>
                {exclamationPicker('WARN')}
              </div>
            )}
            />
          ))}
        </Tabs>
        {selectedTab != null && currentTabs.map((tab, indexTab) => (
          <IsrViewerDetailsTabPanel value={selectedTab} index={indexTab}>
            {switchTabs( // Quizas sea necesario hacer un cambio
              tab, module, selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
            )}
          </IsrViewerDetailsTabPanel>
        ))}
      </div>
    </Container>
  );
};

IsrViewerDetails.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  module: PropTypes.instanceOf(Object).isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetails;
