import actions from './img/actions.JPG';
import view from './img/modal_view_query.JPG';

const SectionViewQuery = () => (
  <div className="SectionViewAcceptance">
    <p>
      To visualize the details of a query,
      in the results grid the user has to select &quot;View&quot; in the &quot;Actions&quot;
      button for the corresponding query.
      Alternatively, the user can double click on the query in the grid.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal window with the information is opened, with all the fields disabled.
    </p>
    <p>
      <img src={view} alt="View Acceptance" />
    </p>
  </div>
);

export default SectionViewQuery;
