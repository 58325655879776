/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';
import validationBRViewModalImg from './img/validation-br-view-modal.png';

const { t } = i18next;

const SectionValidationBRView = () => (
  <div className="subSection">
    <p>
      To visualize full information of a business rule,
      the user must click on the &quot;Actions&quot; button
      corresponding to the row of that specific business rule,
      and then click on &quot;
      {t(tSchema.common.view)}
      &quot; button.
    </p>
    <p>
      A modal displays the information related to the selected business rule, in read-only format:
    </p>
    <p>
      <img src={validationBRViewModalImg} alt="validationBRViewModal" />
    </p>
  </div>
);

export default SectionValidationBRView;
