/* eslint-disable import/order */

import PropTypes from 'prop-types';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import {
  Button, Dropdown, Form, Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearEventTargetValue } from '@common/utils';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import IconSvg from '@components/IconSvg';
import tSchema from '@lang/schema';
import BasicTable from '@pages/FishingTripsLogbook/components/ReportDetailsSection/components/tables/BasicTable';
import { renderReferenceDataRow } from '@pages/ReferenceViewer/components/utils';
import {
  deleteReferenceAttribute,
  fetchReferenceAttributesById,
  updateReferenceAttribute,
} from '@services/vessel_record/actions/referenceData';

import * as attributesTypes from '../utils';

import ModalAddEditReferenceAttribute from './modals/ModalAddEditReferenceAttribute';

import './style.scss';

export const REFERENCE_DATA_TYPE = {
  XFA: 'XFA',
  MDR: 'MDR',
};

const ModalGridReferencesAttributes = ({
  columns,
  columnsReferenceAttributes,
  edit,
  index,
  onChangeReferenceDataInfo,
  onChangeFile,
  showModalAddNewLine,
  onSave,
  closeModalGrid,
  referenceData,
  referenceDataInfo,
  show,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const referenceAttributes = useSelector((state) => state.referenceData.referenceAttributes);
  const isLoading = useSelector((state) => state.referenceData.isLoading);
  const [editRowReferenceData, setEditRowReferenceData] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditReferenceData, setIsEditReferenceData] = useState(false);
  const inputOpenFileRef = React.createRef();
  const isCompletedUpdate = useSelector((state) => state.referenceData.completedUpdateAttributes);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (isCompletedUpdate) {
      dispatch(fetchReferenceAttributesById(referenceData[index].code));
      showDeleteModal && setShowDeleteModal(false);
      isEditReferenceData && setIsEditReferenceData(false);
    }
  }, [isCompletedUpdate]);

  useEffect(() => {
    setPageSize(10);
  }, [referenceAttributes, pageSize]);

  const editRow = (row) => {
    setIsEditReferenceData(true);
    setEditRowReferenceData(row);
    document.body.click();
  };
  const deleteRow = (row) => {
    setShowDeleteModal(true);
    setEditRowReferenceData(row);
    document.body.click();
  };

  const actions = useMemo(() => [
    { label: t(tSchema.common.edit), action: editRow },
    { label: t(tSchema.common.delete), action: deleteRow },
  ], [editRow, deleteRow]);

  const onConfirm = useCallback(() => {
    dispatch(deleteReferenceAttribute(referenceData[index].code, editRowReferenceData.code));
  }, [editRowReferenceData]);

  const onCloseConfirm = useCallback(() => {
    setShowDeleteModal(false);
    setEditRowReferenceData({});
  }, []);

  const tableDynamicConfig = useCallback((headerColumns) => headerColumns.map(
    (header) => ({ code: header.code, text: header.name, sortable: true }),
  ).filter((header) => header.code !== 'actions'), []);

  const onSelectFile = useCallback((key) => {
    if (key === 'csv') {
      inputOpenFileRef.current.accept = '.csv';
      inputOpenFileRef.current.click();
    } else if (key === 'excel') {
      inputOpenFileRef.current.accept = '.xls,.xlsx';
      inputOpenFileRef.current.click();
    }
  }, [inputOpenFileRef]);

  const onCloseModal = useCallback(() => {
    closeModalGrid();
  }, [closeModalGrid]);

  const handleCloseModalAddEditReferenceAttribute = useCallback(() => {
    setIsEditReferenceData(false);
  }, []);

  const handleSubmitFormModalAddEditReferenceAttribute = useCallback((values) => {
    dispatch(updateReferenceAttribute(referenceData[index]?.code, values));
  }, [referenceData, dispatch, index]);

  const referenceDataIndex = useMemo(() => referenceData[index] || {}, [referenceData, index]);

  const headers = useMemo(() => tableDynamicConfig(columns), [columns, tableDynamicConfig]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onCloseModal}
      centered
      size="xxl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {referenceDataIndex.description}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="reference-modal-table-row">
        {edit
          ? (
            <div className="pb-0" style={{ flexDirection: 'column' }}>
              <Form.Group className="input-half">
                <Form.Label className="label-modal">
                  Name
                </Form.Label>
                <div className="input-text">
                  <Form.Control
                    className="input-text"
                    id="name"
                    defaultValue={referenceDataIndex.name}
                    readOnly
                  />
                </div>
              </Form.Group>
              <Form.Group className="input-full">
                <Form.Label className="label-modal">
                  Description
                </Form.Label>
                <div className="input-text">
                  <Form.Control
                    id="description"
                    value={referenceDataInfo.description}
                    onChange={onChangeReferenceDataInfo}
                    readOnly={!edit}
                  />
                </div>
              </Form.Group>
            </div>
          )
          : (
            <div className="pb-0" style={{ flexDirection: 'column' }}>
              <b className="reference-title">
                { referenceDataIndex.name}
              </b>
              <div>{referenceDataInfo.description}</div>
            </div>
          )}
        <BasicTable
          tableId="dynamic-table-reference-data"
          data={referenceAttributes || []}
          headers={headers}
          pageSize={pageSize}
          actions={edit ? actions : null}
          rowRender={renderReferenceDataRow}
          loading={isLoading}
          autoFilters
        />
        {isEditReferenceData && (
          <ModalAddEditReferenceAttribute
            formFields={columnsReferenceAttributes}
            formValues={editRowReferenceData}
            isEdit
            isLoading={isLoading}
            onClose={handleCloseModalAddEditReferenceAttribute}
            onSubmitForm={handleSubmitFormModalAddEditReferenceAttribute}
            showModal={isEditReferenceData}
            title={`${referenceDataIndex.description}`}
          />
        )}
        {showDeleteModal && (
          <ConfirmationModal
            isOpen={showDeleteModal}
            onHide={onCloseConfirm}
            title="Delete reference attribute"
            message="Are you sure you want to delete this reference attribute?"
            onConfirm={onConfirm}
            onCancel={onCloseConfirm}
            cancelButtonText={t(tSchema.common.cancel)}
            confirmButtonText={t(tSchema.common.delete)}
          />
        )}
        <div className="row row-form-text text-modal save-close-row">
          {edit && (
            <>
              <Form.Control
                type="file"
                ref={inputOpenFileRef}
                style={{ display: 'none' }}
                onChange={onChangeFile}
                // Allow to select the same file twice
                onClick={clearEventTargetValue}
              />
              <Dropdown style={{ position: 'absolute', left: '1rem' }} onSelect={onSelectFile}>
                <Dropdown.Toggle id="download-rules" variant="primary" placement="top-start">
                  Import from
                  <IconSvg name="caretDown" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="csv">{t(tSchema.common.csv)}</Dropdown.Item>
                  <Dropdown.Item eventKey="excel">{t(tSchema.common.excel)}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button onClick={showModalAddNewLine} style={{ marginRight: '0.188rem' }}>
                Add new line
              </Button>
            </>
          )}
          {edit && (referenceDataIndex.name !== referenceDataInfo.name
              || referenceDataIndex.description !== referenceDataInfo.description)
            && (
              <Button style={{ marginRight: '0.188rem' }} onClick={onSave}>
                {t(tSchema.common.save)}
              </Button>
            )}

          <Button onClick={onCloseModal}>
            {t(tSchema.common.close)}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalGridReferencesAttributes.propTypes = {
  show: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  referenceData: PropTypes.shape({}).isRequired,
  referenceDataInfo: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    list: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.booleanTypeProptype)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.baseType)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.gearTypeProptype)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.territoryTypes)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.vesselTypes)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.vesselEventTypes)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.vesselSegmentTypes)),
      PropTypes.arrayOf(PropTypes.shape(attributesTypes.vesselPortTypes)),
    ]),
  }).isRequired,
  onChangeReferenceDataInfo: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnsReferenceAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeFile: PropTypes.func.isRequired,
  showModalAddNewLine: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModalGrid: PropTypes.func.isRequired,
};

export default ModalGridReferencesAttributes;
