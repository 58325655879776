/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { isNilOrEmpty } from '@common/utils';
import DataTable from '@components/DataTable';
import RowTable from '@components/DataTable/components/RowTable';
import PropTypes from 'prop-types';
import React, {
  useMemo, useRef, useCallback,
} from 'react';

import { REPORT_VERSIONS_STATE } from './constants';

import { tableConfig } from './config';

import './style.scss';
import { isReportCancelled, isReportDeleted, isReportEdited } from '@pages/FishingTripsLogbook/common/utils';

const renderReportVersionSymbol = (version, purposeVersion) => {
  /* eslint-disable */
  if (isReportCancelled({version, purposeVersion})) {
    return '(C) ';
  } else if (isReportDeleted({version, purposeVersion})) {
    return '(D) ';
  } else if (isReportEdited({version, purposeVersion})) {
    return '(*) ';
  } else {
    return '';
  }
  /* eslint-enable */
};

const TripReportsTable = (
  {
    tripId,
    tripReportsData,
    loading,
    onViewReportDetails,
    onViewMessage: handleViewMessage,
    totalResults,
    totalPages,
    ...rest
  },
) => {
  const tripReportsTableRef = useRef();

  // TODO this functionality needs to be defined by client
  const handleRelatedFishingTrips = useCallback(
    () => null, [],
  );
  // TODO this functionality needs to be defined by client
  const relatedFishingTripsConditionallyDisabled = useCallback(
    () => true, [],
  );
  const handleViewReportDetails = useCallback(
    (row) => onViewReportDetails(row?.fishingTripId, row?.faType, row?.reportType),
    [onViewReportDetails],
  );
  const viewReportDetailsConditionallyDisabled = useCallback(
    (row) => isNilOrEmpty(row?.fishingTripId), [],
  );

  const actions = useMemo(() => [
    { label: 'View Catch Composition', action: handleRelatedFishingTrips, conditionallyDisabled: relatedFishingTripsConditionallyDisabled },
    { label: 'View Report Details', action: handleViewReportDetails, conditionallyDisabled: viewReportDetailsConditionallyDisabled },
    { label: 'View Message', action: handleViewMessage },
  ], [
    handleRelatedFishingTrips,
    relatedFishingTripsConditionallyDisabled,
    handleViewReportDetails,
    viewReportDetailsConditionallyDisabled,
    handleViewMessage,
  ]);

  const handleRowSelected = useCallback(
    (row, event) => {
      // Double click
      event.detail === 2
       && !viewReportDetailsConditionallyDisabled(row) && handleViewReportDetails(row);
    },
    [handleViewReportDetails, viewReportDetailsConditionallyDisabled],
  );

  return (
    <DataTable
      onRowSelected={handleRowSelected}
      actions={actions}
      ref={tripReportsTableRef}
      tableId="trip-reports-table"
      totalResults={totalResults}
      tableConfig={tableConfig}
      rowData={tripReportsData}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      loading={loading}
      maxPages={totalPages || 1}
      showCheckbox
      rowRender={({
        row,
        id,
        cols,
        selectedId,
      }) => (
        <RowTable
          key={id}
          rowData={row}
          tableColumns={cols}
          isHoverRow={selectedId === id}
          onRowSelected={handleRowSelected}
          showCancelledDeleted
          isTripReports
          versionSymbol={renderReportVersionSymbol(row.version)}
          isCancelledDeleted={row.version === REPORT_VERSIONS_STATE.CANCELLED
                          || row.version === REPORT_VERSIONS_STATE.DELETED}
        />
      )}
      {...rest}
    />
  );
};

TripReportsTable.propTypes = {
  tripId: PropTypes.string.isRequired,
  tripReportsData: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  loading: PropTypes.bool,
  onViewReportDetails: PropTypes.func.isRequired,
  onViewMessage: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

TripReportsTable.defaultProps = {
  loading: undefined,
};

export default TripReportsTable;
