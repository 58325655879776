import tSchema from '@lang/schema';

export const businessRulesValueOptions = [
  { code: 'domain', name: 'brdomain' },
  { code: 'level', name: 'brlevel' },
  { code: 'sequence', name: 'brsubgroup' },
  { code: 'subLevel', name: 'brsublevel' },
  { code: 'typeOfEntity', name: 'brtypeofentity' },
  { code: 'status', name: 'brstatus' },
  { code: 'severity', name: 'brseverity' },
  { code: 'executionOrder', name: 'executionorder' },
  { code: 'context', name: 'brcontext' },
  { code: 'owner', name: 'browner' },
];

export const getTableConfig = (t) => [
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.name),
    code: 'name',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.cfr),
    code: 'cfr',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.flagState),
    code: 'countryOfRegistration',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.uvi),
    code: 'uvi',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.extMark),
    code: 'externalMarking',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.ircs),
    code: 'ircs',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.mmsi),
    code: 'mmsi',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.mainFishingGear),
    code: 'mainFishingGear',
    sortable: true,
  },
];

export default {
  businessRulesValueOptions,
};
