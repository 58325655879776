import editAttr from './img/edit_attr_fill.png';
import formMod from './img/form_mod.png';
import resultRow from './img/row_mod.png';

const SectionReferenceDataEditRow = () => (
  <div className="SectionReferenceDataEditRow__title">
    <p>
      Once in the edition form of a reference data, users can edit the attributes clicking on the
      &quot;Actions&quot; button of the corresponding attribute,
      and then selecting &quot;Edit&quot;.
      A modal window opens containing the edition form.
    </p>
    <p>
      <img src={editAttr} alt="Editing field" />
    </p>
    <p>
      Users cannot edit the &quot;Code&quot; field in the form. The rest of fields are editable.
    </p>
    <p>
      <img src={formMod} alt="Form modified" />
    </p>
    <p>
      Once the modifications have been finished,
      the user has to click the &quot;Confirm&quot; button to save the changes.
      Then, the information in the reference data grid is updated with the changes performed.
    </p>
    <p>
      <img src={resultRow} alt="Editing field" />
    </p>
  </div>
);

export default SectionReferenceDataEditRow;
