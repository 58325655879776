import { useState, useEffect, useMemo } from 'react';
import {
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import InputDate, { DATE_TYPE } from '../../components/Form/Inputs/InputDate';
import InputSelect from '../../components/Form/Inputs/InputSelect';
import { fetchSourcesList, downloadValidationReport } from '../../services/vessel_record/actions/activityReports';

const schema = tSchema.dmaAdmin.dataAdminReporting.validationReports;

const ValidationReports = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [source, setSource] = useState('ALL');
  const [clickedDownload, setClickedDownload] = useState(false);
  const [isShowingExport, setIsShowingExport] = useState(false);
  const sourcesList = useSelector((state) => state.activityReports.sourcesList);
  const isExporting = useSelector((state) => state.activityReports.isExporting);

  useEffect(() => {
    dispatch(fetchSourcesList());
  }, []);

  const formatSourcesList = () => [
    { code: 'ALL', description: 'ALL' },
    ...sourcesList.map((s) => ({ code: s.externalSource, description: s.externalSource })),
  ];

  const changeDateHandler = (id, value) => {
    if (id === 'from') {
      setFromDate(value);
    } else if (id === 'to') {
      setToDate(value);
    }
  };

  const changeSourceHandler = (id, value) => {
    setSource(value);
  };

  const formatFilters = () => (
    `${fromDate !== '' ? `startDate=${fromDate}:00.000` : ''}${
      toDate !== '' ? `&endDate=${toDate}:00.000` : ''}${
      source !== 'ALL' ? `&source=${source}` : ''}`
  );

  const dateIsRange = useMemo(() => (
    (fromDate !== null && fromDate !== '') && (toDate !== null && toDate !== '')
  ), [fromDate, toDate]);
  const dateIsValid = useMemo(() => fromDate < toDate, [fromDate, toDate]);
  const dateError = useMemo(
    () => (!dateIsRange && t(schema.validation.dateRange))
    || (!dateIsValid && t(schema.validation.dateOverlap)),
    [dateIsRange, dateIsValid],
  );

  document.body.addEventListener('click', () => {
    if (clickedDownload) {
      setClickedDownload(false);
    }
  });

  return (
    <>
      <FieldGroup
        label={t(schema.downloadReport)}
        style={{ maxWidth: '50%', paddingRight: '40px', paddingLeft: '40px' }}
      >
        <FieldGroup label={t(schema.from)}>
          <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Label>
              {t(schema.date)}
            </Form.Label>
            <InputDate
              className="input-date ml-4"
              id="from"
              type={DATE_TYPE.DATE_TIME}
              readOnly={false}
              value={fromDate}
              isValid
              onChange={changeDateHandler}
              required={false}
              complex={false}
              hasAction={false}
              maxDate={toDate || new Date()}
            />
          </Form.Group>
        </FieldGroup>
        <FieldGroup label={t(schema.to)}>
          <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Label>
              {t(schema.date)}
            </Form.Label>
            <InputDate
              className="input-date ml-4"
              id="to"
              type={DATE_TYPE.DATE_TIME}
              readOnly={false}
              value={toDate}
              isValid
              onChange={changeDateHandler}
              required={false}
              complex={false}
              hasAction={false}
              minDate={fromDate}
              maxDate={new Date()}
            />
          </Form.Group>
        </FieldGroup>
        <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Label>
            {t(schema.source)}
          </Form.Label>
          <div style={{ width: '100%' }}>
            <InputSelect
              className="input-select ml-4"
              id="source"
              readOnly={false}
              value={source}
              onChange={changeSourceHandler}
              required
              options={formatSourcesList()}
              complex={false}
              hasAction={false}
            />
          </div>
        </Form.Group>
        <OverlayTrigger
          placement="bottom"
          rootClose
          trigger="click"
          show={clickedDownload && dateError}
          overlay={(
            <Popover rootClose="false" className="vesselActions">
              <Popover.Content>
                <div style={{ textAlign: 'center' }}>
                  {dateError}
                </div>
              </Popover.Content>
            </Popover>
          )}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                setClickedDownload(true);
                if (!dateError) {
                  dispatch(downloadValidationReport(formatFilters()));
                  setIsShowingExport(true);
                }
              }}
            >
              {t(schema.downloadReport)}
            </Button>
          </div>
        </OverlayTrigger>
      </FieldGroup>
      <Modal
        backdrop="static"
        show={isExporting && isShowingExport}
      >
        <Modal.Header>
          <Modal.Title>
            {t(schema.exportingValidations)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '40px', height: '40px' }} />
          </div>
          <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
            This process may take a few minutes...
          </div>
          <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
            <Button
              id="close-modal"
              style={{ display: 'flex', marginTop: '1.25rem' }}
              onClick={() => {
                setIsShowingExport(false);
              }}
            >
              Run this task in background
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

ValidationReports.defaultProps = {};

ValidationReports.propTypes = {};

export default ValidationReports;
