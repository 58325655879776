import PropTypes from 'prop-types';
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import Filters from '@components/Filters';

import tSchema from '@lang/schema';

import {
  getFiltersConfig,
  getFiltersValues,
} from '@services/isr/reports/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import {
  getSearchOptions,
  resetPagination,
  getConfigureFilters,
} from './utils';

import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerFilter = ({
  searchFilters,
  setSearchFilters,
  loading,
  pageRequest,
  setPageRequest,
  dataTableRef,
}) => {
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [initialFilters, setInitialFilters] = useState(null);
  const [filterCombos, setFilterCombos] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { t } = useTranslation();
  const { userPreferences } = useUserPreferences();
  const idScenarioLastActive = userPreferences.lastActiveIsr?.id;

  const updateSearchFiltersHandler = useCallback((filters) => {
    if (pageRequest.current > 1) {
      resetPagination(dataTableRef, setPageRequest);
    }
    setSearchFilters(filters);
  }, [pageRequest, resetPagination]);

  const fetchFiltersValues = useCallback(async (filters) => {
    await requestWithMessage({
      request: () => getFiltersValues(filters, idScenarioLastActive),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setFilterCombos(data || []);
      },
    });
  });

  const handlerChangedFilters = useCallback((filters) => setSelectedFilters(filters));

  const fetchFiltersConfig = useCallback(async () => {
    await requestWithMessage({
      request: () => getFiltersConfig(),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        fetchFiltersValues(data);
      },
    });
  });

  useEffect(() => {
    fetchFiltersConfig();
  }, [idScenarioLastActive]);

  useEffect(() => {
    if (filterCombos) {
      const defaultFilters = {};
      searchFilters && setSearchFilters(null);
      setInitialFilters(defaultFilters);
      setFiltersConfig(
        getConfigureFilters(filterCombos, t,
          selectedFilters && selectedFilters.infringementClassification),
      );
    }
  }, [filterCombos]);

  useEffect(() => {
    if (filterCombos) {
      setFiltersConfig(
        getConfigureFilters(filterCombos, t,
          selectedFilters && selectedFilters.infringementClassification),
      );
    }
  }, [selectedFilters?.infringementClassification]);

  return (
    <div className="isrViewerFilter">
      <Filters
        filters={filtersConfig}
        searchOptions={getSearchOptions(t)}
        onUpdateSearchFilters={updateSearchFiltersHandler}
        isUIBlocked={loading}
        initialFilters={initialFilters}
        handlerChangedFilters={handlerChangedFilters}
      />
    </div>
  );
};

IsrViewerFilter.propTypes = {
  setSearchFilters: PropTypes.func.isRequired,
  searchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  pageRequest: PropTypes.objectOf(PropTypes.any).isRequired,
  setPageRequest: PropTypes.func.isRequired,
  dataTableRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default IsrViewerFilter;
