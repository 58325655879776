import PropTypes from 'prop-types';
import React, {
  useRef, useState, useCallback, useMemo, useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { M1 } from '@pages/IsrViewer/commons/constants';
import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import {
  getTableConfig, formatVmsOperational, formatRemOperational, formatLastPortCall,
} from './utils';

import { formatHour } from '@common/dateUtils';
import { requestWithMessage } from '@common/utilities/Notification';
import { fromDDToDMD } from '@common/utils';

const IsrViewerDetailsTabInspectedVessel = ({
  module, selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
}) => {
  const dataTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['inspectedVesselTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.inspectedVesselTab);
        const versionData = getVersionData(data.inspectedVesselTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid,
    selectedRecipient,
    isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <div>
          <Row>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.flag)}
                  :
                </Form.Label>
                <p>{tabData.flag || ''}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.name)}
                  :
                </Form.Label>
                <p>{tabData.name || ''}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.vesselType)}
                  :
                </Form.Label>
                <p>{tabData.vesselType || ''}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.externalMark)}
                  :
                </Form.Label>
                <p>{tabData.externalMarking || ''}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.ircs)}
                  :
                </Form.Label>
                <p>{tabData.ircs || ''}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.imo)}
                  :
                </Form.Label>
                <p>{tabData.imo || ''}</p>
              </div>
            </Col>
            <Col md={2}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.cfr)}
                  :
                </Form.Label>
                <p>{tabData.cfr || ''}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.position)}
                  :
                </Form.Label>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <p>{tabData.positionLatitude ? fromDDToDMD(tabData.positionLatitude, true, t) : ''}</p>
                  <p>{tabData.positionLongitude ? fromDDToDMD(tabData.positionLongitude, false, t) : ''}</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.at)}
                  :
                </Form.Label>
                <p>{tabData.positionDate && formatHour(tabData.positionDate, true)}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.location)}
                  :
                </Form.Label>
                <p>
                  {tabData.locationID && tabData.locationSchemeID
                    ? `${tabData.locationID}  (${tabData.locationSchemeID})` : ''}
                </p>
              </div>
            </Col>
            { module === M1 && (
            <Col md={2}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.activity)}
                  :
                </Form.Label>
                <p>{tabData.activity || ''}</p>
              </div>
            </Col>
            )}
          </Row>
          { module === M1 && (
          <Row>
            <Col md={6}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.vmsOperational)}
                  :
                </Form.Label>
                <p>{formatVmsOperational(tabData, t)}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.remOperational)}
                  :
                </Form.Label>
                <p>{formatRemOperational(tabData, t)}</p>
              </div>
            </Col>
          </Row>
          )}
          <Row>
            <Col md={6}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.lastPortCall)}
                  :
                </Form.Label>
                <p>{formatLastPortCall(tabData, t)}</p>
              </div>
            </Col>
          </Row>
          <FieldGroup label={t(tSchema.isrViewer.contacts)}>
            <Container fluid className="isrContacts">
              <DataTable
                rowData={tabData.contacts}
                idColumn="validityDate"
                tableId="isrContactsTable"
                ref={dataTableRef}
                tableConfig={tableConfig}
                loading={loading}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
        </div>
      )}
    </>
  );
};

IsrViewerDetailsTabInspectedVessel.propTypes = {
  module: PropTypes.instanceOf(Object).isRequired,
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabInspectedVessel;
