import PropTypes from 'prop-types';
import { path } from 'ramda';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

const LevelFormField = ({
  getOptions, disabled, rule, padLeft, onChange,
}) => {
  const { t } = useTranslation();

  const { createEditView: schema } = tSchema.dmaAdmin.brEngine;
  return (
    <Form.Group as={Col} controlId="formLevel">
      <Form.Label>
        {t(schema.level)}
      </Form.Label>
      <Form.Control
        required
        as="select"
        name="level"
        defaultValue={path(['level', 'id'])(rule)}
        disabled={disabled}
        onChange={onChange}
      >
        {getOptions('brlevel', 'id', (opt) => `L${padLeft(`${opt.id} - ${opt.description}`, 2, '0')}`)}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(schema.validation.emptyLevel)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

LevelFormField.propTypes = {
  getOptions: PropTypes.func.isRequired,
  padLeft: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired,
};

export default LevelFormField;
