const tSchema = {
  common: {
    actions: 'common.actions',
    addFilter: 'common.addFilter',
    applyFilters: 'common.applyFilters',
    cancel: 'common.cancel',
    confirm: 'common.confirm',
    clearFilters: 'common.clearFilters',
    csv: 'common.csv',
    delete: 'common.delete',
    edit: 'common.edit',
    excel: 'common.excel',
    execute: 'common.execute',
    exportAs: 'common.exportAs',
    exportToExcel: 'common.exportToExcel',
    filters: 'common.filters',
    logOut: 'common.logOut',
    results: 'common.results',
    resultsPerPage: 'common.resultsPerPage',
    save: 'common.save',
    close: 'common.close',
    selectFrom: 'common.selectFrom',
    view: 'common.view',
    selectPlaceholder: 'common.selectPlaceholder',
    remove: 'common.remove',
    emptyValuePlaceholder: 'common.emptyValuePlaceholder',
    emptyValueOnExcertpt: 'common.emptyValueOnExcertpt',
    noOptionSelected: 'common.noOptionSelected',
    loading: 'common.loading',
    equals: 'common.equals',
    contains: 'common.contains',
    notContains: 'common.notContains',
    before: 'common.before',
    after: 'common.after',
    notEquals: 'common.notEquals',
  },
  menu: {
    home: 'menu.home',
    acceptanceBR: 'menu.acceptanceBR',
    activeDirectory: 'menu.activeDirectory',
    brEngine: 'menu.brEngine',
    dataAdminReporting: 'menu.dataAdminReporting',
    dataExchangeModule: 'menu.dataExchangeModule',
    dmaAdministration: 'menu.dmaAdministration',
    domain: 'menu.domain',
    domainPlaceHolder: 'menu.domainPlaceHolder',
    externalSourcesMGMT: 'menu.externalSourcesMGMT',
    failoverParameters: 'menu.failoverParameters',
    fishingActivities: 'menu.fishingActivities',
    keycloak: 'menu.keycloak',
    logbookViewer: 'menu.logbookViewer',
    logManagement: 'menu.logManagement',
    messageViewer: 'menu.messageViewer',
    queryReportMGMT: 'menu.queryReportMGMT',
    referenceDataMGMT: 'menu.referenceDataMGMT',
    scenarioMGMT: 'menu.scenarioMGMT',
    servicesMonitoring: 'menu.servicesMonitoring',
    trafficMonitoring: 'menu.trafficMonitoring',
    userManagement: 'menu.userManagement',
    validationBR: 'menu.validationBR',
    vesselGroupMGMT: 'menu.vesselGroupMGMT',
    vesselMGMT: 'menu.vesselMGMT',
    vesselRecord: 'menu.vesselRecord',
    isrReports: 'menu.isrReports',
    isrViewer: 'menu.isrViewer',
  },
  notifications: {
    common: {
      error: 'notifications.common.error',
      fetchData: 'notifications.common.fetchData',
      fetchFilterOptions: 'notifications.common.fetchFilterOptions',
      exportCsv: 'notifications.common.exportCsv',
      timeOut: 'notifications.common.timeOut',
    },
    dmaAdmin: {
      queryReportMGMT: {
        querySubscription: {
          ok: 'notifications.dmaAdmin.queryReportMGMT.querySubscription.ok',
        },
        reportSubscription: {
          ok: 'notifications.dmaAdmin.queryReportMGMT.reportSubscription.ok',
        },
      },
      vesselGroupMGMT: {
        vesselGroups: {
          ok: 'notifications.dmaAdmin.vesselGroupMGMT.vesselGroups.ok',
          error: {
            fetchVesselGroups: 'notifications.dmaAdmin.vesselGroupMGMT.vesselGroups.error.fetchVesselGroups',
            importVessels: 'notifications.dmaAdmin.vesselGroupMGMT.vesselGroups.error.importVessels',
          },
        },
        deleteVesselGroups: {
          ok: 'notifications.dmaAdmin.vesselGroupMGMT.deleteVesselGroups.ok',
          error: {
            fetchVesselGroups: 'notifications.dmaAdmin.vesselGroupMGMT.deleteVesselGroups.error.fetchVesselGroups',
            importVessels: 'notifications.dmaAdmin.vesselGroupMGMT.deleteVesselGroups.error.importVessels',
          },
        },
      },
      externalSources: {
        ok: {
          edit: 'notifications.dmaAdmin.externalSources.ok.edit',
          execute: 'notifications.dmaAdmin.externalSources.ok.execute',
        },
        error: {
          edit: 'notifications.dmaAdmin.externalSources.error.edit',
          editVessel: 'notifications.dmaAdmin.externalSources.error.editVessel',
          execute: 'notifications.dmaAdmin.externalSources.error.execute',
        },
      },
      scenarioMGMT: {
        users: {
          assignScenario: {
            success: 'notifications.dmaAdmin.scenarioMGMT.users.assignScenario.success',
            error: 'notifications.dmaAdmin.scenarioMGMT.users.assignScenario.error',
          },
          fetchUsers: {
            error: 'notifications.dmaAdmin.scenarioMGMT.users.fetchUsers.error',
          },
        },
      },
    },
  },
  dem: {
    trafficMonitoring: {
      dataDomain: 'dem.trafficMonitoring.dataDomain',
      date: 'dem.trafficMonitoring.date',
      dateOfReception: 'dem.trafficMonitoring.dateOfReception',
      sendingDate: 'dem.trafficMonitoring.sendingDate',
      id: 'dem.trafficMonitoring.id',
      incoming: 'dem.trafficMonitoring.incoming',
      message: 'dem.trafficMonitoring.message',
      outgoing: 'dem.trafficMonitoring.outgoing',
      overview: 'dem.trafficMonitoring.overview',
      recipient: 'dem.trafficMonitoring.recipient',
      relatedMessages: 'dem.trafficMonitoring.relatedMessages',
      sender: 'dem.trafficMonitoring.sender',
      type: 'dem.trafficMonitoring.type',
    },
    failoverParameters: {
      description: 'dem.failoverParameters.description',
      minutes: 'dem.failoverParameters.minutes',
      days: 'dem.failoverParameters.days',
      hours: 'dem.failoverParameters.hours',
      attempts: 'dem.failoverParameters.attempts',
      parameter: 'dem.failoverParameters.parameter',
      value: 'dem.failoverParameters.value',
      unit: 'dem.failoverParameters.unit',
    },
  },
  servicesMonitoring: {
    serviceName: 'servicesMonitoring.serviceName',
    status: 'servicesMonitoring.status',
  },
  dmaAdmin: {
    messageViewer: {
      dataDomain: 'dmaAdmin.messageViewer.dataDomain',
      dateOfReception: 'dmaAdmin.messageViewer.dateOfReception',
      sendingDate: 'dmaAdmin.messageViewer.sendingDate',
      id: 'dmaAdmin.messageViewer.id',
      incoming: 'dmaAdmin.messageViewer.incoming',
      message: 'dmaAdmin.messageViewer.message',
      outgoing: 'dmaAdmin.messageViewer.outgoing',
      overview: 'dmaAdmin.messageViewer.overview',
      recipient: 'dmaAdmin.messageViewer.recipient',
      relatedMessages: 'dmaAdmin.messageViewer.relatedMessages',
      sender: 'dmaAdmin.messageViewer.sender',
      type: 'dmaAdmin.messageViewer.type',
    },
    scenarioMGMT: {
      scenarios: {
        createScenario: 'dmaAdmin.scenarioMGMT.scenarios.createScenario',
        dataAccessibilityFrom: 'dmaAdmin.scenarioMGMT.scenarios.dataAccessibilityFrom',
        dataAccessibilityTo: 'dmaAdmin.scenarioMGMT.scenarios.dataAccessibilityTo',
        description: 'dmaAdmin.scenarioMGMT.scenarios.description',
        name: 'dmaAdmin.scenarioMGMT.scenarios.name',
        scenarios: 'dmaAdmin.scenarioMGMT.scenarios.scenarios',
        status: 'dmaAdmin.scenarioMGMT.scenarios.status',
        owner: 'dmaAdmin.scenarioMGMT.scenarios.owner',
      },
      createEditView: {
        createScenario: 'dmaAdmin.scenarioMGMT.createEditView.createScenario',
        dataAccessibilityFrom: 'dmaAdmin.scenarioMGMT.createEditView.dataAccessibilityFrom',
        dataAccessibilityTo: 'dmaAdmin.scenarioMGMT.createEditView.dataAccessibilityTo',
        timePeriod: 'dmaAdmin.scenarioMGMT.createEditView.timePeriod',
        description: 'dmaAdmin.scenarioMGMT.createEditView.description',
        editScenario: 'dmaAdmin.scenarioMGMT.createEditView.editScenario',
        endDate: 'dmaAdmin.scenarioMGMT.createEditView.endDate',
        keepEmptyToIndefinite: 'dmaAdmin.scenarioMGMT.createEditView.keepEmptyToIndefinite',
        multichoiceFilters: 'dmaAdmin.scenarioMGMT.createEditView.multichoiceFilters',
        name: 'dmaAdmin.scenarioMGMT.createEditView.name',
        newScenario: 'dmaAdmin.scenarioMGMT.createEditView.newScenario',
        startDate: 'dmaAdmin.scenarioMGMT.createEditView.startDate',
        status: 'dmaAdmin.scenarioMGMT.createEditView.status',
        vesselGroup: 'dmaAdmin.scenarioMGMT.createEditView.vesselGroup',
        vesselGroups: 'dmaAdmin.scenarioMGMT.createEditView.vesselGroups',
        viewScenario: 'dmaAdmin.scenarioMGMT.createEditView.viewScenario',
        visualizationFilters: 'dmaAdmin.scenarioMGMT.createEditView.visualizationFilters',
        programme: 'dmaAdmin.scenarioMGMT.createEditView.programme',
        flagStates: 'dmaAdmin.scenarioMGMT.createEditView.flagStates',
        controlParties: 'dmaAdmin.scenarioMGMT.createEditView.controlParties',
        isrParties: 'dmaAdmin.scenarioMGMT.createEditView.isrParties',
        owner: 'dmaAdmin.scenarioMGMT.createEditView.owner',
      },
      users: {
        assigned: 'dmaAdmin.scenarioMGMT.users.assigned',
        assignScenarios: 'dmaAdmin.scenarioMGMT.users.assignScenarios',
        description: 'dmaAdmin.scenarioMGMT.users.description',
        discardChanges: 'dmaAdmin.scenarioMGMT.users.discardChanges',
        from: 'dmaAdmin.scenarioMGMT.users.from',
        name: 'dmaAdmin.scenarioMGMT.users.name',
        selectUser: 'dmaAdmin.scenarioMGMT.users.selectUser',
        status: 'dmaAdmin.scenarioMGMT.users.status',
        to: 'dmaAdmin.scenarioMGMT.users.to',
        users: 'dmaAdmin.scenarioMGMT.users.users',
        owner: 'dmaAdmin.scenarioMGMT.users.owner',
        validation: {
          validationErrors: 'dmaAdmin.scenarioMGMT.users.validation.validationErrors',
          noChanges: 'dmaAdmin.scenarioMGMT.users.validation.noChanges',
          fromBeforeTo: 'dmaAdmin.scenarioMGMT.users.validation.fromBeforeTo',
          startAccessDateRequired: 'dmaAdmin.scenarioMGMT.users.validation.startAccessDateRequired',
        },
      },
    },
    brEngine: {
      validationBR: {
        active: 'dmaAdmin.brEngine.validationBR.active',
        sublevel: 'dmaAdmin.brEngine.validationBR.sublevel',
        code: 'dmaAdmin.brEngine.validationBR.code',
        status: 'dmaAdmin.brEngine.validationBR.status',
        context: 'dmaAdmin.brEngine.validationBR.context',
        createBR: 'dmaAdmin.brEngine.validationBR.createBR',
        description: 'dmaAdmin.brEngine.validationBR.description',
        endDate: 'dmaAdmin.brEngine.validationBR.endDate',
        typeOfMessage: 'dmaAdmin.brEngine.validationBR.typeOfMessage',
        owner: 'dmaAdmin.brEngine.validationBR.owner',
        severity: 'dmaAdmin.brEngine.validationBR.severity',
        startDate: 'dmaAdmin.brEngine.validationBR.startDate',
        activeOn: 'dmaAdmin.brEngine.validationBR.activeOn',
      },
      createEditView: {
        context: 'dmaAdmin.brEngine.createEditView.context',
        definition: 'dmaAdmin.brEngine.createEditView.definition',
        description: 'dmaAdmin.brEngine.createEditView.description',
        domain: 'dmaAdmin.brEngine.createEditView.domain',
        editBusinessRule: 'dmaAdmin.brEngine.createEditView.editBusinessRule',
        endDate: 'dmaAdmin.brEngine.createEditView.endDate',
        entityToApplyBR: 'dmaAdmin.brEngine.createEditView.entityToApplyBR',
        executionOrder: 'dmaAdmin.brEngine.createEditView.executionOrder',
        level: 'dmaAdmin.brEngine.createEditView.level',
        message: 'dmaAdmin.brEngine.createEditView.message',
        newBusinessCase: 'dmaAdmin.brEngine.createEditView.newBusinessCase',
        owner: 'dmaAdmin.brEngine.createEditView.owner',
        sequence: 'dmaAdmin.brEngine.createEditView.sequence',
        severity: 'dmaAdmin.brEngine.createEditView.severity',
        startDate: 'dmaAdmin.brEngine.createEditView.startDate',
        status: 'dmaAdmin.brEngine.createEditView.status',
        subLevel: 'dmaAdmin.brEngine.createEditView.subLevel',
        typeOfMessage: 'dmaAdmin.brEngine.createEditView.typeOfMessage',
        viewBusinessRule: 'dmaAdmin.brEngine.createEditView.viewBusinessRule',
        lastModified: 'dmaAdmin.brEngine.createEditView.lastModified',
        validation: {
          emptyDomain: 'dmaAdmin.brEngine.createEditView.validation.emptyDomain',
          emptyLevel: 'dmaAdmin.brEngine.createEditView.validation.emptyLevel',
          emptySubLevel: 'dmaAdmin.brEngine.createEditView.validation.emptySubLevel',
          emptySequence: 'dmaAdmin.brEngine.createEditView.validation.emptySequence',
          emptyStatus: 'dmaAdmin.brEngine.createEditView.validation.emptyStatus',
          emptySeverity: 'dmaAdmin.brEngine.createEditView.validation.emptySeverity',
          emptyOwner: 'dmaAdmin.brEngine.createEditView.validation.emptyOwner',
          emptyContext: 'dmaAdmin.brEngine.createEditView.validation.emptyContext',
          emptyTypeOfMessage: 'dmaAdmin.brEngine.createEditView.validation.emptyTypeOfMessage',
          emptyMessage: 'dmaAdmin.brEngine.createEditView.validation.emptyMessage',
          emptyDescription: 'dmaAdmin.brEngine.createEditView.validation.emptyDescription',
          emptyExecutionOrder: 'dmaAdmin.brEngine.createEditView.validation.emptyExecutionOrder',
          dateEnd: 'dmaAdmin.brEngine.createEditView.validation.dateEnd',
          dateStart: 'dmaAdmin.brEngine.createEditView.validation.dateStart',
          emptyDefinition: 'dmaAdmin.brEngine.createEditView.validation.emptyDefinition',
          emptyEndDate: 'dmaAdmin.brEngine.createEditView.validation.emptyEndDate',
          emptyEntityToApplyBR: 'dmaAdmin.brEngine.createEditView.validation.emptyEntityToApplyBR',
          emptyNewBusinessCase: 'dmaAdmin.brEngine.createEditView.validation.emptyNewBusinessCase',
          emptyStartDate: 'dmaAdmin.brEngine.createEditView.validation.emptyStartDate',
          emptyViewBusinessRule: 'dmaAdmin.brEngine.createEditView.validation.emptyViewBusinessRule',
        },
      },
    },
    acceptanceBR: {
      query: {
        queryAcceptance: {
          active: 'dmaAdmin.acceptanceBR.query.queryAcceptance.active',
          createQueryAcceptanceCase: 'dmaAdmin.acceptanceBR.query.queryAcceptance.createQueryAcceptanceCase',
          dataEndDate: 'dmaAdmin.acceptanceBR.query.queryAcceptance.dataEndDate',
          dataStartDate: 'dmaAdmin.acceptanceBR.query.queryAcceptance.dataStartDate',
          queryAcceptance: 'dmaAdmin.acceptanceBR.query.queryAcceptance.queryAcceptance',
          sender: 'dmaAdmin.acceptanceBR.query.queryAcceptance.sender',
          validFrom: 'dmaAdmin.acceptanceBR.query.queryAcceptance.validFrom',
          validTo: 'dmaAdmin.acceptanceBR.query.queryAcceptance.validTo',
          vesselGroups: 'dmaAdmin.acceptanceBR.query.queryAcceptance.vesselGroups',
          queryContext: 'dmaAdmin.acceptanceBR.query.queryAcceptance.queryContext',
          isrParty: 'dmaAdmin.acceptanceBR.query.queryAcceptance.isrParty',
          isProgramme: 'dmaAdmin.acceptanceBR.query.queryAcceptance.isProgramme',
          owner: 'dmaAdmin.acceptanceBR.query.queryAcceptance.owner',
          filters: {
            requestedDataDate: 'dmaAdmin.acceptanceBR.query.queryAcceptance.filters.requestedDataDate',
            validOn: 'dmaAdmin.acceptanceBR.query.queryAcceptance.filters.validOn',
            status: 'dmaAdmin.acceptanceBR.query.queryAcceptance.filters.status',
            queryContext: 'dmaAdmin.acceptanceBR.query.queryAcceptance.filters.queryContext',
            isProgramme: 'dmaAdmin.acceptanceBR.query.queryAcceptance.filters.isProgramme',
          },
        },
        createEditView: {
          activityTimeframe: 'dmaAdmin.acceptanceBR.query.createEditView.activityTimeframe',
          dataScope: 'dmaAdmin.acceptanceBR.query.createEditView.dataScope',
          editAcceptanceRule: 'dmaAdmin.acceptanceBR.query.createEditView.editAcceptanceRule',
          from: 'dmaAdmin.acceptanceBR.query.createEditView.from',
          newAcceptanceCase: 'dmaAdmin.acceptanceBR.query.createEditView.newAcceptanceCase',
          sender: 'dmaAdmin.acceptanceBR.query.createEditView.sender',
          isrParty: 'dmaAdmin.acceptanceBR.query.createEditView.isrParty',
          status: 'dmaAdmin.acceptanceBR.query.createEditView.status',
          to: 'dmaAdmin.acceptanceBR.query.createEditView.to',
          vesselGroups: 'dmaAdmin.acceptanceBR.query.createEditView.vesselGroups',
          viewAcceptanceRule: 'dmaAdmin.acceptanceBR.query.createEditView.viewAcceptanceRule',
          deleteAcceptanceRule: 'dmaAdmin.acceptanceBR.query.createEditView.deleteAcceptanceRule',
          queryContext: 'dmaAdmin.acceptanceBR.query.createEditView.queryContext',
          isProgramme: 'dmaAdmin.acceptanceBR.query.createEditView.isProgramme',
          owner: 'dmaAdmin.acceptanceBR.query.createEditView.owner',
          validation: {
            emptySender: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptySender',
            emptyISRParty: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptyISRParty',
            emptyStatus: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptyStatus',
            emptyActivityStartDate: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptyActivityStartDate',
            emptyVesselGroups: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptyVesselGroups',
            emptyOwner: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptyOwner',
            dateOverlap: 'dmaAdmin.acceptanceBR.query.createEditView.validation.dateOverlap',
            emptyDataScopeTo: 'dmaAdmin.acceptanceBR.query.createEditView.validation.emptyDataScopeTo',
          },
        },
      },
      report: {
        reportAcceptance: {
          active: 'dmaAdmin.acceptanceBR.report.reportAcceptance.active',
          createReportAcceptanceRule: 'dmaAdmin.acceptanceBR.report.reportAcceptance.createReportAcceptanceRule',
          dataScopeFrom: 'dmaAdmin.acceptanceBR.report.reportAcceptance.dataScopeFrom',
          dataScopeTo: 'dmaAdmin.acceptanceBR.report.reportAcceptance.dataScopeTo',
          flagState: 'dmaAdmin.acceptanceBR.report.reportAcceptance.flagState',
          reportAcceptance: 'dmaAdmin.acceptanceBR.report.reportAcceptance.reportAcceptance',
          sender: 'dmaAdmin.acceptanceBR.report.reportAcceptance.sender',
          validFrom: 'dmaAdmin.acceptanceBR.report.reportAcceptance.validFrom',
          validTo: 'dmaAdmin.acceptanceBR.report.reportAcceptance.validTo',
          filters: {
            reportDataDate: 'dmaAdmin.acceptanceBR.report.reportAcceptance.filters.reportDataDate',
            validOn: 'dmaAdmin.acceptanceBR.report.reportAcceptance.filters.validOn',
            status: 'dmaAdmin.acceptanceBR.report.reportAcceptance.filters.status',
          },
        },
        createEditView: {
          activityTimeframe: 'dmaAdmin.acceptanceBR.report.createEditView.activityTimeframe',
          dataScope: 'dmaAdmin.acceptanceBR.report.createEditView.dataScope',
          editAcceptanceRule: 'dmaAdmin.acceptanceBR.report.createEditView.editAcceptanceRule',
          flagState: 'dmaAdmin.acceptanceBR.report.createEditView.flagState',
          from: 'dmaAdmin.acceptanceBR.report.createEditView.from',
          newAcceptanceCase: 'dmaAdmin.acceptanceBR.report.createEditView.newAcceptanceCase',
          sender: 'dmaAdmin.acceptanceBR.report.createEditView.sender',
          status: 'dmaAdmin.acceptanceBR.report.createEditView.status',
          to: 'dmaAdmin.acceptanceBR.report.createEditView.to',
          viewAcceptanceRule: 'dmaAdmin.acceptanceBR.report.createEditView.viewAcceptanceRule',
          deleteAcceptanceRule: 'dmaAdmin.acceptanceBR.report.createEditView.deleteAcceptanceRule',
        },
      },
    },
    queryReportMGMT: {
      query: {
        active: 'dmaAdmin.queryReportMGMT.query.active',
        consolidated: 'dmaAdmin.queryReportMGMT.query.consolidated',
        createQuery: 'dmaAdmin.queryReportMGMT.query.createQuery',
        endDate: 'dmaAdmin.queryReportMGMT.query.endDate',
        name: 'dmaAdmin.queryReportMGMT.query.name',
        lastExecute: 'dmaAdmin.queryReportMGMT.query.lastExecute',
        querySubscription: 'dmaAdmin.queryReportMGMT.query.querySubscription',
        receiver: 'dmaAdmin.queryReportMGMT.query.receiver',
        schedule: 'dmaAdmin.queryReportMGMT.query.schedule',
        startDate: 'dmaAdmin.queryReportMGMT.query.startDate',
        subscriptionType: 'dmaAdmin.queryReportMGMT.query.subscriptionType',
        commission: 'dmaAdmin.queryReportMGMT.query.commission',
      },
      report: {
        active: 'dmaAdmin.queryReportMGMT.report.active',
        createReport: 'dmaAdmin.queryReportMGMT.report.createReport',
        endDate: 'dmaAdmin.queryReportMGMT.report.endDate',
        name: 'dmaAdmin.queryReportMGMT.report.name',
        receiver: 'dmaAdmin.queryReportMGMT.report.receiver',
        reportSubscription: 'dmaAdmin.queryReportMGMT.report.reportSubscription',
        sender: 'dmaAdmin.queryReportMGMT.report.sender',
        startDate: 'dmaAdmin.queryReportMGMT.report.startDate',
        type: 'dmaAdmin.queryReportMGMT.report.type',
      },
      createEditView: {
        domainSelector: 'dmaAdmin.queryReportMGMT.createEditView.domainSelector',
        consolidatedQuery: 'dmaAdmin.queryReportMGMT.createEditView.consolidatedQuery',
        dataScope: 'dmaAdmin.queryReportMGMT.createEditView.dataScope',
        from: 'dmaAdmin.queryReportMGMT.createEditView.from',
        to: 'dmaAdmin.queryReportMGMT.createEditView.to',
        days: 'dmaAdmin.queryReportMGMT.createEditView.days',
        description: 'dmaAdmin.queryReportMGMT.createEditView.description',
        endDate: 'dmaAdmin.queryReportMGMT.createEditView.endDate',
        executionTime: 'dmaAdmin.queryReportMGMT.createEditView.executionTime',
        general: 'dmaAdmin.queryReportMGMT.createEditView.general',
        last: 'dmaAdmin.queryReportMGMT.createEditView.last',
        name: 'dmaAdmin.queryReportMGMT.createEditView.name',
        newQueryAcceptanceCase: 'dmaAdmin.queryReportMGMT.createEditView.newQueryAcceptanceCase',
        originalSender: 'dmaAdmin.queryReportMGMT.createEditView.originalSender',
        queryObject: 'dmaAdmin.queryReportMGMT.createEditView.queryObject',
        queryType: 'dmaAdmin.queryReportMGMT.createEditView.queryType',
        queryParameters: 'dmaAdmin.queryReportMGMT.createEditView.queryParameters',
        receiver: 'dmaAdmin.queryReportMGMT.createEditView.receiver',
        recurrence: 'dmaAdmin.queryReportMGMT.createEditView.recurrence',
        typeOptions: {
          1: 'dmaAdmin.queryReportMGMT.createEditView.typeOptions.1',
          2: 'dmaAdmin.queryReportMGMT.createEditView.typeOptions.2',
        },
        queryTypeOptions: {
          1: 'dmaAdmin.queryReportMGMT.createEditView.queryTypeOptions.1',
          2: 'dmaAdmin.queryReportMGMT.createEditView.queryTypeOptions.2',
        },
        startingDate: 'dmaAdmin.queryReportMGMT.createEditView.startingDate',
        status: 'dmaAdmin.queryReportMGMT.createEditView.status',
        type: 'dmaAdmin.queryReportMGMT.createEditView.type',
        vessel: 'dmaAdmin.queryReportMGMT.createEditView.vessel',
        vesselGroups: 'dmaAdmin.queryReportMGMT.createEditView.vesselGroups',
        vesselIdType: 'dmaAdmin.queryReportMGMT.createEditView.vesselIdType',
        vesselCountry: 'dmaAdmin.queryReportMGMT.createEditView.vesselCountry',
        vesselStatus: 'dmaAdmin.queryReportMGMT.createEditView.vesselStatus',
        trips: 'dmaAdmin.queryReportMGMT.createEditView.trips',
      },
    },
    referenceDataMGMT: {
      description: 'dmaAdmin.referenceDataMGMT.description',
      name: 'dmaAdmin.referenceDataMGMT.name',
      upload: {
        title: 'dmaAdmin.referenceDataMGMT.upload.title',
        created: 'dmaAdmin.referenceDataMGMT.upload.created',
        updated: 'dmaAdmin.referenceDataMGMT.upload.updated',
        discarded: 'dmaAdmin.referenceDataMGMT.upload.discarded',
        errors: 'dmaAdmin.referenceDataMGMT.upload.errors',
      },
    },
    vesselGroupMGMT: {
      vesselGroups: {
        active: 'dmaAdmin.vesselGroupMGMT.vesselGroups.active',
        inactive: 'dmaAdmin.vesselGroupMGMT.vesselGroups.inactive',
        createVesselGroup: 'dmaAdmin.vesselGroupMGMT.vesselGroups.createVesselGroup',
        deleteVesselGroup: 'dmaAdmin.vesselGroupMGMT.deleteVesselGroups.deleteVesselGroup',
        editVesselGroup: 'dmaAdmin.vesselGroupMGMT.vesselGroups.editVesselGroup',
        viewVesselGroup: 'dmaAdmin.vesselGroupMGMT.vesselGroups.viewVesselGroup',
        description: 'dmaAdmin.vesselGroupMGMT.vesselGroups.description',
        importVesselGroups: 'dmaAdmin.vesselGroupMGMT.vesselGroups.importVesselGroups',
        name: 'dmaAdmin.vesselGroupMGMT.vesselGroups.name',
        status: 'dmaAdmin.vesselGroupMGMT.vesselGroups.status',
        type: 'dmaAdmin.vesselGroupMGMT.vesselGroups.type',
        deleteConfirmation: 'dmaAdmin.vesselGroupMGMT.vesselGroups.deleteConfirmation',
      },
      import: {
        loadVesselsFromFile: 'dmaAdmin.vesselGroupMGMT.import.loadVesselsFromFile',
        loadVesselGroupsFromFile: 'dmaAdmin.vesselGroupMGMT.import.loadVesselGroupsFromFile',
      },
      createEditView: {
        addVessels: 'dmaAdmin.vesselGroupMGMT.createEditView.addVessels',
        cfr: 'dmaAdmin.vesselGroupMGMT.createEditView.cfr',
        description: 'dmaAdmin.vesselGroupMGMT.createEditView.description',
        dynamic: 'dmaAdmin.vesselGroupMGMT.createEditView.dynamic',
        extMark: 'dmaAdmin.vesselGroupMGMT.createEditView.extMark',
        flagState: 'dmaAdmin.vesselGroupMGMT.createEditView.flagState',
        groupType: 'dmaAdmin.vesselGroupMGMT.createEditView.groupType',
        ircs: 'dmaAdmin.vesselGroupMGMT.createEditView.ircs',
        loadVesselsFromFile: 'dmaAdmin.vesselGroupMGMT.createEditView.loadVesselsFromFile',
        mainFishingGear: 'dmaAdmin.vesselGroupMGMT.createEditView.mainFishingGear',
        mmsi: 'dmaAdmin.vesselGroupMGMT.createEditView.mmsi',
        name: 'dmaAdmin.vesselGroupMGMT.createEditView.name',
        static: 'dmaAdmin.vesselGroupMGMT.createEditView.static',
        status: 'dmaAdmin.vesselGroupMGMT.createEditView.status',
        uvi: 'dmaAdmin.vesselGroupMGMT.createEditView.uvi',
        vesselName: 'dmaAdmin.vesselGroupMGMT.createEditView.vesselName',
      },
    },
    externalSources: {
      main: {
        externalSource: 'dmaAdmin.externalSources.main.externalSource',
        priority: 'dmaAdmin.externalSources.main.priority',
        status: 'dmaAdmin.externalSources.main.status',
        lastExecutionTime: 'dmaAdmin.externalSources.main.lastExecutionTime',
      },
      editView: {
        endDate: 'dmaAdmin.externalSources.editView.endDate',
        executionTime: 'dmaAdmin.externalSources.editView.executionTime',
        externalSource: 'dmaAdmin.externalSources.editView.externalSource',
        priority: 'dmaAdmin.externalSources.editView.priority',
        recurrence: 'dmaAdmin.externalSources.editView.recurrence',
        startingDate: 'dmaAdmin.externalSources.editView.startingDate',
        status: 'dmaAdmin.externalSources.editView.status',
        statusOptions: {
          active: 'dmaAdmin.externalSources.editView.statusOptions.active',
          enabled: 'dmaAdmin.externalSources.editView.statusOptions.enabled',
          inoperative: 'dmaAdmin.externalSources.editView.statusOptions.inoperative',
        },
      },
    },
    dataAdminReporting: {
      validationReports: {
        date: 'dmaAdmin.dataAdminReporting.validationReports.date',
        downloadReport: 'dmaAdmin.dataAdminReporting.validationReports.downloadReport',
        from: 'dmaAdmin.dataAdminReporting.validationReports.from',
        source: 'dmaAdmin.dataAdminReporting.validationReports.source',
        to: 'dmaAdmin.dataAdminReporting.validationReports.to',
        validationReports: 'dmaAdmin.dataAdminReporting.validationReports.validationReports',
        validation: {
          dateRange: 'dmaAdmin.dataAdminReporting.validationReports.validation.dateRange',
          dateOverlap: 'dmaAdmin.dataAdminReporting.validationReports.validation.dateOverlap',
        },
        exportingValidations: 'dmaAdmin.dataAdminReporting.validationReports.exportingValidations',
      },
      dataActivity: {
        cfr: 'dmaAdmin.dataAdminReporting.dataActivity.cfr',
        dataActivity: 'dmaAdmin.dataAdminReporting.dataActivity.dataActivity',
        dateOfAction: 'dmaAdmin.dataAdminReporting.dataActivity.dateOfAction',
        source: 'dmaAdmin.dataAdminReporting.dataActivity.source',
        typeOfAction: 'dmaAdmin.dataAdminReporting.dataActivity.typeOfAction',
        uvi: 'dmaAdmin.dataAdminReporting.dataActivity.uvi',
      },
    },
  },
  vesselMGMT: {
    addVessel: 'vesselMGMT.addVessel',
    vesselIdentifier: 'vesselMGMT.vesselIdentifier',
    cfr: 'vesselMGMT.cfr',
    countryOfRegistration: 'vesselMGMT.countryOfRegistration',
    exportToExcel: 'vesselMGMT.exportToExcel',
    externalMarking: 'vesselMGMT.externalMarking',
    importFrom: 'vesselMGMT.importFrom',
    ircs: 'vesselMGMT.ircs',
    loa: 'vesselMGMT.loa',
    name: 'vesselMGMT.name',
    powerOfMainEngine: 'vesselMGMT.powerOfMainEngine',
    reportedEvent: 'vesselMGMT.reportedEvent',
    searchInTheFullHistory: 'vesselMGMT.searchInTheFullHistory',
    tonnageGt: 'vesselMGMT.tonnageGt',
    uvi: 'vesselMGMT.uvi',
    validityDate: 'vesselMGMT.validityDate',
    vesselType: 'vesselMGMT.vesselType',
    export: {
      exportingVessels: 'vesselMGMT.export.exportingVessels',
    },
    createEditView: {
      vesselIdentification: {
        countryOfImportExport: 'vesselMGMT.createEditView.vesselIdentification.countryOfImportExport',
        aisIndicator: 'vesselMGMT.createEditView.vesselIdentification.aisIndicator',
        cfr: 'vesselMGMT.createEditView.vesselIdentification.cfr',
        countryOfRegistration: 'vesselMGMT.createEditView.vesselIdentification.countryOfRegistration',
        ersIndicator: 'vesselMGMT.createEditView.vesselIdentification.ersIndicator',
        euSegment: 'vesselMGMT.createEditView.vesselIdentification.euSegment',
        externalMarking: 'vesselMGMT.createEditView.vesselIdentification.externalMarking',
        ircs: 'vesselMGMT.createEditView.vesselIdentification.ircs',
        ircsIndicator: 'vesselMGMT.createEditView.vesselIdentification.ircsIndicator',
        licenseIndicator: 'vesselMGMT.createEditView.vesselIdentification.licenseIndicator',
        mmsi: 'vesselMGMT.createEditView.vesselIdentification.mmsi',
        nameOfVessel: 'vesselMGMT.createEditView.vesselIdentification.nameOfVessel',
        nationalRegistrationNumber: 'vesselMGMT.createEditView.vesselIdentification.nationalRegistrationNumber',
        placeOfRegistration: 'vesselMGMT.createEditView.vesselIdentification.placeOfRegistration',
        portOfRegistration: 'vesselMGMT.createEditView.vesselIdentification.portOfRegistration',
        reportedEvent: 'vesselMGMT.createEditView.vesselIdentification.reportedEvent',
        satphoneNumber: 'vesselMGMT.createEditView.vesselIdentification.satphoneNumber',
        thirdPartyCode: 'vesselMGMT.createEditView.vesselIdentification.thirdPartyCode',
        thirdPartyVesselIdentifier: 'vesselMGMT.createEditView.vesselIdentification.thirdPartyVesselIdentifier',
        thirdPartyVesselIdentifiers: 'vesselMGMT.createEditView.vesselIdentification.thirdPartyVesselIdentifiers',
        typeOfExport: 'vesselMGMT.createEditView.vesselIdentification.typeOfExport',
        uvi: 'vesselMGMT.createEditView.vesselIdentification.uvi',
        validityDate: 'vesselMGMT.createEditView.vesselIdentification.validityDate',
        vesselType: 'vesselMGMT.createEditView.vesselIdentification.vesselType',
        vmsIndicator: 'vesselMGMT.createEditView.vesselIdentification.vmsIndicator',
      },
      vesselTechnicalDetails: {
        breadth: 'vesselMGMT.createEditView.vesselTechnicalDetails.breadth',
        dateOfEntryIntoService: 'vesselMGMT.createEditView.vesselTechnicalDetails.dateOfEntryIntoService',
        draught: 'vesselMGMT.createEditView.vesselTechnicalDetails.draught',
        fishHoldCapacity: 'vesselMGMT.createEditView.vesselTechnicalDetails.fishHoldCapacity',
        freezingCapacity: 'vesselMGMT.createEditView.vesselTechnicalDetails.freezingCapacity',
        hullMaterial: 'vesselMGMT.createEditView.vesselTechnicalDetails.hullMaterial',
        loa: 'vesselMGMT.createEditView.vesselTechnicalDetails.loa',
        mainFishingGear: 'vesselMGMT.createEditView.vesselTechnicalDetails.mainFishingGear',
        numberOfFishHolds: 'vesselMGMT.createEditView.vesselTechnicalDetails.numberOfFishHolds',
        otherTonnage: 'vesselMGMT.createEditView.vesselTechnicalDetails.otherTonnage',
        powerOfAuxiliaryEngine: 'vesselMGMT.createEditView.vesselTechnicalDetails.powerOfAuxiliaryEngine',
        powerOfMainEngine: 'vesselMGMT.createEditView.vesselTechnicalDetails.powerOfMainEngine',
        storageCapacity: 'vesselMGMT.createEditView.vesselTechnicalDetails.storageCapacity',
        subsidiaryFishingGear: 'vesselMGMT.createEditView.vesselTechnicalDetails.subsidiaryFishingGear',
        subsidiaryFishingGears: 'vesselMGMT.createEditView.vesselTechnicalDetails.subsidiaryFishingGears',
        tonnageGt: 'vesselMGMT.createEditView.vesselTechnicalDetails.tonnageGt',
        vesselImages: 'vesselMGMT.createEditView.vesselTechnicalDetails.vesselImages',
        vesselPhotoDate: 'vesselMGMT.createEditView.vesselTechnicalDetails.vesselPhotoDate',
        vesselPhotoDescription: 'vesselMGMT.createEditView.vesselTechnicalDetails.vesselPhotoDescription',
        vesselPhotoType: 'vesselMGMT.createEditView.vesselTechnicalDetails.vesselPhotoType',
        yearOfConstruction: 'vesselMGMT.createEditView.vesselTechnicalDetails.yearOfConstruction',
      },
      companyInformation: {
        city: 'vesselMGMT.createEditView.companyInformation.city',
        country: 'vesselMGMT.createEditView.companyInformation.country',
        emailAddress: 'vesselMGMT.createEditView.companyInformation.emailAddress',
        faxNumber: 'vesselMGMT.createEditView.companyInformation.faxNumber',
        imoIdentifier: 'vesselMGMT.createEditView.companyInformation.imoIdentifier',
        legalPersonIndicator: 'vesselMGMT.createEditView.companyInformation.legalPersonIndicator',
        name: 'vesselMGMT.createEditView.companyInformation.name',
        nationality: 'vesselMGMT.createEditView.companyInformation.nationality',
        operator: 'vesselMGMT.createEditView.companyInformation.operator',
        owner: 'vesselMGMT.createEditView.companyInformation.owner',
        phoneNumber: 'vesselMGMT.createEditView.companyInformation.phoneNumber',
        poBox: 'vesselMGMT.createEditView.companyInformation.poBox',
        postCode: 'vesselMGMT.createEditView.companyInformation.postCode',
        publicAid: 'vesselMGMT.createEditView.companyInformation.publicAid',
        street: 'vesselMGMT.createEditView.companyInformation.street',
      },
      validation: {
        invalidForm: 'vesselMGMT.createEditView.validation.invalidForm',
        errors: 'vesselMGMT.createEditView.validation.errors',
        warnings: 'vesselMGMT.createEditView.validation.warnings',
      },
      buttons: {
        cancel: 'vesselMGMT.createEditView.buttons.cancel',
        save: 'vesselMGMT.createEditView.buttons.save',
        saveWithWarnings: 'vesselMGMT.createEditView.buttons.saveWithWarnings',
        saveAndConfirm: 'vesselMGMT.createEditView.buttons.saveAndConfirm',
        saveAndConfirmWithWarnings: 'vesselMGMT.createEditView.buttons.saveAndConfirmWithWarnings',
      },
      lastEdited: 'vesselMGMT.createEditView.lastEdited',
    },
    historical: {
      selectHistorical: 'vesselMGMT.historical.selectHistorical',
      source: 'vesselMGMT.historical.source',
      version: 'vesselMGMT.historical.version',
      table: {
        flagState: 'vesselMGMT.historical.table.flagState',
        reportEvent: 'vesselMGMT.historical.table.reportEvent',
        validityDate: 'vesselMGMT.historical.table.validityDate',
        source: 'vesselMGMT.historical.table.source',
        vesselType: 'vesselMGMT.historical.table.vesselType',
        ircs: 'vesselMGMT.historical.table.ircs',
        externalMarking: 'vesselMGMT.historical.table.externalMarking',
        name: 'vesselMGMT.historical.table.name',
        transactionDate: 'vesselMGMT.historical.table.transactionDate',
      },
    },
    vesselGroups: {
      availableGroups: 'vesselMGMT.vesselGroups.availableGroups',
      cfr: 'vesselMGMT.vesselGroups.cfr',
      currentGroups: 'vesselMGMT.vesselGroups.currentGroups',
      externalMarking: 'vesselMGMT.vesselGroups.externalMarking',
      ircs: 'vesselMGMT.vesselGroups.ircs',
      name: 'vesselMGMT.vesselGroups.name',
      reportedEvent: 'vesselMGMT.vesselGroups.reportedEvent',
      uvi: 'vesselMGMT.vesselGroups.uvi',
      validityDate: 'vesselMGMT.vesselGroups.validityDate',
      vesselGroups: 'vesselMGMT.vesselGroups.vesselGroups',
    },
    upload: {
      title: 'vesselMGMT.upload.title',
      inserted: 'vesselMGMT.upload.inserted',
      updated: 'vesselMGMT.upload.updated',
      insertedWithWarnings: 'vesselMGMT.upload.insertedWithWarnings',
      updatedWithWarnings: 'vesselMGMT.upload.updatedWithWarnings',
      errors: 'vesselMGMT.upload.errors',
      downloadReport: 'vesselMGMT.upload.downloadReport',
    },
  },
  isrViewer: {
    deleteConfirmation: 'isrViewer.deleteConfirmation',
    programme: 'isrViewer.programme',
    identifier: 'isrViewer.identifier',
    module: 'isrViewer.module',
    controlParty: 'isrViewer.controlParty',
    infringement: 'isrViewer.infringement',
    dateTime: 'isrViewer.dateTime',
    object: 'isrViewer.object',
    vesselName: 'isrViewer.vesselName',
    reportStatus: 'isrViewer.reportStatus',
    exportToExcel: 'isrViewer.exportToExcel',
    updateReferences: 'isrViewer.updateReferences',
    errorEmpty: 'isrViewer.errorEmpty',
    errorLoading: 'isrViewer.errorLoading',
    attachFile: 'isrViewer.attachFile',
    viewMessage: 'isrViewer.viewMessage',
    removeObject: 'isrViewer.removeObject',
    context: 'isrViewer.context',
    infringementClassification: 'isrViewer.infringementClassification',
    infringementType: 'isrViewer.infringementType',
    gear: 'isrViewer.gear',
    timeframe: 'isrViewer.timeframe',
    inspectedSpecies: 'isrViewer.inspectedSpecies',
    objectCountry: 'isrViewer.objectCountry',
    businessTriggeredAnd: 'isrViewer.businessTriggeredAnd',
    businessTriggeredOr: 'isrViewer.businessTriggeredOr',
    schemeId: 'isrViewer.schemeId',
    nationality: 'isrViewer.nationality',
    name: 'isrViewer.name',
    role: 'isrViewer.role',
    cardPresented: 'isrViewer.cardPresented',
    m1Title: 'isrViewer.m1Title',
    m3Title: 'isrViewer.m3Title',
    patrolVessel: 'isrViewer.patrolVessel',
    inspectedVessel: 'isrViewer.inspectedVessel',
    documents: 'isrViewer.documents',
    catchProducts: 'isrViewer.catchProducts',
    infringements: 'isrViewer.infringements',
    inspectionClosure: 'isrViewer.inspectionClosure',
    inspectionMeans: 'isrViewer.inspectionMeans',
    reportReference: 'isrViewer.reportReference',
    date: 'isrViewer.date',
    arrivalOnBoard: 'isrViewer.arrivalOnBoard',
    departure: 'isrViewer.departure',
    inspectionStart: 'isrViewer.inspectionStart',
    inspectionEnd: 'isrViewer.inspectionEnd',
    controlAuthority: 'isrViewer.controlAuthority',
    designatedPort: 'isrViewer.designatedPort',
    portOfInspection: 'isrViewer.portOfInspection',
    flag: 'isrViewer.flag',
    position: 'isrViewer.position',
    north: 'isrViewer.north',
    south: 'isrViewer.south',
    east: 'isrViewer.east',
    west: 'isrViewer.west',
    at: 'isrViewer.at',
    on: 'isrViewer.on',
    imo: 'isrViewer.imo',
    location: 'isrViewer.location',
    externalMark: 'isrViewer.externalMark',
    ircs: 'isrViewer.ircs',
    inspectors: 'isrViewer.inspectors',
    preInspection: 'isrViewer.preInspection',
    radioCall: 'isrViewer.radioCall',
    vesselLogbook: 'isrViewer.vesselLogbook',
    boardingLadder: 'isrViewer.boardingLadder',
    result: 'isrViewer.result',
    description: 'isrViewer.description',
    details: 'isrViewer.details',
    vesselType: 'isrViewer.vesselType',
    cfr: 'isrViewer.cfr',
    activity: 'isrViewer.activity',
    vmsOperational: 'isrViewer.vmsOperational',
    remOperational: 'isrViewer.remOperational',
    lastPortCall: 'isrViewer.lastPortCall',
    contacts: 'isrViewer.contacts',
    legalEntity: 'isrViewer.legalEntity',
    address: 'isrViewer.address',
    documentsAuthorizations: 'isrViewer.documentsAuthorizations',
    type: 'isrViewer.type',
    checked: 'isrViewer.checked',
    notChecked: 'isrViewer.notChecked',
    additionalInfo: 'isrViewer.additionalInfo',
    observationsComments: 'isrViewer.observationsComments',
    value: 'isrViewer.value',
    unit: 'isrViewer.unit',
    gearChecked: 'isrViewer.gearChecked',
    ecGaugeChecked: 'isrViewer.ecGaugeChecked',
    gearMarkingChecked: 'isrViewer.gearMarkingChecked',
    gearCharacteristics: 'isrViewer.gearCharacteristics',
    gearAttachments: 'isrViewer.gearAttachments',
    attachmentCharacteristics: 'isrViewer.attachmentCharacteristics',
    catchOnboard: 'isrViewer.catchOnboard',
    discards: 'isrViewer.discards',
    species: 'isrViewer.species',
    weight: 'isrViewer.weight',
    numberFish: 'isrViewer.numberFish',
    sizeClass: 'isrViewer.sizeClass',
    presentation: 'isrViewer.presentation',
    preservation: 'isrViewer.preservation',
    catchArea: 'isrViewer.catchArea',
    catchOffloaded: 'isrViewer.catchOffloaded',
    catchReceived: 'isrViewer.catchReceived',
    catches: 'isrViewer.catches',
    donorVessels: 'isrViewer.donorVessels',
    suspInfringObserved: 'isrViewer.suspInfringObserved',
    detInfringObserved: 'isrViewer.detInfringObserved',
    suspInfringements: 'isrViewer.suspInfringements',
    suspInfringCategories: 'isrViewer.suspInfringCategories',
    id: 'isrViewer.id',
    legalReference: 'isrViewer.legalReference',
    observation: 'isrViewer.observation',
    seriousViolation: 'isrViewer.seriousViolation',
    typeClassification: 'isrViewer.typeClassification',
    typeCode: 'isrViewer.typeCode',
    typeLabel: 'isrViewer.typeLabel',
    typeDescription: 'isrViewer.typeDescription',
    actionTaken: 'isrViewer.actionTaken',
    detActionTaken: 'isrViewer.detActionTaken',
    inspInChargeSign: 'isrViewer.inspInChargeSign',
    masterSign: 'isrViewer.masterSign',
    observComments: 'isrViewer.observComments',
    typeObservations: 'isrViewer.typeObservations',
    vmsPreArrival: 'isrViewer.vmsPreArrival',
    vesselLogbookCompl: 'isrViewer.vesselLogbookCompl',
    statusRfmos: 'isrViewer.statusRfmos',
    rfmo: 'isrViewer.rfmo',
    vesselId: 'isrViewer.vesselId',
    authorizedList: 'isrViewer.authorizedList',
    iuuList: 'isrViewer.iuuList',
    sent: 'isrViewer.sent',
    recipient: 'isrViewer.recipient',
    attachments: 'isrViewer.attachments',
    addAttachments: 'isrViewer.addAttachments',
    participants: 'isrViewer.participants',
    size: 'isrViewer.size',
    uploadDate: 'isrViewer.uploadDate',
    user: 'isrViewer.user',
    europeCountries: 'isrViewer.europeCountries',
    worldCountries: 'isrViewer.worldCountries',
    descriptionFile: 'isrViewer.descriptionFile',
    currentVersion: 'isrViewer.currentVersion',
    correctedVersion: 'isrViewer.correctedVersion',
    deletedVersion: 'isrViewer.deletedVersion',
    yes: 'isrViewer.yes',
    no: 'isrViewer.no',
    exportError: 'isrViewer.exportError',
    continueBackground: 'isrViewer.continueBackground',
    deleteSuccess: 'isrViewer.deleteSuccess',
    deleteError: 'isrViewer.deleteError',
    deleteReportSuccess: 'isrViewer.deleteReportSuccess',
    deleteReportError: 'isrViewer.deleteReportError',
    exportingReports: 'isrViewer.exportingReports',
    takeMinutes: 'isrViewer.takeMinutes',
    errorReferences: 'isrViewer.errorReferences',
    successReferences: 'isrViewer.successReferences',
    updatingReferences: 'isrViewer.updatingReferences',
    firstPage: 'isrViewer.firstPage',
    previousPage: 'isrViewer.previousPage',
    nextPage: 'isrViewer.nextPage',
    lastPage: 'isrViewer.lastPage',
    errorFailoverParam: 'isrViewer.errorFailoverParam',
    errorFileBig: 'isrViewer.errorFileBig',
    errorManySelections: 'isrViewer.errorManySelections',
    documentsTable: {
      documents: 'isrViewer.documentsTable.documents',
      certifRegistryCheck: 'isrViewer.documentsTable.certifRegistryCheck',
      enginePropPowerCheck: 'isrViewer.documentsTable.enginePropPowerCheck',
      fishingLicenceChecked: 'isrViewer.documentsTable.fishingLicenceChecked',
      fishingAuthChecked: 'isrViewer.documentsTable.fishingAuthChecked',
      portLandAuthCheck: 'isrViewer.documentsTable.portLandAuthCheck',
      paperFishingLogbookChecked: 'isrViewer.documentsTable.paperFishingLogbookChecked',
      eFishingLogbookCheck: 'isrViewer.documentsTable.eFishingLogbookCheck',
      priorNotificationRefCheck: 'isrViewer.documentsTable.priorNotificationRefCheck',
      stowagePlanChecked: 'isrViewer.documentsTable.stowagePlanChecked',
      fishRoomCertificate: 'isrViewer.documentsTable.fishRoomCertificate',
      ullageTablesTankChecked: 'isrViewer.documentsTable.ullageTablesTankChecked',
      certifWeighSystemsCheck: 'isrViewer.documentsTable.certifWeighSystemsCheck',
      memberProducerOrganisation: 'isrViewer.documentsTable.memberProducerOrganisation',
      otherCatchDocumentation: 'isrViewer.documentsTable.otherCatchDocumentation',
      nSheets: 'isrViewer.documentsTable.nSheets',
      firstSheet: 'isrViewer.documentsTable.firstSheet',
      lastSheet: 'isrViewer.documentsTable.lastSheet',
      eLogbookReference: 'isrViewer.documentsTable.eLogbookReference',
      purposePriorNoti: 'isrViewer.documentsTable.purposePriorNoti',
    },
    catchProductsTable: {
      catchOnBoard: 'isrViewer.catchProductsTable.catchOnBoard',
      catchOffloaded: 'isrViewer.catchProductsTable.catchOffloaded',
      trxCatchesOthVessels: 'isrViewer.catchProductsTable.trxCatchesOthVessels',
      sepRegUndersized: 'isrViewer.catchProductsTable.sepRegUndersized',
      marginTolSpecies: 'isrViewer.catchProductsTable.marginTolSpecies',
      minReferenceSize: 'isrViewer.catchProductsTable.minReferenceSize',
      labelling: 'isrViewer.catchProductsTable.labelling',
      catchWeighing: 'isrViewer.catchProductsTable.catchWeighing',
      boxContainerCount: 'isrViewer.catchProductsTable.boxContainerCount',
      samplingOnDischarge: 'isrViewer.catchProductsTable.samplingOnDischarge',
      holdAfterDischarge: 'isrViewer.catchProductsTable.holdAfterDischarge',
      ullageTables: 'isrViewer.catchProductsTable.ullageTables',
      sampling: 'isrViewer.catchProductsTable.sampling',
      catchWeighOnLanding: 'isrViewer.catchProductsTable.catchWeighOnLanding',
      catch: 'isrViewer.catchProductsTable.catch',
      sepStowUndersized: 'isrViewer.catchProductsTable.sepStowUndersized',
      sepStowDemersal: 'isrViewer.catchProductsTable.sepStowDemersal',
      discardRecords: 'isrViewer.catchProductsTable.discardRecords',
    },
    ok: 'isrViewer.ok',
    notOk: 'isrViewer.notOk',
  },
  fishingActivities: {
    logbookViewer: {
      filters: {
        showFilters: 'fishingActivities.logbookViewer.filters.showFilters',
        hideFilters: 'fishingActivities.logbookViewer.filters.hideFilters',
        filterPlaceholder: 'fishingActivities.logbookViewer.filters.filterPlaceholder',
        noOptionsMessage: 'fishingActivities.logbookViewer.filters.noOptionsMessage',
        search: 'fishingActivities.logbookViewer.filters.search',
        favourite: 'fishingActivities.logbookViewer.filters.favourite',
        anyFavourites: 'fishingActivities.logbookViewer.filters.anyFavourites',
        removeFavourite: 'fishingActivities.logbookViewer.filters.removeFavourite',
        removeFavouriteConfirmation: 'fishingActivities.logbookViewer.filters.removeFavouriteConfirmation',
      },
      tripView: {
        tripId: 'fishingActivities.logbookViewer.tripView.tripId',
        flagState: 'fishingActivities.logbookViewer.tripView.flagState',
        name: 'fishingActivities.logbookViewer.tripView.name',
        cfr: 'fishingActivities.logbookViewer.tripView.cfr',
        uvi: 'fishingActivities.logbookViewer.tripView.uvi',
        ext: 'fishingActivities.logbookViewer.tripView.ext',
        ircs: 'fishingActivities.logbookViewer.tripView.ircs',
        iccat: 'fishingActivities.logbookViewer.tripView.iccat',
        gfcm: 'fishingActivities.logbookViewer.tripView.gfcm',
        startedOn: 'fishingActivities.logbookViewer.tripView.startedOn',
        departurePort: 'fishingActivities.logbookViewer.tripView.departurePort',
        lastActivity: 'fishingActivities.logbookViewer.tripView.lastActivity',
        type: 'fishingActivities.logbookViewer.tripView.type',
        lastActivityDate: 'fishingActivities.logbookViewer.tripView.lastActivityDate',
        lastActivityReceivedOn: 'fishingActivities.logbookViewer.tripView.lastActivityReceivedOn',
        onGoing: 'fishingActivities.logbookViewer.tripView.onGoing',
        daysAtSea: 'fishingActivities.logbookViewer.tripView.daysAtSea',
        includeDeletedCancelled: 'fishingActivities.logbookViewer.tripView.includeDeletedCancelled',
        activityDate: 'fishingActivities.logbookViewer.tripView.activityDate',
        activityType: 'fishingActivities.logbookViewer.tripView.activityType',
        listOfReportsForTripId: 'fishingActivities.logbookViewer.tripView.listOfReportsForTripId',
        reportReceivedOn: 'fishingActivities.logbookViewer.tripView.reportReceivedOn',
        reportType: 'fishingActivities.logbookViewer.tripView.reportType',
        tripView: 'fishingActivities.logbookViewer.tripView.tripView',
      },
      reportView: {
        activityDate: 'fishingActivities.logbookViewer.reportView.activityDate',
        activityType: 'fishingActivities.logbookViewer.reportView.activityType',
        cfr: 'fishingActivities.logbookViewer.reportView.cfr',
        ext: 'fishingActivities.logbookViewer.reportView.ext',
        flagState: 'fishingActivities.logbookViewer.reportView.flagState',
        gfcm: 'fishingActivities.logbookViewer.reportView.gfcm',
        iccat: 'fishingActivities.logbookViewer.reportView.iccat',
        ircs: 'fishingActivities.logbookViewer.reportView.ircs',
        name: 'fishingActivities.logbookViewer.reportView.name',
        receivedOn: 'fishingActivities.logbookViewer.reportView.receivedOn',
        reportType: 'fishingActivities.logbookViewer.reportView.reportType',
        reportView: 'fishingActivities.logbookViewer.reportView.reportView',
        tripId: 'fishingActivities.logbookViewer.reportView.tripId',
        uvi: 'fishingActivities.logbookViewer.reportView.uvi',
        version: 'fishingActivities.logbookViewer.reportView.version',
        daysAtSea: 'fishingActivities.logbookViewer.reportView.daysAtSea',
      },
      details: {
        area: 'fishingActivities.logbookViewer.details.area',
        catch: 'fishingActivities.logbookViewer.details.catch',
        characteristic: 'fishingActivities.logbookViewer.details.characteristic',
        code: 'fishingActivities.logbookViewer.details.code',
        contact: 'fishingActivities.logbookViewer.details.contact',
        country: 'fishingActivities.logbookViewer.details.country',
        departure: 'fishingActivities.logbookViewer.details.departure',
        description: 'fishingActivities.logbookViewer.details.description',
        effortZone: 'fishingActivities.logbookViewer.details.effortZone',
        ext: 'fishingActivities.logbookViewer.details.ext',
        extMark: 'fishingActivities.logbookViewer.details.extMark',
        faoArea: 'fishingActivities.logbookViewer.details.faoArea',
        farm: 'fishingActivities.logbookViewer.details.farm',
        fisheryType: 'fishingActivities.logbookViewer.details.fisheryType',
        fisheryTypeDescription: 'fishingActivities.logbookViewer.details.fisheryTypeDescription',
        fishingGear: 'fishingActivities.logbookViewer.details.fishingGear',
        gfcm: 'fishingActivities.logbookViewer.details.gfcm',
        iccat: 'fishingActivities.logbookViewer.details.iccat',
        ircs: 'fishingActivities.logbookViewer.details.ircs',
        latitude: 'fishingActivities.logbookViewer.details.latitude',
        location: 'fishingActivities.logbookViewer.details.location',
        longitude: 'fishingActivities.logbookViewer.details.longitude',
        managementArea: 'fishingActivities.logbookViewer.details.managementArea',
        measureMmt: 'fishingActivities.logbookViewer.details.measureMmt',
        measureMtr: 'fishingActivities.logbookViewer.details.measureMtr',
        name: 'fishingActivities.logbookViewer.details.name',
        ocurrence: 'fishingActivities.logbookViewer.details.ocurrence',
        originalVersion: 'fishingActivities.logbookViewer.details.originalVersion',
        port: 'fishingActivities.logbookViewer.details.port',
        position: 'fishingActivities.logbookViewer.details.position',
        quantity: 'fishingActivities.logbookViewer.details.quantity',
        reasonCode: 'fishingActivities.logbookViewer.details.reasonCode',
        reasonDescription: 'fishingActivities.logbookViewer.details.reasonDescription',
        rfmo: 'fishingActivities.logbookViewer.details.rfmo',
        role: 'fishingActivities.logbookViewer.details.role',
        seeContact: 'fishingActivities.logbookViewer.details.seeContact',
        sizeCategory: 'fishingActivities.logbookViewer.details.sizeCategory',
        sizeClass: 'fishingActivities.logbookViewer.details.sizeClass',
        species: 'fishingActivities.logbookViewer.details.species',
        speciesTargetFao: 'fishingActivities.logbookViewer.details.speciesTargetFao',
        speciesTargetGroup: 'fishingActivities.logbookViewer.details.speciesTargetGroup',
        statRectangle: 'fishingActivities.logbookViewer.details.statRectangle',
        territoryEez: 'fishingActivities.logbookViewer.details.territoryEez',
        trip: 'fishingActivities.logbookViewer.details.trip',
        type: 'fishingActivities.logbookViewer.details.type',
        typeCode: 'fishingActivities.logbookViewer.details.typeCode',
        typeDescription: 'fishingActivities.logbookViewer.details.typeDescription',
        uvi: 'fishingActivities.logbookViewer.details.uvi',
        cfr: 'fishingActivities.logbookViewer.details.cfr',
        vessel: 'fishingActivities.logbookViewer.details.vessel',
        vesselFlag: 'fishingActivities.logbookViewer.details.vesselFlag',
        vesselName: 'fishingActivities.logbookViewer.details.vesselName',
        weightKmg: 'fishingActivities.logbookViewer.details.weightKmg',
        weightMeans: 'fishingActivities.logbookViewer.details.weightMeans',
        value: 'fishingActivities.logbookViewer.details.value',
        discardReason: 'fishingActivities.logbookViewer.details.discardReason',
        affectedQuantity: 'fishingActivities.logbookViewer.details.affectedQuantity',
        recoveryMeasure: 'fishingActivities.logbookViewer.details.recoveryMeasure',
        characteristicsDescription: 'fishingActivities.logbookViewer.details.characteristicsDescription',
        characteristicsValue: 'fishingActivities.logbookViewer.details.characteristicsValue',
        sourceVesselStorage: 'fishingActivities.logbookViewer.details.sourceVesselStorage',
        identification: 'fishingActivities.logbookViewer.details.identification',
        destinationVesselStorage: 'fishingActivities.logbookViewer.details.destinationVesselStorage',
        start: 'fishingActivities.logbookViewer.details.start',
        end: 'fishingActivities.logbookViewer.details.end',
        duration: 'fishingActivities.logbookViewer.details.duration',
        subsections: {
          location: 'fishingActivities.logbookViewer.details.subsections.location',
          catch: 'fishingActivities.logbookViewer.details.subsections.catch',
          fishingGear: 'fishingActivities.logbookViewer.details.subsections.fishingGear',
          gearProblems: 'fishingActivities.logbookViewer.details.subsections.gearProblems',
          relatedVessels: 'fishingActivities.logbookViewer.details.subsections.relatedVessels',
          subActivities: 'fishingActivities.logbookViewer.details.subsections.subActivities',
          discardReasonDescription: 'fishingActivities.logbookViewer.details.subsections.discardReasonDescription',
          storage: 'fishingActivities.logbookViewer.details.subsections.storage',
          otherCharacteristics: 'fishingActivities.logbookViewer.details.subsections.otherCharacteristics',
        },
      },
      contactInfo: {
        address: 'fishingActivities.logbookViewer.contactInfo.address',
        alias: 'fishingActivities.logbookViewer.contactInfo.alias',
        blockName: 'fishingActivities.logbookViewer.contactInfo.blockName',
        buildingName: 'fishingActivities.logbookViewer.contactInfo.buildingName',
        cityName: 'fishingActivities.logbookViewer.contactInfo.cityName',
        citySubdivision: 'fishingActivities.logbookViewer.contactInfo.citySubdivision',
        contactInfo: 'fishingActivities.logbookViewer.contactInfo.contactInfo',
        country: 'fishingActivities.logbookViewer.contactInfo.country',
        countryDivision: 'fishingActivities.logbookViewer.contactInfo.countryDivision',
        familyNamePrefix: 'fishingActivities.logbookViewer.contactInfo.familyNamePrefix',
        gender: 'fishingActivities.logbookViewer.contactInfo.gender',
        givenName: 'fishingActivities.logbookViewer.contactInfo.givenName',
        middleName: 'fishingActivities.logbookViewer.contactInfo.middleName',
        nameSuffix: 'fishingActivities.logbookViewer.contactInfo.nameSuffix',
        person: 'fishingActivities.logbookViewer.contactInfo.person',
        plotIdentification: 'fishingActivities.logbookViewer.contactInfo.plotIdentification',
        postalArea: 'fishingActivities.logbookViewer.contactInfo.postalArea',
        postOfficeBox: 'fishingActivities.logbookViewer.contactInfo.postOfficeBox',
        role: 'fishingActivities.logbookViewer.contactInfo.role',
        streetName: 'fishingActivities.logbookViewer.contactInfo.streetName',
      },
    },
  },
};

export const toSchema = (obj, prev) => {
  const res = {};
  Object.keys(obj).forEach((key) => {
    const val = prev ? `${prev}.${key}` : key;
    res[key] = typeof obj[key] === 'object'
      ? toSchema(obj[key], val)
      : val;
  });
  return res;
};

export default tSchema;
