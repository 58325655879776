import PropTypes from 'prop-types';
import { any, prop, propEq } from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import getRoutePaths from '@pages/App/utils/routePaths';

import Section from '@components/Section';

import DataActivity from './DataActivity';
import ValidationReports from './ValidationReports';

import './style.scss';

const getSections = (t) => {
  const routePaths = getRoutePaths(t);
  return [
    {
      id: routePaths.dataAdministrationReporting.sections.VALIDATION_REPORTS.url,
      key: routePaths.dataAdministrationReporting.sections.VALIDATION_REPORTS.url,
      title: routePaths.dataAdministrationReporting.sections.VALIDATION_REPORTS.title,
      render: () => (
        <ValidationReports />
      ),
    },
    {
      id: routePaths.dataAdministrationReporting.sections.DATA_ACTIVITY.url,
      key: routePaths.dataAdministrationReporting.sections.DATA_ACTIVITY.url,
      title: routePaths.dataAdministrationReporting.sections.DATA_ACTIVITY.title,
      render: () => (
        <DataActivity />
      ),
    },
  ];
};

const ActivityReports = ({ title, path }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sectionId } = useParams();
  const sections = useMemo(() => getSections(t), []);

  const currentSection = sections.find((s) => s.id === sectionId);

  useEffect(() => {
    document.title = `${window.env.REACT_APP_TITLE} - ${title} ${
      currentSection ? currentSection.title : ''
    }`;
  }, [currentSection]);

  const changeSection = (section) => {
    history.push(`${path}/${section.id}`);
  };

  if (!any(propEq('id', sectionId), sections)) {
    return <Redirect to={`${path}/${sections[0].id}`} />;
  }

  return (
    <Container id="activity-reports-tabs" fluid>
      <Section
        tabTitleGetter={prop('title')}
        sections={sections}
        defaultSection={currentSection}
        className="main"
        onCurrentSectionChangeCallback={(section) => changeSection(section)}
      />
    </Container>
  );
};

ActivityReports.defaultProps = {};

ActivityReports.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ActivityReports;
