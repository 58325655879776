import { fullfilled, rejected, pending } from '../../../utils';
import { Actions, Status } from '../../actions/administration';

const fetchAllAttributesPending = (state) => ({
  ...state,
  isLoading: true,
});

const fetchAllAttributesRejected = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: true,
  errorMessage: payload,
});

const fetchAllAttributesFullfilled = (state, { payload }) => ({
  ...state,
  attributes: payload,
  isLoading: false,

});

const fetchAttribute = (state, { payload }) => ({
  ...state,
  attributeSelected: { ...payload },
  attributeOriginal: { ...payload },
});

const putAttributeFullFilled = (state) => ({
  ...state,
  responseModification: Status.OK,

});
const putAttributePending = (state) => ({
  ...state,
  responseModification: Status.PENDING,

});

const putAttributeRejected = (state) => ({
  ...state,
  responseModification: Status.REJECTED,

});

const postAttributeFullFilled = (state) => ({
  ...state,
  responseModification: Status.OK,
});
const postAttributePending = (state) => ({
  ...state,
  responseModification: Status.PENDING,
});

const postAttributeRejected = (state) => ({
  ...state,
  responseModification: Status.REJECTED,
});

const removeAttributeFullFilled = (state) => ({
  ...state,
  responseModification: Status.OK,
});
const removeAttributePending = (state) => ({
  ...state,
  responseModification: Status.PENDING,
});

const removeAttributeRejected = (state) => ({
  ...state,
  responseModification: Status.REJECTED,
});

const Attributes = {
  [fullfilled(Actions.FETCH_ALL_ATTRIBUTES)]: fetchAllAttributesFullfilled,
  [pending(Actions.FETCH_ALL_ATTRIBUTES)]: fetchAllAttributesPending,
  [rejected(Actions.FETCH_ALL_ATTRIBUTES)]: fetchAllAttributesRejected,

  [fullfilled(Actions.EDIT_ATTRIBUTE)]: putAttributeFullFilled,
  [pending(Actions.EDIT_ATTRIBUTE)]: putAttributePending,
  [rejected(Actions.EDIT_ATTRIBUTE)]: putAttributeRejected,

  [fullfilled(Actions.CREATE_ATTRIBUTE)]: postAttributeFullFilled,
  [pending(Actions.CREATE_ATTRIBUTE)]: postAttributePending,
  [rejected(Actions.CREATE_ATTRIBUTE)]: postAttributeRejected,

  [fullfilled(Actions.REMOVE_ATTRIBUTE)]: removeAttributeFullFilled,
  [pending(Actions.REMOVE_ATTRIBUTE)]: removeAttributePending,
  [rejected(Actions.REMOVE_ATTRIBUTE)]: removeAttributeRejected,

  [Actions.FETCH_ATTRIBUTE]: fetchAttribute,
};

export default Attributes;
