import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import InputCheck from '../../../../components/Form/Inputs/InputCheck';
import InputDate from '../../../../components/Form/Inputs/InputDate';
import InputNumber from '../../../../components/Form/Inputs/InputNumber';
import InputSelect from '../../../../components/Form/Inputs/InputSelect';
import InputText from '../../../../components/Form/Inputs/InputText';
import InputTextArea from '../../../../components/Form/Inputs/InputTextArea';
import {
  setIsValidVesselField,
  changeVesselInformation,
  changeComplexVesselInformation,
} from '../../../../services/vessel_record/actions/vessel';

import { INPUT_TYPES } from './constants';

const Visualization = (readOnly, visualization) => {
  switch (visualization) {
    case 'EDIT':
      return readOnly;

    case 'CREATE':
      return false;

    case 'VIEW':
      return true;

    default:
      return null;
  }
};

const orderArrayByGroup = (a, b) => {
  if (a.groupBy > b.groupBy) {
    return 1;
  }
  if (a.groupBy < b.groupBy) {
    return -1;
  }
  return 0;
};

const VesselInput = ({
  visualization,
  inputType,
  attribute,
  template,
  complex,
  defaultValue,
  maxLength,
  minLength,
  complexValue,
  complexName,
  complexIndex,
  isTextArea,
  minDate = null,
  maxDate = null,
  ...props
}) => {
  const { t } = useTranslation();
  const formDataId = attribute.name === 'code' || attribute.name === 'value' ? attribute.customProperties[0]?.value : attribute.customProperties.formData;

  const inputOptions = ({ formData, id }) => formData && formData[id]?.sort(orderArrayByGroup);

  if (formDataId && (inputType !== INPUT_TYPES.BOOLEAN)) {
    return (
      <InputSelect
        className="input-select"
        id={attribute.name}
        readOnly={Visualization(attribute.readOnly, visualization)}
        value={(complex ? complexValue[attribute.name] : attribute.value) || defaultValue}
        isValid={attribute.isValid}
        isInvalid={!attribute.isValid}
        showValidation={false}
        setIsValid={setIsValidVesselField}
        onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
        required={attribute.required}
        options={inputOptions({ formData: template.formData, id: formDataId })}
        complex={complex}
        complexName={complexName}
        index={complexIndex}
        disabledPlaceholder={t(tSchema.common.noOptionSelected)}
      />
    );
  }
  switch (inputType) {
    case INPUT_TYPES.STRING:
      if (!isTextArea) {
        return (
          <InputText
            className="input-text"
            id={attribute.name}
            readOnly={Visualization(attribute.readOnly, visualization)}
            value={(complex ? complexValue[attribute.name] : attribute.value) || defaultValue}
            isValid={attribute.isValid}
            setIsValid={setIsValidVesselField}
            onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
            required={attribute.required}
            maxLength={maxLength}
            minLength={minLength}
            complex={complex}
            complexName={complexName}
            index={complexIndex}
          />
        );
      }
      return (
        <InputTextArea
          className="input-text"
          id={attribute.name}
          readOnly={Visualization(attribute.readOnly, visualization)}
          value={(complex ? complexValue[attribute.name] : attribute.value) || defaultValue}
          isValid={attribute.isValid}
          setIsValid={setIsValidVesselField}
          onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
          required={attribute.required}
          maxLength={maxLength}
          minLength={minLength}
          complex={complex}
          complexName={complexName}
          index={complexIndex}
        />
      );
    case INPUT_TYPES.DATE:
      return (
        <InputDate
          className="input-date"
          id={attribute.name}
          readOnly={visualization === 'VIEW'}
          value={(complex ? complexValue[attribute.name] : attribute.value) || defaultValue}
          isValid={attribute.isValid}
          setIsValid={setIsValidVesselField}
          onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
          required={attribute.required}
          complex={complex}
          complexName={complexName}
          index={complexIndex}
          minDate={minDate}
          maxDate={maxDate}
          {...props}
        />
      );
    case INPUT_TYPES.NUMBER:
      return (
        <InputNumber
          className="input-number"
          id={attribute.name}
          readOnly={Visualization(attribute.readOnly, visualization)}
          value={(complex ? complexValue[attribute.name] : `${attribute.value}`) || defaultValue}
          isValid={attribute.isValid}
          setIsValid={setIsValidVesselField}
          onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
          required={attribute.required}
          complex={complex}
          complexName={complexName}
          index={complexIndex}
        />
      );
    case INPUT_TYPES.INTEGER:
      return (
        <InputNumber
          className="input-number"
          id={attribute.name}
          readOnly={Visualization(attribute.readOnly, visualization)}
          value={(complex ? complexValue[attribute.name] : `${attribute.value}`) || defaultValue}
          isValid={attribute.isValid}
          setIsValid={setIsValidVesselField}
          onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
          required={attribute.required}
          decimals={false}
          complex={complex}
          complexName={complexName}
          index={complexIndex}
          {...props}
        />
      );
    case INPUT_TYPES.BOOLEAN:
      return (
        <InputCheck
          className="input-checkbox"
          id={attribute.name}
          readOnly={Visualization(attribute.readOnly, visualization)}
          value={attribute.value || defaultValue}
          onChange={complex ? changeComplexVesselInformation : changeVesselInformation}
          required={attribute.required}
        />
      );
    default:
      return null;
  }
};

VesselInput.defaultProps = {
  complex: false,
  defaultValue: null,
  maxLength: null,
  minLength: 0,
  complexValue: null,
  complexName: null,
  complexIndex: null,
  isTextArea: false,
};
VesselInput.propTypes = {
  visualization: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  attribute: PropTypes.instanceOf(PropTypes.object).isRequired,
  template: PropTypes.instanceOf(PropTypes.object).isRequired,
  complex: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  complexValue: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  complexName: PropTypes.string,
  complexIndex: PropTypes.number,
  isTextArea: PropTypes.bool,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
};

export default VesselInput;
