/* eslint-disable import/order */

import { Actions } from '../../actions/referenceData';
import { BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';
/*
    Vessel's status  modification when the action
    fetchAllVessels is launched but didn't get response yet
*/
const fetchAllReferenceDataPending = (state) => ({
  ...state,
  isLoading: true,
});

/*
    Vessel's status modification when the action
    fetchAllVessels gets an error or doesn't get response
*/
const fetchAllReferenceDataRejected = (state, { payload }) => (
  {
    ...state,
    isLoading: false,
    error: true,
    errorMessage: payload,
  }
);

const fetchAllReferenceDataFullFilled = (state, { payload }) => (payload ? {
  ...state,
  originReferenceData: payload,
  referenceData: payload,
  isLoading: false,
} : { ...state });

const fetchReferenceAttributesByIdPending = (state) => ({
  ...state,
  isLoading: true,
});
const fetchReferenceAttributesByIdFullFilled = (state, { payload }) => ({
  ...state,
  referenceAttributes: payload.map((attribute) => {
    if (attribute.startDate && attribute.endDate) {
      return {
        ...attribute,
        startDate: formatDate(attribute.startDate, BACK_DATE_FORMAT, null, true),
        endDate: formatDate(attribute.endDate, BACK_DATE_FORMAT, null, true),
      };
    }
    if (attribute.startDate) {
      return {
        ...attribute,
        startDate: formatDate(attribute.startDate, BACK_DATE_FORMAT, null, true),
      };
    }
    if (attribute.endDate) {
      return {
        ...attribute,
        endDate: formatDate(attribute.endDate, BACK_DATE_FORMAT, null, true),
      };
    }
    return attribute;
  }),
  isLoading: false,
});

const updateReferenceDataPending = (state) => ({
  ...state,
  isLoading: true,
});

const updateReferenceDataFullFilled = (state, { payload }) => ({
  ...state,
  isLoading: false,
  updatedReferenceData: payload,
});
const updateReferenceDataRejected = (state) => ({
  ...state,
  isLoading: false,
});

const calculateMaxPages = (totalCount, pageSize) => {
  let maxPages = 0;
  if (totalCount < pageSize) {
    maxPages = 1;
  } else {
    maxPages = Math.trunc(totalCount / pageSize);
    if (totalCount % pageSize > 0) {
      maxPages += 1;
    }
  }
  return maxPages;
};

const setPagination = (state, { payload }) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page: payload.page,
    size: payload.size,
    orderBy: payload.orderBy,
    maxPages: calculateMaxPages(state.pagination.totalCount, payload.size),
  },
});

const createReferenceAttributePending = (state) => ({
  ...state,
  completedUpdateAttributes: false,
  isLoading: true,
});

const createReferenceAttributeRejected = (state, { payload }) => ({
  ...state,
  isLoading: false,
  completedUpdateAttributes: false,
  errorMessage: payload,
});

const createReferenceAttributeFullFilled = (state) => ({
  ...state,
  isLoading: false,
  completedUpdateAttributes: true,
});

const deleteReferenceAttributePending = (state) => ({
  ...state,
  isLoading: true,
  completedUpdateAttributes: false,
});

const deleteReferenceAttributeRejected = (state, { payload }) => ({
  ...state,
  isLoading: false,
  errorMessage: payload,
  completedUpdateAttributes: false,
});

const deleteReferenceAttributeFullFilled = (state) => ({
  ...state,
  isLoading: false,
  completedUpdateAttributes: true,
});

const updateReferenceAttributePending = (state) => ({
  ...state,
  completedUpdateAttributes: false,
  isLoading: true,
});

const updateReferenceAttributeRejected = (state, { payload }) => ({
  ...state,
  isLoading: false,
  completedUpdateAttributes: false,
  errorMessage: payload,
});

const updateReferenceAttributeFullFilled = (state) => ({
  ...state,
  isLoading: false,
  completedUpdateAttributes: true,
});

const clearReferenceAttributes = (state) => ({
  ...state,
  referenceAttributes: [],
});

const uploadReferenceAttributePending = (state) => ({
  ...state,
  uploadReference: {
    reports: null,
    isUploadingReference: true,
    error: null,
  },
});

const uploadReferenceAttributeRejected = (state, { payload }) => ({
  ...state,
  uploadReference: {
    reports: null,
    isUploadingReference: false,
    error: payload,
  },
});

const uploadReferenceAttributeFullFilled = (state, { payload }) => ({
  ...state,
  uploadReference: {
    reports: payload,
    isUploadingReference: false,
    error: null,
  },
});

const cleanUploadReferenceReports = (state) => ({
  ...state,
  uploadReference: {
    isUploadingReference: false,
    reports: null,
    error: null,
  },
});

const applyFilter = (state, { payload }) => {
  const { originReferenceData } = state;

  const filteredReferenceData = originReferenceData.filter(
    (entry) => entry.name.toLowerCase().includes(payload.filterValue?.toLowerCase()),
  );

  return {
    ...state,
    referenceData: filteredReferenceData,
    isLoading: false,
  };
};

const Crud = {
  [Actions.FETCH_ALL_REFERENCEDATA.REJECTED]: fetchAllReferenceDataRejected,
  [Actions.FETCH_ALL_REFERENCEDATA.PENDING]: fetchAllReferenceDataPending,
  [Actions.FETCH_ALL_REFERENCEDATA.FULFILLED]: fetchAllReferenceDataFullFilled,
  [Actions.FETCH_REFERENCE_ATTRIBUTES_BY_ID.PENDING]: fetchReferenceAttributesByIdPending,
  [Actions.FETCH_REFERENCE_ATTRIBUTES_BY_ID.FULFILLED]: fetchReferenceAttributesByIdFullFilled,
  [Actions.UPDATE_REFERENCE_DATA.FULFILLED]: updateReferenceDataFullFilled,
  [Actions.UPDATE_REFERENCE_DATA.PENDING]: updateReferenceDataPending,
  [Actions.UPDATE_REFERENCE_DATA.REJECTED]: updateReferenceDataRejected,
  [Actions.REFERENCE_SET_PAGINATION]: setPagination,
  [Actions.SET_FILTERS]: applyFilter,
  [Actions.CREATE_REFERENCE_ATTRIBUTE.REJECTED]: createReferenceAttributeRejected,
  [Actions.CREATE_REFERENCE_ATTRIBUTE.PENDING]: createReferenceAttributePending,
  [Actions.CREATE_REFERENCE_ATTRIBUTE.FULFILLED]: createReferenceAttributeFullFilled,
  [Actions.DELETE_REFERENCE_ATTRIBUTE.REJECTED]: deleteReferenceAttributeRejected,
  [Actions.DELETE_REFERENCE_ATTRIBUTE.PENDING]: deleteReferenceAttributePending,
  [Actions.DELETE_REFERENCE_ATTRIBUTE.FULFILLED]: deleteReferenceAttributeFullFilled,
  [Actions.UPDATE_REFERENCE_ATTRIBUTE.REJECTED]: updateReferenceAttributeRejected,
  [Actions.UPDATE_REFERENCE_ATTRIBUTE.PENDING]: updateReferenceAttributePending,
  [Actions.UPDATE_REFERENCE_ATTRIBUTE.FULFILLED]: updateReferenceAttributeFullFilled,
  [Actions.CLEAR_REFERENCE_ATTRIBUTES.FULFILLED]: clearReferenceAttributes,
  [Actions.UPLOAD_REFERENCE_ATTRIBUTE.REJECTED]: uploadReferenceAttributeRejected,
  [Actions.UPLOAD_REFERENCE_ATTRIBUTE.PENDING]: uploadReferenceAttributePending,
  [Actions.UPLOAD_REFERENCE_ATTRIBUTE.FULFILLED]: uploadReferenceAttributeFullFilled,
  [Actions.CLEAN_REFERENCE_UPLOAD_REPORTS]: cleanUploadReferenceReports,
};

export default Crud;
