import { update } from 'ramda';

import { Actions } from '../../actions/vessel';

/*
    Vessel's status modification when the action
    fetchEntityVessel is launched but didn't get response yet
*/

// eslint-disable-next-line no-unused-vars
const fetchEntityVessel = (state) => {
  const vesselEntity = [];
  const tabs = [];
  const vesselRegisterEntity = state.template.registerEntities?.filter((r) => r.name === 'vessel');
  let vesselAttributes = vesselRegisterEntity?.length > 0
  && vesselRegisterEntity[0].simpleAttributes;

  vesselAttributes = vesselAttributes && vesselAttributes.concat(vesselRegisterEntity?.length > 0
    && vesselRegisterEntity[0].complexAttributes);

  vesselAttributes = vesselAttributes && vesselAttributes.filter(
    (x) => Boolean(x.customProperties.tab),
  );

  vesselAttributes && vesselAttributes.forEach((attr) => {
    const attrModified = { ...attr, ...attr.customProperties };

    if (attrModified.name === 'reportedEvent' || attrModified.name === 'validityDate') {
      attrModified.readOnly = true;
    }

    // Si el tab no existe, lo crea y añade el atributo dentro
    if (!tabs.find((tab) => tab.name === attrModified.tab)) {
      vesselEntity.push(
        {
          tab: attrModified.tab,
          attributes:
          [{ ...attrModified, isValid: true }],
        },
      );
      const tab = { name: attrModified.tab, level: '' };
      tabs.push(tab);
    } else {
      vesselEntity.forEach((ve) => {
        if (ve.tab === attrModified.tab) {
          ve.attributes.push({ ...attrModified, isValid: true });
        }
      });
    }
  });

  return {
    ...state,
    vessel: { ...state.vessel, validityDate: new Date().toISOString().split('T')[0], reportedEvent: 'NEW' },
    entity: vesselEntity,
    lookups: state.template.lookupEntities,
    isLoading: false,
    tabs,
    vesselTemplate: vesselRegisterEntity && vesselRegisterEntity[0].simpleAttributes,
  };
};

/*
    Updates the vessel object with the information written on the form
*/
// eslint-disable-next-line no-unused-vars
const changeVesselInformationReducer = (state, { payload }) => {
  // state.vessel.find(v => v.name === payload.name).value = payload.value
  const entity = state.entity.map((e) => ({
    ...e,
    attributes: e.attributes.map((attr) => {
      if (attr.name === payload.name) {
        return { ...attr, value: payload.value };
      }
      return { ...attr };
    }),
  }));

  //  const { vessel } = state;
  //  vessel[payload.name] = payload.value;
  return {
    ...state,
    entity,
    vessel: { ...state.vessel, [payload.name]: payload.value },
    modified: true,
    validated: false,
  };
};

const changeComplexVesselInformationReducer = (state, { payload }) => {
  const entity = state.entity.map((e) => ({
    ...e,
    attributes: e.attributes.map((attr) => {
      if (attr.name === payload.name) {
        return {
          ...attr,
          value: [
            ...attr.value.slice(0, payload.index),
            { ...attr.value[payload.index], [payload.simpleName]: payload.value },
            ...attr.value.slice(payload.index + 1),
          ],
        };
      }
      return { ...attr };
    }),
  }));
  const { vessel } = state;
  vessel[payload.name] = [
    ...vessel[payload.name].slice(0, payload.index),
    { ...vessel[payload.name][payload.index], [payload.simpleName]: payload.value },
    ...vessel[payload.name].slice(payload.index + 1),
  ];
  return {
    ...state,
    entity,
    vessel,
    modified: true,
    validated: false,
  };
};

const changeComplexLocalReducer = (state, { payload }) => {
  const entity = state.entity.map((e) => ({
    ...e,
    attributes: e.attributes.map((attr) => {
      if (attr.name === payload.name) {
        return {
          ...attr,
          value: [
            ...attr.value.slice(0, payload.index),
            { ...attr.value[payload.index], [payload.simpleName]: payload.value },
            ...attr.value.slice(payload.index + 1),
          ],
        };
      }
      return { ...attr };
    }),
  }));
  return {
    ...state,
    entity,
  };
};

const addComplexRowReducer = (state, { payload }) => {
  const entity = state.entity.map((e) => ({
    ...e,
    attributes: e.attributes.map((attr) => {
      if (attr.name === payload.name) {
        if (Array.isArray(attr.value)) {
          return {
            ...attr,
            value: [
              ...attr.value,
              {},
            ],
          };
        }
        return {
          ...attr,
          value: [
            {},
          ],
        };
      }
      return { ...attr };
    }),
  }));
  const { vessel } = state;
  if (Array.isArray(vessel[payload.name])) {
    vessel[payload.name] = [
      ...vessel[payload.name],
      {},
    ];
  } else {
    vessel[payload.name] = [
      {},
    ];
  }
  return {
    ...state,
    entity,
    vessel,
  };
};

const removeComplexRowReducer = (state, { payload }) => {
  const entity = state.entity.map((e) => ({
    ...e,
    attributes: e.attributes.map((attr) => {
      if (attr.name === payload.name) {
        return {
          ...attr,
          value: [
            ...attr.value.slice(0, payload.index),
            ...attr.value.slice(payload.index + 1),
          ],
        };
      }
      return { ...attr };
    }),
  }));
  const { vessel } = state;
  vessel[payload.name] = [
    ...vessel[payload.name].slice(0, payload.index),
    ...vessel[payload.name].slice(payload.index + 1),
  ];
  return {
    ...state,
    entity,
    vessel,
    modified: true,
    validated: false,
  };
};

/*
    Updates the fields to not valid when they are required and are null or empty
*/
const checkMandatoryFields = (state) => {
  let isValidForm = true;
  const formatedVessel = [];
  let { vessel } = state.vessel;
  const entity = state.entity.map((tab) => ({
    ...tab,
    attributes: tab.attributes.map((attr) => {
      let isValid = true;
      vessel = { ...vessel, [attr.name]: attr.value };
      formatedVessel.push({
        name: attr.name,
        value: attr.value,
        type: attr.simpleDataType || 'String',
      });
      if (attr.required && (attr.value === null || attr.value === '')) {
        isValid = false;
        isValidForm = false;
      }
      return {
        ...attr,
        isValid,
        modified: true,
        validated: false,
      };
    }),

  }));
  const vesselformating = window.env.REACT_APP_ENVIRONMENT === 'mock' ? vessel : formatedVessel;

  return {
    ...state,
    entity,
    isValidForm,
    formatedVesselInfo: vesselformating,
  };
};

/*
    Updates the isValid property on the vessel field
*/
const setIsValidVesselField = (state, { payload }) => {
  const entity = state.entity.map((tab) => ({
    ...tab,
    attributes: tab.attributes.map((attr) => ({
      ...attr,
      isValid: attr.name === payload.field ? payload.value : attr.isValid,
    })),
  }));
  return {
    ...state,
    entity,
  };
};

const cleanVesselForm = (state) => ({
  ...state,
  entity: [],
  vessel: {},
  selectedVesselId: null,
  listDrools: [],
  previousVersion: null,
  validated: false,
  send: false,
  modified: false,
  isLoadingDrools: false,
  vesselHistoric: {
    content: [],
  },
});

const setVessel = (state, { payload }) => ({
  ...state,
  vessel: payload.vessel,
});

const validateDroolsPending = (state) => ({
  ...state,
  isLoadingDrools: true,
  validated: false,
});

const validateDroolsFulfilled = (state, { payload }) => {
  const tabs = [...state.tabs];
  const checkErrors = (tab, index) => {
    const { attributes } = state.entity[index];
    const attributesNames = attributes.map((x) => x.name);
    const errorTab = payload.filter((y) => attributesNames?.indexOf(y.referencedItem) > -1);
    if (errorTab.length !== 0) {
      if (errorTab.find((y) => y.resultState === 'ERROR') !== undefined) {
        return { ...tab, level: 'error' };
      }
      return { ...tab, level: 'warning' };
    }
    return { ...tab, level: null };
  };
  const tabsWithErrors = tabs.map((tab, index) => checkErrors(tab, index));
  return {
    ...state,
    tabs: tabsWithErrors,
    listDrools: payload,
    isLoadingDrools: false,
    modified: false,
    validated: true,
  };
};

const validateDroolsRejected = (state) => ({
  ...state,
  isLoadingDrools: false,
  validated: false,
});

const setSend = (state, { payload }) => ({
  ...state,
  send: payload.send,
  savingType: payload.type,
});

const uploadImagePending = (state) => ({
  ...state,
});

const uploadImageFulfilled = (state, { payload }) => ({
  ...state,
  image: payload,
});

const uploadImageRejected = (state) => ({
  ...state,
});

const assignImageReducer = (state, { payload }) => {
  const entity = state.entity.map((e) => (
    e.tab === 'VESSEL TECHNICAL DETAILS'
      ? {
        ...e,
        attributes: e.attributes.map((attr) => (
          attr.name === 'vesselImage'
            ? {
              ...attr,
              value: update(payload.index,
                {
                  ...attr.value[payload.index],
                  photo: {
                    id: state.image.id,
                    fileName: null,
                    mimeType: null,
                    size: 0,
                    acceptance: 'PENDING',
                  },
                  photoId: state.image.id,
                },
                attr.value),
            }
            : attr
        )),
      }
      : e));

  const { vessel } = { ...state };
  vessel.vesselImage = [
    ...vessel.vesselImage.slice(0, payload.index),
    {
      ...vessel.vesselImage[payload.index],
      photo: {
        id: state.image.id,
        fileName: null,
        mimeType: null,
        size: 0,
        acceptance: 'PENDING',
      },
      photoId: state.image.id,
    },
    ...vessel.vesselImage.slice(payload.index + 1),
  ];

  return {
    ...state,
    entity,
    vessel,
    image: '',
  };
};

const Form = {
  [Actions.FETCH_ENTITY_VESSEL]: fetchEntityVessel,
  [Actions.CHANGE_VESSEL_INFORMATION]: changeVesselInformationReducer,
  [Actions.CHANGE_COMPLEX_VESSEL_INFORMATION]: changeComplexVesselInformationReducer,
  [Actions.CHANGE_COMPLEX_LOCAL]: changeComplexLocalReducer,
  [Actions.CHECK_MANDATORY_FIELDS]: checkMandatoryFields,
  [Actions.SET_IS_VALID_FIELD_FORM]: setIsValidVesselField,
  [Actions.CLEAN_VESSEL_FORM]: cleanVesselForm,
  [Actions.SET_VESSEL]: setVessel,
  [Actions.ADD_COMPLEX_ROW]: addComplexRowReducer,
  [Actions.REMOVE_COMPLEX_ROW]: removeComplexRowReducer,
  [Actions.VALIDATE_BUSINESS_RULES.PENDING]: validateDroolsPending,
  [Actions.VALIDATE_BUSINESS_RULES.FULFILLED]: validateDroolsFulfilled,
  [Actions.VALIDATE_BUSINESS_RULES.REJECTED]: validateDroolsRejected,
  [Actions.SET_SEND]: setSend,
  [Actions.UPLOAD_IMAGE.PENDING]: uploadImagePending,
  [Actions.UPLOAD_IMAGE.FULFILLED]: uploadImageFulfilled,
  [Actions.UPLOAD_IMAGE.REJECTED]: uploadImageRejected,
  [Actions.ASSIGN_IMAGE]: assignImageReducer,
};

export default Form;
