import {
  formatSortParams,
  get, post, put, del, downloadFile,
} from '../utils';

import { currentISO8601Date } from '@common/dateUtils';
import { getFileWithMessage } from '@common/utilities/Notification';

const baseURLquery = 'subscription/query/';
const baseURLvesselquery = 'subscription/vessel/';
const baseURLreport = 'subscription/report/';

export function apiFetchQueries(isReport, isVessel) {
  let url;
  if (isReport) {
    url = baseURLreport;
  } else if (isVessel) {
    url = baseURLvesselquery;
  } else {
    url = baseURLquery;
  }
  return (searchFilters, params) => {
    const filter = searchFilters ? `/${searchFilters}` : '';
    return get()(`${url}getAll${filter}`, formatSortParams(params));
  };
}

export const apiExecuteQuerySubscription = (id) => post()(`${baseURLquery}execute/${id}`);

export const apiExecuteVesselQuerySubscription = (id) => post()(`${baseURLvesselquery}execute/${id}`);

export const apiExecuteReportSubscription = (id) => post()(`${baseURLreport}execute/${id}`);

export const apiDeleteQuerySubscription = (id) => del()(`${baseURLquery}delete/${id}`);

export const apiDeleteVesselQuerySubscription = (id) => del()(`${baseURLvesselquery}delete/${id}`);

export const apiDeleteReportSubscription = (id) => del()(`${baseURLreport}delete/${id}`);

export const apiFetchQuerySubscription = (id) => get()(`${baseURLquery}get/${id}`);

export const apiFetchVesselQuerySubscription = (id) => get()(`${baseURLvesselquery}get/${id}`);

export const apiFetchReportSubscription = (id) => get()(`${baseURLreport}get/${id}`);

export const apiDownloadTableData = (searchFilters, format, type, isVessel) => {
  const filter = searchFilters ? `?${searchFilters}` : '';
  let url;
  if (type === 'query') {
    if (isVessel) { url = baseURLvesselquery; } else { url = baseURLquery; }
  } else {
    url = baseURLreport;
  }
  if (format === 'csv') return get()(`${url}export/${format}/${filter}`);
  return getFileWithMessage({
    promise: downloadFile()(`${url}exportExcel/${filter}`),
    errorMessage: 'Error exporting the vessel Group',
    fileName: `br_${currentISO8601Date()}`,
  });
};

export const apiSaveQuery = (request) => (request.id
  ? put()(`${baseURLquery}update`, request)
  : post()(`${baseURLquery}create`, request));

export const apiSaveReport = (request) => (request.id
  ? put()(`${baseURLreport}update`, request)
  : post()(`${baseURLreport}create`, request));

export const apiSaveVesselQuery = (request) => (request.id
  ? put()(`${baseURLvesselquery}update`, request)
  : post()(`${baseURLvesselquery}create`, request));

export const apiFetchQueriesOptions = (optionObj) => {
  const { option, entity = 'subscription', optionsPath = 'options/' } = optionObj;
  // Harcoded for now
  if (option === 'vesselStatus') {
    return new Promise((resolve) => {
      resolve(({
        ok: true,
        json: () => [
          { id: 1, description: 'Active' },
          { id: 2, description: 'All' },
        ],
      }));
    });
  }
  return get()(`${entity}/${optionsPath}${option}`);
};
