import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import getUserManualConfig from '@components/UserManual/userManualConfig';

import './style.scss';

import UserManualModal from './UserManualModal';

/**
 * A component to show the breadcrumbs based on the navigator route
 */
const UserManual = ({ domain }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const section = getUserManualConfig(t, domain).find(
    ({ url, currDomain }) => url === location.pathname
    && (currDomain === domain || currDomain == null),
  );
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div id="user-manual">
        {section && (
          <OverlayTrigger
            placement="left"
            overlay={(props) => (
              <Tooltip id="user-manual-tooltip" {...props}>
                Help -
                {' '}
                {section.title}
              </Tooltip>
            )}
          >
            <Button
              variant="primary"
              className="user-manual-button"
              onClick={() => setShowModal(true)}
            >
              ?
            </Button>
          </OverlayTrigger>
        )}
        <UserManualModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          section={section}
        />
      </div>
    </>
  );
};

UserManual.defaultProps = {
  domain: null,
};

UserManual.propTypes = {
  domain: PropTypes.objectOf(PropTypes.any),
};

export default UserManual;
