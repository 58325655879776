import actions from './img/actions.png';
import gridList from './img/table.png';

const SectionReferenceData = () => (
  <div className="SectionReferenceData">
    <p>
      The results grid displays a list of all the Reference Data available in the system.
    </p>
    <p>
      <img src={gridList} alt="Reference Data list" />
    </p>
    <p>
      Each Reference Data can be edited, or viewed in detail.
      To do so, the user shall select the desired option from the list
      provided when clicking the button in the column &quot;Actions&quot;.
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
  </div>
);

export default SectionReferenceData;
