import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { JOIN_FISHING_OPERATION_PROPTYPES, JOIN_FISHING_OPERATION_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const JoinFishingOperation = ({ joinFishingOperations, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentJoinFishingOperations, setCurrentJoinFishingOperations] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(JOIN_FISHING_OPERATION_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => setCurrentJoinFishingOperations((
    joinFishingOperations && joinFishingOperations[pageNumber - 1])
    || JOIN_FISHING_OPERATION_SCHEMA),
  [joinFishingOperations, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        versions={currentJoinFishingOperations}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={joinFishingOperations}
        onSelectPagination={setPageNumber}
      />
      <Row>
        <Col md={3} sm={4} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField label={t('Vessel Related Activity Code')} value={currentVersion?.relatedVesselActivity} />
          <TextInputField label={t('Vessel Related Activity Description')} value={currentVersion?.relatedVesselActivityDescription} />
          <TextInputField label={t('JFO Identification')} value={currentVersion?.identification} />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <TextInputField
            label={t(schema.start)}
            value={currentVersion?.startDate && `${formatDateTime(currentVersion?.startDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField
            label={t(schema.end)}
            value={currentVersion?.endDate && `${formatDateTime(currentVersion?.endDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <TextInputField label={t(schema.duration)} value={currentVersion?.duration} />
          <TextInputField label={t('Fishery Type Code')} value={currentVersion?.fisheryType} />
          <TextInputField label={t('Fishery Type Description')} value={currentVersion?.fisheryTypeDescription} />
        </Col>
        <Col md={3} sm={4} xs={12}>
          <TextInputField label={t('Operations')} value={currentVersion?.operations} />
          <TextInputField label={t('Species Target (FAO)')} value={currentVersion?.speciesTarget} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

JoinFishingOperation.propTypes = {
  joinFishingOperations: JOIN_FISHING_OPERATION_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default JoinFishingOperation;
