import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const InputCheck = ({
  id,
  value,
  readOnly,
  onChange,
  isValid,
  setIsValid,
  required,
}) => {
  const dispatch = useDispatch();
  return (
    <div style={{}}>
      <Form.Check
        id={id}
        type="checkbox"
        disabled={readOnly}
        defaultChecked={value === 'Y'}
        onChange={(e) => {
          dispatch(onChange(id, e.target.checked ? 'Y' : 'N'));
          if (required && !isValid && e.target.checked) {
            dispatch(setIsValid(id, true));
          }
        }}
        style={{
          zoom: '1.5',
          border: isValid ? '' : '0.125rem solid red',
          marginLeft: '0.313rem',
        }}
      />
    </div>
  );
};

InputCheck.defaultProps = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
};

InputCheck.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
};

export default InputCheck;
