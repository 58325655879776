import {
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';

export const configureTable = (isIncoming) => [
  {
    text: 'BR code',
    code: 'brCode',
    sortable: true,
    render: (row) => `${row.brCode.split('_')[0]}`,
  },
  {
    text: isIncoming ? 'Incoming BR status' : 'Response Status',
    code: 'warning',
    sortable: true,
    render: (row) => (
      row.warning ? (<span className="dot-warning" />) : <span className="dot-error" />
    ),
  },
  {
    text: 'BR message',
    code: 'brMessage',
    sortable: true,
  },
  {
    text: 'UUID',
    code: 'uuid',
    sortable: true,
    render: (row) => (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="uuid-tooltip">{row.uuid ? row.uuid : '-'}</Tooltip>}
      >
        <p className="limit-text" style={{ margin: 0 }}>{row.uuid ? row.uuid : '-'}</p>
      </OverlayTrigger>
    ),
  },
  {
    text: 'XPATH',
    code: 'xpath',
    sortable: true,
    render: (row) => (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="xpath-tooltip">{row.xpath ? row.xpath : 'N/A'}</Tooltip>}
      >
        <p className="limit-text" style={{ margin: 0 }}>{row.xpath ? row.xpath : 'N/A'}</p>
      </OverlayTrigger>
    ),
  }];

export default configureTable;
