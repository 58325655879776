import tSchema from '@lang/schema';

export const getTableConfig = (t) => [
  {
    text: t(tSchema.dmaAdmin.referenceDataMGMT.name),
    code: 'name',
    sortable: true,
  },
  {
    text: t(tSchema.dmaAdmin.referenceDataMGMT.description),
    code: 'description',
    sortable: true,
  },
];

export const orderByModal = {
  field: 'code',
  order: 'asc',
};

export const getFiltersList = (t) => [
  {
    code: 'name',
    label: t(tSchema.dmaAdmin.referenceDataMGMT.name),
    type: 'text',
  },
];
export const searchOptions = [
  { label: 'contains', value: '==' },
];
