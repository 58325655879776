import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row } from 'react-bootstrap';

import FieldCheckBoxWrapper from '../FieldCheckBoxWrapper/FieldCheckBoxWrapper';

import './style.scss';

const Quantity = ({
  label, onChange: handleChange, values, name, checked, onFieldChecked: handleFieldChecked,
}) => (
  <FieldCheckBoxWrapper checked={checked} name={name} onFieldChecked={handleFieldChecked}>
    <Row className="mx-0">
      <Form.Label className="pl-n1 m-0">{label}</Form.Label>
    </Row>
    <Row className="mx-0 ml-5">
      <Form.Group as={Row} className="d-flex align-items-center m-0 my-2 justify-content-between flex-grow-1" controlId={`${name}-from`}>
        <Form.Label>From</Form.Label>
        <Form.Control
          required
          type="text"
          name={`${name}.from`}
          className="side-filters-control quantity-control"
          onChange={handleChange}
          value={values[name].from}
        />
      </Form.Group>
      <Form.Group as={Row} className="d-flex align-items-center m-0 my-2 justify-content-between flex-grow-1" controlId={`${name}-to`}>
        <Form.Label>To</Form.Label>
        <Form.Control
          required
          type="text"
          name={`${name}.to`}
          className="side-filters-control quantity-control"
          onChange={handleChange}
          value={values[name].to}
        />
      </Form.Group>
    </Row>
  </FieldCheckBoxWrapper>
);

Quantity.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFieldChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

export default Quantity;
