import { Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Modal, Row, Col, Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import BasicTable from '@pages/FishingTripsLogbook/components/ReportDetailsSection/components/tables/BasicTable';

import tSchema from '@lang/schema';

import { configureTable } from './config';

import { formatDateTime } from '@common/dateUtils';

import './style.scss';

const TabPanel = ({
  children, value, index, ...other
}) => (
  <div
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
    <Box>
      <Typography component="span">{children}</Typography>
    </Box>
    )}
  </div>
);
TabPanel.defaultProps = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};
TabPanel.propTypes = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};

function ModalComponent({
  show, setShow, message,
}) {
  const { t } = useTranslation();
  const SPECIFIC_VALUE = 'EFCA';
  const STANDARD_VALUE = 'EU';
  const SPECIFIC = 'Specific';
  const STANDARD = 'Standard';

  const handleClose = () => setShow(false);
  const { isIncoming, validationDetails } = message;
  const headers = configureTable(isIncoming);
  const [selectedTab, setSelectedTab] = useState(0);

  const specificBRs = validationDetails
  && validationDetails.filter((br) => br.brOwner === SPECIFIC_VALUE);
  const standardBRs = validationDetails
  && validationDetails.filter((br) => br.brOwner === STANDARD_VALUE);

  const tabs = [STANDARD, SPECIFIC];
  const switchTabs = (tab) => {
    switch (tab) {
      case SPECIFIC:
        return (
          <Container className="data-table message-detail-table">
            <Row>
              <BasicTable
                tableId="specific-brs"
                headers={headers}
                data={specificBRs}
                idColumn="brCode"
                margin={false}
              />
            </Row>
          </Container>
        );
      case STANDARD:
        return (
          <Container className="data-table message-detail-table">
            <Row>
              <BasicTable
                tableId="standard-brs"
                headers={headers}
                data={standardBRs}
                idColumn="brCode"
                margin={false}
              />
            </Row>
          </Container>

        );
      default:
        return null;
    }
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        animation={false}
        onHide={handleClose}
        centered
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            { isIncoming ? 'Incoming Msg ID' : 'Outgoing Msg ID'}
            {` | ${message.identification} | `}
            { isIncoming ? 'BR Validation details' : 'BR reported by recipient'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container id="traffic-monitoring-brs" className="w-100 text-center">
            <Row>
              <Col>
                <Row>
                  <p className="value mr-1">{t(tSchema.dem.trafficMonitoring.sender)}</p>
                  {message?.creatingParty || message?.sender}
                </Row>
                <Row>
                  <p className="value mr-1">{t(tSchema.dem.trafficMonitoring.dataDomain)}</p>
                  {message?.domain || message?.dataDomain?.code}
                </Row>
                <Row>
                  <p className="value mr-1">{t(tSchema.dem.trafficMonitoring.date)}</p>
                  {formatDateTime(message.creationDate, 'DD/MM/YYYY HH:mm:ss', true)}
                </Row>
              </Col>
              <Col>
                <Row>
                  <p className="value mr-1">{t(tSchema.dem.trafficMonitoring.recipient)}</p>
                  {' '}
                  {message?.recipient}
                </Row>
                <Row>
                  <p className="value mr-1">{t(tSchema.dem.trafficMonitoring.type)}</p>
                  {' '}
                  {message?.typeMessage || message?.type?.description}
                </Row>
              </Col>
            </Row>
          </Container>
          <Tabs
            className="vessel"
            variant="fullWidth"
            value={selectedTab}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {tabs && tabs?.map((r) => (
              <Tab
                key={r}
                label={<span>{r}</span>}
              />
            ))}
          </Tabs>
          {selectedTab != null && tabs.map((tab, indexTab) => (
            <TabPanel value={selectedTab} index={indexTab}>
              {switchTabs(tab)}
            </TabPanel>
          ))}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {t(tSchema.common.close)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  message: PropTypes.shape({
    creatingParty: PropTypes.string,
    domain: PropTypes.string,
    typeMessage: PropTypes.string,
    creationDate: PropTypes.string,
    dataDomain: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      code: PropTypes.string,
    }),
    id: PropTypes.number,
    idType: PropTypes.number,
    identification: PropTypes.string,
    isIncoming: PropTypes.bool,
    message: PropTypes.string,
    recipient: PropTypes.string,
    relatedMessages: PropTypes.arrayOf(PropTypes.any),
    reportStatus: null,
    responseStatus: null,
    sender: PropTypes.string,
    status: null,
    type: PropTypes.shape({ id: PropTypes.number, description: PropTypes.string }),
    uuid: PropTypes.string,
    validationResults: PropTypes.string,
    validationDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,

};

export default ModalComponent;
