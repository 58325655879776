import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import messagesListIncomingISRImg from './img/messages-list-incoming-isr.png';
import messagesListIncomingImg from './img/messages-list-incoming.png';

import { DOMAIN } from '@common/utils';

const SectionMessagesList = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="subSection">
      <p>
        The section on the left of the central panel displays a list
        where the user can see all the messages.
        Last received messages are shown first.
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messagesListIncomingImg} alt="messagesListIncoming" />
          : <img src={messagesListIncomingISRImg} alt="messagesListIncomingISR" />}
      </p>
      <p>
        More messages are shown when user scrolls down the list.
      </p>
      <p>
        User can see the total number of results at the bottom of the list.
      </p>
    </div>
  );
};

export default SectionMessagesList;
