import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';
import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { STORAGE_PROPTYPES } from '../../constants';

const schema = tSchema.fishingActivities.logbookViewer.details;

const Storage = ({ sourceVesselStorage, destinationVesselStorage }) => {
  const { t } = useTranslation();
  return (
    <Container fluid id="report-details-related-vessel">
      <Row>
        {sourceVesselStorage && (
        <Col md={4} sm={6} xs={12}>
          <FieldGroup label={t(schema.sourceVesselStorage)}>
            <TextInputField
              label={t(schema.type)}
              value={sourceVesselStorage?.type}
            />
            <TextInputField
              label={t(schema.identification)}
              value={sourceVesselStorage?.identification}
            />
          </FieldGroup>
        </Col>
        )}
        {destinationVesselStorage && (
        <Col md={4} sm={6} xs={12}>
          <FieldGroup label={t(schema.destinationVesselStorage)}>
            <TextInputField
              label={t(schema.type)}
              value={destinationVesselStorage?.type}
            />
            <TextInputField
              label={t(schema.identification)}
              value={destinationVesselStorage?.identification}
            />
          </FieldGroup>
        </Col>
        )}
      </Row>
    </Container>
  );
};

Storage.propTypes = {
  sourceVesselStorage: STORAGE_PROPTYPES.isRequired,
  destinationVesselStorage: STORAGE_PROPTYPES.isRequired,
};

export default Storage;
