/* eslint-disable import/order */

import { prop } from 'ramda';
import { Badge } from 'react-bootstrap';
import { formatDate, formatDateTime, fromUTCToLocalDate } from '@common/dateUtils';
import { formatSelectOption } from '@common/utils';
import tSchema from '@lang/schema';

const statusBadge = (status) => (status ? <Badge className="status" variant={status === 1 ? 'success' : 'danger'} pill /> : '');
const schema = tSchema.dmaAdmin.queryReportMGMT.query;

export const getTableConfig = (isVesselQuery, t) => [
  {
    text: t(schema.name),
    code: 'name',
    sortable: true,
  },
  {
    text: t(schema.startDate),
    code: 'startingDate',
    sortable: true,
    render: (row) => (formatDate(row.schedulingOptions?.startDate ? fromUTCToLocalDate(
      row.schedulingOptions?.startDate, row.schedulingOptions?.executionTime,
    ) : row.schedulingOptions?.startDate, undefined, undefined, true)),
  },
  {
    text: t(schema.endDate),
    code: 'endDate',
    sortable: true,
    render: (row) => (formatDate(row.schedulingOptions?.endDate ? fromUTCToLocalDate(
      row.schedulingOptions?.endDate, row.schedulingOptions?.executionTime,
    ) : row.schedulingOptions?.endDate, undefined, undefined, true)),
  },

  {
    text: t(schema.receiver),
    code: 'receiver',
    sortable: !isVesselQuery,
    render: (row) => (
      row.receiver
      || (isVesselQuery && t(tSchema.dmaAdmin.queryReportMGMT.query.commission))
    ),
  },
  {
    text: t(schema.schedule),
    code: 'recurrence',
    sortable: true,
    render: (row) => (row.schedulingOptions?.recurrence?.description || row.type?.description || '-'),
  },
  {
    text: t(schema.consolidated),
    code: 'consolidated',
    sortable: true,
    render: (row) => (row.consolidated ? 'Y' : 'N'),
  },
  {
    text: t(schema.active),
    code: 'status',
    sortable: true,
    render: (row) => statusBadge(row.schedulingOptions?.status?.id),
  },
  {
    text: t(schema.lastExecute),
    code: 'lastExecute',
    sortable: true,
    render: (row) => (formatDateTime(row.lastExecute) !== '-' ? `${formatDateTime(row.lastExecute)}, UTC` : '-'),
  },
];

export const configureFilters = (options, defaultValues, isVesselQuery, t) => {
  const filters = [
    {
      code: 'name',
      label: t(schema.name),
      type: 'text',
    },
    {
      code: 'receiver',
      label: t(schema.receiver),
      type: 'select',
      idProp: 'code',
      options: prop('fluxParties')(options).map((option) => formatSelectOption(option, 'code', 'name')),
      defaultValue: prop('fluxParties')(defaultValues),
    },
    {
      code: 'subscriptionType',
      label: t(schema.subscriptionType),
      type: 'select',
      options: prop('type')(options),
      defaultValue: prop('type')(defaultValues),
    },
  ];

  if (isVesselQuery) {
    filters.filter((filter) => filter.code !== 'receiver');
  }

  return filters;
};

export const searchOptions = [
  { label: 'contains', value: '=-', type: 'text' },
  { label: 'equal', value: '==' },
  { label: 'not contains', value: '!-', type: 'text' },
  { label: 'begins with', value: '*=', type: 'text' },
  { label: 'ends with', value: '=*', type: 'text' },
  {
    label: 'is null', value: '=null', type: 'text', single: true,
  },
  { label: 'before', value: '<', type: 'date' },
  { label: 'after', value: '>', type: 'date' },
  { label: 'not equals', value: '!-', type: 'complex-select' },
  { label: 'contains', value: '=-', type: 'complex-select' },
];

export const valueOptions = [
  { option: 'fluxParties', entity: 'businessrules' },
  { option: 'vesselGroups', entity: 'logbook' },
  { option: 'vesselFlags', entity: 'logbook' },
  { option: 'trips', entity: 'logbook' },
  { option: 'type' },
  { option: 'status' },
  { option: 'vesselStatus' },
  { option: 'queryType' },
  { option: 'recurrence' },
  { option: 'vesselIdType' },
  { option: 'vesselQueryType' },
];
