/* eslint-disable linebreak-style */
import deleteImage from './img/delete.png';

const SectionDeleteReportAcceptance = () => (
  <div className="SectionDeleteAcceptance">
    <p>
      To delete an acceptance report,
      in the results grid the user must click on the &quot;Actions&quot; button corresponding
      to the row of that specific acceptance rule, and then click on &quot;Delete&quot; button.
    </p>
    <p>
      A modal asks for confirmation on the removal.
    </p>
    <p>
      <img src={deleteImage} alt="Delete Acceptance" />
    </p>
    <p>
      On confirming the deletion,
      the report acceptance is automatically removed from the
      list displayed in the results grid.
    </p>
  </div>
);

export default SectionDeleteReportAcceptance;
