import tSchema from '@lang/schema';
import './style.scss';

const schema = tSchema.vesselMGMT;

export const getTableConfig = (t) => [
  { text: t(schema.cfr), code: 'cfr', sortable: true },
  { text: t(schema.uvi), code: 'uvi', sortable: true },
  { text: t(schema.externalMarking), code: 'externalMarking', sortable: true },
  { text: t(schema.name), code: 'name', sortable: true },
  { text: t(schema.countryOfRegistration), code: 'countryOfRegistration', sortable: true },
  { text: t(schema.ircs), code: 'ircs', sortable: true },
  { text: t(schema.powerOfMainEngine), code: 'powerOfMainEngine', sortable: true },
  { text: t(schema.loa), code: 'loa', sortable: true },
  { text: t(schema.tonnageGt), code: 'tonnageGT', sortable: true },
  { text: t(schema.vesselType), code: 'vesselType', sortable: true },
  { text: t(schema.reportedEvent), code: 'reportedEvent', sortable: true },
  { text: t(schema.validityDate), code: 'validityDate', sortable: true },
];

export function filterSearchAttributes(searchableAttributes) {
  const filtersConfig = [{ code: 'any', label: 'Vessel Identifier', type: 'text' },
    { code: 'inactive', label: 'Include inactive', type: 'boolean' }];

  searchableAttributes.forEach((elem) => {
    const element = elem;
    if (element.simpleDataType === 'Date') {
      element.simpleDataType = 'date_range';
    }
    filtersConfig.push(
      {
        code: element.name,
        label: element.displayName,
        type: element.simpleDataType.toLowerCase(),
      },
    );
  });

  return filtersConfig;
}

export const vesselsValueOptions = [
  'getRefData',
];

export const vesselsValueOptionsx = [
  'countries',
];
