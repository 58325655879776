import { dispatchPromiseWithMessage } from '../../../../common/utilities/Notification';
import {
  actionCreator, get, post, put, del,
} from '../../../utils';

const createActionType = actionCreator('CRUD');

const TypeActionsCrud = {
  FETCH_ALL_REFERENCEDATA: createActionType('FETCH_ALL_REFERENCEDATA'),
  UPDATE_REFERENCE_DATA: createActionType('UPDATE_REFERENCE_DATA'),
  FETCH_REFERENCE_ATTRIBUTES_BY_ID: createActionType('FETCH_REFERENCE_ATTRIBUTES_BY_ID'),
  REFERENCE_SET_PAGINATION: 'REFERENCE_SET_PAGINATION',
  SET_FILTERS: 'SET_FILTERS',
  CREATE_REFERENCE_ATTRIBUTE: createActionType('CREATE_REFERENCE_ATTRIBUTE'),
  DELETE_REFERENCE_ATTRIBUTE: createActionType('DELETE_REFERENCE_ATTRIBUTE'),
  UPDATE_REFERENCE_ATTRIBUTE: createActionType('UPDATE_REFERENCE_ATTRIBUTE'),
  CLEAR_REFERENCE_ATTRIBUTES: 'CLEAR_REFERENCE_ATTRIBUTES',
  UPLOAD_REFERENCE_ATTRIBUTE: createActionType('UPLOAD_REFERENCE_ATTRIBUTE'),
  CLEAN_REFERENCE_UPLOAD_REPORTS: 'CLEAN_REFERENCE_UPLOAD_REPORTS',
};

/*
  Formate Plural
*/
const formatePlural = (formData) => {
  if (formData.slice(-1) === 'y') {
    return `${formData.slice(0, -1)}ies`;
  }
  return `${formData}${formData.slice(-1) !== 's' ? 's' : ''}`;
};

/*
Formate Form Data
Take out the spaces and the capital letter at the beggining
*/
const formateFormData = (formData) => formatePlural(formData.charAt(0).toLowerCase() + formData.slice(1)).replace(/\s/g, '');

/*
  Formate Sentence
*/
const formateSentence = (text) => `${text.charAt(0).toUpperCase()}${text.slice(1).replace(/([A-Z])/g, ' $1')}`;

/*
    FETCH ALL REFERENCE DATA
    Calls the endpoint that returns all the reference data
*/
const fetchAllReferenceData = () => dispatchPromiseWithMessage({
  type: TypeActionsCrud.FETCH_ALL_REFERENCEDATA,
  promise: get()('faService/formData/referenceDatas'),
  errorMessage: 'Error loading the reference data',
});

/*
  FETCH REFERENCE DATA BY ID
  Calls the endpoint that returns the reference attributes from the
  reference data requested
*/
const fetchReferenceAttributesById = (id) => dispatchPromiseWithMessage({
  type: TypeActionsCrud.FETCH_REFERENCE_ATTRIBUTES_BY_ID,
  promise: get()(`faService/formData/${formateFormData(id)}`),
  errorMessage: 'Error loading the reference data requested',
});

/*
  UPDATE REFERENCE DATA
  Update a reference data based on its code
*/
const updateReferenceData = (referenceData) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.UPDATE_REFERENCE_DATA,
    promise: put()('faService/formData/referenceDatas', referenceData),
    errorMessage: 'Error updating reference data',
    successMessage: 'Updated succesfully',
  })
);

const setPagination = ({
  page, size = {}, orderBy = {},
}) => ({
  type: TypeActionsCrud.REFERENCE_SET_PAGINATION,
  payload: {
    page, size, orderBy,
  },
});

const setFilter = (filterValue) => ({
  type: TypeActionsCrud.SET_FILTERS,
  payload: {
    filterValue,
  },
});

const createReferenceAttribute = (referenceData, body) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.CREATE_REFERENCE_ATTRIBUTE,
    promise: post()(`faService/formData/${formateFormData(referenceData)}/create`, body),
    showBackErrorMessage: true,
    errorMessage: `Error creating new ${formateSentence(referenceData)}`,
    successMessage: `Successfully created new ${formateSentence(referenceData)}`,
  })
);

const deleteReferenceAttribute = (referenceData, code) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.DELETE_REFERENCE_ATTRIBUTE,
    promise: del()(`faService/formData/${formateFormData(referenceData)}/${code}`),
    errorMessage: `Error deleting ${formateSentence(referenceData)}`,
    successMessage: `Successfully deleted ${formateSentence(referenceData)}`,
  })
);

const updateReferenceAttribute = (referenceData, body) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.UPDATE_REFERENCE_ATTRIBUTE,
    promise: post()(`faService/formData/${formateFormData(referenceData)}/update`, body),
    errorMessage: `Error updating existing ${formateSentence(referenceData)}`,
    successMessage: `Successfully updated existing ${formateSentence(referenceData)}`,
  })
);

const clearReferenceAttributes = () => ({
  type: TypeActionsCrud.CLEAR_REFERENCE_ATTRIBUTES,
});

const uploadReferenceAttributes = (referenceData, file, callback) => {
  const formData = new FormData();
  formData.append(
    'file',
    file,
  );
  return dispatchPromiseWithMessage({
    type: TypeActionsCrud.UPLOAD_REFERENCE_ATTRIBUTE,
    promise: post()(`faService/formData/${formateFormData(referenceData)}/upload`, formData),
    errorMessage: 'Error uploading file',
    successMessage: 'File uploaded successfully ',
    callback,
  });
};

const cleanUploadReferenceReports = () => ({
  type: TypeActionsCrud.CLEAN_REFERENCE_UPLOAD_REPORTS,
});

export {
  TypeActionsCrud,
  setPagination,
  setFilter,
  fetchAllReferenceData,
  fetchReferenceAttributesById,
  updateReferenceData,
  createReferenceAttribute,
  deleteReferenceAttribute,
  updateReferenceAttribute,
  clearReferenceAttributes,
  uploadReferenceAttributes,
  cleanUploadReferenceReports,
};
