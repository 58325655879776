import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/DataTable';
import DragAndDropFile from '@components/DragAndDropFile/DragAndDropFile';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { apiFetchVesselsFromFile } from '@services/vessels/api';

import {
  getCurrentVesselsPagination, getTotalPages, sortAscending, sortDescending,
} from './utils';

import tableCheckConfig from './config';

import { showErrorNotification, showWarningNotification } from '@common/utilities/Notification';
import { formatHeaderSort } from '@common/utils';

const formatNotFoundVessels = (notFound) => {
  if (isEmpty(notFound)) {
    return null;
  }
  const { uvi, cfr } = notFound;
  return (
    <>
      <p>
        These Vessels have not been found and could not be added
      </p>
      <ul className="not-found-vessels">
        {uvi && uvi.map((v) => (
          <li key={`not-found-uvi-${v}`}>
            UVI:
            {' '}
            {v}
          </li>
        ))}
        {cfr && cfr.map((v) => (
          <li key={`not-found-cfr-${v}`}>
            CFR:
            {' '}
            {v}
          </li>
        ))}
      </ul>
    </>
  );
};

const VesselImporter = ({
  checkAdded,
  onUploadingFile: handleUploadingFile,
  onFetchFileError: handleFetchFileError,
  selectedRows,
  onSelectedRows: handleSelectedRows,
}) => {
  const { t } = useTranslation();

  const [fileContent, setFileContent] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isFetched, setIsFetched] = useState(false);
  const [orderBy, setOrderBy] = useState({});
  const [page, setPage] = useState(1);

  const handleDataFromCSV = useCallback(async (file) => {
    handleUploadingFile(true);
    const formData = new FormData();
    formData.append('file', file);
    const vesselsFromFile = await apiFetchVesselsFromFile(formData);
    if (vesselsFromFile.ok) {
      const response = await vesselsFromFile.json();
      const {
        notFound,
      } = response || {};
      if (notFound && (notFound.uvi.length > 0 || notFound.cfr.length > 0)) {
        showWarningNotification(formatNotFoundVessels(notFound));
      }
      const arrayVessels = [...(response && response.Vessels)];
      const numTotalPages = getTotalPages(arrayVessels, pageSize);
      setTotalPages(numTotalPages);
      setFileContent(arrayVessels);
      setIsFetched(true);
      if (response.Vessels.length === 0) {
        showErrorNotification(
          t(tSchema.notifications.dmaAdmin.vesselGroupMGMT.vesselGroups.error.importVessels),
        );
        handleFetchFileError();
      }
    }
    handleUploadingFile(false);
  }, []);

  const handleChangePageSize = useCallback((size) => {
    const numTotalPages = getTotalPages(fileContent, size);
    isFetched && setTotalPages(numTotalPages);
    setPageSize(size);
    setPage(1);
  }, [isFetched, fileContent]);

  const handleTableHeaderClick = useCallback((header) => {
    orderBy.field === header.code && orderBy.order === 'desc'
      ? setFileContent((prevState) => (sortAscending(prevState, header.code)))
      : setFileContent((prevState) => (sortDescending(prevState, header.code)));
    setOrderBy(formatHeaderSort(header, orderBy));
  }, [orderBy]);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  return (
    <FieldGroup label={t(tSchema.dmaAdmin.vesselGroupMGMT.import.loadVesselsFromFile)}>
      <DragAndDropFile onChange={handleDataFromCSV} />
      {(isFetched) && (
        <div id="container-vessels-file">
          <DataTable
            tableId="groupVesselsFile"
            tableConfig={tableCheckConfig({
              vessels: fileContent,
              page,
              pageSize,
              selectedRows,
              onSelectedRows: handleSelectedRows,
              checkAdded,
              t,
            })}
            orderBy={isEmpty(orderBy) ? null : orderBy}
            onTableHeaderClick={handleTableHeaderClick}
            rowData={getCurrentVesselsPagination(fileContent, page, pageSize)}
            onPageSizeChangeHandler={handleChangePageSize}
            onPageChangeHandler={handleChangePage}
            maxPages={totalPages}
            totalResults={fileContent.length}
            tableActions={false}
            error={false}
            errorMessageEmptyArray="No vessels found"
            errorMessageLoadingData="An error has occurred loading vessels from file"
          />
        </div>
      )}
    </FieldGroup>
  );
};

VesselImporter.propTypes = {
  onUploadingFile: PropTypes.func.isRequired,
  onFetchFileError: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectedRows: PropTypes.func.isRequired,
  checkAdded: PropTypes.func.isRequired,
};

export default VesselImporter;
