import dayjs from 'dayjs';

const minMax = require('dayjs/plugin/minMax');

dayjs.extend(minMax);

export const DATE_FORMAT = 'DD/MM/YYYY';
export const BACK_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const BACK_TIME_FORMAT = 'HH:mm:ss.mmm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const BACK_DATE_TIME_FORMAT = `${BACK_DATE_FORMAT}T${TIME_FORMAT}`;
export const FILE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH-mm';
export const MIN_AVAILABLE_DATE = '1850-01-01';
export const MAX_AVAILABLE_DATE = '9999-01-01';
const DATE_IS_EMPTY = '-';

export const formatDate = (
  date,
  format = DATE_FORMAT,
  noDate = '',
  localTime = false,
) => {
  if (date) {
    const franjaHoraria = /.*\s(.+)/.exec((new Date(date)).toLocaleDateString(navigator.language, { timeZoneName: 'short' }))[1];
    if (localTime) return `${dayjs(date).format(format)}, ${franjaHoraria}`;
    return dayjs(date).format(format);
  }
  return noDate;
};

export const fromUTCToLocalHour = (hour) => {
  const localDateWithTime = new Date(new Date().setUTCHours(hour.split(':')[0], hour.split(':')[1], 0, 0));
  return `${localDateWithTime.getHours()}:${localDateWithTime.getMinutes()}`;
};

export const fromUTCToLocalDate = (date, hour) => {
  if (date) {
    const localDateWithTime = new Date(new Date(date).setUTCHours(hour.split(':')[0], hour.split(':')[1], 0, 0));
    return new Date(localDateWithTime.getFullYear(),
      localDateWithTime.getMonth(),
      localDateWithTime.getDate(),
      localDateWithTime.getHours(),
      localDateWithTime.getMinutes(), 0, 0);
  }
  return '';
};

export const fromUTCToLocalDateTime = (dateTime) => {
  if (dateTime) {
    const date = dateTime.split('T')[0];
    const hour = dateTime.split('T')[1];
    return fromUTCToLocalDate(date, hour);
  }
  return '';
};

export const formatCurrentDate = (format = DATE_FORMAT) => dayjs().format(format);

export const currentISO8601Date = () => dayjs().format();

export const validateDate = (date) => dayjs(date).isValid();

export const addFormatDate = (date, format, quantity = 1, type = 'days') => dayjs(date).add(quantity, type).format(format);

export const subtractFormatDate = (date, format, quantity = 1, type = 'days') => dayjs(date).subtract(quantity, type).format(format);

export const valueOfDate = (date) => dayjs(date).valueOf();

export const unixDate = (date) => dayjs(date).unix();

export const dayjsDate = (date) => date && dayjs(date);
/**
 *
 * @param {Date} date to format
 * @param {String} dateIsEmpty optional parameter. return when date is empty
 * @returns the date in the format DD/MM/YYYY,
 * if the date is null or undefined, returns the dateIsEmpty
 */

export const formatDateTime = (
  date,
  dateIsEmpty = DATE_IS_EMPTY,
  localTime = false,
) => formatDate(date, DATE_TIME_FORMAT, dateIsEmpty, localTime);
/**
 *
 * @param {*} date
 * @param {*} startDate optional parameter. if not defined, it will be minAvailableDate
 * @param {*} endDate optional parameter. if not defined, it will be maxAvailableDate
 * @returns if the date is between the startDate and endDate returns the input date,
 * otherwise returns a date between the startDate and endDate
 */

export const dateInRange = (date, startDate, endDate) => {
  if (!date) {
    return date;
  }
  const dateDayjs = dayjs(date).isValid() ? dayjs(date) : dayjs();
  const startDateDayjs = dayjs(startDate).isValid() ? dayjs(startDate) : dayjs(MIN_AVAILABLE_DATE);
  const endDateDayjs = dayjs(endDate).isValid() ? dayjs(endDate) : dayjs(MAX_AVAILABLE_DATE);

  return dayjs.min(dayjs.max(dateDayjs, startDateDayjs), endDateDayjs);
};

export const millisToHourMinutes = (duration) => {
  const minutes = Math.floor((duration / (1 * 60)) % 60);
  const hours = Math.floor((duration / (1 * 60 * 60)) % 24);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const fromLocalToUTCHour = (hour) => `${new Date(new Date().setHours(hour.split(':')[0], hour.split(':')[1], 0, 0)).getUTCHours()}:${hour.split(':')[1]}`;
export const fromLocalToUTCDate = (date, hour) => {
  const dateWithHour = new Date(new Date(date).setHours(hour.split(':')[0], hour.split(':')[1], 0, 0));
  const dateToUTC = new Date(
    dateWithHour.getUTCFullYear(),
    dateWithHour.getUTCMonth(),
    dateWithHour.getUTCDate(),
    dateWithHour.getUTCHours(),
    dateWithHour.getUTCMinutes(),
    dateWithHour.getUTCSeconds(),
    dateWithHour.getUTCMilliseconds(),
  );
  return formatDate(dateToUTC, BACK_DATE_FORMAT);
};
export const getDateForInput = (date) => date
&& formatDate(date, BACK_DATE_FORMAT);

/**
 * Used for filters
 * @param {*} v
 * @returns the date processed for back query
 */
export const postProcessDate = (v) => ({
  ...v,
  value: formatDate(v.value, BACK_DATE_FORMAT),
});

/**
 * Checks if Date is valid date
 * @param {*} date
 * @returns
 */
export const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
/**
 * New valid date fron string or null
 * @param {*} value
 * @returns
 */
export const getValidDateOrNull = (value) => {
  const result = value && new Date(value);
  return (result && isValidDate(result) && result) || null;
};

export const formatHour = (date, showUTC) => {
  const dateObject = new Date(date);

  const hours = dateObject.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}${showUTC ? ', UTC' : ''}`;
};
