/* eslint-disable import/order */

import {
  compose, find, identity, map, prepend, prop, propEq,
} from 'ramda';
import * as Yup from 'yup';
import tSchema from '@lang/schema';
import { fromUTCToLocalDate, fromUTCToLocalHour } from '@common/dateUtils';

const modelObjectArrayValidation = {
  label: Yup.string().required(),
  value: Yup.string().required(),
};

const anyVesselOrGroupSelected = (schema, node) => {
  // eslint-disable-next-line no-console
  console.log({
    schema,
    node,
    'node.parent.groups?.length': node.parent.queryObject?.groups?.length,
    'node.parent.vessel?.length': node.parent.queryObject?.vessel?.length,
  });
  if (node.parent.queryObject?.groups?.length < 1 && node.parent.queryObject?.vessel?.length < 1) {
    return false;
  }
  return true;
};

const vesselAndGroupsValidation = (
  {
    queryType, isVesselDomain,
  },
) => {
  const isVesselMinRequired = !isVesselDomain && (+queryType === 1);
  const messageCondition = (isVesselDomain && +queryType === 2 && 'or vessel country')
    || '';
  const reportMinMessage = `At least one vessel or vessel group ${messageCondition} must be selected`;

  const vesselGroupsValidation = (schema, node) => {
    if (node.parent.groups?.length < 1 && node.parent.vessel?.length < 1
    && isVesselMinRequired) {
      return schema.of(Yup.object().shape(
        modelObjectArrayValidation,
      )).min(1, reportMinMessage);
    }
    return schema.min(0);
  };

  return {
    vessel: Yup.array()
      .when([], vesselGroupsValidation).required(),
    groups: Yup.array()
      .when([], vesselGroupsValidation).required(),
  };
};

export const queryValidationSchema = Yup.object().shape({
  consolidated: Yup.boolean().required('Consolidated is required'),
  vesselIdType: Yup.string().when(['type', 'isReport'], {
    is: (type, isReport) => type === 1 && !isReport,
    then: Yup.string().required('Vessel id type is required'),
  }),
  receiver: Yup.string().required('Receiver is required'),
  queryObject: Yup.object().when(['queryType', 'isReport'],
    (queryType) => Yup.object().shape(
      vesselAndGroupsValidation({
        queryType, isVesselDomain: false,
      }),
      [['groups', 'vessel']],
    )).required(),
});

export const vesselQueryValidationSchema = Yup.object().shape({
  vesselQueryType: Yup.string().required('Query Type is required'),
  vesselCountry: Yup.string().when(['vesselQueryType'], (vesselQueryType, schema, node) => (
    +vesselQueryType === 2
    && !anyVesselOrGroupSelected(schema, node)
      ? Yup.string().required('Vessel Country is required').nullable()
      : Yup.string().nullable())),
  vesselStatus: Yup.string().required('Vessel Status is required'),
  receiver: Yup.string().required('Receiver is required'),

  queryObject: Yup.object().when(['type', 'vesselQueryType', 'vesselCountry'],
    (type, vesselQueryType, vesselCountry) => (+vesselQueryType === 2
      ? Yup.object().shape(
        vesselAndGroupsValidation({
          queryType: vesselQueryType,
          type,
          isVesselDomain: true,
          vesselCountry,
        }),
        [['groups', 'vessel']],
      ).required()
      : Yup.object().shape())),
});

export const reportValidationSchema = Yup.object().shape({
  originalSender: Yup.string().required('Original Sender is required'),
  receiver: Yup.string().required('Receiver is required'),
  queryObject: Yup.object().when(['queryType'],
    (queryType) => Yup.object().shape(
      vesselAndGroupsValidation({
        queryType, isVesselDomain: false,
      }),
      [['groups', 'vessel']],
    )).required(),
});

export const schemaValidation = Yup.object().shape(
  {
    type: Yup.number().required('Type is required'),
    name: Yup.string().required('Name can\'t be an empty value'),
    description: Yup.string().nullable(),
    dataScopeType: Yup.string(),
    schedulingOptions: Yup.object().when('type', {
      is: (value) => value === 1,
      then: Yup.object().shape(
        {
          startDate: Yup.string().required('Start date is required'),
          executionTime: Yup.string().required('Execution time is required'),
          recurrence: Yup.number().typeError('Recurrence is required').required('Recurrence is required'),
          status: Yup.number().typeError('Status is required').required('Status is required'),
        },
      ),
    }),
    dataScopeFromTo: Yup.object().when(
      ['dataScopeType', 'vesselQueryType'],
      (dataScopeType, vesselQueryType) => ((dataScopeType === 'fromTo' && +vesselQueryType !== 2)
        ? Yup.object().shape(
          {
            from: Yup.date().required('Start date is required'),
            to: Yup.date().min(
              Yup.ref('from'),
              "End date can't be before start date",
            ).required('End date is required'),
          },
        )
        : Yup.object()),
    ),

    tripObject: Yup.array().of(Yup.object().shape(
      {
        label: Yup.string().required(),
        value: Yup.number().required(),
      },
    )).when(['queryType'], {
      is: (queryType) => {
        const isTripType = +queryType === 2;
        const isTripMinRequired = isTripType;
        return isTripMinRequired;
      },
      then: (schema) => schema.min(1, 'At least one trip is required').required(),
    }),
  },

);

export const queryDefaultValues = {
  isReport: true,
  type: 1,
  queryType: 1,
  dataScopeType: 'lastDays',
  consolidated: true,
  vesselIdType: 1,
  queryObject: {},
  schedulingOptions: {
    executionTime: '00:00',
  },
  dataScopeFromTo: {},
};

export const vesselDefaultValues = (t = identity) => ({
  isReport: true,
  type: 1,
  dataScopeType: 'lastDays',
  vesselQueryType: 1,
  receiver: t(tSchema.dmaAdmin.queryReportMGMT.query.commission),
  queryObject: {},
  schedulingOptions: {
    executionTime: '00:00',
  },
  dataScopeFromTo: {},
});

export const getOptions = (option, idName = 'id', textName = 'description', customText = false) => option && compose(
  prepend(
    <option key="empty" value="">
      {' '}
    </option>,
  ),
  map((x) => (
    <option key={prop(idName)(x)} value={prop(idName)(x)}>
      {customText ? customText(x) : prop(textName)(x)}
    </option>
  )),
  prop(option) || [],
);

export const getOptionById = (options, option, idValue, idPropName = 'id') => {
  try {
    return compose(
      find(propEq(idPropName, idValue)),
      prop(option),
    )(options);
  } catch (error) {
    return null;
  }
};
export const mapSubscriptionIn = (subscription) => ({
  ...subscription,
  type: subscription.type && subscription.type.id,
  vesselIdType: subscription.vesselIdType && subscription.vesselIdType.id,
  queryType: subscription.queryType && subscription.queryType.id,
  vesselQueryType: subscription.vesselQueryType && subscription.vesselQueryType.id,
  vesselStatus: subscription.vesselStatus && subscription.vesselStatus.id,
  schedulingOptions: {
    ...subscription.schedulingOptions,
    recurrence: subscription.schedulingOptions
    && subscription.schedulingOptions.recurrence && subscription.schedulingOptions.recurrence.id,
    status: subscription.schedulingOptions
    && subscription.schedulingOptions.status && subscription.schedulingOptions.status.id,
    startDate: subscription.schedulingOptions?.startDate
      ? fromUTCToLocalDate(
        subscription.schedulingOptions.startDate, subscription.schedulingOptions.executionTime,
      )
      : subscription.schedulingOptions?.startDate,
    endDate: subscription.schedulingOptions?.endDate
      ? fromUTCToLocalDate(
        subscription.schedulingOptions.endDate, subscription.schedulingOptions.executionTime,
      )
      : subscription.schedulingOptions?.endDate,
    executionTime: subscription.schedulingOptions?.executionTime
      ? fromUTCToLocalHour(subscription.schedulingOptions.executionTime)
      : subscription.schedulingOptions?.executionTime,
  },
});
export const mapSubscriptionOut = (subscriptionProp, options) => {
  const {
    isReport, schedulingOptions, tripObject, ...subscription
  } = subscriptionProp;

  return {
    ...subscription,
    ...(tripObject && tripObject.length && { tripObject }),
    ...(subscription.type && { type: getOptionById(options, 'type', subscription.type) }),
    ...(subscription.vesselIdType && { vesselIdType: getOptionById(options, 'vesselIdType', parseInt(subscription.vesselIdType, 10)) }),
    ...(subscription.queryType && { queryType: getOptionById(options, 'queryType', subscription.queryType) }),
    ...(subscription.vesselQueryType && { vesselQueryType: getOptionById(options, 'vesselQueryType', parseInt(subscription.vesselQueryType, 10)) }),
    ...(subscription.vesselStatus && { vesselStatus: getOptionById(options, 'status', parseInt(subscription.vesselStatus, 10)) }),
    ...(subscription.type === 1 && {
      schedulingOptions: {
        ...schedulingOptions,
        ...(schedulingOptions.recurrence && { recurrence: getOptionById(options, 'recurrence', +schedulingOptions.recurrence) }),
        ...(schedulingOptions.status && { status: getOptionById(options, 'status', +schedulingOptions.status) }),
      },
    }),
  };
};
