import { prop } from 'ramda';

import { postProcessDateMillis, previewProcessDateMillis } from '@pages/FishingTripsLogbook/common/utils';

import { addGroupByToCountries } from '@services/vessel_record/reducers/vessel/utils';

import { formatSelectOptions } from '@common/utils';

export const getTypeNames = (isTripView) => [
  'vesselFlags',
  'memberStates',
  'vesselGroups',
  'species',
  'fisheryTypes',
  'gearTypes',
  'declarationTypes',
  'reportTypes',
  ...(!isTripView ? ['reportVersion'] : []),
];

export const configureFilters = (types, toggleVesselModal, isTripView) => [
  {
    code: 'onlyLastTrip',
    label: 'Only last trip',
    type: 'checkbox',
    allwaysTrue: true,
  },
  {
    code: 'timeframe',
    label: 'Timeframe',
    type: 'date-range',
    // This should be temporal, and the default format of the dates
    // when the filters are no longer passed in the URI
    postProcess: postProcessDateMillis,
    previewProcess: previewProcessDateMillis,
  },
  {
    code: 'tripId',
    label: 'Trip ID',
    type: 'input-add',
  },
  {
    code: 'flagState',
    label: 'Flag State',
    type: 'select',
    isMulti: true,
    options: types && types.length !== 0 && formatSelectOptions(addGroupByToCountries([...prop('vesselFlags', types), ...prop('memberStates', types)], { memberState: prop('memberStates', types) })),
  },
  /*
  {
    code: 'vesselGroup',
    label: 'Vessel Group',
    type: 'select',
    isMulti: true,
    options: prop('vesselGroups', types),
  },
  {
    code: 'vesselId',
    label: 'Vessel Search',
    textButton: 'Select',
    type: 'button',
    onClick: toggleVesselModal,
  }, */
  {
    code: 'vesselIds',
    label: 'Vessel ID',
    type: 'input-add',
  },
  {
    code: 'vesselName',
    label: 'Vessel Name',
    type: 'input-add',
  },
  // TODO review the posibility to use this type 'select-vessel'
  // to don't need toggleVesselsModal
  // {
  //   code: 'vesselId',
  //   label: 'Vessel Search',
  //   textButton: 'Select',
  //   type: 'select-vessel',
  // },
  {
    code: 'species',
    label: 'Species',
    type: 'select',
    isMulti: true,
    options: prop('species', types),
  },
  {
    code: 'fisheryType',
    label: 'Fishery Type',
    type: 'select',
    isMulti: true,
    options: formatSelectOptions(prop('fisheryTypes', types)),
  },
  {
    code: 'gearType',
    label: 'Gear Type',
    type: 'select',
    isMulti: true,
    options: formatSelectOptions(prop('gearTypes', types)),
  },
  {
    code: 'meshSize',
    label: 'Mesh Size',
    type: 'quantity',
  },
  ...(isTripView ? [{
    code: 'activityTypeAnd',
    label: 'Activity Type (AND)',
    type: 'select',
    options: formatSelectOptions(prop('declarationTypes', types)),
    isMulti: true,
  }] : []),
  {
    code: 'activityTypeOr',
    label: 'Activity Type (OR)',
    type: 'select',
    options: formatSelectOptions(prop('declarationTypes', types)),
    isMulti: true,
  },
  ...(!isTripView ? [{
    code: 'reportVersion',
    label: 'Report Version',
    type: 'select',
    options: prop('reportVersion', types),
  }] : []),
  {
    code: 'reportType',
    label: 'Report Type',
    type: 'select',
    options: prop('reportTypes', types),
  },
];
