/* eslint-disable import/order */
import SectionValidationBRActivate from './SectionValidationBRActivate';
import SectionValidationBRDeactivate from './SectionValidationBRDeactivate';
import SectionValidationBRCreate from './SectionValidationBRCreate';
import SectionValidationBREdit from './SectionValidationBREdit';
import SectionValidationBRExport from './SectionValidationBRExport';
import SectionValidationBRTable from './SectionValidationBRTable';
import SectionValidationBRView from './SectionValidationBRView';

const VALIDATION_BR = {
  ACTIVATE: {
    title: 'Activate Business Rule',
    body: <SectionValidationBRActivate />,
  },
  DEACTIVATE: {
    title: 'Deactivate Business Rule',
    body: <SectionValidationBRDeactivate />,
  },
  CREATE: {
    title: 'Create Business Rule',
    body: <SectionValidationBRCreate />,
  },
  EDIT: {
    title: 'Edit Business Rule',
    body: <SectionValidationBREdit />,
  },
  EXPORT: {
    title: 'Export Business Rule',
    body: <SectionValidationBRExport />,
  },
  TABLE: {
    title: 'Business Rule Grid',
    body: <SectionValidationBRTable />,
  },
  VIEW: {
    title: 'View Business Rule',
    body: <SectionValidationBRView />,
  },
};

export default VALIDATION_BR;
