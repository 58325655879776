import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

const WrapperModal = ({
  showModal, setShowModal, headerTitle, children, ...rest
}) => {
  const { t } = useTranslation();

  const handleClose = () => setShowModal(false);
  return (

    <Modal
      backdrop="static"
      show={showModal}
      onHide={handleClose}
      {...rest}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark font-weight-bold">{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t(tSchema.common.close)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

WrapperModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

};

export default WrapperModal;
