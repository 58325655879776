/* eslint-disable linebreak-style */
import editForm from './img/editForm.png';

const SectionEditReportAcceptance = () => (
  <div className="SectionQueryAcceptance">
    <p>
      To edit a report acceptance,
      in the results grid the user must click on the &quot;Actions&quot;
      button corresponding to the row of that specific acceptance rule,
      and then click on &quot;Edit&quot; button.
    </p>
    <p>
      A modal displays the information related
      to the selected report acceptance, in editable format:
    </p>
    <p>
      <img src={editForm} alt="editForm" />
    </p>
    <p>
      After editing the Report Acceptance, the user shall click on &quot;Save&quot; button.
      Then the user is able to see the changes performed in the central
      grid of the application.
    </p>
  </div>
);

export default SectionEditReportAcceptance;
