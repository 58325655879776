import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

function SequenceFormField({
  disabled, onChange, valueDefault,
}) {
  const { t } = useTranslation();
  const sec = (text) => text && text.split('_')[0];
  const { createEditView: schema } = tSchema.dmaAdmin.brEngine;

  const sequenceChangeHandler = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const numberValue = value.replace(/\D/g, '').slice(0, 7);
      const formattedValue = numberValue.length > 4 ? `${numberValue.slice(0, 4)}_${numberValue.slice(4)}` : numberValue;
      onChange(formattedValue);
    },
  );

  return (
    <Form.Group as={Col} controlId="formSequence">
      <Form.Label>
        {t(schema.sequence)}
      </Form.Label>
      <Form.Control
        required
        type="text"
        name="sequence"
        value={sec(valueDefault)}
        disabled={disabled}
        onChange={sequenceChangeHandler}
      />
      <Form.Control.Feedback type="invalid">
        {t(schema.validation.emptySequence)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

SequenceFormField.propTypes = {
  valueDefault: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SequenceFormField.defaultProps = {
  valueDefault: undefined,
  disabled: undefined,
};

export default SequenceFormField;
