import PropTypes from 'prop-types';

const LineSeparator = ({
  name,
  position, // top o bottom
  children = null,
}) => (
  <div className={position === 'top' ? 'lineMarginTop' : 'lineMarginBottom'}>
    {name !== null && (
    <div className="label" style={{ marginLeft: '0px' }}>
      {name}
      {children}
    </div>
    )}
    <hr className="line-separator" />
  </div>
);

LineSeparator.defaultProps = {
  name: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.element,
};

LineSeparator.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.element,
};

export default LineSeparator;
