/* eslint-disable no-nested-ternary */
import {
  pipe, map,
  isNil, all, reject, isEmpty,
} from 'ramda';

import tSchema from '@lang/schema';

import { formatHour, formatDate } from '@common/dateUtils';

const schema = tSchema.isrViewer;

const formatChecked = (t) => (checked) => {
  switch (checked) {
    case 'YES':
      return t(schema.checked);
    case 'Y':
      return t(schema.ok);
    case 'NO':
      return t(schema.notChecked);
    case 'N':
      return t(schema.notOk);
    case 'NOT_APPLICABLE':
      return 'Not applicable';
    case null:
      return '';
    default:
      return checked;
  }
};

/* eslint-disable no-nested-ternary */
const getString = ([checked, indicator, details]) => `${checked}${(indicator && checked) && ': '}
${indicator}${details ? ((checked || indicator) ? ` (${details})` : `(${details})`) : ''}`;

const addrSubBlock = (condition, label, value) => (condition ? (
  <>
    <b>{label}</b>
    {' '}
    {value}
  </>
) : value);

const formatAddress = ({
  plot, blockKey, block, poBoxKey, poBox,
}) => {
  if (all(isNil, [plot, blockKey, block, poBoxKey, poBox])) return '';

  const formattedPlot = addrSubBlock(plot, 'Plot', plot);
  const formattedBlock = addrSubBlock(blockKey, 'Block', `${blockKey ? `${blockKey}` : ''}${block ? (blockKey ? `, ${block}` : `${block}`) : ''}`);
  const formattedPoBox = addrSubBlock(poBoxKey, 'P.O. Box', `${poBoxKey ? `${poBoxKey}` : ''}${poBox ? (poBoxKey ? `, ${poBox}` : `${poBox}`) : ''}`);

  const components = reject(isEmpty, [formattedPlot, formattedBlock, formattedPoBox]);

  return components.reduce((acc, curr, index) => (
    <>
      {acc}
      {index > 0 && acc !== null && ', '}
      {curr}
    </>
  ));
};

export function getTableConfig(t) {
  return [
    { text: t(schema.role), code: 'role', sortable: true },
    { text: t(schema.legalEntity), code: 'legalEntity', sortable: true },
    { text: t(schema.name), code: 'name', sortable: true },
    {
      text: t(schema.address),
      code: 'address',
      sortable: true,
      render: (row) => formatAddress(
        {
          plot: row.addressPlot,
          blockKey: row.addressBlockKey,
          block: row.addressBlock,
          poBoxKey: row.addressPOBoxKey,
          poBox: row.addressPOBox,
        },
      ),
    },
    { text: t(schema.nationality), code: 'nationality', sortable: true },
  ];
}

export function formatVmsOperational(tabData, t) {
  let result = '';
  const { vmsOperationalChecked, vmsOperationalIndicator, vmsOperationalDetails } = tabData;
  const flag = tabData
    && (vmsOperationalChecked || vmsOperationalIndicator || vmsOperationalDetails);
  if (flag) {
    result = pipe(
      map(formatChecked(t)),
      getString,
    )([vmsOperationalChecked, vmsOperationalIndicator, vmsOperationalDetails]);
  }
  return result;
}

export function formatRemOperational(tabData, t) {
  let result = '';
  const { remOperationalChecked, remOperationalIndicator, remOperationalDetails } = tabData;
  const flag = tabData
    && (remOperationalChecked || remOperationalIndicator || remOperationalDetails);
  if (flag) {
    result = pipe(
      map(formatChecked(t)),
      getString,
    )([remOperationalChecked, remOperationalIndicator, remOperationalDetails]);
  }
  return result;
}

export function formatLastPortCall(tabData, t) {
  let result = '';

  const { lastPortOfCall, lastPortOfCallCode, lastPortOfCallDate } = tabData;
  const flag = tabData
    && (!isNil(lastPortOfCall) && !isNil(lastPortOfCallCode) && !isNil(lastPortOfCallDate));
  if (flag) {
    result = `${lastPortOfCall} (${lastPortOfCallCode}) ${t(schema.on)} ${formatDate(lastPortOfCallDate)} ${t(schema.at)} ${formatHour(lastPortOfCallDate, true)}`;
  }
  return result;
}
