import { DIRECTION } from '@common/utils';

export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const CATCH_ONBOARD = 'CATCH_ONBOARD';
export const DISCARDS = 'DISCARDS';
export const CATCH_OFFLOADED = 'CATCH_OFFLOADED';
export const CATCH_RECEIVED = 'CATCH_RECEIVED';

export const PRODUCTS_DEFAULT_ORDER = {
  field: 'type',
  order: DIRECTION.DESC,
};

export const CATCH_DEFAULT_ORDER = {
  field: 'species',
  order: DIRECTION.DESC,
};

export const DISCARDS_DEFAULT_ORDER = {
  field: 'species',
  order: DIRECTION.DESC,
};

export const OFFLOADED_DEFAULT_ORDER = {
  field: 'species',
  order: DIRECTION.DESC,
};

export const RECEIVED_DEFAULT_ORDER = {
  field: 'species',
  order: DIRECTION.DESC,
};

export const DONOR_DEFAULT_ORDER = {
  field: 'name',
  order: DIRECTION.DESC,
};
