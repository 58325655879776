import {
  actionCreator, get, dispatchPromise,
} from '../../../utils';

const createActionType = actionCreator('HISTORIC');

const TypeActionsHistoric = {
  GET_VERSIONS_FROM_VESSEL: createActionType('GET_VERSIONS_FROM_VESSEL'),
  GET_PREVIOUS_VERSION: createActionType('GET_PREVIOUS_VERSION'),
  GET_VERSIONS_FROM_VESSEL_CHANGES: createActionType('GET_VERSIONS_FROM_VESSEL_CHANGES'),
  CLEAN_HISTORIC: 'CLEAN_HISTORIC',
};

/*
  GET VERSIONS FROM VESSEL
  Gets an array with all the versions from a vessel
*/
const getVersionsFromVessel = (etalonId, options) => (
  dispatchPromise({
    type: TypeActionsHistoric.GET_VERSIONS_FROM_VESSEL,
    promise: get()(`vrService/elastic/getHistoricVessel/${etalonId}`, options),
  })
);

/*
  GET VERSIONS FROM VESSEL WITH CHANGES
  Gets an array with all the versions from a vessel
*/
const getVersionsFromVesselChanges = (etalonId) => (
  dispatchPromise({
    type: TypeActionsHistoric.GET_VERSIONS_FROM_VESSEL_CHANGES,
    promise: get()(`vrService/vessel/${etalonId}/changes-history`),
  })
);

/*
    CLEAN HISTORIC
    Cleans historic from state
*/
const cleanHistoric = () => ({
  type: TypeActionsHistoric.CLEAN_HISTORIC,
});

/*
  GET PREVIOUS VERSION
  Gets the previous version of the vessel
*/
const getPreviousVersion = (etalonId) => (
  dispatchPromise({
    type: TypeActionsHistoric.GET_PREVIOUS_VERSION,
    promise: get()(`vrService/elastic/getHistoricVessel/${etalonId}`),
  })
);

export {
  TypeActionsHistoric,
  cleanHistoric,
  getVersionsFromVessel,
  getPreviousVersion,
  getVersionsFromVesselChanges,
};
