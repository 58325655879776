import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import tSchema from '@lang/schema';

import { REACT_SELECT } from '@common/utils';

import './style.scss';

const schema = tSchema.fishingActivities.logbookViewer.filters;

const AddFilter = ({ filters, onAddFilter: handleAddFilter }) => {
  const { t } = useTranslation();
  return (
    <Form.Row className="d-flex justify-content-between m-0 px-3">
      <Form.Group
        controlId="newFilterSelector"
        className="d-flex align-items-center flex-grow-1 m-0 my-2 justify-content-between "
        style={{ width: '100%' }}
      >
        <Form.Label className="mr-5 text-nowrap my-auto">{t(tSchema.common.addFilter)}</Form.Label>
        <ReactSelect
          styles={REACT_SELECT.customStylesDefault()}
          value={null}
          className="multi-choice-select form-control-sm h-100 m-0 p-0"
          placeholder={t(schema.filterPlaceholder)}
          onChange={handleAddFilter}
          options={filters.map(({ code, label }) => ({ value: code, label }))}
          isMulti={false}
          closeMenuOnSelect
          noOptionsMessage={() => t(schema.noOptionsMessage)}
        />
      </Form.Group>
    </Form.Row>
  );
};

AddFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddFilter: PropTypes.func.isRequired,
};

export default AddFilter;
