import {
  fetchVesselsGroups,
  fetchGroupsFromVessel,
  updateVesselGroups,
  swapVisualGroup,
  approveVessel,
  cleanUploadVesselsReports,
  editVessel,
  exportVessels,
  fetchAllVessels,
  fetchVesselById,
  getSearchableAttributes,
  getFormData,
  getVesselPorts,
  setPagination,
  TypeActionsCrud,
  uploadVessels,
  clearVesselsAndPagination,
  loadVesselFromState,
} from './crud';
import {
  addComplexRow,
  assignImage,
  changeComplexLocal,
  changeComplexVesselInformation,
  changeVesselInformation,
  checkMandatoryFields,
  cleanVesselForm,
  fetchEntityVessel,
  removeComplexRow,
  setIsValidVesselField,
  setSend,
  setVessel,
  TypeActionsForm,
  uploadImage,
  validateDrools,
} from './form';
import {
  cleanHistoric, getPreviousVersion, getVersionsFromVessel, TypeActionsHistoric,
} from './historic';

const Actions = {
  ...TypeActionsCrud,
  ...TypeActionsForm,
  ...TypeActionsHistoric,
};

export {
  Actions,
  // CRUD
  fetchAllVessels,
  fetchVesselById,
  editVessel,
  approveVessel,
  getSearchableAttributes,
  getFormData,
  getVesselPorts,
  setPagination,
  uploadVessels,
  cleanUploadVesselsReports,
  exportVessels,
  fetchVesselsGroups,
  fetchGroupsFromVessel,
  updateVesselGroups,
  swapVisualGroup,
  clearVesselsAndPagination,
  loadVesselFromState,
  // FORM
  fetchEntityVessel,
  changeVesselInformation,
  changeComplexVesselInformation,
  changeComplexLocal,
  checkMandatoryFields,
  setIsValidVesselField,
  cleanVesselForm,
  setVessel,
  addComplexRow,
  removeComplexRow,
  validateDrools,
  setSend,
  uploadImage,
  assignImage,
  // HISTORIC
  cleanHistoric,
  getVersionsFromVessel,
  getPreviousVersion,
};
