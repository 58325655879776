import edit from './img/edit_attr.png';
import mdrNoEdit from './img/mdr_no_edit.png';

const SectionReferenceDataEdit = () => (
  <div className="SectionReferenceDataEdit">
    <p>
      Users can edit the reference data, clicking on the &quot;Actions&quot; button
      of the corresponding row and then selecting the &quot;Edit&quot; option.
      A modal window appears displaying the information available:
    </p>
    <p>
      <img src={edit} alt="Edit Reference Data" />
    </p>
    <p>
      <p>
        <b>Notes on the edition:</b>
      </p>
      <p>

        <li>
          Users cannot edit the field &quot;Name&quot; in form. They can only edit the description.
        </li>
        <li>
          In the grid displayed in the modal window,
          those rows with value MDR as Source cannot be edited.
        </li>
      </p>
      <p>
        <img src={mdrNoEdit} alt="MDR source" />
      </p>
    </p>

  </div>
);

export default SectionReferenceDataEdit;
