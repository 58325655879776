import i18next from 'i18next';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

const schema = tSchema.dmaAdmin.scenarioMGMT.users;

const { t } = i18next;

const tableConfig = (onRowChangeHandler) => [
  {
    text: t(schema.name),
    code: 'name',
  },
  {
    text: t(schema.description),
    code: 'description',
    render: (row) => (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="description-tooltip">{row.description}</Tooltip>}
      >
        <p className="limit-text">{row.description}</p>
      </OverlayTrigger>
    ),
  },
  {
    text: t(schema.isActive),
    code: 'isActive',
    render: (row) => (row.isActive ? 'Active' : 'Inactive'),
  },
  {
    text: t(schema.assigned),
    code: 'isAssigned',
    render: (row) => (
      <Form.Group className="mb-0" controlId={`isAssigned-${row.id}`}>
        <Form.Check
          type="checkbox"
      // TODO
          checked={row.isAssigned}
          onChange={(e) => onRowChangeHandler(e.currentTarget?.checked, row.id, 'isAssigned')}
          label={null}
        />
      </Form.Group>
    ),
  },
  {
    text: t(schema.from),
    code: 'startAccessPeriod',
    render: (row) => {
      const required = row.isAssigned;
      const isInvalid = required && !row.startAccessPeriod;
      return (
        <Form.Group className="mb-0" controlId={`startAccessPeriod-${row.id}`}>
          <InputDate
            name="startAccessPeriod"
            id="startAccessPeriod"
            required={required}
            value={row.startAccessPeriod}
            disabled={!row.isAssigned}
            isValid={!isInvalid}
            maxDate={row.endAccessPeriod}
            onChange={(id, value) => onRowChangeHandler(value, row.id, 'startAccessPeriod')}
            complex={false}
            hasAction={false}
          />
        </Form.Group>
      );
    },
  },
  {
    text: t(schema.to),
    code: 'endAccessPeriod',
    render: (row) => {
      const required = row.isAssigned;
      const isInvalid = required && row.startAccessPeriod > row.endAccessPeriod;
      return (
        <Form.Group className="mb-0" controlId={`endAccessPeriod-${row.id}`}>
          <InputDate
            name="endAccessPeriod"
            id="endAccessPeriod"
            required={required}
            value={row.endAccessPeriod}
            disabled={!row.isAssigned}
            isValid={!isInvalid}
            minDate={row.startAccessPeriod}
            onChange={(id, value) => onRowChangeHandler(value, row.id, id)}
            complex={false}
            hasAction={false}
          />
          <Form.Control.Feedback type="invalid">
            {isInvalid && 'The end date must be after the start date'}
          </Form.Control.Feedback>
        </Form.Group>
      );
    },
  },
];

export default tableConfig;
