import tSchema from '@lang/schema';

const schema = tSchema.isrViewer;

export function getGearCharactsTableConfig(t) {
  return [
    {
      text: t(schema.type), code: 'type', render: (row) => `${row.type} (${row.typeCode})`,
    },
    { text: t(schema.value), code: 'value' },
    { text: t(schema.unit), code: 'unit' },
  ];
}

export function getAttachCharactsTableConfig(t) {
  return [
    { text: t(schema.type), code: 'type' },
    { text: t(schema.value), code: 'value' },
  ];
}
