import PropTypes from 'prop-types';
import {
  useCallback, useRef, useState, useEffect, useMemo,
} from 'react';
import {
  Card, Row, Form, Col,
} from 'react-bootstrap';

import InputDate from '@components/Form/Inputs/InputDate';
import IconSvg from '@components/IconSvg';

import InputSelect from '../../../../../../components/Form/Inputs/InputSelect';
import {
  formatFiltersToChoose, selectFilterCustomSort,
  getFormDataSelectOptions,
  SEARCH_TYPES,
} from '../../../../../../componentsRecord/Filters/FiltersUtils';

import './style.scss';

const checkRange = (range) => {
  if (range[0] === null) {
    return `< ${range[1]}`;
  }
  if (range[1] === null) {
    return `> ${range[0]}`;
  }
  if (range[0] !== null && range[1] !== null) {
    return `beetween ${range[0]} and ${range[1]}`;
  }
  return '';
};

const Criteria = ({
  searchableAttributes,
  formDataList,
  attributeCriteria,
  setAttributeCriteria,
  edit,
}) => {
  const keyPressHold = useRef(false);
  const [criteriaHidden, setCriteriaHidden] = useState(true);
  const [criteriaToChoose, setCriteriaToChoose] = useState([]);

  const checkExact = (filter, attribute) => {
    const formData = (attribute && attribute.customProperties.formData) || undefined;
    if (formData) {
      const { description } = formDataList[formData]?.find(
        (data) => {
          const val = data.code;
          return val?.toString() === filter?.value?.toString();
        },
      ) || { description: '' };
      return `= ${description}`;
    }
    return `= ${filter.value}`;
  };

  const showTypeValue = (filter, attribute) => {
    switch (filter.searchType) {
      case SEARCH_TYPES.EXACT:
        return checkExact(filter, attribute);
      case SEARCH_TYPES.FUZZY:
        return `contains "${filter.value}"`;
      case SEARCH_TYPES.RANGE:
        return checkRange(filter.range);
      default:
        return '';
    }
  };

  const toggleCollapsed = useCallback(() => {
    setCriteriaHidden(!criteriaHidden);
  });
  function resetCriteria() {
    setAttributeCriteria([]);
  }
  const filterChooseCriteria = () => {
    // Filtra los filtros del desplegable para que no aparezcan los mismos
    // que se están mostrando
    const chooseCriteria = searchableAttributes.filter(
      (attr) => !attributeCriteria.map((f) => f.name).includes(attr.name),
    );
    setCriteriaToChoose(chooseCriteria);
  };
  const addAttributeCriteria = (id, value) => {
    const filter = searchableAttributes.find((attr) => attr.name === value);
    const { formData } = filter.customProperties || {};
    if (formData) {
      setAttributeCriteria([
        ...attributeCriteria,
        {
          inverted: false,
          name: filter.name,
          searchType: 'EXACT',
          type: 'STRING',
          value: formDataList[formData][0].code,
        },
      ]);
    } else {
      setAttributeCriteria([
        ...attributeCriteria,
        {
          inverted: false,
          name: filter.name,
          searchType: 'EXACT',
          type: 'STRING',
          value: null,
        },
      ]);
    }
  };
  const deleteAttributeCriteria = (value) => {
    setAttributeCriteria(
      attributeCriteria.filter((attr) => attr.name !== value),
    );
  };
  const handleChangeCriteria = (id, searchType, type, value, rangeMin, rangeMax) => {
    setAttributeCriteria(attributeCriteria.map((attr) => {
      if (attr.name === id) {
        if ((rangeMin !== null && rangeMin !== undefined)
        || (rangeMax !== null && rangeMax !== undefined)) {
          return {
            inverted: false,
            name: id,
            searchType: searchType || attr.searchType,
            type,
            value: value || attr.value,
            range: [rangeMin, rangeMax],
          };
        }
        return {
          inverted: false,
          name: id,
          searchType: searchType || attr.searchType,
          type,
          value: value || attr.value,
        };
      }
      return attr;
    }));
  };

  const getSearchType = (criteria) => {
    let searchType = '';
    if (criteria.searchType === 'EXACT') {
      searchType = criteria.searchType;
    } else if (criteria.range[0] !== null && criteria.range[1] !== null) {
      searchType = 'RANGE';
    } else if (criteria.range[0] === null) {
      searchType = 'RANGE_SMALLER';
    } else if (criteria.range[1] === null) {
      searchType = 'RANGE_GREATHER';
    }
    return searchType;
  };

  useEffect(() => {
    filterChooseCriteria();
  }, [searchableAttributes, attributeCriteria]);

  const newFilterOptions = useMemo(
    () => formatFiltersToChoose(criteriaToChoose), [criteriaToChoose],
  );

  return (
    <Card body id="criteria-section" className="mb-2">
      <Row>
        <>
          <div
            className="cursor-pointer m-2"
            onClick={edit && toggleCollapsed}
            onKeyPress={(ev) => {
              if (ev.key === ' ' || ev.key === 'Enter') {
                ev.preventDefault();
                !keyPressHold.current && edit && toggleCollapsed();
              }
              keyPressHold.current = true;
            }}
            onKeyUp={() => {
              keyPressHold.current = false;
            }}
            role="button"
            tabIndex={0}
          >
            <span className="m-2">Criteria</span>
            {edit && (criteriaHidden ? <IconSvg name="caretDown" /> : <IconSvg name="caretUp" />)}
          </div>
          <div className="options">
            {criteriaHidden && (
              <div className="vessels-criteria-summary">
                {attributeCriteria && attributeCriteria.map((filter) => {
                  const attribute = searchableAttributes.find((attr) => attr.name === filter.name);
                  if (attribute) {
                    return (
                      <div key={filter.name}>
                        {`${attribute.displayName} ${showTypeValue(filter, attribute)}`}
                      </div>
                    );
                  }
                  return <></>;
                })}
              </div>
            )}
            {edit && (
              <div
                className="clear-criteria"
                onClick={() => resetCriteria()}
                onKeyPress={(ev) => {
                  if (ev.key === ' ' || ev.key === 'Enter') {
                    ev.preventDefault();
                    !keyPressHold.current && resetCriteria();
                  }
                  keyPressHold.current = true;
                }}
                onKeyUp={() => {
                  keyPressHold.current = false;
                }}
                role="button"
                tabIndex={0}
              >
                <p>Clear criteria</p>
              </div>
            )}
          </div>
        </>
      </Row>
      <Row
        hidden={criteriaHidden}
      >
        <Col id="criteria-list-section">
          {
            attributeCriteria.map((criteria) => {
              const attribute = searchableAttributes.find((attr) => attr.name === criteria.name);
              const formData = (attribute && attribute.customProperties.formData) || undefined;
              return (
                <Row key={criteria.name} style={{ flexWrap: 'nowrap', marginBottom: '0.313rem' }}>
                  <Form.Group as={Col} style={{ flexGrow: 0 }}>
                    <Form.Check
                      type="checkbox"
                      label={
                        (attribute && attribute.displayName) || criteria.name
                      }
                      name={criteria.name}
                      defaultChecked
                      onChange={() => {
                        deleteAttributeCriteria(criteria.name);
                      }}
                    />
                  </Form.Group>
                  {
                    // Si es un desplegable
                    formData && (
                      <InputSelect
                        className="attribute-filter-select"
                        value={criteria.value}
                        hasAction={false}
                        onChange={(id, value) => handleChangeCriteria(criteria.name, 'EXACT', 'STRING', value)}
                        options={getFormDataSelectOptions(
                          { formData, formDataList },
                        )}
                      />
                    )
                  }

                  {
                    // Si es un string
                    !formData && attribute && attribute.simpleDataType === 'String' && (
                      <>
                        <Form.Control
                          as="select"
                          style={{ width: '11.25rem' }}
                          value={criteria.searchType}
                          onChange={(e) => {
                            handleChangeCriteria(criteria.name, e.target.value, 'STRING', undefined);
                          }}
                        >
                          <option value={SEARCH_TYPES.EXACT}>Equals</option>
                          <option value={SEARCH_TYPES.FUZZY}>Contains</option>
                        </Form.Control>
                        <Form.Control
                          type="text"
                          style={{ width: '11.25rem' }}
                          value={criteria.value}
                          onChange={(e) => {
                            handleChangeCriteria(criteria.name, undefined, 'STRING', e.target.value);
                          }}
                        />
                      </>
                    )
                  }
                  {
                    // Si es un number
                    !formData && attribute && (attribute.simpleDataType === 'Number' || attribute.simpleDataType === 'Integer') && (
                      <>
                        <Form.Control
                          as="select"
                          style={{ width: '11.25rem' }}
                          value={getSearchType(criteria)}
                          onChange={(e) => {
                            switch (e.target.value) {
                              case 'EXACT':
                                handleChangeCriteria(criteria.name, 'EXACT', attribute.simpleDataType, undefined);
                                break;
                              case 'RANGE_GREATHER':
                                handleChangeCriteria(criteria.name, 'RANGE', attribute.simpleDataType, undefined, 0, null);
                                break;
                              case 'RANGE_SMALLER':
                                handleChangeCriteria(criteria.name, 'RANGE', attribute.simpleDataType, undefined, null, 0);
                                break;
                              case 'RANGE':
                                handleChangeCriteria(criteria.name, 'RANGE', attribute.simpleDataType, undefined, 0, 0);
                                break;
                              default:
                            }
                          }}
                        >
                          <option value="EXACT">Equals</option>
                          <option value="RANGE_GREATHER">Greather than</option>
                          <option value="RANGE_SMALLER">Smaller than</option>
                          <option value="RANGE">In range</option>
                        </Form.Control>
                        {criteria.searchType === 'EXACT' && (
                          <Form.Control
                            type="number"
                            style={{ width: '11.25rem' }}
                            value={criteria.value}
                            onChange={(e) => {
                              handleChangeCriteria(criteria.name, 'EXACT', attribute.simpleDataType, e.target.value);
                            }}
                          />
                        )}
                        {criteria.searchType === 'RANGE' && criteria.range[0] !== null && (
                          <Form.Control
                            type="number"
                            style={{ width: '11.25rem' }}
                            value={criteria.range[0]}
                            onChange={(e) => {
                              handleChangeCriteria(criteria.name, 'RANGE', attribute.simpleDataType, undefined, e.target.value, criteria.range[1]);
                            }}
                          />
                        )}
                        {criteria.searchType === 'RANGE' && criteria.range[1] !== null && (
                          <Form.Control
                            type="number"
                            style={{ width: '11.25rem' }}
                            value={criteria.range[1]}
                            onChange={(e) => {
                              handleChangeCriteria(criteria.name, 'RANGE', attribute.simpleDataType, undefined, criteria.range[0], e.target.value);
                            }}
                          />
                        )}
                      </>
                    )
                  }
                  {
                    // Si es una fecha
                    !formData && attribute && attribute.simpleDataType === 'Date' && (
                      <>
                        <Form.Control
                          as="select"
                          style={{ width: '11.25rem' }}
                          onChange={(e) => {
                            switch (e.target.value) {
                              case 'EXACT':
                                handleChangeCriteria(criteria.name, 'EXACT', 'TIMESTAMP', undefined);
                                break;
                              case 'RANGE_GREATHER':
                                handleChangeCriteria(criteria.name, 'RANGE', 'TIMESTAMP', undefined, 0, null);
                                break;
                              case 'RANGE_SMALLER':
                                handleChangeCriteria(criteria.name, 'RANGE', 'TIMESTAMP', undefined, null, 0);
                                break;
                              case 'RANGE':
                                handleChangeCriteria(criteria.name, 'RANGE', 'TIMESTAMP', undefined, 0, 0);
                                break;
                              default:
                            }
                          }}
                        >
                          <option value="EXACT">Equals</option>
                          <option value="RANGE_GREATHER">Greather than</option>
                          <option value="RANGE_SMALLER">Smaller than</option>
                          <option value="RANGE">In range</option>
                        </Form.Control>
                        {criteria.searchType === 'EXACT' && (
                          <InputDate
                            style={{ width: '11.25rem' }}
                            name="criteria.value"
                            id="criteria.value"
                            value={criteria.value}
                            onChange={(id, value) => handleChangeCriteria(criteria.name, 'EXACT', 'TIMESTAMP', value)}
                            complex={false}
                            hasAction={false}
                          />
                        )}
                        {criteria.searchType === 'RANGE' && criteria.range[0] !== null && (
                          <InputDate
                            style={{ width: '11.25rem' }}
                            name="criteria.range[0]"
                            id="criteria.range[0]"
                            value={criteria.range[0]}
                            onChange={(
                              id, value,
                            ) => handleChangeCriteria(criteria.name, 'RANGE', 'TIMESTAMP', undefined, value, criteria.range[1])}
                            complex={false}
                            hasAction={false}
                          />
                        )}
                        {criteria.searchType === 'RANGE' && criteria.range[1] !== null && (
                          <InputDate
                            style={{ width: '11.25rem' }}
                            name="criteria.range[1]"
                            id="criteria.range[1]"
                            value={criteria.range[1]}
                            onChange={(
                              id, value,
                            ) => handleChangeCriteria(criteria.name, 'RANGE', 'TIMESTAMP', undefined, criteria.range[0], value)}
                            complex={false}
                            hasAction={false}
                          />
                        )}
                      </>
                    )
                  }
                </Row>
              );
            })
          }
        </Col>
        <Col xs={4} className="d-flex align-items-end" id="new-criteria-section">
          <Form.Group controlId="newCriteriaSelector" className="d-flex flex-grow-1 pr-2" style={{ marginBottom: 0 }}>
            <Form.Label className="flex-grow-1">Add Criteria</Form.Label>
            <InputSelect
              placeholder="Select a filter"
              className="new-filter-selector"
              id="newFilterSelector"
              value={null}
              hasAction={false}
              onChange={addAttributeCriteria}
              options={newFilterOptions}
              customOrder={selectFilterCustomSort}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

Criteria.propTypes = {
  searchableAttributes: PropTypes.instanceOf(Array).isRequired,
  formDataList: PropTypes.instanceOf(Object).isRequired,
  attributeCriteria: PropTypes.instanceOf(Array).isRequired,
  setAttributeCriteria: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default Criteria;
