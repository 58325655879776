import PropTypes from 'prop-types';
import React from 'react';

import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';

const CategoryLinkName = ({
  text, icon,
}) => {
  const iconClassName = icon ? '' : 'placeholder';
  return (
    <div className="title">
      <div className="collapser">
        <IconSvg name={ICON.CHEVRON_DOWN} className="icon-collapsed" />
        <IconSvg name={ICON.CHEVRON_RIGHT} className="icon-open" />
      </div>
      <div className="filler" />
      <div className={`icon ${iconClassName}`}>
        {icon && <IconSvg name={icon} />}
      </div>
      <div className="text">{text}</div>
    </div>
  );
};

CategoryLinkName.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default CategoryLinkName;
