import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import PaginationHeader from '../paginationHeader/PaginationHeader';
import VersionSelector from '../versionSelector/VersionSelector';

const ReportHeader = ({
  onSelectPagination = () => {},
  onSelectVersion = () => {},
  pagination = {},
  versions = null,
  reports = null,
  title = 'Report',
}) => (
  <Row className="d-flex justify-content-between py-2 px-3 ">
    <VersionSelector
      versions={versions}
      onSelectVersion={onSelectVersion}
     // controlId="prueba111"
    />
    <PaginationHeader
      title={title}
      versions={reports}
      pagination={pagination}
      onSelectPagination={onSelectPagination}

    />
  </Row>
);
ReportHeader.propTypes = {
  versions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSelectVersion: PropTypes.func.isRequired,
  pagination: PropTypes.number.isRequired,
  onSelectPagination: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  reports: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default ReportHeader;
