import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import InputSelect from '@components/Form/Inputs/InputSelect';
import ValidationWarning from '@components/Form/ValidationWarning';

import tSchema from '@lang/schema';

import {
  changeVesselInformation,
} from '../../../../services/vessel_record/actions/vessel';

import { INPUT_TYPES } from './constants';
import VesselInput from './VesselInput';

import { BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';

const statusOptions = [
  { code: 'Active', description: 'Active' },
  { code: 'Inactive', description: 'Inactive' },
  { code: 'Inoperative', description: 'Inoperative' },
];

/*
    Los campos max/minLength cuando se utilizan en un atributo string
    representan la longitud máxima y mínima de la cadena mientras que
    si se utilizan en un atributo de tipo number representan el número
    máximo y mínimo que se puede introducir
*/
const VesselFormGroup = ({
  classSpace,
  attrName,
  inputType,
  attributes,
  displayName,
  defaultValue,
  maxLength,
  minLength,
  visualization,
  minDate = null,
  maxDate = null,
  ...props
}) => {
  const { t } = useTranslation();
  const listDrools = useSelector((state) => state.vessel.listDrools);
  const permissions = useSelector((state) => state.authentication.permissions);
  const previousVersion = useSelector((state) => state.vessel.previousVersion);
  const template = useSelector((state) => state.vessel.template);
  const vesselEntity = useSelector((state) => state.vessel.vessel);

  const index = attributes.findIndex((attr) => attr.name === attrName);

  const errorsId = (id) => listDrools?.filter((x) => x.referencedItem === id);

  /*
  El valor anterior se debe mostrar cuando ha cambiado el valor
  desde la anterior version a la actual, y si en la anterior no tenía nada (era undefined)
  y ahora tiene un valor, se debe aplicar unicamente si el barco se encuentra 'Pending Confirmation'
  y el rol del usuario cuya sesión está inciada es 'Approver'
  */
  const showFormerValue = (attr, idx) => {
    let currentValue = attr[idx].value;
    if (attr[idx].simpleDataType === 'Date') {
      currentValue = formatDate(currentValue, BACK_DATE_FORMAT, undefined, true);
    }

    if (permissions.includes('APPROVE_MODIFICATIONS') && vesselEntity.approverStatus === 'PENDING_CONFIRMATION') {
      if (previousVersion !== null) {
        if (currentValue !== null && previousVersion[attr[idx].name] !== currentValue) {
          return true;
        }
      }
    }

    return false;
  };

  if (index !== -1) {
    return (
      <Form.Group className={`${classSpace} ${inputType === INPUT_TYPES.BOOLEAN ? 'form-group-check-row' : ''}`}>
        <Form.Label className="label-modal">
          {displayName !== '' ? displayName : attributes[index].displayName}
            &nbsp;
          {
              errorsId(attributes[index].name).length !== 0
              && <ValidationWarning messages={errorsId(attributes[index].name)} />
            }
        </Form.Label>
        {attrName === 'status'
          ? (
            <InputSelect
              className="input-select"
              id={attributes[index].name}
              readOnly={visualization === 'VIEW' || permissions.find((p) => p === 'DELETE_VESSELS') === undefined}
              value={attributes[index].value}
              onChange={changeVesselInformation}
              options={statusOptions}
              disabledPlaceholder={t(tSchema.common.noOptionSelected)}
            />
          )
          : (
            <VesselInput
              visualization={visualization}
              inputType={inputType}
              attribute={attributes[index]}
              template={template}
              defaultValue={defaultValue}
              maxLength={maxLength}
              minLength={minLength}
              minDate={minDate}
              maxDate={maxDate}
              {...props}
            />
          )}
        {
            showFormerValue(attributes, index) && (
            <div className="formerValue">
              {'Former Value: '}
              {previousVersion[attributes[index].name] || '-'}
            </div>
            )
          }
      </Form.Group>
    );
  }
  return <></>;
};

VesselFormGroup.defaultProps = {
  defaultValue: null,
  maxLength: null,
  minLength: 0,
  displayName: '',
};
VesselFormGroup.propTypes = {
  displayName: PropTypes.string,
  classSpace: PropTypes.string.isRequired,
  attrName: PropTypes.string.isRequired,
  visualization: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
};

export default VesselFormGroup;
