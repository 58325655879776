import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import InputDate, { DATE_TYPE } from './InputDate';

import {
  getValidDateOrNull,
  MAX_AVAILABLE_DATE,
  MIN_AVAILABLE_DATE,
} from '@common/dateUtils';

const InputDateRange = ({
  id,
  fromValue,
  toValue,
  onChangeFrom,
  onChangeTo,
  required,
  maxDate,
  minDate,
  type,
  hiddenLabels,
}) => {
  const { t } = useTranslation();

  const startDate = useMemo(() => getValidDateOrNull(fromValue), [fromValue]);
  const endDate = useMemo(() => getValidDateOrNull(toValue), [toValue]);

  return (
    <>
      <Col sm={6} xs={12} className="d-flex align-items-start" style={{ minWidth: '150px' }}>
        <div className="d-flex flex-column h-100 flex-grow-1" style={{ maxWidth: '100%' }}>
          {!hiddenLabels && <Form.Label>{t('From')}</Form.Label>}
          <Form.Control
            value={fromValue}
            name={`${id}-from-value`}
            hidden
          />
          <InputDate
            name={`${id}-temp-from-value`}
            id={id}
            type={type}
            onChange={onChangeFrom}
            required={required}
            complex={false}
            hasAction={false}
            selectsStart
            value={fromValue}
            startDate={startDate}
            endDate={endDate}
            maxDate={toValue || maxDate}
            minDate={minDate}
          />
        </div>
      </Col>
      <Col sm={6} xs={12} className="d-flex align-items-start" style={{ minWidth: '150px' }}>
        <div className="d-flex flex-column h-100 flex-grow-1" style={{ maxWidth: '100%' }}>
          {!hiddenLabels && <Form.Label>{t('To')}</Form.Label>}
          <Form.Control
            value={toValue}
            name={`${id}-to-value`}
            hidden
          />
          <InputDate
            name={`${id}-temp-to-value`}
            id={id}
            type={type}
            onChange={onChangeTo}
            required={required}
            complex={false}
            hasAction={false}
            selectsEnd
            value={toValue}
            startDate={startDate}
            endDate={endDate}
            maxDate={maxDate}
            minDate={fromValue || minDate}
          />
        </div>
      </Col>
    </>
  );
};

InputDateRange.defaultProps = {
  id: undefined,
  fromValue: undefined,
  toValue: undefined,
  onChangeFrom: undefined,
  onChangeTo: undefined,
  required: undefined,
  type: DATE_TYPE.DATE,
  maxDate: MAX_AVAILABLE_DATE,
  minDate: MIN_AVAILABLE_DATE,
  hiddenLabels: false,
};

InputDateRange.propTypes = {
  id: PropTypes.string,
  fromValue: PropTypes.string,
  toValue: PropTypes.string,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hiddenLabels: PropTypes.bool,
};

export default InputDateRange;
