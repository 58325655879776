import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import './FieldGroup.scss';

function FieldGroup({
  label, children, ...rest
}) {
  return (
    <Col className="fieldGroup" sm={{ span: 12 }} {...rest}>
      <div className="content">
        <div className="label">{label}</div>
        {children}
      </div>
    </Col>
  );
}

FieldGroup.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

};

export default FieldGroup;
