import {
  compose, map, prop, filter,
} from 'ramda';
import * as Yup from 'yup';

import tSchema from '@lang/schema';

import { DOMAIN } from '@common/utils';

const schema = tSchema.dmaAdmin.acceptanceBR.query.createEditView.validation;

export const schemaValidation = (t, domainCode) => {
  const isFADomain = domainCode === DOMAIN.FA;
  return Yup.object().shape(
    {
      sender:
    isFADomain ? Yup.string().nullable().required(t(schema.emptySender)) : Yup.string().nullable(),
      isrParty:
    !isFADomain ? Yup.string().nullable().required(t(schema.emptyISRParty))
      : Yup.string().nullable(),
      startDateData: Yup.date().nullable(),
      endDateData: Yup.date().nullable().min(
        Yup.ref('startDateData'),
        t(schema.dateOverlap),
      ).when(
        'startDateData',
        {
          is: (value) => Boolean(value),
          then: Yup.date().required(t(schema.emptyDataScopeTo)),
        },
      ),
      ...(isFADomain ? {
        activityStartDate: Yup.date().nullable().required(t(schema.emptyActivityStartDate)),
        activityEndDate: Yup.date().nullable().min(
          Yup.ref('activityStartDate'),
          t(schema.dateOverlap),
        ),
      } : {
        startActiveDate: Yup.date().nullable().required(t(schema.emptyActivityStartDate)),
        endActiveDate: Yup.date().nullable().min(
          Yup.ref('startActiveDate'),
          t(schema.dateOverlap),
        ),
      }),
      status: Yup.string().required(t(schema.emptyStatus)),
      queryContexts: !isFADomain ? Yup.array().min(1, 'At least a Query Context must be selected').of(Yup.object().shape(
        {
          label: Yup.string(),
          value: Yup.string(),
        },
      )).required() : Yup.string().nullable(),
    },

  );
};

export const defaultValues = {
};

export const getOptions = (option, idName = 'id', textName = 'description', customText = false) => option && compose(
  map((x) => (
    <option key={prop(idName)(x)} value={prop(idName)(x)}>
      {customText ? customText(x) : prop(textName)(x)}
    </option>
  )),
  prop(option),
);

export const getIsrFilteredOptions = (option, idName = 'id', textName = 'description', customText = false) => option && compose(
  map((x) => (
    <option key={prop(idName)(x)} value={prop(idName)(x)}>
      {customText ? customText(x) : prop(textName)(x)}
    </option>
  )),
  filter((x) => prop(idName)(x) === 'XFA' || prop(idName)(x) === 'XEU'),
  prop(option),
);

export const millisToHourMinutes = (duration) => {
  const minutes = Math.floor((duration / (1 * 60)) % 60);
  const hours = Math.floor((duration / (1 * 60 * 60)) % 24);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
