import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup, OverlayTrigger, Popover,
} from 'react-bootstrap';

import ActionsButton from './components/ActionsButton/ActionsButton';

import './style.scss';

export const ACTIONS_PROPTYPES = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string,
  action: PropTypes.func,
  conditionallyDisabled: PropTypes.func,
}));

const ActionsButtons = ({ actions, row, loading = false }) => (
  actions && actions.map(({
    label, action, conditionallyDisabled, dinamicLabel, disabled,
  }) => (
    <Button
      variant="link"
      className="action-button"
      key={`action-button-${label}`}
      disabled={disabled || (conditionallyDisabled && conditionallyDisabled(row))}
      onClick={() => {
        !loading && action && action(row);
        document.body.click();
      }}
    >
      {dinamicLabel ? dinamicLabel(row) : label}
    </Button>
  )));

ActionsButtons.propTypes = {
  actions: ACTIONS_PROPTYPES,
  row: PropTypes.shape({}),
};
ActionsButtons.defaultProps = {
  actions: null,
  row: null,
};

const ActionsPopOver = ({
  children, actions, row, loading,
}) => (
  <OverlayTrigger
    trigger="click"
    placement="bottom"
    rootClose
    overlay={(
      <Popover>
        <Popover.Content>
          <ButtonGroup vertical>
            {children || <ActionsButtons actions={actions} row={row} loading={loading} />}
          </ButtonGroup>
        </Popover.Content>
      </Popover>
            )}
  >
    <ActionsButton disabled={loading} />
  </OverlayTrigger>
);

ActionsPopOver.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  actions: ACTIONS_PROPTYPES,
  row: PropTypes.shape({}),
  loading: PropTypes.bool,
};
ActionsPopOver.defaultProps = {
  children: null,
  actions: null,
  row: null,
  loading: false,
};

export default ActionsPopOver;
