import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  Modal, Form, InputGroup, Row,
  Col, Container, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useFailoverParameter from '@pages/FailoverViewer/hooks/useFailoverParameter';

import tSchema from '@lang/schema';

// eslint-disable-next-line import/order
import { requestWithMessage } from '@common/utilities/Notification';

import './EditFailOverModal.scss';

import ConfigContext from '@contexts/config';

const EditFailOverModalFactory = ({ apiService }) => {
  const EditFailOverModal = ({
    show,
    onClose,
    parameterName = 'Parameter',
    parameterType,
    parameterValue,
  }) => {
    const { t } = useTranslation();
    const { setConfigValue } = useContext(ConfigContext);

    const renderInput = (value, handleChange, isSubmitting) => {
      switch (parameterType) {
        case 'ATTEMPTS':
          return (
            <>
              <Form.Control
                type="number"
                min="0"
                placeholder="Value"
                aria-label="Value"
                aria-describedby="value"
                name="value"
                value={value}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputGroup.Text style={{ borderRadius: '0 .25rem .25rem 0' }}>
                {t(tSchema.dem.failoverParameters.attempts)}
              </InputGroup.Text>
            </>
          );
        case 'MINUTES':
          return (
            <>
              <Form.Control
                type="number"
                min="0"
                placeholder="Value"
                aria-label="Value"
                aria-describedby="value"
                name="value"
                value={value}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputGroup.Text style={{ borderRadius: '0 .25rem .25rem 0' }}>
                {t(tSchema.dem.failoverParameters.minutes)}
              </InputGroup.Text>
            </>
          );
        case 'HOURS':
          return (
            <>
              <Form.Control
                type="number"
                min="0"
                placeholder="Value"
                aria-label="Value"
                aria-describedby="value"
                name="value"
                value={value}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputGroup.Text style={{ borderRadius: '0 .25rem .25rem 0' }}>
                {t(tSchema.dem.failoverParameters.hours)}
              </InputGroup.Text>
            </>
          );
        case 'DAYS':
          return (
            <>
              <Form.Control
                type="number"
                min="0"
                placeholder="Value"
                aria-label="Value"
                aria-describedby="value"
                name="value"
                value={value}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputGroup.Text style={{ borderRadius: '0 .25rem .25rem 0' }}>
                {t(tSchema.dem.failoverParameters.days)}
              </InputGroup.Text>
            </>
          );
        case 'BOOLEAN':
          return (
            <Form.Control
              required
              placeholder="Select a status"
              as="select"
              name="value"
              value={value}
              onChange={handleChange}
              disabled={isSubmitting}
            >
              <option key="status-option-active" value="1">
                Active
              </option>
              <option key="status-option-inactive" value="0">
                Inactive
              </option>
            </Form.Control>
          );
        default:
          return (
            <Form.Control
              type="number"
              min="0"
              placeholder="Value"
              aria-label="Value"
              aria-describedby="value"
              name="value"
              value={value}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          );
      }
    };

    const { editFailOver, isLoading } = useFailoverParameter({ parameter: parameterName });

    const onSubmit = async ({ code, value }, setSubmitting) => {
      await requestWithMessage({
        errorMessage: 'Failed to fetch failover parameter',
        request: () => apiService.updateParameter({ parameter: code, value }),
        setLoading: setSubmitting,
        onSuccess: () => {
          if (code === 'customUserTimeout') {
            setConfigValue('timeOut', value);
          }
          onClose();
        },
        successMessage: `Successfully updated ${parameterName}`,
      });
    };

    return (
      <Modal
        backdrop="static"
        show={show}
        onHide={onClose}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            Update
            {' '}
            {parameterName}
            {' '}
            parameter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          <Formik
            initialValues={{
              ...editFailOver,
              value: parameterValue,
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values,
                setSubmitting);
            }}
          >
            {
              ({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Container fluid id="edit-failover-container" className="d-flex justify-content-center align-content-center">
                  {isLoading ? (
                    <div className="loading-div align-self-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">{t(tSchema.common.loading)}</span>
                      </div>
                    </div>
                  ) : (
                    <Col>
                      <Form
                        className="py-2"
                        style={{ boxSizing: 'border-box' }}
                        onSubmit={handleSubmit}
                      >
                        <p className="mb-3">
                          {values?.description}
                        </p>

                        <Row className="d-flex flex-nowrap px-3">
                          <Form.Label
                            className="mr-4"
                            style={{
                              justifySelf: 'center',
                              alignSelf: 'center',
                            }}
                          >
                            Value
                          </Form.Label>
                          <InputGroup className="mb-3">
                            {renderInput(values?.value, handleChange, isSubmitting)}
                          </InputGroup>
                        </Row>
                        { isLoading === false && (
                          <Row className="d-flex justify-content-end">
                            {' '}
                            <Button
                              variant="primary"
                              type="submit"
                              className="mr-2"
                              disabled={isSubmitting}
                            >
                              {t(tSchema.common.save)}
                            </Button>
                            <Button variant="primary" onClick={onClose} disabled={isSubmitting}>
                              {t(tSchema.common.cancel)}
                            </Button>
                          </Row>
                        )}
                      </Form>
                    </Col>
                  )}
                </Container>
              )
            }
          </Formik>
        </Modal.Body>
      </Modal>
    );
  };
  EditFailOverModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    parameterName: PropTypes.string.isRequired,
    parameterType: PropTypes.string.isRequired,
    parameterValue: PropTypes.number.isRequired,
  };

  return EditFailOverModal;
};
EditFailOverModalFactory.propTypes = {
  apiService: PropTypes.shape({
    getParametersList: PropTypes.func,
    updateParameter: PropTypes.func,
    getParameter: PropTypes.func,
  }).isRequired,
};
export default EditFailOverModalFactory;
