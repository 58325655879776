import actions from './img/actions.JPG';
import deleteImage from './img/modal_delete_query.JPG';

const SectionDeleteQuery = () => (
  <div className="SectionDeleteAcceptance">
    <p>
      To delete a query subscription,
      the user has to select &quot;Delete&quot; in the &quot;Actions&quot;
      button for the corresponding query.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal shows the information about the query,
      asking the user for confirmation to delete the query.
    </p>
    <img src={deleteImage} alt="Delete Acceptance" />
    <p>
      After deleting a query subscription,
      the results grid is updated not displaying the removed element.
    </p>
  </div>
);

export default SectionDeleteQuery;
