import { fetchLogbookResourceData } from '@services/tripLogbook/api';
import { get } from '@services/utils';

export function fetchTypes(scenarioId) {
  return (option) => (option === 'vesselGroups' ? get()(`vrService/groupRecord/getGroups/list/${scenarioId}`)
    : get()(`logbook/options/${option}`));
}

/**
 * @param {String} filters
 * @param {{limit: number, page: number, sort: string, direction: string}} query The query params
 * @returns {Promise<Response>}
 */
export function fetchTripList(filters, query) {
  return fetchLogbookResourceData('fishing-trips', filters, query);
}

export function exportData() {
  return (filters, type) => get()(`logbook/fishing-trips/${filters}/export?as=${type}`.replace('//', '/'));
}

export default {
  fetchTypes,
};
