import { any, map, propEq } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import { apiFetchService } from '@services/serviceStatus/api';

import './style.scss';

import { useRequestWithLoading } from '@common/hooks/request';

const schema = tSchema.servicesMonitoring;

const serviceNames = [
  'apiGateway',
  'faService',
  'vrService',
  'message-handler',
  'validation',
  'isr-service',
];

const rowStatus = (row) => {
  let status = 'Unknown';
  if (row.status === true) {
    status = 'Up';
  } else if (row.status === false) {
    status = 'Down';
  }
  return status;
};

const rowRender = (row) => (
  <div className="d-flex align-items-center align-center">
    <div className="status">{rowStatus(row)}</div>
    <div
      className={`icon-status ${rowStatus(row).toLowerCase()} `}
    />
  </div>
);

const getTableConfig = (t) => [
  {
    text: t(schema.serviceName),
    code: 'name',
  },
  {
    text: t(schema.status),
    code: 'status',
    render: rowRender,
  }];

const ServiceViewer = () => {
  const { t } = useTranslation();
  const tableConfig = useMemo(() => getTableConfig(t), [t]);
  const [services, setServices] = useState(
    map((s) => ({ name: s, status: null }))(serviceNames),
  );

  const [loading, setLoading] = useState(false);
  const requestWithLoading = useRequestWithLoading();

  useEffect(() => {
    requestWithLoading(async () => {
      const queries = await Promise.all(
        serviceNames.map(apiFetchService()),
      );

      const data = await Promise.all(queries.map((x) => x.json()));
      setServices(
        data.map((d) => ({
          name: d[0].ServiceName,
          status: any(propEq('Status', 'passing'))(d),
        })),
      );
    }, setLoading);
  }, []);

  return (
    <Container className="service-viewer ml-4">
      <Row>
        <DataTable
          tableId="services"
          tableConfig={tableConfig}
          loading={loading}
          rowData={services}
          pagination={false}
        />
      </Row>
    </Container>
  );
};

export default ServiceViewer;
