import PropTypes from 'prop-types';
import {
  filter as rFilter,
} from 'ramda';
import {
  Col, Form, Row,
} from 'react-bootstrap';

import { filterTypes } from './config';

const FilterRow = ({
  filter,
  searchOptions,
  setFormErrors,
}) => (
  <Row key={filter.code} className="mb-1 d-flex align-items-center">
    <Form.Group as={Col} xs={12} sm={6} md={3} lg={2} controlId={`${filter.code}Enabled`} className="mb-0 filter-name">
      <Form.Check
        type="checkbox"
        label={filter.label}
        defaultChecked="checked"
        name={filter.alwaysPresent ? '' : `${filter.code}-enabled`}
        disabled={filter.alwaysPresent}
      />
    </Form.Group>
    <Form.Group as={Col} xs={12} sm={6} md={9} lg={5} className="filter-content mb-0">
      <Row>
        {filter.alwaysPresent && <input type="hidden" value="on" name={`${filter.code}-enabled`} />}
        {filter.type === 'multichoice' ? filterTypes[filter.type].render(filter, searchOptions)
          : filterTypes[filter.type].render(filter, searchOptions,
            (error) => setFormErrors((prev) => rFilter(
              (x) => x, { ...prev, ...error },
            )))}
      </Row>
    </Form.Group>
  </Row>
);
FilterRow.propTypes = {
  filter: PropTypes.shape({
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    alwaysPresent: PropTypes.bool,
  }).isRequired,
  searchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      type: PropTypes.string,
      single: PropTypes.bool,
    }),
  ).isRequired,
  setFormErrors: PropTypes.func.isRequired,
};

FilterRow.defaultProps = {
};

export default FilterRow;
