import PropTypes from 'prop-types';
import { path } from 'ramda';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

function ContextSelectFormField({
  rule, disabled, getOptions,
}) {
  const { t } = useTranslation();
  return (
    <Form.Group as={Col} controlId="formContext">
      <Form.Label>
        {t(tSchema.dmaAdmin.brEngine.createEditView.context)}
      </Form.Label>
      <Form.Control
        required
        as="select"
        name="context"
        defaultValue={path(['context', 'id'])(rule)}
        disabled={disabled}
      >
        {getOptions('brcontext')}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(tSchema.dmaAdmin.brEngine.createEditView.validation.emptyContext)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
ContextSelectFormField.propTypes = {
  getOptions: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired,
};
export default ContextSelectFormField;
