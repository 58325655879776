import SectionFailoverParametersEdit from './SectionFailoverParametersEdit';
import SectionFailoverParametersTable from './SectionFailoverParametersTable';

const FAILOVER = {
  EDIT: {
    title: 'Failover Parameters Edit',
    body: <SectionFailoverParametersEdit />,
  },
  TABLE: {
    title: 'Failover Parameters Table',
    body: <SectionFailoverParametersTable />,
  },
};

export default FAILOVER;
