import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './style.scss';

const ToggleTable = ({ value, onClick }) => {
  const handleClick = useCallback(() => onClick && onClick(!value), [value, onClick]);
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>
          <strong>{value ? 'Less information' : 'More information'}</strong>
        </Tooltip>
        )}
    >
      <Button
        onClick={handleClick}
        variant="link"
        className="table-header-toggle"
      >
        {
             value
               ? <span>-</span>
               : <span>+</span>
            }
      </Button>
    </OverlayTrigger>
  );
};

ToggleTable.propTypes = {
  value: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ToggleTable;
