import i18next from 'i18next';

import tSchema from '@lang/schema';

import { valueOptions, valueOptionsLogBook } from '../../../config';

import { showErrorNotification } from '@common/utilities/Notification';

const { t } = i18next;

const getValuesFromJSON = async ({ allOk, options }) => {
  if (allOk) {
    const optionsJson = await Promise.all(options.map((x) => x?.json()));
    const obj = {};
    const newValueOptions = [
      ...valueOptions,
      ...valueOptionsLogBook,
      'parties',
      'validationStatusincoming',
      'validationStatusoutgoing',
      'exchangeStatus',
      'reportStatus',
      'responseStatus',
      'type'];
    optionsJson.forEach((x, i) => {
      obj[newValueOptions[i]] = x;
    });
    return obj;
  }
  showErrorNotification(
    t(tSchema.notifications.common.fetchFilterOptions),
  );
  return {};
};

export default getValuesFromJSON;
