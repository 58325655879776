/* eslint-disable linebreak-style */
import view from './img/view.png';

const SectionViewReportAcceptance = () => (
  <div className="SectionViewAcceptance">
    <p>
      To visualize full information of an acceptance rule,
      in the results grid the user can either click on the &quot;Actions&quot; button
      corresponding to the row of that specific rule and then click on &quot;View&quot; button,
      or double click on that acceptance rule.
    </p>
    <p>
      A modal displays the information
      related to the selected report acceptance, in read-only format:
    </p>
    <p>
      <img src={view} alt="View Acceptance" />
    </p>

  </div>
);

export default SectionViewReportAcceptance;
