import PropTypes from 'prop-types';
import { any, prop, propEq } from 'ramda';
import {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import getRoutePaths from '@pages/App/utils/routePaths';

import Section from '@components/Section';

import * as apiService from '@services/failover/api';

import QuerySubscriptionSection from './components/QuerySubscriptions';
import ReportSubscriptionsSection from './components/ReportSubcriptions';

import { requestWithMessage } from '@common/utilities/Notification';

import './style.scss';

const QuerySubscriptionsViewer = ({
  title, path,
}) => {
  const { t } = useTranslation();
  const [maximumDaysToQuery, setMaximumDaysToQuery] = useState(0);
  const history = useHistory();
  const { sectionId } = useParams();

  const routePaths = useMemo(() => getRoutePaths(t), [t]);

  const fetchMaximumDaysToQuery = useCallback(() => {
    requestWithMessage({
      errorMessage: 'Failed to fetch failover parameter',
      request: () => apiService.getParameter({ parameter: 'maximumDaysToQuery' }),
      onSuccess: async (response) => {
        const data = await response.json();
        setMaximumDaysToQuery(data.value);
      },
    });
  });

  useEffect(() => {
    fetchMaximumDaysToQuery();
  }, [fetchMaximumDaysToQuery]);

  const sections = [
    {
      id: routePaths.queryReportMGMT.sections.QUERY_SUBCRIPTIONS.url,
      key: routePaths.queryReportMGMT.sections.QUERY_SUBCRIPTIONS.url,
      title: routePaths.queryReportMGMT.sections.QUERY_SUBCRIPTIONS.title,
      render: () => (
        <QuerySubscriptionSection maximumDaysToQuery={maximumDaysToQuery} />
      ),
    },
    {
      id: routePaths.queryReportMGMT.sections.REPORT_SUBCRIPTIONS.url,
      key: routePaths.queryReportMGMT.sections.REPORT_SUBCRIPTIONS.url,
      title: routePaths.queryReportMGMT.sections.REPORT_SUBCRIPTIONS.title,
      render: () => (
        <ReportSubscriptionsSection maximumDaysToQuery={maximumDaysToQuery} />
      ),
    },
  ];

  const currentSection = sections.find((s) => s.id === sectionId);

  useEffect(() => {
    document.title = `${window.env.REACT_APP_TITLE} - ${title} ${
      currentSection ? currentSection.title : ''
    }`;
  }, [currentSection, title]);

  if (!any(propEq('id', sectionId), sections)) {
    return <Redirect to={`${path}/${sections[0].id}`} />;
  }

  return (
    <Container fluid id="trip-logbook-viewer">
      <Section
        tabTitleGetter={prop('title')}
        sections={sections}
        defaultSection={currentSection}
        className="main"
        onCurrentSectionChangeCallback={(section) => history.push(`${path}/${section.id}`)}
      />
    </Container>
  );
};

QuerySubscriptionsViewer.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default QuerySubscriptionsViewer;
