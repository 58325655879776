import attachFile from './img/attachSection.png';

const SectionAttachFile = () => (
  <div className="SectionQueryAcceptance">
    <p>
      To attach a file to a report,
      {' '}
      in the results grid the user must click on the &quot;Actions&quot;
      button corresponding to the row of that specific report,
      and then click on &quot;Attach File&quot; button.
    </p>
    <p>
      Attachment section displays the information related
      to the selected report:
    </p>
    <p>
      <img src={attachFile} alt="editForm" />
    </p>
    <p>
      The attachment cannot be deleted by Reader user.
      Only Editor and Approver could add a new attachment.
    </p>
    <p>
      After editing the Attachment Section, the list of attachments is updated.
    </p>
  </div>
);

export default SectionAttachFile;
