import hljs from 'highlight.js/lib/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import 'highlight.js/styles/github.css';

const HighlightText = ({ children, language, className }) => {
  hljs.registerLanguage(language.name, language);

  useEffect(() => {
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block);
    });
  }, [children]);

  return (
    <pre className={`highlighted-text${className ? ` ${className}` : ''}`}>
      <code className={language.name}>{children}</code>
    </pre>
  );
};

HighlightText.propTypes = {
  children: PropTypes.string.isRequired,
  language: PropTypes.func.isRequired,
  className: PropTypes.string,
};

HighlightText.defaultProps = {
  className: null,
};

export default HighlightText;
