function getVersionData(data, version) {
  let result = null;
  if (data != null && version != null) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].uuid === version.value) {
        result = data[i];
        break;
      }
    }
  }
  return result;
}

export default getVersionData;
