import { LOGBOOK_FILTERS } from './filters';
import { REPORT_DATA_TABLE } from './reportDataTable';
import { TRIP_DATA_TABLE } from './tripDataTable';

const LOGBOOK = {
  LOGBOOK_FILTERS: {
    title: 'Logbook filters',
    body: LOGBOOK_FILTERS,
  },
  TRIP_DATA_TABLE: {
    title: 'Data grid',
    body: TRIP_DATA_TABLE,
  },
  REPORT_DATA_TABLE: {
    title: 'Report Data Grid',
    body: REPORT_DATA_TABLE,
  },
};

export default LOGBOOK;
