import { getTableConfig } from '../../config';
import TableCheckColumn from '../TableCheckColumn';

import { getCurrentVesselsPagination } from './utils';

const tableCheckConfig = ({
  vessels, page, pageSize, selectedRows, onSelectedRows: handleSelectedRows, checkAdded, t,
}) => [
  TableCheckColumn({
    vessels: getCurrentVesselsPagination(vessels, page, pageSize),
    selectedRows,
    onSelectedRows: handleSelectedRows,
    checkAdded,
  }),
  ...getTableConfig(t)];

export default tableCheckConfig;
