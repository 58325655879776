import PropTypes from 'prop-types';
import React from 'react';
import {
  Form,
} from 'react-bootstrap';

import { Else, If, Then } from '@components/Conditionals/If';

import './style.scss';

const TextInputField = ({ label, value, children }) => (
  <Form.Group className="text-input-field">
    {
      label && (
      <Form.Label className="pt-1">{label}</Form.Label>
      )
    }
    {children
      ? (
        <If conditional={typeof children === 'string'}>
          <Then>
            <div className="form-control">{children}</div>
          </Then>
          <Else>
            {children}
          </Else>
        </If>
      )
      : <Form.Control type="text" disabled value={value || ''} />}
  </Form.Group>
);

TextInputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
TextInputField.defaultProps = {
  children: null,
};

export default TextInputField;
