import { Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import React, {
  useRef, useState, useMemo, useCallback, useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import getVersionData from '@pages/IsrViewer/commons/utils';
import IsrViewerDetailsTabPanel from '@pages/IsrViewer/components/details/components/tabPanel/IsrViewerDetailsTabPanel';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import IsrViewerDetailsCatchProductsReceived from './components/received';
import {
  getProductsTableConfig,
  getCatchTableConfig,
  getDiscardsTableConfig,
  getOffloadedTableConfig,
  switchTabs,
  getTabs,
  exclamationPicker,
} from './utils';

import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerDetailsTabCatchProducts = ({
  module, selectedUuid, selectedRecipient, selectedVersion, isHiddenISRTab,
}) => {
  const productsTableRef = useRef();

  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const { t } = useTranslation();

  const productsTableConfig = useMemo(() => getProductsTableConfig(t), [t]);
  const catchTableConfig = useMemo(() => getCatchTableConfig(t), [t]);
  const discardsTableConfig = useMemo(() => getDiscardsTableConfig(t), [t]);
  const offloadedTableConfig = useMemo(() => getOffloadedTableConfig(t), [t]);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['catchProductsTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.catchProductsTab);
        const versionData = getVersionData(data.catchProductsTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid,
    selectedRecipient,
    isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  const currentTabs = getTabs(module, t);

  const receivedSection = (
    <IsrViewerDetailsCatchProductsReceived data={tabData.received} loading={loading} />
  );

  const catchTable = (
    <Container fluid className="isrCatch">
      <DataTable
        rowData={tabData.catchOnBoard}
        idColumn="validityDate"
        tableId="isrCatchTable"
        tableConfig={catchTableConfig}
        showPagination={false}

      />
    </Container>
  );

  const discardsTable = (
    <Container fluid className="isrDiscards">
      <DataTable
        rowData={tabData.discards}
        idColumn="validityDate"
        tableId="isrDiscardsTable"
        tableConfig={discardsTableConfig}
        showPagination={false}
      />
    </Container>
  );

  const offloadedTable = (
    <Container fluid className="isrOffloaded">
      <DataTable
        rowData={tabData.catchOffloaded}
        idColumn="validityDate"
        tableId="isrOffloadedTable"
        tableConfig={offloadedTableConfig}
        showPagination={false}
      />
    </Container>
  );

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <Container
          fluid
          style={{
            paddingBottom: '0.625rem',
          }}
        >
          <Container fluid className="isrProducts">
            <DataTable
              rowData={tabData.rows}
              idColumn="validityDate"
              tableId="isrProductsTable"
              ref={productsTableRef}
              tableConfig={productsTableConfig}
              showPagination={false}
            />
          </Container>
          <Container fluid className="isrProdCatchTabs">
            <Tabs
              className="isrReportsTabs"
              value={selectedTab}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              {currentTabs && currentTabs?.map((r) => (
                <Tab label={(
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  >
                    <span>{r.label}</span>
                    {exclamationPicker('WARN')}
                  </div>
            )}
                />
              ))}
            </Tabs>
            {selectedTab != null && currentTabs.map((tab, indexTab) => (
              <IsrViewerDetailsTabPanel value={selectedTab} index={indexTab}>
                {switchTabs(
                  tab,
                  t,
                  catchTable,
                  discardsTable,
                  offloadedTable,
                  receivedSection,
                )}
              </IsrViewerDetailsTabPanel>
            ))}
          </Container>
          <Container
            fluid
          >
            <Row
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '10px',
              }}
            >
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.observationsComments)}
                    :
                  </Form.Label>
                  <p>{tabData.observationAndComments}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      )}

    </>
  );
};

IsrViewerDetailsTabCatchProducts.propTypes = {
  module: PropTypes.instanceOf(Object).isRequired,
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabCatchProducts;
