import Burguer from './burguer';
import Filters from './filters';
import LogOut from './logOut';
import PAGINATION from './pagination';
import SORTING from './sorting';
import Version from './version';

const COMMON = {
  FILTER: {
    title: 'Filters',
    body: <Filters />,
  },
  getFilterWithSection: (section) => ({
    title: 'Filters',
    body: <Filters section={section} />,
  }),
  PAGINATION: {
    title: 'Pagination',
    body: PAGINATION,
  },
  SORTING: {
    title: 'Grid Sorting',
    body: SORTING,
  },
  BURGUER: {
    title: 'Hide Menu',
    body: Burguer,
  },
  LOGOUT: {
    title: 'Log Out',
    body: LogOut,
  },
  VERSION: {
    title: 'Version of the application',
    body: Version,
  },
};

export default COMMON;
