import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, {
  useEffect, useCallback, useRef, useState,
  useMemo,
} from 'react';

import { useSideFilterContext } from '@pages/FishingTripsLogbook/SideFilterContext/SideFilterContext';

import SideFilters from '@components/SideFilters';

import { apiSaveOnSelectFavourite } from '@services/userPreferences/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import { defaultValues as defaultValuesFunc, schemaValidation as schemaValidationFunc } from './utils';

const SearchSection = ({
  isUIBlocked,
  onAddFavouriteFilter: handleAddFavouriteFilter,
  favouriteFilters,
  initialChoosedFilters,
  view,
}) => {
  const defaultValues = useMemo(() => defaultValuesFunc(view === 'reportView'), [view]);
  const schemaValidation = useMemo(() => schemaValidationFunc(view === 'reportView'), [view]);
  const formikRef = useRef(null);
  const [initialFormikFilters, setInitialFormikFilters] = useState(defaultValues);
  const [initialActiveFilters, setInitialActiveFilters] = useState([]);
  const { setUserPreferences, userPreferences } = useUserPreferences();
  const localActiveFilter = useRef([]);
  const {
    sideFilterState:
    { activeFilters, choosedFilters },
    setSideFilterState,
  } = useSideFilterContext();

  useEffect(() => {
    if (activeFilters.length) {
      const initialFiltersValues = { ...defaultValues };
      activeFilters.forEach((activeFilter) => {
        if ('values' in activeFilter) {
          Object.keys(defaultValues).forEach((defaultValueKey) => {
            if (defaultValueKey === activeFilter.code) {
              initialFiltersValues[defaultValueKey] = activeFilter.values;
            }
          });
        }
      });

      const tripViewActiveFilters = activeFilters.filter((activeFilter) => activeFilter.code !== 'reportVersion');

      localActiveFilter.current = activeFilters;

      view === 'tripView' ? setInitialActiveFilters(tripViewActiveFilters) : setInitialActiveFilters(activeFilters);

      setInitialFormikFilters(initialFiltersValues);
    } else {
      setInitialActiveFilters([]);
      localActiveFilter.current = [];
    }
  }, [activeFilters, defaultValues, view]);

  useEffect(() => {
    if (favouriteFilters) {
      const initialFavouritesFilters = { ...defaultValues };
      const newActiveFilters = [];

      favouriteFilters.forEach((favouriteFilter) => {
        Object.keys(defaultValues).forEach((defaultValueKey) => {
          if (defaultValueKey === favouriteFilter.code) {
            initialFavouritesFilters[defaultValueKey] = favouriteFilter.values;
          }
        });
      });

      initialChoosedFilters.forEach((initialChoosedFilter) => {
        favouriteFilters.forEach((favouriteFilter) => {
          if (favouriteFilter.code === initialChoosedFilter.code) {
            newActiveFilters.push({
              ...initialChoosedFilter,
              checked: true,
              values: favouriteFilter.values,
            });
          }
        });
      });

      setInitialFormikFilters(initialFavouritesFilters);

      setSideFilterState((prevState) => ({
        ...prevState,
        activeFilters: newActiveFilters,
        choosedFilters: initialChoosedFilters.filter(
          (choosedFilter) => !favouriteFilters.find(
            (favouriteFilter) => (favouriteFilter.code === choosedFilter.code),
          ),
        ),
      }));
    }
  }, [favouriteFilters]);

  useEffect(() => () => {
    let newActiveFilters = [];

    const formikValues = formikRef.current?.values;

    localActiveFilter.current.forEach((activeFilter) => {
      if (activeFilter.code === 'reportVersion' && view === 'tripView') {
        newActiveFilters = [...newActiveFilters, activeFilter];
      }

      Object.keys(formikValues).forEach((valueKey) => {
        if (activeFilter.code === valueKey) {
          newActiveFilters = [
            ...newActiveFilters,
            { ...activeFilter, values: formikValues[valueKey] },
          ];
        }
      });
    });

    if (!isEqual(defaultValues, formikValues) && newActiveFilters.length) {
      setSideFilterState((prevState) => ({ ...prevState, activeFilters: newActiveFilters }));
    }
  },
  []);

  const handleResetFilter = useCallback(() => {
    setSideFilterState((prevState) => ({ ...prevState, resetFilters: true }));
    setInitialFormikFilters(defaultValues);
  },
  []);

  useEffect(() => {
    handleResetFilter();
  }, [userPreferences.lastActiveFa?.name]);

  const handleAddFilter = useCallback((optionSelected) => {
    const { value: filterSelected } = optionSelected;
    const newFiltersArray = choosedFilters.filter((option) => option.code !== filterSelected);
    const newActiveFilters = [choosedFilters.find((option) => option.code === filterSelected)];
    const formikValues = formikRef.current?.values;

    activeFilters.forEach((activeFilter) => {
      Object.keys(formikValues).forEach((formikKey) => {
        if (formikKey === activeFilter.code) {
          newActiveFilters.push({
            ...activeFilter,
            values: formikValues[formikKey],
          });
        }
      });
    });

    setSideFilterState((prevState) => ({
      ...prevState,
      choosedFilters: newFiltersArray,
      activeFilters: newActiveFilters,
    }));
  }, [activeFilters, choosedFilters, view, formikRef.current?.values]);

  const handleSubmit = useCallback((formData) => {
    if (!isUIBlocked) {
      const searchFilters = {};
      Object.keys(formData).forEach((key) => {
        if (!isEqual(formData[key], defaultValues[key]) || typeof formData[key] === 'boolean') {
          activeFilters?.forEach((filter) => {
            if (filter.checked && filter.code === key) {
              searchFilters[key] = formData[key];
            }
          });
        }
      });
      setSideFilterState((prevState) => ({ ...prevState, submitedFilters: searchFilters }));
    }
  }, [isUIBlocked, defaultValues, activeFilters, setSideFilterState]);

  const handleFieldChecked = useCallback((name, checked) => {
    const formikValues = formikRef.current?.values;

    const newActiveFilters = activeFilters.map((activeFilter) => (
      activeFilter.code === name
        ? { ...activeFilter, values: formikValues[name], checked }
        : { ...activeFilter, values: formikValues[activeFilter.code] }
    ));

    setSideFilterState((prevState) => ({ ...prevState, activeFilters: newActiveFilters }));
  }, [activeFilters, view, formikRef.current?.values]);

  const handleSaveFavourite = useCallback(async (name, values, activeSideFilters) => {
    const newReportViewFavourites = { name, filters: [] };

    activeSideFilters.forEach((activeSideFilter) => {
      Object.keys(values).forEach((valueKey) => {
        if (activeSideFilter.checked && activeSideFilter.code === valueKey) {
          newReportViewFavourites.filters.push({ code: valueKey, values: values[valueKey] });
        }
      });
    });

    const { lastActiveFa, logbookFavFilters, lastActiveIsr } = userPreferences;
    const newUserPreferences = {
      ...{ lastActiveFa, logbookFavFilters, lastActiveIsr },
      logbookFavFilters: logbookFavFilters
        ? [...userPreferences.logbookFavFilters, newReportViewFavourites]
        : [newReportViewFavourites],
    };

    const resSaveSelectFavourite = await apiSaveOnSelectFavourite()(newUserPreferences);
    if (resSaveSelectFavourite.ok) {
      setUserPreferences((prevState) => ({ ...prevState, ...newUserPreferences }));
      handleAddFavouriteFilter();
    }
  },
  [handleAddFavouriteFilter, userPreferences, setUserPreferences, view]);

  return (
    <SideFilters
      formikRef={formikRef}
      initialFilters={initialFormikFilters}
      schemaValidation={schemaValidation}
      activeFilters={initialActiveFilters}
      choosedFilters={choosedFilters}
      onResetFilter={handleResetFilter}
      onAddFilter={handleAddFilter}
      onSubmit={handleSubmit}
      onFieldChecked={handleFieldChecked}
      onSaveFavourite={handleSaveFavourite}
    />
  );
};

SearchSection.propTypes = {
  isUIBlocked: PropTypes.bool,
  onAddFavouriteFilter: PropTypes.func.isRequired,
  favouriteFilters: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    values: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({ from: PropTypes.string, to: PropTypes.string })),
    ]),
  })),
  initialChoosedFilters: PropTypes.arrayOf(PropTypes.shape({})),
  view: PropTypes.string,
};

SearchSection.defaultProps = {
  isUIBlocked: false,
  favouriteFilters: null,
  initialChoosedFilters: [],
  view: 'tripView',
};

export default SearchSection;
