/* eslint-disable import/order */
import Section from '@components/Section';
import getRoutePaths from '@pages/App/utils/routePaths';
import services from '@services/messages/api';
import { BaseMessageProvider } from '@services/messages/BaseMessageContext';
import PropTypes from 'prop-types';
import { any, prop, propEq } from 'ramda';
import { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LogbookViewerSectionWithTabs from './LogbookViewerSectionWithTabs';
import './style.scss';
import { getSearchableAttributes } from '@services/vessel_record/actions/vessel';

const LogbookViewer = ({
  title, path,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sectionId } = useParams();
  const dispatch = useDispatch();
  const routePaths = useMemo(() => getRoutePaths(t), [t]);

  const sections = [
    {
      id: routePaths.logbookViewer.sections.TRIP_VIEW.url,
      key: routePaths.logbookViewer.sections.TRIP_VIEW.url,
      title: routePaths.logbookViewer.sections.TRIP_VIEW.title,
      component: <LogbookViewerSectionWithTabs isTrip />,
    },
    {
      id: routePaths.logbookViewer.sections.REPORT_VIEW.url,
      key: routePaths.logbookViewer.sections.REPORT_VIEW.url,
      title: routePaths.logbookViewer.sections.REPORT_VIEW.title,
      component: <LogbookViewerSectionWithTabs isTrip={false} />,
    },
  ];

  const currentSection = sections.find((s) => s.id === sectionId);

  useEffect(() => {
    document.title = `${window.env.REACT_APP_TITLE} - ${title} ${
      currentSection ? currentSection.title : ''
    }`;
  }, [currentSection]);

  useEffect(() => {
    dispatch(getSearchableAttributes());
  }, []);

  if (!any(propEq('id', sectionId), sections)) {
    return <Redirect to={`${path}/${sections[0].id}`} />;
  }

  return (
    <Container fluid id="trip-logbook-viewer">
      <BaseMessageProvider services={services('trafficMonitoring')}>
        <Section
          tabTitleGetter={prop('title')}
          sections={sections}
          defaultSection={currentSection}
          className="main"
          onCurrentSectionChangeCallback={(section) => history.push(`${path}/${section.id}`)}
        />
      </BaseMessageProvider>
    </Container>
  );
};

LogbookViewer.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default LogbookViewer;
