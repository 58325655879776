import {
  del,
  formatSortParams,
  get, post, put,
} from '../utils';

import { DOMAIN } from '@common/utils';

// const baseURL = 'scenarios/';
const baseURLFA = 'scenarios/';
const baseURLISR = 'isrScenarios/';

export const apiFetchUserScenarios = (userId, params, domain) => {
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) { return get()(`${baseURL}user/${userId}`, params); } return null;
};

export const apiFetchScenarios = (searchFilters, params, domain) => {
  const filter = searchFilters ? `/${searchFilters}` : '';
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) { return get()(`${baseURL}getAll${filter}`, formatSortParams(params)); } return null;
};

export const apiFetchScenario = (id, domain) => {
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) { return get()(`${baseURL}get/${id}`); } return null;
};

export const apiDownloadTableData = (searchFilters, type, domain) => {
  const filter = searchFilters ? `/${searchFilters}` : '';
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) {
    return get()(`${baseURL}export/${type}${filter}`);
  } return null;
};

export const apiSaveScenario = (scenario, domain) => {
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) {
    return scenario.id
      ? put()(`${baseURL}update`, scenario)
      : post()(`${baseURL}create`, scenario);
  } return null;
};

export const apiDeleteScenario = (id, domain) => {
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) {
    return id && del()(`${baseURL}delete/${id}`);
  } return null;
};

export const apiAssignScenario = (scenario, domain) => {
  let baseURL = null;
  switch (domain) {
    case DOMAIN.FA:
      baseURL = baseURLFA;
      break;
    case DOMAIN.ISR:
      baseURL = baseURLISR;
      break;
    default:
      return null;
  }
  if (baseURL) {
    const { isAssigned, ...rest } = scenario;
    return (isAssigned
      ? post()(`${baseURL}assign`, rest)
      : post()(`${baseURL}unassign`, rest));
  } return null;
};

export const apiFetchOptions = (optionObj) => {
  const { option, entity = 'scenarios', endpoint } = optionObj;
  return get()(endpoint || `${entity}/options/${option}`);
};
