import PropTypes from 'prop-types';
import React from 'react';

import './style.scss';

const RowFieldTable = ({
  columnConfig: { name, render },
  rowData,
  isFieldHidden,
  isFirstField,
  isCancelledDeleted,
  versionSymbol,
}) => {
  const newVersionSymbol = versionSymbol === '*' ? ` (${versionSymbol})` : ` ${versionSymbol}`;

  return (
    <td
      key={name}
      className={`${name} ${isCancelledDeleted ? 'cancelled-deleted-version' : ''}`}
      hidden={isFieldHidden}
    >
      {render(rowData)}
      {isFirstField && newVersionSymbol}
    </td>
  );
};

RowFieldTable.propTypes = {
  columnConfig: PropTypes.shape({
    name: PropTypes.string,
    render: PropTypes.func,
  }).isRequired,
  rowData: PropTypes.shape({}).isRequired,
  isFieldHidden: PropTypes.bool,
  isFirstField: PropTypes.bool,
  isCancelledDeleted: PropTypes.bool,
  versionSymbol: PropTypes.string,
};

RowFieldTable.defaultProps = {
  isFieldHidden: false,
  isFirstField: false,
  isCancelledDeleted: false,
  versionSymbol: '',
};

export default RowFieldTable;
