import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import messagesListOutgoingISRImg from './img/messages-list-outgoing-isr.png';
import messagesListOutgoingStatusISR from './img/messages-list-outgoing-status-isr.png';
import messagesListOutgoingStatus from './img/messages-list-outgoing-status.png';
import messagesListOutgoingImg from './img/messages-list-outgoing.png';

import { DOMAIN } from '@common/utils';

const SectionMessagesList = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="subSection">
      <p>
        The section on the left of the central panel displays a list
        where the user can see all the messages.
        Last sent messages are shown first.
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messagesListOutgoingImg} alt="messagesListOutgoing" />
          : <img src={messagesListOutgoingISRImg} alt="messagesListOutgoingISR" />}
      </p>
      <p>
        These are the possible status and indicators for the left column:
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messagesListOutgoingStatus} alt="messagesListOutgoing" />
          : <img src={messagesListOutgoingStatusISR} alt="messagesListOutgoingISR" />}
      </p>
      <p>
        More messages are shown when user scrolls down the list.
      </p>
      <p>
        User can see the total number of results at the bottom of the list.
      </p>
    </div>
  );
};

export default SectionMessagesList;
