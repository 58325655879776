import { DIRECTION } from '@common/utils';

export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DBL_CLICK = 2;
export const CURRENT = 'CURRENT';
export const CORRECTED = 'CORRECTED';
export const DELETED = 'DELETED';

export const DEFAULT_ORDER = {
  field: 'dateTime',
  order: DIRECTION.DESC,
};
