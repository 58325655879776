import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { DEPARTURE_SCHEMA, ENTRYINTOAREA_PROPTYPES } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const ReportDetailsEntryIntoArea = ({ entryIntoArea, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(
    DEPARTURE_SCHEMA,
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [currentEntryIntoArea, setCurrentEntryIntoArea] = useState([]);

  useEffect(() => setCurrentEntryIntoArea(
    (entryIntoArea && entryIntoArea[pageNumber - 1])
    || DEPARTURE_SCHEMA,
  ),
  [entryIntoArea, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-entryintoarea">
      <ReportHeader
        title="Entry into Area"
        versions={currentEntryIntoArea}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={entryIntoArea}
        onSelectPagination={setPageNumber}
      />

      <Row>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
          <TextInputField
            label={t(schema.fisheryType)}
            value={[currentVersion?.fisheryType, currentVersion?.fisheryTypeDescription].filter(Boolean).join(' - ')}
          />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.reasonCode)}
            value={currentVersion?.reason}
          />
          <TextInputField
            label={t(schema.reasonDescription)}
            value={currentVersion?.reasonDescription}
          />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.speciesTargetGroup)}
            value={currentVersion?.speciesTargetGroup}
          />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

ReportDetailsEntryIntoArea.propTypes = {
  entryIntoArea: ENTRYINTOAREA_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default ReportDetailsEntryIntoArea;
