import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ICON } from '@components/IconSvg/IconSvg';
import UploadReport from '@components/UploadReport/UploadReport';

import tSchema from '@lang/schema';

const schema = tSchema.dmaAdmin.referenceDataMGMT;

const ModalUploadReference = ({
  reports, onHideModal, show, error,
}) => {
  const { t } = useTranslation();
  const reportsArray = useMemo(() => reports && [
    {
      data: reports.inserted,
      icon: ICON.CHECKED,
      title: schema.upload.created,
    },
    {
      data: reports.updated,
      icon: ICON.CHECKED,
      title: schema.upload.updated,
    },
    {
      data: reports.discarded,
      icon: ICON.WARN,
      title: schema.upload.discarded,
    },
    {
      data: reports.errors,
      icon: ICON.CANCEL,
      title: schema.upload.errors,
    },
  ],
  [reports]);
  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHideModal}
      size="auto-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>
            {t(schema.upload.title)}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UploadReport reports={reportsArray} error={error && 'There was an error'} />
      </Modal.Body>
    </Modal>

  );
};

ModalUploadReference.propTypes = {
  reports: PropTypes.shape({
    inserted: PropTypes.arrayOf(PropTypes.string),
    updated: PropTypes.arrayOf(PropTypes.string),
    discarded: PropTypes.arrayOf(PropTypes.string),
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onHideModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

ModalUploadReference.defaultProps = {
  error: null,
};

export default ModalUploadReference;
