import version from './img/version.JPG';

const Version = (
  <div className="subSection">
    <p>
      The version of the application is displayed at the bottom line of the application panel.
    </p>
    <img src={version} alt="version" />
  </div>
);

export default Version;
