import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Button, Container, Form, FormGroup,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  userLogin as userLoginAction,
  checkLoginSession as checkLoginSessionAction,
} from '@services/authentication/actions';
import { getLogedIn } from '@services/authentication/reducers';

const Login = ({
  isLogedIn = false, externalLogin, checkLoginSession, userLogin, location,
}) => {
  useEffect(() => {
    checkLoginSession(externalLogin);
  }, [checkLoginSession, externalLogin]);

  return isLogedIn ? (
    <Redirect to={location.state ? location.state.referrer : '/'} />
  ) : (
    externalLogin || (
      <Container id="login">
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
            userLogin();
          }}
        >
          <FormGroup controlId="usernameField">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" name="username" autoComplete="username" />
          </FormGroup>
          <FormGroup controlId="passwordField">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" autoComplete="current-password" />
          </FormGroup>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
      </Container>
    )
  );
};

Login.propTypes = {
  isLogedIn: PropTypes.bool.isRequired,
  externalLogin: PropTypes.bool,
  checkLoginSession: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({ referrer: PropTypes.shape({}) }),
  }).isRequired,
};

Login.defaultProps = {
  externalLogin: false,
};

const mapStateToProps = (state) => ({
  isLogedIn: getLogedIn(state),
});

const mapDispatchToProps = {
  checkLoginSession: checkLoginSessionAction,
  userLogin: userLoginAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
