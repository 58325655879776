import actions from './img/actions.JPG';
import message from './img/message.JPG';
import moreColumns from './img/more_columns.JPG';
import table from './img/table.JPG';
import tripDetails from './img/trip_details.JPG';

export const REPORT_DATA_TABLE = (
  <div className="subSection">
    <p>
      The grid displayed on the Report tab displays the following columns:
      <ul>
        <li>Name</li>
        <li>Flag State</li>
        <li>CFR</li>
        <li>Version</li>
        <li>Activity Type</li>
        <li>Activity Date</li>
        <li>Report Type</li>
        <li>Received on</li>
        <li>Trip ID</li>
        <li>Actions</li>
      </ul>
    </p>
    <p>
      <img src={table} alt="report grid " />
    </p>
    <p>
      Also, in the header of the grid,
      there is a + button that allows the user to see the additional columns listed below:
      <ul>
        <li>UVI</li>
        <li>EXT</li>
        <li>IRCS</li>
        <li>ICCAT</li>
        <li>GFCM</li>
      </ul>
    </p>
    <p>
      <img src={moreColumns} alt="More columns" />
    </p>
    <p>
      In the Actions column, for every row, there is a button that makes some operations available:
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
    <p>
      Selecting the &quot;View Message&quot; action, you can see the raw XML message sent.
    </p>
    <p>
      <img src={message} alt="XML Message" />
    </p>
    <p>
      Selecting the &quot;View Report Details&quot;, or double clicking on the row,
      a new modal window opens displaying the information about the trip to which
      the report selected is associated.
    </p>
    <p>
      <img src={tripDetails} alt="Trip Details" />
    </p>
  </div>
);

export default REPORT_DATA_TABLE;
