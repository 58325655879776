import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ActionsButton from '@components/ActionsPopOver/components/ActionsButton/ActionsButton';
import { DATA_TABLE_ACTIONS_CODE } from '@components/DataTable/DataTable';

export const renderReferenceDataRow = ({
  row, id, cols, selectedId, onClick: onClickRow,
}) => (
  <tr
    key={`row-${id}`}
    className={selectedId && id === selectedId ? 'selected-row' : null}
    onClick={() => onClickRow && onClickRow(row)}
  >
    {cols.map((columnConfig) => {
      const isMDR = !row.source || row.source === 'MDR';

      if (isMDR && columnConfig.code === DATA_TABLE_ACTIONS_CODE) {
        return (
          <OverlayTrigger
            overlay={(
              <Tooltip>
                <strong>MDR cannot be edited.</strong>
              </Tooltip>
)}
            placement="bottom"
          >
            <td
              key={`row-${id}-column-${columnConfig.text}`}
              className={[columnConfig.code, columnConfig.className].filter(Boolean)
                .join(' ')}
            >
              <ActionsButton disabled />
            </td>
          </OverlayTrigger>
        );
      }
      return (
        <td
          key={`row-${id}-column-${columnConfig.text}`}
          className={[columnConfig.code, columnConfig.className].filter(Boolean)
            .join(' ')}
        >
          { // eslint-disable-next-line no-nested-ternary
          columnConfig.render
            ? columnConfig.render(row)
            : (row[columnConfig.code] === null
              ? row[columnConfig.code]
              : String(row[columnConfig.code]))
}
        </td>
      );
    })}
  </tr>
);

export default renderReferenceDataRow;
