/* eslint-disable import/order */

import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import BRStatusButton from './components/FlagsComponents/BRStatusButton';
import ExchangeStatusButton from './components/FlagsComponents/ExchangeStatusButton';
import StatusFlagComponent from './components/FlagsComponents/StatusFlagComponent';
import ReportStatusButton from './components/FlagsComponents/ReportStatusButton';
import ResponseStatusButton from './components/FlagsComponents/ResponseStatusButton';
import { formatDateTime } from '@common/dateUtils';
import tSchema from '@lang/schema';
import './style.scss';

function IncomingLabels({ message, relatedMessage = false }) {
  return (
    <>
      {relatedMessage === false
        ? (
          <BRStatusButton
            statusResult={message.validationResults}
            message={message}
            isIncoming={message.isIncoming}
          />
        )
        : (
          <ReportStatusButton
            message={message}
          />
        )}
    </>
  );
}

IncomingLabels.propTypes = {
  relatedMessage: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string,
    identification: PropTypes.string,
    validationResults: PropTypes.string,
    isIncoming: PropTypes.bool,
  }).isRequired,

};

function OutcomingLabels({ message, isRelatedMessage, domain }) {
  return (
    <>
      {(isRelatedMessage)
        ? (
          <BRStatusButton
            message={message}
            statusResult={message.validationResults}
            isIncoming={message.isIncoming}
          />
        ) : (
          <>
            <ResponseStatusButton
              message={message}
              domain={domain}
            />
            <ReportStatusButton
              message={message}
            />
          </>
        )}
    </>
  );
}

OutcomingLabels.propTypes = {
  isRelatedMessage: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    identification: PropTypes.string.isRequired,
    isIncoming: PropTypes.bool,
    validationResults: PropTypes.string,
  }).isRequired,
  domain: PropTypes.number.isRequired,
};

const MessageList = ({
  messages,
  onMessageSelectCallback,
  onScrollCallback,
  activeMessages,
  loading,
  disableScroll,
  isRelatedMessage,
  typeSection,
  domain,
}) => {
  const { t } = useTranslation();
  const keyPressHold = useRef(false);
  const isIncoming = typeSection === 'incoming';
  const messageSelectHandler = useCallback(
    (message) => !loading && onMessageSelectCallback && onMessageSelectCallback(message),
  );

  const isSameMessage = useCallback(
    (message, m) => m.id === message.id && m.isIncoming === message.isIncoming,
  );

  return (
    <div
      className={`data-box-list d-flex flex-column ${loading ? 'loading' : ''}${
        disableScroll ? ' no-scroll' : ''
      }`}
      onScroll={!loading ? onScrollCallback : undefined}
      style={{ height: '100%' }}
    >
      {messages && messages.map((message) => (
        <div
          style={{ maxHeight: '100%' }}
          className={`data-box ${
            activeMessages && activeMessages.find((m) => isSameMessage(message, m)) ? 'active' : ''
          }`}
          onClick={() => messageSelectHandler(message)}
          onKeyPress={(ev) => {
            if (ev.key === ' ' || ev.key === 'Enter') {
              ev.preventDefault();
              !keyPressHold.current && messageSelectHandler(message);
            }
            keyPressHold.current = true;
          }}
          onKeyUp={() => { keyPressHold.current = false; }}
          key={message.id}
          role="button"
          tabIndex={0}
        >
          <Container>
            <Row className="title mb-2 mr-1 justify-content-between flex-wrap">
              <Col sm={4} md={4} xs={4} lg={4}>
                <div className="title value">
                  {message.identification}
                </div>
                {message.isIncoming === false
                   && (
                   <StatusFlagComponent
                     message={message}
                   />
                   )}
              </Col>
              {message.exchangeStatus && (
              <Col lg={4} sm={4} className="mr-0 pr-0 align-self-end">
                <ExchangeStatusButton
                  message={message}
                  isIncoming={message.isIncoming}
                />
              </Col>
              )}
              {message.validationResults && (

              <Col lg={4} sm={4} className="mr-0 pr-0 ">
                {isIncoming
                  ? (
                    <IncomingLabels
                      relatedMessage={isRelatedMessage}
                      message={message}
                      isIncoming={isIncoming}
                    />
                  )
                  : (
                    <OutcomingLabels
                      isRelatedMessage={isRelatedMessage}
                      message={message}
                      domain={domain}
                    />
                  )}
              </Col>

              )}
            </Row>
            <Row>
              <Col xs={9}>
                <Container fluid>
                  <Row>
                    <Col>
                      <span>{t(tSchema.dem.trafficMonitoring.dataDomain)}</span>
                      <span className="value">{message.dataDomain && message.dataDomain.code}</span>
                    </Col>
                    <Col>
                      <span>{t(tSchema.dem.trafficMonitoring.recipient)}</span>
                      <span className="value">{message.recipient}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span>{t(tSchema.dem.trafficMonitoring.type)}</span>
                      <span className="value">{message.type && message.type.description}</span>
                    </Col>
                    <Col>
                      <span>{t(tSchema.dem.trafficMonitoring.sender)}</span>
                      <span className="value">{message.sender}</span>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={3} className="d-flex justify-content-end align-content-end">
                <div className="value date">
                  {formatDateTime(message.creationDate, 'DD/MM/YYYY HH:mm:ss', true)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ))}
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      identification: PropTypes.string.isRequired,
      isIncoming: PropTypes.bool.isRequired,
      validationResults: PropTypes.string,
      dataDomain: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
      }),
      recipient: PropTypes.string,
      type: PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
      }),
      sender: PropTypes.string,
    }),
  ).isRequired,
  onMessageSelectCallback: PropTypes.func.isRequired,
  onScrollCallback: PropTypes.func,
  activeMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isIncoming: PropTypes.bool.isRequired,
    }),
  ),
  loading: PropTypes.bool,
  disableScroll: PropTypes.bool,
  isRelatedMessage: PropTypes.bool,
  typeSection: PropTypes.string.isRequired,
  domain: PropTypes.number.isRequired,
};

MessageList.defaultProps = {
  onScrollCallback: null,
  activeMessages: null,
  loading: false,
  disableScroll: false,
  isRelatedMessage: false,
};

export default MessageList;
