import * as yup from 'yup';

const selectSchema = yup.array().of(yup.object().shape({
  value: yup.string(),
  label: yup.string(),
}));

export const schemaValidation = (isReport) => yup.object().shape({
  onlyLastTrip: yup.bool(),
  timeframe: yup.object().shape({
    from: yup.string(),
    to: yup.string(),
  }),
  timeframeFrom: yup.string(),
  timeframeTo: yup.string(),
  tripIdInput: yup.string().min(3).label('Trip ID'),
  tripId: yup.array().of(yup.string()),
  flagState: selectSchema,
  vesselIdInput: yup.string().min(3).label('Vessel ID'),
  vesselId: yup.array().of(yup.object().shape({
    cfr: yup.string().nullable(),
    etalon_id: yup.string(),
    uvi: yup.string().nullable(),
  })),
  vesselGroup: selectSchema,
  species: selectSchema,
  fisheryType: selectSchema,
  gearType: selectSchema,
  meshSize: yup.object().shape({
    from: yup.string(),
    to: yup.string(),
  }),
  activityTypeAnd: selectSchema,
  activityTypeOr: selectSchema,
  ...(isReport && { reportVersion: selectSchema }),
  reportType: selectSchema,
  vesselIds: yup.array().of(yup.string()),
  vesselName: yup.array().of(yup.string()),
});

export const defaultValues = (isReport) => ({
  onlyLastTrip: true,
  timeframe: {
    from: '',
    to: '',
  },
  tripIdInput: '',
  tripId: [],
  flagState: [],
  vesselIdInput: '',
  vesselId: [],
  vesselGroup: [],
  species: [],
  fisheryType: [],
  gearType: [],
  meshSize: {
    from: '',
    to: '',
  },
  activityTypeAnd: [],
  activityTypeOr: [],
  ...(isReport && { reportVersion: [] }),
  reportType: [],
  vesselName: [],
  vesselIds: [],
});
