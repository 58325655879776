const TypeActionsForm = {
  FETCH_ATTRIBUTE_INFO: 'FETCH_ATTRIBUTE_INFO',
  CLEAN_ATTRIBUTE_FORM: 'CLEAN_ATTRIBUTE_FORM',
  CHANGE_ATTRIBUTE_INFORMATION: 'CHANGE_ATTRIBUTE_INFORMATION',
  GET_TABS: 'GET_TABS',
  VALIDATE_FORM: 'VALIDATE_FORM',
  SET_IS_VALID_FIELD: 'SET_IS_VALID_FIELD',
  OPEN_FORM: 'OPEN_FORM',
};

/*
    CHANGE ATTRIBUTE INFORMATION
    Updates the vessel object with the information written on the form
*/
const changeAttributeInformation = (name, value) => ({
  type: TypeActionsForm.CHANGE_ATTRIBUTE_INFORMATION,
  payload: {
    name,
    value,
  },
});

const validateForm = (visualization) => ({
  type: TypeActionsForm.VALIDATE_FORM,
  visualization,

});

const setIsValidAttributeField = (field, value) => ({
  type: TypeActionsForm.SET_IS_VALID_FIELD,
  payload: {
    field,
    value,
  },
});

const cleanForm = () => ({
  type: TypeActionsForm.CLEAN_ATTRIBUTE_FORM,
  payload: {

    customProperties: {},
    description: '',
    displayName: '',
    name: '',
    rights: {
      createdAt: '',
      updatedAt: '',
    },
    searchable: false,
    simpleDataType: '',
    unique: false,

  },
});

const getTabs = () => ({
  type: TypeActionsForm.GET_TABS,
  payload: [],
});

const openForm = (value) => ({
  type: TypeActionsForm.OPEN_FORM,
  value,
});

export {
  TypeActionsForm,
  changeAttributeInformation,
  getTabs,
  cleanForm,
  validateForm,
  setIsValidAttributeField,
  openForm,
};
