/* eslint-disable import/order */
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { map, prop } from 'ramda';
import {
  useEffect, useMemo, useState,
} from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import FieldGroup from '@components/Form';
import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

import { apiFetchScenario } from '@services/scenarios/api';
import { addGroupByToCountries } from '@services/vessel_record/reducers/vessel/utils';

import { schemaValidation } from './utils';

import { getDateForInput } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { selectUtils } from '@common/utilities/selectUtils';
import { DOMAIN, formatSelectOptions } from '@common/utils';
import './style.scss';
import { Permissions, hasPermission } from '@common/keycloakFunctions';
import { useSelector } from 'react-redux';

const schema = tSchema.dmaAdmin.scenarioMGMT.createEditView;

const ScenarioEditor = ({
  isDataAdmin,
  scenarioId,
  edit,
  onSave,
  onCancel,
  options,
  loading: parentLoading,
  domain,
}) => {
  const { t } = useTranslation();
  const permissions = useSelector((state) => state.authentication.permissions);
  const [loading, setLoading] = useState(parentLoading);
  const [scenario, setScenario] = useState({});
  const [selectedVesselGroups, setSelectedVesselGroups] = useState([]);
  const [selectedProgrammes, setSelectedProgrammes] = useState([]);
  const [selectedFlagStates, setSelectedFlagStates] = useState([]);
  const [selectedISRParties, setSelectedISRParties] = useState([]);
  const [selectedControlParties, setSelectedControlParties] = useState([]);
  const isFADomain = domain === DOMAIN.FA;
  const isISRDomain = domain === DOMAIN.ISR;

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  const { vesselGroupsOptions } = useMemo(() => ({
    vesselGroupsOptions: selectUtils.mapOptions({ options: options?.vesselGroups, labelPropName: 'name' }),
  }), [options]);
  const { programmesOptions } = useMemo(() => ({
    programmesOptions: selectUtils.mapOptions({ options: options?.programmes, idName: 'code' }),
  }), [options]);
  const { flagStatesOptions } = useMemo(() => ({
    flagStatesOptions: selectUtils.mapOptionsGroupBy({
      options: formatSelectOptions(
        addGroupByToCountries(
          [...prop('vesselFlags', options), ...prop('memberStates', options)],
          { memberState: prop('memberStates', options) },
        ),
      ),
    }),
  }), [options]);

  const { isrPartiesOptions } = useMemo(() => ({
    isrPartiesOptions: selectUtils.mapOptions({ options: formatSelectOptions(options?.isrParties, 'code', 'name'), idName: 'code' }),
  }), [options]);
  const { controlPartiesOptions } = useMemo(() => ({
    controlPartiesOptions: selectUtils.mapOptions({ options: formatSelectOptions(options?.controlParties, 'code', 'name'), idName: 'code' }),
  }), [options]);
  const requestWithLoading = useRequestWithLoading();

  useEffect(() => {
    if (scenarioId !== null && scenarioId >= 0) {
      requestWithLoading(async () => {
        const query = await apiFetchScenario(scenarioId, domain);

        if (query.ok) {
          const data = await query.json();
          setScenario(data);
          setSelectedVesselGroups(
            selectUtils.selectedOptions(vesselGroupsOptions, data.vesselGroups),
          );
          setSelectedProgrammes(
            selectUtils.selectedOptions(programmesOptions, data.programmes),
          );
          setSelectedFlagStates(
            selectUtils.selectedOptionsGroupBy(flagStatesOptions, data.territories),
          );
          setSelectedControlParties(
            selectUtils.selectedOptions(controlPartiesOptions, data.controlParties),
          );
          setSelectedISRParties(
            selectUtils.selectedOptions(isrPartiesOptions, data.fluxParties),
          );
        } else {
          setScenario({});
          onCancel && onCancel();
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    } else {
      setScenario({});
    }
  }, [scenarioId, requestWithLoading, onCancel]);

  const disabled = useMemo(() => !(edit && (
    (isISRDomain
       && hasPermission(permissions, Permissions.EDIT_SCENARIO))
        || isDataAdmin)), [edit, isDataAdmin]);

  const initialValues = useMemo(() => ({
    ...scenario,
    id: (scenarioId > 0) ? scenarioId : null,
    name: scenario.name || '',
    // eslint-disable-next-line no-nested-ternary
    isActive: scenario.isActive == null ? 'Active' : (scenario.isActive ? 'Active' : 'Inactive'),
    description: scenario.description || '',
    vesselGroups: selectedVesselGroups,
    startDataPeriod: getDateForInput(scenario.startDataPeriod) || '',
    endDataPeriod: getDateForInput(scenario.endDataPeriod) || '',
    programmes: selectedProgrammes || [],
    fluxParties: selectedISRParties || [],
    controlParties: selectedControlParties || [],
    territories: selectedFlagStates || [],
  }), [scenario, scenarioId, selectedVesselGroups, selectedProgrammes,
    selectedISRParties, selectedControlParties, selectedFlagStates]);

  return (
    <Formik
      validationSchema={schemaValidation(domain)}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(valuesForm) => {
        onSave({
          ...valuesForm,
          isActive: valuesForm.isActive === 'Active',
          vesselGroups: valuesForm.vesselGroups.map((e) => e.value),
          programmes: valuesForm.programmes.map((e) => e.value),
          controlParties: valuesForm.controlParties.map((e) => e.value),
          fluxParties: valuesForm.fluxParties.map((e) => e.value),
          territories: valuesForm.territories.map((e) => e.value),
        });
      }}

    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        touched,
        errors,
        resetForm,
      }) => {
        const isTouched = useMemo(() => touched.id, [touched.id]);
        return (
          <Form
            key={scenario.id}
            id="scenario-editor"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="editor-fields">
              {loading && (
              <div className="loading-div">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t(tSchema.common.loading)}</span>
                </div>
              </div>
              )}

              <Form.Row>
                <Form.Group as={Col} controlId="formName">
                  <Form.Label>{t(schema.name)}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    isValid={touched.name && !errors.name}
                    name="name"
                    isInvalid={touched.name && errors.name}
                    value={values.name}
                    disabled={disabled}
                    onChange={handleChange}
                  />
                  { (edit && isDataAdmin) && <input type="hidden" name="name" value={values.name} /> }

                  {(edit) && touched.name && !!errors.name
                    && (<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>)}
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="formStatus">
                  <Form.Label>
                    {t(schema.status)}
                  </Form.Label>
                  <Form.Control
                    required
                    placeholder="Select a status"
                    as="select"
                    name="isActive"
                    isValid={isTouched && !errors.isActive}
                    isInvalid={isTouched && errors.isActive}
                    value={values.isActive}
                    disabled={disabled}
                    onChange={handleChange}
                  >
                    <option key="status-option-active" value="Active">
                      Active
                    </option>
                    <option key="status-option-inactive" value="Inactive">
                      Inactive
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.isActive}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formDescription">
                  <Form.Label>{t(schema.description)}</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    isValid={touched.description && !errors.description}
                    rows="4"
                    name="description"
                    isInvalid={touched.description && errors.description && true}
                    onChange={handleChange}
                    value={values.description}
                    disabled={disabled}
                  />
                  { (edit && isDataAdmin) && <input type="hidden" name="description" value={values.description} /> }
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              {isFADomain
                && (
                <>
                  <FieldGroup label={t(schema.visualizationFilters)}>
                    <Form.Label>
                      {t(schema.timePeriod)}
                    </Form.Label>
                    <Form.Row>
                      <Form.Group as={Col} md={8} controlId="formDataPeriod">
                        <Form.Row>
                          <Form.Group as={Col} controlId="formstartDataPeriod">
                            <Form.Label>
                              {t(schema.startDate)}
                            </Form.Label>
                            <InputDate
                              name="startDataPeriod"
                              id="startDataPeriod"
                              value={values.startDataPeriod}
                              disabled={disabled}
                              isValid={touched.startDataPeriod && !errors.startDataPeriod}
                              maxDate={values.endDataPeriod}
                              onChange={(id, value) => handleChange(
                                { target: { name: id, value } },
                              )}
                              complex={false}
                              hasAction={false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.startDataPeriod}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formendDataPeriod">
                            <Form.Label>
                              {t(schema.endDate)}
                            </Form.Label>
                            <InputDate
                              name="endDataPeriod"
                              id="endDataPeriod"
                              value={values.endDataPeriod}
                              disabled={disabled}
                              isValid={touched.endDataPeriod && !errors.endDataPeriod}
                              minDate={values.startDataPeriod}
                              onChange={(id, value) => handleChange(
                                { target: { name: id, value } },
                              )}
                              complex={false}
                              hasAction={false}
                            />
                            <Form.Text>
                              {t(schema.keepEmptyToIndefinite)}
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                              {errors.endDataPeriod}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </Form.Group>

                      <Form.Group as={Col} md={4} controlId="formVesselGroup">
                        <Form.Label>
                          {t(schema.vesselGroup)}
                        </Form.Label>
                        <Form.Control
                          required
                          placeholder="Pick a vessel group"
                          as="select"
                          name="vesselGroup"
                          isValid={isTouched && !errors.vesselGroup}
                          isInvalid={isTouched && errors.vesselGroup}
                          value={values.vesselGroup || -1}
                          disabled={disabled}
                          onChange={handleChange}
                        >
                          <option value={null}> </option>
                          {map((x) => (
                            <option key={`vesselGroup-option-${prop('value')(x)}`} value={prop('value')(x)}>
                              {prop('label')(x)}
                            </option>
                          ))(vesselGroupsOptions)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.vesselGroup}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </FieldGroup>

                  <FieldGroup label={t(schema.multichoiceFilters)}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formVesselGroups">
                        <Form.Label>
                          {t(schema.vesselGroups)}
                        </Form.Label>
                        <ReactSelect
                          name="vesselGroups"
                          styles={touched.vesselGroups
                        && selectUtils.selectStyles(!errors.vesselGroups)}
                          className={touched.vesselGroups && (errors.vesselGroups ? 'is-invalid' : 'is-valid')}
                          isDisabled={!edit}
                          isMulti
                          closeMenuOnSelect={false}
                          value={values.vesselGroups}
                          options={vesselGroupsOptions}
                          onChange={(option) => setFieldValue('vesselGroups', option)}
                          disabled={disabled}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vesselGroups}
                        </Form.Control.Feedback>

                      </Form.Group>
                    </Form.Row>
                  </FieldGroup>
                </>
                )}
              {isISRDomain
                  && (
                  <>
                    <FieldGroup label={t(schema.visualizationFilters)}>
                      <Form.Label>
                        {t(schema.timePeriod)}
                      </Form.Label>
                      <Form.Row>
                        <Form.Group as={Col} md={8} controlId="formDataPeriod">
                          <Form.Row>
                            <Form.Group as={Col} controlId="formstartDataPeriod">
                              <Form.Label>
                                {t(schema.startDate)}
                              </Form.Label>
                              <InputDate
                                name="startDataPeriod"
                                id="startDataPeriod"
                                value={values.startDataPeriod}
                                disabled={disabled}
                                isValid={touched.startDataPeriod && !errors.startDataPeriod}
                                maxDate={values.endDataPeriod}
                                onChange={(id, value) => handleChange(
                                  { target: { name: id, value } },
                                )}
                                complex={false}
                                hasAction={false}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.startDataPeriod}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formendDataPeriod">
                              <Form.Label>
                                {t(schema.endDate)}
                              </Form.Label>
                              <InputDate
                                name="endDataPeriod"
                                id="endDataPeriod"
                                value={values.endDataPeriod}
                                disabled={disabled}
                                isValid={touched.endDataPeriod && !errors.endDataPeriod}
                                minDate={values.startDataPeriod}
                                onChange={(id, value) => handleChange(
                                  { target: { name: id, value } },
                                )}
                                complex={false}
                                hasAction={false}
                              />
                              <Form.Text>
                                {t(schema.keepEmptyToIndefinite)}
                              </Form.Text>
                              <Form.Control.Feedback type="invalid">
                                {errors.endDataPeriod}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                        </Form.Group>

                        <Form.Group as={Col} md={4} controlId="formProgrammes">
                          <Form.Label>
                            {t(schema.programme)}
                          </Form.Label>
                          <ReactSelect
                            name="programmes"
                            styles={{
                              ...touched.programmes
                          && selectUtils.selectStyles(!errors.programmes),
                            }}
                            className={`${touched.programmes && (errors.programmes ? 'is-invalid' : 'is-valid')}`}
                            isDisabled={!edit}
                            isMulti
                            closeMenuOnSelect={false}
                            value={values.programmes}
                            options={programmesOptions}
                            onChange={(option) => setFieldValue('programmes', option)}
                            disabled={disabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.programmes}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </FieldGroup>

                    <FieldGroup label={t(schema.multichoiceFilters)}>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formVesselGroups">
                          <Form.Label>
                            {t(schema.vesselGroups)}
                          </Form.Label>
                          <ReactSelect
                            name="vesselGroups"
                            styles={touched.vesselGroups
                          && selectUtils.selectStyles(!errors.vesselGroups)}
                            className={touched.vesselGroups && (errors.vesselGroups ? 'is-invalid' : 'is-valid')}
                            isDisabled={!edit}
                            isMulti
                            closeMenuOnSelect={false}
                            value={values.vesselGroups}
                            options={vesselGroupsOptions}
                            onChange={(option) => setFieldValue('vesselGroups', option)}
                            disabled={disabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.vesselGroups}
                          </Form.Control.Feedback>

                        </Form.Group>
                        <Form.Group as={Col} controlId="formTerritories">
                          <Form.Label>
                            {t(schema.flagStates)}
                          </Form.Label>
                          <ReactSelect
                            name="territories"
                            styles={{
                              ...touched.territories
                              && selectUtils.selectStyles(!errors.territories),
                              ...selectUtils.defaultStyles(),
                            }}
                            className={touched.territories && (errors.territories ? 'is-invalid' : 'is-valid')}
                            isDisabled={!edit}
                            isMulti
                            closeMenuOnSelect={false}
                            value={values.territories}
                            options={flagStatesOptions}
                            onChange={(option) => setFieldValue('territories', option)}
                            disabled={disabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.territories}
                          </Form.Control.Feedback>

                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formControlParties">
                          <Form.Label>
                            {t(schema.controlParties)}
                          </Form.Label>
                          <ReactSelect
                            name="controlParties"
                            styles={touched.controlParties
                          && selectUtils.selectStyles(!errors.controlParties)}
                            className={touched.controlParties && (errors.controlParties ? 'is-invalid' : 'is-valid')}
                            isDisabled={!edit}
                            isMulti
                            closeMenuOnSelect={false}
                            value={values.controlParties}
                            options={controlPartiesOptions}
                            onChange={(option) => setFieldValue('controlParties', option)}
                            disabled={disabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.controlParties}
                          </Form.Control.Feedback>

                        </Form.Group>
                        <Form.Group as={Col} controlId="formFLUXParties" hidden>
                          <Form.Label>
                            {t(schema.isrParties)}
                          </Form.Label>
                          <ReactSelect
                            name="fluxParties"
                            styles={touched.fluxParties
                          && selectUtils.selectStyles(!errors.fluxParties)}
                            className={touched.fluxParties && (errors.fluxParties ? 'is-invalid' : 'is-valid')}
                            isDisabled={!edit}
                            isMulti
                            closeMenuOnSelect={false}
                            value={values.fluxParties}
                            options={isrPartiesOptions}
                            onChange={(option) => setFieldValue('fluxParties', option)}
                            disabled={disabled}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fluxParties}
                          </Form.Control.Feedback>

                        </Form.Group>
                      </Form.Row>
                    </FieldGroup>
                  </>
                  )}
            </div>
            {edit && (
            <Form.Row className="actions justify-content-end">
              {onCancel && (
              <Button
                className="mr-3 symbol-cross"
                variant="primary"
                onClick={() => {
                  setScenario({});
                  resetForm();
                  onCancel();
                }}
              >
                {t(tSchema.common.cancel)}
              </Button>
              )}
              {onSave && (
              <Button
                className="symbol-tick"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {t(tSchema.common.save)}
              </Button>
              )}
            </Form.Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

ScenarioEditor.propTypes = {
  isDataAdmin: PropTypes.bool,
  scenarioId: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  options: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
        }),
        PropTypes.shape({
          id_field: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ]),
    ),
  ).isRequired,
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  domain: PropTypes.string.isRequired,
};

ScenarioEditor.defaultProps = {
  scenarioId: null,
  onSave: null,
  onCancel: null,
  isDataAdmin: false,
  edit: false,
  loading: false,
};

export default ScenarioEditor;
