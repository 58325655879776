import React from 'react';
import {
  Button,
} from 'react-bootstrap';
import './sessionNotification.scss';

const SessionNotification = () => (
  <div className="sessionToast__footer">
    <h5>
      Session Expired
    </h5>
    <p>
      Your session has expired. Please reload and sign in again.
    </p>
    <div className="actions">
      <Button
        variant="secondary"
        size="sm"
      >
        Dismiss
      </Button>
      <Button
        variant="primary"
        size="sm"
        onClick={() => window.location.reload(false)}
      >
        Reload
      </Button>
    </div>
  </div>
);

export default SessionNotification;
