/* eslint-disable import/order */

import { isEmpty } from 'lodash';
import { identity, pipe, prop } from 'ramda';
import { formatDate, formatCurrentDate, BACK_DATE_FORMAT } from '@common/dateUtils';
import tSchema from '@lang/schema';

export const SEARCH_TYPES = {
  EXACT: 'EXACT',
  RANGE_GREATHER: 'RANGE_GREATHER',
  RANGE_SMALLER: 'RANGE_SMALLER',
  RANGE: 'RANGE',
  FUZZY: 'FUZZY',
  CONTAINS: 'LIKE',
};
export const TYPES = {
  STRING: 'STRING',
  TIMESTAMP: 'TIMESTAMP',
  DATE: 'DATE',
};

export const DEFAULT_FILTERS = {
  text: '',
  formFields: [],
  status: [],
  dateFrom: formatCurrentDate(BACK_DATE_FORMAT),
  dateTo: formatCurrentDate(BACK_DATE_FORMAT),
};

export const FIELDS_CONTAINS = [
  'cfr',
  'ircs',
  'uvi',
  'mmsi',
  'nationalRegistrationNumber',
  'source',
  'name',
];

export const FIELDS_EXACT = [
  'ownerPoBox',
  'ownerPostCode',
  'ownerPhoneNumber',
  'ownerFaxNumber',
  'ownerEmailAddress',
  'ownerImoIdentifier',
  'operatorPoBox',
  'operatorPostCode',
  'operatorPhoneNumber',
  'operatorFaxNumber',
  'operatorEmailAddress',
  'operatorImoIdentifier',
];

export const isExactField = (field) => (FIELDS_EXACT.indexOf(field) > -1);

export const isContainsField = (field) => (FIELDS_CONTAINS.indexOf(field) > -1);

export const checkRange = (range) => {
  if (range[0] === null) {
    return `< ${range[1]}`;
  }
  if (range[1] === null) {
    return `> ${range[0]}`;
  }
  if (range[0] !== null && range[1] !== null) {
    return `beetween ${range[0]} and ${range[1]}`;
  }
  return '';
};

export const hasValueForHidden = (attr, filter) => {
  if (filter?.searchType === SEARCH_TYPES.RANGE) {
    const isEmptyRange = isEmpty(filter.range[0]) && isEmpty(filter.range[1]);
    return !isEmptyRange && attr.name === filter.name;
  }
  return (attr.name === filter.name);
};

export const checkExact = (filter, attribute, formDataList, t = identity) => {
  const formData = attribute && attribute.customProperties.formData;
  if (formData) {
    const descriptionToShow = pipe(
      (objectList) => objectList[formData],
      (listOfValues) => listOfValues.find(
        (obj) => obj.code === filter.value || obj.comboValue === filter.value,
      ),
      prop('comboLabel') || prop('description'),
    )(formDataList);

    if (descriptionToShow) {
      return `= ${descriptionToShow}`;
    }
  }
  return filter.value ? `= ${filter.value}` : t(tSchema.common.emptyValueOnExcertpt);
};

const checkDateRange = (range) => {
  if (range[0] === null) {
    return `< ${formatDate(range[1], BACK_DATE_FORMAT, undefined, true)}`;
  }
  if (range[1] === null) {
    return `> ${formatDate(range[0], BACK_DATE_FORMAT, undefined, true)}`;
  }
  if (range[0] !== null && range[1] !== null) {
    return `between ${formatDate(range[0], BACK_DATE_FORMAT, undefined, true)} and ${formatDate(range[1], BACK_DATE_FORMAT, undefined, true)}`;
  }
  return '';
};

export const showTypeValue = (formDataList, t = identity) => (filter, attribute) => {
  switch (filter.searchType) {
    case SEARCH_TYPES.EXACT:
      return filter.type === TYPES.DATE
        ? ` = ${formatDate(filter.value, BACK_DATE_FORMAT, undefined, true)}`
        : checkExact(filter, attribute, formDataList, t);

    case SEARCH_TYPES.FUZZY:
    case SEARCH_TYPES.CONTAINS:
      return `contains "${filter.value}"`;
    case SEARCH_TYPES.RANGE:
      if (filter.type === 'DATE' || filter.type === 'TIMESTAMP') {
        return checkDateRange(filter.range);
      }
      return checkRange(filter.range);
    default:
      return '';
  }
};

export const DEFAULT_GROUP_LABEL = 'OTHER';
export const selectFilterTabOrder = [
  'MOST COMMON FILTERS',
  'STATUS',
  'VESSEL IDENTIFICATION',
  'TECHNICAL DETAILS',
  DEFAULT_GROUP_LABEL,
  'COMPANY INFORMATION',
];
export const selectFilterCustomSort = (a, b) => {
  if (selectFilterTabOrder.indexOf(a?.toUpperCase())
  > selectFilterTabOrder.indexOf(b?.toUpperCase())) {
    return 1;
  }
  if (selectFilterTabOrder.indexOf(a?.toUpperCase())
  < selectFilterTabOrder.indexOf(b?.toUpperCase())) {
    return -1;
  }
  return 0;
};
export const formatFiltersToChoose = (filtersToChoose) => filtersToChoose.map((e) => ({
  code: e.name,
  label: e.displayName,
  groupBy: (e.customProperties?.filterTab || DEFAULT_GROUP_LABEL)
    .toUpperCase(),
}));

export const getFormDataSelectOptions = (
  { formData, formDataList },
) => formDataList && formDataList[formData]?.map((f) => ({
  label: f.comboLabel || f.description,
  code: f.comboValue || f.code,
  groupBy: f.groupBy,
}));
