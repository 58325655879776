import Crud from './crud';
import Form from './form';
import Historic from './historic';

/* Initial State of Vessel */
const initialState = {
  vessels: [],
  vesselGroups: [],
  currentGroups: [],
  isLoading: false,
  isLoadingDrools: false,
  isLoadingVessel: false,
  loadingHistoric: false,
  isExporting: false,
  isLoadingGroups: false,
  isSavingGroups: false,
  vessel: {},
  oldVessel: {},
  entity: [],
  lookups: [],
  error: false,
  errorMessage: '',
  tabsForm: [],
  isValidForm: true,
  selectedVesselId: null,
  formatedVesselInfo: [],
  totalCountVessels: 0,
  pagination: {
    page: 1,
    size: 10,
    totalCount: 0,
    maxPages: 1,
    filtersBody: {},
  },
  searchableAttributes: [],
  template: {},
  vesselHistoric: {
    content: [],
  },
  vesselTemplate: [],
  listDrools: [],
  modified: false,
  validated: false,
  send: false,
  uploadVessels: {
    isUploadingVessels: false,
    reports: null,
    error: null,
  },
  previousVersion: null,
  image: '',
};

const Managers = {
  ...Crud,
  ...Form,
  ...Historic,
};

export default (state = initialState, action = { type: '' }) => {
  const finded = Managers[action.type];
  return finded ? finded(state, action) : { ...state };
};
