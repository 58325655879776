/* eslint-disable import/order */

import { isEmpty } from 'ramda';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Flag from '../../componentsRecord/Flags';
import VesselHistoric from '../../componentsRecord/VesselHistoric';

import {
  filtersList,
  getTableConfig,
  searchOptions,
} from './config';

import { formatDate } from '@common/dateUtils';
import { requestWithMessage } from '@common/utilities/Notification';
import { formatHeaderSort, getApproverStatus } from '@common/utils';
import DataTable from '@components/DataTable';
import Filters from '@components/Filters';
import tSchema from '@lang/schema';
import {
  cleanHistoric,
  fetchVesselById, getFormData, getSearchableAttributes,
} from '@services/vessel_record/actions/vessel';
import { apiFetchDataActivity } from '@services/vessel_record/api';
import { getVersionsFromVesselChanges } from '@services/vessel_record/actions/vessel/historic';

const DataActivity = () => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const dispatch = useDispatch();

  const [dataActivities, setDataActivities] = useState(null);
  const errorDataActivity = useSelector((state) => state.activityReports.errorDataActivity);
  const template = useSelector((state) => state.vessel.template);
  const loadingVessel = useSelector((state) => state.vessel.isLoading);
  const selectedVessel = useSelector((state) => state.vessel.vessel);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const pagination = useSelector((state) => state.activityReports.pagination);
  const maxPages = useRef(1);
  const totalResults = useRef(0);
  const [loading, setLoading] = useState(false);
  const dataTableRef = useRef();

  useEffect(() => {
    if (isEmpty(template)) {
      dispatch(getSearchableAttributes());
      dispatch(getFormData());
    }
  }, []);

  const loadData = useCallback(async () => {
    await requestWithMessage({
      request: () => apiFetchDataActivity(
        searchFilters,
        {
          page: page - 1,
          size: pageSize,
          sort: orderBy && orderBy.field,
          direction: orderBy && orderBy.order,
        },
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setDataActivities(data.content || []);
      },
      setLoading,
    });
  }, [searchFilters, page, pageSize, orderBy]);

  useEffect(() => {
    loadData();
  }, [page, pageSize, orderBy, searchFilters]);

  const pageSizeChangeHandler = useCallback((size) => {
    setPage(1);
    setPageSize(Number.parseInt(size, 10));
  }, []);

  const pageChangeHandler = useCallback((pageRequest) => {
    setPage(pageRequest);
  }, [loadData]);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    setPage(1);
  };
  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      setPage(1);
      setOrderBy(formatHeaderSort(header, orderBy));
      resetPagination();
    }
  }, [loading, page, orderBy, resetPagination]);

  const onHandleHistorical = useCallback((row) => {
    dispatch(fetchVesselById(row.vesselId));
    dispatch(getVersionsFromVesselChanges(row.vesselId));
    setShowModal(true);
  }, []);
  const actions = useMemo(() => [
    {
      label: 'Show historical',
      action: onHandleHistorical,
    },
  ], [onHandleHistorical]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && onHandleHistorical(row), // Double Click
    [onHandleHistorical],
  );

  const handleOnHide = useCallback(() => {
    dispatch(cleanHistoric());
    setShowModal(false);
  }, []);

  return (
    <div id="dataActivity">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Filters
              filters={filtersList}
              pagination={pagination}
              formDataList={filtersList}
              onUpdateSearchFilters={setSearchFilters}
              hasDefaultFilters={false}
              searchOptions={searchOptions}

            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              tableId="dataActivity"
              ref={dataTableRef}
              actions={actions}
              totalResults={totalResults.current}
              tableConfig={tableConfig}
              rowData={dataActivities || []}
              pageSize={pageSize}
              orderBy={orderBy}
              onTableHeaderClick={tableHeaderClickHandler}
              onPageSizeChangeHandler={pageSizeChangeHandler}
              maxPages={maxPages.current}
              onPageChangeHandler={pageChangeHandler}
              onRowSelected={handleRowSelected}
              loading={loading || isEmpty(template)}
              error={errorDataActivity}
              errorMessageEmptyArray="No data activity found"
              errorMessageLoadingData="An error has occurred loading the data activity"
            />
          </Col>
        </Row>
      </Container>
      {loadingVessel ? (
        <Modal
          backdrop="static"
          show
          size="xl"
        >
          <Modal.Header closeButton className="pb-0">
            <Modal.Title>
              {t('Loading Vessel')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <Container fluid>
              <div>
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status" />
                </div>
                <div className="label d-flex justify-content-center mt-4" style={{ fontSize: '1.25rem', textAlign: 'center' }}>Loading vessel data...</div>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          backdrop="static"
          className="vessel-viewer-modal"
          show={showModal}
          onHide={handleOnHide}
          size="xl"
        >
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>
              {selectedVessel.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Header>
            <Container
              fluid
              style={{
                paddingBottom: '0.625rem',
              }}
            >
              <Row
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Col>
                  <Flag country={selectedVessel?.countryOfRegistration} />
                </Col>
                <Col>
                  <div className="header-columns">
                    <Form.Label>Name</Form.Label>
                    <p>{selectedVessel?.name || '---'}</p>
                  </div>
                  <div className="header-columns">
                    <Form.Label>IRCS</Form.Label>
                    <p>{selectedVessel?.ircs || '---'}</p>
                  </div>
                </Col>
                <Col>
                  <div className="header-columns">
                    <Form.Label>CFR</Form.Label>
                    <p>{selectedVessel?.cfr || '---'}</p>
                  </div>
                  <div
                    className="header-columns"
                  >
                    <Form.Label>External Marking</Form.Label>
                    <p>{selectedVessel?.externalMarking || '---'}</p>
                  </div>

                </Col>

                <Col>
                  <div className="header-columns">
                    <Form.Label>UVI</Form.Label>
                    <p>{selectedVessel?.uvi || '---'}</p>
                  </div>
                  <div className="header-columns">
                    <Form.Label>Validity Date</Form.Label>
                    <p>{formatDate(selectedVessel?.validityDate, undefined, '---', true)}</p>
                  </div>

                </Col>

                <Col xs={3}>
                  <div className="header-columns">
                    <Form.Label>Reported Event</Form.Label>
                    <p>{selectedVessel?.reportedEvent || '---'}</p>
                  </div>
                  <div className="header-columns">
                    <Form.Label>{getApproverStatus(selectedVessel?.approverStatus)}</Form.Label>
                  </div>

                </Col>

              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <VesselHistoric etalonId={selectedVessel?.etalonId} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ marginRight: '0.375rem' }}
                onClick={handleOnHide}
              >
                {t(tSchema.common.close)}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

DataActivity.defaultProps = {};

DataActivity.propTypes = {};

export default DataActivity;
