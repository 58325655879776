import {
  Col, Container, Row,
} from 'react-bootstrap';

import TextInputField from '@components/TextInputField';

import { AAP_PROCESSES_PROPTYPES, AAP_PROCESS_PROPTYPES } from '../../constants';
import BasicTable from '../tables/BasicTable';

export const PACKAGE_TABLE_HEADERS = [
  { text: 'Packaging Type', code: 'packagingType', sortable: false },
  { text: 'Packaging Unit', code: 'packagingUnit', sortable: false },
  { text: 'Packaging Unit Avg Weight (KG)', code: 'packagingUnitAvgWeight', sortable: false },
  { text: 'Weight (KG)', code: 'weight', sortable: false },
  { text: 'Fish Quantity', code: 'fishQuantity', sortable: false },
  { text: 'Weight Means', code: 'weightMeans', sortable: false },
];

const AapProcess = ({ aapProcess }) => (
  <div className="mb-3">
    <Row>
      <Col md={3} sm={6} xs={12}>
        <TextInputField label="Presentation" value={aapProcess?.presentation} />
      </Col>
      <Col md={3} sm={6} xs={12}>
        <TextInputField label="Preservation" value={aapProcess?.preservation} />
      </Col>
      <Col md={3} sm={6} xs={12}>
        <TextInputField label="Freshness" value={aapProcess?.freshness} />
      </Col>
      <Col md={3} sm={6} xs={12}>
        <TextInputField label="Conversion Factor" value={aapProcess?.conversionFactor} />
      </Col>
    </Row>
    {(aapProcess?.products && aapProcess?.products.length > 0) && (
    <Row>
      <Col sm={12}>
        <BasicTable data={aapProcess?.products} headers={PACKAGE_TABLE_HEADERS} />
      </Col>
    </Row>
    )}
  </div>
);
AapProcess.propTypes = {
  aapProcess: AAP_PROCESS_PROPTYPES.isRequired,
};

const AapProcesses = ({ aapProcesses }) => (
  <Container fluid id="aap-processes-modal">
    {aapProcesses && aapProcesses.map((location, index) => (
      <>
        <h6 className="title">
          {`AAP Process ${index + 1}`}
        </h6>
        <AapProcess aapProcess={location} />
      </>
    ))}
  </Container>
);

AapProcesses.propTypes = {
  aapProcesses: AAP_PROCESSES_PROPTYPES.isRequired,
};

export default AapProcesses;
