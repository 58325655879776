import {
  isEmpty, propEq, compose, prop, find,
} from 'ramda';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Container,
  Col,
  Row,
  Modal,
  Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DataTable from '@components/DataTable';
import Filters from '@components/Filters';

import tSchema from '@lang/schema';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import {
  apiFetchEfcaRules,
  apiFetchEfcaRulesOptions,
  apiSaveQueryAcceptanceBR,
  apiDeleteAcceptanceQuery,
} from '@services/efcaRules/api';
import useDomainId from '@services/userPreferences/hooks/useDomainId';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import QueryAcceptanceRuleEditor from '../QueryAcceptanceRuleEditor';

import {
  configureFilters,
  searchOptions,
  tableConfig as getTableConfig,
  valueOptions,
} from './config';

import { useRequestWithLoading } from '@common/hooks/request';
import {
  hasPermission,
  isDataOrSystemAdmin,
  isIsrSystemAdmin,
  isXEUorXFA,
  Permissions,
} from '@common/keycloakFunctions';
import {
  requestsWithMessage, requestWithMessage, showErrorNotification,
} from '@common/utilities/Notification';
import './style.scss';
import { DOMAIN, formatHeaderSort } from '@common/utils';

const schema = tSchema.dmaAdmin.acceptanceBR.query;
export const QueryAcceptanceSection = () => {
  const { t } = useTranslation();
  const [types, setTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(null);
  const [queryAcceptance, setQueryAcceptance] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const [filtersConfig, setFiltersConfig] = useState([]);
  const [initialFilters, setInitialFilters] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const pageRequest = useRef(1);
  const maxPages = useRef(0);
  const totalResults = useRef(0);
  const downloadRef = useRef();
  const dataTableRef = useRef();
  const { keycloak } = useKeyCloak();
  const { userPreferences } = useUserPreferences();
  const permissions = useSelector((state) => state.authentication.permissions);
  const domainCode = userPreferences && userPreferences.domain;
  const domain = useDomainId();
  const requestWithLoading = useRequestWithLoading();

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };

  const fetchOptions = useCallback(() => {
    requestsWithMessage({
      requests: valueOptions.map(apiFetchEfcaRulesOptions),
      errorMessage: 'An error while loading the filter options has occurred',
      onSuccess: async (responses) => {
        const optionsJson = await Promise.all(responses.map((x) => x.json()));
        const newTypes = {};
        optionsJson.forEach((x, i) => {
          newTypes[valueOptions[i].option] = x;
        });
        setTypes(newTypes);
      },
      setLoading,
    });
  }, [valueOptions]);

  const getValueFromId = useCallback(
    (option, id, property = 'description', idProp = 'id') => types && compose(
      prop(property),
      find(propEq(idProp, id)),
      prop(option),
    )(types),
    [types],
  );

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);
  const isDataAdmin = isDataOrSystemAdmin(keycloak);
  const isIsrSystemAdminC = isIsrSystemAdmin(keycloak);
  const isXEUorXFAUser = isXEUorXFA(keycloak);
  const tableConfig = getTableConfig(
    getValueFromId, domainCode, isIsrSystemAdminC, isXEUorXFAUser,
  );
  useEffect(() => {
    if (types) {
      const defaultFilters = {};
      searchFilters && setSearchFilters(null);
      setInitialFilters(defaultFilters);
      setFiltersConfig(
        configureFilters(types, defaultFilters, domainCode, isIsrSystemAdminC, isXEUorXFAUser),
      );
    }
  }, [types, domainCode]);

  const loadData = useCallback(() => requestWithLoading(async () => {
    setSelectedId(null);
    const filtersWithDomain = searchFilters ? `domain==${domain},${searchFilters}` : `domain==${domain}`;

    const fetchedData = await apiFetchEfcaRules(false, false, domainCode)(
      filtersWithDomain,
      {
        page: pageRequest.current - 1,
        size: pageSize,
        sort: orderBy && orderBy.field,
        direction: orderBy && orderBy.order,
      },
    );
    if (fetchedData.ok) {
      const data = await fetchedData.json();
      totalResults.current = data.totalElements;
      maxPages.current = data.totalPages || 1;
      setQueryAcceptance(data.content || []);
    } else {
      showErrorNotification(t(tSchema.notifications.common.fetchData));
    }
  }, setLoading),
  [
    searchFilters,
    domain,
    pageRequest,
    pageSize,
    orderBy,
    domainCode,
  ]);

  useEffect(() => {
    !loading && loadData();
  }, [
    pageSize,
    orderBy,
    searchFilters,
    pageRequest.current,
    domainCode,
  ]);

  const viewRow = useCallback(
    (row) => {
      setEditMode(false);
      setShowEditor(true);
      setSelectedId(row.id);
      setSaveError(null);
    }, [],
  );
  const editRow = useCallback(
    (row) => {
      setEditMode(true);
      setShowEditor(true);
      setSelectedId(row.id);
      setSaveError(null);
    }, [],
  );
  const deleteRow = useCallback(
    (row) => {
      setSelectedId(row.id);
      setShowDelete(true);
      setSaveError(null);
    }, [],
  );
  const actions = useMemo(() => [
    {
      label: t(tSchema.common.edit),
      action: editRow,
      disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions,
        Permissions.EDIT_ACCEPTANCE_BR),
    },
    { label: t(tSchema.common.view), action: viewRow },
    {
      label: t(tSchema.common.delete),
      action: deleteRow,
      disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions,
        Permissions.EDIT_ACCEPTANCE_BR),
    },
  ], [
    editRow,
    viewRow,
    deleteRow,
  ]);

  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      pageRequest.current = 1;
      setOrderBy(formatHeaderSort(header, orderBy));
      resetPagination();
    }
  }, [loading, pageRequest, orderBy, resetPagination]);

  const updateSearchFiltersHandler = useCallback((filters) => {
    if (pageRequest.current > 1) {
      resetPagination();
    }
    setSearchFilters(filters);
  }, [pageRequest, resetPagination]);

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    loadData();
  }, [loadData]);

  const createEfcaRuleButtonClickHandler = useCallback(() => {
    setEditMode(true);
    setShowEditor(true);
    setSelectedId(null);
    setSaveError(null);
  }, []);
  const createEfcaRuleButtonDisabled = useMemo(
    () => loading
    || (
      selectedId
      && isEmpty(selectedId)
    ),
    [loading, selectedId],
  );

  const isHidden = useMemo(
    () => {
      switch (domainCode) {
        case DOMAIN.ISR:
          return !hasPermission(permissions, Permissions.EDIT_ACCEPTANCE_BR)
            && !isDataAdmin;
        default:
          return !isDataAdmin;
      }
    }, [domainCode, permissions, isDataAdmin],
  );

  const createButton = useMemo(() => (
    <Button
      id="new-query"
      className="symbol-plus"
      onClick={createEfcaRuleButtonClickHandler}
      disabled={createEfcaRuleButtonDisabled}
      hidden={isHidden}
    >
      {t(schema.queryAcceptance.createQueryAcceptanceCase)}
    </Button>
  ), [createEfcaRuleButtonClickHandler, createEfcaRuleButtonDisabled, isDataAdmin]);

  const editQuery = useCallback((query) => {
    const queryWithDomain = { ...query, domain: query.domain || domain };
    requestWithMessage({
      request: async () => apiSaveQueryAcceptanceBR(
        domainCode === DOMAIN.FA ? queryWithDomain : query, domainCode,
      ),
      onSuccess: () => {
        setShowEditor(false);
        setSaveError(null);
        loadData();
      },
      onError: async (response) => {
        if (response?.response?.status === 400) {
          setSaveError(response?.response?.data?.message);
        } else {
          showErrorNotification();
          setShowEditor(false);
          setSaveError(null);
        }
      },
      errorMessage: t(tSchema.notifications.common.error),
      successMessage: 'The rule was succesfully saved',
      setLoading,
    });
  }, [loadData, domain]);

  const editHandler = useCallback((query) => {
    editQuery(query);
  }, [editQuery]);

  const cancelEditHandler = useCallback(() => {
    setShowEditor(false);
    setSelectedId(null);
    setSaveError(null);
  }, []);

  const deleteHandler = useCallback(() => {
    setShowDelete(false);
    requestWithMessage({
      request: () => apiDeleteAcceptanceQuery(selectedId, domainCode),
      onSuccess: loadData,
      successMessage: 'The rule was succesfully deleted',
      errorMessage: 'There was an error deleting the rule',
      setLoading,
    });
  }, [selectedId, loadData]);
  const cancelDeleteHandler = useCallback(() => {
    setShowDelete(false);
    setSelectedId(null);
  }, []);

  const editorTitle = useMemo(() => {
    const editOrViewTitle = editMode
      ? t(schema.createEditView.editAcceptanceRule)
      : t(schema.createEditView.viewAcceptanceRule);
    return (selectedId && selectedId > 0
      ? editOrViewTitle
      : t(schema.createEditView.newAcceptanceCase));
  },
  [selectedId, editMode]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && viewRow(row), // Double Click
    [viewRow],
  );

  return (
    <Container fluid id="queryAcceptanceViewer">
      <>
        <a href="/" ref={downloadRef} hidden>
          Download
        </a>
        <Filters
          filters={filtersConfig}
          searchOptions={searchOptions}
          onUpdateSearchFilters={updateSearchFiltersHandler}
          isUIBlocked={loading}
          initialFilters={initialFilters}
        />
        <Row>
          <Col>
            <DataTable
              ref={dataTableRef}
              tableId="queryAcceptances"
              totalResults={totalResults.current}
              tableConfig={tableConfig}
              actions={actions}
              orderBy={orderBy}
              onTableHeaderClick={tableHeaderClickHandler}
              onRowSelected={handleRowSelected}
              rowData={queryAcceptance}
              defaultPageSize={pageSize}
              onPageSizeChangeHandler={pageSizeChangeHandler}
              onPageChangeHandler={pageChangeHandler}
              loading={loading}
              maxPages={maxPages.current}
              tableActions={(<>{ createButton }</>)}
            />
          </Col>
        </Row>
        <Modal
          backdrop="static"
          show={showEditor}
          onHide={cancelEditHandler}
          size="xl"
        >
          <Modal.Header closeButton className="pb-0">
            <Modal.Title>{editorTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <QueryAcceptanceRuleEditor
              editTitle={`${t(tSchema.common.edit)} Case`}
              newTitle="New Acceptance Case"
              key={`e${selectedId || 'new'}${editMode ? 'edit' : 'view'}`}
              isDataAdmin={isDataAdmin}
              queryId={selectedId}
              edit={editMode}
              onSave={editHandler}
              onCancel={cancelEditHandler}
              loading={loading}
              options={types}
              saveError={saveError}
              isIsrSystemAdmin={isIsrSystemAdminC}
            />
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          show={showDelete}
          onHide={cancelDeleteHandler}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t(tSchema.common.delete)}
              {' '}
              Case
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning">
              Are you sure you want to delete this case?
            </Alert>
            <QueryAcceptanceRuleEditor
              editTitle={`${t(tSchema.common.edit)} Case`}
              newTitle="New Acceptance Case"
              key={`e${selectedId}${editMode ? 'edit' : 'view'}`}
              isDataAdmin={false}
              queryId={selectedId}
              edit={false}
              onSave={null}
              onCancel={null}
              loading={loading}
              options={types}
              isIsrSystemAdmin={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={cancelDeleteHandler}>
              {t(tSchema.common.cancel)}
            </Button>
            <Button variant="danger" onClick={deleteHandler}>
              {t(tSchema.common.delete)}
            </Button>
          </Modal.Footer>
        </Modal>
        <br />
      </>
    </Container>
  );
};

export default QueryAcceptanceSection;
