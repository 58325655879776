/* eslint-disable linebreak-style */
import { FETCH_EXTERNAL_SOURCES } from './actions';

const initialState = {
  sources: [],
  loading: false,
};

function externalSources(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_EXTERNAL_SOURCES.PENDING:
      return { ...state, loading: true };
    case FETCH_EXTERNAL_SOURCES.FULFILLED:
      return {
        ...state,
        sources: [...payload],
        loading: false,
      };
    case FETCH_EXTERNAL_SOURCES.REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default externalSources;
