import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, {
  useRef, useState, useCallback, useMemo, useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import formatChecked from '../utils';

import getTableConfig from './utils';

import { formatHour } from '@common/dateUtils';
import { requestWithMessage } from '@common/utilities/Notification';
import { fromDDToDMD } from '@common/utils';

const IsrViewerDetailsTabPatrolVessel = ({
  selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
}) => {
  const dataTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['inspectionTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.inspectionTab);
        const versionData = getVersionData(data.inspectionTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid, selectedRecipient, isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <div>
          <Row>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.flag)}
                  :
                </Form.Label>
                <p>{tabData.flag || ''}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.name)}
                  :
                </Form.Label>
                <p>{tabData.name || ''}</p>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.externalMark)}
                    :
                  </Form.Label>
                  <p>{tabData.externalMark || ''}</p>
                </div>

              </div>
            </Col>
            <Col md={2}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.imo)}
                  :
                </Form.Label>
                <p>{tabData.imo || ''}</p>
              </div>
            </Col>
            <Col>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.ircs)}
                  :
                </Form.Label>
                <p>{tabData.ircs || ''}</p>
              </div>

            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.position)}
                  :
                </Form.Label>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <p>{tabData.positionLatitude ? fromDDToDMD(tabData.positionLatitude, true, t) : ''}</p>
                  <p>{tabData.positionLongitude ? fromDDToDMD(tabData.positionLongitude, false, t) : ''}</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.at)}
                  :
                </Form.Label>
                <p>{tabData.positionDate && formatHour(tabData.positionDate, true)}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.location)}
                  :
                </Form.Label>
                <p>{(isNil(tabData.locationID) || isNil(tabData.locationSchemeID)) ? '' : `${tabData.locationID}  (${tabData.locationSchemeID})`}</p>
              </div>
            </Col>
            <Col md={2} />
          </Row>
          <FieldGroup label={t(tSchema.isrViewer.inspectors)}>
            <Container fluid className="isrInspectors">
              <DataTable
                rowData={tabData.inspectors}
                idColumn="validityDate"
                tableId="isrInspectorsTable"
                ref={dataTableRef}
                tableConfig={tableConfig}
                loading={loading}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
          <FieldGroup label={t(tSchema.isrViewer.preInspection)}>
            <Row>
              <Col md={4}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.radioCall)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.radioCallPreBoarding, t)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.vesselLogbook)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.logbookCompleted, t)}
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.result)}
                    :
                  </Form.Label>
                  <p>{formatChecked(tabData.result, t)}</p>
                </div>
              </Col>
              <Col md={5}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.details)}
                    :
                  </Form.Label>
                  <p>{tabData.details || ''}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.boardingLadder)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.boardingLadder, t)}
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.description)}
                    :
                  </Form.Label>
                  <p>{tabData.description || ''}</p>
                </div>
              </Col>
            </Row>
          </FieldGroup>
        </div>
      )}
    </>
  );
};

IsrViewerDetailsTabPatrolVessel.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabPatrolVessel;
