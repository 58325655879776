import i18next from 'i18next';

import tSchema from '@lang/schema';

const { t: defaultT } = i18next;

const schema = tSchema.dem.failoverParameters;

const getTableConfig = (t = defaultT) => [
  {
    text: t(schema.parameter),
    code: 'code',
  },
  {
    text: t(schema.description),
    code: 'description',
  },
  {
    text: t(schema.value),
    code: 'value',
  },
  {
    text: t(schema.unit),
    code: 'unit',
  },
];

export default getTableConfig;
