import tSchema from '@lang/schema';

import formatChecked from '../utils';

const schema = tSchema.isrViewer;

export function getInfringTableConfig(t) {
  return [
    { text: t(schema.legalReference), code: 'legalReference' },
    { text: t(schema.observation), code: 'observation' },
    {
      text: t(schema.seriousViolation), code: 'seriousViolation', render: (row) => (formatChecked(row.seriousViolation, t)),
    },
  ];
}

export function getCategoriesTableConfig(t) {
  return [
    { text: t(schema.typeClassification), code: 'typeClassification', render: (row) => (row.typeClassification?.split('_INFRINGEMENT_TYPE')[0]) },
    { text: t(schema.typeCode), code: 'typeCode' },
    { text: t(schema.typeLabel), code: 'typeLabel' },
    { text: t(schema.typeDescription), code: 'typeDescription' },
  ];
}
