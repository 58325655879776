/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';

import failoverParametersEditImg from './img/failover-parameters-edit.png';

const { t } = i18next;

const SectionFailoverParametersEdit = () => (
  <div className="subSection">
    <p>
      When &quot;
      {t(tSchema.common.edit)}
      &quot; button (in one of the rows of the Action column of the grid)
      is pressed, a modal window is opened.
    </p>
    <p>
      <img src={failoverParametersEditImg} alt="failoverParametersEdit" />
    </p>
    <p>
      This window shows the parameter description and a
      field to edit the value depending on the unit type
    </p>
    <p>
      To save the changes, click on the &quot;
      {t(tSchema.common.save)}
      &quot; button.
    </p>
    <p>
      To discard the changes, click on the &quot;
      {t(tSchema.common.cancel)}
      &quot; button.
    </p>
  </div>
);

export default SectionFailoverParametersEdit;
