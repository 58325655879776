import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import * as React from 'react';
import { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { getFormData } from '@services/vessel_record/actions/vessel';
import { FORM_DATA_TYPES } from '@services/vessel_record/reducers/vessel/utils';

import { getFormDataSelectOptions } from '../../../componentsRecord/Filters/FiltersUtils';

import InputSelect from './InputSelect';

const InputTerritory = ({
  id,
  name,
  value,
  disabled,
  onChange,
  required,
  isTouched,
  errors,
}) => {
  const { t } = useTranslation();

  const formDataList = useSelector((state) => state.vessel.template.formData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(formDataList)) {
      dispatch(getFormData());
    }
  }, []);

  const vesselTerritoryOptions = useMemo(() => (formDataList ? getFormDataSelectOptions(
    { formData: FORM_DATA_TYPES.VESSEL_TERRITORY, formDataList },
  ) : []), [formDataList]);

  const inputSelectHandleChange = useCallback(
    (n, v) => {
      onChange({ target: { name: n, value: v } });
    }, [onChange],
  );

  return (
    <InputSelect
      id={id}
      name={name}
      showValidation={isTouched}
      isValid={isTouched && !errors}
      isInvalid={isTouched && errors}
      readOnly={disabled}
      placeholder={disabled && t('-')}
      value={value || ''}
      hasAction={false}
      onChange={inputSelectHandleChange}
      options={vesselTerritoryOptions}
      isLoading={!formDataList}
      required={required}
    />
  );
};

InputTerritory.defaultProps = {
  id: 'vessel-terrytory',
  name: 'vessel-terrytory',
  value: '',
  errors: undefined,
  disabled: false,
  isTouched: false,
  onChange: identity,
  required: false,
};

InputTerritory.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.string,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default InputTerritory;
