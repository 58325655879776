/* eslint-disable linebreak-style */
import buttonCreate from './img/action_create_acceptance.png';
import form from './img/create_acceptance_report.png';

const SectionAddReportAcceptance = () => (
  <div className="SectionAddAcceptance">
    <p>
      Below the results grid, the user shall click on the “Create Report Acceptance Rule” button.
    </p>
    <p>
      <img src={buttonCreate} alt="Button create" />
    </p>
    <p>
      When users click on the button, the creation form is displayed.
    </p>
    <p>
      <img src={form} alt="Form create" />
    </p>
    <p>
      Once all the fields have been filled in, the user shall click on the &quot;Save&quot; button.
      Then the modal form is closed and the grid displayed in the central panel of the
      application is updated with the new report created.
    </p>
  </div>
);

export default SectionAddReportAcceptance;
