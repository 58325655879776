import addButton from './img/add_button.JPG';
import createModal from './img/create_modal.JPG';
import errorDescription from './img/error_description.JPG';
import errorMessage from './img/error_message.JPG';
import errors from './img/errors.JPG';

const SectionAddVessel = () => (
  <div className="subSection">
    <p>
      With the ‘Add Vessel’ button it is possible to add a new vessel.
    </p>
    <p>
      <img src={addButton} alt="Button Add Vessel" />
    </p>
    <p>
      When the user clicks it, a new window opens containing the creation form:
    </p>
    <p>
      <img src={createModal} alt="Create Vessel Modal" />
    </p>
    <p>
      Information of the vessel is grouped in tabs. The
      active tab is highlighted with a red underscore and
      bold letters.
    </p>
    <p>
      When the user clicks the Save button,
      available at the bottom right corner in every tab,
      the data filled in the form is validated; if any,
      the errors resulting from the validation of the business rules are shown.
      Moreover in the tabs in which there are errors,
      an icon is shown corresponding to the severity of the error.
    </p>
    <p>
      <img src={errors} alt="Errors" />
    </p>
    <p>
      Also, a message appears warning the user that,
      if there are errors, the form cannot be saved.
      The vessel cannot be created until those errors are solved.
    </p>
    <p>
      <img src={errorMessage} alt="Error Message" />
    </p>
    <p>
      The error message described above disappears once the user makes a change in the form.
    </p>
    <p>
      For each attribute containing errors,
      a warning icon is shown besides the corresponding form field.
      Some attributes may have more than one error. When clicking the warning icon,
      a list of errors for that attribute is shown.
    </p>
    <p>
      <img src={errorDescription} alt="Error Description" />
    </p>
  </div>
);

export default SectionAddVessel;
