import PropTypes from 'prop-types';
import React, {
  useRef, useState, useCallback, useMemo,
  useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import formatChecked from '../utils';

import {
  getInspectorsTableConfig,
  getStatusTableConfig,
} from './utils';

import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerDetailsTabInspectionMeans = ({
  selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
}) => {
  const inspectorsTableRef = useRef();
  const [inspectorsLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const statusTableRef = useRef();
  const [statusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const inspectorsTableConfig = useMemo(() => getInspectorsTableConfig(t), [t]);
  const statusTableConfig = useMemo(() => getStatusTableConfig(t), [t]);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['inspectionTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.inspectionTab);
        const versionData = getVersionData(data.inspectionTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid,
    selectedRecipient,
    isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <div>
          <FieldGroup label={t(tSchema.isrViewer.inspectors)}>
            <Container fluid className="isrInspectors">
              <DataTable
                rowData={tabData.inspectors}
                idColumn="validityDate"
                tableId="isrInspectorsTable"
                ref={inspectorsTableRef}
                tableConfig={inspectorsTableConfig}
                loading={inspectorsLoading}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
          <FieldGroup label={t(tSchema.isrViewer.preInspection)}>
            <Row>
              <Col md={3}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.vmsPreArrival)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.vmsPreArrival, t)}
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.result)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.vmsPreArrivalResult, t)}
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.details)}
                    :
                  </Form.Label>
                  <p>{tabData.vmsPreArrivalDetails || ''}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.vesselLogbookCompl)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.vesselLogbookCompleted, t)}
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.result)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.vesselLogbookCompletedResult, t)}
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.details)}
                    :
                  </Form.Label>
                  <p>{tabData.vesselLogbookCompletedDetails || ''}</p>
                </div>
              </Col>
            </Row>
          </FieldGroup>
          <FieldGroup label={t(tSchema.isrViewer.statusRfmos)}>
            <Container fluid className="isrStatus">
              <DataTable
                rowData={tabData.statusRFMOs}
                idColumn="validityDate"
                tableId="isrStatusTable"
                ref={statusTableRef}
                tableConfig={statusTableConfig}
                loading={statusLoading}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
        </div>
      )}
    </>
  );
};

IsrViewerDetailsTabInspectionMeans.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabInspectionMeans;
