import excel from './img/excel.png';
import importExcelCsv from './img/import_excel_csv.png';
import createdReference from './img/reference_created.png';
import rowCreatedByExcel from './img/row_created_by_excel.png';
import updateExcel from './img/update_excel.png';

const SectionReferenceDataImport = () => (
  <div className="SectionReferenceDataImport">
    <p>
      To import reference data, in the Reference Data edition form,
      below the attributes grid on the bottom left corner,
      the user shall click on &quot;Import from&quot;.
    </p>
    <p>
      <img src={importExcelCsv} alt="import excel cvs" />
    </p>
    <p>
      <img src={excel} alt="Import Reference Data" />
    </p>
    <p>
      After importing the reference data,
      a modal appears confirming the import and providing a summary of the result.
    </p>
    <p>
      <img src={createdReference} alt="Created Reference Data" />
    </p>
    <p>
      In case new attributes have been imported
      (see an example of this result in the picture above &quot;2 Reference data created&quot;),
      they are added to the attributes list.
    </p>
    <p>
      <img src={rowCreatedByExcel} alt="Row Created By Excel" />
    </p>
    <p>
      In case the code already existed, it is just updated.
    </p>
    <p>
      <img src={updateExcel} alt="Update Reference Data" />
    </p>
    <p />
  </div>
);

export default SectionReferenceDataImport;
