import { pipe } from 'ramda';
import React, { useEffect } from 'react';

import useDomainId from '@services/userPreferences/hooks/useDomainId';

import { getValuesFromJSON, callsMessageOptions, getResponseStatus } from './utils';

import { useRequestWithLoading } from '@common/hooks/request';

const useMessageOptions = ({ setLoading, services }) => {
  const requestWithLoading = useRequestWithLoading();
  const [MessageOptions, setMessageOptions] = React.useState({});
  const domainId = useDomainId();
  useEffect(() => {
    requestWithLoading(async () => {
      const callOptions = await callsMessageOptions({ domainId, services });
      const messagesOptions = await pipe(
        getResponseStatus,
        await getValuesFromJSON,
      )(callOptions);

      setMessageOptions(messagesOptions);
    }, setLoading);
  }, [domainId]);

  return MessageOptions;
};

export default useMessageOptions;
