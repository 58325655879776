import imgDelete from './img/delete_attr.png';

const SectionReferenceDataDelete = () => (
  <div className="SectionReferenceDataDelete">
    <p>
      Once in the edition form of a reference data,
      users can delete attributes clicking on the &quot;Actions&quot;
      button of the corresponding attribute,
      and then selecting &quot;Delete&quot;.
      A modal window opens asking the user for confirmation on the removal.
    </p>
    <p>
      <img src={imgDelete} alt="Delete Attribute" />
    </p>
    <p>
      After clicking the &quot;Delete&quot; button,
      the corresponding attribute is removed from the attributes grid.
    </p>
  </div>
);

export default SectionReferenceDataDelete;
