import {
  del, downloadFile, formatSortParams, get, post, postFile, put,
} from '../utils';

import { FILE_DATE_TIME_FORMAT, formatCurrentDate } from '@common/dateUtils';
import { getFileWithMessage } from '@common/utilities/Notification';

const baseUrl = 'vrService/groupRecord/';

export const paramsToUrl = (params) => (Object.keys(params).length > 0
  ? `?${Object.keys(params).map((key) => (params[key] === 0 || params[key]) && `${key}=${params[key]}`)
    .filter(Boolean).join('&')}`
  : '');

export const apiSearchVessel = (mocked) => (filters) => get(mocked)(
  `logbook/options/vessels/${filters}`,
);

export const apiFetchVesselsGroups = (searchFilters, params) => {
  const filter = searchFilters ? `/${searchFilters}` : '';
  const paramsFormatted = formatSortParams(params) || {};
  const urlParams = Object.keys(paramsFormatted).length > 0
    ? `?${Object.keys(paramsFormatted).map((key) => `${key}=${paramsFormatted[key]}`).join('&')}`
    : '';
  return post()(`${baseUrl}getGroups${filter}${urlParams}`, paramsFormatted);
};

export const apiFetchVesselGroupById = (id) => get()(
  `${baseUrl}${id}`,
);

export const apiExportVesselGroupExcelById = (id) => getFileWithMessage({
  promise: downloadFile()(`${baseUrl}exportExcel/${id}`),
  errorMessage: 'Error exporting the vessel Group',
  fileName: `vessel_group_${id}_export_${formatCurrentDate(FILE_DATE_TIME_FORMAT)}`,
});

export const apiFetchVesselsFromVesselGroup = (id, params, request) => post()(
  `${baseUrl}vessels/${id}${paramsToUrl(params)}`, request,
);

export const apiFetchVesselsFromFile = (formData) => postFile()(
  'vrService/vessel/getVesselsFromFile', formData,
);

export const apiDeleteVesselGroup = (id) => id && del()(
  `logbook/vesselGroup/delete/${id}`,
);

export const apiCheckVesselsDB = (vesselsToCheck) => put()(
  'logbook/checkVessels', vesselsToCheck,
);

export const apiSaveVesselGroup = (vesselGroup, id) => (id
  ? put()(`${baseUrl}${id}`, vesselGroup)
  : post()('vrService/groupRecord', vesselGroup));

export const apiAddVesselsToGroup = (id, vesselsList) => {
  const etalonsList = vesselsList.map((v) => v.etalon_id);
  return post()(`${baseUrl}addVessels?idGroup=${id}`, etalonsList);
};

export const apiDeleteVesselFromGroup = (groupId, vesselId) => del()(
  `${baseUrl}vessel?idGroup=${groupId}&idVessel=${vesselId}`,
);

export const apiFetchVesselGroupsFromFile = (formData) => postFile()(
  `${baseUrl}upload`, formData,
);
