import scenariosTable from './img/scenariosTable.JPG';
import selectUser from './img/selectUser.JPG';

const SectionUsersTable = () => (
  <div className="SectionUsersTable">
    <p>
      In the Users tab, you can select one user from the list displayed
      when the content of the selector is unfolded.
    </p>
    <p>
      <img src={selectUser} alt="User selector" />
    </p>
    <p>
      When a user is selected, a list is displayed containing all the scenarios available:
    </p>
    <p>
      <img src={scenariosTable} alt="Scenarios grid" />
    </p>
    <p>
      In the &quot;Assigned&quot; column you can assign a scenario to the
      selected user by clicking on the corresponding check button and asigning
      dates in the &quot;From&quot; and &quot;To&quot; columns.
      Only the &quot;From&quot; date is mandatory, if the &quot;To&quot; date
      is empty, the assignment has no end date and the scenario remains assigned to the user
      until the check is removed.
    </p>
  </div>
);

export default SectionUsersTable;
