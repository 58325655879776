import React, { useCallback, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EditFailOverModalFactory from '@pages/FailoverViewer/components/EditFailOverModal';
import getTableConfig from '@pages/FailoverViewer/config';
import useFailoverList from '@pages/FailoverViewer/hooks/useFailoverList';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import './FailoverViewer.scss';
import * as apiService from '@services/failover/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import { Permissions, hasPermission } from '@common/keycloakFunctions';
import { DOMAIN } from '@common/utils';

const FailoverViewer = () => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);
  const { userPreferences } = useUserPreferences();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const EditFailOverModal = EditFailOverModalFactory({ apiService });
  const domainCode = userPreferences && userPreferences.domain;
  const { failoverList, isLoading } = useFailoverList(showModal);
  const permissions = useSelector((state) => state.authentication.permissions);
  const editRow = React.useCallback((row) => {
    if (hasPermission(permissions, Permissions.EDIT_FAILOVER)) {
      setSelectedRow(row);
      setShowModal(true);
    }
  }, []);

  const actions = React.useMemo(() => [
    {
      label: t(tSchema.common.edit),
      action: editRow,
      disabled: domainCode === DOMAIN.ISR && !hasPermission(permissions, Permissions.EDIT_FAILOVER),
    },
  ], [editRow]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && editRow(row), // Double Click
    [editRow],
  );

  return (
    <Container fluid className="pt-2 mt-2">
      <DataTable
        actions={actions}
        tableConfig={tableConfig}
        rowData={failoverList}
        loading={isLoading}
        tableId="failoverParameters"
        showPagination={false}
        onRowSelected={handleRowSelected}
      />
      { showModal && (
        <EditFailOverModal
          show={showModal}
          onClose={() => setShowModal(!showModal)}
          parameterName={selectedRow.code}
          parameterType={selectedRow.unit}
          parameterValue={selectedRow.value}
        />
      )}
    </Container>
  );
};

export default FailoverViewer;
