import tSchema from '@lang/schema';

const schema = tSchema.isrViewer;

function getTableConfig(t) {
  return [
    { text: t(schema.typeObservations), code: 'typeObservation' },
    { text: t(schema.observation), code: 'observation' },
  ];
}

export default getTableConfig;
