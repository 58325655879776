import i18next from 'i18next';

import tSchema from '@lang/schema';

import { DOMAIN } from '@common/utils';

const { t } = i18next;

const getDmaAdministration = (links) => ([
  {
    text: t(tSchema.menu.dmaAdministration),
    icon: 'database',
    links,
  },
]);

export const faDataAdmin = ({ routes }) => getDmaAdministration([
  {
    url: routes.messageViewer.url,
    text: t(tSchema.menu.messageViewer),
    icon: 'email',
  },
  {
    url: routes.scenarioMGMT.url,
    text: t(tSchema.menu.scenarioMGMT),
    icon: 'assignment',
  },
  {
    text: t(tSchema.menu.brEngine),
    icon: 'database',
    isOpen: true,
    links: [
      {
        url: routes.validationBR.url,
        text: t(tSchema.menu.validationBR),
        icon: 'checklist',
      },
      {
        url: routes.acceptanceBR.url,
        text: t(tSchema.menu.acceptanceBR),
        icon: 'checklist',
      },
    ],
  },
  {
    url: routes.queryReportMGMT.url,
    text: t(tSchema.menu.queryReportMGMT),
    icon: 'cog-solid',
  },
  {
    url: routes.referenceDataMGMT.url,
    text: t(tSchema.menu.referenceDataMGMT),
    icon: 'edit',
  },
  {
    url: routes.vesselGroupMGMT.url,
    text: t(tSchema.menu.vesselGroupMGMT),
    icon: 'vessel',
  },
]);

export const vrDataAdmin = ({ domain, routes }) => {
  const isVesselDomain = domain === DOMAIN.VESSEL;
  return getDmaAdministration([
    {
      url: routes.vesselGroupMGMT.url,
      text: t(tSchema.menu.vesselGroupMGMT),
      icon: 'vessel',
    },
    {
      url: routes.referenceDataMGMT.url,
      text: t(tSchema.menu.referenceDataMGMT),
      icon: 'edit',
    },

    {
      text: t(tSchema.menu.brEngine),
      icon: 'database',
      isOpen: true,
      links: isVesselDomain ? [
        {
          url: routes.validationBR.url,
          text: t(tSchema.menu.validationBR),
          icon: 'checklist',
        },
      ] : [
        {
          url: routes.validationBR.url,
          text: t(tSchema.menu.validationBR),
          icon: 'checklist',
        },
        {
          url: routes.acceptanceBR.url,
          text: t(tSchema.menu.acceptanceBR),
          icon: 'checklist',
        },
      ],
    },
    {
      url: routes.messageViewer.url,
      text: t(tSchema.menu.messageViewer),
      icon: 'email',
    },
    {
      url: routes.queryReportMGMT.url,
      text: t(tSchema.menu.queryReportMGMT),
      icon: 'cog-solid',
    },
    {
      url: routes.externalSourcesMGMT.url,
      text: t(tSchema.menu.externalSourcesMGMT),
      icon: 'cog-solid',
    },
    {
      url: routes.dataAdministrationReporting.url,
      text: t(tSchema.menu.dataAdminReporting),
      icon: 'cog-solid',
    },
  ]);
};

export const irDataAdmin = ({ routes }) => getDmaAdministration([
  {
    url: routes.messageViewer.url,
    text: t(tSchema.menu.messageViewer),
    icon: 'email',
  },
  {
    url: routes.scenarioMGMT.url,
    text: t(tSchema.menu.scenarioMGMT),
    icon: 'assignment',
  },
  {
    url: routes.referenceDataMGMT.url,
    text: t(tSchema.menu.referenceDataMGMT),
    icon: 'edit',
  },
  {
    text: t(tSchema.menu.brEngine),
    icon: 'database',
    isOpen: true,
    links: [
      {
        url: routes.validationBR.url,
        text: t(tSchema.menu.validationBR),
        icon: 'checklist',
      },
      {
        url: routes.acceptanceBR.url,
        text: t(tSchema.menu.acceptanceBR),
        icon: 'checklist',
      },
    ],
  },
]);
