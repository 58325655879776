import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import messagesBRISRImg from './img/messages-br-isr.png';
import messagesBRImg from './img/messages-br.png';

import { DOMAIN } from '@common/utils';

const SectionMessagesBR = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="subSection">
      <p>
        When the user clicks on the BR button of a message displayed
        in the&quot;Messages list&quot;, a new modal window is opened
        containing BR Validation details related to selected message.
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messagesBRImg} alt="messageBR" />
          : <img src={messagesBRISRImg} alt="messageBRISR" />}
      </p>
      <ul>
        <li>BR status in red, means BR validation resulted in errors.</li>
        <li>BR status in yellow, means BR validation resulted in warnings.</li>
        <li>BR status in green, means BR validation successful.</li>
      </ul>
    </div>
  );
};

export default SectionMessagesBR;
