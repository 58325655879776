import i18next from 'i18next';

import tSchema from '@lang/schema';

const { t } = i18next;

export const faCoordinators = ({ routes }) => ([
  {
    text: t(tSchema.menu.vesselRecord),
    icon: 'vessel',
    isOpen: true,
    links: [
      {
        url: routes.vesselMGMT.url,
        text: t(tSchema.menu.vesselMGMT),
        icon: 'vessel',
      },
    ],
  },
  {
    text: t(tSchema.menu.fishingActivities),
    icon: 'fish',
    isOpen: true,
    links: [
      {
        url: routes.logbookViewer.url,
        text: t(tSchema.menu.logbookViewer),
        icon: 'eye',
      },
    ],
  },
]);

export const vrCoordinators = ({ routes }) => ([
  {
    text: t(tSchema.menu.vesselRecord),
    icon: 'vessel',
    isOpen: true,
    links: [
      {
        url: routes.vesselMGMT.url,
        text: t(tSchema.menu.vesselMGMT),
        icon: 'vessel',
      },
    ],
  },
]);

export const irCoordinators = ({ routes }) => ([
  {
    text: t(tSchema.menu.isrReports),
    icon: 'checklist',
    isOpen: true,
    links: [
      {
        url: routes.isrViewer.url,
        text: t(tSchema.menu.isrViewer),
        icon: 'eye',
      },
    ],
  },
]);

// export const irCoordinators = ({ routes }) => ([
//   {
//     text: t(tSchema.menu.vesselRecord),
//     icon: 'vessel',
//     isOpen: true,
//     links: [
//       {
//         url: routes.vesselMGMT.url,
//         text: t(tSchema.menu.vesselMGMT),
//         icon: 'vessel',
//       },
//     ],
//   },
//   {
//     text: 'Inspection Report MGMT',
//     icon: 'database',
//     isOpen: true,
//     links: [
//       {
//         url: routes.inspectionReportValidation.url,
//         text: 'Inspection Report Validation',
//         icon: 'clipboard-check-solid',
//       },
//       {
//         url: routes.inspectionReportQuery.url,
//         text: 'Inspection Report Query',
//         icon: 'edit',
//       },
//     ],
//   },
// ]);
