/* eslint-disable linebreak-style */
/* eslint-disable import/order */

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import * as Yup from 'yup';
import {
  Button, Col, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { fromLocalToUTCDate, fromLocalToUTCHour, getDateForInput } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { selectUtils } from '@common/utilities/selectUtils';
import { DOMAIN, formatSelectOption } from '@common/utils';
import tSchema from '@lang/schema';
import EDITOR_MODE from '@pages/QuerySubscriptions/config';
import {
  apiFetchQuerySubscription,
  apiFetchVesselQuerySubscription,
  apiFetchReportSubscription,
} from '@services/queries/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import FieldGroup from '../../../../components/Form/FieldGroup';
import InputTerritory from '../../../../components/Form/Inputs/InputTerritory';

import {
  GeneralFieldGroup, QueryParametersFieldGroup, TypeFieldGroup, VesselSelectsForm,
} from './FormComponents';
import {
  queryDefaultValues,
  vesselDefaultValues,
  getOptions,
  mapSubscriptionIn,
  mapSubscriptionOut,
  reportValidationSchema,
  schemaValidation,
  vesselQueryValidationSchema,
  queryValidationSchema,
} from './utils';

import './style.scss';

const schema = tSchema.dmaAdmin.queryReportMGMT.createEditView;

const QueryEditor = ({
  isDataAdmin,
  queryId,
  mode,
  onSave,
  onCancel,
  options,
  isReport,
  loading: parentLoading,
  maximumDaysToQuery,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(parentLoading);
  const [querySubscription, setQuerySubscription] = useState({});
  const [selectedVesselGroups, setSelectedVesselGroups] = useState([]);
  const [selectedVessels, setSelectedVessels] = useState([]);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');

  const requestWithLoading = useRequestWithLoading();

  const { userPreferences: { domain, domains } } = useUserPreferences();

  const isVesselDomain = domain === DOMAIN.VESSEL;

  const isSelectedVessel = selectedDomain === DOMAIN.VESSEL;

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  useEffect(() => {
    isReport && isVesselDomain ? setSelectedDomain(DOMAIN.FA) : setSelectedDomain(domain);
  }, [domain, isReport, isVesselDomain]);

  const {
    recurrenceOptions, fluxPartiesOptions, statusOptions,
    vesselGroupsOptions, fishingTripsOptions, vesselQueryTypeOptions, vesselStatusOptions,
  } = useMemo(() => (options ? {
    recurrenceOptions: options?.recurrence && getOptions('recurrence')(options),
    fluxPartiesOptions: options?.fluxParties && getOptions('fluxParties', 'code', 'name', (option) => formatSelectOption(option, 'code', 'name').description)(options),
    statusOptions: options?.recurrence && getOptions('status')(options),
    vesselStatusOptions: getOptions('vesselStatus')(options),
    vesselQueryTypeOptions: options?.vesselQueryType && getOptions('vesselQueryType')(options),
    vesselGroupsOptions: selectUtils.mapOptions({ options: options.vesselGroups, labelPropName: 'name' }),
    vesselFlagsOptions: selectUtils.mapOptions({ options: options.vesselFlags }),
    fishingTripsOptions: selectUtils.mapOptionsTrips({ options: options.trips }),
  } : {}), [options]);

  useEffect(() => {
    if (queryId !== null && queryId >= 0) {
      requestWithLoading(async () => {
        let response;
        if (isReport) {
          response = await apiFetchReportSubscription(queryId);
        } else if (isVesselDomain) {
          response = await apiFetchVesselQuerySubscription(queryId);
        } else {
          response = await apiFetchQuerySubscription(queryId);
        }

        if (response.ok) {
          const data = await response.json();
          setQuerySubscription(mapSubscriptionIn(data));
          setSelectedVesselGroups(
            selectUtils.selectedOptions(
              vesselGroupsOptions, data.queryObject && data.queryObject?.groups,
            ),
          );
          setSelectedVessels(
            data.queryObject?.vessel,
          );
          setSelectedTrips(
            selectUtils.selectedOptions(
              fishingTripsOptions, data.tripObject,
            ),
          );
        } else {
          setQuerySubscription({});
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    }
  }, [queryId, requestWithLoading]);

  const handleOnSave = useCallback((valuesForm) => {
    const isQueryTypeNews = valuesForm.vesselQueryType === '1';
    const isQueryObjectVessel = valuesForm.queryType === 1;
    const isScheduled = valuesForm.type === 1;
    const isDataScopeLastDays = valuesForm.dataScopeType === 'lastDays';
    const hourUTC = valuesForm.schedulingOptions?.executionTime
      ? fromLocalToUTCHour(valuesForm.schedulingOptions?.executionTime)
      : valuesForm.schedulingOptions?.executionTime;
    const dateStartUTC = valuesForm.schedulingOptions?.startDate ? fromLocalToUTCDate(
      valuesForm.schedulingOptions?.startDate, valuesForm.schedulingOptions?.executionTime,
    ) : valuesForm.schedulingOptions?.startDate;
    const dateEndUTC = valuesForm.schedulingOptions?.endDate ? fromLocalToUTCDate(
      valuesForm.schedulingOptions?.endDate, valuesForm.schedulingOptions?.executionTime,
    ) : valuesForm.schedulingOptions?.endDate;
    const mappedValues = {
      ...valuesForm,
      dataScopeFromTo: !isDataScopeLastDays ? {
        to: getDateForInput(valuesForm.dataScopeFromTo?.to),
        from: getDateForInput(valuesForm.dataScopeFromTo?.from),
      } : undefined,
      dataScopeLastDays: isDataScopeLastDays ? valuesForm.dataScopeLastDays : undefined,
      queryObject: (!isQueryTypeNews || isQueryObjectVessel) ? {
        ...valuesForm.queryObject,
        vessel: valuesForm.queryObject?.vessel?.map(
          // eslint-disable-next-line camelcase
          ({ etalon_id, cfr, ircs }) => ({ etalon_id, cfr, ircs }),
        ),
        groups: valuesForm.queryObject?.groups?.map((e) => e.value),
      } : undefined,
      schedulingOptions: isScheduled ? {
        ...valuesForm.schedulingOptions,
        executionTime: hourUTC,
        startDate: dateStartUTC,
        endDate: dateEndUTC,
      } : undefined,
      tripObject: !isSelectedVessel
      && !isQueryObjectVessel
        ? valuesForm.tripObject?.map((e) => e.value) : null,
      vesselCountry: isSelectedVessel && !isQueryTypeNews ? valuesForm.vesselCountry : undefined,
    };

    onSave(mapSubscriptionOut(mappedValues, options), isSelectedVessel);
  }, [onSave, options, isSelectedVessel]);

  const getSchemaValidation = useMemo(() => {
    const schemaLastDays = Yup.object().shape({
      dataScopeLastDays: Yup.number().when(
        ['dataScopeType', 'vesselQueryType'],
        (dataScopeType, vesselQueryType) => ((dataScopeType !== 'fromTo' && +vesselQueryType !== 2)
          ? Yup.number().min(1, 'Last Day must be greater than or equal to 1').required('Last Day is a required field').max(maximumDaysToQuery, `Last Day must be smaller than or equal to ${maximumDaysToQuery}`)
          : Yup.number()),
      ),
    });
    return schemaValidation.concat(schemaLastDays);
  }, [schemaValidation]);

  const disabled = useMemo(() => !((mode === EDITOR_MODE.CREATE || mode === EDITOR_MODE.EDIT)
  && isDataAdmin),
  [mode, isDataAdmin]);
  const originalSender = isReport ? { originalSender: querySubscription.originalSender || '' } : {};

  const initialValues = useMemo(() => {
    const defaultValues = isSelectedVessel ? vesselDefaultValues(t) : queryDefaultValues;

    return {
      ...originalSender,
      ...defaultValues,
      isReport,
      ...querySubscription,
      dataScopeFromTo: {
        from: getDateForInput(querySubscription.dataScopeFromTo?.from) || '',
        to: getDateForInput(querySubscription.dataScopeFromTo?.to) || '',
      },
      schedulingOptions: {
        ...defaultValues.schedulingOptions,
        ...querySubscription.schedulingOptions,
        startDate: getDateForInput(querySubscription.schedulingOptions?.startDate),
        endDate: getDateForInput(querySubscription.schedulingOptions?.endDate),
      },
      queryObject: {
        vessel: selectedVessels || [],
        groups: selectedVesselGroups || [],
      },
      tripObject: selectedTrips || [],
      id: (queryId > 0) ? queryId : null,
      name: querySubscription.name || '',
      description: querySubscription.description || '',
    };
  }, [
    querySubscription,
    queryDefaultValues,
    vesselDefaultValues,
    selectedVessels,
    selectedVesselGroups,
    selectedTrips,
    isReport,
    isSelectedVessel,
    t,
  ]);

  let validation;

  if (isReport) {
    validation = getSchemaValidation.concat(reportValidationSchema);
  } else if (isSelectedVessel) {
    validation = getSchemaValidation.concat(vesselQueryValidationSchema);
  } else {
    validation = getSchemaValidation.concat(queryValidationSchema);
  }

  // TODO: continuar con la union de la validacion.(isReport ? { reportValidationSchema } : {})
  // const validation = isReport ? schemaValidation.concat(reportValidationSchema) :
  // schemaValidation;
  // { ...schemaValidation, ...(isReport && { ...reportValidationSchema.fields }) };

  const handleChangeDomain = useCallback(({ target: { value } }) => setSelectedDomain(value), []);

  return (
    <Formik
      validationSchema={validation}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleOnSave}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        touched,
        errors,
      }) => {
        const isScheduled = values.type === 1;
        const isTouched = !!touched.id;
        const showVesselCountry = useMemo(() => (
          +values.vesselQueryType === 2 || +values.vesselQueryType === 1)
          && selectedDomain === DOMAIN.VESSEL, [values.vesselQueryType, selectedDomain]);

        return (
          <Form
            key={querySubscription.id}
            id="query-editor"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="editor-fields">
              {loading && (
              <div className="loading-div">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t(tSchema.common.loading)}</span>
                </div>
              </div>
              )}
              {mode === EDITOR_MODE.CREATE && (
                <Form.Row>
                  <Col xl={{ span: 6 }} sm={{ span: 12 }}>
                    <Form.Group>
                      <Form.Label htmlFor="basic-url">
                        {t(schema.domainSelector)}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onClick={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        onChange={handleChangeDomain}
                        value={selectedDomain}
                        style={{ height: '2.375rem' }}
                        className="p-2.5 w-70"
                      >
                        { domains ? domains.map(({ code }) => (
                          (isReport && code === DOMAIN.VESSEL) || code === DOMAIN.ISR
                            ? null
                            : (
                              <option key={`${code}--id`} value={code}>
                                {code}
                              </option>
                            ))) : ''}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
              )}
              {selectedDomain !== 'IR' && (
                <Form.Row>
                  <Col xl={{ span: 6 }} sm={{ span: 12 }}>
                    <GeneralFieldGroup
                      values={values}
                      errors={{ name: errors.name, description: errors.description }}
                      touched={{ name: touched.name, description: touched.description }}
                      disabled={disabled}
                      handleChange={handleChange}
                    />
                    <QueryParametersFieldGroup
                      errors={errors}
                      values={values}
                      disabled={disabled}
                      isTouched={isTouched}
                      handleChange={handleChange}
                      vesselQueryTypeOptions={vesselQueryTypeOptions}
                      fluxPartiesOptions={fluxPartiesOptions}
                      vesselStatusOptions={vesselStatusOptions}
                      touched={touched}
                      isReport={isReport}
                      selectedDomain={selectedDomain}
                      maximumDaysToQuery={maximumDaysToQuery}
                    />

                  </Col>
                  <Col xl={{ span: 6 }} sm={{ span: 12 }}>
                    <TypeFieldGroup {...{
                      isTouched,
                      isScheduled,
                      errors,
                      values,
                      handleChange,
                      setFieldValue,
                      disabled,
                      type: options?.type,
                      recurrenceOptions,
                      statusOptions,
                    }}
                    />
                    {(+values.vesselQueryType === 2
                      || selectedDomain === DOMAIN.FA || showVesselCountry) && (
                      <FieldGroup label={t(schema.queryObject)}>
                        {showVesselCountry && (
                        <Form.Group as={Col} controlId="formVesselCountry" className="pl-0 pr-0">
                          <Form.Label>
                            {t(schema.vesselCountry)}
                          </Form.Label>
                          <InputTerritory
                            id="vesselCountry"
                            name="vesselCountry"
                            className="attribute-filter-select"
                            isTouched={isTouched}
                            errors={errors.vesselCountry}
                            value={values.vesselCountry || ''}
                            disabled={disabled}
                            placeholder={disabled && t('-')}
                            onChange={handleChange}
                          />
                          {/* hidden field for bootstrap Form.Control.Feedback */}
                          <Form.Control
                            name="vesselCountry"
                            value={values.vesselCountry}
                            disabled
                            hidden
                            isInvalid={isTouched && !!errors.vesselCountry}
                            isValid={isTouched && !errors.vesselCountry}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.vesselCountry}
                          </Form.Control.Feedback>
                        </Form.Group>
                        )}
                        {(selectedDomain === DOMAIN.FA) && (
                        <Form.Row>
                          <Form.Group as={Col}>
                            {options && options.queryType
                          && options.queryType.map((e) => (
                            <Form.Check
                              key={e.id}
                              inline
                              id={`query-type-option-${e.description}`}
                              label={t(schema.queryTypeOptions[e.id])}
                              value={e.id}
                              checked={values.queryType === e.id}
                              name="queryType"
                              type="radio"
                              onChange={(event) => {
                                setFieldValue('queryType', +event.currentTarget?.value);
                                setFieldValue('queryObject.vessel', []);
                                setFieldValue('queryObject.groups', []);
                                setFieldValue('tripObject', []);
                              }}
                              disabled={disabled}
                            />
                          ))}
                          </Form.Group>
                        </Form.Row>
                        )}
                        {(values.queryType === 1
                        || +values.vesselQueryType === 2) && (
                        <VesselSelectsForm
                          errors={errors}
                          isTouched={isTouched}
                          disabled={disabled}
                          values={values}
                          setFieldValue={setFieldValue}
                          vesselGroupsOptions={vesselGroupsOptions}
                          selectStyles={selectUtils.selectStyles}
                        />
                        )}
                        {values.queryType === 2 && (
                        <Form.Row>
                          <Form.Group as={Col} controlId="formTrips">
                            <Form.Label>
                              {t(schema.trips)}
                            </Form.Label>
                            <ReactSelect
                              name="tripObject"
                              styles={isTouched
                              && selectUtils.selectStyles(!errors.tripObject)}
                              className={isTouched && (errors.tripObject ? 'is-invalid' : 'is-valid')}
                              isDisabled={mode === EDITOR_MODE.VIEW}
                              isMulti
                              closeMenuOnSelect={false}
                              value={values.tripObject}
                              options={fishingTripsOptions}
                              onChange={(option) => setFieldValue('tripObject', option)}
                              disabled={disabled}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.tripObject}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        )}
                      </FieldGroup>
                    )}
                  </Col>
                </Form.Row>
              )}
            </div>
            {(mode !== EDITOR_MODE.VIEW && selectedDomain !== DOMAIN.ISR) && (
            <Form.Row className="actions justify-content-end">
              <Button className="mr-3" variant="primary" onClick={onCancel}>
                <span className="symbol-cross" />
                {' '}
                {t(tSchema.common.cancel)}
              </Button>
              <Button variant="primary" type="submit" disabled={loading}>
                <span className="symbol-tick" />
                {' '}
                {t(tSchema.common.save)}
              </Button>
            </Form.Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const arrayIdDescriptionPropType = PropTypes.arrayOf(PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}));

QueryEditor.propTypes = {
  isDataAdmin: PropTypes.bool,
  isReport: PropTypes.bool,
  queryId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  options: PropTypes.shape({
    fluxParties: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      endDate: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
    })),
    queryType: arrayIdDescriptionPropType,
    vesselQueryType: arrayIdDescriptionPropType,
    recurrence: arrayIdDescriptionPropType,
    status: arrayIdDescriptionPropType,
    trips: arrayIdDescriptionPropType,
    type: arrayIdDescriptionPropType,
    vesselFlags: arrayIdDescriptionPropType,
    vesselGroups: PropTypes.arrayOf(PropTypes.shape({
      active: PropTypes.bool,
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    vesselIdType: arrayIdDescriptionPropType,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  maximumDaysToQuery: PropTypes.number,
};

QueryEditor.defaultProps = {
  queryId: null,
  isDataAdmin: false,
  isReport: false,
  loading: false,
  maximumDaysToQuery: null,
};

export default QueryEditor;
