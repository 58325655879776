import ToggleTable from '@components/DataTable/components/ToggleTable';

import tSchema from '@lang/schema';

import { formatDateTime } from '@common/dateUtils';

export const renderDeparturePort = ({ port, portName }) => {
  if (!port || !portName) {
    return '-';
  }
  return `${port} - ${portName}`;
};

const schema = tSchema.fishingActivities.logbookViewer.tripView;

const vesselFields = [
  'vesselFlag',
  'vesselName',
  'cfr',
  'uvi',
  'extMark',
  'ircs',
  'iccat',
  'gfcm',
];

function formatTableField(row, value, field) {
  let result = null;
  if (row) {
    result = value;
    if (row.isLinked) {
      if (vesselFields.includes(field)) {
        result = (
          <div style={{ color: '#01b4ec' }}>
            {`${value}`}
          </div>
        );
      }
    }
  }
  return result;
}

export const tableConfig = (t, onToggle) => (toggled) => [
  {
    text: t(schema.tripId),
    code: 'id',
    sortable: true,
    render: (row) => formatTableField(row, row.id, 'id'),
  },
  {
    text: t(schema.flagState),
    code: 'vesselFlag',
    sortable: true,
    render: (row) => formatTableField(row, row.vesselFlag, 'vesselFlag'),
  },
  {
    text: t(schema.name),
    code: 'name',
    sortable: true,
    render: (row) => formatTableField(row, row.vesselName, 'vesselName'),
  },
  {
    text: t(schema.cfr),
    code: 'cfr',
    sortable: true,
    render: (row) => formatTableField(row, row.cfr, 'cfr'),
  },
  {
    text: t(schema.uvi),
    code: 'uvi',
    sortable: true,
    hidden: !toggled,
    render: (row) => formatTableField(row, row.uvi, 'uvi'),
  },
  {
    text: t(schema.ext),
    code: 'ext',
    sortable: true,
    hidden: !toggled,
    render: (row) => formatTableField(row, row.extMark, 'extMark'),
  },
  {
    text: t(schema.ircs),
    code: 'ircs',
    sortable: true,
    hidden: !toggled,
    render: (row) => formatTableField(row, row.ircs, 'ircs'),
  },
  {
    text: t(schema.iccat),
    code: 'iccat',
    sortable: true,
    hidden: !toggled,
    render: (row) => formatTableField(row, row.iccat, 'iccat'),
  },
  {
    text: t(schema.gfcm),
    code: 'gfcm',
    sortable: true,
    hidden: !toggled,
    render: (row) => formatTableField(row, row.gfcm, 'gfcm'),
  },
  {
    text: t(schema.toggle), header: <ToggleTable value={toggled} onClick={onToggle} />, code: 'toggle', render: () => '',
  },
  {
    text: t(schema.startedOn),
    code: 'startedOn',
    sortable: true,
    render: (row) => formatTableField(row, (formatDateTime(row.startDate) !== '-' ? `${formatDateTime(row.startDate)}, UTC` : '-'), 'startDate'),
  },
  {
    text: t(schema.departurePort),
    code: 'departurePort',
    sortable: true,
    render: (row) => formatTableField(row, renderDeparturePort(row), 'departurePort'),
  },
  {
    text: t(schema.lastActivity),
    code: 'fishingActivityType',
    sortable: true,
    render: (row) => formatTableField(row, row.lastNotification.faType, 'fishingActivityType'),
  },
  {
    text: t(schema.type),
    code: 'lastReportType',
    sortable: true,
    render: (row) => formatTableField(row, row.lastNotification.reportType, 'lastReportType'),
  },
  {
    text: t(schema.lastActivityDate),
    code: 'lastActivityDate',
    sortable: true,
    render: (row) => formatTableField(row,
      (formatDateTime(row.lastNotification.occurrenceDate) !== '-'
        ? `${formatDateTime(row.lastNotification.occurrenceDate)}, UTC` : '-'), 'occurrenceDate'),
  },
  {
    text: t(schema.lastActivityReceivedOn),
    code: 'lastActivityReceivedOn',
    sortable: true,
    render: (row) => formatTableField(row,
      (formatDateTime(row.lastNotification.notificationDate) !== '-'
        ? `${formatDateTime(row.lastNotification.notificationDate)}, UTC` : '-'), 'notificationDate'),
  },
  {
    text: t(schema.onGoing),
    code: 'onGoing',
    sortable: true,
    render: (row) => formatTableField(row, (row.status ? 'Active' : 'Finished'), 'status'),
  },
  {
    text: t(schema.daysAtSea),
    code: 'daysAtSea',
    sortable: true,
    render: (row) => formatTableField(row, row.daysAtSea, 'daysAtSea'),
  },
];
