import ascendingIcon from './img/ascending-icon.png';
import descendingIcon from './img/descending-icon.png';
import notSortedIcon from './img/notsorted-icon.png';
import tableSort from './img/table-sort.png';

const SORTING = (
  <div className="subSection">
    <p>
      Sorting is a mechanism that allows the user to sort the data displayed in the grid.
    </p>
    <p>
      When a grid column is sortable, a sort icon is displayed in the column header.
    </p>
    <p>
      <img src={tableSort} alt="tableSort" />
    </p>
    <p>
      The sort icon indicates the current sort direction.
      <ul>
        <li>
          <img src={notSortedIcon} alt="notSortedIcon" />
          Not sorted.
        </li>
        <li>
          <img src={ascendingIcon} alt="ascendingIcon" />
          Ascending.
        </li>
        <li>
          <img src={descendingIcon} alt="descendingIcon" />
          Descending.
        </li>
      </ul>
    </p>
    <p>
      When a user sorts the data pressing the sort icon,
      the icon changes automatically to reflect the sort direction.
    </p>
    <p>
      When the user clicks on an unsorted column,
      the data is sorted in ascending order and the sort icon changes
      to reflect that sort direction.
    </p>
    <p>
      When the user clicks on a column sorted in ascending order,
      the data is sorted in descending order and the sort icon
      changes to reflect that sort direction.
    </p>
    <p>
      When the user clicks on a column sorted in descending order,
      previous ordering restrictions are removed and the sort icon
      changes to reflect that the default order is applied to the data displayed.
    </p>
  </div>
);

export default SORTING;
