/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';

const { t } = i18next;

const systemAdmin = ({ routes, isDataAdmin, isISRDataAdmin }) => {
  const links = [];
  if (isDataAdmin) {
    links.push({
      url: routes.trafficMonitoring.url,
      text: t(tSchema.menu.trafficMonitoring),
      icon: 'traffic-light-solid',
    });
  } else if (isISRDataAdmin) {
    links.push({
      url: routes.failOverParameters.url,
      text: t(tSchema.menu.failoverParameters),
      icon: 'traffic-light-solid',
    });
  } else {
    links.push(
      {
        url: routes.trafficMonitoring.url,
        text: t(tSchema.menu.trafficMonitoring),
        icon: 'traffic-light-solid',
      },
      {
        url: routes.failOverParameters.url,
        text: t(tSchema.menu.failoverParameters),
        icon: 'traffic-light-solid',
      },
      {
        url: window.env.REACT_APP_KEYCLOAK_URL,
        text: t(tSchema.menu.userManagement),
        icon: 'cog-solid',
      },
      {
        url: routes.servicesMonitoring.url,
        text: t(tSchema.menu.servicesMonitoring),
        icon: 'cog-solid',
      },
    );
  }
  return (

    [
      {
        text: t(tSchema.menu.dataExchangeModule),
        icon: 'database',
        isOpen: true,
        links,
      },

      //   {
      //     url: routes.dbAdministration.url,
      //     text: t(tSchema.menu.DB) Administration',
      //     icon: 'cog-solid',
      //   },

    //   {
    //     url: routes.operationalProcedure.url,
    //     text: t(tSchema.menu.Operational) Procedure',
    //     icon: 'cog-solid',
    //   },
    ]);
};

export default systemAdmin;
