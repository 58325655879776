import SectionAddQuery from './SectionAddQuery';
import SectionDeleteQuery from './SectionDeleteQuery';
import SectionEditQuery from './SectionEditQuery';
import SectionExecuteQuery from './SectionExecuteQuery';
import SectionQuerySubscription from './SectionQuerySubscription';
import SectionViewQuery from './SectionViewQuery';

const QUERY_REPORT = {
  TABLE_QUERY: {
    title: 'Query Subscription Table',
    body: <SectionQuerySubscription />,
  },
  CREATE: {
    title: 'Create Query',
    body: <SectionAddQuery />,
  },
  EDIT: {
    title: 'Edit Query',
    body: <SectionEditQuery />,
  },
  DELETE: {
    title: 'Delete Query',
    body: <SectionDeleteQuery />,
  },
  VIEW: {
    title: 'View Query',
    body: <SectionViewQuery />,
  },
  EXECUTE: {
    title: 'Execute Query',
    body: <SectionExecuteQuery />,
  },

};

export default QUERY_REPORT;
