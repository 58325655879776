/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { Redirect, Route, useParams } from 'react-router-dom';

const TitleWrapper = ({ title, children }) => {
  const { sectionId } = useParams();
  document.title = [window.env.REACT_APP_TITLE, title, sectionId].filter(Boolean).join(' - ');
  return children;
};
TitleWrapper.propTypes = {
  title: PropTypes.string,
};

TitleWrapper.defaultProps = {
  title: null,
};

const PrivateRoute = ({
  component: Component,
  componentProps,
  isLogedIn,
  enabled,
  title,
  ...rest
}) => {
  const redirectTo = (!isLogedIn && {
    pathname: '/login',
    state: { referrer: rest.location },
  }) || (!enabled && {
    pathname: '/',
  });

  return (
    <Route
      {...rest}
      render={(props) => (!redirectTo ? (
        <TitleWrapper title={title}>
          <Component {...props} {...componentProps} />
        </TitleWrapper>
      ) : (
        <Redirect
          push
          to={redirectTo}
        />
      ))}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  componentProps: PropTypes.object,
  isLogedIn: PropTypes.bool,
  enabled: PropTypes.bool,
  title: PropTypes.string,
};

PrivateRoute.defaultProps = {
  componentProps: null,
  isLogedIn: false,
  enabled: true,
  title: null,
};

export default PrivateRoute;
