/* eslint-disable import/order */
import PropTypes from 'prop-types';
import React from 'react';
import BRStatusButton from './BRStatusButton';

const StatusFlagComponent = ({ message }) => (
  <div className="ml-1">
    <button
      type="button"
      className="custom-button"
      tabIndex={0}
      style={{ width: '35px' }}
    >
      <BRStatusButton
        statusResult={message.validationResults}
        message={message}
      />
    </button>
  </div>

);

StatusFlagComponent.propTypes = {
  message: PropTypes.objectOf({}),
};

StatusFlagComponent.defaultProps = {
  message: null,
};

export default StatusFlagComponent;
