import Crud from './crud';

/* Initial State of Reference Data */
const initialState = {
  referenceData: [],
  originReferenceData: [],
  referenceAttributes: [],
  updatedReferenceData: {},
  isLoading: false,
  completedUpdateAttributes: false,
  error: false,
  pagination: {
    page: 1,
    size: 10,
    totalCount: 0,
    maxPages: 1,
  },
  errorMessage: '',
  uploadReference: {
    isUploadingReference: false,
    reports: null,
    error: null,
  },
};

const Managers = {
  ...Crud,
};

export default (state = initialState, action = { type: '' }) => {
  const finded = Managers[action.type];
  return finded ? finded(state, action) : { ...state };
};
