export const INSPECTION_MEANS = 'INSPECTION_MEANS';
export const PATROL_VESSEL = 'PATROL_VESSEL';
export const INSPECTED_VESSEL = 'INSPECTED_VESSEL';
export const DOCUMENTS = 'DOCUMENTS';
export const GEAR = 'GEAR';
export const CATCH_PRODUCTS = 'CATCH_PRODUCTS';
export const INFRINGEMENTS = 'INFRINGEMENTS';
export const INSPECTION_CLOSURE = 'INSPECTION_CLOSURE';
export const ATTACHMENTS = 'ATTACHMENTS';
export const CURRENT_STATE = 'CURRENT';
export const DELETED_STATE = 'DELETED';
export const CORRECTED_STATE = 'CORRECTED';
