/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, componentProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component {...props} {...componentProps} />}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  componentProps: PropTypes.object,
};

PublicRoute.defaultProps = {
  componentProps: null,
};

export default PublicRoute;
