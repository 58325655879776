import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import messageContentISRImg from './img/messages-content-isr.png';
import messageContentImg from './img/messages-content.png';
import messageRelatedContentISRImg from './img/messages-related-content-isr.png';
import messageRelatedContentImg from './img/messages-related-content.png';

import { DOMAIN } from '@common/utils';

const SectionMessagesContent = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="subSection">
      <p>
        Central section is a message overview tab where user can see the selected message content.
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messageContentImg} alt="messagesContent" />
          : <img src={messageContentISRImg} alt="messagesContentISR" />}
      </p>
      <p>
        If a related message was selected, a new tab is displayed with that related message content.
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messageRelatedContentImg} alt="messagesRelatedContent" />
          : <img src={messageRelatedContentISRImg} alt="messagesRelatedContentISR" />}
      </p>
    </div>
  );
};

export default SectionMessagesContent;
