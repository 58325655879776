import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Button,
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';
import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { FISHING_OPERATION_RELATED_VESSELS_PROPTYPES, FISHING_OPERATION_RELATED_VESSEL_PROPTYPES } from '../../constants';
import ContactInfoModal from '../Modals/ContactInfoModal';

const schema = tSchema.fishingActivities.logbookViewer.details;

const RelatedVessel = ({ relatedVessel, handleClickContactInfo }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-3">
      <Row>
        <Col md={3} sm={6} xs={12}>
          <TextInputField label={t(schema.role)} value={relatedVessel?.role} />
          <TextInputField label={t(schema.name)} value={relatedVessel?.name} />
          <TextInputField label={t(schema.country)} value={relatedVessel?.country} />
        </Col>
        <Col md={3} sm={6} xs={12}>
          <TextInputField label={t(schema.cfr)} value={relatedVessel?.cfr} />
          <TextInputField label={t(schema.uvi)} value={relatedVessel?.uvi} />
        </Col>
        <Col md={3} sm={6} xs={12}>

          <TextInputField label={t(schema.extMark)} value={relatedVessel?.extMark} />
          <TextInputField label={t(schema.iccat)} value={relatedVessel?.iccat} />
          <TextInputField label={t(schema.gfcm)} value={relatedVessel?.gfcm} />
        </Col>
        <Col md={3} sm={12}>
          <FieldGroup label={t(schema.contact)}>
            <Row>
              <Col xs="12">
                <TextInputField
                  label={t(schema.name)}
                  value={relatedVessel?.contactInfo?.givenName}
                >
                  <Row>
                    <Col xs="6">
                      <Form.Control type="text" disabled value={relatedVessel?.contactInfo?.givenName} />
                    </Col>
                    <Col xs="6">
                      <Form.Control type="text" disabled value={relatedVessel?.contactInfo?.middleName} />
                    </Col>
                  </Row>
                </TextInputField>
                <TextInputField label={t(schema.role)} value={relatedVessel?.contactInfo?.role}>
                  <Row>
                    <Col xs="6">
                      <Form.Control type="text" disabled value={relatedVessel?.contactInfo?.role} />
                    </Col>
                    <Col xs="6">
                      <Button onClick={() => handleClickContactInfo(relatedVessel?.contactInfo)}>
                        See Contact Info
                      </Button>
                    </Col>
                  </Row>
                </TextInputField>
              </Col>
            </Row>
          </FieldGroup>
        </Col>
      </Row>
    </div>
  );
};
RelatedVessel.propTypes = {
  relatedVessel: FISHING_OPERATION_RELATED_VESSEL_PROPTYPES.isRequired,
  handleClickContactInfo: PropTypes.func.isRequired,
};

const RelatedVessels = ({ relatedVessels }) => {
  const multipleItems = !!(relatedVessels && relatedVessels?.length);
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  return (
    <Container fluid id="report-details-related-vessel">
      {multipleItems ? relatedVessels.map((relatedVessel, index) => (
        <>
          <h6 className="title">
            {`Related Vessel ${index + 1}`}
          </h6>
          <RelatedVessel
            relatedVessel={relatedVessel}
            handleClickContactInfo={setShowContactInfoModal}
          />
        </>
      )) : (
        <RelatedVessel
          relatedVessel={relatedVessels}
          handleClickContactInfo={setShowContactInfoModal}
        />
      )}
      <ContactInfoModal
        show={showContactInfoModal}
        setShow={setShowContactInfoModal}
        data={showContactInfoModal}
      />
    </Container>
  );
};

RelatedVessels.propTypes = {
  relatedVessels: FISHING_OPERATION_RELATED_VESSELS_PROPTYPES.isRequired,
};

export default RelatedVessels;
