import PropTypes from 'prop-types';
import React from 'react';

const getCountryISO2 = require('country-iso-3-to-2');

const Flags = ({
  country,
}) => (
  (country !== undefined && country !== null && typeof country === 'string') && (

    <div style={{ borderStyle: 'double' }}>
      <img
        style={{
          width: '100%', aspectRatio: '4 / 3', overflow: 'hidden',
        }}
        src={`${process.env.PUBLIC_URL}/assets/flags/${getCountryISO2(country)?.toLowerCase()}.svg`}
        alt={`${getCountryISO2(country)} flag`}
      />
    </div>

  )
);

Flags.defaultProps = {
  country: PropTypes.string,
};
Flags.propTypes = {
  country: PropTypes.string,
};
export default Flags;
