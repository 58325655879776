import tSchema from '@lang/schema';

const schema = tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups;

const typeIcon = (type) => (

  <div className="statusIcon">
    {type ? 'Dynamic' : 'Static'}
  </div>
);

export const getTableConfig = (t) => [
  {
    text: t(schema.name),
    code: 'name',
    sortable: true,
  },
  {
    text: t(schema.description),
    code: 'description',
    sortable: true,
  },
  {
    text: t(schema.type),
    code: 'dynamic',
    sortable: true,
    render: (row) => typeIcon(row.dynamic),
  },
];

export const getFiltersConfig = (t) => [
  {
    code: 'name',
    label: t(schema.name),
    type: 'text',
  },
  {
    code: 'description',
    label: t(schema.description),
    type: 'text',
  },
  {
    code: 'isActive',
    label: t(schema.status),
    type: 'select',
    options: [{ id: true, description: 'Active' }, { id: false, description: 'Inactive' }],
  },
  {
    code: 'isDynamic',
    label: t(schema.type),
    type: 'select',
    options: [{ id: true, description: 'Dynamic' }, { id: false, description: 'Static' }],
  },
];

export const businessRulesValueOptions = [
  'brdomain',
  'brlevel',
  'brsubgroup',
  'brsublevel',
  'brstatus',
  'brseverity',
  'field',
  'executionorder',
  'brcontext',
  'browner',
  'brtypeofentity',
];
