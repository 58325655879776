/* eslint-disable linebreak-style */
export const LOGBOOK_SECTIONS = {
  DEPARTURE: 'DEPARTURE',
  AREA_ENTRY: 'AREA_ENTRY',
  FISHING_OPERATION: 'FISHING_OPERATION',
  JOINT_FISHING_OPERATION: 'JOINT_FISHING_OPERATION',
  DISCARD: 'DISCARD',
  RELOCATION: 'RELOCATION',
  AREA_EXIT: 'AREA_EXIT',
  ARRIVAL: 'ARRIVAL',
  LANDING: 'LANDING',
  TRANSHIPMENT: 'TRANSHIPMENT',
  PRIOR_NOTIFICATION_OF_ARRIVAL: 'PRIOR_NOTIFICATION_OF_ARRIVAL',
  TRANSHIPMENT_OR_RELOCATION_NOTIFICATION: 'TRANSHIPMENT_OR_RELOCATION_NOTIFICATION',
};

export const REPORT_TYPES = {
  DECLARATION: 'DECLARATION',
  NOTIFICATION: 'NOTIFICATION',
};
