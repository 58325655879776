/* eslint-disable import/order */
import '../style.scss';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import {
  Button, Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '@common/dateUtils';
import { getApproverStatus } from '@common/utils';
import tSchema from '@lang/schema';
import {
  swapVisualGroup,
  updateVesselGroups,
} from '@services/vessel_record/actions/vessel';
import Flag from '../../../componentsRecord/Flags';

const VesselViewerGroupsModal = ({
  showModal,
  availableGroups,
  changeVisualizationGroups,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const selectedVessel = useSelector((state) => state.vessel.vessel || {});
  const historic = useSelector((state) => state.vessel.vesselHistoric);
  const currentGroups = useSelector((state) => state.vessel.currentGroups || []);

  const swapColumn = useCallback((object, destination) => {
    dispatch(swapVisualGroup(object, destination));
  }, [dispatch, swapVisualGroup]);

  return (
    <Modal
      backdrop="static"
      show={showModal}
      className="vessel-viewer-modal"
      size="xl"
      onHide={() => changeVisualizationGroups(false)}
    >
      <Modal.Header className="pb-0" closeButton>
        <Container
          fluid
          style={{
            paddingBottom: '0.625rem',
          }}
        >
          <Row
            style={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >

            <Col>

              <Flag country={selectedVessel?.countryOfRegistration} />

            </Col>
            <Col>
              <div className="header-columns">
                <Form.Label>Name</Form.Label>
                <p>{selectedVessel?.name || historic.vessel || ''}</p>
              </div>
              <div className="header-columns">
                <Form.Label>IRCS</Form.Label>
                <p>{selectedVessel?.ircs || ''}</p>
              </div>
            </Col>
            <Col>
              <div className="header-columns">
                <Form.Label>CFR</Form.Label>
                <p>{selectedVessel?.cfr || ''}</p>
              </div>
              <div
                className="header-columns"
              >
                <Form.Label>External Marking</Form.Label>
                <p>{selectedVessel?.externalMarking || ''}</p>
              </div>

            </Col>

            <Col>
              <div className="header-columns">
                <Form.Label>UVI</Form.Label>
                <p>{selectedVessel?.uvi || ''}</p>
              </div>
              <div className="header-columns">
                <Form.Label>Validity Date</Form.Label>
                <p>{formatDate(selectedVessel?.validityDate, undefined, '', true)}</p>
              </div>

            </Col>

            <Col xs={3}>
              <div className="header-columns">
                <Form.Label>Reported Event</Form.Label>
                <p>{selectedVessel?.reportedEvent || ''}</p>
              </div>
              <div className="header-columns">
                <Form.Label>{getApproverStatus(selectedVessel?.approverStatus)}</Form.Label>
              </div>

            </Col>

          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container
          fluid
          className="groups-viewer"
        >
          <Row className="modal-title">
            <h3>Vessel Groups</h3>
          </Row>
          <Row className="modal-content-groups">
            <Col className="modal-column" md={5}>
              <Row>
                <span className="header-div-label">Current Groups</span>
              </Row>
              <Row>
                <Col className="data-container">
                  {currentGroups.map((c) => (
                    <Row style={{ margin: 0 }}>
                      {c.vesselGroupRecordCriteriaEntityList.length === 0
                          && (
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span>{c.name}</span>
                            <button type="submit" className="label-group-button" onClick={() => swapColumn(c, 'AVAILABLE')}>-</button>
                          </div>
                          )}

                      {c.vesselGroupRecordCriteriaEntityList.length > 0
                          && (
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <span style={{ fontStyle: 'italic', color: '#a1a1a1' }}>{c.name}</span>
                            <button style={{ display: 'none' }} type="submit" className="label-group-button">-</button>
                          </div>
                          )}
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col className="modal-column" md={5}>
              <Row>
                <span className="header-div-label">Available Groups</span>
              </Row>
              <Row>
                <Col className="data-container">
                  {availableGroups.map((c) => (
                    <Row style={{ margin: 0 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span>{c.name}</span>
                        <button type="submit" className="label-group-button" onClick={() => swapColumn(c, 'CURRENT')}>+</button>
                      </div>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end" style={{ display: 'flex' }}>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '0.625rem',
                }}
                onClick={() => {
                  dispatch(updateVesselGroups(currentGroups, selectedVessel.etalonId));
                  changeVisualizationGroups(false);
                }}
              >
                {t(tSchema.common.save)}
              </Button>

              <Button
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  changeVisualizationGroups(false);
                }}
              >
                {t(tSchema.common.cancel)}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

VesselViewerGroupsModal.defaultProps = {
  showModal: PropTypes.instanceOf(Object),
  availableGroups: PropTypes.instanceOf(Array),
  changeVisualizationGroups: PropTypes.func,

};

VesselViewerGroupsModal.propTypes = {
  showModal: PropTypes.instanceOf(Object),
  availableGroups: PropTypes.instanceOf(Array),
  changeVisualizationGroups: PropTypes.func,
};

export default VesselViewerGroupsModal;
