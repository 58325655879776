import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

const ConfirmationModal = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
}) => {
  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Modal
      backdrop="static"
      show={isOpen}
      onHide={handleCancel}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          {message}
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
};

export default ConfirmationModal;
