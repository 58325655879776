import AccordionSection from '../../../AccordionSection';

import collapse from './img/collapse.png';
import expand from './img/expand.png';
import LOGBOOK_SUBSECTIONS from './sections';

export const LOGBOOK_FILTERS = (
  <div className="subSection">
    <p>
      Click on the expand filters icon to expand the filters layer.
    </p>
    <p>
      <img src={expand} alt="expandFilters" />
    </p>
    <p>
      Click on the collapse filters icon to collapse the filters layer.
    </p>
    <p>
      <img src={collapse} alt="collapse filters" />
    </p>
    <AccordionSection
      sections={[
        LOGBOOK_SUBSECTIONS.SEARCH,
        LOGBOOK_SUBSECTIONS.FAVOURITES,
      ]}
    />
  </div>
);

export default LOGBOOK_FILTERS;
