import PropTypes from 'prop-types';
import { Accordion, Button, Card } from 'react-bootstrap';

const AccordionSection = ({ sections = [], defaultActiveKey }) => (
  <Accordion defaultActiveKey={defaultActiveKey}>
    {sections.map(({ title, body }, index) => (
      <Card>
        <Card.Header>
          <Accordion.Toggle
            className="accordion-toggle"
            as={Button}
            variant="link"
            eventKey={`${index}`}
          >
            {title}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`${index}`}>
          <Card.Body>{body}</Card.Body>
        </Accordion.Collapse>
      </Card>
    ))}
  </Accordion>
);
AccordionSection.defaultProps = {
  defaultActiveKey: null,
};

AccordionSection.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.element.isRequired,
  })).isRequired,
  defaultActiveKey: PropTypes.string,
};

export default AccordionSection;
