import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

const CustomMenuList = (props) => {
  const itemHeight = 35;
  const {
    options, children, maxHeight, getValue,
  } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;

  return (
    <div>
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={itemHeight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    </div>
  );
};

CustomMenuList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.objectOf(PropTypes.any),
  maxHeight: PropTypes.number,
  getValue: PropTypes.func,
};

CustomMenuList.defaultProps = {
  options: [],
  children: null,
  maxHeight: null,
  getValue: null,
};

export default CustomMenuList;
