import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col } from 'react-bootstrap';

const SimpleTextField = React.forwardRef((
  { filter: { code, onChange: handleChange, defaultValue } },
  ref,
) => (
  <>
    <input hidden name={`${code}-comparation`} value="==" readOnly />
    <Col md={4} sm={6} xs={6} ref={ref}>
      <Form.Control type="text" name={`${code}-value`} defaultValue={defaultValue} onChange={({ target: { value } }) => handleChange({ [code]: value })} />
    </Col>
  </>
));
SimpleTextField.propTypes = {
  filter: PropTypes.objectOf({
    code: PropTypes.string,
  }).isRequired,
};

export default SimpleTextField;
