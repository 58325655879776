import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { LANDING_PROPTYPES, LANDING_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const Landing = ({ landing, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentLanding, setCurrentLanding] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(LANDING_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => setCurrentLanding(
    (landing && landing[pageNumber - 1])
    || LANDING_SCHEMA,
  ),
  [landing, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        versions={currentLanding}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={landing}
        onSelectPagination={setPageNumber}
      />
      <Row>
        <Col xl="auto" lg={4} xs={12}>
          <TextInputField
            label={t(schema.start)}
            value={currentVersion?.startDate && `${formatDateTime(currentVersion?.startDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col xl="auto" lg={4} xs={12}>
          <TextInputField label={t(schema.identification)} value={currentVersion?.identification} />
        </Col>
        <Col xl="auto" lg={4} xs={12}>
          <TextInputField
            label={t(schema.end)}
            value={currentVersion?.endDate && `${formatDateTime(currentVersion?.endDate.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col xl="auto" lg={4} xs={12}>
          <TextInputField label={t(schema.duration)} value={currentVersion?.duration} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

Landing.propTypes = {
  landing: LANDING_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default Landing;
