import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { fetchAllVessels, setPagination, clearVesselsAndPagination } from '@services/vessel_record/actions/vessel';

import Filters from '../../../../../../componentsRecord/Filters';

import tableCheckConfig from './config';

import { formatHeaderSort } from '@common/utils';

const VesselSearcher = ({
  checkAdded,
  selectedRows,
  onSelectedRows: handleSelectedRows,
}) => {
  const { t } = useTranslation();
  const pagination = useSelector((state) => state.vessel.pagination);
  const searchableAttributes = useSelector((state) => state.vessel.searchableAttributes);
  const template = useSelector((state) => state.vessel.template);
  const vessels = useSelector((state) => state.vessel.vessels);
  const isLoading = useSelector((state) => state.vessel.isLoading);
  const [orderBy, setOrderBy] = useState({});

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearVesselsAndPagination()), []);

  const dataTableRef = useRef();

  const getVessels = async () => {
    const filtersBody = {
      ...pagination.filtersBody,
      ...(orderBy ? {
        sortFields: [
          {
            field: orderBy.field,
            order: orderBy.order.toUpperCase(),
          },
        ],
      } : {}),
    };
    await dispatch(fetchAllVessels({
      page: pagination.page,
      count: pagination.size,
      filtersBody,
    }));
    dispatch(setPagination({
      page: pagination.page,
      size: pagination.size,
      filtersBody,
    }));
  };

  useEffect(() => {
    if (!isEmpty(orderBy)) {
      getVessels();
    }
  }, [orderBy]);

  const handleResetPagination = useCallback(() => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
  }, []);

  const handleChangePageSize = useCallback(async (size) => {
    dispatch(fetchAllVessels({
      page: 1,
      count: size,
      filtersBody: pagination.filtersBody,
    }));
    dispatch(setPagination({
      page: 1,
      size,
      filtersBody: pagination.filtersBody,
    }));
  }, [pagination]);

  const handlePageChange = useCallback(async (page) => {
    dispatch(fetchAllVessels({
      page,
      count: pagination.size,
      filtersBody: pagination.filtersBody,
    }));
    dispatch(setPagination({
      page,
      size: pagination.size,
      filtersBody: pagination.filtersBody,
    }));
  }, [pagination]);

  const handleTableHeaderClick = useCallback((header) => {
    setOrderBy((prevState) => (formatHeaderSort(header, prevState)));
  }, []);

  const onFilterUpdate = useCallback((filters) => {
    dispatch(setPagination({
      page: filters.page,
      size: filters.count,
      filtersBody: filters.filtersBody,
      isSearchOnDate: filters.isSearchOnDate,
    }));
    dispatch(fetchAllVessels(filters));
    handleResetPagination();
  }, []);

  return (
    <FieldGroup label={t(tSchema.dmaAdmin.vesselGroupMGMT.createEditView.addVessels)}>
      <Filters
        searchableAttributes={searchableAttributes}
        formDataList={template.formData}
        pagination={pagination}
        onUpdateSearchFilters={onFilterUpdate}
        hasDefaultFilters={false}
      />
      {!isEmpty(vessels) && (
        <DataTable
          tableId="groupVessels"
          ref={dataTableRef}
          tableConfig={tableCheckConfig({
            vessels,
            selectedRows,
            onSelectedRows: handleSelectedRows,
            checkAdded,
            t,
          })}
          orderBy={isEmpty(orderBy) ? null : orderBy}
          onTableHeaderClick={handleTableHeaderClick}
          rowData={vessels}
          onPageSizeChangeHandler={handleChangePageSize}
          onPageChangeHandler={handlePageChange}
          defaultPageSize={pagination.size}
          loading={isLoading}
          maxPages={pagination.maxPages}
          tableActions={false}
          totalResults={pagination.totalCount}
          error={false}
          errorMessageEmptyArray="No vessels found"
          errorMessageLoadingData="An error has occurred loading the vessels"
          footerContent={(
            <div className="footer-table-right">
              Vessels Selected:
              {' '}
              {selectedRows.length}
            </div>
          )}
        />
      )}
    </FieldGroup>
  );
};

VesselSearcher.propTypes = {
  checkAdded: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectedRows: PropTypes.func.isRequired,
};

export default VesselSearcher;
