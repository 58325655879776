import table from './img/tableReport.png';

const SectionReportAcceptance = () => (
  <div className="SectionReportAcceptance">
    <p>
      The results grid displays the following columns:
      <ul>
        <li>Sender</li>
        <li>Data Scope from</li>
        <li>Data Scope to</li>
        <li>Valid from</li>
        <li>Valid to</li>
        <li>Flag State</li>
        <li>Active</li>
      </ul>
    </p>
    <p>
      <img src={table} alt="Report Acceptance" />
    </p>
    <p>
      In addition, there is a column &quot;Actions&quot;.
      Clicking in the button displayed in this column for each row,
      a list of operations is made available for the user,
      so each Report Acceptance can be edited, visualized in detail, or deleted.
    </p>
  </div>
);

export default SectionReportAcceptance;
