import { equals, reject } from 'ramda';

import {
  actionCreator, post, postFile,
} from '@services/utils';

import { dispatchPromiseWithMessage } from '@common/utilities/Notification';

const createActionType = actionCreator('FORM');

const TypeActionsForm = {
  FETCH_ENTITY_VESSEL: 'FETCH_ENTITY_VESSEL',
  CHANGE_VESSEL_INFORMATION: 'CHANGE_VESSEL_INFORMATION',
  CHANGE_COMPLEX_VESSEL_INFORMATION: 'CHANGE_COMPLEX_VESSEL_INFORMATION',
  CHANGE_COMPLEX_LOCAL: 'CHANGE_COMPLEX_LOCAL',
  CHECK_MANDATORY_FIELDS: 'CHECK_MANDATORY_FIELDS',
  SET_IS_VALID_FIELD_FORM: 'SET_IS_VALID_FIELD_FORM',
  CLEAN_VESSEL_FORM: 'CLEAN_VESSEL_FORM',
  SET_VESSEL: 'SET_VESSEL',
  ADD_COMPLEX_ROW: 'ADD_COMPLEX_ROW',
  REMOVE_COMPLEX_ROW: 'REMOVE_COMPLEX_ROW',
  VALIDATE_BUSINESS_RULES: createActionType('VALIDATE_BUSINESS_RULES'),
  SET_SEND: 'SET_SEND',
  UPLOAD_IMAGE: createActionType('UPLOAD_IMAGE'),
  ASSIGN_IMAGE: 'ASSIGN_IMAGE',
  CLEAR_ERROR_MESSAGE_HANDLER: 'CLEAR_ERROR_MESSAGE_HANDLER',
};

/*
    FETCH ENTITY VESSEL
    Calls the endpoint that returns the vessel's template
*/
const fetchEntityVessel = () => ({
  type: TypeActionsForm.FETCH_ENTITY_VESSEL,
});
// dispatchPromise({
//   type: TypeActionsForm.FETCH_ENTITY_VESSEL,
// }));

/*
    CHANGE VESSEL INFORMATION
    Updates the vessel object with the information written on the form
*/
const changeVesselInformation = (name, value) => ({
  type: TypeActionsForm.CHANGE_VESSEL_INFORMATION,
  payload: {
    name,
    value,
  },

});

const changeComplexVesselInformation = (name, simpleName, index, value) => ({
  type: TypeActionsForm.CHANGE_COMPLEX_VESSEL_INFORMATION,
  payload: {
    name,
    simpleName,
    index,
    value,
  },
});

const changeComplexLocal = (name, simpleName, index, value) => ({
  type: TypeActionsForm.CHANGE_COMPLEX_LOCAL,
  payload: {
    name,
    simpleName,
    index,
    value,
  },
});

const addComplexRow = (name) => ({
  type: TypeActionsForm.ADD_COMPLEX_ROW,
  payload: {
    name,
  },

});

const removeComplexRow = (name, index) => ({
  type: TypeActionsForm.REMOVE_COMPLEX_ROW,
  payload: {
    name,
    index,
  },

});

/*
    CHECK MANDATORY FIELDS
    Checks before sending the vessel if all the required fields are filled
*/
const checkMandatoryFields = () => ({
  type: TypeActionsForm.CHECK_MANDATORY_FIELDS,
});

/*
    SET IS VALID FIELD
    Set isValid value for a field
*/

const setIsValidVesselField = (field, value) => ({
  type: TypeActionsForm.SET_IS_VALID_FIELD_FORM,
  payload: {
    field,
    value,
  },
});

const cleanVesselForm = () => ({
  type: TypeActionsForm.CLEAN_VESSEL_FORM,
});

const setVessel = (vessel) => ({
  type: TypeActionsForm.SET_VESSEL,
  payload: {
    vessel,
  },
});

const validateDrools = (vessel) => (
  dispatchPromiseWithMessage({
    type: TypeActionsForm.VALIDATE_BUSINESS_RULES,
    promise: post()('vrValidation/validate', reject(equals(''))(vessel)),
    errorMessage: 'An error has occurred and data could not be validated',
  })
);

const setSend = (send, type) => ({
  type: TypeActionsForm.SET_SEND,
  payload: {
    send,
    type,
  },
});

const uploadImage = (image) => {
  const formData = new FormData();
  formData.append(
    'file',
    image,
  );
  return dispatchPromiseWithMessage({
    type: TypeActionsForm.UPLOAD_IMAGE,
    promise: postFile()('vrService/image/upload', formData),
    successMessage: 'Image uploaded',
    errorMessage: 'Image could not be uploaded',
  });
};

const assignImage = (index) => ({
  type: TypeActionsForm.ASSIGN_IMAGE,
  payload: {
    index,
  },
});

export {
  TypeActionsForm,
  fetchEntityVessel,
  changeVesselInformation,
  changeComplexVesselInformation,
  changeComplexLocal,
  checkMandatoryFields,
  setIsValidVesselField,
  cleanVesselForm,
  setVessel,
  addComplexRow,
  removeComplexRow,
  validateDrools,
  setSend,
  uploadImage,
  assignImage,
};
