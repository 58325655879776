import PropTypes from 'prop-types';
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import formatChecked from '../utils';

import {
  getInfringTableConfig,
  getCategoriesTableConfig,
} from './utils';

import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerDetailsTabInfringements = ({
  selectedUuid, selectedRecipient, selectedVersion, isHiddenISRTab,
}) => {
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedInfr, setSelectedInfr] = useState(null);

  const { t } = useTranslation();

  const infringTableConfig = useMemo(() => getInfringTableConfig(t), [t]);
  const categoriesTableConfig = useMemo(() => getCategoriesTableConfig(t), [t]);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['infringementsTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.infringementsTab);
        const versionData = getVersionData(data.infringementsTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid,
    selectedRecipient,
    isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  const handleInfrSelected = useCallback(
    (row, event) => {
      if (event.detail === 1) {
        setSelectedInfr(row.id);
        setSelectedCategories(row.infringementCategories ? row.infringementCategories : []);
      }
    }, // Simple Click
    [],
  );

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <Container
          fluid
          style={{
            paddingBottom: '0.625rem',
          }}
        >
          <Row
            style={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Col>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.suspInfringObserved)}
                  :
                </Form.Label>
                <p>
                  {formatChecked(tabData.suspectedInfringementsObserved, t)}
                </p>
              </div>
              <div className="header-columns">
                <Form.Label>
                  {t(tSchema.isrViewer.detInfringObserved)}
                  :
                </Form.Label>
                <p>{tabData.suspectedDetails}</p>
              </div>
            </Col>
          </Row>
          <FieldGroup label={t(tSchema.isrViewer.suspInfringements)}>
            <Container fluid className="isrInfringements">
              <DataTable
                rowData={tabData.suspectedInfringements ? tabData.suspectedInfringements : []}
                idColumn="id"
                tableId="isrInfringementsTable"
                tableConfig={infringTableConfig}
                rowSelected={selectedInfr}
                onRowSelected={handleInfrSelected}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
          { selectedCategories && selectedInfr
          && (
          <FieldGroup label={t(tSchema.isrViewer.suspInfringCategories)}>
            <Container fluid className="isrCategories">
              <DataTable
                rowData={selectedCategories || []}
                idColumn="validityDate"
                tableId="isrCategoriesTable"
                tableConfig={categoriesTableConfig}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
          )}
        </Container>
      )}
    </>
  );
};

IsrViewerDetailsTabInfringements.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabInfringements;
