import axios from 'axios';
import {
  compose,
  find,
  identity,
  ifElse,
  is,
  isEmpty,
  isNil,
  join,
  keys,
  map,
  prepend,
  prop,
  reject,
} from 'ramda';

import { addFormatDate, BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';

export const actionCreator = (domain) => (type) => ({
  REQUEST: `${domain}/${type}`,
  PENDING: `${domain}/${type}_PENDING`,
  FULFILLED: `${domain}/${type}_FULFILLED`,
  REJECTED: `${domain}/${type}_REJECTED`,
});

export const dispatchPromise = ({ type, promise }) => (dispatch) => {
  dispatch({
    type: type.PENDING,
  });
  return {
    type: type.REQUEST,
    header: null,
    payload: promise
      .then(async (res) => {
        if (res.ok) {
          const contentType = res.headers['content-type'];
          if (contentType && contentType.indexOf('application/json') !== -1) {
            const json = await res.json();
            dispatch({
              type: type.FULFILLED,
              payload: json,
            });
          } else {
            dispatch({
              type: type.FULFILLED,
            });
          }
        } else {
          throw new Error('');
        }
      })
      .catch(() => dispatch({
        type: type.REJECTED,
      })),

  };
};

export const dispatchPromiseForFile = ({ type, promise }) => (dispatch) => {
  dispatch({
    type: type.PENDING,
  });
  return {
    type: type.REQUEST,
    header: null,
    payload: promise
      .then(async (res) => {
        if (res.ok) {
          dispatch({
            type: type.FULFILLED,
            payload: {},
          });
        } else {
          throw new Error('');
        }
      })
      .catch(() => dispatch({
        type: type.REJECTED,
      })),

  };
};
export const updateTokenPromise = (keycloak) => new Promise((fulfill, pReject) => {
  keycloak && keycloak
    .updateToken(30)
    .then(() => {
      fulfill();
    })
    .catch(() => {
      pReject(new Error('Failed to refresh token'));
    });
});

export const get = (absolute) => (endpoint, params, config = {}) => {
  const buildResource = compose(
    ifElse(
      isEmpty,
      () => endpoint,
      compose(
        join(''),
        prepend(endpoint),
        ifElse(isEmpty, identity, prepend('?')),
        join('&'),
        map((x) => {
          const value = prop(x, params);
          return is(Array, value) ? value.map((v) => `${x}[]=${v}`) : `${x}=${value}`;
        }),
        reject((x) => isNil(prop(x)(params))),
      ),
    ),
    keys,
  );
  const host = window.env.REACT_APP_API_URL;
  return axios.get(`${absolute ? '' : `${host}/`}${buildResource(params)}`, config);
};

export const post = () => (endpoint, request) => axios.post(`${window.env.REACT_APP_API_URL}/${endpoint}`, request);

export const postFile = () => (endpoint, request) => axios.post(`${window.env.REACT_APP_API_URL}/${endpoint}`, request);

export const put = () => (endpoint, request) => axios.put(`${window.env.REACT_APP_API_URL}/${endpoint}`, request);

export const del = () => (endpoint) => axios.delete(`${window.env.REACT_APP_API_URL}/${endpoint}`);

export const getFile = () => (endpoint, filters) => axios({
  url: `${window.env.REACT_APP_API_URL}/${endpoint}`,
  method: 'POST',
  data: filters,
  responseType: 'blob', // important
});

export const downloadFile = () => (endpoint, filters) => axios({
  url: `${window.env.REACT_APP_API_URL}/${endpoint}`,
  method: 'GET',
  data: filters,
  responseType: 'blob',
});

// This array should be updated if the comparators change
const comparators = [
  { old: '==', new: 'eq.' },
  { old: '!=', new: 'neq.' },
  { old: '=-', new: 'has.' },
  { old: '!-', new: 'nhas.' },
  { old: '*=', new: 'strt.' },
  { old: '=*', new: 'ends.' },
  { old: '=null', new: 'eq.null' },
  { old: '<', new: 'lt.' },
  { old: '>', new: 'gt.' },
];
/**
 * This function recives the old filters ({code}{comparation}{value}) as a string used in the URI
 * and returns the new ones that will be sent in the query, as an object.
 *
 * **WARNING**: This method should be temporal, and once the back is fully changed to accept
 * the query filters, the Filters common component should be updated and used instead.
 * When the component is updated, the Splunk service in the back should be updated as well
 * to recieve the new filter format.
 * @param {String} [old=""] The old filters as a string
 * @returns {Object} The new object with the query filters
 */
export function oldFiltersToQuery(old) {
  const filters = old?.split(',');
  const query = {};
  filters && filters.forEach((filter) => {
    const comparator = find((c) => filter.indexOf(c.old) !== -1, comparators);
    if (!comparator) {
      console.warn(
        `A filter comparator was not recognized in the filter "${filter}", thus ignored`,
      );
      return;
    }
    const posComp = filter.indexOf(comparator.old);
    const code = filter.slice(0, posComp);
    const value = filter.slice(posComp + comparator.old.length);
    if (query[code]) {
      query[code] = [query[code], `${comparator.new}${value}`];
    } else {
      query[code] = `${comparator.new}${value}`;
    }
  });
  return query;
}

/**
 * This function recives the query params object ({sort, direction, rest})
 * and returns query params with sort formatted to {sort: 'sort,direction'}, as an object.
 *
 * @param {Object} [params={sort,direction,rest}] The query params
 * @returns {Object} The new object with the query params with sort formatted to
 * {sort: 'sort,direction'}
 */
export const formatSortParams = (params) => {
  const { sort: sortValue, direction: directionValue, ...restParams } = params;
  const sort = [sortValue, directionValue].filter(Boolean).join(',');
  return {
    ...restParams,
    ...(sort && { sort }),
  };
};

const filterComp = {
  EQ: 'eq.',
  GT: 'gt.',
  LT: 'lt.',
  IN: 'in.',
  AND: 'and.',
  OR: 'or.',
  NEQ: 'neq.',
  HAS: 'has.',
  NHAS: 'nhas.',
  STRT: 'strt.',
  ENDS: 'ends.',
  EQ_NULL: 'eq.null',
};

const getComp = (comparation = filterComp.EQ) => (code, value) => `${code}=${comparation}${Array.isArray(value)
  // map to get value (for select types)
  ? value.map((v) => (v && v.value) || v).join('+')
  : value}`;
const getCompEq = getComp(filterComp.EQ);
const getCompGt = getComp(filterComp.GT);
const getCompLt = getComp(filterComp.LT);

const formatTimeFrameFilter = (code, { from, to }) => [
  from && getCompGt(code, formatDate(from, BACK_DATE_FORMAT)),
  to && getCompLt(code, addFormatDate(to, BACK_DATE_FORMAT)),
].filter(Boolean).join('&');

const formatQuantityRangeFilter = (code, { from, to }) => ((from === to)
  ? getCompEq(code, to)
  : [
    (from) && getCompGt(code, from),
    (to) && getCompLt(code, to),
  ].filter(Boolean).join('&'));

export const formatFilter = {
  eq: getCompEq,
  timeFrame: formatTimeFrameFilter,
  quantityRange: formatQuantityRangeFilter,
  in: getComp(filterComp.IN),
  and: getComp(filterComp.AND),
  or: getComp(filterComp.OR),
};

const fullfilled = (type) => `${type}_FULFILLED`;
const rejected = (type) => `${type}_REJECTED`;
const pending = (type) => `${type}_PENDING`;

export { fullfilled, rejected, pending };
