import {
  any,
  identity,
  propEq,
  prop,

} from 'ramda';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import InputDate, { DATE_TYPE } from '@components/Form/Inputs/InputDate';
import InputDateRange from '@components/Form/Inputs/InputDateRange';

import InputAddField from './Components/InputAddField';
import MultichoiceField from './Components/MultichoiceField';
import SimpleTextField from './Components/SimpleTextField';

const optionMap = (filter) => (o) => (
  <option value={o.value} key={`${filter.code}${o.value}`}>
    {o.label}
  </option>
);

const renderText = ({ filter, searchOptions }) => {
  const valueRef = React.createRef();
  const hasComparationSelector = any(propEq('type', filter.type), searchOptions);
  const colWidth = hasComparationSelector ? 6 : 12;
  return (
    <>
      {hasComparationSelector ? (
        <Col xs={colWidth}>
          <Form.Control
            as="select"
            name={`${filter.code}-comparation`}
            onChange={(x) => {
              valueRef.current.hidden = x.target.value === '=null';
            }}
          >
            {searchOptions
              .filter((o) => (o.type ? o.type === filter.type : true))
              .map(optionMap(filter))}
          </Form.Control>
        </Col>
      ) : (
        <input hidden name={`${filter.code}-comparation`} value="-=" readOnly />
      )}
      <Col xs={colWidth} ref={valueRef}>
        <Form.Control type="text" value={filter.defaultValue} name={`${filter.code}-value`} onChange={({ target: { value } }) => filter.onChange({ [filter.code]: value })} />
      </Col>
    </>
  );
};

export const filterTypes = {
  text: {
    render: (filter, searchOptions) => renderText({ filter, searchOptions }),
    value: identity,
  },
  'text-contains-first': {
    render: (filter, searchOptions) => renderText({ filter, searchOptions }),
    value: identity,
  },
  'simple-text': {
    render: (filter) => {
      const valueRef = React.createRef();
      return (<SimpleTextField filter={filter} ref={valueRef} />);
    },
    value: identity,
  },
  select: {
    render: (filter) => {
      const {
        idProp = 'id', textProp = 'description', group = 'group', fieldToShow,
      } = filter;
      const hasFieldToShow = any(prop(fieldToShow))(filter.options || []);
      const selectDescription = hasFieldToShow ? fieldToShow : textProp;
      const currentGroups = [];
      return (
        <>
          <Col sm={12}>
            <Form.Control
              as="select"
              name={`${filter.code}-value`}
              defaultValue={filter.defaultValue}
              readOnly={filter.fixed}
              onChange={({ target: { value } }) => filter.onChange({ [filter.code]: value })}
            >
              {(filter.options || []).map((o) => {
                if (o[group] && (currentGroups.length === 0 || !currentGroups.includes(o[group]))) {
                  currentGroups.push(o[group]);
                  return (
                    <optgroup key={o[group]} label={o[group]} style={{ color: 'white', background: 'grey' }}>
                      {(filter.options || []).map((op) => {
                        if (op[group] && op[group] === o[group]) {
                          return (
                            <option
                              value={op[idProp]}
                              key={op[idProp]}
                              disabled={filter.fixed && op[idProp] !== filter.defaultValue}
                              style={{ color: 'black', background: 'white' }}
                            >
                              {op[selectDescription]}
                            </option>
                          );
                        }
                        return undefined;
                      })}
                    </optgroup>
                  );
                }
                if (!o[group]) {
                  return (
                    <option
                      value={o[idProp]}
                      key={o[idProp]}
                      disabled={filter.fixed && o[idProp] !== filter.defaultValue}
                      style={{ color: 'black', background: 'white' }}
                    >
                      {o[selectDescription]}
                    </option>
                  );
                }
                return undefined;
              })}
            </Form.Control>
            <input hidden name={`${filter.code}-comparation`} value="==" readOnly />
          </Col>
        </>
      );
    },
    value: identity,
  },
  'complex-select': {
    render: (filter, searchOptions) => {
      const valueRef = React.createRef();
      const { idProp = 'id', textProp = 'description' } = filter;
      return (
        <>
          <Col xs={6}>
            <Form.Control
              as="select"
              name={`${filter.code}-comparation`}
              onChange={(x) => {
                valueRef.current.hidden = x.target.value === '=null';
              }}
            >
              {searchOptions
                .filter((o) => (o.type ? o.type === filter.type : true))
                .map(optionMap(filter))}
            </Form.Control>
          </Col>
          <Col xs={6}>
            <Form.Control
              as="select"
              name={`${filter.code}-value`}
              defaultValue={filter.defaultValue}
              readOnly={filter.fixed}
              ref={valueRef}
              onChange={({ target: { value } }) => filter.onChange({ [filter.code]: value })}
            >
              {(filter.options || []).map((o) => (
                <option
                  value={o[idProp]}
                  key={o[idProp]}
                  disabled={filter.fixed && o[idProp] !== filter.defaultValue}
                >
                  {o[textProp]}
                </option>
              ))}
            </Form.Control>
          </Col>
        </>
      );
    },
    value: identity,
  },
  date: {
    render: (filter, searchOptions) => {
      const refComparation = React.createRef();
      const refRange = React.createRef();
      return (
        <>
          <Col xs={4}>
            <Form.Control
              as="select"
              name={`${filter.code}-comparation`}
              onChange={(x) => {
                refRange.current.hidden = x.target.value !== '*';
                refComparation.current.hidden = x.target.value === '*';
              }}
            >
              {searchOptions
                .filter((o) => (o.type ? o.type === filter.type : true))
                .map(optionMap(filter))}
            </Form.Control>
          </Col>
          <Col xs={8}>
            <div ref={refComparation}>
              <Form.Control
                value={filter.defaultValue}
                name={`${filter.code}-value`}
                hidden
              />
              <InputDate
                className="input-date"
                name={`${filter.code}-tempvalue`}
                id={filter.code}
                type={filter.dateType || DATE_TYPE.DATE}
                value={filter.defaultValue}
                onChange={(filterCode, date) => {
                  filter.onChange({ [filterCode]: date });
                }}
                required={false}
                complex={false}
                hasAction={false}
                maxDate={filter.maxDate}
                minDate={filter.minDate}
              />
            </div>
            <Row ref={refRange} hidden>
              <InputDateRange
                id={filter.code}
                onChangeFrom={(filterCode, date) => {
                  filter.onChange({
                    [filterCode]: { ...filter.rangeValue, from: date },
                  });
                }}
                onChangeTo={(filterCode, date) => {
                  filter.onChange({
                    [filterCode]: { ...filter.rangeValue, to: date },
                  });
                }}
                fromValue={filter.rangeValue?.from}
                toValue={filter.rangeValue?.to}
                hiddenLabels
              />
            </Row>
          </Col>
        </>
      );
    },
    value(filter) {
      return {
        ...filter,
        value: (filter.comparation === '*') ? `${filter.from}+${filter.to || 'indefinite'}` : filter.value,
      };
    },
  },
  simpledate: {
    render: (filter) => (
      <Col xs={12}>
        <Form.Control
          value={filter.defaultValue}
          name={`${filter.code}-value`}
          hidden
        />
        <InputDate
          name={`${filter.code}-tempvalue`}
          id={filter.code}
          type={filter.dateType || DATE_TYPE.DATE}
          value={filter.defaultValue}
          onChange={(filterCode, date) => filter.onChange({ [filterCode]: date })}
          required={false}
          complex={false}
          hasAction={false}
          maxDate={filter.maxDate}
          minDate={filter.minDate}
        />
      </Col>
    ),
    value(filter) {
      return {
        ...filter,
        comparation: '==',
      };
    },
  },
  multichoice: {
    render: (filter) => (<MultichoiceField filter={filter} />),
    value(filter) {
      return {
        ...filter,
        comparation: '=',
      };
    },
  },
  datetime: {
    render: (filter, searchOptions) => (
      <>
        <Col xs={6}>
          <Form.Control as="select" name={`${filter.code}-comparation`}>
            {searchOptions
              .filter((o) => (o.type ? o.type === filter.type : true))
              .map(optionMap(filter))}
          </Form.Control>
        </Col>
        <Col xs={6}>
          <Form.Control
            value={filter.defaultValue}
            name={`${filter.code}-value`}
            hidden
          />
          <InputDate
            name={`${filter.code}-tempvalue`}
            id={filter.code}
            type={DATE_TYPE.DATE_TIME}
            value={filter.defaultValue}
            onChange={(filterCode, date) => filter.onChange({ [filterCode]: date })}
            required={false}
            complex={false}
            hasAction={false}
            maxDate={filter.maxDate}
            minDate={filter.minDate}
          />
        </Col>
      </>
    ),
    value: identity,
  },
  'date-range': {
    render: (filter) => (
      <InputDateRange
        id={filter.code}
        onChangeFrom={(filterCode, date) => filter.onChange({
          [filterCode]: { ...filter.rangeValue, from: date },
        })}
        onChangeTo={(filterCode, date) => filter.onChange({
          [filterCode]: { ...filter.rangeValue, to: date },
        })}
        fromValue={filter.rangeValue?.from}
        toValue={filter.rangeValue?.to}
        maxDate={filter.maxDate}
      />
    ),
    value(filter) {
      return {
        ...filter,
        comparation: '=',
        value: `${filter.from || 'indefinite'}/${filter.to || 'indefinite'}`,
      };
    },
  },
  'input-add': {
    render: (filter) => (
      <InputAddField
        filter={filter}
      />
    ),
    value: identity,
  },

};

export default { filterTypes };
