import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise-middleware';
import ReduxThunk from 'redux-thunk';

import { reactions, reducers } from './services';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(promise, ReduxThunk, ...reactions)),
);

export default store;
