import { formatSortParams, get } from '../utils';

/**
 *
 * @param {string} baseUrl  must be 'trafficMonitoring' or 'message'
 * @returns this return a object with methods to get messages and options.
 */
const messageApi = (baseUrl) => {
  if (!baseUrl) {
    throw new Error('baseUrl is required');
  }
  /**
 * @param {String} types
 * @param {String} filtersWithDomain
 * @param {String} params
 * @returns Promise with messages
 */
  const apiFetchMessages = (types, filtersWithDomain, params) => get()(`${baseUrl}/${types}/${filtersWithDomain}`, formatSortParams(params));

  /**
 * @param {String} type
 * @param {number} messageId
 * @returns Promise with message
 */
  const apiFetchMessageById = (type, messageId, config) => get()(`${baseUrl}/${type}/id/${messageId}`, null, config);
  /**
   *
   * @param {String} type
   * @param {Number} messageId
   * @returns Promise with validation result of message
   */
  const apiFetchMessageValidationDetails = (type, messageId) => get()(`${baseUrl}/${type}/validationDetail/${messageId}`);

  /**
     * @param {String} type must be 'incoming' or 'outgoing'
     * @param {Number} messageId
     * @param {Number} domainId
     * @returns Promise with response result of message
     */
  const apiFetchMessageResponseDetails = (type, messageId, domainId) => get()(`${baseUrl}/${type}/responseDetails/${messageId}/domain/${domainId}`);

  /**
     * @param {String} type must be 'incoming' or 'outgoing'
     * @returns Promise with options
     */
  const apiFetchMessageStatusOptions = (type) => get()(`${baseUrl}/options/${type}/status`);

  /**
     * @param {String} type must be 'incoming' or 'outgoing'
     * @returns Promise with options
     */
  const apiFetchMessageExchangeStatusOptions = (type) => get()(`${baseUrl}/options/${type}/exchangeStatus`);

  /**
     * @param {String} type must be 'incoming' or 'outgoing'
     * @returns Promise with options
     */
  const apiFetchMessageReportStatusOptions = (type) => get()(`${baseUrl}/options/${type}/reportStatus`);
  /**
     * @param {String} type must be 'incoming' or 'outgoing'
     * @returns Promise with options
     */
  const apiFetchMessageResponseStatusOptions = (type) => get()(`${baseUrl}/options/${type}/responseStatus`);

  /**
     * @param {String} type must be 'incoming' or 'outgoing'
     * @returns Promise with options
     */
  const apiFetchMessageFilterOptions = (type) => get()(`${baseUrl}/${type}/get`);

  /**
 *
 * @param {Number} domain Domain id
 */
  const apiFetchMessageTypeOption = (domain) => get()(`${baseUrl}/type/get/${domain}`);

  /**
   * @returns Promise with parties / flags
   */
  const apiFetchMessagePartiesFilterOptions = (domain) => get()(`trafficMonitoring/options/parties/${domain}`);

  /**
  * @param {String} option
  * @returns Promise with logbook options
  */
  const apiFetchLogBookOptions = (option) => get()(`logbook/options/${option}`);

  /**
  * @param {Number} id
  * @param {String} type incoming/outcoming
  * @returns Promise with xml content
  */
  const apiDownloadXML = (id, type) => get()(`trafficMonitoring/${type}/download/${id}`);

  return {
    apiFetchMessages,
    apiFetchMessageById,
    apiFetchMessageValidationDetails,
    apiFetchMessageStatusOptions,
    apiFetchMessageExchangeStatusOptions,
    apiFetchMessageReportStatusOptions,
    apiFetchMessageResponseStatusOptions,
    apiFetchMessageFilterOptions,
    apiFetchMessageTypeOption,
    apiFetchMessagePartiesFilterOptions,
    apiFetchLogBookOptions,
    apiDownloadXML,
    apiFetchMessageResponseDetails,
  };
};

export default messageApi;
