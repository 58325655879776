export const getCurrentVesselsPagination = (vessels, numPage, sizePage) => vessels.slice(
  (numPage - 1) * sizePage, numPage * sizePage,
);

export const getTotalPages = (vessels, sizePage) => parseInt(vessels.length / sizePage, 10) + 1;

export const sortDescending = (array, code) => array.sort((x, y) => {
  if (x[code] > y[code]) {
    return -1;
  }
  if (y[code] > x[code]) {
    return 1;
  }
  return 0;
});
export const sortAscending = (array, code) => array.sort((x, y) => {
  if (x[code] < y[code]) {
    return -1;
  }
  if (y[code] < x[code]) {
    return 1;
  }
  return 0;
});
