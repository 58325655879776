import * as R from 'ramda';

import { M1, M3 } from '@pages/IsrViewer/commons/constants';

import tSchema from '@lang/schema';

import formatChecked from '../utils';

const schema = tSchema.isrViewer;

const tableConfig = (t) => [
  {
    label: t(schema.documentsTable.documents),
    key: 'DOCUMENTS_CHECKED',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.certifRegistryCheck),
    key: 'CERTIF_REGISTRY_CHECK',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.enginePropPowerCheck),
    key: 'ENGINE_PROP_POWER_CHECK',
    modules: [M1],
  },
  {
    label: t(schema.documentsTable.fishingLicenceChecked),
    key: 'FISHING_LICENCE_CHECKED',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.fishingAuthChecked),
    key: 'FISHING_AUTHORISATION_CHECKED',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.portLandAuthCheck),
    key: 'PORT_LANDING_AUTH_CHECK',
    modules: [M3],
  },
  {
    label: t(schema.documentsTable.paperFishingLogbookChecked),
    key: 'PAPER_FISHING_LOGBOOK_CHECKED',
    modules: [M3, M1],
    addInfo: [
      { key: 'NB_OF_PAPER_FISHING_LOG_SHEET', label: 'nSheets' },
      { key: 'FIRST_LOG_SHEET_NUMBER', label: 'firstSheet' },
      { key: 'LAST_LOG_SHEET_NUMBER', label: 'lastSheet' }],
  },
  {
    label: t(schema.documentsTable.eFishingLogbookCheck),
    key: 'E_FISHING_LOGBOOK_CHECK',
    modules: [M3, M1],
    addInfo: [
      { key: 'ELECTRONIC_LOGBOOK_REFERENCE', label: 'eLogbookReference' },
    ],
  },
  {
    label: t(schema.documentsTable.priorNotificationRefCheck),
    key: 'PRIOR_NOTIFICATION_REF_CHECK',
    modules: [M3, M1],
    addInfo: [
      { key: 'PURPOSE_OF_PRIOR_NOTIFICATION', label: 'purposePriorNoti' },
    ],
  },
  {
    label: t(schema.documentsTable.stowagePlanChecked),
    key: 'STOWAGE_PLAN_CHECKED',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.fishRoomCertificate),
    key: 'FISH_ROOM_CERTIFICATE_CHECKED',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.ullageTablesTankChecked),
    key: 'ULLAGE_TABLES_TANKS_CHECKED',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.certifWeighSystemsCheck),
    key: 'CERTIF_WEIGH_SYSTEMS_CHECK',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.memberProducerOrganisation),
    key: 'MEMBER_PRODUCER_ORGANISATION',
    modules: [M3, M1],
  },
  {
    label: t(schema.documentsTable.otherCatchDocumentation),
    key: 'OTHER_CATCH_DOCUMENTATION',
    modules: [M3],
  },
];

const formatAdditionalInformation = (doc, data) => {
  const extraInfo = {};
  doc.addInfo.forEach((info) => {
    const docFind = data.find((docItem) => info.key === docItem.type);
    if (docFind) { extraInfo[info.label] = docFind.details; }
  });
  return extraInfo;
};

export const formatData = (data, module, t) => {
  if (data) {
    const dataformatted = tableConfig(t).map((row) => {
      const doc = data.find((docItem) => docItem.type === row.key);
      if (doc) {
        if (
          (module === M1 && row.modules.includes(M1))
        || (module === M3 && row.modules.includes(M3))) {
          const commonAttributes = {
            type: row.label,
            checked: doc?.checked,
            result: doc?.result,
            details: doc?.details,
            additionalInformation: doc?.additionalInformation,
          };
          if (row.addInfo) {
            const extraInfo = formatAdditionalInformation(row, data);
            return {
              ...commonAttributes,
              additionalInformation: R.isEmpty(extraInfo) ? doc?.additionalInformation : extraInfo,
            };
          }
          return commonAttributes;
        }
      } return {
        type: row.label,
      };
    });
    return dataformatted;
  } return [];
};

const printExtraInfo = (row, t) => (
  <div>
    {Object.keys(row.additionalInformation).map((info) => (
      <div>
        <p>
          {t(schema.documentsTable[info])}
          :
          {' '}
          {row.additionalInformation[info]}
        </p>
      </div>
    ))}
  </div>
);
function getTableConfig(t) {
  return [
    { text: t(schema.type), code: 'type' },
    {
      text: t(schema.checked),
      code: 'checked',
      render: (row) => formatChecked(row.checked, t),
    },
    {
      text: t(schema.result),
      code: 'result',
      render: (row) => formatChecked(row.result, t),
    },
    { text: t(schema.details), code: 'details' },
    {
      text: t(schema.additionalInfo),
      code: 'additionalInformation',
      render: (row) => (row.additionalInformation && typeof row.additionalInformation === 'object'
        ? printExtraInfo(row, t) : row.additionalInformation),
    },
  ];
}

export default getTableConfig;
