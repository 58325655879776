/* eslint-disable import/order */

import i18next from 'i18next';
import { any, propEq } from 'ramda';
import { toast } from 'react-toastify';
import SessionNotification from '@components/Notification/SessionNotification';
import tSchema from '@lang/schema';

import { dispatchPromise, dispatchPromiseForFile } from '../../services/utils';
import axios from 'axios';

const { t } = i18next;

export const SESSION_NOTIFICATION_TOAST_ID = 'session-notification';

const options = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ECONNABORTED_CODE = 'ECONNABORTED';

const toastMessageWithHeader = (header, message) => (
  <>
    <div>{header}</div>
    <div>{message}</div>
  </>
);

const showWarningNotification = (message = t(
  tSchema.notifications.common.error,
), messageOptions = options) => {
  toast.warn(toastMessageWithHeader('Warning', message), messageOptions);
};
const showErrorNotification = (message = t(
  tSchema.notifications.common.error,
), messageOptions = options) => {
  toast.error(toastMessageWithHeader('Error', message), messageOptions);
};

const showOkNotification = (message, messageOptions = options) => {
  toast.success(toastMessageWithHeader('Success', message), messageOptions);
};

const showErrorSessionNotification = () => {
  toast(<SessionNotification />, {
    ...options,
    autoClose: false,
    toastId: SESSION_NOTIFICATION_TOAST_ID,
  });
};

const dispatchPromiseWithMessage = ({
  type,
  promise,
  successMessage = null,
  errorMessage = null,
  showBackErrorMessage,
  callback = null,
}) => {
  const apiCall = promise;
  apiCall
    .then((res) => {
      if (successMessage !== null && res.status === 200) {
        showOkNotification(successMessage);
      }
      if (callback && res?.data) {
        callback(res?.data);
      } else if (callback) {
        callback();
      }
      // Custom message from backend
      const customResponseData = res.response?.data;
      const responseErrorMessage = (showBackErrorMessage && customResponseData?.message
        && t(customResponseData.message))
        || errorMessage;
      if (res.status !== 200 && responseErrorMessage !== null) {
        if (res.isAxiosError && res.code === ECONNABORTED_CODE) {
          showWarningNotification(t(
            tSchema.notifications.common.timeOut,
            {
              value: axios.defaults.timeout / 1000,
            },
          ));
        } else showErrorNotification(responseErrorMessage);
      }
    })
    .catch((error) => {
      console.warn(error);
      if (errorMessage !== null) {
        toast.error(errorMessage);
      }
    });
  return (
    dispatchPromise({
      type,
      promise: apiCall,
    })
  );
};

export const getFileWithMessage = ({
  promise,
  errorMessage = null,
  statusWarningMessage = {},
  fileName = 'fileName',
  callback = null,
}) => promise
  .then((response) => {
    if (response.isAxiosError && response.code === ECONNABORTED_CODE) {
      showWarningNotification(t(
        tSchema.notifications.common.timeOut,
        {
          value: axios.defaults.timeout / 1000,
        },
      ));
    } else if (response.status !== 200 && errorMessage !== null) {
      // Custom message from backend
      const customResponseData = response.response?.data;
      const responseErrorMessage = customResponseData?.message
        || statusWarningMessage[response.status]
         || errorMessage;
      showWarningNotification(responseErrorMessage);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    callback && callback(response);
  })
  .catch((error) => {
    console.warn(error);
    if (errorMessage !== null) {
      showErrorNotification(errorMessage);
    }
  });

const dispatchPromiseWithFile = ({
  type,
  promise,
  errorMessage = null,
  statusWarningMessage = {},
  fileName,
  callback = null,
}) => {
  const apiCall = getFileWithMessage({
    promise, errorMessage, statusWarningMessage, fileName, callback,
  });
  return (
    dispatchPromiseForFile({
      type,
      promise: apiCall,
    })
  );
};
const requestWithMessage = async ({
  request,
  successMessage,
  errorMessage,
  showCustomResponseErrorMessage = true,
  onSuccess,
  onError,
  onResponseErrorMessage,
  setLoading,
}) => {
  setLoading && setLoading(true);
  return request().then(async (response) => {
    if (response.isAxiosError && response.code === ECONNABORTED_CODE) {
      showWarningNotification(t(
        tSchema.notifications.common.timeOut,
        {
          value: axios.defaults.timeout / 1000,
        },
      ));
    } else if (response.status === 200 && response.ok) {
      successMessage && showOkNotification(successMessage);
      onSuccess && onSuccess(response);
      return response;
    } else if (response.status !== 500) {
      const customResponseData = response.response?.data;
      const responseErrorMessage = (showCustomResponseErrorMessage || onResponseErrorMessage)
         && (customResponseData?.message || response.message);
      const message = showCustomResponseErrorMessage
        ? responseErrorMessage
        : errorMessage;
      message && showErrorNotification(message);
      onResponseErrorMessage && onResponseErrorMessage(responseErrorMessage);
    } else {
      errorMessage && showErrorNotification(errorMessage);
    }
    onError && onError(response);

    return null;
  }).catch((error) => {
    if (showCustomResponseErrorMessage || errorMessage) {
      showErrorNotification(errorMessage || error);
    }
  }).finally(() => { setLoading && setLoading(false); });
};

const requestsWithMessage = async ({
  requests,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
  onFinish,
  setLoading,
}) => {
  setLoading && setLoading(true);
  const responses = await Promise.all(requests);
  const allOk = !any(propEq('ok', false || undefined))(responses);

  if (allOk) {
    successMessage && showOkNotification(successMessage);
    onSuccess && onSuccess(responses);
  } else {
    errorMessage && showErrorNotification(errorMessage);
    onError && onError(responses);
  }

  setLoading && setLoading(false);
  onFinish && onFinish(responses);
  return responses;
};

const dispatchErrorMessage = (message) => {
  toast.error(message);
};
const dispatchSuccessMessage = (message) => {
  toast.success(message);
};

export default dispatchPromiseWithMessage;

export {
  dispatchPromiseWithMessage,
  dispatchPromiseWithFile,
  requestWithMessage,
  requestsWithMessage,
  showErrorNotification,
  showWarningNotification,
  showOkNotification,
  showErrorSessionNotification,
  dispatchErrorMessage,
  dispatchSuccessMessage,
};
