import actions from './img/actions.JPG';
import queryTable from './img/query_table.JPG';

const SectionQuerySubscription = () => (
  <div className="SectionQueryAcceptance">
    <p>
      The grid located on the Query subscription tab
      should display the following columns:
      <ul>
        <li>Name</li>
        <li>Start Date</li>
        <li>End Date</li>
        <li>Receiver</li>
        <li>Schedule</li>
        <li>Consolidated</li>
        <li>Active</li>
        <li>Actions</li>
      </ul>
    </p>
    <p>
      <img src={queryTable} alt="query table " />
    </p>
    <p>
      Each Query Subscription can be edited, deleted or executed.
      To do so, the user shall select the desired option from the list provided
      when clicking the button in the column &quot;Actions&quot;.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
  </div>
);

export default SectionQuerySubscription;
