/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Modal, Row, Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import BasicTable from '@pages/FishingTripsLogbook/components/ReportDetailsSection/components/tables/BasicTable';

import tSchema from '@lang/schema';

import { configureTable } from './config';

import './style.scss';

function ModalResponseDetails({
  show, setShow, message,
}) {
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const { isIncoming, validationDetails } = message;
  const headers = configureTable(isIncoming);

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        animation={false}
        onHide={handleClose}
        centered
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            { isIncoming ? 'Incoming Msg ID' : 'Outgoing Msg ID'}
            {` | ${message.identification} | `}
            { isIncoming ? 'BR Validation details' : 'BR reported by recipient'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="data-table message-detail-table">
            <Row>
              <BasicTable
                headers={headers}
                data={validationDetails}
                idColumn="brCode"
                margin={false}
              />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {t(tSchema.common.close)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ModalResponseDetails.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  message: PropTypes.shape({
    creatingParty: PropTypes.string,
    domain: PropTypes.string,
    typeMessage: PropTypes.string,
    creationDate: PropTypes.string,
    dataDomain: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      code: PropTypes.string,
    }),
    id: PropTypes.number,
    idType: PropTypes.number,
    identification: PropTypes.string,
    isIncoming: PropTypes.bool,
    message: PropTypes.string,
    recipient: PropTypes.string,
    relatedMessages: PropTypes.arrayOf(PropTypes.any),
    reportStatus: null,
    responseStatus: null,
    sender: PropTypes.string,
    status: null,
    type: PropTypes.shape({ id: PropTypes.number, description: PropTypes.string }),
    uuid: PropTypes.string,
    validationResults: PropTypes.string,
    validationDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,

};

export default ModalResponseDetails;
