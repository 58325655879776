import actions from './img/actions.png';
import viewMessage from './img/viewMessage.png';

const SectionViewMessage = () => (
  <div className="SectionViewMessage">
    <p>
      To view message of a report,
      the user has to select &quot;View Message&quot; in the &quot;Actions&quot;
      button for the corresponding report.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal shows the XML message of the IS report.
    </p>
    <img src={viewMessage} alt="View Message" />
  </div>
);

export default SectionViewMessage;
