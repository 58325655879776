import SectionDataActivityHistorical from './SectionDataActivityHistorical';
import SectionDataActivityTable from './SectionDataActivityTable';
import SectionValidationReport from './SectionValidationReport';

const DATA_ADMIN_REPORTING = {
  DATA_ACTIVITY_TABLE: {
    title: 'Data Activity Grid',
    body: <SectionDataActivityTable />,
  },
  DATA_ACTIVITY_HISTORICAL: {
    title: 'Historical Viewer',
    body: <SectionDataActivityHistorical />,
  },
  VALIDATION_REPORT: {
    title: 'Validation Report',
    body: <SectionValidationReport />,
  },
};

export default DATA_ADMIN_REPORTING;
