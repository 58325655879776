import { actionCreator, dispatchPromise } from '../utils';

import {
  apiSaveBusinessRule,
  apiToggleRuleActivation,
} from './api';

const createActionType = actionCreator('BUSINESS_RULES_VIEWER');

export const FETCH_BUSINESS_RULES = createActionType('FETCH_BUSINESS_RULES');

export const SAVE_BUSINESS_RULE = createActionType('SAVE_BUSINESS_RULE');

export const saveBusinessRule = (rule) => dispatchPromise({
  type: SAVE_BUSINESS_RULE,
  promise: apiSaveBusinessRule(rule),
});

export const TOGGLE_RULE_ACTIVATION = createActionType(
  'TOGGLE_RULE_ACTIVATION',
);

export const toggleRuleActivation = (rule) => dispatchPromise({
  type: TOGGLE_RULE_ACTIVATION,
  promise: apiToggleRuleActivation(rule),
});
