import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, {
  useMemo, useCallback, useState, useRef, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import {
  fetchISRReportViewSectionMessage,
  getIsrReports,
  removeObjectReference,
  removeReport,
} from '@services/isr/reports/api';
import { useBaseMessageService } from '@services/messages/BaseMessageContext';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import IsrViewerTableButtons from './components/buttons/IsrViewerTableButtons';
import BatchModal from './components/modals/batch/BatchModal';
import ConfirmationModal from './components/modals/confirmation/ConfirmationModal';
import LoadingModal from './components/modals/loading/LoadingModal';
import {
  DEFAULT_ORDER,
  DEFAULT_PAGE_SIZE,
  DBL_CLICK,
  FIRST_PAGE,
} from './constants';
import {
  formatIsrReports,
  tableCheckConfig,
} from './utils';

import { useRequestWithLoading } from '@common/hooks/request';
import { Permissions, hasPermission } from '@common/keycloakFunctions';
import { showErrorNotification, requestWithMessage } from '@common/utilities/Notification';
import { formatHeaderSort } from '@common/utils';

import './style.scss';

const IsrViewerTable = ({
  searchFilters,
  selectedId,
  setSelectedId,
  loading,
  setLoading,
  pageRequest,
  setPageRequest,
  dataTableRef,
  setTab,
  setForceUpdateFlag,
  forceUpdateFlag,
  selectedRecipient,
  setSelectedRecipient,
  onViewReportDetailsHandler,
  setSelectedTabResults,
}) => {
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER);
  const [isrReports, setIsrReports] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShowingExport, setIsShowingExport] = useState(false);
  const [isShowingDelete, setIsShowingDelete] = useState(false);
  const [isShowingUpdate, setIsShowingUpdate] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { t } = useTranslation();
  const { services: Services } = useBaseMessageService();
  const totalResults = useRef(0);
  const maxPages = useRef(0);
  const requestWithLoading = useRequestWithLoading();
  const [loadingXml, setLoadingXml] = useState(false);
  const [xmlMessageCache, setXmlMessageCache] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reportMessage, setReportMessage] = useState(null);
  const permissions = useSelector((state) => state.authentication.permissions);
  const { userPreferences } = useUserPreferences();
  const idScenarioLastActive = userPreferences.lastActiveIsr?.id;
  const [reloadReferences, setReloadReferences] = useState({});

  const handleReportMessageLoaded = useCallback((message) => setReportMessage(message), []);
  const triggerDataFetch = () => setForceUpdateFlag({});
  const triggerDataReload = () => setReloadReferences({});

  const showGridInfo = useCallback(
    (row, tab) => {
      if (hasPermission(permissions, Permissions.ISR_READ_DATA)) {
        if (tab) { setTab(tab); }
        onViewReportDetailsHandler(row.identifier, row.recipient, row.reportType, row.uuid);
      }
    },
    [permissions],
  );

  const handlerRemoveReport = useCallback(async (id) => {
    await requestWithMessage({
      request: () => removeReport(id),
      errorMessage: t(tSchema.isrViewer.deleteReportError),
      onSuccess: () => {
        triggerDataFetch();
      },
      successMessage: t(tSchema.isrViewer.deleteReportSuccess),
      setLoading,
    });
    setIsShowingDelete(false);
  },
  []);

  const handleRemove = useCallback(async (row) => {
    setDeleteId(row.id);
    setIsShowingDelete(true);
  },
  []);

  const handlerShowAttachments = useCallback((row) => {
    showGridInfo(row, 7);
  }, []);

  const handlerRemoveObject = useCallback(async (row) => {
    await requestWithMessage({
      request: () => removeObjectReference(row.id),
      errorMessage: t(tSchema.isrViewer.deleteError),
      onSuccess: () => {
        triggerDataFetch();
      },
      successMessage: t(tSchema.isrViewer.deleteSuccess),
      setLoading,
    });
  },
  []);

  const getOnTableHeaderClick = useCallback(
    (header) => {
      !loading && setOrderBy((prevState) => formatHeaderSort(header, prevState, DEFAULT_ORDER));
    },
    [loading],
  );

  const handleViewMessage = useCallback((row) => {
    !loading && !loadingXml
      && setSelectedMessage((prev) => ({
        id: row.id,
        useCached: prev && prev.id === row.id,
      }));
  }, [loading, loadingXml]);

  const actions = useMemo(() => [
    {
      label: t(tSchema.common.view),
      action: showGridInfo,
      disabled: !hasPermission(permissions, Permissions.ISR_READ_DATA),
    },
    {
      label: t(tSchema.common.delete),
      action: handleRemove,
      disabled: !hasPermission(permissions, Permissions.ISR_DELETE_REPORT),
    },
    {
      label: t(tSchema.isrViewer.attachFile),
      action: handlerShowAttachments,
      disabled: !hasPermission(permissions, Permissions.ISR_READ_DATA),
    },
    {
      label: t(tSchema.isrViewer.viewMessage),
      action: handleViewMessage,
      disabled: !hasPermission(permissions, Permissions.ISR_VIEW_MESSAGE),
    },
    {
      label: t(tSchema.isrViewer.removeObject),
      action: handlerRemoveObject,
      disabled: !hasPermission(permissions, Permissions.ISR_REMOVE_OBJ_REF),
      conditionallyDisabled: (row) => !row.object,
    },
  ], [
    showGridInfo,
  ]);

  useEffect(() => {
    if (selectedMessage !== null) {
      selectedMessage.useCached
        ? handleReportMessageLoaded({ message: xmlMessageCache.message })
        : requestWithLoading(async () => {
          const query = await fetchISRReportViewSectionMessage(selectedMessage.id);

          if (query.ok) {
            const data = await query.json();
            if (data.isTooLarge) {
              setLoading(true);
              const query1 = await Services.apiDownloadXML(data.xmlIncomingId, 'incoming');
              if (query1.ok) {
                const data1 = await query1.json();
                const url = window.URL.createObjectURL(new Blob([data1]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Message.xml');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false);
              } else {
                setLoading(false);
                showErrorNotification();
              }
            } else {
              handleReportMessageLoaded({ message: data.message });
              setXmlMessageCache(data.message);
            }
          } else {
            showErrorNotification(t(tSchema.notifications.common.fetchData));
          }
        }, setLoadingXml);
    }
  }, [selectedMessage]);

  const showTableButtons = hasPermission(permissions, Permissions.ISR_UPDATE_REFERENCES)
  || hasPermission(permissions, Permissions.ISR_EXPORT_EXCEL);

  const tableButtons = (
    showTableButtons && (
    <IsrViewerTableButtons
      setIsShowingExport={setIsShowingExport}
      setIsShowingUpdate={setIsShowingUpdate}
      totalResults={totalResults.current}
      selectedRows={selectedRows}
      searchFilters={searchFilters}
      idScenario={idScenarioLastActive}
      triggerDataFetch={triggerDataReload}
    />
    )
  );

  const checkAdded = (report) => {
    const rep = report;
    return rep == null;
  };

  const handleRowSelected = useCallback(
    (row, event) => event.detail === DBL_CLICK && showGridInfo(row),
    [showGridInfo],
  );

  const pageSizeChangeHandler = useCallback((size) => {
    setPageRequest(FIRST_PAGE);
    setPageSize(Number.parseInt(size, DEFAULT_PAGE_SIZE));
  }, []);

  const pageChangeHandler = useCallback((page) => {
    setPageRequest(page);
    triggerDataFetch();
  }, []);

  const fetchIsrReports = useCallback(async () => {
    await requestWithMessage({
      request: () => getIsrReports(
        searchFilters,
        {
          page: pageRequest.current - 1,
          size: pageSize,
          sort: orderBy && orderBy.field,
          direction: orderBy && orderBy.order,
        },
        idScenarioLastActive,
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || FIRST_PAGE;
        setIsrReports(data.content || []);
        setSelectedTabResults(0);
      },
      setLoading,
    });
  }, [searchFilters, pageRequest.current, pageSize, orderBy, idScenarioLastActive]);

  useEffect(() => {
    if (!isEmpty(orderBy)) {
      fetchIsrReports();
    }
  }, [
    forceUpdateFlag,
    pageSize,
    orderBy,
    searchFilters,
    idScenarioLastActive,
    reloadReferences,
  ]);

  useEffect(() => {
    setSelectedRows([]);
  }, [
    searchFilters,
    reloadReferences,
    idScenarioLastActive,
  ]);

  return (
    <div id="isrViewerTable">
      <DataTable
        actions={actions}
        rowSelected={`${selectedId}-${selectedRecipient}`}
        ref={dataTableRef}
        tableId="isrReports"
        totalResults={totalResults.current}
        tableConfig={tableCheckConfig({
          reports: isrReports,
          selectedRows,
          onSelectedRows: setSelectedRows,
          checkAdded,
          t,
        })}
        orderBy={orderBy}
        onTableHeaderClick={getOnTableHeaderClick}
        onRowSelected={handleRowSelected}
        rowData={formatIsrReports(isrReports)}
        defaultPageSize={pageSize}
        onPageSizeChangeHandler={pageSizeChangeHandler}
        onPageChangeHandler={pageChangeHandler}
        loading={loading}
        maxPages={maxPages.current}
        tableActions={tableButtons}
        showTotalResults
        errorMessageEmptyArray={t(tSchema.isrViewer.errorEmpty)}
        errorMessageLoadingData={t(tSchema.isrViewer.errorLoading)}
      />
      {!loading && !loadingXml && reportMessage
        && (
        <LoadingModal
          data={reportMessage}
          onClose={() => {
            setSelectedId(null);
            setSelectedMessage(null);
            setReportMessage(null);
            setSelectedRecipient(null);
          }}
        />
        ) }
      {isShowingExport
        && (
        <BatchModal
          isShowing={isShowingExport}
          setIsShowing={setIsShowingExport}
          title={t(tSchema.isrViewer.exportingReports)}
        />
        ) }
      {isShowingUpdate
        && (
        <BatchModal
          isShowing={isShowingUpdate}
          setIsShowing={setIsShowingUpdate}
          title={t(tSchema.isrViewer.updatingReferences)}
        />
        ) }
      {isShowingDelete
        && (
        <ConfirmationModal
          isShowing={isShowingDelete}
          setIsShowing={setIsShowingDelete}
          msg={
            t(tSchema.isrViewer.deleteConfirmation)
          }
          actionBtnLabel={t(tSchema.common.delete)}
          handleAction={handlerRemoveReport}
          id={deleteId}
        />
        ) }
    </div>
  );
};

IsrViewerTable.propTypes = {
  searchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedId: PropTypes.instanceOf(PropTypes.any).isRequired,
  setSelectedId: PropTypes.func.isRequired,
  selectedRecipient: PropTypes.instanceOf(PropTypes.any).isRequired,
  setSelectedRecipient: PropTypes.func.isRequired,
  loading: PropTypes.instanceOf(PropTypes.any).isRequired,
  setLoading: PropTypes.func.isRequired,
  pageRequest: PropTypes.objectOf(PropTypes.any).isRequired,
  setPageRequest: PropTypes.func.isRequired,
  dataTableRef: PropTypes.objectOf(PropTypes.any).isRequired,
  setTab: PropTypes.func.isRequired,
  setForceUpdateFlag: PropTypes.func.isRequired,
  forceUpdateFlag: PropTypes.objectOf(PropTypes.any).isRequired,
  onViewReportDetailsHandler: PropTypes.func.isRequired,
  setSelectedTabResults: PropTypes.func.isRequired,
};

export default IsrViewerTable;
