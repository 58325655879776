/*
    Permissions:
     - READ_DATA: Read data
     - WRITE_DATA: Write data (create and modify) vessel current values
     - WRITE_HISTORIC_DATA: Write data (create and modify) vessel historical values
     - APPROVE_MODIFICATIONS: Approve creation/modification
     - DELETE_VESSELS: Set vessels to inactive
     - BUSINESS_RULES_MODIFICATIONS: Business Rules activation, creation and edition
     - USER_AND_GROUPS_MANAGEMENT: User access and groups management
     - USER_PERMISSIONS_AND_ROLES: User permissions/roles management
     - SOURCES_ADMINISTRATION: Sources administration (enabling and scheduling)
     - DATA_ACTIVITY_REPORTS: Access to data activity reports
     - SYSTEM_ACTIVITY_REPORTS: Access to system activity reports
     - REFERENCE_DATA: Manage reference data
     - VESSEL_GROUPS: Create and modify vessel groups
*/

const Permissions = {
  READ_DATA: 'READ_DATA',
  WRITE_DATA: 'WRITE_DATA',
  ISR_READ_DATA: 'ISR_READ_DATA',
  ISR_UPDATE_DATA: 'ISR_UPDATE_DATA',
  ISR_DELETE_DATA: 'ISR_DELETE_DATA',
  ISR_REMOVE_OBJ_REF: 'ISR_REMOVE_OBJ_REF',
  ISR_VIEW_MESSAGE: 'ISR_VIEW_MESSAGE',
  ISR_DELETE_REPORT: 'ISR_DELETE_REPORT',
  ISR_UPDATE_REFERENCES: 'ISR_UPDATE_REFERENCES',
  ISR_EXPORT_EXCEL: 'ISR_EXPORT_EXCEL',
  ISR_DELETE_ATTACH: 'ISR_DELETE_ATTACH',
  WRITE_HISTORIC_DATA: 'WRITE_HISTORIC_DATA',
  APPROVE_MODIFICATIONS: 'APPROVE_MODIFICATIONS',
  DELETE_VESSELS: 'DELETE_VESSELS',
  BUSINESS_RULES_MODIFICATIONS: 'BUSINESS_RULES_MODIFICATIONS',
  USER_AND_GROUPS_MANAGEMENT: 'USER_AND_GROUPS_MANAGEMENT',
  USER_PERMISSIONS_AND_ROLES: 'USER_PERMISSIONS_AND_ROLES',
  SOURCES_ADMINISTRATION: 'SOURCES_ADMINISTRATION',
  DATA_ACTIVITY_REPORTS: 'DATA_ACTIVITY_REPORTS',
  SYSTEM_ACTIVITY_REPORTS: 'SYSTEM_ACTIVITY_REPORTS',
  REFERENCE_DATA: 'REFERENCE_DATA',
  VESSEL_GROUPS: 'VESSEL_GROUPS',
  EDIT_FAILOVER: 'EDIT_FAILOVER',
  EDIT_VALIDATION_BR: 'EDIT_VALIDATION_BR',
  EDIT_ACCEPTANCE_BR: 'EDIT_ACCEPTANCE_BR',
  EXPORT_VALIDATION_BR: 'EXPORT_VALIDATION_BR',
  EDIT_SCENARIO: 'EDIT_SCENARIO',
  EDIT_SCENARIO_USERS: 'EDIT_SCENARIO_USERS',
  EDIT_REFERENCE_DATA: 'EDIT_REFERENCE_DATA',
};

export const Roles = {
  VR_USER_READER: 'vr-user-reader',
  VR_USER_EDITOR: 'vr-user-editor',
  VR_USER_APPROVER: 'vr-user-approver',
  VR_DATA_ADMINISTRATOR: 'vr-data-administrator',
  VR_SYSTEM_ADMINISTRATOR: 'vr-system-administrator',
  ISR_USER_READER: 'isr-reader',
  ISR_USER_EDITOR: 'isr-editor',
  ISR_USER_APPROVER: 'isr-approver',
  ISR_DATA_ADMINISTRATOR: 'isr-data-administrator',
  ISR_SYSTEM_ADMINISTRATOR: 'isr-system-administrator',
  DATA_ADMIN: 'data-admin',
  ADMIN_ROLE: 'admin-role',
};

const getPermissionsFromRol = (rol) => {
  switch (rol) {
    case Roles.VR_USER_READER:
      return [Permissions.READ_DATA];
    case Roles.VR_USER_EDITOR:
      return [
        Permissions.READ_DATA,
        Permissions.WRITE_DATA,
      ];
    case Roles.VR_USER_APPROVER:
      return [
        Permissions.READ_DATA,
        Permissions.WRITE_DATA,
        Permissions.WRITE_HISTORIC_DATA,
        Permissions.APPROVE_MODIFICATIONS,
        Permissions.DELETE_VESSELS,
        Permissions.DATA_ACTIVITY_REPORTS,
      ];
    case Roles.VR_DATA_ADMINISTRATOR:
      return [
        Permissions.BUSINESS_RULES_MODIFICATIONS,
        Permissions.USER_PERMISSIONS_AND_ROLES,
        Permissions.SOURCES_ADMINISTRATION,
        Permissions.DATA_ACTIVITY_REPORTS,
        Permissions.REFERENCE_DATA,
        Permissions.VESSEL_GROUPS,
      ];
    case Roles.VR_SYSTEM_ADMINISTRATOR:
      return [
        Permissions.USER_AND_GROUPS_MANAGEMENT,
        Permissions.USER_PERMISSIONS_AND_ROLES,
        Permissions.SYSTEM_ACTIVITY_REPORTS,
      ];
    case Roles.DATA_ADMIN:
      return [
        Permissions.USER_AND_GROUPS_MANAGEMENT,
      ];
    case Roles.ISR_USER_READER:
      return [
        Permissions.ISR_READ_DATA,
        Permissions.ISR_EXPORT_EXCEL,
      ];
    case Roles.ISR_USER_EDITOR:
    case Roles.ISR_USER_APPROVER:
      return [
        Permissions.ISR_READ_DATA,
        Permissions.ISR_DELETE_DATA,
        Permissions.ISR_UPDATE_DATA,
        Permissions.ISR_REMOVE_OBJ_REF,
        Permissions.ISR_UPDATE_REFERENCES,
        Permissions.ISR_EXPORT_EXCEL,
        Permissions.ISR_DELETE_ATTACH,
      ];
    case Roles.ISR_DATA_ADMINISTRATOR:
      return [
        Permissions.ISR_READ_DATA,
        Permissions.ISR_DELETE_DATA,
        Permissions.ISR_DELETE_REPORT,
        Permissions.ISR_REMOVE_OBJ_REF,
        Permissions.ISR_UPDATE_REFERENCES,
        Permissions.ISR_EXPORT_EXCEL,
        Permissions.ISR_VIEW_MESSAGE,
        Permissions.EDIT_VALIDATION_BR,
        Permissions.EDIT_ACCEPTANCE_BR,
        Permissions.EXPORT_VALIDATION_BR,
        Permissions.EDIT_SCENARIO,
        Permissions.EDIT_SCENARIO_USERS,
        Permissions.EDIT_REFERENCE_DATA,
      ];
    case Roles.ISR_SYSTEM_ADMINISTRATOR:
      return [
        Permissions.ISR_READ_DATA,
        Permissions.ISR_DELETE_DATA,
        Permissions.ISR_EXPORT_EXCEL,
        Permissions.ISR_VIEW_MESSAGE,
        Permissions.EDIT_VALIDATION_BR,
        Permissions.EDIT_FAILOVER,
        Permissions.EDIT_SCENARIO_USERS,
        Permissions.EXPORT_VALIDATION_BR,
      ];
    case Roles.ADMIN_ROLE:
      return [
        Permissions.ISR_DELETE_REPORT,
        Permissions.ISR_VIEW_MESSAGE,
      ];
    default:
      return [];
  }
};

const getPermissionsFromRoles = (roles) => {
  let permissions = [];
  roles.map((rol) => {
    permissions = permissions.concat(getPermissionsFromRol(rol));
    return 0;
  });
  return permissions;
};

/*
    HAS ROLE
    Check if the user has the role
*/
const hasRole = (roles, role) => roles?.includes(role);

/*
    HAS PERMISSION
    Check if the user has the permission
*/
const hasPermission = (userPermissions, permission) => userPermissions?.includes(permission);

/*
    HAS ANY PERMISSION
    Check if the user has any of the permissions in the list
*/
const hasAnyPermission = (user, permissions) => {
  let hasPermissions = false;
  permissions.map((permission) => {
    if (hasPermission(user, permission)) {
      hasPermissions = true;
    }
    return 0;
  });
  return hasPermissions;
};

export const RealmRole = {
  DATA_ADMIN: 'data-admin',
  SYSTEM_ADMIN: 'system-admin',
  APP_USER: 'app-user',
  ISR_DATA_ADMINISTRATOR: 'isr-data-administrator',
  ISR_SYSTEM_ADMINISTRATOR: 'isr-system-administrator',
  FIS_PARTY_XEU: 'fis-party-xeu',
  FIS_PARTY_XFA: 'fis-party-xfa',
};

const hasRealmRole = (keycloak) => (realmRole) => keycloak?.hasRealmRole(realmRole);

const hasAnyRealmRole = (keycloak, realmRoles) => realmRoles?.some(
  hasRealmRole(keycloak),
);

const isDataOrSystemAdmin = (keycloak) => hasAnyRealmRole(
  keycloak,
  [RealmRole.DATA_ADMIN, RealmRole.SYSTEM_ADMIN],
);

const isIsrDataAdmin = (keycloak) => hasAnyRealmRole(
  keycloak,
  [RealmRole.ISR_DATA_ADMINISTRATOR],
);

const isXEUorXFA = (keycloak) => hasAnyRealmRole(keycloak,
  [RealmRole.FIS_PARTY_XEU, RealmRole.FIS_PARTY_XFA]);

const isIsrSystemAdmin = (keycloak) => hasAnyRealmRole(
  keycloak,
  [RealmRole.ISR_SYSTEM_ADMINISTRATOR],
);

const isAdminOrSystemAdmin = (keycloak) => hasAnyRealmRole(
  keycloak,
  [RealmRole.DATA_ADMIN, RealmRole.SYSTEM_ADMIN],
);

export {
  Permissions,
  hasRealmRole,
  getPermissionsFromRoles,
  hasPermission,
  hasAnyPermission,
  hasAnyRealmRole,
  isDataOrSystemAdmin,
  hasRole,
  isIsrSystemAdmin,
  isAdminOrSystemAdmin,
  isXEUorXFA,
  isIsrDataAdmin,
};
