import tSchema from '@lang/schema';

import {
  formatSortParams,
  get,
  postFile,
  getFile,
  del,
  downloadFile,
  put,
  post,
  actionCreator,
} from '@services/utils';

import { FILE_DATE_TIME_FORMAT, formatCurrentDate } from '@common/dateUtils';
import { dispatchPromiseWithFile, dispatchPromiseWithMessage } from '@common/utilities/Notification';

const baseURL = 'isrService/';
const createActionType = actionCreator('CRUD');
const EXPORT_REPORTS = createActionType('EXPORT_REPORTS');
const UPDATE_REFERENCES = createActionType('UPDATE_REFERENCES');
const schema = tSchema.isrViewer;

export const getIsrReports = (searchFilters, params, idScenario) => {
  const filter = searchFilters ? `/${searchFilters}` : '';
  const concat = searchFilters ? '&' : '/';
  const scenario = idScenario ? `${concat}scenario==${idScenario}` : '';
  return get()(`${baseURL}reports${filter}${scenario}`, formatSortParams(params));
};

export const getFiltersConfig = () => get()(`${baseURL}filters`);

function formatSearchFilters(searchFilters) {
  let result;
  if (searchFilters) {
    const filters = searchFilters.map(({ code }) => `${code}`).join(',');
    result = { filterCodes: filters };
  }
  return result;
}

function formatTabs(tabs) {
  let result;
  if (tabs) {
    const filterTabs = tabs.map((tab) => `${tab}`).join(',');
    result = { tabsToReturn: filterTabs };
  }
  return result;
}

export const getFiltersValues = (searchFilters, idScenarioLastActive) => get()(`${baseURL}filters/value`, { ...formatSearchFilters(searchFilters), scenario: idScenarioLastActive });

export const getReportTabs = (identifier, recipient, tabs) => get()(`${baseURL}tabs/${identifier}/recipient/${recipient}`, formatTabs(tabs));

export const exportReports = (payload, setIsShowingExport, t) => {
  const emptyFilter = payload.idScenario ? '' : '/EMPTY';
  const filter = payload.searchFilters ? `/${payload.searchFilters}` : emptyFilter;
  const concat = payload.searchFilters ? '&' : '/';
  const scenario = payload.idScenario ? `${concat}scenario==${payload.idScenario}` : '';
  return dispatchPromiseWithFile({
    type: EXPORT_REPORTS,
    promise: getFile()(`${baseURL}exportIsReports${filter}${scenario}`, { idsList: payload.ids }),
    errorMessage: t(schema.exportError),
    fileName: `isr_report_export_${formatCurrentDate(FILE_DATE_TIME_FORMAT)}`,
    callback: () => {
      setIsShowingExport(false);
    },
  });
};

export const updateReferences = (reports, setIsShowingUpdate, t, triggerDataFetch) => (
  dispatchPromiseWithMessage({
    type: UPDATE_REFERENCES,
    promise: post()(`${baseURL}updateVesselsForReports`, reports),
    errorMessage: t(schema.errorReferences),
    successMessage: t(schema.successReferences),
    callback: () => {
      setIsShowingUpdate(false);
      triggerDataFetch();
    },
  })
);

/**
 * @param {String|Number} xmlId The value of declaration's xmlIncomingID
 * @returns {Promise<Response>}
 */
export function fetchISRReportViewSectionMessage(id) {
  return get()(`isrService/message/id/${id}`);
}

export const uploadAttachment = (image, uuid, recipient, description) => {
  const formData = new FormData();
  formData.append(
    'file',
    image,
  );
  return postFile()(`${baseURL}attachment/upload?humanReadableId=${uuid}&recipient=${recipient}&description=${description}`, formData);
};

export const deleteAttachment = (id) => del()(`${baseURL}deleteAttachment/${id}`);
export const downloadAttachment = (id) => downloadFile()(`${baseURL}getAttachmentisreport/${id}`);
export const removeObjectReference = (reportId) => put()(`${baseURL}removeEtalonFromReport/${reportId}`);
export const removeReport = (reportId) => del()(`${baseURL}delete/id/${reportId}`);
