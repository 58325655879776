import { isEmpty } from 'ramda';

import {
  actionCreator, get, getFile, post, postFile, put,
} from '../../../utils';

import { FILE_DATE_TIME_FORMAT, formatCurrentDate } from '@common/dateUtils';
import { dispatchPromiseWithFile, dispatchPromiseWithMessage } from '@common/utilities/Notification';

const createActionType = actionCreator('CRUD');

const TypeActionsCrud = {
  FETCH_ALL_VESSELS: createActionType('FETCH_ALL_VESSELS'),
  FETCH_VESSEL_BY_ID: createActionType('FETCH_VESSEL_BY_ID'),
  POST_VESSEL: 'POST_VESSEL',
  EDIT_VESSEL: createActionType('EDIT_VESSEL'),
  APPROVE_VESSEL: createActionType('APPROVE_VESSEL'),
  GET_SEARCHEABLE_ATTRIBUTES: createActionType('GET_SEARCHEABLE_ATTRIBUTES'),
  MAIN_SEARCH: 'MAIN_SEARCH',
  ADVANCED_SEARCH_VESSEL: 'ADVANCED_SEARCH_VESSEL',
  SET_PAGINATION: 'SET_PAGINATION',
  UPLOAD_VESSELS: createActionType('UPLOAD_VESSELS'),
  CLEAN_VESSELS_UPLOAD_REPORTS: 'CLEAN_VESSELS_UPLOAD_REPORTS',
  EXPORT_VESSELS: createActionType('EXPORT_VESSELS'),
  FETCH_ALL_GROUPS: createActionType('FETCH_ALL_GROUPS'),
  FETCH_GROUPS_FROM_VESSEL: createActionType('FETCH_GROUPS_FROM_VESSEL'),
  UPDATE_VESSEL_GROUPS: createActionType('UPDATE_VESSEL_GROUPS'),
  SWAP_GROUP_PERTENENCY: 'SWAP_GROUP_PERTENENCY',
  GET_FORM_DATA: createActionType('GET_FORM_DATA'),
  GET_VESSEL_PORTS: createActionType('GET_VESSEL_PORTS'),
  CLEAR_VESSELS: 'CLEAR_VESSELS',
  LOAD_VESSEL_FROM_STATE: 'LOAD_VESSEL_FROM_STATE',
};

const setSearchRequest = (page, count, groupInfo) => `vrService/vessel/getVessels?page=${page}&count=${count}${groupInfo ? '&groupInfo=true' : ''}`;

const setHistoricSearchRequest = (page, count) => `vrService/elastic/advancedSearchHistoricVessel?page=${page}&count=${count}`;

const setDateFormat = (date, isFromTo, isDateTo) => {
  let formatedDate = date;
  if (!isEmpty(date)) {
    if (date !== null && !date.includes('T') && isFromTo) {
      formatedDate = isDateTo ? `${date}T23:59:59.999` : `${date}T00:00:00.000`;
    }
  }
  return formatedDate;
};

const formatBody = (isSearchOnDate, filtersBody, sortFields) => {
  const bodyFormatted = { ...filtersBody };
  // Formatear fechas de búsqueda de histórico dateFrom/dateTo
  if (isSearchOnDate) {
    bodyFormatted.dateFrom = setDateFormat(bodyFormatted.dateFrom, true);
    bodyFormatted.dateTo = setDateFormat(bodyFormatted.dateTo, true, true);
  } else {
    delete bodyFormatted.dateFrom;
    delete bodyFormatted.dateTo;
  }
  // Si text está vacío no se envía
  if (bodyFormatted.text === '') {
    delete bodyFormatted.text;
  }
  if (bodyFormatted.formFields) {
    bodyFormatted.formFields = bodyFormatted.formFields.map((field) => {
      const fieldFormatted = field;
      const isDate = field.type === 'DATE';
      const isTimestamp = field.type === 'TIMESTAMP';
      // Si el searchType no es RANGE, se quita el range en caso de que esté
      if (fieldFormatted.searchType !== 'RANGE' && fieldFormatted.range) {
        delete fieldFormatted.range;
      }
      // Si es un rango y date, se formatean las fechas de los rangos
      if (fieldFormatted.searchType === 'RANGE' && (isDate || isTimestamp)) {
        if (fieldFormatted.range[0] !== null) {
          fieldFormatted.range[0] = setDateFormat(fieldFormatted.range[0]);
        }
        if (fieldFormatted.range[1] !== null) {
          fieldFormatted.range[1] = setDateFormat(fieldFormatted.range[1]);
        }
      }
      // Si es un date, se formatea el value
      if (fieldFormatted.searchType === 'EXACT' && (isDate || isTimestamp)) {
        fieldFormatted.value = setDateFormat(fieldFormatted.value);
      }
      // Si es un rango, se elimina el value
      if (fieldFormatted.searchType === 'RANGE') {
        delete fieldFormatted.value;
      }

      return fieldFormatted;
    });
    bodyFormatted.formFields = bodyFormatted.formFields.filter(
      (field) => (field.searchType !== 'RANGE' && field.value !== null) || field.searchType === 'RANGE',
    );
  }
  if (sortFields) {
    bodyFormatted.sortFields = sortFields;
  }

  return bodyFormatted;
};

/*
    FETCH ALL VESSELS
    Calls the endpoint that returns all the vessels with the specified filters
*/
const fetchAllVessels = ({
  page, count, filtersBody, isSearchPeriod, isSearchOnDate, groupInfo = false, sortFields,
}) => {
  const body = formatBody(isSearchOnDate, filtersBody, sortFields);
  const endpoint = isSearchPeriod
    ? setHistoricSearchRequest(page, count)
    : setSearchRequest(page, count, groupInfo);

  return (
    dispatchPromiseWithMessage({
      type: TypeActionsCrud.FETCH_ALL_VESSELS,
      promise: post()(endpoint, body),
      errorMessage: 'Error loading the vessels',
    })
  );
};

/*
    FETCH VESSEL BY ID
    Calls the endpoint that return the info of one vessel
*/
const fetchVesselById = (vesselId, dateFrom, callback, errorMessage) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.FETCH_VESSEL_BY_ID,
    promise: get()(`vrService/vessel/${vesselId}`, { ...(dateFrom ? { date: dateFrom } : {}) }),
    errorMessage: errorMessage || 'Error loading the specified vessel',
    callback,
  })
);

/*
  EDIT VESSEL
*/
const editVessel = (vesselId, body) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.EDIT_VESSEL,
    promise: put()(`vrService/vessel/${vesselId}`, body),
    successMessage: 'Vessel successfully updated',
    errorMessage: 'The vessel could not be updated',
  })
);

/*
  APPROVE VESSEL
*/
const approveVessel = ({
  userName, vesselId, validFrom,
  validTo,
}) => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.APPROVE_VESSEL,
    promise: put()(`vrService/vessel/approve/${vesselId}?user=${userName}&validFrom=${validFrom}&validTo=${validTo}`),
    successMessage: 'Vessel data has been confirmed',
    errorMessage: 'Vessel could not be confirmed',
  })
);

/*
  EXPORT VESSELS TO EXCEL
 */
const exportVessels = (payload) => dispatchPromiseWithFile({
  type: TypeActionsCrud.EXPORT_VESSELS,
  promise: getFile()(`vrService/vessel/getVessels/download?page=1&count=${payload.count}`, payload.filtersBody),
  errorMessage: 'Error exporting the vessels',
  fileName: `vessel_export_${formatCurrentDate(FILE_DATE_TIME_FORMAT)}`,
});

const getSearchableAttributes = () => dispatchPromiseWithMessage({
  type: TypeActionsCrud.GET_SEARCHEABLE_ATTRIBUTES,
  promise: get()('vrService/getTemplate'),
  errorMessage: 'Error loading fields',
});

const getFormData = () => dispatchPromiseWithMessage({
  type: TypeActionsCrud.GET_FORM_DATA,
  promise: get()('vrService/getFormData'),
  errorMessage: 'Error loading form data',
});

const getVesselPorts = () => dispatchPromiseWithMessage({
  type: TypeActionsCrud.GET_VESSEL_PORTS,
  promise: get()('vrService/formData/vesselPorts'),
});

const setPagination = ({
  page, size, filtersBody = {},
}) => ({
  type: TypeActionsCrud.SET_PAGINATION,
  payload: {
    page, size, filtersBody,
  },
});

/*
  UPLOAD VESSELS
  Upload Vessels from file
*/
const uploadVessels = (file, source) => {
  const formData = new FormData();
  formData.append(
    'file',
    file,
  );
  return dispatchPromiseWithMessage({
    type: TypeActionsCrud.UPLOAD_VESSELS,
    promise: postFile()(`vrService/vessel/upload?source=${source}`, formData),
    errorMessage: 'Vessels could not be uploaded',
  });
};

const cleanUploadVesselsReports = () => ({
  type: TypeActionsCrud.CLEAN_VESSELS_UPLOAD_REPORTS,
});

const swapVisualGroup = (group, destination) => ({
  type: TypeActionsCrud.SWAP_GROUP_PERTENENCY,
  payload: {
    destination,
    group,
  },
});

const fetchVesselsGroups = () => dispatchPromiseWithMessage({
  type: TypeActionsCrud.FETCH_ALL_GROUPS,
  promise: post()('vrService/groupRecord/getGroups', {}),
  errorMessage: 'Vessel groups could not be obtained',
});

const fetchGroupsFromVessel = (etalonId) => dispatchPromiseWithMessage({
  type: TypeActionsCrud.FETCH_GROUPS_FROM_VESSEL,
  promise: get()(`vrService/groupRecord/getGroupsFromVessel?etalonId=${etalonId}`),
  errorMessage: 'The groups of this vessel could not be obtained',
});

const updateVesselGroups = (currentGroups, etalonId) => {
  const arrayToSend = [];
  currentGroups.map((group) => (arrayToSend.push(group.id)));
  return (dispatchPromiseWithMessage({
    type: TypeActionsCrud.UPDATE_VESSEL_GROUPS,
    promise: put()(`vrService/groupRecord/updateGroups/${etalonId}`, arrayToSend),
    errorMessage: 'Vessel groups could not be updated',
    successMessage: 'Vessel groups updated successfully',
  }));
};

const clearVesselsAndPagination = () => ({
  type: TypeActionsCrud.CLEAR_VESSELS,
});

const loadVesselFromState = (vessel) => ({
  type: TypeActionsCrud.LOAD_VESSEL_FROM_STATE,
  payload: vessel,
});

export {
  TypeActionsCrud,
  fetchAllVessels,
  fetchVesselById,
  editVessel,
  approveVessel,
  getSearchableAttributes,
  getFormData,
  getVesselPorts,
  setPagination,
  uploadVessels,
  cleanUploadVesselsReports,
  formatBody,
  exportVessels,
  fetchVesselsGroups,
  fetchGroupsFromVessel,
  updateVesselGroups,
  swapVisualGroup,
  clearVesselsAndPagination,
  loadVesselFromState,
};
