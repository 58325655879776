import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import editForm from './img/editForm.png';
import editFormIsr from './img/editFormIsr.png';

import { DOMAIN } from '@common/utils';

const SectionEditQueryAcceptance = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="SectionQueryAcceptance">
      <p>
        To edit a query acceptance,
        {' '}
        in the results grid the user must click on the &quot;Actions&quot;
        button corresponding to the row of that specific acceptance rule,
        and then click on &quot;Edit&quot; button.
      </p>
      <p>
        A modal displays the information related
        to the selected query acceptance, in editable format:
      </p>
      {domain === DOMAIN.FA
        ? (
          <p>
            <img src={editForm} alt="editForm" />
          </p>
        ) : (
          <p>
            <img src={editFormIsr} alt="editForm" />
          </p>
        )}
      <p>
        After editing the Query Acceptance, the user shall click on &quot;Save&quot; button.
        Then the user is able to see the changes performed in the central
        grid of the application.
      </p>
    </div>
  );
};

export default SectionEditQueryAcceptance;
