import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import view from './img/view.png';
import viewIsr from './img/viewISR.png';

import { DOMAIN } from '@common/utils';

const SectionViewQueryAcceptance = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="SectionViewAcceptance">
      <p>
        To visualize full information of an acceptance rule,
        in the results grid the user can either click on the &quot;Actions&quot; button
        corresponding to the row of that specific rule and then click on &quot;View&quot; button,
        or double click on that acceptance rule.
      </p>
      <p>
        A modal displays the information
        related to the selected query acceptance, in read-only format:
      </p>
      {domain === DOMAIN.FA
        ? (
          <p>
            <img src={view} alt="View Acceptance" />
          </p>
        )
        : (
          <p>
            <img src={viewIsr} alt="View Acceptance" />
          </p>
        )}
    </div>
  );
};

export default SectionViewQueryAcceptance;
