import validationBRTableActionsDeactivateImg from './img/validation-br-table-actions-deactivate.png';
import validationBRTableActionsImg from './img/validation-br-table-actions.png';
import validationBRTableImg from './img/validation-br-table.png';

const SectionValidationBRTable = () => (
  <div className="subSection">
    <p>
      Grid shows all validation BRs data.
    </p>
    <p>
      <img src={validationBRTableImg} alt="validationBRTable" />
    </p>
    <p>
      Each validation BR can be viewed, edited, activated or deactivated.
      In the &quot;Actions&quot; column,
      if the user clicks the Actions button,
      the list of available operations is displayed:
    </p>
    <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
      <p>
        <img src={validationBRTableActionsImg} alt="validationBRTableActions" />
      </p>
      <p>
        <img src={validationBRTableActionsDeactivateImg} alt="validationBRTableActionsDeactivate" />
      </p>
    </p>
  </div>
);

export default SectionValidationBRTable;
