/* eslint-disable import/order */

import { identity } from 'ramda';
import { useTranslation } from 'react-i18next';
import tSchema from '@lang/schema';
import AccordionSection from '../../../AccordionSection';

import apply from './img/apply.png';
import clear from './img/clear.png';
import expand from './img/expand.png';
import availableFilters from './img/available-filters.png';
import addFilter from './img/filter-add-filter.png';
import selectISR from './img/filter-select-isr.png';
import filtersExcerptISR from './img/filters-excerpt-isr.png';
import filtersExpandedISR from './img/filters-expanded-isr.png';
import selectFilteredISR from './img/filter-select-filtered-isr.png';
import newFilterISR from './img/filter-new-filter-isr.png';
import singleFilterISR from './img/single-filter-isr.png';

const general = () => ({
  title: 'General',
  body: (
    <>
      <p>
        Filters are used to narrow down the data displayed by default in the grid,
        or to search for specific data.
      </p>
      <p>
        The filters panel is displayed above the grid.
      </p>
      <p>
        When filters are collapsed, an excerpt of the current applied filters is displayed.
      </p>
      <p>
        <img src={filtersExcerptISR} alt="filtersExcerptISR" />
      </p>
      <p>
        User can expand the filters panel to add,
        remove or edit the enabled filters and their values,
        by clicking on the expand icon.
      </p>
      <p>
        <img src={expand} alt="expand" />
      </p>
      <p>
        When filters are expanded,
        a list of applied filters is displayed,
        and their values can be edited.
      </p>
      <p>
        <img src={filtersExpandedISR} alt="filtersExpandedISR" />
      </p>
      <p>
        The &quot;Search Period&quot; filter cannot be used alone,
        it needs at least one more filter to which apply the criteria selected
        in the search period filter.
      </p>
      <p>
        The available filters are:
        <ul>
          <li>Context: the context of the report</li>
          <li>Participants: the nationality of the different inspectors</li>
          <li>Program: the ISR Program of the report</li>
          <li>Report ID: the human readable of the report</li>
          <li>Module: the module of the report</li>
          <li>Control Party:  the control party of the report</li>
          <li>Infringement: indicate if there is an infringement suspicion with this report</li>
          <li>
            Infringement Classification: the classification of the infringement suspicion
            of the report
          </li>
          <li>Infringement Type: the type of the infringement suspicion of the report</li>
          <li>Gear: the gear of the Inspected Vessel found during inspection</li>
          <li>Timeframe: a period in which the reference date of the report must be created</li>
          <li>
            Inspected Species: a fishing activity catch specified for this vessel transport
            means
          </li>
          <li>
            Vessel ID: search in all types of ID (CFR, UVI, External marking, IRCS) for all
            the inspected vessels received of all reports
          </li>
          <li>Vessel Name: search the name in all the inspected vessels received of all reports</li>
          <li>Object Country Registration: the country registration of the object inspected</li>
          <li>Status: the status of the report</li>
          <li>
            Business Rules Triggered (AND): find the Business Rules Triggered for each report
            messages received (must be triggered all the Business Rules selected)
          </li>
          <li>
            Business Rules Triggered (OR): find the Business Rules Triggered for each report
            messages received (must be triggered at least one of the Business Rules selected)
          </li>
        </ul>
        <p />
        Between each filter a logical AND is applied (the reports must match all filters to
        be displayed) and a logical OR is applied inside each filter when it is multi-valued
        or multi-choices.
      </p>
      <p>
        <img src={availableFilters} alt="availableFilters" />
      </p>
    </>
  ),
});
const addNewFilter = (t = identity) => ({
  title: 'Add new filter',
  body: (
    <>
      <p>
        Click on the &quot;
        {t(tSchema.common.addFilter)}
        &quot; dropdown to display the list of
        available filters.
      </p>
      <p>
        <img src={addFilter} alt="addFilter" />
      </p>
      <p>
        Select the filter you want to add to the list of applied filters.
      </p>
      <p>
        <img src={selectISR} alt="selectISR" />
      </p>
      <p>
        You can type the filter name in the search box to narrow down the
        list of available filters.
      </p>
      <p>
        <img src={selectFilteredISR} alt="selectFilteredISR" />
      </p>
      <p>
        The new filter is added to the list of applied filters.
      </p>
      <p>
        <img src={newFilterISR} alt="newFilterISR" />
      </p>
      <p>
        Multiple filters can be added to the search.
      </p>
    </>
  ),
});
const clearFilters = (t = identity) => ({
  title: 'Clear filters',
  body: (
    <>
      <p>
        <img src={clear} alt="clear" />
      </p>
      <p>
        Click on the &quot;
        {t(tSchema.common.clearFilters)}
        &quot; button to clear all filters currently applied.
      </p>
    </>
  ),
});
const applyFilters = (t = identity) => ({
  title: 'Apply filters',
  body: (
    <>
      <p>
        <img src={apply} alt="apply" />
      </p>
      <p>
        Click on the &quot;
        {t(tSchema.common.applyFilters)}
        &quot; button to update the list of filters applied.
      </p>
    </>
  ),
});
const removeFilter = (t = identity) => ({
  title: 'Remove filter',
  body: (
    <>
      <p>
        To remove a filter, uncheck the checkbox next to the filter name,
        and click on the &quot;
        {t(tSchema.common.applyFilters)}
        &quot; button.
      </p>
      <img src={singleFilterISR} alt="singleFilterISR" />

    </>
  ),
});

const Filters = () => {
  const { t } = useTranslation();

  return (
    <div className="subSection">
      <AccordionSection sections={[
        general(),
        addNewFilter(t), removeFilter(t), applyFilters(t), clearFilters(t),
      ]}
      />
    </div>
  );
};

export default Filters;
