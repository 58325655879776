import PropTypes from 'prop-types';
import { remove } from 'ramda';
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';

import IconSvg from '@components/IconSvg';
import MultiSelectValues from '@components/MultiSelectValues';
import './style.scss';

const CustomActionMultiSelect = ({
  values = [], onRemoveSelection, label,
  onActionButtonClick,
  onChange,
  disabled,
  placeholder,
  isValid,
  isInvalid,
  valueMap,
}) => {
  const removeValue = useCallback((index) => {
    onRemoveSelection && onRemoveSelection(values[index]);
    onChange(remove(index, 1, values));
  }, [values, onRemoveSelection]);
  return (
    <div className={`custom-action-multi-select ${
      [isInvalid && 'is-invalid',
        isValid && 'is-valid',
        disabled && 'disabled',
      ].filter(Boolean).join(' ')}`}
    >
      <div className="values">
        <MultiSelectValues
          values={valueMap ? values?.map(valueMap) : values}
          onRemoveSelection={removeValue}
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          onClickPlaceholder={onActionButtonClick}
        />
      </div>
      {!disabled
    && (
      <>
        <div className="button-separation" />
        <Button
          className="add-button d-flex align-items-center justify-content-center"
          onClick={onActionButtonClick}
        >
          <IconSvg name="add" className="add-icon" />
        </Button>
      </>
    )}
    </div>
  );
};

CustomActionMultiSelect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemoveSelection: PropTypes.func,
  onActionButtonClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  valueMap: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

CustomActionMultiSelect.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  isValid: false,
  isInvalid: false,
  onRemoveSelection: null,
};

export default CustomActionMultiSelect;
