import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  Form,
  Button, Col, Row, Container,
} from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import useRecurrence from '@pages/ExternalSources/hooks/useRecurrence';
import useSource from '@pages/ExternalSources/hooks/useSource';

import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

import * as serviceApi from '@services/externalSources/api';

import { millisToHourMinutes } from '@common/dateUtils';
import { requestWithMessage } from '@common/utilities/Notification';

const schema = tSchema.dmaAdmin.externalSources.editView;

const getOptions = (t) => [
  { code: 'Active', description: t(schema.statusOptions.active) },
  { code: 'Enabled', description: t(schema.statusOptions.enabled) },
  { code: 'Inoperative', description: t(schema.statusOptions.inoperative) },
];

const ExternalSourceModal = ({
  readOnly, attributes, onClose = () => {}, onExecute = () => {},
}) => {
  const { t } = useTranslation();

  const { recurrence } = useRecurrence();
  const { source, isLoading } = useSource(attributes?.externalSource);

  const onUpdateSource = useCallback(
    async (sourceToUpdate, setSubmiting) => {
      await requestWithMessage({
        request: () => serviceApi.updateExternalSource(sourceToUpdate),
        successMessage: 'External source updated successfully',
        errorMessage: 'Error updating external source',
        onSuccess: onClose,
        setLoading: setSubmiting,
      });
    },
    [],
  );

  const options = useMemo(() => getOptions(t), [t]);

  return isLoading ? (
    <Container className="d-flex justify-content-center align-content-center">
      <div className="loading-div align-self-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">{t(tSchema.common.loading)}</span>
        </div>
      </div>
    </Container>
  )
    : (
      <Formik
        initialValues={{
          ...source,
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => onUpdateSource(values, setSubmitting)}
      >
        {
          ({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>

              <div id="vesselEditor" style={{ marginLeft: '1.5%', marginRight: '1.5%' }}>

                <div className="row row-form-text text-modal pt-4" style={{ alignItems: 'baseline' }}>
                  <Col>
                    <Form.Group className="col">

                      <Form.Label>
                        {t(schema.status)}
                      </Form.Label>
                      <Form.Control
                        name="status"
                        as="select"
                        onChange={handleChange}
                        value={values.status}
                        disabled={readOnly}
                      >
                        {
                        options.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.description}
                          </option>
                        ))
                      }
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="col">
                      <br />
                      <Form.Label>
                        {t(schema.priority)}
                      </Form.Label>
                      <Form.Control
                        name="priority"
                        value={values.priority}
                        type="number"
                        onChange={handleChange}
                        disabled={readOnly}
                      />
                    </Form.Group>
                  </Col>
                  <Form.Group className="col">
                    <Form.Row>
                      <Form.Group as={Col} controlId="externalSource.startDate">
                        <Form.Label>
                          {t(schema.startingDate)}
                        </Form.Label>
                        <InputDate
                          name="startingDate"
                          id="startingDate"
                          value={values.startingDate}
                          disabled={readOnly}
                          maxDate={values.endDate}
                          onChange={(id, value) => handleChange({ target: { name: id, value } })}
                          complex={false}
                          hasAction={false}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="externalSourceExecutionTime">
                        <Form.Label>
                          {t(schema.executionTime)}
                        </Form.Label>
                        <TimePicker
                          name="executionTime"
                          value={values.executionTime}
                          start="00:00"
                          end="23:59"
                          step={15}
                          onChange={(value) => setFieldValue('executionTime', `${millisToHourMinutes(value)}:00`)}
                          type="text"
                          disabled={readOnly}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row style={{ marginTop: '2.5%' }} className="inline">
                      <Form.Label column lg={3}>
                        {t(schema.recurrence)}
                      </Form.Label>
                      <Col>
                        <ReactSelect
                          options={recurrence || []}
                          name="recurrence"
                          value={values.recurrence}
                          onChange={(value) => setFieldValue('recurrence', value)}
                          getOptionLabel={(option) => option.description}
                          getOptionValue={(option) => option.id}
                          isDisabled={readOnly}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row style={{ marginTop: '2.5%' }} className="inline">
                      <Form.Label column lg={3}>
                        {t(schema.endDate)}
                      </Form.Label>
                      <Col>
                        <InputDate
                          name="endDate"
                          id="endDate"
                          value={values.endDate}
                          disabled={readOnly}
                          minDate={values.startingDate}
                          onChange={(id, value) => handleChange({ target: { name: id, value } })}
                          complex={false}
                          hasAction={false}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                {!readOnly && (
                <Row className="justify-content-end mr-2 mt-4">
                  <Button type="button" onClick={() => onClose()} className="mr-2">
                    {t(tSchema.common.cancel)}
                  </Button>
                  <Button type="button" className="mr-2" onClick={() => onExecute(values)}>
                    {t(tSchema.common.execute)}
                    {' '}
                    now
                  </Button>
                  <Button type="submit">
                    {t(tSchema.common.save)}
                  </Button>
                </Row>
                )}
                <div className="formerValue" style={{ paddingTop: '2%' }}>
                  {values.externalSource === 'EUFLEET' && !readOnly && 'The synchronisation date can not be edited because the synchronisation with EUFLEET takes place every day'}
                </div>
              </div>
            </Form>
          )
}
      </Formik>
    );
};

ExternalSourceModal.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  attributes: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
};

export default ExternalSourceModal;
