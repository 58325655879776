import {
  Col, Row,
} from 'react-bootstrap';

import FieldGroup from '@components/Form';
import TextInputField from '@components/TextInputField';

import { RELATED_FISHING_TRIP_PROPTYPES } from '../../constants';

import { formatDateTime } from '@common/dateUtils';

const RelatedFishingTrip = ({ relatedFishingTrip }) => (
  <div id="related-fishing-trip-modal">
    <Row>
      <Col sm={6} xs={12}>
        <TextInputField label="type" value={relatedFishingTrip?.type} />
        <TextInputField label="Identification" value={relatedFishingTrip?.euTripId} />
      </Col>
      <Col sm={6} xs={12}>
        <FieldGroup label="Delimited Period">
          <TextInputField label="Start Date" value={formatDateTime(relatedFishingTrip?.startDate)} />
          <TextInputField label="End Date" value={formatDateTime(relatedFishingTrip?.endDate)} />
          <TextInputField label="Duration (days)" value={relatedFishingTrip?.duration} />
        </FieldGroup>
      </Col>
    </Row>
  </div>
);

RelatedFishingTrip.propTypes = {
  relatedFishingTrip: RELATED_FISHING_TRIP_PROPTYPES.isRequired,
};

export default RelatedFishingTrip;
