import { isNil } from 'ramda';

import { get, post, put } from '../utils';

export function apiFetchBusinessRules() {
  return (pageRequest, pageSize, searchFilters, orderBy) => {
    const filter = searchFilters ? `/${searchFilters}` : '';
    const order = isNil(orderBy) || (isNil(orderBy.field) && isNil(orderBy.order)) ? '' : `/${orderBy.field}:${orderBy.order}`;
    const pagination = `${
      order ? ',' : '/'
    }pageNumber=${pageRequest},pageLimit=${pageSize}`;
    return get()(`businessrules/getAll${filter}${order}${pagination}`);
  };
}

export function apiFetchBusinessRule() {
  return (id) => get()(`businessrules/get/${id}`);
}

export function apiDownloadTableData() {
  return (searchFilters, type) => {
    const filter = searchFilters ? `/${searchFilters}` : '';
    return get()(`businessrules/export/${type}${filter}`, {}, { responseType: 'text' });
  };
}

export const apiSaveBusinessRule = (rule) => (rule.id
  ? put()('businessrules/update', rule)
  : post()('businessrules/create', rule));

export function apiFetchBusinessRulesOptions() {
  return (option) => {
    const { option: optionName, domain } = option;
    const domainOnUrl = domain ? `/${domain}` : '';
    return get()(`${optionName || option}/get${domainOnUrl}`);
  };
}

export const apiToggleRuleActivation = (rule) => put()(`businessrules/activation/${rule.id}`);

export function apiToCheckCode() {
  return (code, startDate, endDate) => get()('businessrules/validate', code, startDate, endDate);
}

export default {
  apiFetchBusinessRules,
  apiToCheckCode,
  apiFetchBusinessRule,
  apiDownloadTableData,
  apiSaveBusinessRule,
  apiFetchBusinessRulesOptions,
  apiToggleRuleActivation,
};
