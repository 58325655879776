import { FAVOURITES } from './favourites';
import { SEARCH } from './search';

const LOGBOOK_SUBSECTIONS = {
  SEARCH: {
    title: 'Search',
    body: SEARCH,
  },
  FAVOURITES: {
    title: 'Favourites',
    body: FAVOURITES,
  },
};

export default LOGBOOK_SUBSECTIONS;
