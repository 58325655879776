import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Modal, Container, Row, Form, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import {
  formatFormFields, getInitialValues, orderColumns, schemaValidation,
} from './utils';

const ModalAddEditReferenceAttribute = ({
  formFields = [],
  formValues = {},
  isEdit = false,
  isLoading = false,
  onClose: handleCloseModal,
  onSubmitForm: handleSubmitForm,
  showModal,
  title,
}) => {
  const { t } = useTranslation();

  const initialFormValues = getInitialValues(formValues);

  return (
    <Modal
      backdrop="static"
      id="add-new-line-modal"
      show={showModal}
      onHide={handleCloseModal}
      centered
      contentClassName="custom-modal-with-border"
    >
      <Container>
        <Modal.Header className="px-0" closeButton>
          <Modal.Title><h3 style={{ marginBottom: '0.625rem' }}>{title}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleSubmitForm}
            validationSchema={schemaValidation}
            enableReinitialize
          >
            {({
              values, handleSubmit, handleChange, errors, touched,
            }) => (
              <>
                {(formatFormFields(formFields).sort(orderColumns).map(({
                  editable, name, dataType, code,
                }) => (name !== 'source') && (
                <Row>
                  <Form.Group
                    className="input-full"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Form.Label className="label-modal" style={{ marginRight: '0.625rem', textTransform: 'capitalize', minWidth: '100px' }}>
                      {name}
                    </Form.Label>
                    <Form.Control
                      type={dataType}
                      name={code}
                      value={values[code]}
                      onChange={handleChange}
                      disabled={isEdit && !editable}
                      isValid={touched[code] && !errors[code]}
                      isInvalid={touched[code] && errors[code]}
                      min={code === 'endDate' ? values.startDate : null}
                      max={code === 'startDate' ? values.endDate : null}
                    />
                  </Form.Group>
                </Row>
                )))}
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ marginRight: '0.25rem' }}
                    onClick={handleCloseModal}
                    disabled={isLoading}
                  >
                    {t(tSchema.common.cancel)}
                  </Button>
                  <Button disabled={isLoading} onClick={handleSubmit}>{isEdit ? 'Confirm' : 'Add'}</Button>
                </Row>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Container>
    </Modal>
  );
};
ModalAddEditReferenceAttribute.propTypes = {
  formFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  isEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModalAddEditReferenceAttribute;
