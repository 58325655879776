import tSchema from '@lang/schema';

const schema = tSchema.isrViewer;

export default function formatChecked(checked, t) {
  switch (checked) {
    case 'YES':
      return t(schema.checked);
    case 'Y':
      return t(schema.yes);
    case 'NO':
      return t(schema.notChecked);
    case 'N':
      return t(schema.no);
    case 'NOT_APPLICABLE':
      return 'N/A';
    case null:
      return '';
    default:
      return checked;
  }
}
