import { any, propEq } from 'ramda';

const getResponseStatus = (options) => {
  const allOk = !any(propEq('ok', false))(options);
  if (allOk) {
    return ({ allOk, options });
  }
  return ({ allOk, options: null });
};

export default getResponseStatus;
