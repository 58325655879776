import tSchema from '@lang/schema';

import formatChecked from '../utils';

const schema = tSchema.isrViewer;

function getTableConfig(t) {
  return [
    {
      text: t(schema.schemeId),
      code: 'schemeId',
      render: (row) => (row.inspectorSchemeID && row.inspectorID
        ? `${row.inspectorSchemeID} - ${row.inspectorID}` : row.inspectorSchemeID || row.inspectorID),
    },
    { text: t(schema.nationality), code: 'nationality' },
    { text: t(schema.name), code: 'name' },
    { text: t(schema.role), code: 'role' },
    {
      text: t(schema.cardPresented),
      code: 'cardPresented',
      render: (row) => (formatChecked(row.cardPresented, t)),
    },
  ];
}

export default getTableConfig;
