import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Image, Modal,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import IconSvg from '@components/IconSvg';

import {
  assignImage, changeComplexLocal, removeComplexRow, uploadImage, changeComplexVesselInformation,
} from '@services/vessel_record/actions/vessel';

import InputSelect from '../../../components/Form/Inputs/InputSelect';

import VesselInput from './inputs/VesselInput';
import RemoteImage from './RemoteImage';

import { showWarningNotification } from '@common/utilities/Notification';
import { clearEventTargetValue, fromKBToMB, MAX_MB_SIZE_FILES } from '@common/utils';

const VesselImageItem = ({
  attribute, value, index, visualization,
}) => {
  const dispatch = useDispatch();
  const image = useSelector((state) => state.vessel.image);
  const template = useSelector((state) => state.vessel.template);
  const [clicked, setClicked] = useState(false);
  const [zoom, setZoom] = useState(false);

  const isPNGorJPEG = (file) => file.type === 'image/jpeg' || file.type === 'image/png';

  const inputOpenFileRef = React.createRef();

  const setImage = (data) => {
    dispatch(changeComplexLocal('vesselImage', 'image', index, data));
  };

  useEffect(() => {
    inputOpenFileRef.current.accept = '.jpg,.png';
  }, []);

  useEffect(() => {
    if (clicked && image !== '') {
      dispatch(assignImage(index));
      setClicked(false);
    }
  }, [image]);

  return (
    <div className={[value.image && 'image-flex', 'vessel-image'].filter(Boolean).join(' ')}>
      {value.photo?.id
        && (
          <div style={{ width: '15rem' }}>
            <button className="image-zoom-button" type="button" onClick={() => setZoom(true)}>
              <RemoteImage
                src={value.photo.id}
                setData={setImage}
                data={value.image}
                width="100%"
                height="auto"
              />
            </button>
            <Modal
              show={zoom}
              onHide={() => setZoom(false)}
              centered
              dialogClassName="image-zoomed"
            >
              <Image src={value.image} />
            </Modal>
          </div>
        )}
      <div className="image-form">
        <Form.Group className={`${visualization !== 'VIEW' ? 'input-one-third' : 'input-two-thirds'} lineMarginTop`}>
          <Form.Label className="label-modal">
            {attribute.simpleAttributes[0].displayName}
          </Form.Label>
          <InputSelect
            id={attribute.simpleAttributes[0].name}
            visualization={visualization}
            readOnly={visualization === 'VIEW'}
            onChange={changeComplexVesselInformation}
            hasAction
            complex
            complexName="vesselImage"
            index={index}
            template={template}
            value={value?.photoType}
            options={template?.formData?.vesselPhotoType}
          />
        </Form.Group>
        <Form.Group className={`${visualization !== 'VIEW' ? 'input-one-quarter' : 'input-one-third'} lineMarginTop`}>
          <Form.Label className="label-modal">
            {attribute.simpleAttributes[2].displayName}
          </Form.Label>
          <VesselInput
            visualization={visualization}
            inputType={attribute.simpleAttributes[2].simpleDataType.toLowerCase()}
            attribute={attribute.simpleAttributes[2]}
            template={template}
            complex
            complexValue={value}
            complexName={attribute.name}
            complexIndex={index}
            maxDate={new Date().toISOString().split('T')[0]}
          />
        </Form.Group>
        <Form.Control
          type="file"
          ref={inputOpenFileRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files[0] != null) {
              setClicked(true);

              const sizeInMB = fromKBToMB(e.target.files[0].size);
              if (sizeInMB > MAX_MB_SIZE_FILES) {
                showWarningNotification('Image is too big. File size must be less than 10MB');
              } else if (!isPNGorJPEG(e.target.files[0])) {
                showWarningNotification('Image extension must be PNG or JPEG.');
              } else {
                dispatch(changeComplexLocal('vesselImage', 'fileName', index, e.target.files[0].name));
                dispatch(uploadImage(e.target.files[0]));
              }
            }
          }}
          // Allow to select the same file twice
          onClick={clearEventTargetValue}
        />
        {visualization !== 'VIEW' ? (
          <>
            {value.fileName
              ? (
                <div title={`${value.fileName}`} className="inline-filename">
                  <b>
                    {value.fileName}
                  </b>
                </div>
              ) : null}
            <Button
              onClick={() => inputOpenFileRef.current.click()}
              className="inline-button image-button"
            >
              {value.photo?.id ? 'Change image' : 'Select an image'}
            </Button>
            <Button
              onClick={() => dispatch(removeComplexRow(attribute.name, index))}
              className="inline-button"
            >
              <IconSvg name="delete" className="no-margin" />
            </Button>
          </>
        ) : null}
        <Form.Group className="input-full lineMarginTop">
          <Form.Label className="label-modal">
            {attribute.simpleAttributes[1].displayName}
          </Form.Label>
          <VesselInput
            visualization={visualization}
            inputType={attribute.simpleAttributes[1].simpleDataType.toLowerCase()}
            attribute={attribute.simpleAttributes[1]}
            template={template}
            complex
            complexValue={value}
            complexName={attribute.name}
            complexIndex={index}
          />
        </Form.Group>
      </div>
    </div>
  );
};

VesselImageItem.defaultProps = {
  attribute: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  visualization: PropTypes.string,
};

VesselImageItem.propTypes = {
  attribute: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  visualization: PropTypes.string,
};

export default VesselImageItem;
