import navigation from './img/navigation.png';
import pagination from './img/pagination.png';
import resultsPerPage from './img/resultsPerPage.png';

const PAGINATION = (
  <div className="subSection">
    <p>
      Pagination is a mechanism that allows the user to view a subset of the data.
      It is used to navigate through the data.
    </p>
    <img src={pagination} alt="pagination" />
    <p>
      The pagination bar is displayed at the bottom of the grid.
    </p>
    <p>
      The highlighted part of the pagination bar indicates the page number currently displayed.
    </p>
    <ul>
      <li>
        <p>
          The ‘results per page’ field is a dropdown menu that  allows the user to
          select the number of results to be displayed per page.
        </p>
        <p>
          <img src={resultsPerPage} alt="resultsPerPage" />
        </p>
      </li>
      <li>
        <p>
          There is a set of buttons that allows the user to navigate
          through the data.
        </p>
        <p>
          <img src={navigation} alt="navigation" />
        </p>
        <p>
          Click on a page number to navigate to that page.
        </p>
        <p>
          <span className="item">&laquo;</span>
          :
          Click on the &quot;First&quot; button
          to navigate to the first page.
        </p>
        <p>
          <span className="item">&lsaquo;</span>
          :
          Click on the &quot;Previous&quot; button to navigate to the previous page.
        </p>
        <p>
          <span className="item">...</span>
          :
          &quot;...&quot; It indicates that there exists a series of intermediate page numbers
          that are not shown in the bar because of the limited size of the bar.
        </p>
        <p>
          <span className="item">&rsaquo;</span>
          :
          Click on the &quot;Next&quot; button to navigate to the next page.
        </p>
        <p>
          <span className="item">&raquo;</span>
          :
          Click on the &quot;Last&quot; button to navigate to the last page.
        </p>
      </li>

    </ul>
  </div>
);

export default PAGINATION;
