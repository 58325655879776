import i18next from 'i18next';
import { prop } from 'ramda';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import { formatDate, postProcessDate } from '@common/dateUtils';
import { formatSelectOption } from '@common/utils';

const { t } = i18next;

const statusIcon = (status, text) => (
  <OverlayTrigger placement="right" overlay={<Tooltip>{text}</Tooltip>}>
    <div className="statusIcon">
      {status.id === 0 ? 'Yes' : 'No'}
    </div>
  </OverlayTrigger>
);

export const repeatCharacters = (character, ammount) => new Array(ammount).fill(character).join('');
export const padLeft = (str, length, filler) => (str.length < length ? `${repeatCharacters(filler, length - str.length)}${str}` : str);

const severityIcon = (severity, text) => {
  if (severity === undefined) return <></>;

  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{text}</Tooltip>}>
      <div className="severityIcon">
        {severity.id === 0 ? <IconSvg name="error" /> : <IconSvg name="warn" />}
      </div>
    </OverlayTrigger>
  );
};

export const tableConfig = (getValue) => [
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.code),
    code: 'code',
    sortable: true,
    render: (row) => `${row.code.split('_')[0]}`,
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.sublevel),
    code: 'subLevel',
    sortable: true,
    render: (row) => `${padLeft(`${row.subLevel.code}`, 2, '0')} - ${row.subLevel.description}`,
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.owner),
    code: 'owner',
    sortable: true,
    render: (row) => getValue('browner', prop('id')(row.owner)),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.context),
    code: 'context',
    sortable: true,
    render: (row) => getValue('brcontext', prop('id')(row.context)),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.typeOfMessage),
    code: 'typeOfEntity',
    sortable: true,
    render: (row) => getValue('brtypeofentity', prop('id')(row.typeOfEntity)),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.active),
    code: 'status',
    sortable: true,
    render: (row) => statusIcon(row.status, getValue('brstatus', prop('id')(row.status))),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.severity),
    code: 'severity',
    sortable: true,
    render: (row) => severityIcon(
      row.severity,
      getValue('brseverity', prop('id')(row.severity)),
    ),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.startDate),
    code: 'startDate',
    sortable: true,
    render: (row) => formatDate(row.startDate),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.endDate),
    code: 'endDate',
    sortable: true,
    render: (row) => formatDate(row.endDate),
  },
  {
    text: t(tSchema.dmaAdmin.brEngine.validationBR.description),
    code: 'description',
    sortable: true,
    render: (row) => (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="description-tooltip">{row.description}</Tooltip>}
      >
        <p className="limit-text" style={{ margin: 0 }}>{row.description}</p>
      </OverlayTrigger>
    ),
  },
];

export const configureFilters = (valueOptions, defaultValues) => [
  {
    code: 'code',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.code),
    type: 'text',
  },
  {
    code: 'context',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.context),
    type: 'select',
    options: prop('brcontext')(valueOptions),
    defaultValue: prop('context')(defaultValues),
  },
  {
    code: 'typeOfEntity',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.typeOfMessage),
    type: 'multichoice',
    options: prop('brtypeofentity')(valueOptions),
  },
  {
    code: 'subLevel',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.sublevel),
    type: 'multichoice',
    options: prop('brsublevel')(valueOptions).map((option) => formatSelectOption(option, 'code', 'description')),
  },
  {
    code: 'status',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.status),
    type: 'select',
    options: prop('brstatus')(valueOptions),
  },
  {
    code: 'severity',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.severity),
    type: 'select',
    options: prop('brseverity')(valueOptions),
  },
  {
    code: 'activeOn',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.activeOn),
    type: 'simpledate',
    postProcess: postProcessDate,
  },
  {
    code: 'owner',
    label: t(tSchema.dmaAdmin.brEngine.validationBR.owner),
    type: 'multichoice',
    options: prop('browner')(valueOptions),
  },
];

export const searchOptions = [
  { label: 'contains', value: '=-', type: 'text' },
  { label: 'equal', value: '==' },
  { label: 'not contains', value: '!-', type: 'text' },
  { label: 'begins with', value: '*=', type: 'text' },
  { label: 'ends with', value: '=*', type: 'text' },
  {
    label: 'is null', value: '=null', type: 'text', single: true,
  },
];

export const businessRulesValueOptions = (domain) => [
  'brdomain',
  'brlevel',
  'brsubgroup',
  { option: 'brsublevel', domain },
  'brstatus',
  'brseverity',
  'field',
  'executionorder',
  'brcontext',
  'browner',
  { option: 'brtypeofentity', domain },
];
