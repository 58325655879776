import { useMemo } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import tSchema from '@lang/schema';

import {
  DEPARTURE_CATCHES_PROPTYPES,
} from '../../constants';
import BasicTable from '../tables/BasicTable';

const schema = tSchema.fishingActivities.logbookViewer.details;

const getTableHeaders = (t) => [
  { text: t(schema.value), code: 'value', sortable: false },
  { text: t(schema.discardReason), code: 'code', sortable: false },
];

const DiscardReasons = ({ discardReasons }) => {
  const { t } = useTranslation();
  const tableHeaders = useMemo(() => getTableHeaders(t), [t]);
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <BasicTable
            data={discardReasons}
            headers={tableHeaders}
          />
        </Col>
      </Row>
    </Container>
  );
};

DiscardReasons.propTypes = {
  discardReasons: DEPARTURE_CATCHES_PROPTYPES.isRequired,
};

export default DiscardReasons;
