import { combineReducers } from 'redux';

import authentication from './authentication/reducers';
import businessRules from './businessRules/reducers';
import externalSources from './externalSources/reducers';
import activityReports from './vessel_record/reducers/activityReports';
import administration from './vessel_record/reducers/administration';
import referenceData from './vessel_record/reducers/referenceData';
import vessel from './vessel_record/reducers/vessel';

export const reducers = combineReducers({
  businessRules,
  authentication,
  externalSources,
  vessel,
  administration,
  referenceData,
  activityReports,
});

export const reactions = [
];

export default {
  reducers,
};
