/* eslint-disable import/order */
import PropTypes from 'prop-types';
import {
  useCallback, useRef, useState, useEffect, useMemo,
} from 'react';
import {
  Card, Row, Form, Col, Button,
} from 'react-bootstrap';
import './Filters.scss';
import { useTranslation } from 'react-i18next';

import When from '@components/Conditionals/When';
import InputDate, { DATE_TYPE } from '@components/Form/Inputs/InputDate';
import tSchema from '@lang/schema';

import InputSelect from '../../components/Form/Inputs/InputSelect';
import IconSvg from '../../components/IconSvg/IconSvg';

import {
  DEFAULT_FILTERS, getFormDataSelectOptions,
  SEARCH_TYPES, showTypeValue, TYPES, formatFiltersToChoose,
  selectFilterCustomSort, hasValueForHidden, isContainsField, isExactField,
} from './FiltersUtils';
import { showWarningNotification } from '@common/utilities/Notification';

const Filters = ({
  searchableAttributes,
  formDataList,
  pagination,
  hasDefaultFilters = true,
  onUpdateSearchFilters,
}) => {
  const { t } = useTranslation();

  const keyPressHold = useRef(false);
  const [filtersHidden, setFiltersHidden] = useState(true);
  const [isSearchPeriod, setIsSearchPeriod] = useState(false);
  const [isSearchOnDate, setIsSearchOnDate] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState(DEFAULT_FILTERS);
  const [attributeFilters, setAttributeFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({
    text: '',
    status: [],
    formFields: [],
  });
  const [anyFieldFilter, setAnyFieldFilter] = useState(true);
  const [filtersToChoose, setFiltersToChoose] = useState([]);
  const [isClearing, setIsClearing] = useState(false);

  const showTypeValueWithFormData = showTypeValue(formDataList, t);

  const filterChooseFilters = () => {
    // Filtra los filtros del desplegable para que no aparezcan los mismos
    // que se están mostrando
    const chooseFilters = searchableAttributes.filter(
      (attr) => !attributeFilters.map((f) => f.name).includes(attr.name),
    ).filter(
      (attr) => !defaultFilters.formFields.map((f) => f.name).includes(attr.name),
    );
    setFiltersToChoose(chooseFilters);
  };

  const addAttributeFilter = (value, setDefaultValue = false) => {
    const filter = searchableAttributes.find((attr) => attr.name === value);
    const { formData } = filter.customProperties || {};
    const attrFilterValue = setDefaultValue && formData && formDataList[formData]
      ? formDataList[formData][0].code.toString() : '';
    const searchType = SEARCH_TYPES.EXACT;
    setAttributeFilters([
      ...attributeFilters,
      {
        inverted: false,
        name: filter.name,
        searchType,
        type: formData ? filter.simpleDataType : TYPES.STRING,
        value: attrFilterValue,
      },
    ]);
  };

  const deleteAttributeFilter = (value) => {
    setAttributeFilters(
      attributeFilters.filter((attr) => attr.name !== value),
    );
  };

  useEffect(() => {
    filterChooseFilters();
  }, [searchableAttributes, attributeFilters]);

  const toggleCollapsed = useCallback(() => {
    setFiltersHidden(!filtersHidden);
  });

  const handleChangeDefaultFilters = (id, value) => {
    switch (id) {
      case 'checkAnyField':
        setDefaultFilters((prevState) => ({ ...prevState, text: '' }));
        break;
      case 'checkSearchPeriod':
        setDefaultFilters((prevState) => (
          { ...prevState, dateFrom: '', dateTo: '' }));
        break;
      case 'anyField':
        setDefaultFilters((prevState) => ({ ...prevState, text: value }));
        break;
      case 'includeInactive':
        // Si se incluyen inactivos, el filtro active se elimina
        value
          ? setDefaultFilters((prevState) => ({ ...prevState, formFields: prevState.formFields.filter((f) => f.name !== 'status') }))
          : setDefaultFilters((prevState) => ({
            ...prevState,
            formFields: [...prevState.formFields, {
              inverted: false,
              name: 'status',
              searchType: SEARCH_TYPES.EXACT,
              type: TYPES.STRING,
              value: 'Active',
            }],
          }));
        break;
      case 'searchPeriod':
        value
          ? setDefaultFilters((prevState) => (
            { ...prevState, dateFrom: value, dateTo: value }))
          : setDefaultFilters((prevState) => (
            { ...prevState, formFields: [] }));
        break;
      default:
        break;
    }
  };

  const handleChangeFilters = (id, searchType, type, value, rangeMin, rangeMax) => {
    setAttributeFilters(attributeFilters.map((attr) => {
      if (attr.name === id) {
        if (rangeMin !== null || rangeMax !== null) {
          return {
            inverted: false,
            name: id,
            searchType: searchType || attr.searchType,
            type,
            value: value || '',
            range: [rangeMin, rangeMax],
          };
        }
        return {
          inverted: false,
          name: id,
          searchType: searchType || attr.searchType,
          type,
          value: value || '',
        };
      }
      return attr;
    }));
  };
  const formatFiltersRange = (filters) => filters.map((attr) => {
    /*
      Para los filtros de Smaller Than o Greater Than UNIDATA utiliza el filtro de Rango y,
      por lo tanto, incluye los valores extremos.
      Es por ello que le restamos una parte al valor que
      ingrese el usuario para que muestre los datos correctamente.
    */
    if (attr.searchType === SEARCH_TYPES.RANGE) {
      if (attr.range[0] === null || attr.range[1] === null) {
        return {
          ...attr,
          range: [attr.range[0] !== null
            ? parseFloat(attr.range[0]) + 0.0000000001 : attr.range[0],
          attr.range[1] !== null ? parseFloat(attr.range[1]) - 0.0000000001 : attr.range[1]],
        };
      }
    }
    return attr;
  });
  const fetchVesselsWithFilters = (event) => {
    if (isSearchPeriod && defaultFilters.text === '' && attributeFilters.length === 0) {
      event?.preventDefault();
      showWarningNotification("The Search on period filter can't be launched alone and must be combined with other filter");
    } else {
      const filters = defaultFilters;
      event?.preventDefault();
      const formattedFilters = formatFiltersRange(attributeFilters);
      onUpdateSearchFilters({
        page: 1,
        count: pagination.size,
        filtersBody: {
          ...filters,
          formFields: [
            ...filters.formFields,
            ...formattedFilters,
          ],
        },
        isSearchPeriod,
        isSearchOnDate,
      });

      setAppliedFilters({
        ...filters,
        status: [...filters.formFields],
        formFields: [
          ...attributeFilters,
        ],
      });
      setFiltersHidden(true);
      event?.stopPropagation();
    }
  };

  const resetFilters = useCallback(() => {
    setDefaultFilters(DEFAULT_FILTERS);
    setAttributeFilters([]);
    setAnyFieldFilter(true);
    setIsSearchPeriod(false);
    setIsClearing(true);
  }, [DEFAULT_FILTERS]);

  useEffect(() => {
    if (isClearing === true) {
      fetchVesselsWithFilters();
      setIsClearing(false);
    }
  }, [isClearing]);

  const newFilterOptions = useMemo(() => formatFiltersToChoose(filtersToChoose), [filtersToChoose]);

  const handleNewFilter = useCallback((id, value) => {
    addAttributeFilter(value);
  }, [addAttributeFilter]);
  const handleAttributeFilter = useCallback((value, filter, attribute) => {
    handleChangeFilters(
      filter.name,
      SEARCH_TYPES.EXACT,
      attribute.simpleDataType,
      value,
    );
  }, [addAttributeFilter]);

  const handleCheckSearchPeriod = useCallback(
    (e) => {
      setIsSearchPeriod(e.target.checked);
      setIsSearchOnDate(false);
    },
    [],
  );
  const handleChangeSearchPeriod = useCallback(
    (e) => {
      const isFullHistory = e.target.value === 'fullHistory';
      setIsSearchOnDate(!isFullHistory);
      isFullHistory ? handleChangeDefaultFilters('searchPeriod') : handleChangeDefaultFilters('searchPeriod', DEFAULT_FILTERS.dateFrom);
    },
    [DEFAULT_FILTERS],
  );

  const handleCheckAnyField = useCallback(
    (e) => {
      setAnyFieldFilter(e.target.checked);
      handleChangeDefaultFilters('checkAnyField', e.target.checked);
    },
    [],
  );

  const handleChangeAnyField = useCallback((e) => { handleChangeDefaultFilters('anyField', e.target.value); }, []);

  // filter handlers
  const handleTextInputChange = (filter) => (e) => {
    handleChangeFilters(
      filter.name,
      // eslint-disable-next-line no-nested-ternary
      e.target.value && !isExactField(filter.name)
        ? (isContainsField(filter.name) ? SEARCH_TYPES.CONTAINS : SEARCH_TYPES.FUZZY)
        : SEARCH_TYPES.EXACT,
      TYPES.STRING,
      e.target.value || '',
    );
  };

  return (
    <Card body id="filter-section" className="mb-2">
      <Row>
        <>
          <div
            className="cursor-pointer m-2"
            onClick={toggleCollapsed}
            onKeyPress={(ev) => {
              if (ev.key === ' ' || ev.key === 'Enter') {
                ev.preventDefault();
                !keyPressHold.current && toggleCollapsed();
              }
              keyPressHold.current = true;
            }}
            onKeyUp={() => {
              keyPressHold.current = false;
            }}
            role="button"
            tabIndex={0}
          >
            <span className="m-2">{t(tSchema.common.filters)}</span>
            {filtersHidden ? <IconSvg name="caretDown" /> : <IconSvg name="caretUp" />}
          </div>
          <div className="options">
            {filtersHidden && (
              <div className="vessels-filters-summary">
                {appliedFilters.text !== '' && (
                  <div key="anyField">
                    {`Vessel Identifier contains "${appliedFilters.text}"`}
                  </div>
                )}
                {isSearchPeriod && (
                  <div key="fullHistory">
                    {`Search period
                      ${isSearchOnDate ? `on date ${new Date(appliedFilters.dateFrom).toLocaleDateString()}` : 'in full history'}`}
                  </div>
                )}
                {appliedFilters.formFields && appliedFilters.formFields.map((filter) => {
                  const attribute = searchableAttributes.find(
                    (attr) => hasValueForHidden(attr, filter),
                  );
                  if (attribute) {
                    return (
                      <div key={filter.name}>
                        {`${attribute.displayName} ${showTypeValueWithFormData(filter, attribute)}`}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
            <div
              className="clear-filters"
              onClick={() => resetFilters()}
              onKeyPress={(ev) => {
                if (ev.key === ' ' || ev.key === 'Enter') {
                  ev.preventDefault();
                  !keyPressHold.current && resetFilters();
                }
                keyPressHold.current = true;
              }}
              onKeyUp={() => {
                keyPressHold.current = false;
              }}
              role="button"
              tabIndex={0}
            >
              <p>{t(tSchema.common.clearFilters)}</p>
            </div>
          </div>
        </>
      </Row>
      <Row
        as={Form}
        hidden={filtersHidden}
        className="mb-3"
        onSubmit={fetchVesselsWithFilters}
      >
        <Col id="filter-list-seccion">
          { /* Filtros por defecto + Map de los filtros */ }
          <When condition={hasDefaultFilters}>
            <Row key="anyField" style={{ marginBottom: '1vh' }}>
              <Form.Group as={Col} controlId="anyFieldEnabled" style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Check
                  type="checkbox"
                  label={t(tSchema.vesselMGMT.vesselIdentifier)}
                  checked={anyFieldFilter}
                  name="anyField"
                  onChange={handleCheckAnyField}
                />
                <Form.Control
                  type="text"
                  name="anyField-enabled"
                  className="filter-row-item"
                  value={defaultFilters.text}
                  onChange={handleChangeAnyField}
                />
              </Form.Group>
            </Row>
            <Row key="searchPeriod" style={{ marginBottom: '1vh' }}>
              <Form.Group as={Col} controlId="searchPeriodEnabled" style={{ display: 'flex', alignItems: 'center', height: '2.375rem' }}>
                <Form.Check
                  type="checkbox"
                  label="Search Period"
                  checked={isSearchPeriod}
                  name="searchPeriod"
                  onChange={handleCheckSearchPeriod}
                />
                {isSearchPeriod && (
                  <Form.Control
                    as="select"
                    name="searchPeriodSelect"
                    className="filter-row-item"
                    defaultValue="fullHistory"
                    onChange={handleChangeSearchPeriod}
                  >
                    <option value="fullHistory">
                      Full history
                    </option>
                    <option value="date">
                      Search on Date
                    </option>
                  </Form.Control>
                )}
                {isSearchOnDate && (
                <InputDate
                  className="filter-row-item"
                  value={defaultFilters.dateFrom}
                  id="searchPeriod"
                  onChange={(filterCode, date) => handleChangeDefaultFilters('searchPeriod', date)}
                  required
                  complex={false}
                  hasAction={false}
                />
                )}
              </Form.Group>
            </Row>
          </When>
          {
            attributeFilters.map((filter) => {
              const attribute = searchableAttributes.find((attr) => attr.name === filter.name);
              const formData = (attribute && attribute.customProperties.formData) || undefined;
              return (
                <Row key={filter.name} style={{ marginBottom: '1vh' }}>
                  <Form.Group as={Col} controlId={filter.name} style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Check
                      type="checkbox"
                      label={
                        (attribute && attribute.displayName) || filter.displayName || filter.name
                      }
                      name={filter.name}
                      defaultChecked
                      onChange={() => {
                        deleteAttributeFilter(filter.name);
                      }}
                    />
                    {
                    // Si es un desplegable
                    formData && (
                    <InputSelect
                      className="attribute-filter-select"
                      value={filter.value}
                      hasAction={false}
                      placeholder={t(tSchema.common.emptyValuePlaceholder)}
                      onChange={(id, value) => handleAttributeFilter(value, filter, attribute)}
                      options={getFormDataSelectOptions(
                        { formData, formDataList },
                      )}
                    />
                    )
                  }
                    {
                    // Si es un string
                    !formData && attribute && attribute.simpleDataType === 'String' && (
                    <Form.Control
                      type="text"
                      className="filter-row-item"
                      value={filter.value}
                      placeholder={t(tSchema.common.emptyValuePlaceholder)}
                      onChange={handleTextInputChange(filter)}
                    />
                    )
                  }
                    {
                    // Si es un number
                    !formData && attribute && (attribute.simpleDataType === 'Number' || attribute.simpleDataType === 'Integer') && (
                      <>
                        <Form.Control
                          as="select"
                          className="filter-row-item"
                          onChange={(e) => {
                            switch (e.target.value) {
                              case SEARCH_TYPES.EXACT:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.EXACT,
                                  attribute.simpleDataType,
                                  undefined,
                                );
                                break;
                              case SEARCH_TYPES.RANGE_GREATHER:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.RANGE,
                                  attribute.simpleDataType,
                                  undefined,
                                  0,
                                  null,
                                );
                                break;
                              case SEARCH_TYPES.RANGE_SMALLER:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.RANGE,
                                  attribute.simpleDataType,
                                  undefined,
                                  null,
                                  0,
                                );
                                break;
                              case SEARCH_TYPES.RANGE:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.RANGE,
                                  attribute.simpleDataType,
                                  undefined,
                                  0,
                                  0,
                                );
                                break;
                              default:
                            }
                          }}
                        >
                          <option value={SEARCH_TYPES.EXACT}>Equals</option>
                          <option value={SEARCH_TYPES.RANGE_GREATHER}>Greather than</option>
                          <option value={SEARCH_TYPES.RANGE_SMALLER}>Smaller than</option>
                          <option value={SEARCH_TYPES.RANGE}>In range</option>
                        </Form.Control>
                        {filter.searchType === SEARCH_TYPES.EXACT && (
                          <Form.Control
                            type="number"
                            className="filter-row-item"
                            value={filter.value}
                            onChange={(e) => {
                              handleChangeFilters(
                                filter.name,
                                SEARCH_TYPES.EXACT,
                                attribute.simpleDataType,
                                e.target.value,
                              );
                            }}
                          />
                        )}
                        {filter.searchType === SEARCH_TYPES.RANGE && filter.range[0] !== null && (
                          <Form.Control
                            type="number"
                            className="filter-row-item"
                            value={filter.range[0]}
                            onChange={(e) => {
                              handleChangeFilters(
                                filter.name,
                                SEARCH_TYPES.RANGE,
                                attribute.simpleDataType,
                                undefined,
                                e.target.value,
                                filter.range[1],
                              );
                            }}
                          />
                        )}
                        {filter.searchType === SEARCH_TYPES.RANGE && filter.range[1] !== null && (
                          <Form.Control
                            type="number"
                            className="filter-row-item"
                            value={filter.range[1]}
                            onChange={(e) => {
                              handleChangeFilters(
                                filter.name,
                                SEARCH_TYPES.RANGE,
                                attribute.simpleDataType,
                                undefined,
                                filter.range[0],
                                e.target.value,
                              );
                            }}
                          />
                        )}
                      </>
                    )
                  }
                    {
                    // Si es una fecha
                    !formData && attribute && attribute.simpleDataType === 'Date' && (
                      <>
                        <Form.Control
                          as="select"
                          className="filter-row-item"
                          onChange={(e) => {
                            switch (e.target.value) {
                              case SEARCH_TYPES.EXACT:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.EXACT,
                                  TYPES.DATE,
                                  '',
                                );
                                break;
                              case SEARCH_TYPES.RANGE_GREATHER:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.RANGE,
                                  TYPES.DATE,
                                  undefined,
                                  '',
                                  null,
                                );
                                break;
                              case SEARCH_TYPES.RANGE_SMALLER:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.RANGE,
                                  TYPES.DATE,
                                  undefined,
                                  null,
                                  '',
                                );
                                break;
                              case SEARCH_TYPES.RANGE:
                                handleChangeFilters(
                                  filter.name,
                                  SEARCH_TYPES.RANGE,
                                  TYPES.DATE,
                                  undefined,
                                  '',
                                  '',
                                );
                                break;
                              default:
                            }
                          }}
                        >
                          <option value={SEARCH_TYPES.EXACT}>Equals</option>
                          <option value={SEARCH_TYPES.RANGE_GREATHER}>Greather than</option>
                          <option value={SEARCH_TYPES.RANGE_SMALLER}>Smaller than</option>
                          <option value={SEARCH_TYPES.RANGE}>In range</option>
                        </Form.Control>
                        {filter.searchType === SEARCH_TYPES.EXACT && (
                        <InputDate
                          key={`${filter.name}exact`}
                          className="input-date filter-row-item"
                          name={`${filter.name}-tempvalue`}
                          id={filter.name}
                          type={filter.dateType || DATE_TYPE.DATE}
                          value={filter.value}
                          onChange={(filterCode, date) => handleChangeFilters(
                            filter.name,
                            SEARCH_TYPES.EXACT,
                            TYPES.DATE,
                            date,
                          )}
                          required={false}
                          complex={false}
                          hasAction={false}
                          maxDate={filter.maxDate}
                          minDate={filter.minDate}
                        />
                        )}
                        {filter.searchType === SEARCH_TYPES.RANGE && filter.range[0] !== null && (
                          <InputDate
                            className="filter-row-item"
                            value={filter.range[0]}
                            onChange={(filterCode, date) => handleChangeFilters(
                              filter.name,
                              SEARCH_TYPES.RANGE,
                              TYPES.DATE,
                              undefined,
                              date,
                              filter.range[1],
                            )}
                            required={false}
                            complex={false}
                            hasAction={false}
                          />
                        )}
                        {filter.searchType === SEARCH_TYPES.RANGE && filter.range[1] !== null && (
                          <InputDate
                            className="filter-row-item"
                            value={filter.range[1]}
                            onChange={(filterCode, date) => handleChangeFilters(
                              filter.name,
                              SEARCH_TYPES.RANGE,
                              TYPES.DATE,
                              undefined,
                              filter.range[0],
                              date,
                            )}
                            required={false}
                            complex={false}
                            hasAction={false}
                          />
                        )}
                      </>
                    )
                  }
                  </Form.Group>
                </Row>
              );
            })
          }
          <Form.Row className="d-flex align-items-end">
            <Form.Group as={Col} controlId="newFilterSelector" className="d-flex d-flex align-items-center justify-content-end">
              <Form.Label style={{ whiteSpace: 'nowrap', marginBottom: 0, marginRight: '1.5vw' }}>
                {t(tSchema.common.addFilter)}
              </Form.Label>
              <InputSelect
                placeholder="Select a filter"
                className="new-filter-selector"
                id="newFilterSelector"
                value={null}
                hasAction={false}
                onChange={handleNewFilter}
                options={newFilterOptions}
                customOrder={selectFilterCustomSort}
              />
              <Button
                id="add-filter"
                variant="primary"
                type="submit"
                className="my-1"
              >
                {t(tSchema.common.applyFilters)}
              </Button>
            </Form.Group>
          </Form.Row>
        </Col>
      </Row>
    </Card>
  );
};

Filters.defaultProps = {
  searchableAttributes: [],
  formDataList: {},
  pagination: PropTypes.instanceOf(Object),
};

Filters.propTypes = {
  hasDefaultFilters: PropTypes.bool.isRequired,
  onUpdateSearchFilters: PropTypes.func.isRequired,
  searchableAttributes: PropTypes.instanceOf(Array),
  formDataList: PropTypes.instanceOf(Object),
  pagination: PropTypes.instanceOf(Object),

};

export default Filters;
