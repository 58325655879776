/* eslint-disable import/order */

import {
  actionCreator, get, downloadFile,
} from '../../../utils';
import { dispatchPromiseWithFile, dispatchPromiseWithMessage } from '@common/utilities/Notification';

const createActionType = actionCreator('CRUD');

const TypeActionsCrud = {
  FETCH_DATA_ACTIVITY: createActionType('FETCH_DATA_ACTIVITY'),
  FETCH_SOURCES_LIST: createActionType('FETCH_SOURCES_LIST'),
  DOWNLOAD_REPORT: createActionType('DOWNLOAD_REPORT'),
  CHANGE_ATTRIBUTE_FILTERS: 'CHANGE_ATTRIBUTE_FILTERS',
};

const fetchDataActivity = (filter = '') => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.FETCH_DATA_ACTIVITY,
    promise: get()(`vrService/activity${filter}`),
    errorMessage: 'Error loading data activity',
  })
);

const fetchSourcesList = () => (
  dispatchPromiseWithMessage({
    type: TypeActionsCrud.FETCH_SOURCES_LIST,
    promise: get()('vrService/externalSources/externalSources'),
    errorMessage: 'Error loading sources',
  })
);

const downloadValidationReport = (filters) => dispatchPromiseWithFile({
  type: TypeActionsCrud.DOWNLOAD_REPORT,
  promise: downloadFile()(`vrService/validationHistory/export?${filters}`),
  errorMessage: 'Error downloading the report',
  statusWarningMessage: {
    204: 'There are no reports for the selected date range',
  },
  fileName: 'Validation Report',
});

const changeAttributeFilters = (filters) => ({
  type: TypeActionsCrud.CHANGE_ATTRIBUTE_FILTERS,
  payload: filters,
});

export {
  TypeActionsCrud,
  fetchDataActivity,
  fetchSourcesList,
  downloadValidationReport,
  changeAttributeFilters,
};
