import actions from './img/actions.JPG';
import editSource from './img/edit_source.JPG';
import eufleetNotEdit from './img/eufleet_not_edit.JPG';

const SectionEditSource = () => (
  <div className="SectionEditSource">
    <p>
      From the external sources grid,
      an external source can be edited by clicking on the corresponding
      button of the column &quot;Actions&quot;,
      and then selecting &quot;Edit&quot;.
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
    <p>
      A modal is opened containing the edition form with
      all the information about the external source:
    </p>
    <p>
      <img src={editSource} alt="Edit External Source" />
    </p>
    <p>
      EUFLEET cannot be edited so the corresponding button
      in the &quot;Actions&quot; column is disabled.
    </p>
    <p>
      <img src={eufleetNotEdit} alt="EUFLEET cannot be edited" />
    </p>
  </div>
);

export default SectionEditSource;
