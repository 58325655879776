/* eslint-disable import/order */

import i18next from 'i18next';
import tSchema from '@lang/schema';
import validationBRCreateButtonImg from './img/validation-br-create-button.png';
import validationBRCreateModalImg from './img/validation-br-create-modal.png';

const { t } = i18next;

const SectionValidationBRCreate = () => (
  <div className="subSection">
    <p>
      To create a business rule, the user must click on the
      &quot;
      {t(tSchema.dmaAdmin.brEngine.validationBR.createBR)}
      &quot; button, displayed below the results grid.
    </p>
    <p>
      <img src={validationBRCreateButtonImg} alt="validationBRCreateButton" />
    </p>
    This button opens a modal with the following related fields:
    <p>
      <img src={validationBRCreateModalImg} alt="validationBRCreateModal" />
    </p>
    Once the user has filled in the form with the new business rule values
    and click on &quot;
    {t(tSchema.common.save)}
    &quot; button, the business rule is created.
  </div>
);

export default SectionValidationBRCreate;
