import { isEmpty } from 'ramda';
import React, {
  useEffect, useState, useCallback,
} from 'react';
import { Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import { apiFetchUserScenarios, apiSaveOnSelectScenario } from '@services/userPreferences/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import { RealmRole } from '@common/keycloakFunctions';
import { requestWithMessage } from '@common/utilities/Notification';
import { DOMAIN } from '@common/utils';

const SelectMultiChoicesScenario = () => {
  const { userPreferences, setUserPreferences } = useUserPreferences();
  const [loading, setLoading] = useState(false);
  const { keycloak } = useKeyCloak();

  const {
    lastActiveFa, lastActiveIsr, domain, logbookFavFilters,
  } = userPreferences;

  const lastActiveValue = userPreferences.domain === DOMAIN.FA ? lastActiveFa : lastActiveIsr;
  const lastActiveLabel = userPreferences.domain === DOMAIN.FA ? 'lastActiveFa' : 'lastActiveIsr';
  const userScenariosLabel = userPreferences.domain === DOMAIN.FA
    ? 'userScenariosFa' : 'userScenariosIsr';

  const onSuccessFetchUserScenarion = useCallback(async (response, menuHasBeenOpened) => {
    const data = await response.json();
    const scenarioExists = data.find(
      (scenario) => scenario.name === lastActiveValue?.name,
    );
    setUserPreferences((prevState) => ({
      ...prevState,
      [userScenariosLabel]: [{ id: 0, name: 'All data' }, ...data],
    }));
    if (menuHasBeenOpened) {
      if (isEmpty(lastActiveValue)) {
        setUserPreferences((prevState) => ({
          ...prevState,
          [lastActiveLabel]: data[0] || '',
        }));
      } else {
        setUserPreferences((prevState) => ({
          ...prevState,
          [lastActiveLabel]: scenarioExists ? lastActiveValue : { id: 0, name: 'All data' },
        }));
      }
    } else {
      setUserPreferences((prevState) => ({
        ...prevState,
        [lastActiveLabel]: scenarioExists ? lastActiveValue : { id: 0, name: 'All data' },
      }));
    }

    if (!scenarioExists) {
      const newUserPreferences = {
        ...{ lastActiveFa, logbookFavFilters, lastActiveIsr },
        [lastActiveLabel]: { id: 0, name: 'All data' },
      };
      apiSaveOnSelectScenario()(newUserPreferences);
    }

    setLoading(false);
  }, [keycloak, RealmRole, lastActiveValue]);
  const onErrorFetchUserScenarion = useCallback(() => setLoading(false), []);

  const loadData = useCallback((menuHasBeenOpened) => {
    setLoading(true);
    requestWithMessage({
      request: () => apiFetchUserScenarios(domain),
      onSuccess: (response) => onSuccessFetchUserScenarion(response, menuHasBeenOpened),
      onError: onErrorFetchUserScenarion,
      errorMessage: 'Error loading the scenarios',
    });
  }, [onSuccessFetchUserScenarion]);

  useEffect(() => {
    if (keycloak.token && userPreferences.domain) {
      loadData();
    }
  }, [userPreferences.domain, keycloak.token]);

  const handleChanged = useCallback((scenariosSelected) => {
    setUserPreferences((preState) => ({
      ...preState,
      [lastActiveLabel]: scenariosSelected === null ? '' : scenariosSelected,
    }));

    const newUserPreferences = {
      ...{ lastActiveFa, logbookFavFilters, lastActiveIsr },
      [lastActiveLabel]: scenariosSelected === null ? '' : scenariosSelected,
    };
    apiSaveOnSelectScenario()(newUserPreferences);
  }, []);

  return (
    <Form className="col-8 justify-content-end form-row" data-testid="scenarioSelect">
      <Form.Group as={Row} className="mt-3 col-6">
        <Form.Label className="my-auto col-4 text-right pr-0 mr-0" htmlFor="selectScenario">Scenarios</Form.Label>
        <ReactSelect
          name="selectScenario"
          inputId="selectScenario"
          closeMenuOnSelect
          value={lastActiveValue}
          options={userPreferences[userScenariosLabel]}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={handleChanged}
          className="pl-0 col-7 ml-1"
          isLoading={loading}
          onMenuOpen={() => loadData(true)}
          placeholder="Select Scenario"
        />
      </Form.Group>
    </Form>
  );
};

export default SelectMultiChoicesScenario;
