import PropTypes from 'prop-types';
import React, {
  useRef, useState, useCallback, useMemo, useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import formatChecked from '../utils';

import getTableConfig from './utils';

import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerDetailsTabInspectionClosure = ({
  selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
}) => {
  const dataTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['inspectionClosureTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.inspectionClosureTab);
        const versionData = getVersionData(data.inspectionClosureTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid,
    selectedRecipient,
    isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <Container
          fluid
          style={{
            paddingBottom: '0.625rem',
          }}
        >
          <div>
            <Row md={3}>
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.actionTaken)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.actionTaken, t)}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.detActionTaken)}
                    :
                  </Form.Label>
                  <p>{tabData.actionTakenDetails}</p>
                </div>
              </Col>
            </Row>
            <Row md={3}>
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.inspInChargeSign)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.inspetorInCharge, t)}
                  </p>
                </div>
              </Col>
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.masterSign)}
                    :
                  </Form.Label>
                  <p>
                    {formatChecked(tabData.masterSignature, t)}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <FieldGroup label={t(tSchema.isrViewer.observComments)}>
            <Container fluid className="isrObservations">
              <DataTable
                rowData={tabData.observationAndComments}
                idColumn="validityDate"
                tableId="isrObservationsTable"
                ref={dataTableRef}
                tableConfig={tableConfig}
                loading={loading}
                showPagination={false}
              />
            </Container>
          </FieldGroup>
        </Container>
      )}
    </>
  );
};

IsrViewerDetailsTabInspectionClosure.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabInspectionClosure;
