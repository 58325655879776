import burguerButton from './img/burguer_button.JPG';

const Burguer = (
  <div className="subSection">
    <p>
      The Menu of the application, located on the left of the screen,
      can be hidden or shown using the &quot;Burguer button&quot;,
      highlighted in red in the picture below.
    </p>
    <img src={burguerButton} alt="burguer button" />
  </div>
);

export default Burguer;
