import PropTypes from 'prop-types';
import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router, Redirect, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ActivityReports from '@pages/ActivityReports';
import Login from '@pages/Authentication/Login';
import Logout from '@pages/Authentication/Logout';
import BusinessRulesViewer from '@pages/BusinessRules';
import EfcaRulesViewer from '@pages/EfcaRules';
import ExternalSource from '@pages/ExternalSources';
import FailoverViewer from '@pages/FailoverViewer';
import TripLogbookViewer from '@pages/FishingTripsLogbook';
import Home from '@pages/Home';
import IsrViewer from '@pages/IsrViewer';
import MessageViewer from '@pages/Messages';
import QuerySubscriptionsViewer from '@pages/QuerySubscriptions';
import ReferenceViewer from '@pages/ReferenceViewer';
import ScenariosViewer from '@pages/Scenarios';
import ServiceViewer from '@pages/ServiceStatus';
import TrafficMonitoringView from '@pages/TrafficMonitoring';
import VesselGroupsViewer from '@pages/VesselGroupsViewer';
import VesselsViewer from '@pages/VesselsViewer';

import UserManual from '@components/UserManual';

import tSchema from '@lang/schema';
import SchemaPage from '@lang/SchemaPage';

import {
  updateLogedIn, setUserName, setRoles, setPermissions,
} from '@services/authentication/actions';
import { getLogedIn } from '@services/authentication/reducers';
import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import { apiFetchDomainOptions, apiFetchUserPreferences } from '@services/userPreferences/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import { DUMMY_LOGIN } from '../../common/constants';

import Breadcrumbs from './components/Breadcrumbs';
import Footer from './components/Footer';
import Header from './components/Header';
import MainMenu from './components/MainMenu';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import getRoutePaths from './utils/routePaths';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { RealmRole } from '@common/keycloakFunctions';
import { DOMAIN } from '@common/utils';

const App = ({
  isLogedIn = false, updateLogedIn: updateLogedInAction,
  authentification = {},
}) => {
  const { t } = useTranslation();
  const routePaths = useMemo(() => getRoutePaths(t), [t]);

  const [collapseMenu, setCollapseMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUserPreferences, userPreferences } = useUserPreferences();
  const { keycloak, loading: keycloakLoading } = useKeyCloak();

  useEffect(() => {
    setLoading(keycloakLoading);
  }, [keycloakLoading]);

  const loginWorking = keycloak.token || DUMMY_LOGIN === true;

  const domain = userPreferences && userPreferences.domain;
  const isFADomain = domain === DOMAIN.FA;
  const isVesselDomain = domain === DOMAIN.VESSEL;
  const isIRDomain = domain === DOMAIN.ISR;

  const isDataAdmin = useMemo(() => keycloak.token
    && keycloak.hasRealmRole(RealmRole.DATA_ADMIN), [keycloak]);

  const isSystemAdmin = useMemo(() => keycloak.token
        && keycloak.hasRealmRole(RealmRole.SYSTEM_ADMIN), [keycloak]);

  const isCoordinators = useMemo(() => keycloak.token
        && keycloak.hasRealmRole(RealmRole.APP_USER), [keycloak]);

  const isISRDataAdmin = useMemo(() => keycloak.token
  && keycloak.hasRealmRole(RealmRole.ISR_DATA_ADMINISTRATOR), [keycloak]);

  const isISRSystemAdmin = useMemo(() => keycloak.token
  && keycloak.hasRealmRole(RealmRole.ISR_SYSTEM_ADMINISTRATOR), [keycloak]);

  const isSuperUser = useMemo(() => (isDataAdmin && isSystemAdmin), [isDataAdmin, isSystemAdmin]);

  useEffect(() => {
    if (keycloak.token) {
      const fetchDomains = async () => {
        setUserPreferences((prevState) => (
          {
            ...prevState,
            loadingDomains: true,
          }));
        const resDomainOptions = await apiFetchDomainOptions();
        const resPreferences = await apiFetchUserPreferences();
        setUserPreferences((prevState) => (
          {
            ...prevState,
            loadingDomains: false,
          }));
        if (resDomainOptions.ok) {
          const dataDomainOptions = await resDomainOptions.json();
          setUserPreferences((prevState) => (
            {
              ...prevState,
              // lastActive: dataUserPreferences.lastActive || null,
              domains: dataDomainOptions,
            }));
        }
        if (resPreferences.ok) {
          const dataUserPreferences = await resPreferences.json();
          setUserPreferences((prevState) => (
            {
              ...prevState,
              lastActiveIsr: dataUserPreferences.lastActiveIsr || null,
              lastActiveFa: dataUserPreferences.lastActiveFa || null,
              logbookFavFilters: dataUserPreferences.logbookFavFilters,
            }));
        }
      };
      fetchDomains();
    }
  }, [keycloak, DUMMY_LOGIN, setUserPreferences]);

  const handleCollapseMenu = useCallback(
    () => setCollapseMenu(!collapseMenu),
    [collapseMenu],
  );
  const validationBRViewerEnabled = useMemo(
    () => (isSuperUser || isSystemAdmin || isDataAdmin || isISRSystemAdmin || isISRDataAdmin)
        && (isFADomain || isVesselDomain || isIRDomain),
    [isDataAdmin, isSuperUser, isSystemAdmin,
      isFADomain, isVesselDomain, isIRDomain, isISRSystemAdmin, isISRDataAdmin],
  );

  const acceptanceBRViewerEnabled = useMemo(
    () => (isSuperUser || isSystemAdmin || isDataAdmin || isISRSystemAdmin || isISRDataAdmin)
       && (isIRDomain || isFADomain),
    [isDataAdmin, isSuperUser, isSystemAdmin, isFADomain,
      isIRDomain, isISRSystemAdmin, isISRDataAdmin],
  );

  const servicesViewerEnabled = useMemo(
    () => (isSystemAdmin || isSuperUser || isISRSystemAdmin)
    && (isFADomain || isVesselDomain || isIRDomain),
    [isSuperUser, isSystemAdmin, isFADomain, isVesselDomain, isISRSystemAdmin, isIRDomain],
  );
  return keycloak && (
    <Router>
      <div id="app">
        <Header
          onLogout={() => {
            if (keycloak.token) {
              keycloak.logout();
              updateLogedInAction(false);
            }
          }}
          dummyLogin={DUMMY_LOGIN}
          isLogedIn={isLogedIn}
          username={authentification.userName}
          setLoading={setLoading}
        />
        <div id="top-menu">
          <Breadcrumbs onCollapseCallback={handleCollapseMenu} />
          <UserManual domain={domain} />
        </div>
        <Container fluid>
          <Row>
            <MainMenu
              collapse={collapseMenu}
            />
            <ToastContainer
              position="bottom-right"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <div id="page-container">
              <div id="page">
                {loginWorking ? (
                  <Switch>
                    <PublicRoute
                      component={Login}
                      path="/login"
                      componentProps={{ externalLogin: !DUMMY_LOGIN }}
                      exact
                    />
                    <PublicRoute component={Logout} path="/logout" exact />
                    <PrivateRoute
                      component={SchemaPage}
                      title={t('Lang Schema')}
                      path="/langSchema"
                      isLogedIn={isLogedIn}
                      enabled={isSuperUser}
                      exact
                    />
                    <PrivateRoute
                      component={TrafficMonitoringView}
                      componentProps={{
                        title: t(tSchema.menu.trafficMonitoring),
                        path: routePaths.trafficMonitoring.url,
                      }}
                      title={t(tSchema.menu.trafficMonitoring)}
                      path={`${routePaths.trafficMonitoring.url}/:sectionId?`}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isISRSystemAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={FailoverViewer}
                      title={t(tSchema.menu.failoverParameters)}
                      path={routePaths.failOverParameters.url}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isISRSystemAdmin || isISRDataAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={ServiceViewer}
                      title={t(tSchema.menu.servicesMonitoring)}
                      path={routePaths.servicesMonitoring.url}
                      isLogedIn={isLogedIn}
                      enabled={servicesViewerEnabled}
                      exact
                    />
                    <PrivateRoute
                      component={MessageViewer}
                      componentProps={{
                        title: t(tSchema.menu.messageViewer),
                        path: routePaths.messageViewer.url,
                      }}
                      title={t(tSchema.menu.messageViewer)}
                      path={`${routePaths.messageViewer.url}/:sectionId?`}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin
                        || isISRSystemAdmin || isISRDataAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={ScenariosViewer}
                      componentProps={{
                        title: t(tSchema.menu.scenarioMGMT),
                        path: routePaths.scenarioMGMT.url,
                      }}
                      title={t(tSchema.menu.scenarioMGMT)}
                      path={`${routePaths.scenarioMGMT.url}/:sectionId?`}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin
                         || isISRSystemAdmin || isISRDataAdmin)
                        && (isFADomain || isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={BusinessRulesViewer}
                      title={t(tSchema.menu.validationBR)}
                      path={routePaths.validationBR.url}
                      isLogedIn={isLogedIn}
                      enabled={validationBRViewerEnabled}
                      exact
                    />
                    <PrivateRoute
                      component={EfcaRulesViewer}
                      componentProps={{
                        title: t(tSchema.menu.acceptanceBR),
                        path: routePaths.acceptanceBR.url,
                      }}
                      title={t(tSchema.menu.acceptanceBR)}
                      path={`${routePaths.acceptanceBR.url}/:sectionId?`}
                      isLogedIn={isLogedIn}
                      enabled={acceptanceBRViewerEnabled}
                      exact
                    />
                    <PrivateRoute
                      component={QuerySubscriptionsViewer}
                      componentProps={{
                        title: t(tSchema.menu.queryReportMGMT),
                        path: routePaths.queryReportMGMT.url,
                      }}
                      title={t(tSchema.menu.queryReportMGMT)}
                      path={`${routePaths.queryReportMGMT.url}/:sectionId?`}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={ReferenceViewer}
                      title={t(tSchema.menu.referenceDataMGMT)}
                      path={routePaths.referenceDataMGMT.url}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isISRSystemAdmin
                        || isISRDataAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      isLogedIn={isLogedIn}
                      exact
                    />
                    <PrivateRoute
                      component={ActivityReports}
                      componentProps={{
                        title: 'Activity Reports',
                        path: routePaths.dataAdministrationReporting.url,
                      }}
                      title="Activity Reports"
                      path={`${routePaths.dataAdministrationReporting.url}/:sectionId?`}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      isLogedIn={isLogedIn}
                      exact
                    />
                    <PrivateRoute
                      component={VesselGroupsViewer}
                      title={t(tSchema.menu.vesselGroupMGMT)}
                      path={routePaths.vesselGroupMGMT.url}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      isLogedIn={isLogedIn}
                      exact
                    />
                    <PrivateRoute
                      component={TripLogbookViewer}
                      componentProps={{
                        title: t(tSchema.menu.logbookViewer),
                        path: routePaths.logbookViewer.url,
                      }}
                      title={t(tSchema.menu.logbookViewer)}
                      path={`${routePaths.logbookViewer.url}/:sectionId?`}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isCoordinators)
                        && isFADomain}
                      exact
                    />
                    <PrivateRoute
                      component={VesselsViewer}
                      title={t(tSchema.menu.vesselMGMT)}
                      path={routePaths.vesselMGMT.url}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isCoordinators)
                        && (isFADomain || isVesselDomain || isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={IsrViewer}
                      title={t(tSchema.menu.isrViewer)}
                      path={routePaths.isrViewer.url}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isCoordinators)
                        && (isIRDomain)}
                      exact
                    />
                    <PrivateRoute
                      component={Home} // TODO: Hacer componente InspectionReportValidationViewer
                      title={t(tSchema.menu.inspectionReportValidation)}
                      path={routePaths.inspectionReportValidation.url}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isCoordinators)
                        && isIRDomain}
                      exact
                    />
                    <PrivateRoute
                      component={Home} // TODO: Hacer componente InspectionReportQueryViewer
                      title={t(tSchema.menu.inspectionReportQuery)}
                      path={routePaths.inspectionReportQuery.url}
                      isLogedIn={isLogedIn}
                      enabled={(isSuperUser || isSystemAdmin || isDataAdmin || isCoordinators)
                        && isIRDomain}
                      exact
                    />
                    <PrivateRoute
                      component={ExternalSource}
                      title={t(tSchema.menu.externalSourcesMGMT)}
                      path={routePaths.externalSourcesMGMT.url}
                      enabled={(isDataAdmin || isSuperUser) && isVesselDomain}
                      isLogedIn={isLogedIn}
                      exact
                    />
                    <PrivateRoute component={Home} a path="/" isLogedIn={isLogedIn} exact />
                    <PrivateRoute
                      component={() => <Redirect to="/" />}
                      path="/"
                      isLogedIn={isLogedIn}
                    />
                  </Switch>
                ) : (
                  loading && (
                    <div className="h4 align-items-center justify-content-center d-flex w-100 flex-column">
                      <div>
                        <div className="spinner-border" role="status">
                          <span className="sr-only">{t(tSchema.common.loading)}</span>
                        </div>
                      </div>
                      <div>{t(tSchema.common.loading)}</div>
                      <div>
                        You will be redirected soon. If it takes too long try reloading the page or
                        checking your connection.
                      </div>
                    </div>
                  )
                )}
              </div>
              <Footer />
            </div>
          </Row>
        </Container>
      </div>
    </Router>
  );
};

App.defaultProps = {
  authentification: null,
};
App.propTypes = {
  isLogedIn: PropTypes.bool.isRequired,
  updateLogedIn: PropTypes.func.isRequired,
  authentification: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  isLogedIn: getLogedIn(state),
  authentification: state.authentication,
});

const mapDispatchToProps = {
  updateLogedIn,
  setUserName,
  setRoles,
  setPermissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
