import { formatDateTime } from '@common/dateUtils';

function formatActivityType(row) {
  let result = null;
  if (row) {
    result = row.faType;
    if (row.purposeVersion === '5' && row.version === '4') {
      result += ' (*)';
    }
    if ((row.purposeVersion === '5' || row.purposeVersion === '9') && row.version === '3') {
      result += ' (D)';
    }
    if ((row.purposeVersion === '5' || row.purposeVersion === '9') && row.version === '2') {
      result += ' (C)';
    }
  }
  return result;
}

function formatTableField(row, value) {
  let result = null;
  if (row) {
    result = value;
    if (row.purposeVersion === '5' && (row.version === '3' || row.version === '2')) {
      result = (
        <div style={{ color: '#c1c1c1' }}>
          {`${value}`}
        </div>
      );
    }
  }
  return result;
}

export const tableConfig = [
  {
    text: 'Activity Type',
    code: 'activityType',
    sortable: true,
    render: (row) => formatTableField(row, formatActivityType(row)),
  },
  {
    text: 'Activity Date',
    code: 'activityDate',
    sortable: true,
    render: (row) => formatTableField(row, formatDateTime(row.occurrenceDate) !== '-' ? `${formatDateTime(row.occurrenceDate)}, UTC` : '-'),
  },
  {
    text: 'Report Type', code: 'reportType', sortable: true, render: (row) => formatTableField(row, row.reportType),
  },
  {
    text: 'Report Received on', code: 'reportReceivedOn', sortable: true, render: (row) => formatTableField(row, formatDateTime(row.notificationDate) !== '-' ? `${formatDateTime(row.notificationDate)}, UTC` : '-'),
  },
];

export default tableConfig;
