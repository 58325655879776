/* eslint-disable linebreak-style */
import { Button } from 'react-bootstrap';

import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import { downloadAttachment, deleteAttachment } from '@services/isr/reports/api';

import { fromUTCToLocalDateTime, formatDateTime } from '@common/dateUtils';
import { Permissions, hasPermission } from '@common/keycloakFunctions';
import { requestWithMessage, showErrorNotification } from '@common/utilities/Notification';

const schema = tSchema.isrViewer;

function getTableConfig(t, deleteHandler, permissions) {
  return [
    {
      text: t(schema.name),
      code: 'filename',
      render: (row) => (
        <Button
          variant="link"
          className="action-button"
          key={`action-button-${row.filename}`}
          onClick={async () => {
            const query1 = await downloadAttachment(row.id);
            if (query1.ok) {
              const url = window.URL.createObjectURL(new Blob([query1.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', row.filename);
              document.body.appendChild(link);
              link.click();
            } else {
              showErrorNotification();
            }
          }}
        >
          {row.filename}
        </Button>
      ),
    },
    { text: t(schema.description), code: 'description' },
    { text: t(schema.size), code: 'size' },
    {
      text: t(schema.uploadDate),
      code: 'uploadDate',
      render: (row) => {
        const date = fromUTCToLocalDateTime(row.uploadDate);
        return `${formatDateTime(date, '-', true)}`;
      },
    },
    { text: t(schema.user), code: 'user' },
    {
      code: 'deleteAttachment',
      render: (row) => (
        <Button
          onClick={() => {
            requestWithMessage({
              request: () => deleteAttachment(row.id),
              onSuccess: () => deleteHandler(),
              successMessage: 'Attachment has been removed successfully',
              errorMessage: 'Error removing attachment',
            });
          }}
          disabled={!hasPermission(permissions, Permissions.ISR_DELETE_ATTACH)}
        >
          <IconSvg name="delete" className="no-margin" />
        </Button>
      ),
    },
  ];
}

export default getTableConfig;
