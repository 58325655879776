/* eslint-disable linebreak-style */
const SectionValidationBRDeactivate = () => (
  <div className="subSection">
    <p>
      To deactivate a business rule, the user must click on the &quot;Actions&quot;
      button corresponding to the row of that specific business rule,
      and then click on &quot;Deactivate&quot; button.
    </p>
  </div>
);

export default SectionValidationBRDeactivate;
