import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Form, Row, Button } from 'react-bootstrap';

import MultiSelectValues from '@components/MultiSelectValues';

import FieldCheckBoxWrapper from '../FieldCheckBoxWrapper/FieldCheckBoxWrapper';

import './style.scss';

const VesselSearchButton = ({
  name, label, textButton, onClick: handleClick, onFieldChecked: handleFieldChecked, checked,
}) => {
  const { values: { vesselId }, setFieldValue } = useFormikContext();

  const unSelectRowIndex = useCallback((index) => {
    const newVesselId = vesselId.filter((vessel, i) => i !== index);
    setFieldValue('vesselId', newVesselId);
  }, [vesselId, setFieldValue]);

  const selectedRowsLabels = useMemo(
    () => vesselId?.map((e) => e.cfr || e.uvi),
    [vesselId],
  );

  const valuesComponent = useMemo(() => (
    <MultiSelectValues
      values={selectedRowsLabels}
      onRemoveSelection={unSelectRowIndex}
      label={selectedRowsLabels.length > 0 ? 'Vessels' : ''}
    />
  ),
  [selectedRowsLabels, unSelectRowIndex]);
  return (
    <>
      <FieldCheckBoxWrapper checked={checked} name={name} onFieldChecked={handleFieldChecked}>
        <Form.Group as={Row} className="d-flex align-items-center m-0 my-2 justify-content-between w-90 flex-grow-1" controlId={name}>
          <Form.Label className="m-0">{label}</Form.Label>
          <Button type="button" className="side-filter-button" onClick={handleClick}>
            {textButton}
          </Button>
        </Form.Group>
      </FieldCheckBoxWrapper>
      {valuesComponent !== [] && valuesComponent }
    </>
  );
};

VesselSearchButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onFieldChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default VesselSearchButton;
