import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import {
  Button, Col, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import FieldGroup from '@components/Form';
import InputDate from '@components/Form/Inputs/InputDate';

import tSchema from '@lang/schema';

import { apiFetchAcceptanceQuery } from '@services/efcaRules/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import {
  defaultValues, getOptions, schemaValidation,
} from './utils';

import './style.scss';
import { getDateForInput } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { selectUtils } from '@common/utilities/selectUtils';
import { DOMAIN, formatSelectOption } from '@common/utils';

const schema = tSchema.dmaAdmin.acceptanceBR.query.createEditView;

const QueryAcceptanceRuleEditor = ({
  isDataAdmin,
  queryId,
  edit,
  onSave,
  onCancel,
  options,
  loading: parentLoading,
  saveError,
  isIsrSystemAdmin,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(parentLoading);
  const [queryAcceptance, setQueryAcceptance] = useState({});
  const [selectedVesselGroups, setSelectedVesselGroups] = useState([]);
  const [selectedQueryContexts, setSelectedQueryContexts] = useState([]);
  const [selectedISProgrammes, setSelectedISProgrammes] = useState([]);
  const isEditMode = useMemo(() => queryId !== null && queryId >= 0, [queryId]);
  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;
  const isFADomain = domainCode === DOMAIN.FA;

  const requestWithLoading = useRequestWithLoading();

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  const {
    vesselGroupsOptions, senderOptions, statusOptions, queryContextOptions,
    inspectionProgrammesOptions,
  } = useMemo(() => ({
    vesselGroupsOptions: selectUtils.mapOptions({ options: options.vesselGroups, labelPropName: 'name' }),
    queryContextOptions: selectUtils.mapOptions({ options: options.queryContexts, labelPropName: 'code', idName: 'code' }),
    inspectionProgrammesOptions: selectUtils.mapOptions({ options: options.inspectionProgrammes, labelPropName: 'code', idName: 'code' }),
    statusOptions: getOptions('brstatus')(options),
    senderOptions: options?.fluxParties && getOptions('fluxParties', 'code', 'name', (option) => formatSelectOption(option, 'code', 'name').description)(options),
  }), [options]);

  useEffect(() => {
    if (isEditMode) {
      requestWithLoading(async () => {
        const response = await apiFetchAcceptanceQuery(queryId, domainCode);

        if (response.ok) {
          const data = await response.json();
          setQueryAcceptance(data);
          setSelectedVesselGroups(
            selectUtils.selectedOptions(vesselGroupsOptions, data.vesselGroups),
          );
          setSelectedQueryContexts(
            selectUtils.selectedOptions(queryContextOptions, data.queryContexts),
          );
          setSelectedISProgrammes(
            selectUtils.selectedOptions(inspectionProgrammesOptions, data.isProgrammes),
          );
        } else {
          setQueryAcceptance({});
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    }
  }, [isEditMode, queryId, requestWithLoading, isFADomain]);

  const containsObjectWithAttributeValue = useCallback((array, attribute, value) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attribute] === value) {
        return true;
      }
    }
    return false;
  }, []);

  const handleOnSave = useCallback((valuesForm) => {
    const mappedValues = {
      ...valuesForm,
      startDateData: getDateForInput(valuesForm.startDateData),
      endDateData: getDateForInput(valuesForm.endDateData),
      activityStartDate: getDateForInput(valuesForm.activityStartDate),
      activityEndDate: getDateForInput(valuesForm.activityEndDate),
      endActiveDate: getDateForInput(valuesForm.endActiveDate),
      startActiveDate: getDateForInput(valuesForm.startActiveDate),
      vesselGroups: valuesForm.vesselGroups?.map((e) => e.value),
      isProgrammes: valuesForm.queryContexts && containsObjectWithAttributeValue(valuesForm.queryContexts, 'value', 'JDP') ? valuesForm.isProgrammes?.map((e) => e.value) : [],
      queryContexts: valuesForm.queryContexts?.map((e) => e.value),
    };
    onSave(mappedValues);
  }, [onSave]);

  const disabled = useMemo(() => {
    switch (domainCode) {
      case DOMAIN.ISR:
        return !edit;
      default:
        return !(edit && (isDataAdmin || isIsrSystemAdmin));
    }
  }, [edit, isDataAdmin, isIsrSystemAdmin, domainCode]);

  const initialValues = useMemo(() => ({
    ...defaultValues,
    status: '0',
    ...(isFADomain ? {
      sender: senderOptions[0]?.key,
    }
      : {
        isrParty: senderOptions[0]?.key,
      }),
    ...queryAcceptance,
    ...(isFADomain ? {
      vesselGroups: selectedVesselGroups,
    }
      : {
        queryContexts: selectedQueryContexts,
        isProgrammes: selectedISProgrammes,
      }),
    id: (queryId > 0) ? queryId : null,

  }), [queryAcceptance, defaultValues, selectedVesselGroups,
    selectedQueryContexts, selectedISProgrammes, isFADomain]);

  return (
    <Formik
      validationSchema={schemaValidation(t, domainCode)}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleOnSave}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        touched,
        errors,
      }) => {
        const isTouched = useMemo(() => touched.id, [touched.id]);
        return (
          <Form
            key={queryAcceptance.id}
            id="query-editor"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="editor-fields">
              {loading && (
              <div className="loading-div">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t(tSchema.common.loading)}</span>
                </div>
              </div>
              )}
              <Form.Row>
                <Col xl={{ span: 6 }} sm={{ span: 12 }}>
                  {isFADomain
                    ? (
                      <Form.Group as={Col} controlId="formSender">
                        <Form.Label>{t(schema.sender)}</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          name="sender"
                          isValid={isTouched && !errors.sender}
                          isInvalid={isTouched && errors.sender}
                          value={values.sender || ''}
                          disabled={disabled}
                          onChange={handleChange}
                        >
                          {senderOptions}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.sender}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )
                    : (
                      <Form.Group as={Col} controlId="formISRParty">
                        <Form.Label>{t(schema.isrParty)}</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          name="isrParty"
                          isValid={isTouched && !errors.isrParty}
                          isInvalid={isTouched && errors.isrParty}
                          value={values.isrParty || ''}
                          disabled={disabled}
                          onChange={handleChange}
                        >
                          {senderOptions}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.isrParty}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}

                  <Form.Group as={Col} controlId="formStatus">
                    <Form.Label>{t(schema.status)}</Form.Label>
                    <Form.Control
                      as="select"
                      isValid={isTouched && !errors.status}
                      name="status"
                      isInvalid={isTouched && errors.status}
                      value={values.status}
                      disabled={disabled}
                      onChange={handleChange}
                    >
                      {statusOptions}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                  </Form.Group>
                  {isFADomain
                  && (
                  <Form.Group as={Col} controlId="formVesselGroups">
                    <Form.Label>{t(schema.vesselGroups)}</Form.Label>
                    <ReactSelect
                      name="vesselGroups"
                      styles={isTouched
                             && selectUtils.selectStyles(!errors.vesselGroups)}
                      className={isTouched && (errors.vesselGroups ? 'is-invalid' : 'is-valid')}
                      isDisabled={disabled}
                      isMulti
                      closeMenuOnSelect={false}
                      options={vesselGroupsOptions}
                      value={values.vesselGroups}
                      onChange={(option) => setFieldValue('vesselGroups', option)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.vesselGroups}
                    </Form.Control.Feedback>
                  </Form.Group>
                  )}
                </Col>

                <Col xl={{ span: 6 }} sm={{ span: 12 }}>
                  {isFADomain
                  && (
                  <FieldGroup label={t(schema.dataScope)}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formStartDateData">
                        <Form.Label>{t(schema.from)}</Form.Label>
                        <InputDate
                          name="startDateData"
                          id="startDateData"
                          required
                          value={values.startDateData}
                          disabled={disabled}
                          isValid={isTouched && !errors.startDateData}
                          maxDate={values.endDateData}
                          onChange={(id, value) => handleChange({ target: { name: id, value } })}
                          complex={false}
                          hasAction={false}
                        />
                        <Form.Control
                          hidden
                          name="startDateData"
                          isValid={isTouched && !errors.startDateData}
                          isInvalid={isTouched && !!errors.startDateData}
                          value={values.startDateData || ''}
                          disabled={disabled}
                          max={values.endDateData}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.startDateData}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formEndDateData">
                        <Form.Label>{t(schema.to)}</Form.Label>
                        <InputDate
                          name="endDateData"
                          id="endDateData"
                          required
                          value={values.endDateData}
                          disabled={disabled}
                          isValid={isTouched && !errors.endDateData}
                          minDate={values.startDateData}
                          onChange={(id, value) => handleChange({ target: { name: id, value } })}
                          complex={false}
                          hasAction={false}
                        />
                        <Form.Control
                          hidden
                          name="endDateData"
                          isValid={isTouched && !errors.endDateData}
                          value={values.endDateData || ''}
                          disabled={disabled}
                          isInvalid={isTouched && !!errors.endDateData}
                          min={values.startDateData}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.endDateData}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </FieldGroup>
                  )}
                  {isFADomain
                    ? (
                      <FieldGroup label={t(schema.activityTimeframe)}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formActivityStartDate">
                            <Form.Label>{t(schema.from)}</Form.Label>
                            <InputDate
                              name="activityStartDate"
                              id="activityStartDate"
                              required
                              value={values.activityStartDate}
                              disabled={disabled}
                              isValid={isTouched && !errors.activityStartDate}
                              maxDate={values.activityEndDate}
                              onChange={
                                (id, value) => handleChange({ target: { name: id, value } })
                              }
                              complex={false}
                              hasAction={false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.activityStartDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formActivityEndDate">
                            <Form.Label>{t(schema.to)}</Form.Label>
                            <InputDate
                              name="activityEndDate"
                              id="activityEndDate"
                              required
                              value={values.activityEndDate}
                              disabled={disabled}
                              isValid={isTouched && !errors.activityEndDate}
                              minDate={values.activityStartDate}
                              onChange={
                                (id, value) => handleChange({ target: { name: id, value } })
                              }
                              complex={false}
                              hasAction={false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.activityEndDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </FieldGroup>
                    ) : (
                      <FieldGroup label={t(schema.activityTimeframe)}>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formActivityStartDate">
                            <Form.Label>{t(schema.from)}</Form.Label>
                            <InputDate
                              name="startActiveDate"
                              id="startActiveDate"
                              required
                              value={values.startActiveDate}
                              disabled={disabled}
                              isValid={isTouched && !errors.startActiveDate}
                              maxDate={values.endActiveDate}
                              onChange={
                                (id, value) => handleChange({ target: { name: id, value } })
                              }
                              complex={false}
                              hasAction={false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.startActiveDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formActivityEndDate">
                            <Form.Label>{t(schema.to)}</Form.Label>
                            <InputDate
                              name="endActiveDate"
                              id="endActiveDate"
                              required
                              value={values.endActiveDate}
                              disabled={disabled}
                              isValid={isTouched && !errors.endActiveDate}
                              minDate={values.startActiveDate}
                              onChange={
                                (id, value) => handleChange({ target: { name: id, value } })
                              }
                              complex={false}
                              hasAction={false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.endActiveDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </FieldGroup>
                    )}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  {!isFADomain
                && (
                <Form.Group as={Col} controlId="formQueryContext">
                  <Form.Label>{t(schema.queryContext)}</Form.Label>
                  <ReactSelect
                    name="queryContexts"
                    styles={isTouched
                             && selectUtils.selectStyles(!errors.queryContexts)}
                    className={isTouched && (errors.queryContexts ? 'is-invalid' : 'is-valid')}
                    isDisabled={disabled}
                    isMulti
                    closeMenuOnSelect={false}
                    options={queryContextOptions}
                    value={values.queryContexts}
                    onChange={(option) => setFieldValue('queryContexts', option)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.queryContexts}
                  </Form.Control.Feedback>
                </Form.Group>
                )}
                </Col>
              </Form.Row>
              {values.queryContexts && containsObjectWithAttributeValue(values.queryContexts, 'value', 'JDP')
              && (
              <Form.Row>
                <Col>
                  <Form.Group as={Col} controlId="formISProgramme">
                    <Form.Label>{t(schema.isProgramme)}</Form.Label>
                    <ReactSelect
                      name="isProgrammes"
                      styles={isTouched
                             && selectUtils.selectStyles(!errors.isProgrammes)}
                      className={isTouched && (errors.isProgrammes ? 'is-invalid' : 'is-valid')}
                      isDisabled={disabled}
                      isMulti
                      closeMenuOnSelect={false}
                      options={inspectionProgrammesOptions}
                      value={values.isProgrammes}
                      onChange={(option) => setFieldValue('isProgrammes', option)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.isProgrammes}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              )}
            </div>
            {edit && (
            <Form.Row className="actions justify-content-end">
              {saveError && <div className="invalid-feedback" style={{ display: 'block' }}>{saveError}</div>}
              <Button className="mr-3" variant="primary" onClick={onCancel}>
                <span className="symbol-cross" />
                {' '}
                {t(tSchema.common.cancel)}
              </Button>
              <Button variant="primary" type="submit" disabled={loading}>
                <span className="symbol-tick" />
                {' '}
                {t(tSchema.common.save)}
              </Button>
            </Form.Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

QueryAcceptanceRuleEditor.propTypes = {
  isDataAdmin: PropTypes.bool,
  queryId: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  options: PropTypes.shape({
    brstatus: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      endDate: PropTypes.string,
      id: PropTypes.number,
      startDate: PropTypes.string,
    })),
    fluxParties: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      endDate: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
    })),
    vesselGroups: PropTypes.arrayOf(PropTypes.shape({
      active: PropTypes.bool,
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    queryContexts: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      endDate: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
    })),
    inspectionProgrammes: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      endDate: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
    })),
  }).isRequired,
  edit: PropTypes.bool,
  loading: PropTypes.bool,
  saveError: PropTypes.string,
  isIsrSystemAdmin: PropTypes.bool,
};

QueryAcceptanceRuleEditor.defaultProps = {
  queryId: null,
  isDataAdmin: false,
  isIsrSystemAdmin: false,
  edit: false,
  loading: false,
  saveError: null,
};

export default QueryAcceptanceRuleEditor;
