import PropTypes from 'prop-types';
import React from 'react';
import { Form, Col } from 'react-bootstrap';
import ReactSelect from 'react-select';

import CustomMenuList from '@components/CustomMenuList';

const MultichoiceField = ({
  filter: {
    idProp = 'id',
    group = 'group',
    textProp = 'description',
    options, defaultValue = [],
    onChange: handleChange,
    code,
    isMultichoice = true,
    largeList = false,
  },
}) => {
  const currentGroups = [];
  const currentFilteredGroups = [];
  const optionsSelected = options.filter((en) => {
    if (en[group]
      && (currentFilteredGroups.length === 0 || !currentFilteredGroups.includes(en[group]))) {
      currentFilteredGroups.push(en[group]);
      return true;
    }
    if (!en[group]) {
      return true;
    }
    return false;
  }).map((e) => {
    if (e[group] && (currentGroups.length === 0 || !currentGroups.includes(e[group]))) {
      currentGroups.push(e[group]);
      return ({
        label: e[group],
        options: options.filter((op) => {
          if (op[group] && op[group] === e[group]) {
            return true;
          }
          return false;
        }).map((o) => ({ label: o[textProp], value: o[idProp] })),
      });
    }
    return ({ label: e[textProp], value: e[idProp] });
  });
  const menuList = largeList ? { MenuList: CustomMenuList } : {};
  return (
    <Form.Group as={Col} xs={12} className="mb-0">
      <ReactSelect
        isMulti={isMultichoice}
        closeMenuOnSelect={false}
        options={optionsSelected}
        value={defaultValue}
        onChange={(e) => handleChange({ [code]: e })}
        components={menuList}
        styles={{
          option: (baseStyles) => ({
            ...baseStyles,
            color: 'black',
            backgroundColor: 'white',
          }),
          groupHeading: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
            backgroundColor: 'grey',
            padding: '8px 12px',
          }),
          group: (baseStyles) => ({
            ...baseStyles,
            color: 'white',
            backgroundColor: 'grey',
            paddingBottom: '0px!important',
            paddingTop: '0px!important',
          }),
        }}
      />
      <input
        type="text"
        name={`${code}-value`}
        value={Array.isArray(defaultValue) ? defaultValue.map((obj) => obj.value).join().replace(/,/g, '&') : defaultValue.value}
        hidden
        readOnly
      />
    </Form.Group>
  );
};

MultichoiceField.propTypes = {
  filter: PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    idProp: PropTypes.string,
    group: PropTypes.string,
    textProp: PropTypes.string,
    isMultichoice: PropTypes.bool,
    largeList: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
  }).isRequired,
};

export default MultichoiceField;
