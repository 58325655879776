import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import messagesRelatedISRImg from './img/messages-related-isr.png';
import messagesRelatedImg from './img/messages-related.png';

import { DOMAIN } from '@common/utils';

const SectionMessagesRelated = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="subSection">
      <p>
        The panel on the right of the screen displays a list where user can see all related
        messages from selected message (currently displayed in the central panel).
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={messagesRelatedImg} alt="messagesRelatedBR" />
          : <img src={messagesRelatedISRImg} alt="messagesRelatedBRISR" />}
      </p>
      <p>
        More related messages are displayed when user scrolls down the list.
      </p>
      <p>
        If a related message is selected, a new tab is displayed in
        the central panel with the message content.
      </p>
      <p>
        User can see the total number of results at the bottom of the list.
      </p>
    </div>
  );
};

export default SectionMessagesRelated;
