import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Row } from 'react-bootstrap';

const FieldCheckBoxWrapper = ({
  children,
  label,
  name,
  alwaysPresent,
  checked,
  onFieldChecked: handleFieldChecked,
}) => {
  const onFieldChange = useCallback((event) => handleFieldChecked(name, event.target.checked),
    [handleFieldChecked, name]);

  return (
    <Form.Group
      as={Row}
      sm="auto"
      md="auto"
      lg="auto"
      controlId={`${name}Enabled`}
      className="mb-0 mx-0 d-flex nowrap align-items-center"
    >
      <Form.Check
        type="checkbox"
        label={label}
        checked={checked !== undefined && checked}
        name={name}
        disabled={alwaysPresent}
        className="d-inline-block overflow-hidden my-auto mr-2"
        onChange={onFieldChange}
      />
      {children}
    </Form.Group>
  );
};

FieldCheckBoxWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  label: PropTypes.element,
  name: PropTypes.string.isRequired,
  alwaysPresent: PropTypes.bool,
  checked: PropTypes.bool,
  onFieldChecked: PropTypes.func.isRequired,
};

FieldCheckBoxWrapper.defaultProps = {
  label: undefined,
  alwaysPresent: undefined,
  checked: true,
};

export default FieldCheckBoxWrapper;
