import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';

import IconSvg from '@components/IconSvg';
import MultiSelectValues from '@components/MultiSelectValues';

const InputAddField = ({
  filter: {
    defaultValue = [],
    onChange: handleChange,
    code,
  },
}) => {
  const [value, setValue] = useState('');

  const handleClickAddButton = useCallback(() => {
    const newArrayAddedValues = [...defaultValue, value];
    handleChange({ [code]: newArrayAddedValues });
    setValue('');
  }, [value, defaultValue]);

  const handleCloseSelection = useCallback((index) => {
    const newArrayAddedValues = [...defaultValue];
    newArrayAddedValues.splice(index, 1);
    handleChange({ [code]: newArrayAddedValues });
  }, [defaultValue]);

  return (
    <>
      <Col md={6}>
        <Form.Group as={Row} className={`input-add-form d-flex align-items-center justify-content-between m-0 flex-grow-1 ${defaultValue.length === 0 && 'my-2'}`}>
          <Row className="mx-0">
            <Form.Control
              type="text"
              className="side-filters-control"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <Button
              className="add-button d-flex align-items-center justify-content-center"
              onClick={handleClickAddButton}
            >
              <IconSvg name="add" className="add-icon" />
            </Button>
          </Row>
          <input
            type="text"
            name={`${code}-value`}
            value={Array.isArray(defaultValue) ? defaultValue.join().replace(/,/g, '&') : defaultValue.value}
            hidden
            readOnly
          />
          <input hidden name={`${code}-comparation`} value="==" readOnly />
        </Form.Group>
      </Col>
      <Col md={10}>
        {
      defaultValue.length > 0 && (
        <Row className="d-flex m-0 mt-1 mb-2 align-items-center multi-select-values">
          <MultiSelectValues
            values={defaultValue}
            onRemoveSelection={handleCloseSelection}
          />
        </Row>
      )
      }
      </Col>
    </>
  );
};

InputAddField.propTypes = {
  filter: PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    idProp: PropTypes.string,
    group: PropTypes.string,
    textProp: PropTypes.string,
    isMultichoice: PropTypes.bool,
    largeList: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
  }).isRequired,
};

export default InputAddField;
