import * as Yup from 'yup';

import { BACK_DATE_FORMAT, formatCurrentDate } from '@common/dateUtils';

export const formatFormFields = (formFields) => formFields?.map((formField) => {
  switch (formField.code) {
    case 'startDate':
      return { ...formField, name: 'Start Date' };
    case 'endDate':
      return { ...formField, name: 'End Date' };
    default:
      return formField;
  }
});

export const getInitialValues = ({
  code, description, startDate, endDate, ...rest
}) => ({
  code: code || '',
  description: description || '',
  source: 'EFCA',
  startDate: (startDate && startDate.split(',')[0]) || formatCurrentDate(BACK_DATE_FORMAT),
  endDate: (endDate && endDate.split(',')[0]) || '2100-12-31',
  ...rest,
});

export const orderColumns = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

export const schemaValidation = () => Yup.object().shape({
  code: Yup.string().required('Code is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date().required('End Date is required').min(
    Yup.ref('startDate'),
    "End date can't be before start date",
  ),
});
