import actions from './img/actions.png';
import actionsVersion from './img/actions_version.JPG';
import editModal from './img/edit_modal.JPG';
import versions from './img/versions.JPG';

const SectionEditVessel = () => (
  <div className="subSection">
    <p>
      From the grid of vessels, in the column &quot;Actions&quot;,
      selecting the &quot;View&quot; action.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      Opens a new window containing the versions of the
      vessel selected.
    </p>
    <p>
      <img src={versions} alt="Versions list" />
    </p>
    <p>
      For each version, there are some actions available from the ‘Actions’ column.
    </p>
    <p>
      <img src={actionsVersion} alt="Versions actions" />
    </p>
    <p>
      The &quot;Edit&quot; action opens a new window displaying the edit
      form and also the information about the version selected.
    </p>
    <p>
      <img src={editModal} alt="Edit Modal" />
    </p>
    <p>
      If the fields CFR and UVI are already filled,
      they cannot be modified so the fields remain disabled.
    </p>
    <p>
      When the user edits some fields, if the Validity Date has not been changed,
      the edition of the vessel does not create a new version,
      it only edits the selected version.
    </p>
  </div>
);

export default SectionEditVessel;
