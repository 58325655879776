import PropTypes from 'prop-types';

import { INPUT_TYPES } from '../inputs/constants';
import VesselFormGroup from '../inputs/VesselFormGroup';
import LineSeparator from '../LineSeparator';

const TabCompanyInformation = ({
  attributes,
  visualization,
}) => (
  <>
    <LineSeparator name="Owner" position="top" />
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="ownerLegalPersonIndicator"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.BOOLEAN}
        attributes={attributes}
        displayName="Legal Person Indicator"
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="ownerName"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Name"
        maxLength={100}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerStreet"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Street Name"
        maxLength={256}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerPoBox"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="PO box"
        maxLength={25}
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="ownerCity"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="City"
        maxLength={100}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerPostCode"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="PostCode"
        maxLength={25}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerCountry"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Country"
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="ownerPhoneNumber"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Phone number"
        maxLength={30}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerFaxNumber"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Fax number"
        maxLength={30}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerEmailAddress"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Email Address"
        maxLength={50}
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="ownerNationality"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Nationality"
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="ownerImoIdentifier"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="IMO Identifier"
        maxLength={7}
        minLength={7}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="publicAid"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Public Aid"
        visualization={visualization}
      />
    </div>
    <LineSeparator name="Operator" position="top" />
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="operatorLegalPersonIndicator"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.BOOLEAN}
        attributes={attributes}
        displayName="Legal Person Indicator"
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="operatorName"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Name"
        maxLength={100}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorStreet"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Street Name"
        maxLength={256}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorPoBox"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="PO box"
        maxLength={25}
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="operatorCity"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="City"
        maxLength={100}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorPostCode"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Post code"
        maxLength={25}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorCountry"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Country"
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="operatorPhoneNumber"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Phone number"
        maxLength={30}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorFaxNumber"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Fax number"
        maxLength={30}
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorEmailAddress"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Email Address"
        maxLength={50}
        visualization={visualization}
      />
    </div>
    <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
      <VesselFormGroup
        attrName="operatorNationality"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="Nationality"
        visualization={visualization}
      />
      <VesselFormGroup
        attrName="operatorImoIdentifier"
        classSpace="input-one-third"
        inputType={INPUT_TYPES.STRING}
        attributes={attributes}
        displayName="IMO identifier"
        maxLength={7}
        minLength={7}
        visualization={visualization}
      />
    </div>
  </>
);

TabCompanyInformation.defaultProps = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  visualization: PropTypes.string,
};

TabCompanyInformation.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  visualization: PropTypes.string,
};

export default TabCompanyInformation;
