import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';

const CheckComponent = ({
  row, onSelectRowCheck, isSelected, disabled,
}) => {
  const handleChange = (event) => {
    const { checked } = event.target;
    onSelectRowCheck && onSelectRowCheck(row, checked);
  };
  return (
    <Form.Group className="m-0 d-flex justify-content-end">
      <Form.Check type="checkbox" className="my-auto" onChange={handleChange} checked={isSelected} disabled={disabled} />
    </Form.Group>
  );
};
CheckComponent.propTypes = {
  row: PropTypes.shape({
    etalon_id: PropTypes.string,
    cfr: PropTypes.string,
    uvi: PropTypes.string,
  }).isRequired,
  onSelectRowCheck: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};
CheckComponent.defaultProps = {
  onSelectRowCheck: null,
};

export default CheckComponent;
