import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { EXIT_FROM_AREA_PROPTYPES, EXIT_FROM_AREA_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const ExitFromArea = ({ exitFromArea, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentExitFromArea, setCurrentExitFromArea] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(EXIT_FROM_AREA_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => setCurrentExitFromArea((exitFromArea && exitFromArea[pageNumber - 1])
  || EXIT_FROM_AREA_SCHEMA),
  [exitFromArea, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        title="Entry into Area"
        versions={currentExitFromArea}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={exitFromArea}
        onSelectPagination={setPageNumber}
      />

      <Row>
        <Col sm={4} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col sm={4} xs={12}>
          <TextInputField
            label={t(schema.fisheryType)}
            value={`${currentVersion?.fisheryType || ''}${currentVersion?.fisheryType || currentVersion?.fisheryTypeDescription ? ' - ' : ''}${currentVersion?.fisheryTypeDescription || ''}`}
          />
        </Col>
        <Col sm={4} xs={12}>
          <TextInputField label={t('Species Target (FAO)')} value={currentVersion?.speciesTarget} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

ExitFromArea.propTypes = {
  exitFromArea: EXIT_FROM_AREA_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default ExitFromArea;
