/* eslint-disable max-len */
import { SAVE_BUSINESS_RULE, TOGGLE_RULE_ACTIVATION } from './actions';

const initialState = {
  businessRules: {
    editor: {
      businessRule: {},
    },
    activeRuleToggle: {
      businessRule: {},
    },
    loading: false,
  },
};

function businessRules(currentState, { type, payload }) {
  const state = currentState || initialState.businessRules;
  switch (type) {
    case SAVE_BUSINESS_RULE.PENDING:
    case TOGGLE_RULE_ACTIVATION.PENDING:
      return { ...state, loading: true };
    case SAVE_BUSINESS_RULE.FULFILLED:
      return {
        ...state,
        editor: { ...state.editor, businessRule: { ...payload.businessRule } },
        loading: false,
      };
    case TOGGLE_RULE_ACTIVATION.FULFILLED:
      return {
        ...state,
        activeRuleToggle: {
          ...state.activeRuleToggle,
          businessRule: { ...payload.businessRule },
        },
        loading: false,
      };
    case SAVE_BUSINESS_RULE.REJECTED:
    case TOGGLE_RULE_ACTIVATION.REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const getSavedBusinessRule = (state) => state.businessRules.editor.businessRule;
export const getLoading = (state) => state.businessRules.loading;
export const getUpdatedActivationRule = (state) => state.businessRules.activeRuleToggle.businessRule;

export default businessRules;
