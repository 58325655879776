import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { ACTIONS_PROPTYPES } from '@components/ActionsPopOver/ActionsPopOver';
import DataTable from '@components/DataTable';
import Filters from '@components/Filters';

import { DIRECTION, formatHeaderSort } from '@common/utils';

const sortFunc = (
  { field, order },
) => (
  order === DIRECTION.ASC ? R.ascend(R.propOr('', field)) : R.descend(R.propOr('', field))
);

const BasicTable = ({
  data, headers, actions, pageSize: defaultPageSize, pageNumber: defaultPageNumber, pagination,
  rowRender, tableId, pageSizeOptions, autoFilters, ...props
}) => {
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filters, setFilters] = useState({});
  const [sortedData, setSortedData] = useState(data);
  const [filteredData, setFilteredData] = useState(data);
  const [orderBy, setOrderBy] = useState({});
  const dataTableRef = useRef(null);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    setPageNumber(1);
  };

  useEffect(() => setPageNumber(defaultPageNumber), [defaultPageNumber]);
  useEffect(() => setPageSize(defaultPageSize), [defaultPageSize]);
  useEffect(() => {
    setSortedData(R.sort(sortFunc(orderBy), data));
  }, [orderBy, data]);

  const onPageSizeChangeHandler = useCallback((e) => { setPageSize(+e); setPageNumber(1); }, []);
  const onPageChangeHandler = useCallback((e) => setPageNumber(+e), []);

  const pagedData = useMemo(() => {
    const ini = pageNumber * pageSize - pageSize;
    const end = Math.min(sortedData?.length, ini + pageSize);
    return filteredData?.slice(ini, end);
  },
  [filteredData, pageNumber, pageSize]);

  const maxPages = useMemo(() => {
    const ini = Math.ceil(filteredData?.length / pageSize);
    return ini;
  },
  [filteredData, pageSize]);

  const handleTableHeaderClick = useCallback((header) => {
    setOrderBy(formatHeaderSort(header, orderBy));
  }, [orderBy]);

  const handleUpdateSearchFilters = useCallback((f, formatedFilters) => {
    resetPagination();
    setFilters(formatedFilters);
  },
  []);

  useEffect(() => {
    const filterKeys = filters && Object.keys(filters);
    setFilteredData(
      !filters || R.isEmpty(filterKeys)
        ? sortedData
        : sortedData.filter((row) => filterKeys?.every((key) => {
          const rowVal = row[key]?.toLowerCase();
          const filterVal = filters[key]?.value?.toLowerCase();
          return rowVal?.indexOf(filterVal) > -1;
        })),
    );
  },
  [filters, sortedData]);

  return (
    <>
      {autoFilters && (
      <Filters
        filters={
          headers.map((e) => ({
            ...e, label: e.text, type: 'text',
          }))
        }
        onUpdateSearchFilters={handleUpdateSearchFilters}
      />
      )}
      <DataTable
        ref={dataTableRef}
        tableId={tableId}
        actions={actions}
        totalResults={filteredData?.length}
        tableConfig={headers}
        pagination={pagination}
        rowData={pagedData || []}
        defaultPageSize={pageSize}
        onPageSizeChangeHandler={onPageSizeChangeHandler}
        onPageChangeHandler={onPageChangeHandler}
        maxPages={maxPages}
        pageSizeOptions={pageSizeOptions}
        rowRender={rowRender}
        onTableHeaderClick={handleTableHeaderClick}
        orderBy={orderBy}
        {...props}
      />
    </>
  );
};

BasicTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    code: PropTypes.string,
    sortable: PropTypes.bool,
  })).isRequired,
  actions: ACTIONS_PROPTYPES,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  pagination: PropTypes.bool,
  rowRender: PropTypes.func,
  tableId: PropTypes.string,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  autoFilters: PropTypes.bool,
};
BasicTable.defaultProps = {
  actions: null,
  pageSize: 5,
  pageNumber: 1,
  pagination: true,
  rowRender: undefined,
  tableId: 'basic-table-report-details',
  pageSizeOptions: [5, 10, 15, 20],
  autoFilters: false,
};

export default BasicTable;
