import PropTypes from 'prop-types';
import {
  any,
  compose,
  filter as rFilter,
  find,
  fromPairs,
  identity,
  ifElse,
  isEmpty,
  isNil,
  join,
  keys,
  map,
  mapObjIndexed,
  mergeAll,
  objOf,
  pick,
  prepend,
  prop,
  propEq,
  props,
  reject,
  take,
  toPairs,
  values,
} from 'ramda';
import {
  useCallback, useEffect, useRef, useState,
  useMemo,
} from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import IconSvg from '@components/IconSvg';

import tSchema from '@lang/schema';

import InputSelect from '../Form/Inputs/InputSelect';

import FilterRow from './FilterRow';

import { filterTypes } from './config';

import { formatDate, formatDateTime, validateDate } from '@common/dateUtils';

const getPostprocess = (x) => (x.postProcess || identity)(filterTypes[x.type].value(x));

const updatedSearchFilters = (filters, formatedFilters) => compose(
  join(','),
  (filterList) => {
    const list = [];
    filterList.forEach((f) => {
      const key = f[0];
      if (f[1] === '=null') f.splice(-1);
      const filter = find(propEq('code', key), filters);
      if (filter.type === 'date-range') {
        const [, from, to] = f && f[2] && f[2].match(/(.+)\/(.+)/);
        if (from === to && from !== 'indefinite' && filter.section !== 'isrReportViewer') {
          list.push(join('', [filter.codeTo || key, '==', to]));
        } else {
          from && from !== 'indefinite' && list.push(join('', [key, '>', from]));
          to && to !== 'indefinite' && list.push(join('', [filter.codeTo || key, '<', to]));
        }
      } else
      if (find(propEq('code', key), filters).type === 'multichoice' || (find(propEq('code', key), filters).type === 'input-add')) {
        list.push(join('', [key, '=multichoice', f[2]]));
      } else {
        list.push(join('', f));
      }
    });
    return list;
  },
  map(props(['code', 'comparation', 'value'])),
  rFilter((f) => (f.value || f.comparation === '=null') && f.enabled),
  map(([key, obj]) => ({ ...obj, code: key })),
  toPairs,
)(formatedFilters);

const getFilterValues = (formatedFilters) => pick(
  reject((x) => compose(
    ifElse(
      (y) => y[2] === '=null',
      (y) => isEmpty(y[1]) || isNil(y[1]),
      any((y) => isEmpty(y) || isNil(y) || (typeof y === 'number' && Number.isNaN(y))),
    ),
    props(['value', 'enabled', 'comparation']),
    prop(x),
  )(formatedFilters))(keys(formatedFilters)),
)(formatedFilters);

const dateRangePreviewValue = (value) => {
  const dates = value
    .split('/').map((date) => (validateDate(date) ? formatDate(date, undefined, undefined, true) : date));
  return dates && dates.length > 1 ? `from ${dates[0]} to ${dates[1]}` : 'Invalid';
};

const Filters = ({
  filters,
  searchOptions,
  onUpdateSearchFilters,
  onFiltersVisibilityChange,
  onNewFilter,
  initialFilters,
  isUIBlocked,
  isOpen,
  handlerChangedFilters,
}) => {
  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState({});
  const [changedFilters, setChangedFilters] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [filtersHidden, setFiltersHidden] = useState(!isOpen);
  const [formErrors, setFormErrors] = useState({});
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const keyPressHold = useRef(false);
  const formFilterRef = useRef();

  const getValue = (value, code) => {
    const filter = find(propEq('code', code))(filters) || {};
    const { idProp = 'id', textProp = 'description' } = filter;
    const optionsMultiChoice = prop('options', filter);
    if (filter.previewProcess) { return filter.previewProcess(value); }
    const numericValue = Number.parseInt(value, 10);
    const numericOrStringValue = Number.isNaN(numericValue) ? value : numericValue;
    switch (filter.type) {
      case 'select':
      case 'complex-select':
        return filter.options
          ? compose(
            prop(textProp),
            find(propEq(idProp, numericOrStringValue)),
          )(filter.options)
          : value;
      case 'date':
      case 'simpledate':
        return formatDate(value, undefined, undefined, true);
      case 'datetime':
        return formatDateTime(value, 'DD/MM/YYYY HH:mm:ss', true);
      case 'date-range':
        return dateRangePreviewValue(value);
      case 'multichoice':
        return value.split('&')
          .map((valueOptionSelect) => {
            const optionMultichoice = optionsMultiChoice.find(
              (optionObject) => `${optionObject[filter.idProp || 'id']}` === `${valueOptionSelect}`,
            );
            return optionMultichoice && optionMultichoice[filter.textProp || 'description'];
          }).join(', ');
      default:
        return value;
    }
  };

  const getFilterConfig = useCallback(
    (code, configProp) => {
      const config = find(propEq('code', code), filters);
      return configProp ? config && config[configProp] : config;
    },
    [filters],
  );
  const onSubmitHandler = useCallback((ev) => {
    ev?.preventDefault();
    if (!isUIBlocked) {
      const formData = {};
      new FormData(ev?.target).forEach((value, key) => {
        const [code, property, auxProperty] = key.split('-');
        let overwrite = false;
        if (key && (key.includes('-temp-from-value') || key.includes('-temp-to-value')) && value === '') {
          overwrite = true;
        }
        if (!formData[code]) {
          const config = find(propEq('code', code))(filters);
          formData[code] = {
            type: config.type || 'text',
            postProcess: config.postProcess,
          };
        }
        formData[code][overwrite ? auxProperty : property] = value;
      });

      const formatedFilters = map(getPostprocess)(formData);

      onUpdateSearchFilters(
        updatedSearchFilters(filters, formatedFilters),
        formatedFilters,
      );

      const newFilterValues = getFilterValues(formatedFilters);
      setFilterValues(newFilterValues);
      setSelectedFilters(pick(keys(newFilterValues))(selectedFilters));
    }
    ev?.stopPropagation();
  }, [
    onUpdateSearchFilters, setFilterValues, setSelectedFilters,
    isUIBlocked, filters, selectedFilters,
  ]);

  const resetFilters = useCallback(
    () => {
      setChangedFilters(initialFilters || {});
      setFilterValues({});
      setSelectedFilters((prev) => compose(
        mergeAll,
        prepend(
          compose(
            mergeAll,
            fromPairs,
            rFilter(([k]) => getFilterConfig(k, 'alwaysPresent') || getFilterConfig(k, 'fixed')),
            toPairs,
          )(prev),
        ),
        map(([k, v]) => objOf(k, { enabled: true, defaultValue: v })),
        toPairs,
      )(initialFilters));
      onUpdateSearchFilters(null);
    },
    [getFilterConfig, initialFilters, onSubmitHandler],
  );

  useEffect(() => {
    resetFilters();
  }, [initialFilters]);

  const toggleCollapsed = useCallback(() => {
    setFiltersHidden(!filtersHidden);
    onFiltersVisibilityChange && onFiltersVisibilityChange();
  });
  const onShowMoreFilter = () => setShowMoreFilter(!showMoreFilter);
  const hasFilters = useMemo(() => !isEmpty(selectedFilters), [selectedFilters]);
  const mainClasses = useMemo(() => [
    'mb-2',
    filtersHidden ? 'closed' : 'open',
    !hasFilters && 'noFilters',
  ].filter(Boolean).join(' '), [filtersHidden, hasFilters]);

  const onAddFilterChange = useCallback((id, value) => {
    const code = value;
    if (code !== 'default') {
      onNewFilter && onNewFilter();
      setSelectedFilters((prev) => ({
        ...prev,
        ...objOf(code, {
          enabled: true,
        }),
      }));
      const filterOptions = filters.filter((filt) => filt.code === value)[0]?.options;
      if (filterOptions) {
        const valueChanged = { [value]: filterOptions[0].id };
        setChangedFilters((prevChangedFilters) => ({ ...prevChangedFilters, ...valueChanged }));
        if (handlerChangedFilters) {
          handlerChangedFilters(
            (prevChangedFilters) => ({ ...prevChangedFilters, ...valueChanged }),
          );
        }
      }
    }
  }, [onNewFilter]);

  const handleChangeFilterRow = useCallback((value) => {
    const newValue = !value.infringementClassification ? value : { ...value, infringementType: [] };
    setChangedFilters({ ...changedFilters, ...newValue });
    if (handlerChangedFilters) handlerChangedFilters({ ...changedFilters, ...newValue });
  }, [changedFilters]);

  const addFilterOptions = useMemo(
    () => (filters || []).filter((f) => !selectedFilters[f.code])
      .map((e) => ({ ...e, options: undefined })),
    [filters, selectedFilters],
  );

  return (
    <Card
      body
      id="dem-filter-section"
      className={mainClasses}
      as={Form}
      onSubmit={onSubmitHandler}
      ref={formFilterRef}
    >
      <Row className="actionsRow">
        <div
          className="cursor-pointer m-2"
          onClick={toggleCollapsed}
          onKeyPress={(ev) => {
            ev.persist();
            if (ev.key === ' ' || ev.key === 'Enter') {
              ev.preventDefault();
              !keyPressHold.current && toggleCollapsed();
            }
            keyPressHold.current = true;
          }}
          onKeyUp={() => {
            keyPressHold.current = false;
          }}
          role="button"
          tabIndex={0}
        >
          <span className="m-2">{t(tSchema.common.filters)}</span>
          {filtersHidden ? <IconSvg name="caretDown" /> : <IconSvg name="caretUp" />}
        </div>

        <Col>
          <div className="options">
            {filtersHidden && (
            <div className="filters-summary-fa">
              {compose(
                (x) => (x.length > 5 && showMoreFilter ? [...take(5)(x)] : x),
                values,
                mapObjIndexed((v, k) => {
                  const filter = find(propEq('code', k))(filters);
                  const value = getValue(v.value || v.defaultValue, k);

                  const labelFilter = prop('label', filter);
                  const valueFilter = value?.trim()?.length ? value : `"${value}"`;
                  const comparationLabel = searchOptions && find(propEq('value', v.comparation), searchOptions)?.label;
                  return (
                    !isNil(value) && (
                      <div key={k}>
                        {`${labelFilter} ${comparationLabel || '='} ${
                          v.comparation !== '=null' ? valueFilter : ''
                        }`}
                      </div>
                    )
                  );
                }),
              )(filterValues)}
              { Object.keys(filterValues).length > 5 && <Button type="button" variant="light" key="moreHidden" onClick={onShowMoreFilter}>...</Button>}
            </div>
            )}
            <Button
              className="clear-filters"
              variant="light"
              size="sm"
              onClick={resetFilters}
              disabled={
                          isUIBlocked
                          || !isEmpty(rFilter((x) => x, formErrors))
                          || isEmpty(selectedFilters)
                      }
            >
              {t(tSchema.common.clearFilters)}
            </Button>
          </div>
        </Col>
      </Row>
      {hasFilters && (
      <Row
        hidden={filtersHidden}
        className="mb-3"
      >
        <Col id="filter-list-section" sm={12}>
          {compose(
            map((f) => {
              const isRangeValue = (typeof changedFilters[f.code] === 'object' && f.type === 'date') || f.type === 'date-range';
              const filterWithDefaultOnChange = {
                ...f,
                defaultValue: !isRangeValue && changedFilters[f.code],
                rangeValue: isRangeValue && changedFilters[f.code],
                onChange: handleChangeFilterRow,
              };

              return (
                <FilterRow
                  filter={filterWithDefaultOnChange}
                  searchOptions={searchOptions}
                  setFormErrors={setFormErrors}
                />
              );
            }),
            rFilter((f) => selectedFilters[f.code]),
          )(filters || [])}
        </Col>
      </Row>
      )}
      <Row
        hidden={filtersHidden}
        id="newFilterSectionRow"
      >
        <Col
          sm={12}
          id="new-filter-section"
        >
          <Form.Group controlId="newFilterSelector" id="newFilterSelectorGroup">
            <Form.Label>
              {t(tSchema.common.addFilter)}
            </Form.Label>
            <InputSelect
              className="new-filter-selector"
              options={addFilterOptions}
              hasAction={false}
              value={null}
              onChange={onAddFilterChange}
              readOnly={isUIBlocked}
            />
          </Form.Group>
          <Button
            className="my-1 ml-1"
            variant="primary"
            type="submit"
            disabled={isUIBlocked || !isEmpty(rFilter((x) => x, formErrors))}
            id="add-filter"
            tabIndex={0}
            ref={formFilterRef}
          >
            {t(tSchema.common.applyFilters)}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.oneOfType([PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ), PropTypes.bool]),
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      single: PropTypes.bool,
    }),
  ).isRequired,
  searchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      type: PropTypes.string,
      single: PropTypes.bool,
    }),
  ),
  onUpdateSearchFilters: PropTypes.func.isRequired,
  initialFilters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ),
  onFiltersVisibilityChange: PropTypes.func,
  onNewFilter: PropTypes.func,
  isUIBlocked: PropTypes.bool,
  isOpen: PropTypes.bool,
  handlerChangedFilters: PropTypes.func,
};

Filters.defaultProps = {
  isUIBlocked: false,
  initialFilters: null,
  onFiltersVisibilityChange: null,
  onNewFilter: null,
  isOpen: false,
  searchOptions: [],
  handlerChangedFilters: null,
};

export default Filters;
