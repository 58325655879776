import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import PaginationReceived from './paginationReceived/PaginationReceived';
import { getDonorTableConfig, getReceivedTableConfig } from './utils';

const IsrViewerDetailsCatchProductsReceived = ({ data, loading }) => {
  const { t } = useTranslation();
  const receivedTableConfig = useMemo(() => getReceivedTableConfig(t), [t]);
  const donorTableConfig = useMemo(() => getDonorTableConfig(t), [t]);
  const [catches, setCatches] = useState([]);
  const [donorVessels, setDonorVessels] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setCatches(data[0].catchReceived);
      setDonorVessels(data[0].donorVessels);
    }
  }, [data]);

  const receivedTable = (
    <Container fluid className="isrReceived">
      <DataTable
        rowData={catches}
        idColumn="validityDate"
        tableId="isrReceivedTable"
        tableConfig={receivedTableConfig}
        showPagination={false}
      />
    </Container>
  );

  const donorTable = (
    <Container fluid className="isrDonor">
      <DataTable
        rowData={donorVessels}
        idColumn="validityDate"
        tableId="isrDonorTable"
        tableConfig={donorTableConfig}
        showPagination={false}
      />
    </Container>
  );

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      )
        : (
          <Container
            fluid
            style={{
              paddingBottom: '0.625rem',
            }}
            id="isrViewer-CatchProducts-Received"
          >
            <div>
              <PaginationReceived
                setCatches={setCatches}
                setDonorVessels={setDonorVessels}
                receivedData={data}
                loading={loading}
              />
            </div>
            <div>
              <FieldGroup label={t(tSchema.isrViewer.catches)}>
                {receivedTable}
              </FieldGroup>
              <FieldGroup label={t(tSchema.isrViewer.donorVessels)}>
                {donorTable}
              </FieldGroup>
            </div>

          </Container>
        )}
    </>
  );
};

IsrViewerDetailsCatchProductsReceived.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsCatchProductsReceived;
