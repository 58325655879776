import {
  formatDate, addFormatDate, subtractFormatDate, BACK_DATE_FORMAT,
} from '@common/dateUtils';

const orderByValidityDate = (a, b) => {
  const dateA = new Date(a.validityDate);
  const dateB = new Date(b.validityDate);
  return dateA - dateB;
};

const getIndexRow = (historicVesselsSort, row) => historicVesselsSort.findIndex(
  (element) => formatDate(
    element.validityDate, BACK_DATE_FORMAT, undefined, true,
  ) === formatDate(row.validityDate, BACK_DATE_FORMAT, undefined, true),
);

const sortByValidityDate = (historicVessels) => historicVessels.sort(orderByValidityDate);

const getMinMaxValidityDates = (historicVessels, row) => {
  let minDate;
  let maxDate;
  const sortedValidityDates = sortByValidityDate(historicVessels);
  const indexRow = getIndexRow(sortedValidityDates, row);

  if (sortedValidityDates?.length > 1) {
    if (indexRow === 0) {
      minDate = undefined;
      maxDate = sortedValidityDates[indexRow + 1].validityDate;
    }
    if (indexRow === sortedValidityDates?.length - 1) {
      minDate = sortedValidityDates[indexRow - 1].validityDate;
      maxDate = undefined;
    } else {
      maxDate = sortedValidityDates[indexRow + 1]?.validityDate;
      minDate = sortedValidityDates[indexRow - 1]?.validityDate;
    }
  }

  return {
    minValidityDate: minDate && addFormatDate(minDate, BACK_DATE_FORMAT),
    maxValidityDate: maxDate && subtractFormatDate(maxDate, BACK_DATE_FORMAT),
  };
};

export {
  sortByValidityDate,
  getMinMaxValidityDates,
  BACK_DATE_FORMAT,
};
