import {
  CHECK_LOGIN_SESSION,
  UPDATE_LOGED_IN,
  USER_LOGIN,
  USER_LOGOUT,
  LOGOUT,
  SET_USERNAME,
  SET_ROLES,
  SET_PERMISSIONS,

} from './actions';

const initialState = {
  authentication: {
    logedIn: false,
    userName: '',
    roles: [],
    permissions: [],
  },
};

function authentication(currentState, { type, payload }) {
  const state = currentState || initialState.authentication;
  const token = localStorage.getItem('login_token');
  switch (type) {
    case CHECK_LOGIN_SESSION:
      return {
        ...state,
        logedIn: token !== null,
      };
    case UPDATE_LOGED_IN:
      return { ...state, logedIn: payload };
    case USER_LOGIN:
      localStorage.setItem('login_token', 'dummy');
      return { ...state, logedIn: true };
    case USER_LOGOUT:
      localStorage.removeItem('login_token');
      return { ...state, logedIn: false };
    case LOGOUT:
      return { ...state, token: null };
    case SET_USERNAME:
      return { ...state, userName: payload };
    case SET_ROLES:
      return { ...state, roles: payload };
    case SET_PERMISSIONS:
      return { ...state, permissions: payload };
    default:
      return state;
  }
}

export const getLogedIn = (state) => state.authentication.logedIn;

export default authentication;
