import actions from './img/actions.JPG';
import editDynamicGroup from './img/edit_dynamic_group.JPG';
import editModal from './img/edit_modal.JPG';

const SectionVesselGroupEdit = () => (
  <div className="SectionVesselGroupEdit">
    <p>
      To edit a Vessel Group, click on the &quot;Actions&quot;
      button of the corresponding row and then select the &quot;Edit&quot; action.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal window opens displaying all the information about the vessel group:
    </p>
    <p>
      <img src={editModal} alt="Edit Modal" />
    </p>
    <p>
      For a Static Group, the list of vessels that are in the group is displayed;
      but in the case of a Dynamic Group, only the group criteria are displayed.
    </p>
    <p>
      <img src={editDynamicGroup} alt="Edit Dynamic Group" />
    </p>
  </div>
);

export default SectionVesselGroupEdit;
