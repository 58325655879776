/* eslint-disable import/order */

import { isEmpty } from 'ramda';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import IconSvg, { ICON } from '../../components/IconSvg/IconSvg';
import Filters from '../../componentsRecord/Filters';

import VesselHistoryModal from './components/VesselHistoricModal';

import { getTableConfig } from './config';
import { formatDate } from '@common/dateUtils';
import { createExcelFromReportsArray } from '@common/exportFileFunctions';
import { Permissions, hasPermission, hasAnyPermission } from '@common/keycloakFunctions';
import {
  clearEventTargetValue, formatHeaderSort, DIRECTION,
} from '@common/utils';
import DataTable from '@components/DataTable';
import UploadReport from '@components/UploadReport/UploadReport';
import tSchema from '@lang/schema';
import VesselViewerGroupsModal from '@pages/VesselsViewer/components/VesselViewerGroupsModal';
import VesselViewerModal from '@pages/VesselsViewer/components/VesselViewerModal';
import {
  cleanUploadVesselsReports,
  fetchAllVessels,
  fetchEntityVessel,
  fetchVesselById,
  getSearchableAttributes,
  getFormData,
  getVesselPorts,
  setPagination,
  uploadVessels,
  exportVessels,
  fetchVesselsGroups,
  fetchGroupsFromVessel,
  clearVesselsAndPagination, cleanHistoric,
} from '@services/vessel_record/actions/vessel';
import './style.scss';
import { getVersionsFromVesselChanges } from '@services/vessel_record/actions/vessel/historic';

function handleScroll() {
  const popUps = document.getElementsByClassName('vesselActions');
  for (let i = 0; i < popUps.length; i += 1) {
    popUps[i].style.display = 'none';
  }
}

const schema = tSchema.vesselMGMT;

const defaultOrder = {
  field: 'transactionDate',
  order: DIRECTION.DESC,
};

const VesselsViewer = () => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const dispatch = useDispatch();

  const vesselsList = useSelector((state) => state.vessel.vessels);
  const groupsList = useSelector((state) => state.vessel.vesselGroups.content || []);
  const currentGroups = useSelector((state) => state.vessel.currentGroups || []);
  const isLoading = useSelector((state) => state.vessel.isLoading);
  const isExporting = useSelector((state) => state.vessel.isExporting);
  const error = useSelector((state) => state.vessel.error);
  const searchableAttributes = useSelector((state) => state.vessel.searchableAttributes);
  const pagination = useSelector((state) => state.vessel.pagination);
  const userName = useSelector((state) => state.authentication.userName);
  const permissions = useSelector((state) => state.authentication.permissions);
  const template = useSelector((state) => state.vessel.template);
  const uploadVesselsReport = useSelector((state) => state.vessel.uploadVessels);
  const dataTableRef = useRef();
  const [orderBy, setOrderBy] = useState(defaultOrder);
  const [showHistoricModal, setShowHistoricModal] = useState(false);
  const [vesselSelected, setVesselSelected] = useState(null);
  const [visualizationForm, setVisualizationForm] = useState('VIEW'); // Choose the way the user see the form
  const [showVesselViewerModal, setShowVesselViewerModal] = React.useState(false);
  const [isShowingExport, setisShowingExport] = useState(false);

  const [showModalGroups, setshowModalGroups] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [isSearchPeriod, setIsSearchPeriod] = useState(false);
  const [isSearchOnDate, setIsSearchOnDate] = useState(false);

  useEffect(() => () => dispatch(clearVesselsAndPagination()), []);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
  };

  const setPageRequest = async (page) => {
    const filtersBody = {
      ...pagination.filtersBody,
      ...(orderBy ? {
        sortFields: [
          {
            field: orderBy.field,
            order: orderBy.order?.toUpperCase(),
          },
        ],
      } : {}),
    };
    dispatch(fetchAllVessels({
      page,
      count: pagination.size,
      filtersBody,
      isSearchPeriod,
      isSearchOnDate,
    }));
    dispatch(setPagination({
      page,
      size: pagination.size,
      filtersBody: pagination.filtersBody,
    }));
  };

  const setPageSize = async (size) => {
    const filtersBody = {
      ...pagination.filtersBody,
      ...(orderBy ? {
        sortFields: [
          {
            field: orderBy.field,
            order: orderBy.order?.toUpperCase(),
          },
        ],
      } : {}),
    };
    dispatch(fetchAllVessels({
      page: 1,
      count: size,
      filtersBody,
      isSearchPeriod,
      isSearchOnDate,
    }));
    dispatch(setPagination({
      page: 1,
      size,
      filtersBody: pagination.filtersBody,
    }));
  };

  function FormatVesselsList() {
    return vesselsList.map((vessel) => ({
      ...vessel,
      validityDate: formatDate(vessel.validityDate, undefined, undefined, true),
    }));
  }

  const getVessels = async () => {
    const filtersBody = {
      ...pagination.filtersBody,
      ...(orderBy ? {
        sortFields: [
          {
            field: orderBy.field,
            order: orderBy.order?.toUpperCase(),
          },
        ],
      } : {}),
    };
    dispatch(fetchAllVessels({
      page: pagination.page,
      count: pagination.size,
      filtersBody,
      isSearchPeriod,
      isSearchOnDate,
    }));
    dispatch(setPagination({
      page: pagination.page,
      size: pagination.size,
      filtersBody,
    }));
  };

  async function exportAllVessels() {
    dispatch(exportVessels({
      count: pagination.totalCount,
      filtersBody: pagination.filtersBody,
    }));
  }

  const prepareHistoric = async (etalonId) => {
    dispatch(fetchVesselById(etalonId));
    dispatch(getVersionsFromVesselChanges(etalonId));
  };

  /*
    It runs when the component VesselsViewer loads
    Calls the action that returns all the vessels
  */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    dispatch(getSearchableAttributes());
    dispatch(getFormData());
    dispatch(getVesselPorts());
  }, []);

  /*
    If vessels were uploaded from a file, is neccesary to
    reload the vessels (same pagination and filters as before)
  */
  useEffect(() => {
    if (uploadVesselsReport.isUploadingVessels === false
      && uploadVesselsReport.reports !== null) {
      getVessels();
    }
  }, [uploadVesselsReport]);

  useEffect(() => {
    if (!isEmpty(orderBy)) {
      getVessels();
    }
  }, [orderBy]);

  useEffect(() => {
    if (groupsList.length !== 0) {
      const correctedGroups = groupsList.filter(
        (group) => currentGroups.every((currentgroup) => currentgroup.id !== group.id),
      );

      setAvailableGroups(correctedGroups.filter(
        (group) => group.vesselGroupRecordCriteriaEntityList.length === 0,
      ));
    }
  }, [groupsList, currentGroups]);

  const showGridHistoric = useCallback(
    (row) => {
      setShowHistoricModal(true);
      setVesselSelected(row);
      setVisualizationForm('VIEW');
      hasPermission(
        permissions,
        Permissions.APPROVE_MODIFICATIONS,
      );
    }, [prepareHistoric, permissions],
  );

  const showHistoricalRow = useCallback(
    (row) => {
      prepareHistoric(row.etalon_id);
      setVisualizationForm('HISTORIC');
      setShowVesselViewerModal(true);
    }, [],
  );
  const vesselGroupsRow = useCallback(
    async (row) => {
      dispatch(fetchVesselById(row.etalon_id));
      dispatch(fetchVesselsGroups());
      dispatch(fetchGroupsFromVessel(row.etalon_id));
      setshowModalGroups(true);
    }, [],
  );
  const actions = useMemo(() => [

    {
      label: t(tSchema.common.view),
      action: showGridHistoric,
      disabled: !hasPermission(permissions, Permissions.READ_DATA),
    },
    {
      label: 'Show historical',
      action: showHistoricalRow,
      disabled: !hasAnyPermission(
        permissions,
        [
          Permissions.READ_DATA,
          Permissions.WRITE_DATA,
          Permissions.WRITE_HISTORIC_DATA,
        ],
      ),
    },
    {
      label: 'Vessel groups',
      action: vesselGroupsRow,
      disabled: !hasAnyPermission(
        permissions,
        [
          Permissions.USER_AND_GROUPS_MANAGEMENT,
        ],
      ),
    },
  ], [
    showGridHistoric,
    showHistoricalRow,
    vesselGroupsRow,
    permissions,
  ]);

  const inputOpenFileRef = React.createRef();

  const changeVisualizationGroups = useCallback((changeValue) => {
    setshowModalGroups(changeValue);
  }, [setshowModalGroups]);

  const getOnTableHeaderClick = useCallback((header) => {
    !isLoading && setOrderBy((prevState) => (formatHeaderSort(header, prevState, defaultOrder)));
  }, [isLoading]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && showGridHistoric(row), // Double Click
    [showGridHistoric],
  );

  const uploadedReport = useMemo(() => uploadVesselsReport?.reports && [
    {
      data: uploadVesselsReport.reports.inserted,
      icon: ICON.CHECKED,
      title: schema.upload.inserted,
    },
    {
      data: uploadVesselsReport.reports.updated,
      icon: ICON.CHECKED,
      title: schema.upload.updated,
    },
    {
      data: uploadVesselsReport.reports.insertedWithWarnings,
      icon: ICON.WARN,
      title: schema.upload.insertedWithWarnings,
    },
    {
      data: uploadVesselsReport.reports.updatedWithWarnings,
      icon: ICON.WARN,
      title: schema.upload.updatedWithWarnings,
    },
    {
      data: uploadVesselsReport.reports.errors,
      icon: ICON.CANCEL,
      title: schema.upload.errors,
    },
  ],
  [uploadVesselsReport.reports]);

  return (
    <div id="vesselsViewer">
      <Container fluid>
        <Row>
          <Col xs={12}>
            {permissions.length > 0
              && (
              <Filters
                permissions={permissions}
                searchableAttributes={searchableAttributes}
                formDataList={template.formData}
                pagination={pagination}
                onUpdateSearchFilters={(filters) => {
                  setIsSearchPeriod(filters.isSearchPeriod);
                  setIsSearchOnDate(filters.isSearchOnDate);
                  dispatch(setPagination({
                    page: filters.page,
                    size: filters.count,
                    filtersBody: filters.filtersBody,
                  }));
                  dispatch(fetchAllVessels({
                    ...filters,
                    ...(orderBy ? {
                      sortFields: [
                        {
                          field: orderBy.field,
                          order: orderBy.order?.toUpperCase(),
                        },
                      ],
                    } : {}),
                  }));
                  resetPagination();
                }}
                hasDefaultFilters
              />
              )}
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              actions={actions}
              idColumn="etalon_id"
              tableId="vessels"
              ref={dataTableRef}
              totalResults={pagination.totalCount}
              showTotalResults
              tableConfig={tableConfig}
              orderBy={isEmpty(orderBy) ? null : orderBy}
              onTableHeaderClick={getOnTableHeaderClick}
              rowData={FormatVesselsList()}
              pageSize={pagination.size}
              onPageSizeChangeHandler={setPageSize}
              onRowSelected={handleRowSelected}
              onPageChangeHandler={setPageRequest}
              loading={isLoading}
              maxPages={pagination.maxPages}
              pageRequest={pagination.page}
              tableActions={
                hasAnyPermission(
                  permissions,
                  [Permissions.READ_DATA, Permissions.WRITE_DATA],
                )
                && (
                  <div style={{ display: 'flex', alignItems: 'right' }}>
                    { hasPermission(permissions, Permissions.WRITE_DATA) && (
                      <>
                        <Form.Control
                          type="file"
                          ref={inputOpenFileRef}
                          style={{ display: 'none' }}
                          onChange={(e) => {
                            if (e.target.files[0] != null) {
                              dispatch(uploadVessels(e.target.files[0], `EFCA (${userName})`));
                            }
                          }}
                          // Allow to select the same file twice
                          onClick={clearEventTargetValue}
                        />

                        <Dropdown
                          style={{ marginRight: '0.313rem' }}
                          onSelect={(key) => {
                            if (key === 'csv') {
                              inputOpenFileRef.current.accept = '.csv';
                              inputOpenFileRef.current.click();
                            } else if (key === 'excel') {
                              inputOpenFileRef.current.accept = '.xls,.xlsx';
                              inputOpenFileRef.current.click();
                            }
                          }}
                        >
                          <Dropdown.Toggle
                            className="symbol-upload"
                            variant="primary"
                            placement="top-start"
                          >
                            <IconSvg name={ICON.UPLOAD} />
                            {t(schema.importFrom)}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="csv">{t(tSchema.common.csv)}</Dropdown.Item>
                            <Dropdown.Item eventKey="excel">{t(tSchema.common.excel)}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    )}
                    { hasAnyPermission(
                      permissions,
                      [Permissions.READ_DATA, Permissions.WRITE_DATA],
                    )
                      && (
                        <Button
                          id="export-to-excel"
                          style={{ display: 'flex' }}
                          className="symbol-download"
                          onClick={() => {
                            setisShowingExport(true);
                            exportAllVessels();
                          }}
                          disabled={!pagination.totalCount > 0}
                        >
                          <IconSvg name={ICON.DOWNLOAD} />
                          {t(schema.exportToExcel)}
                        </Button>
                      )}
                    { hasPermission(permissions, Permissions.WRITE_DATA) && (
                      <Button
                        id="new-vessel"
                        className="symbol-plus"
                        style={{ marginLeft: '0.313rem' }}
                        onClick={() => {
                          dispatch(fetchEntityVessel());
                          setVisualizationForm('CREATE');
                          setShowVesselViewerModal(true);
                        }}
                        disabled={isLoading}
                      >
                        Add Vessel
                      </Button>
                    )}
                  </div>
                )

              }
              error={error}
              errorMessageEmptyArray="No vessels found"
              errorMessageLoadingData="An error has occurred loading the vessels"
            />
          </Col>
        </Row>
      </Container>
      {vesselSelected
      && (
      <VesselHistoryModal
        show={showHistoricModal}
        vessel={vesselSelected}
        setVisualizationForm={setVisualizationForm}
        onHide={() => {
          setShowHistoricModal(false);
          dispatch(cleanHistoric());
        }}
        visualizationForm={visualizationForm}
      />
      )}
      <VesselViewerModal
        showModal={showVesselViewerModal}
        visualizationForm={visualizationForm}
        onClose={() => setShowVesselViewerModal(false)}
      />

      <Modal
        backdrop="static"
        show={uploadVesselsReport.isUploadingVessels}
      >
        <Modal.Body>
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '40px', height: '40px' }} />
            <div className="label" style={{ fontSize: '1.188rem' }}>Uploading Vessels</div>
          </div>
          <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
            This process may take a few minutes...
          </div>
        </Modal.Body>
      </Modal>

      <VesselViewerGroupsModal
        showModal={showModalGroups}
        availableGroups={availableGroups}
        changeVisualizationGroups={changeVisualizationGroups}
      />
      <Modal
        backdrop="static"
        show={uploadVesselsReport.reports != null}
        onHide={() => { dispatch(cleanUploadVesselsReports()); }}
        size="auto-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t(schema.upload.title)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <UploadReport reports={uploadedReport} error={uploadVesselsReport.error && 'There was an error'} />
          </div>
          {
            (uploadVesselsReport.reports?.insertedWithWarnings
            || uploadVesselsReport.reports?.updatedWithWarnings
            || uploadVesselsReport.reports?.errors)
            && (
              <div className="row marginVertical" style={{ justifyContent: 'center', marginTop: '0.625rem' }}>
                <Button
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  className="symbol-download ml-2"
                  onClick={
                () => createExcelFromReportsArray(
                  uploadVesselsReport.reports,
                  userName,
                )
              }
                >
                  <IconSvg name={ICON.DOWNLOAD} />
                  <div>
                    {t(schema.upload.downloadReport)}
                  </div>
                </Button>
              </div>
            )
          }
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={uploadVesselsReport.error != null}
        onHide={() => { dispatch(cleanUploadVesselsReports()); }}
      >
        <Modal.Body>
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <IconSvg name="danger-triangle" />
            <div className="label" style={{ fontSize: '1.188rem' }}>There was a mistake</div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={isExporting && isShowingExport}
      >
        <Modal.Body>
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '40px', height: '40px' }} />
            <div className="label" style={{ fontSize: '1.188rem' }}>Exporting Vessels</div>
          </div>
          <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
            This process may take a few minutes...
          </div>
          <div className="row" style={{ marginTop: '0.625rem', justifyContent: 'center', fontSize: '0.875rem' }}>
            <Button
              id="close-modal"
              style={{ display: 'flex', marginTop: '1.25rem' }}
              onClick={() => {
                setisShowingExport(false);
              }}
            >
              Run this task in background
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default VesselsViewer;
