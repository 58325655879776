import { useCallback, useEffect, useState } from 'react';

import * as apiService from '@services/failover/api';

import { requestWithMessage } from '@common/utilities/Notification';

const useFailoverList = (showModal) => {
  const [failoverList, setFailoverList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFailOverParametersList = useCallback(async () => {
    await requestWithMessage({
      errorMessage: 'Failed to fetch failover parameter',
      request: () => apiService.getParametersList(),
      onSuccess: async (response) => {
        const data = await response.json();
        setFailoverList(data);
      },
      setLoading: setIsLoading,
    });
  }, [apiService]);

  useEffect(() => {
    if (showModal === false) {
      fetchFailOverParametersList();
    }
  }, [fetchFailOverParametersList, showModal]);

  return { isLoading, failoverList };
};

export default useFailoverList;
