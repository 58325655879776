import buttonCreate from './img/action_create_query.JPG';
import error from './img/error_form.JPG';
import form from './img/modal_create_query.JPG';

const SectionAddQuery = () => (
  <div className="SectionAddAcceptance">
    <p>
      Below the results grid, the user shall click on the “Create Query” button.
    </p>
    <p>
      <img src={buttonCreate} alt="Button create" />
    </p>
    <p>
      Once the user clicks on the button, the creation form is displayed in a modal window.
    </p>
    <p>
      <img src={form} alt="Form create" />
    </p>
    <p>
      After filling the form fields, the user shall click on the &quot;Save&quot; button.
      If there are no errors,
      the modal window is closed and the results grid is updated with the new query.
    </p>
    <p>
      If there is any error in the form, the fields with errors are highlighted in red.
      The errors have to be corrected before saving the form.
    </p>
    <p>
      <img src={error} alt="Error in form" />
    </p>
  </div>
);

export default SectionAddQuery;
