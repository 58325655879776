import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import {
  Col,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BasicTable from '@pages/FishingTripsLogbook/components/ReportDetailsSection/components/tables/BasicTable';

import tSchema from '@lang/schema';

import { fetchExternalSources } from '@services/externalSources/actions';
import { execute } from '@services/externalSources/api';

import ExternalSourcesDataRow from './components/ExternalSourcesDataRow';
import ExternalSourceModal from './modal/EditExternalSourceModal';

import { getTableConfig } from './config';

import { BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';
import { showErrorNotification, showOkNotification } from '@common/utilities/Notification';

const schema = tSchema.dmaAdmin.externalSources.editView;

const ExternalSources = React.memo(() => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const externalSources = useSelector((state) => state.externalSources.sources);
  const isLoading = useSelector((state) => state.externalSources.loading);
  const [modalView, setModalView] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editRow, setEditRow] = useState({});
  const dispatch = useDispatch();

  const getExternalSources = useCallback(() => {
    dispatch(fetchExternalSources());
  }, []);

  const onExecute = useCallback((source) => {
    execute(source)
      .then((res) => {
        if (res.status === 200) {
          showOkNotification(
            t(tSchema.notifications.dmaAdmin.externalSources.ok.execute),
          );
          getExternalSources();
        } else {
          showErrorNotification(
            t(tSchema.notifications.dmaAdmin.externalSources.error.execute),
          );
        }
      })
      .catch(() => showErrorNotification(
        t(tSchema.notifications.dmaAdmin.externalSources.error.execute),
      ))
      .finally(() => {
        setModalView(false);
      });
  }, []);

  const onModalEdit = useCallback((row) => {
    setEditRow(row);
    setModalView(true);
    setEditMode(true);
  }, []);

  const onModalView = useCallback((row) => {
    setEditRow(row);
    setModalView(true);
    setEditMode(false);
  }, []);

  useEffect(() => {
    if (modalView === false) {
      getExternalSources();
    }
  }, [editMode, modalView]);

  const actions = useMemo(
    () => [
      {
        label: t(tSchema.common.edit),
        action: onModalEdit,
      },
      {
        label: t(tSchema.common.view),
        action: onModalView,
      },
      {
        label: t(tSchema.common.execute),
        action: onExecute,
      },
    ],
    [onModalEdit, onModalView, onExecute],
  );

  const renderExternalSourcesDataRow = ({
    row, id, cols, selectedId, onClick: handleClickRow,
  }) => (
    <ExternalSourcesDataRow
      cols={cols}
      id={id}
      onClick={handleClickRow}
      row={row}
      selectedId={selectedId}
    />
  );

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && row.externalSource !== 'EUFLEET' && onModalView(row), // Double Click
    [onModalView],
  );

  const externalSourcesFormatted = useMemo(
    () => externalSources.map((row) => ({
      ...row,
      lastExecutionDateTime: [formatDate(row.lastExecutionDate, BACK_DATE_FORMAT, undefined, true), row.lastExecutionTime].filter(Boolean).join(' '),
    })),
    [externalSources],
  );

  return (
    <div id="vesselsViewer">
      <Container fluid>
        <Row>
          <Col>
            <BasicTable
              actions={actions}
              idColumn="externalSource"
              headers={tableConfig}
              data={externalSourcesFormatted || []}
              loading={isLoading}
              rowRender={renderExternalSourcesDataRow}
              tableId="dynamic-table-external-sources"
              pageSize={10}
              pageSizeOptions={[10, 25, 50, 100]}
              onRowSelected={handleRowSelected}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        backdrop="static"
        show={modalView}
        onHide={() => setModalView(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>
              {editMode ? t(tSchema.common.edit) : t(tSchema.common.view)}
              {' '}
              {t(schema.externalSource)}
              {editRow.externalSource}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExternalSourceModal
            attributes={editRow}
            readOnly={!editMode}
            onClose={() => setModalView(false)}
            onExecute={onExecute}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default ExternalSources;
