import { Container } from 'react-bootstrap';

import QueryAcceptanceSection from './components/QueryAcceptanceRules';

import './style.scss';

const EfcaRulesViewer = () => (
  <Container fluid id="efca-rules-viewer">
    <QueryAcceptanceSection />
  </Container>
);

EfcaRulesViewer.propTypes = {
};

export default EfcaRulesViewer;
