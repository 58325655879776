import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const InputNumber = ({
  id,
  value,
  readOnly,
  onChange,
  isValid = true,
  setIsValid,
  required,
  decimals = true,
  className,
  complex = false,
  hasAction = true,
  min,
  max,
  ...props
}) => {
  const dispatch = useDispatch();
  const handleChange = useCallback((e) => {
    const changeValue = e.target.value === '' ? null : e.target.value;
    if (hasAction) {
      !complex
        ? dispatch(onChange(id, changeValue))
        : dispatch(onChange(props.complexName, id, props.index, changeValue));
      if (required && !isValid && changeValue !== '') {
        dispatch(setIsValid(id, true));
      }
    } else {
      onChange(id, changeValue);
    }
  }, [hasAction, complex, required, isValid, id, props, min, max]);

  const handleBlur = useCallback((e) => {
    e.target.value = e.target.value && (min || max)
      ? Math.min(Math.max(e.target.value, min), max)
      : e.target.value;
    handleChange(e);
  },
  [handleChange, min, max]);

  return (
    <div className={className}>
      <Form.Control
        key={id}
        type="number"
        disabled={readOnly}
        value={value !== null ? value : ''}
        onChange={handleChange}
        onBlur={handleBlur}
        min={min}
        max={max}
        style={{
          border: isValid ? '' : '0.125rem solid red',
        }}
        step={decimals ? '0.01' : '1'}
        {...props}
      />
    </div>
  );
};

InputNumber.defaultProps = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  decimals: PropTypes.bool,
  className: PropTypes.string,
  complex: false,
  complexName: PropTypes.string,
  index: PropTypes.number,
  hasAction: true,
  min: undefined,
  max: undefined,
};

InputNumber.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  decimals: PropTypes.bool,
  className: PropTypes.string,
  complex: false,
  complexName: PropTypes.string,
  index: PropTypes.number,
  hasAction: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default InputNumber;
