import tSchema from '@lang/schema';

import formatChecked from '../utils';

const schema = tSchema.isrViewer;

export function getInspectorsTableConfig(t) {
  return [
    {
      text: t(schema.schemeId),
      code: 'schemeId',
      render: (row) => (row.inspectorSchemeID && row.inspectorID
        ? `${row.inspectorSchemeID} - ${row.inspectorID}` : row.inspectorSchemeID || row.inspectorID),
    },
    { text: t(schema.nationality), code: 'nationality' },
    { text: t(schema.name), code: 'name' },
    { text: t(schema.role), code: 'role' },
    {
      text: t(schema.cardPresented),
      code: 'cardPresented',
      render: (row) => (formatChecked(row.cardPresented, t)),
    },
  ];
}

export function getStatusTableConfig(t) {
  return [
    { text: t(schema.rfmo), code: 'rfmo' },
    { text: t(schema.vesselId), code: 'vesselID' },
    {
      text: t(schema.authorizedList),
      code: 'authorisedList',
      render: (row) => (formatChecked(row.authorisedList, t)),
    },
    {
      text: t(schema.iuuList),
      code: 'iuuList',
      render: (row) => (formatChecked(row.iuuList, t)),
    },
  ];
}
