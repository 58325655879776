import actions from './img/actions.png';
import viewReport from './img/viewReport.png';

const SectionViewReport = () => (
  <div className="SectionViewReport">
    <p>
      To view a report,
      the user has to select &quot;View&quot; in the &quot;Actions&quot;
      button for the corresponding report.
      The user could realize the same action by double clicking on the line in the result Grid.
    </p>
    <p>
      The user could perform the same action by double clicking on the corresponding line
      in the results Grid.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A new tab opens showing the information of the report.
    </p>
    <img src={viewReport} alt="View Report" />
  </div>
);

export default SectionViewReport;
