/* eslint-disable import/order */

import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import IconSvg from '@components/IconSvg';
import { removeComplexRow } from '@services/vessel_record/actions/vessel';
import VesselInput from './VesselInput';

const VesselComplexInput = ({
  classSpace,
  attrName,
  attributes,
  visualization,
}) => {
  const thirdPartyVesselsSelected = useSelector((state) => state.vessel?.vessel?.thirdPartyVessel);
  const template = useSelector((state) => state.vessel.template);
  const filterTemplate = (templateToFilter, index) => {
    if (attrName === 'thirdPartyVessel') {
      const filteredVessels = [];
      templateToFilter?.formData?.vesselThirdPartyCode?.forEach((element) => {
        let foundVessel = false;
        thirdPartyVesselsSelected.forEach((thirdVesselSelected, indexVessel) => {
          // Se filtra en el resto de Selects de ThirdPartyVessel,
          // por eso la comprobación de los index
          if (index !== indexVessel
            && element.code === thirdVesselSelected.code) { foundVessel = true; }
        });
        if (!foundVessel) { filteredVessels.push(element); }
      });
      return {
        ...templateToFilter,
        formData:
        {
          ...templateToFilter.formData,
          vesselThirdPartyCode: filteredVessels,
        },
      };
    }
    return templateToFilter;
  };
  const dispatch = useDispatch();

  const index = attributes.findIndex((attr) => attr.name === attrName);

  if (index !== null) {
    if (attributes[index].value) {
      return (
        <div className="row row-form-text text-modal">
          {attributes[index].value.map((value, indx) => (
            <>
              {attributes[index].simpleAttributes.map((simpleAttribute, idx) => (
                <Form.Group className={`${classSpace[idx]} lineMarginTop`}>
                  <Form.Label className="label-modal">
                    {attrName !== 'subsidiaryFishingGears' ? simpleAttribute.displayName : `#${indx + 1} Auxiliary Fishing Gear`}
                  </Form.Label>
                  <VesselInput
                    visualization={visualization}
                    inputType={simpleAttribute.simpleDataType.toLowerCase()}
                    attribute={simpleAttribute}
                    template={filterTemplate(template, indx)}
                    complex
                    complexValue={value}
                    complexName={attrName}
                    complexIndex={indx}
                  />
                </Form.Group>
              ))}
              {visualization !== 'VIEW' ? (
                <Button
                  onClick={() => dispatch(removeComplexRow(attrName, indx))}
                  className="inline-button"
                >
                  <IconSvg name="delete" className="no-margin" />
                </Button>
              ) : <></>}
            </>
          ))}
        </div>
      );
    }
    return <></>;
  }
  return <></>;
};

VesselComplexInput.propTypes = {
  classSpace: PropTypes.arrayOf(PropTypes.string).isRequired,
  attrName: PropTypes.string.isRequired,
  visualization: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VesselComplexInput;
