/* eslint-disable prefer-const */
import * as XLSX from 'sheetjs-style';

const formatVesselToExcelData = (vessel, attributesList) => {
  const attributes = attributesList.map((attr) => attr.name);

  const formattedVesselArray = Array.isArray(vessel)
    ? vessel.map((uniqueVessel) => attributes.map((attribute) => uniqueVessel[attribute]))
    : attributes.map((attribute) => vessel[attribute]);

  return [attributes, formattedVesselArray];
};

const formatReportsToExcelData = (reports) => {
  const columns = [
    'Row Number',
    'Status',
    'Attribute',
    'Value',
    'Result',
    'BR',
    'Message',
  ];
  let formattedReports = [];

  reports.insertedWithWarnings.forEach((report) => {
    report.BR?.forEach((error) => {
      const row = [
        report.positionInFile + 1,
        'ACCEPTED',
        error.referencedItem,
        report[error.referencedItem],
        error.resultState,
        error.ruleName,
        error.description,
      ];
      formattedReports.push(row);
    });
  });

  reports.updatedWithWarnings.forEach((report) => {
    report.BR?.forEach((error) => {
      const row = [
        report.positionInFile + 1,
        'ACCEPTED',
        error.referencedItem,
        report[error.referencedItem],
        error.resultState,
        error.ruleName,
        error.description,
      ];
      formattedReports.push(row);
    });
  });

  reports.errors.forEach((report) => {
    report.BR?.forEach((error) => {
      const row = [
        report.positionInFile + 1,
        'REJECTED',
        error.referencedItem,
        report[error.referencedItem],
        error.resultState,
        error.ruleName,
        error.description,
      ];
      formattedReports.push(row);
    });
  });

  const data = [columns, ...formattedReports];
  return data;
};

const calculateColumnsWidth = (data) => {
  let widthCols = [];
  for (let i = 0; i < data[0].length; i += 1) {
    widthCols.push({ wch: data[0][i].length });
  }
  for (let i = 1; i < data.length; i += 1) {
    for (let j = 0; j < data[i].length; j += 1) {
      if (widthCols[j].wch < data[i][j]?.length) {
        widthCols[j].wch = data[i][j]?.length;
      }
    }
  }
  return widthCols;
};

const numberToLetters = (num) => {
  let letters = '';
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
    // eslint-disable-next-line no-param-reassign
    num = Math.floor(num / 26) - 1;
  }
  return letters;
};

const createExcelFromUniqueVessel = (vessel, attributesList, userName) => {
  // 1. Se crea el excel
  let wb = XLSX.utils.book_new();

  // Se define el nombre del fichero con un parámetro por defecto en caso de no ser seleccionado
  let title = vessel.cfr ? vessel.cfr : vessel.uvi;

  // 2. Se le asignan las propiedades
  wb.Props = {
    Title: title,
    Author: userName,
    CreatedDate: new Date(),
  };
  // 3. Se añade una hoja de trabajo
  wb.SheetNames.push('Vessel');

  // 4. Se meten los datos en un array con el formato correcto
  let formattedData = formatVesselToExcelData(vessel, attributesList);
  // formattedData[0]

  // 5. Se crea una hoja con el array
  let ws = XLSX.utils.aoa_to_sheet(formattedData);
  ws['!cols'] = calculateColumnsWidth(formattedData);

  for (let i = 0; i < attributesList.length; i += 1) {
    ws[`${numberToLetters(i)}1`].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FF088404' },
      },
      font: {
        name: 'Calibri',
        sz: 13,
        bold: true,
        color: { rgb: 'FFFFFFFF' },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
      },
    };
  }

  // 6. Se pone la hoja en el lugar de la creada en el excel
  // eslint-disable-next-line dot-notation
  wb.Sheets['Vessel'] = ws;

  // 7. Se pasa el excel a formato binario
  XLSX.writeFile(wb, `${title}.xlsx`, { bookType: 'xlsx', bookSST: false, type: 'array' });
};

const createExcelFromReportsArray = (reports, userName) => {
  // 1. Se crea el excel
  let wb = XLSX.utils.book_new();

  // 2. Se le asignan las propiedades
  wb.Props = {
    Title: 'Reports',
    Author: userName,
    CreatedDate: new Date(),
  };

  // 3. Se añade una hoja de trabajo
  wb.SheetNames.push('Reports');

  // 4. Se meten los datos en un array con el formato correcto
  let formattedData = formatReportsToExcelData(reports);
  let wsData = formattedData;

  // 5. Se crea una hoja con el array
  let ws = XLSX.utils.aoa_to_sheet(wsData);
  ws['!cols'] = calculateColumnsWidth(formattedData);

  // 6. Se pone la hoja en el lugar de la creada en el excel
  // eslint-disable-next-line dot-notation
  wb.Sheets['Reports'] = ws;

  // 7. Se pasa el excel a formato binario
  XLSX.writeFile(wb, 'Reports.xlsx', { bookType: 'xlsx', bookSST: false, type: 'array' });
};

function saveToArrayBuffer(s) {
  let buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  let view = new Uint8Array(buf); // create uint8array as viewer
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xFF; // convert to octet
  return buf;
}

export {
  createExcelFromUniqueVessel,
  createExcelFromReportsArray,
  saveToArrayBuffer,
};
