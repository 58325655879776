/* eslint-disable import/prefer-default-export */
import {
  get,
} from '../utils';

const baseURL = 'ums/';

export const apiFetchUsers = () => get()(`${baseURL}users`);

export const apiFetchFilteredUsers = (searchFilters) => (get()(`${baseURL}users/filter/${searchFilters}`));
