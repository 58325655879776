import SectionEditSource from './SectionEditSource';
import SectionExecuteSource from './SectionExecuteSource';
import SectionSourceTable from './SectionSourceTable';
import SectionViewSource from './SectionViewSource';

const EXTERNAL_SOURCES = {
  SOURCE_TABLE: {
    title: 'External Sources Grid',
    body: <SectionSourceTable />,
  },
  EDIT_SOURCE: {
    title: 'Edit External Source',
    body: <SectionEditSource />,
  },
  VIEW_SOURCE: {
    title: 'View External Source',
    body: <SectionViewSource />,
  },
  EXECUTE_SOURCE: {
    title: 'Execute External Source',
    body: <SectionExecuteSource />,
  },

};

export default EXTERNAL_SOURCES;
