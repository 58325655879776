/* eslint-disable import/order */

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ValidationWarning from '@components/Form/ValidationWarning';
import { INPUT_TYPES } from '../inputs/constants';
import VesselComplexInput from '../inputs/VesselComplexInput';
import VesselFormGroup from '../inputs/VesselFormGroup';
import LineSeparator from '../LineSeparator';
import VesselImages from '../VesselImages';

const TabVesselTechnicalDetails = ({
  attributes,
  addComplexRow,
  visualization,
  addGear,
  errorsId,
  attributeExists,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="mainFishingGear"
          classSpace="input-24"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="loa"
          classSpace="input-14"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="lbp"
          classSpace="input-14"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="hullMaterial"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="breadth"
          classSpace="input-14"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="draught"
          classSpace="input-14"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
      </div>

      {(visualization !== 'VIEW' || (visualization === 'VIEW' && attributeExists('subsidiaryFishingGears'))) && (
        <LineSeparator name="Auxiliary Fishing Gears" position="top">
          &nbsp;
          {
            errorsId('subsidiaryFishingGears').length !== 0
            && <ValidationWarning messages={errorsId('subsidiaryFishingGears')} />
          }
        </LineSeparator>
      )}
      <VesselComplexInput
        attrName="subsidiaryFishingGears"
        classSpace={[visualization !== 'VIEW' ? 'input-45' : 'input-half']}
        attributes={attributes}
        visualization={visualization}
      />
      {visualization !== 'VIEW' && addGear ? (
        <div className="bottomFormButton left mb-3">
          <Button
            onClick={() => dispatch(addComplexRow('subsidiaryFishingGears'))}
          >
            Add an Auxiliary Fishing Gear
          </Button>
        </div>
      ) : <></>}
      {(visualization !== 'VIEW' || (visualization === 'VIEW' && attributeExists('subsidiaryFishingGears'))) && (
        <LineSeparator position="bottom" />
      )}

      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="powerOfMainEngine"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="powerOfAuxiliaryEngine"
          classSpace="input-23"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="tonnageGT"
          classSpace="input-18"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="otherTonnage"
          classSpace="input-19"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="yearOfConstruction"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.INTEGER}
          min={1850}
          max={2500}
          attributes={attributes}
          visualization={visualization}
        />
      </div>
      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="numberOfFishHolds"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.INTEGER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="fishHoldCapacity"
          classSpace="input-23"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="storageCapacity"
          classSpace="input-18"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="freezingCapacity"
          classSpace="input-19"
          inputType={INPUT_TYPES.NUMBER}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="dateOfEntryIntoService"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.DATE}
          attributes={attributes}
          maxDate={new Date()}
          visualization={visualization}
        />
      </div>
      {(visualization !== 'VIEW' || (visualization === 'VIEW' && attributeExists('vesselImage'))) && (
        <LineSeparator name="Vessel images" position="top">
          &nbsp;
          {
            errorsId('vesselImage').length !== 0
            && <ValidationWarning messages={errorsId('vesselImage')} />
          }
        </LineSeparator>
      )}
      <VesselImages attribute={attributes[attributes.findIndex((attr) => attr.name === 'vesselImage')]} visualization={visualization} />
      {visualization !== 'VIEW' ? (
        <div className="bottomFormButton left mb-3">
          <Button
            onClick={() => dispatch(addComplexRow('vesselImage'))}
          >
            Add a Vessel Image
          </Button>
        </div>
      ) : <></>}
      {(visualization !== 'VIEW' || (visualization === 'VIEW' && attributeExists('vesselImage'))) && (
        <LineSeparator position="bottom" />
      )}
    </>
  );
};

TabVesselTechnicalDetails.defaultProps = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  addComplexRow: PropTypes.func,
  visualization: PropTypes.string,
  addGear: PropTypes.bool,
  errorsId: PropTypes.func,
  attributeExists: PropTypes.func,
};

TabVesselTechnicalDetails.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  addComplexRow: PropTypes.func,
  visualization: PropTypes.string,
  addGear: PropTypes.bool,
  errorsId: PropTypes.func,
  attributeExists: PropTypes.func,
};

export default TabVesselTechnicalDetails;
