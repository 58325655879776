import { put, post, get } from '../utils';

export const editSource = (source) => (put()('vrService/externalSources/externalSources', source));
export const execute = (scenario) => (post()(`vrService/etl/execute/manual/${scenario.externalSource}`));
const getRecurrencesOption = () => (get()('subscription/options/recurrence'));
const getExternalSource = (id) => (get()(`vrService/externalSources/externalSources/${id}`));
const addExternalSource = (source) => (post()('vrService/externalSources', source));
const updateExternalSource = (source) => (put()('vrService/externalSources/externalSources', source));

export {
  getRecurrencesOption,
  getExternalSource,
  addExternalSource,
  updateExternalSource,
};
