import getRoutePaths from '@pages/App/utils/routePaths';

import AccordionSection from './AccordionSection';
import SECTIONS from './Sections';
import PAGES from './Sections/pages';

import { DOMAIN } from '@common/utils';

const getUserManualConfig = (t, domain) => {
  const routePaths = getRoutePaths(t);
  return [
    {
      url: '/',
      title: 'EFCA Operational Application',
      description: '',
      currDomain: null,
      content: (
        <>
          <AccordionSection
            sections={[
              SECTIONS.COMMON.BURGUER,
              SECTIONS.COMMON.LOGOUT,
              SECTIONS.COMMON.VERSION,
            ]}
          />
        </>
      ),
    },
    {
      url: `${routePaths.trafficMonitoring.url}/${routePaths.trafficMonitoring.sections.INCOMING.url}`,
      title: `${domain} Traffic Monitoring > Incoming`,
      description: `${domain} Traffic Monitoring Incoming provides a feature to monitor incoming traffic at EFCA’s application, where last received messages are shown first in the list. Additionally it provides a search panel and it displays the detail of any selected message as an XML view.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection
            sections={[
              SECTIONS.COMMON.BURGUER,
              SECTIONS.COMMON.getFilterWithSection(PAGES.TRAFFIC),
              SECTIONS.MESSAGES.LIST_INCOMING,
              SECTIONS.MESSAGES.BR,
              SECTIONS.MESSAGES.CONTENT,
              SECTIONS.MESSAGES.RELATED,
            ]}
          />
        </>),
    },
    {
      url: `${routePaths.trafficMonitoring.url}/${routePaths.trafficMonitoring.sections.OUTGOING.url}`,
      title: `${domain} Traffic Monitoring Outgoing`,
      description: `${domain} Traffic monitoring outgoing provides a feature to monitor outgoing traffic at EFCA. Last received messages are shown first in the list. It also have search panel and display the detail of any selected message in an XML view.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.TRAFFIC),
            SECTIONS.MESSAGES.LIST_OUTGOING,
            SECTIONS.MESSAGES.CONTENT,
            SECTIONS.MESSAGES.RELATED,
          ]}
          />
        </>),
    },
    {
      url: routePaths.failOverParameters.url,
      title: `${domain} Failover Parameters`,
      description: `${domain} Failover Parameters events shall be implemented either for outgoing response messages which expect a response to an outgoing message previously sent by EFCA, or for outgoing report messages sent by EFCA as a response to a received Query Message.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.FAILOVER.TABLE,
            SECTIONS.FAILOVER.EDIT,
          ]}
          />
        </>),
    },
    {
      url: routePaths.externalSourcesMGMT.url,
      title: `${domain} External Sources`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.EXTERNAL_SOURCES.SOURCE_TABLE,
            SECTIONS.EXTERNAL_SOURCES.EDIT_SOURCE,
            SECTIONS.EXTERNAL_SOURCES.VIEW_SOURCE,
            SECTIONS.EXTERNAL_SOURCES.EXECUTE_SOURCE,
          ]}
          />
        </>),
    },
    {
      url: routePaths.servicesMonitoring.url,
      title: `${domain} Services Monitoring`,
      description: 'The Operational application provides a tool to monitor the status of the system and its relevant components.',
      currDomain: null,
      content: (
        <>
          <AccordionSection
            sections={[
              SECTIONS.COMMON.BURGUER,
              SECTIONS.SERVICE_MONITORING.TABLE,
            ]}
          />
        </>),
    },
    {
      url: `${routePaths.messageViewer.url}/${routePaths.messageViewer.sections.INCOMING.url}`,
      title: `${domain} Message Viewer > Incoming`,
      description: `${domain} Message Viewer Incoming provides a feature to monitor incoming messages at EFCA’s application, where last received messages are shown first in the list. Additionally it provides a search panel and it displays the details of any selected message as an XML view.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.MESSAGE_VIEWER),
            SECTIONS.MESSAGES.LIST_INCOMING,
            SECTIONS.MESSAGES.BR,
            SECTIONS.MESSAGES.RELATED,
            SECTIONS.MESSAGES.CONTENT,
          ]}
          />
        </>),
    },
    {
      url: `${routePaths.messageViewer.url}/${routePaths.messageViewer.sections.OUTGOING.url}`,
      title: `${domain} Message Viewer > Outgoing`,
      description: `${domain} Message viewer Outgoing provides a feature to monitor incoming messages at EFCA’s application, where last received messages are shown first in the list. Additionally it provides a search panel and it displays the details of any selected message as an XML view.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.MESSAGE_VIEWER),
            SECTIONS.MESSAGES.LIST_OUTGOING,
            SECTIONS.MESSAGES.BR,
            SECTIONS.MESSAGES.RELATED,
            SECTIONS.MESSAGES.CONTENT,
          ]}
          />
        </>),
    },
    {
      url: `${routePaths.scenarioMGMT.url}/${routePaths.scenarioMGMT.sections.SCENARIOS.url}`,
      title: `${domain} Scenario Management > scenarios`,
      description: `${domain} Management of scenarios`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.SCENARIOS),
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.SCENARIOS.SCENARIOS_TABLE,
            SECTIONS.SCENARIOS.CREATE_SCENARIOS,
            SECTIONS.SCENARIOS.EDIT_SCENARIOS,
          ]}
          />
        </>),
    },
    {
      url: `${routePaths.scenarioMGMT.url}/${routePaths.scenarioMGMT.sections.USERS.url}`,
      title: `${domain} Scenario Management > users`,
      description: `${domain} Management of users`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.SCENARIOS.USERS_TABLE,
          ]}
          />
        </>),
    },
    {
      url: routePaths.validationBR.url,
      title: `${domain} Validation BR`,
      description: `${domain} Validation BRs have the objective of verifying that the data quality of Fishing Activity messages transmitted  over is sufficient to ensure their relevance.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.BR),
            SECTIONS.VALIDATION_BR.TABLE,
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.VALIDATION_BR.CREATE,
            SECTIONS.VALIDATION_BR.EDIT,
            SECTIONS.VALIDATION_BR.VIEW,
            SECTIONS.VALIDATION_BR.ACTIVATE,
            SECTIONS.VALIDATION_BR.DEACTIVATE,
            SECTIONS.VALIDATION_BR.EXPORT,
          ]}
          />
        </>),
    },
    {
      url: routePaths.acceptanceBR.url,
      title: `${domain} Acceptance query`,
      description: `${domain} Acceptance query verifies if a sender is authorized to request data to EFCA regarding a defined set of vessels and during an agreed timeline.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.BR),
            SECTIONS.COMMON.SORTING, SECTIONS.COMMON.PAGINATION,
            SECTIONS.ACCEPTANCE_BR.TABLE_QUERY, SECTIONS.ACCEPTANCE_BR.CREATE_QUERY,
            SECTIONS.ACCEPTANCE_BR.EDIT_QUERY, SECTIONS.ACCEPTANCE_BR.VIEW_QUERY,
            SECTIONS.ACCEPTANCE_BR.DELETE_QUERY,
          ]}
          />
        </>),
    },
    {
      url: `${routePaths.queryReportMGMT.url}/${routePaths.queryReportMGMT.sections.QUERY_SUBCRIPTIONS.url}`,
      title: `${domain} Query Subscriptions`,
      description: `${domain} Query Subscriptions`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.MESSAGE_VIEWER),
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.QUERY_REPORT.TABLE_QUERY,
            SECTIONS.QUERY_REPORT.CREATE,
            SECTIONS.QUERY_REPORT.EDIT,
            SECTIONS.QUERY_REPORT.VIEW,
            SECTIONS.QUERY_REPORT.DELETE,
            SECTIONS.QUERY_REPORT.EXECUTE,
          ]}
          />
        </>),
    },
    {
      url: `${routePaths.queryReportMGMT.url}/${routePaths.queryReportMGMT.sections.REPORT_SUBCRIPTIONS.url}`,
      title: `${domain} Report Subscriptions`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.FILTER,
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
          ]}
          />
          ...In progress
        </>),
    },
    {
      url: routePaths.referenceDataMGMT.url,
      title: `${domain} Reference Data Management`,
      description: `${domain} Reference Data Management enables the validation of vessel information coming from different sources.`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.getFilterWithSection(PAGES.DMA),
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.REFERENCE_DATA.REFERENCE_DATA_SECTION,
            SECTIONS.REFERENCE_DATA.EDIT, SECTIONS.REFERENCE_DATA.EDIT_ROW,
            SECTIONS.REFERENCE_DATA.ADD, SECTIONS.REFERENCE_DATA.DELETE,
            SECTIONS.REFERENCE_DATA.IMPORT,
          ]}
          />
        </>),
    },
    {
      url: routePaths.vesselGroupMGMT.url,
      title: `${domain} Vessel Group Management`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.FILTER,
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.VESSEL_GROUP.GROUP_TABLE,
            SECTIONS.VESSEL_GROUP.CREATE_GROUP,
            SECTIONS.VESSEL_GROUP.EDIT_GROUP,
            SECTIONS.VESSEL_GROUP.VIEW_GROUP,
            SECTIONS.VESSEL_GROUP.DELETE_GROUP,
          ]}
          />
        </>),
    },
    {
      url: routePaths.vesselMGMT.url,
      title: `${domain} Vessel Management`,
      description: `${domain} Vessel Management`,
      currDomain: null,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.COMMON.FILTER,
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.VESSEL_MGMT.ADD_VESSEL,
            SECTIONS.VESSEL_MGMT.EDIT_VESSEL,
            SECTIONS.VESSEL_MGMT.IMPORT_FROM,
            SECTIONS.VESSEL_MGMT.EXPORT,
            SECTIONS.VESSEL_MGMT.COMPARE,
            SECTIONS.VESSEL_MGMT.VESSEL_GROUPS,
            SECTIONS.VESSEL_MGMT.VESSEL_VIEW,
          ]}
          />
        </>),
    },
    {
      url: `${routePaths.logbookViewer.url}/${routePaths.logbookViewer.sections.REPORT_VIEW.url}`,
      title: `${domain} Logbook Viewer > report view`,
      description: (
        <p>
          This page shows a list of reports related to a trip.
          User can open the details of a trip by double clicking on the report row,
          or by selecting its corresponding action.
        </p>),
      currDomain: null,
      content: <AccordionSection
        sections={[
          SECTIONS.COMMON.BURGUER,
          SECTIONS.LOGBOOK.LOGBOOK_FILTERS,
          SECTIONS.LOGBOOK.REPORT_DATA_TABLE,
          SECTIONS.COMMON.SORTING,
          SECTIONS.COMMON.PAGINATION,
        ]}
      />,
    },
    {
      url: `${routePaths.logbookViewer.url}/${routePaths.logbookViewer.sections.TRIP_VIEW.url}`,
      title: `${domain} Logbook Viewer > trip view`,
      description: (
        <p>
          This page shows a list of trips and their related reports.
          Some features available for each trip in the list are:
          opening the latest details of the report by double clicking on the corresponding row,
          opening a list of related trips by clicking on the expand icon
          or by selecting the corresponding action.
        </p>),
      currDomain: null,
      content: <AccordionSection
        sections={[
          SECTIONS.COMMON.BURGUER,
          SECTIONS.LOGBOOK.LOGBOOK_FILTERS,
          SECTIONS.LOGBOOK.TRIP_DATA_TABLE,
        ]}
      />,
    },

    {
      url: `${routePaths.dataAdministrationReporting.url}/${routePaths.dataAdministrationReporting.sections.DATA_ACTIVITY.url}`,
      title: `${domain} Data Activity`,
      description: 'This section of the tool shows vessel data activity and allows to compare changes in historical data.',
      currDomain: null,
      content: <AccordionSection sections={[
        SECTIONS.COMMON.BURGUER,
        SECTIONS.COMMON.FILTER,
        SECTIONS.DATA_ADMIN_REPORTING.DATA_ACTIVITY_TABLE,
        SECTIONS.DATA_ADMIN_REPORTING.DATA_ACTIVITY_HISTORICAL,
      ]}
      />,
    },
    {
      url: `${routePaths.dataAdministrationReporting.url}/${routePaths.dataAdministrationReporting.sections.VALIDATION_REPORTS.url}`,
      title: `${domain} Validation Reports`,
      currDomain: null,
      content: <AccordionSection sections={[
        SECTIONS.COMMON.BURGUER,
        SECTIONS.DATA_ADMIN_REPORTING.VALIDATION_REPORT,
      ]}
      />,
    },
    {
      url: routePaths.isrViewer.url,
      title: `${domain} Reports Viewer`,
      description: 'This page shows a list of reports. Some features available for each report in the list are: opening the latest details of the report by double clicking on the corresponding row or selecting the corresponding action.',
      currDomain: DOMAIN.ISR,
      content: (
        <>
          <AccordionSection sections={[
            SECTIONS.COMMON.BURGUER,
            SECTIONS.REPORT_VIEWER.FILTER,
            SECTIONS.COMMON.SORTING,
            SECTIONS.COMMON.PAGINATION,
            SECTIONS.REPORT_VIEWER.TABLE,
            SECTIONS.REPORT_VIEWER.VIEW,
            SECTIONS.REPORT_VIEWER.DELETE,
            SECTIONS.REPORT_VIEWER.ATTACH_FILE,
            SECTIONS.REPORT_VIEWER.VIEW_MESSAGE,
            SECTIONS.REPORT_VIEWER.REMOVE_OBJ_REF,
          ]}
          />
        </>),
    },
  ];
};

export default getUserManualConfig;
