import actions from './img/actions.JPG';
import viewSource from './img/view_source.JPG';

const SectionViewSource = () => (
  <div className="SectionViewSource">
    <p>
      To view the details of an external source,
      click on the corresponding button of the column &quot;Actions&quot;,
      and then select &quot;View&quot; or double click on the external source in the list.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      A modal shows the information as a form with all the fields disabled.
    </p>
    <p>
      <img src={viewSource} alt="View External Source" />
    </p>
  </div>
);

export default SectionViewSource;
