import PropTypes from 'prop-types';
import React from 'react';

import VesselImageItem from './VesselImageItem';

const VesselImages = ({
  attribute, visualization,
}) => (
  <div className="vesselImages">
    {attribute.value?.map((value, index) => (
      <VesselImageItem
        attribute={attribute}
        value={value}
        index={index}
        visualization={visualization}
      />
    ))}
  </div>
);

VesselImages.defaultProps = {
  attribute: PropTypes.instanceOf(Object),
  visualization: PropTypes.string,
};

VesselImages.propTypes = {
  attribute: PropTypes.instanceOf(Object),
  visualization: PropTypes.string,
};

export default VesselImages;
