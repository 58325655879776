import {
  compose,
  identity,
  ifElse,
  isEmpty,
  isNil,
  join,
  keys,
  map,
  prepend,
  prop,
  reject,
} from 'ramda';

import {
  formatSortParams,
  get,
  oldFiltersToQuery,
  formatFilter,
} from '../utils';

/**
 * @param {String} resource
 * @param {String} filters
 * @param {{limit: number, page: number, sort: string, direction: string}} options
 * @returns {Promise<Response>}
 */
export const fetchLogbookResourceData = (
  resource, filters, options,
) => {
  const { pagination, ...query } = options;

  const filtersFormatted = compose(
    ifElse(
      isEmpty,
      () => '',
      compose(
        join(''),
        ifElse(isEmpty, identity, prepend('/')),
        join('&'),
        map((x) => {
          const value = prop(x, filters);
          switch (x) {
            case 'timeframe':
              return formatFilter.timeFrame(x, value);
            case 'meshSize':
              return formatFilter.quantityRange(x, value);
            case 'tripId':
            case 'vesselIds':
            case 'vesselName':
            case 'vesselId':
              return formatFilter.in(x, value);
            case 'activityTypeAnd':
              return formatFilter.and(x, value);
            case 'activityTypeOr':
              return formatFilter.or(x, value);
            case 'onlyLastTrip':
            case 'scenario':
            default:
              return formatFilter.eq(x, value);
          }
        }),
        reject((x) => isNil(prop(x)(filters))),
      ),
    ),
    keys,
  );

  return get()(
    `logbook/${resource}${filtersFormatted(filters)}`,
    { ...formatSortParams(query), ...oldFiltersToQuery(pagination) },
  );
};

export const fetchFAReportDetails = (id) => get()(`logbook/fishing-trips/${id}/reports`);

export default {
  fetchLogbookResourceData,
};
