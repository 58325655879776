import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import IconSvg from '@components/IconSvg';

import './style.scss';

/**
 * Transforms a text to title case, using either spaces
 * or dashes as the separator character for words
 * @param {String} text The text to be transformed
 */
const titleCase = (text) => text
  .split(/[\s-]+/)
  .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)} `);

const sectionCategory = (section) => {
  switch (section) {
    case 'vessel-viewer':
      return 'Vessel Record';
    default:
      return null;
  }
};

/**
 * A component to show the breadcrumbs based on the navigator route
 */
const Breadcrumbs = ({ onCollapseCallback }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const crumbs = location.pathname.split('/');
  crumbs.unshift(t('menu.home'));
  return (
    <Breadcrumb id="breadcrumbs">
      <Button className="menuButton" variant="primary" onClick={onCollapseCallback}>
        <IconSvg className="menuButtonIcon" name="menu" />
      </Button>

      {crumbs.map((crumb, i) => {
        const category = sectionCategory(crumb);
        const path = crumbs.slice(1, i + 1).join('/');
        return crumb && (
          <Fragment key={`${crumb}`}>
            { category && (
              <BreadcrumbItem key={category} active>
                {category}
              </BreadcrumbItem>
            )}
            <BreadcrumbItem
              onClick={() => history.push(i > 0 ? path : '/')}
              active={i === crumbs.length - 1}
              key={crumb}
            >
              {titleCase(crumb)}
            </BreadcrumbItem>
          </Fragment>
        );
      })}
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  onCollapseCallback: PropTypes.func,
};

Breadcrumbs.defaultProps = {
  onCollapseCallback: null,
};

export default Breadcrumbs;
