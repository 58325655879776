import PropTypes from 'prop-types';
import { any, prop, propEq } from 'ramda';
import { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import getRoutePaths from '@pages/App/utils/routePaths';

import Section from '@components/Section';

import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import ScenariosListSection from './components/ScenariosListSection';
import './style.scss';
import { UsersSection } from './components/UsersSection/UsersSection';

const ScenariosViewer = ({
  title, path,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sectionId } = useParams();
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  const routePaths = useMemo(() => getRoutePaths(t), [t]);

  const sections = [
    {
      id: routePaths.scenarioMGMT.sections.SCENARIOS.url,
      key: routePaths.scenarioMGMT.sections.SCENARIOS.url,
      title: routePaths.scenarioMGMT.sections.SCENARIOS.title,
      render: () => (
        <ScenariosListSection domain={domain} />
      ),
    },
    {
      id: routePaths.scenarioMGMT.sections.USERS.url,
      key: routePaths.scenarioMGMT.sections.USERS.url,
      title: routePaths.scenarioMGMT.sections.USERS.title,
      render: () => (
        <UsersSection domain={domain} />
      ),
    },
  ];

  const currentSection = sections.find((s) => s.id === sectionId);

  useEffect(() => {
    document.title = `${window.env.REACT_APP_TITLE} - ${title} ${
      currentSection ? currentSection.title : ''
    }`;
  }, [currentSection, title]);

  if (!any(propEq('id', sectionId), sections)) {
    return <Redirect to={`${path}/${sections[0].id}`} />;
  }

  return (
    <Container fluid id="scenarios-viewer">
      <Section
        tabTitleGetter={prop('title')}
        sections={sections}
        defaultSection={currentSection}
        className="main"
        onCurrentSectionChangeCallback={(section) => history.push(`${path}/${section.id}`)}
      />
    </Container>
  );
};

ScenariosViewer.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ScenariosViewer;
