import { useDispatch, useSelector } from 'react-redux';

import { getFormData, getSearchableAttributes, getVesselPorts } from '@services/vessel_record/actions/vessel';

const useSearchableAttributes = () => {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.vessel.template);
  const searchableAttributes = useSelector((state) => state.vessel.searchableAttributes);

  const isLoading = useSelector((state) => state.vessel.isLoading);

  const getOptionsFilter = () => {
    dispatch(getSearchableAttributes());
    dispatch(getFormData());
    dispatch(getVesselPorts());
  };

  return {
    searchableAttributes, formData, isLoading, getOptionsFilter,
  };
};

export default useSearchableAttributes;
