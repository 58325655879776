import { EU } from '@pages/IsrViewer/commons/constants';

import tSchema from '@lang/schema';

import { formatSelectOption } from '@common/utils';

export const resetPagination = (dataTableRef, setPageRequest) => {
  dataTableRef.current && dataTableRef.current.resetPagination(1, false);
  setPageRequest(1);
};

export function getSearchOptions(t) {
  return [
    { label: t(tSchema.common.contains), value: '=-', type: 'text' },
    { label: t(tSchema.common.equals), value: '==' },
    { label: t(tSchema.common.notContains), value: '!-', type: 'text' },
    { label: t(tSchema.common.before), value: '<', type: 'date' },
    { label: t(tSchema.common.after), value: '>', type: 'date' },
    { label: t(tSchema.common.notEquals), value: '!-', type: 'complex-select' },
    { label: t(tSchema.common.contains), value: '=-', type: 'complex-select' },
  ];
}

function getFilterCountryValues(code, filters, t) {
  let result;
  if (code && filters) {
    result = filters.filter((filter) => filter.code === code);
    if (result && result[0].values) {
      result = result[0].values.map((filter) => {
        const res = { id: filter.code, description: filter.description };
        if (filter.category === EU) {
          res.group = t(tSchema.isrViewer.europeCountries);
        } else {
          res.group = t(tSchema.isrViewer.worldCountries);
        }
        return res;
      });
    }
  }
  return result || [];
}

function getFilterValues(code, filters, infringementClassificationValue) {
  let result;
  if (code && filters) {
    result = filters.filter((filter) => filter.code === code);
    if (code === 'infringementType') {
      result = result[0].values.filter(
        (item) => item.category === infringementClassificationValue,
      ).map((filter) => (
        { id: filter.code, description: filter.description }
      ));
    } else if (result && result[0] && result[0].values) {
      result = result[0].values.map((filter) => (
        { id: filter.code, description: filter.description }
      ));
    }
  }
  return result || [];
}

export const getConfigureFilters = (values, t, infringementClassificationValue) => [
  {
    code: 'reportContext',
    label: t(tSchema.isrViewer.context),
    type: 'multichoice',
    options: getFilterValues('reportContext', values).map((option) => formatSelectOption(option, 'id', 'description')),
  },
  {
    code: 'isrReportIdentifier',
    label: t(tSchema.isrViewer.identifier),
    type: 'text',
  },
  {
    code: 'participants',
    label: t(tSchema.isrViewer.participants),
    type: 'multichoice',
    options: getFilterCountryValues('participants', values, t).map((option) => formatSelectOption(option, 'id', 'description')),
  },
  {
    code: 'programme',
    label: t(tSchema.isrViewer.programme),
    type: 'select',
    options: getFilterValues('programme', values),
  },
  {
    code: 'typeOfReport',
    label: t(tSchema.isrViewer.module),
    type: 'multichoice',
    options: getFilterValues('typeOfReport', values).map((option) => formatSelectOption(option, 'id', 'description')),
  },
  {
    code: 'controlParty',
    label: t(tSchema.isrViewer.controlParty),
    type: 'multichoice',
    options: getFilterValues('controlParty', values).map((option) => formatSelectOption(option, 'id', 'description')),
  },
  {
    code: 'infringement',
    label: t(tSchema.isrViewer.infringement),
    type: 'select',
    options: getFilterValues('infringement', values),
  },
  {
    code: 'infringementClassification',
    label: t(tSchema.isrViewer.infringementClassification),
    type: 'select',
    options: getFilterValues('infringementClassification', values),
  },
  {
    code: 'infringementType',
    label: t(tSchema.isrViewer.infringementType),
    type: 'multichoice',
    options: getFilterValues('infringementType', values, infringementClassificationValue).map((option) => formatSelectOption(option, 'id', 'description')),
  },
  {
    code: 'gear',
    label: t(tSchema.isrViewer.gear),
    type: 'multichoice',
    options: getFilterValues('gear', values).map((option) => formatSelectOption(option, 'id', 'description')),
  },
  {
    code: 'dateTime',
    label: t(tSchema.isrViewer.timeframe),
    type: 'date-range',
    section: 'isrReportViewer',
  },
  {
    code: 'speciesInspected',
    label: t(tSchema.isrViewer.inspectedSpecies),
    type: 'multichoice',
    options: getFilterValues('speciesInspected', values).map((option) => formatSelectOption(option, 'id', 'description')),
    largeList: true,
  },
  {
    code: 'vesselId',
    label: t(tSchema.isrViewer.vesselId),
    type: 'input-add',
    options: getFilterValues('vesselId', values),
    isMultichoice: true,
  },
  {
    code: 'vesselName',
    label: t(tSchema.isrViewer.vesselName),
    type: 'input-add',
    options: getFilterValues('vesselName', values),
    isMultichoice: true,
  },
  {
    code: 'objectCountryRegistration',
    label: t(tSchema.isrViewer.objectCountry),
    type: 'multichoice',
    options: getFilterCountryValues('objectCountryRegistration', values, t).map((option) => formatSelectOption(option, 'id', 'description')),
    isMultichoice: false,
  },
  {
    code: 'reportVersionIndicator',
    label: t(tSchema.isrViewer.reportStatus),
    type: 'select',
    options: getFilterValues('reportVersionIndicator', values),
  },
  {
    code: 'businessRulesTriggeredAnd',
    label: t(tSchema.isrViewer.businessTriggeredAnd),
    type: 'multichoice',
    options: getFilterValues('businessRulesTriggeredAnd', values, t),
    isMultichoice: true,
  },
  {
    code: 'businessRulesTriggeredOr',
    label: t(tSchema.isrViewer.businessTriggeredOr),
    type: 'multichoice',
    options: getFilterValues('businessRulesTriggeredOr', values, t),
    isMultichoice: true,
  },
];
