/* eslint-disable import/order */

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  Form, Button, Container, Row, Col, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { unstable_batchedUpdates as unstableBatchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification, showOkNotification } from '@common/utilities/Notification';
import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';
import tSchema from '@lang/schema';
import { getFormData, getSearchableAttributes, getVesselPorts } from '@services/vessel_record/actions/vessel';
import {
  apiFetchVesselGroupById, apiSaveVesselGroup, apiAddVesselsToGroup, apiExportVesselGroupExcelById,
} from '@services/vessels/api';

import { SEARCH_TYPES } from '../../../../componentsRecord/Filters/FiltersUtils';

import Criteria from './components/Criteria';
import TableSummary from './components/TableSummary';
import VesselImporter from './components/VesselImporter';
import VesselSearcher from './components/VesselSearcher/VesselSearcher';
import schemaValidatation from './utils';

import './style.scss';

const VesselGroupsEditor = ({
  vesselGroup,
  edit,
  setShowModal,
  modalVisualizationForm,
  onUploading,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const searchableAttributes = useSelector((state) => state.vessel.searchableAttributes);
  const formDataList = useSelector((state) => state.vessel.template.formData);

  const [selectedRowsVS, setSelectedRowsVS] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [vesselLoading, setVesselLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [vesselGroupFromAPI, setVesselGroupFromAPI] = useState({});
  const [isByCriteria, setIsByCriteria] = useState(false);
  const [attributeCriteria, setAttributeCriteria] = useState([]);

  const requestWithLoading = useRequestWithLoading();

  useEffect(() => {
    if (searchableAttributes.length === 0) {
      dispatch(getSearchableAttributes());
      dispatch(getFormData());
      dispatch(getVesselPorts());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(vesselGroup)) {
      requestWithLoading(async () => {
        setLoadingGroups(true);
        const queryVesselGroup = await apiFetchVesselGroupById(vesselGroup?.id);
        setLoadingGroups(false);
        if (queryVesselGroup.ok) {
          const data = await queryVesselGroup.json();
          data.vesselGroupRecordCriteriaEntityList = data.vesselGroupRecordCriteriaEntityList.map(
            (criteria) => {
              const criteriaFormatted = {
                name: criteria.fieldName,
                type: criteria.fieldType,
                searchType: criteria.searchType,
                inverted: criteria.inverted,
                value: criteria.fieldValue,
              };
              if (criteria.searchType === 'RANGE') {
                criteriaFormatted.range = [
                  criteria.fieldRangeBegin,
                  criteria.fieldRangeEnd,
                ];
              }
              return criteriaFormatted;
            },
          );
          setAttributeCriteria(data.vesselGroupRecordCriteriaEntityList);
          setVesselGroupFromAPI(data);
          if (data.vesselGroupRecordCriteriaEntityList.length > 0) {
            setIsByCriteria(true);
          }
        } else {
          showErrorNotification();
        }
      }, setFirstLoading);
    }
  }, [requestWithLoading, vesselGroup]);

  // Checks if the save button sholud be enabled or disabled
  const enabledSaveButton = () => {
    let enabled;
    if (!isByCriteria) {
      enabled = true;
    } else {
      enabled = false;
      attributeCriteria.map((attr) => {
        if (
          (
            attr.searchType === SEARCH_TYPES.EXACT || attr.searchType === SEARCH_TYPES.FUZZY
          ) && attr.value !== null) {
          enabled = true;
        }
        if (attr.searchType === SEARCH_TYPES.RANGE && (
          attr.range[0] !== null || attr.range[0] !== undefined
          || attr.range[1] !== null || attr.range[1] !== undefined
        )) {
          enabled = true;
        }
        return attr;
      });
    }
    return enabled;
  };

  const checkAdded = (vessel) => (
    modalVisualizationForm !== 'CREATE'
      && vessel.idGroupList?.find(
        (group) => group === vesselGroup.id,
      ) !== undefined);

  const disabled = useMemo(() => !edit
  || [loadingGroups, loading, firstLoading, uploading].some(Boolean),
  [edit, loadingGroups, loading, firstLoading, uploading]);

  const loadingMessage = useMemo(() => (
    (loading && 'Saving group...')
    || (uploading && 'Uploading file')
    || ((loadingGroups || firstLoading) && 'Loading group data...')
  ), [loadingGroups, loading, firstLoading, uploading]);

  const initialValues = useMemo(() => ({
    id: vesselGroupFromAPI.id ? vesselGroupFromAPI.id : '',
    name: vesselGroupFromAPI.name ? vesselGroupFromAPI.name : '',
    description: vesselGroupFromAPI.description ? vesselGroupFromAPI.description : '',
    vessels: [],
    active: (isEmpty(vesselGroupFromAPI) || vesselGroupFromAPI.active) ? 'Active' : 'Inactive',
  }),
  [vesselGroupFromAPI]);

  const handleSubmitVesselGroup = useCallback((groupToSaveObject, { setErrors }) => {
    let formatVesselGroupDataToSend = {
      name: groupToSaveObject.name,
      description: groupToSaveObject.description === '' ? null : groupToSaveObject.description,
      active: groupToSaveObject.active === 'Active',
    };
    if (isByCriteria) {
      formatVesselGroupDataToSend = {
        ...formatVesselGroupDataToSend,
        criteriaList: attributeCriteria,
      };
    }

    if (groupToSaveObject) {
      requestWithLoading(async () => {
        const queryVesselGroup = await apiSaveVesselGroup(formatVesselGroupDataToSend,
          groupToSaveObject?.id);
        let queryVesselsList = { ok: true };
        if (queryVesselGroup.ok && !isByCriteria
          && (selectedRows.length || selectedRowsVS.length)) {
          const respVesselGroup = await queryVesselGroup.json();
          queryVesselsList = await apiAddVesselsToGroup(
            respVesselGroup.id, [...selectedRows, ...selectedRowsVS],
          );
        }
        if (
          (isByCriteria && queryVesselGroup.ok)
          || (!isByCriteria && queryVesselGroup.ok && queryVesselsList.ok)
        ) {
          showOkNotification(t(tSchema.notifications.dmaAdmin.vesselGroupMGMT.vesselGroups.ok));
          setShowModal(false);
        } else if (queryVesselGroup.response.status === 400) {
          const resp = queryVesselGroup.response.data;
          if (resp.message === 'Name is already in use') {
            setErrors({ name: 'A group with this name already exists' });
          } else {
            showErrorNotification(resp.message);
          }
        } else {
          showErrorNotification();
          setShowModal(false);
        }
      }, setLoading);
    }
  }, [attributeCriteria, isByCriteria, selectedRows, selectedRowsVS]);

  const handleChangeIsVesselsByCriteria = useCallback(
    (ev) => {
      modalVisualizationForm === 'CREATE' && setIsByCriteria(ev.target.checked);
    }, [modalVisualizationForm],
  );

  const handleClickCancel = useCallback(
    (event, setFieldValue) => {
      event.preventDefault();
      unstableBatchedUpdates(() => {
        setShowModal(false);
        setFieldValue('vessels', []);
      });
    },
    [unstableBatchedUpdates],
  );

  const handleUploadingFile = useCallback((value) => {
    setUploading(value);
    onUploading(value);
  }, []);

  const handleFetchFileError = useCallback((setFieldValue) => {
    setFieldValue('vessels', []);
  }, []);

  const handleFetchVessels = useCallback((vessels, setFieldValue) => {
    setFieldValue('vessels', vessels);
  }, []);

  return (
    <Container fluid>
      {loadingMessage && (
      <div>
        <div className="d-flex justify-content-center">
          <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status" />
        </div>
        <div className="label d-flex justify-content-center mt-4" style={{ fontSize: '1.25rem', textAlign: 'center' }}>
          {loadingMessage}
        </div>
      </div>
      ) }
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schemaValidatation}
        onSubmit={handleSubmitVesselGroup}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} hidden={!!loadingMessage}>
            <Form.Group as={Row} className="mb-3 " controlId="NameControlId">
              <Col>
                <Form.Label>
                  {t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.name)}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.name)}
                  name="name"
                  onChange={handleChange}
                  isInvalid={touched.name && errors.name}
                  isValid={touched.name && !errors.name}
                  value={values.name}
                  disabled={disabled}
                />
                {(edit) && touched.name && !!errors.name
                      && (<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>)}
              </Col>
            </Form.Group>

            <Form.Group className="mb-3 " controlId="DescriptionControlId">
              <Form.Label>
                {t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.description)}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={!disabled
                  && t(tSchema.dmaAdmin.vesselGroupMGMT.vesselGroups.description)}
                name="description"
                onChange={handleChange}
                value={values.description}
                disabled={disabled}
              />
            </Form.Group>
            <Form.Group className="mb-3" style={{ display: 'flex' }}>
              <Form.Label style={{ marginRight: '30px' }}>Group Type</Form.Label>
              <div style={{ display: 'flex' }} id="switch-form">
                <div className={`${!isByCriteria && 'fontWeightBold'}`}>Static</div>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  style={{ marginLeft: '0.625rem', marginRight: '0.125rem' }}
                  checked={isByCriteria}
                  onChange={(ev) => handleChangeIsVesselsByCriteria(ev, setFieldValue)}
                />
                <div className={`${isByCriteria && 'fontWeightBold'}`}>Dynamic</div>
              </div>
            </Form.Group>

            {isByCriteria && (
              <Criteria
                searchableAttributes={searchableAttributes}
                formDataList={formDataList}
                attributeCriteria={attributeCriteria}
                setAttributeCriteria={setAttributeCriteria}
                edit={edit}
              />
            ) }
            {modalVisualizationForm !== 'VIEW' && !isByCriteria && (
              <>
                <VesselSearcher
                  checkAdded={checkAdded}
                  selectedRows={selectedRowsVS}
                  onSelectedRows={setSelectedRowsVS}
                />
                <VesselImporter
                  checkAdded={checkAdded}
                  onUploadingFile={handleUploadingFile}
                  onFetchFileError={() => handleFetchFileError(setFieldValue)}
                  selectedRows={selectedRows}
                  onSelectedRows={setSelectedRows}
                />
              </>
            )}
            {((modalVisualizationForm !== 'CREATE' && !isByCriteria) || (modalVisualizationForm === 'VIEW' && isByCriteria)) && (
              <TableSummary
                setVesselLoading={setVesselLoading}
                isEdit={modalVisualizationForm === 'EDIT'}
                values={values}
                isLoading={vesselLoading}
                onFetchVessels={(vessels) => handleFetchVessels(vessels, setFieldValue)}
                vesselId={vesselGroup?.id}
              />
            )}

            <Row className="d-flex justify-content-end mr-1">
              {edit && (
                <>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={(event) => handleClickCancel(event, setFieldValue)}
                  >
                    <span className="symbol-cross" />
                    {' '}
                    {t(tSchema.common.cancel)}
                  </Button>
                  {
                  !enabledSaveButton()
                    ? (
                      <OverlayTrigger
                        trigger={['hover', 'click', 'focus']}
                        placement="bottom"
                        rootClose
                        overlay={(
                          <Popover>
                            <Popover.Content>
                              <div style={{ textAlign: 'center' }}>
                                It is necessary to add criteria
                                for creating a &quot;By criteria&quot; group
                              </div>
                            </Popover.Content>
                          </Popover>
                        )}
                      >
                        <Button
                          variant="primary"
                          className="ml-2 disabled"
                        >
                          <span className="symbol-tick" />
                          {' '}
                          {t(tSchema.common.save)}
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="ml-2"
                      >
                        <span className="symbol-tick" />
                        {' '}
                        {t(tSchema.common.save)}
                      </Button>
                    )
                }
                </>
              )}
              {modalVisualizationForm === 'VIEW' && vesselGroup?.id && (
              <Button
                style={{ marginLeft: '0.375rem' }}
                id="export-to-excel"
                className="symbol-download ml-2"
                onClick={() => apiExportVesselGroupExcelById(vesselGroup?.id)}
              >
                <IconSvg name={ICON.DOWNLOAD} />
                Export to Excel
              </Button>
              )}
            </Row>

          </Form>

        )}
      </Formik>
    </Container>
  );
};
VesselGroupsEditor.propTypes = {
  vesselGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    criteria: PropTypes.instanceOf(Array),
  }),
  edit: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onUploading: PropTypes.func.isRequired,
  modalVisualizationForm: PropTypes.string,
};

VesselGroupsEditor.defaultProps = {
  vesselGroup: null,
  modalVisualizationForm: '',
};

export default VesselGroupsEditor;
