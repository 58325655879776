import SectionServicesMonitoringTable from './SectionServicesMonitoringTable';

const SERVICE_MONITORING = {
  TABLE: {
    title: 'Grid',
    body: <SectionServicesMonitoringTable />,
  },
};

export default SERVICE_MONITORING;
