import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const InputTextArea = ({
  id,
  value,
  readOnly,
  onChange,
  setIsValid,
  isValid = true,
  required,
  className,
  maxLength,
  minLength,
  complex = false,
  ...props
}) => {
  const dispatch = useDispatch();
  return (
    <div className={className}>
      <Form.Control
        id={id}
        as="textarea"
        disabled={readOnly}
        maxLength={maxLength}
        minLength={minLength}
        value={value !== null ? value : ''}
        onChange={(e) => {
          !complex
            ? dispatch(onChange(id, e.target.value))
            : dispatch(onChange(props.complexName, id, props.index, e.target.value));
          if (required && !isValid && e.target.value !== '') {
            dispatch(setIsValid(id, true));
          }
        }}
        style={{
          border: isValid ? '' : '0.125rem solid red',
        }}
        {...props}
      />

    </div>
  );
};

InputTextArea.defaultProps = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  complex: false,
  complexName: PropTypes.string,
  index: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

InputTextArea.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  complex: false,
  complexName: PropTypes.string,
  index: PropTypes.number,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

export default InputTextArea;
