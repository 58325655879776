import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row } from 'react-bootstrap';

import FieldCheckBoxWrapper from '../FieldCheckBoxWrapper/FieldCheckBoxWrapper';

const Checkbox = ({
  name, label, onChange: handleChange, value, checked, onFieldChecked: handleFieldChecked,
  allwaysTrue,
}) => (
  <FieldCheckBoxWrapper checked={checked} name={name} onFieldChecked={handleFieldChecked}>
    <Form.Group key={name} as={Row} controlId={name} className="d-flex align-items-center m-0 my-3">
      <Form.Label className="m-0">{label}</Form.Label>
      {!allwaysTrue && <Form.Check type="checkbox" checked={value} name={name} onChange={handleChange} className="ml-3" />}
    </Form.Group>
  </FieldCheckBoxWrapper>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onFieldChecked: PropTypes.func.isRequired,
  allwaysTrue: PropTypes.bool,
};

Checkbox.defaultProps = {
  allwaysTrue: false,
};

export default Checkbox;
