import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import VesselSearchModal from '@pages/VesselSearchGrid/VesselSearchModal';

import CustomActionMultiSelect from '@components/CustomActionMultiSelect';

function VesselSearchInput({
  maxSelectable, onChange, minRequired,
  selectedRowsLabelMapper, values,
  label, placeholder, disabled, isInvalid, isValid,
}) {
  const [showVessels, setShowVessels] = useState(false);

  const openVesselSelector = useCallback(() => setShowVessels(true), []);
  return (
    <div style={{ flex: 1, minWidth: '150px' }}>
      <CustomActionMultiSelect
        label={label}
        values={values}
        valueMap={selectedRowsLabelMapper}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        onActionButtonClick={openVesselSelector}
        isInvalid={isInvalid}
        isValid={isValid}
      />
      <VesselSearchModal
        show={showVessels}
        setShow={setShowVessels}
        maxSelectable={maxSelectable}
        minRequired={minRequired}
        onAcceptSelected={onChange}
        defaultSelected={values}
        selectedRowsLabelMapper={selectedRowsLabelMapper}
      />
    </div>
  );
}

VesselSearchInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({
    cfr: PropTypes.string,
    etalon_id: PropTypes.string,
    ircs: PropTypes.string,
    name: PropTypes.string,
    uvi: PropTypes.string,
  })),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  maxSelectable: PropTypes.number,
  minRequired: PropTypes.number,
  selectedRowsLabelMapper: PropTypes.func,
};
VesselSearchInput.defaultProps = {
  disabled: false,
  label: undefined,
  placeholder: undefined,
  isInvalid: false,
  isValid: false,
  minRequired: undefined,
  maxSelectable: undefined,
  values: [],
  selectedRowsLabelMapper: (vessel) => vessel.cfr || vessel.uvi || vessel.name || 'No ID',
};

export default VesselSearchInput;
