import PropTypes from 'prop-types';
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';

import {
  setPermissions,
  setRoles,
  setUserName,
  updateLogedIn,
} from '@services/authentication/actions';

import { DUMMY_LOGIN } from '../../common/constants';
import { getPermissionsFromRoles } from '../../common/keycloakFunctions';

const KeyCloakContext = createContext();

function KeyCloakContextProviderComponent({
  kcInstance,
  children,
  updateLogedIn: updateLogedInAction,
  setUserName: setUserNameInAction,
  setRoles: setRolesInAction,
  setPermissions: setPermissionsInAction,
}) {
  const [keycloak, setKeycloak] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (DUMMY_LOGIN === false && kcInstance) {
      setLoading(true);

      kcInstance
        .init({ onLoad: 'login-required' })
        .then((auth) => {
          if (!auth) {
            window.location.reload();
          }
          updateLogedInAction(true);
          setKeycloak(kcInstance);

          setRolesInAction(kcInstance.realmAccess.roles);
          setPermissionsInAction(getPermissionsFromRoles(kcInstance.realmAccess.roles));

          kcInstance
            .loadUserInfo()
            .then((info) => setUserNameInAction(info.preferred_username))
            .catch((e) => console.error('Error retrieving the preferred user name', e));
        })
        .catch(() => {
          console.error('Authenticated Failed');
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const value = { keycloak, setKeycloak, loading };

  return <KeyCloakContext.Provider value={value}>{children}</KeyCloakContext.Provider>;
}

function useKeyCloak() {
  const context = useContext(KeyCloakContext);

  if (context === undefined) {
    throw new Error('useUserPreferences must be used within a UserContextProvider');
  }

  return context;
}

KeyCloakContextProviderComponent.propTypes = {
  kcInstance: PropTypes.shape({
    init: PropTypes.func.isRequired,
    loadUserInfo: PropTypes.func.isRequired,
    realmAccess: PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  }).isRequired,
  children: PropTypes.element.isRequired,
  setUserName: PropTypes.func.isRequired,
  setRoles: PropTypes.func.isRequired,
  setPermissions: PropTypes.func.isRequired,
  updateLogedIn: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateLogedIn,
  setUserName,
  setRoles,
  setPermissions,
};

const KeyCloakContextProvider = connect(null, mapDispatchToProps)(KeyCloakContextProviderComponent);

export { useKeyCloak, KeyCloakContextProvider };
