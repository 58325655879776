import { ExclamationTriangleFill } from 'react-bootstrap-icons';

import { M1, M3 } from '@pages/IsrViewer/commons/constants';

import tSchema from '@lang/schema';

import formatChecked from '../utils';

import {
  CATCH_ONBOARD,
  DISCARDS,
  CATCH_OFFLOADED,
  CATCH_RECEIVED,
} from './constants';

const schema = tSchema.isrViewer;

const labelsType = {
  CATCH_ON_BOARD_CHECKED: 'catchOnBoard',
  CATCH_OFFLOADED_CHECKED: 'catchOffloaded',
  TRX_CATCHES_OTH_VESSELS_CHECK: 'trxCatchesOthVessels',
  SEP_REG_UNDERSIZED_CHECK: 'sepRegUndersized',
  MARGIN_TOL_SPECIES_CHECK: 'marginTolSpecies',
  MIN_REFERENCE_SIZE_CHECK: 'minReferenceSize',
  LABELLING_CHECKED: 'labelling',
  CATCH_WEIGHING_CHECKED: 'catchWeighing',
  BOX_CONTAINER_COUNT_CHECKED: 'boxContainerCount',
  SAMPLING_ON_DISCHARGE_CHECKED: 'samplingOnDischarge',
  HOLD_AFTER_DISCHARGE_CHECKED: 'holdAfterDischarge',
  ULLAGE_TABLES_CHECKED: 'ullageTables',
  SAMPLING_CHECKED: 'sampling',
  CATCH_WEIGH_ON_LANDING_CHECK: 'catchWeighOnLanding',
  CATCH_CHECKED: 'catch',
  SEP_STOW_UNDERSIZED_CHECK: 'sepStowUndersized',
  SEP_STOW_DEMERSAL_CHECK: 'sepStowDemersal',
  DISCARD_RECORDS_CHECKED: 'discardRecords',
};

export function getProductsTableConfig(t) {
  return [
    { text: t(schema.type), code: 'type', render: (row) => t(schema.catchProductsTable[labelsType[row.type]]) },
    { text: t(schema.checked), code: 'checked', render: (row) => formatChecked(row.checked, t) },
    { text: t(schema.details), code: 'details' },
  ];
}

export function getCatchTableConfig(t) {
  return [
    { text: t(schema.species), code: 'species', render: (row) => `${row.speciesName || ''} (${row.speciesCode})` },
    { text: t(schema.weight), code: 'weight' },
    { text: t(schema.numberFish), code: 'numberOfFish' },
    { text: t(schema.sizeClass), code: 'sizeClass' },
    { text: t(schema.presentation), code: 'presentation' },
    { text: t(schema.preservation), code: 'preservation' },
    {
      text: t(schema.catchArea),
      code: 'catchArea',
      render: (row) => row.catchArea.map((area) => `${area.schemeID}: ${area.value}`).join(', '),
    },
  ];
}

export function getDiscardsTableConfig(t) {
  return [
    { text: t(schema.species), code: 'species', render: (row) => `${row.speciesName || ''} (${row.speciesCode})` },
    { text: t(schema.weight), code: 'weight' },
    { text: t(schema.numberFish), code: 'numberOfFish' },
  ];
}

export function getOffloadedTableConfig(t) {
  return [
    { text: t(schema.species), code: 'species', render: (row) => `${row.speciesName || ''} (${row.speciesCode})` },
    { text: t(schema.weight), code: 'weight' },
    { text: t(schema.numberFish), code: 'numberOfFish' },
    { text: t(schema.sizeClass), code: 'sizeClass' },
    { text: t(schema.presentation), code: 'presentation' },
    { text: t(schema.preservation), code: 'preservation' },
    {
      text: t(schema.catchArea),
      code: 'catchArea',
      render: (row) => row.catchArea.map((area) => `${area.schemeID}: ${area.value}`).join(', '),
    },
  ];
}

export const switchTabs = (
  tab,
  t,
  catchTable,
  discardsTable,
  offloadedTable,
  receivedSection,
) => {
  switch (tab.code) {
    case CATCH_ONBOARD:
      return (
        catchTable
      );
    case DISCARDS:
      return (
        discardsTable
      );
    case CATCH_OFFLOADED:
      return (
        offloadedTable
      );
    case CATCH_RECEIVED:
      return (
        receivedSection
      );
    default:
      return null;
  }
};

export function getTabs(module, t) {
  let result = [];
  if (module === M1) {
    result = [
      { code: CATCH_ONBOARD, label: t(tSchema.isrViewer.catchOnboard) },
      { code: DISCARDS, label: t(tSchema.isrViewer.discards) },
    ];
  }
  if (module === M3) {
    result = [
      { code: CATCH_ONBOARD, label: t(tSchema.isrViewer.catchOnboard) },
      { code: CATCH_OFFLOADED, label: t(tSchema.isrViewer.catchOffloaded) },
      { code: CATCH_RECEIVED, label: t(tSchema.isrViewer.catchReceived) },
    ];
  }
  return result;
}

export function exclamationPicker(level) {
  if (level === 'error') {
    return <ExclamationTriangleFill color="#ff6161" />;
  }
  if (level === 'warning') {
    return <ExclamationTriangleFill color="orange" />;
  }

  return <ExclamationTriangleFill hidden />;
}
