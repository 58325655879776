/* eslint-disable import/order */
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { SideFilterContextProvider } from '@pages/FishingTripsLogbook/SideFilterContext/SideFilterContext';
import PropTypes from 'prop-types';
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button, Container,
} from 'react-bootstrap';

import ReportDetailsSection from './components/ReportDetailsSection';
import ReportViewSection from './components/ReportViewSection';
import TripViewSection from './components/TripViewSection';

import { LOGBOOK_SECTIONS, REPORT_TYPES } from './config';

import './style.scss';

const tripsLabel = 'Trips';
const reportsLabel = 'Reports';
const reportDetailLabel = 'Report details';
const tripReportDetailLabel = 'Trip report details';

const LogbookViewerSectionWithTabs = ({ isTrip }) => {
  const mainLabel = useMemo(() => (isTrip ? tripsLabel : reportsLabel), [isTrip]);
  const [tabs, setTabs] = useState([{
    isDetail: false,
    label: mainLabel,
  }]);
  const [selectedTab, setSelectedTab] = useState(0);

  const detailLabel = useMemo(() => (isTrip ? tripReportDetailLabel : reportDetailLabel), [isTrip]);
  const TableComponent = useMemo(() => (isTrip ? TripViewSection : ReportViewSection), [isTrip]);

  const handleCloseTab = useCallback((id) => {
    const newTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(newTabs);
    setSelectedTab(0);
  }, [tabs]);

  const detailTabLabelMap = useCallback((label, id) => (
    (
      <div className="detail-tab-label">
        <div className="detail-tab-label-title">
          {label}
          <Button
            className="close-button"
            variant="link"
            title={`close ${id}`}
            onClick={(ev) => {
              ev.stopPropagation();
              handleCloseTab(id);
            }}
            tabIndex={0}
          >
            &times;
          </Button>
        </div>
        <div className="detail-tab-label-id">{id}</div>
      </div>
    )
  ), [handleCloseTab]);

  const getSection = (faType, reportType) => {
    if (reportType === REPORT_TYPES.NOTIFICATION) {
      switch (faType) {
        case LOGBOOK_SECTIONS.ARRIVAL:
          return LOGBOOK_SECTIONS.PRIOR_NOTIFICATION_OF_ARRIVAL;
        case LOGBOOK_SECTIONS.TRANSHIPMENT:
          return LOGBOOK_SECTIONS.TRANSHIPMENT_OR_RELOCATION_NOTIFICATION;
        default:
          return null;
      }
    } else {
      return faType;
    }
  };

  const onViewReportDetailsHandler = useCallback((id, faType, reportType) => {
    let indexSelectedTab = 0;
    setTabs((prev) => {
      const prevTabIndex = prev.findIndex((tab) => tab.id === id);
      const isAlreadySelected = prevTabIndex >= 0;
      indexSelectedTab = isAlreadySelected ? prevTabIndex : prev && prev.length;
      return [...prev, ...(!isAlreadySelected ? [{
        isDetail: true,
        id,
        label: detailLabel,
        activeSection: getSection(faType, reportType),
      }] : [])];
    });
    setSelectedTab(indexSelectedTab);
  }, [detailLabel]);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <SideFilterContextProvider>
      <Container fluid className="trip-view-section-tabs p-0" style={{ height: '100%' }}>
        {tabs.length > 1 && (
        <Tabs
          className="simple"
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((t) => (
            <Tab key={`tab-${t.label}-${t.id || 'main'}`} label={t.isDetail ? detailTabLabelMap(t.label, t.id) : t.label} />
          ))}
        </Tabs>
        )}
        {tabs.map((t, index) => (
          <div hidden={selectedTab !== index} key={`content-${t.label}-${t.id || 'main'}`} style={{ height: '100%' }}>
            {t.isDetail
              ? (
                <ReportDetailsSection
                  tripId={t.id}
                  key={t.id}
                  activeSection={t.activeSection}
                />
              )
              : (
                <TableComponent
                  onViewReportDetails={onViewReportDetailsHandler}
                  key={t.label}
                />
              )}
          </div>
        ))}
      </Container>
    </SideFilterContextProvider>
  );
};

LogbookViewerSectionWithTabs.propTypes = {
  isTrip: PropTypes.bool.isRequired,
};

export default LogbookViewerSectionWithTabs;
