import PropTypes from 'prop-types';
import { prop } from 'ramda';
import React, { useState, useCallback } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FavouriteSection from '@pages/FishingTripsLogbook/components/SearchFavouriteSections/components/FavouriteSection';

import CustomTabs from '@components/CustomTabs';
import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';

import tSchema from '@lang/schema';

import SearchSection from './components/SearchSection';

const schema = tSchema.fishingActivities.logbookViewer.filters;

const SearchFavouriteSections = ({
  types, isUIBlocked, initialChoosedFilters, viewSection,
}) => {
  const { t } = useTranslation();
  const [areSectionsVisible, setAreSectionsVisible] = useState(false);
  const [isAddRemoveFavourite, setIsAddRemoveFavourite] = useState(false);
  const [selectedFavouriteFilters, setSelectedFavouriteFilters] = useState(null);

  const handleToggle = useCallback(
    () => setAreSectionsVisible(!areSectionsVisible),
    [areSectionsVisible],
  );

  const handleAddFavouriteFilter = useCallback(
    (filters) => {
      filters && setSelectedFavouriteFilters(filters);
      setIsAddRemoveFavourite((prevState) => !prevState);
    }, [],
  );

  const handleCurrentSectionChange = useCallback(
    () => {
      setSelectedFavouriteFilters(null);
      setIsAddRemoveFavourite((prevState) => !prevState);
    },
    [],
  );

  const ToggleButton = () => (
    <OverlayTrigger
      placement="right"
      overlay={(props) => (
        <Tooltip id="search-favourite-tooltip" {...props}>
          {
              areSectionsVisible
                ? t(schema.hideFilters)
                : t(schema.showFilters)
            }
        </Tooltip>
      )}
    >
      <Button className="search-favourite-toggle" variant="primary" onClick={handleToggle}>
        <IconSvg name={areSectionsVisible ? ICON.CHEVRON_LEFT : ICON.CHEVRON_RIGHT} className="search-favourite-toggle-icon" />
      </Button>
    </OverlayTrigger>
  );

  const sections = [
    {
      id: 'search',
      key: 'search',
      label: t(schema.search),
      content: <SearchSection
        types={types}
        typeSection="search"
        isUIBlocked={isUIBlocked}
        onAddFavouriteFilter={handleAddFavouriteFilter}
        favouriteFilters={selectedFavouriteFilters}
        initialChoosedFilters={initialChoosedFilters}
        view={viewSection}
      />,
    },
    {
      id: 'favourite',
      key: 'favourite',
      label: t(schema.favourite),
      content: <FavouriteSection
        onAddFavouriteFilter={handleAddFavouriteFilter}
      />,
    },
  ];

  return (
    <div className={`search-favourite${areSectionsVisible ? '' : ' collapsed'}`}>
      <CustomTabs
        tabTitleGetter={prop('title')}
        sections={sections}
        defaultSectionIndex={isAddRemoveFavourite ? 1 : 0}
        onCurrentSectionChangeCallback={handleCurrentSectionChange}
        className="tabs-search-favourite search-favourite-section"
        tabsClassName="main-tabs-search-favourite-content"
        contentClassName="tabs-search-favourite-content"
      />
      <ToggleButton />
    </div>
  );
};

SearchFavouriteSections.propTypes = {
  isUIBlocked: PropTypes.bool,
  types: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  initialChoosedFilters: PropTypes.arrayOf(PropTypes.shape({})),
  viewSection: PropTypes.string,
};

SearchFavouriteSections.defaultProps = {
  isUIBlocked: false,
  types: null,
  initialChoosedFilters: [],
  viewSection: 'tripView',
};

export default SearchFavouriteSections;
