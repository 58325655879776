import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Col, Container, Row, Form, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Else, If, Then } from '@components/Conditionals/If';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import IconSvg from '@components/IconSvg/IconSvg';

import tSchema from '@lang/schema';

import { apiSaveOnSelectFavourite } from '@services/userPreferences/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import './style.scss';

const schema = tSchema.fishingActivities.logbookViewer.filters;

const FavouriteSection = ({
  onAddFavouriteFilter: handleAddFavouriteFilter,
}) => {
  const { t } = useTranslation();

  const [favourites, setFavourites] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);

  const { setUserPreferences, userPreferences } = useUserPreferences();

  useEffect(() => {
    userPreferences && userPreferences.logbookFavFilters
    && setFavourites(userPreferences.logbookFavFilters);
  }, [userPreferences]);

  const handleClickAddSearch = useCallback(
    (filters) => {
      handleAddFavouriteFilter(filters);
    },
    [handleAddFavouriteFilter],
  );

  const onShowConfirmationModal = useCallback((index) => {
    setShowConfirmationModal(true);
    setIndexToRemove(index);
  }, []);

  const onHideConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const handeClickRemove = useCallback(
    async (favouriteIndexSelected) => {
      const newUserPreferences = {
        ...userPreferences,
        logbookFavFilters: favourites.filter(
          (state, favouriteIndex) => favouriteIndexSelected !== favouriteIndex,
        ),
      };

      const resSaveSelectFavourite = await apiSaveOnSelectFavourite()(newUserPreferences);
      if (resSaveSelectFavourite.ok) {
        setUserPreferences((prevState) => ({ ...prevState, ...newUserPreferences }));
        setFavourites(newUserPreferences.logbookFavFilters);
      }
      setShowConfirmationModal(false);
    },
    [favourites, userPreferences, setUserPreferences],
  );

  return (
    <Container fluid id="favourite-section">
      <If conditional={favourites.length}>
        <Then>
          {favourites.map(({ name, filters }, index) => (
            <Row className="my-4 mx-3" key={`favourite-row-${name}`}>
              <Col className="p-0">
                <Form.Label className="m-0">{name}</Form.Label>
              </Col>
              <Col className="d-flex justify-content-end p-0 col-favourite-buttons">
                <OverlayTrigger
                  key="favourite-add"
                  placement="top"
                  overlay={(<Tooltip id="tooltip-top">Add to Search</Tooltip>)}
                >
                  <Button className="favourite-button" onClick={() => handleClickAddSearch(filters)}><IconSvg name="search" /></Button>
                </OverlayTrigger>
                <OverlayTrigger
                  key="favourite-remove"
                  placement="top"
                  overlay={(<Tooltip id="tooltip-top">Remove from Favourites</Tooltip>)}
                >
                  <Button className="favourite-button" onClick={() => onShowConfirmationModal(index)}><IconSvg name="close" /></Button>
                </OverlayTrigger>
              </Col>
            </Row>
          ))}
        </Then>
        <Else>
          <span>{t(tSchema.fishingActivities.logbookViewer.filters.anyFavourites)}</span>
        </Else>
      </If>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        title={t(schema.removeFavourite)}
        message={t(schema.removeFavouriteConfirmation)}
        onConfirm={() => handeClickRemove(indexToRemove)}
        onCancel={onHideConfirmationModal}
        cancelButtonText={t(tSchema.common.cancel)}
        confirmButtonText={t(tSchema.common.remove)}
      />
    </Container>
  );
};

FavouriteSection.propTypes = {
  onAddFavouriteFilter: PropTypes.func.isRequired,
};

export default FavouriteSection;
