/* eslint-disable import/order */
import './style.scss';

import { sort } from 'ramda';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchErrorMessage } from '@common/utilities/Notification';
import { DIRECTION, formatHeaderSort, DOMAIN } from '@common/utils';
import DataTable from '@components/DataTable';
import Filters from '@components/Filters';
import tSchema from '@lang/schema';
import ModalGridReferencesAttributes from '@pages/ReferenceViewer/components/ModalGridReferencesAttributes';
import { getFiltersList, getTableConfig, searchOptions } from '@pages/ReferenceViewer/config';
import {
  cleanUploadReferenceReports,
  clearReferenceAttributes,
  fetchAllReferenceData,
  fetchReferenceAttributesById,
  setFilter,
  setPagination,
  updateReferenceData,
  uploadReferenceAttributes,
  createReferenceAttribute,
} from '@services/vessel_record/actions/referenceData';

import { ModalUploadReference } from './components/modals';
import ModalAddEditReferenceAttribute from './components/modals/ModalAddEditReferenceAttribute';

import {
  Permissions, hasPermission,
} from '@common/keycloakFunctions';

import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

const initialFilters = {
  name: '',
};

const sortColumns = (a, b) => {
  const [aVal, bVal] = [a, b].map(({ order }) => parseInt(order, 10));
  return aVal - bVal;
};

const getTypeOfReference = (referenceDataName) => referenceDataName.substring(0, 3);

const ReferenceViewer = () => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);
  const filtersList = useMemo(() => getFiltersList(t), [t]);

  const dispatch = useDispatch();
  const referenceData = useSelector((state) => state.referenceData.referenceData);
  const updatedReferenceData = useSelector((state) => state.referenceData.updatedReferenceData);
  const referenceAttributes = useSelector((state) => state.referenceData.referenceAttributes);
  const isLoading = useSelector((state) => state.referenceData.isLoading);
  const pagination = useSelector((state) => state.referenceData.pagination);
  const error = useSelector((state) => state.referenceData.error);
  const uploadReference = useSelector((state) => state.referenceData.uploadReference);
  const permissions = useSelector((state) => state.authentication.permissions);
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [edit, setEdit] = useState(null);
  const [paginatedData, setPaginatedData] = useState([]);

  const [columnsReferenceAttributes, setColumnsReferenceAttributes] = useState([]);
  const [showModalRefAttribute, setShowModalRefAttribute] = useState(false);
  const [sortedReferenceData, setSortedReferenceData] = useState(referenceData);

  const { page = 1, size = 10, orderBy } = pagination;

  const totalPages = useMemo(() => ((sortedReferenceData && size)
    ? Math.ceil(sortedReferenceData.length / size)
    : 1),
  [sortedReferenceData, size]);

  const [referenceDataInfo, setReferenceDataInfo] = useState({
    code: '',
    name: '',
    description: '',
    list: [],
  });

  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;

  const changeReferenceDataInfo = (event) => {
    const { id, value } = event.target;
    setReferenceDataInfo({ ...referenceDataInfo, [id]: value });
  };

  useEffect(() => {
    setReferenceDataInfo({
      code: sortedReferenceData[index]?.code,
      name: sortedReferenceData[index]?.name,
      description: sortedReferenceData[index]?.description,
      list: referenceAttributes,
    });
    setColumnsReferenceAttributes(sortedReferenceData[index]?.columns);
  }, [index, referenceAttributes]);

  const getReferenceData = useCallback(() => {
    dispatch(fetchAllReferenceData({
      page,
      size,
      orderBy,
    }));
  }, [page, size, orderBy]);

  const onClickSave = useCallback(() => {
    dispatch(updateReferenceData({
      code: referenceDataInfo.code,
      name: referenceDataInfo.name,
      description: referenceDataInfo.description,
      columns: columnsReferenceAttributes,
    }));
    setShow(false);
  }, [referenceDataInfo, columnsReferenceAttributes]);

  const onUpload = useCallback((file) => {
    dispatch(uploadReferenceAttributes(sortedReferenceData[index].code, file, () => {
      dispatch(fetchReferenceAttributesById(sortedReferenceData[index].code));
    }));
  }, [index, sortedReferenceData]);

  const onHideModal = () => {
    dispatch(cleanUploadReferenceReports());
  };

  useEffect(() => {
    if (!show) {
      dispatch(clearReferenceAttributes());
    }
  }, [show]);

  useEffect(() => {
    getReferenceData();
  }, [updatedReferenceData]);

  const handleEditRow = useCallback((row) => {
    setIndex(sortedReferenceData.findIndex((elem) => elem.name === row.name));
    dispatch(fetchReferenceAttributesById(row.code));
    setEdit(true);
    setShow(true);
  }, [sortedReferenceData]);

  const handleViewRow = useCallback((row) => {
    setIndex(sortedReferenceData.findIndex((elem) => elem.name === row.name));
    dispatch(fetchReferenceAttributesById(row.code));
    setEdit(false);
    setShow(true);
  }, [sortedReferenceData]);

  const actions = useMemo(() => [
    {
      label: t(tSchema.common.edit),
      action: handleEditRow,
      disabled: domainCode === DOMAIN.ISR
        && !hasPermission(permissions, Permissions.EDIT_REFERENCE_DATA),
    },
    {
      label: t(tSchema.common.view),
      action: handleViewRow,
    },
  ], [handleEditRow]);

  const onPageChangeHandler = (pageHandler) => {
    dispatch(setPagination({
      page,
      size,
      orderBy,
    }));
    setPaginatedData(
      sortedReferenceData.slice(size * (pageHandler - 1), size * pageHandler),
    );
  };

  const onTableHeaderClick = useCallback((header) => {
    if (header.code && !isLoading) {
      const newOrderBy = formatHeaderSort(header, orderBy);
      dispatch(setPagination({
        page: 1,
        size,
        orderBy: newOrderBy,
      }));
    }
  }, [orderBy, pagination.size, isLoading]);

  useEffect(() => {
    setSortedReferenceData(
      orderBy?.order
        ? [...referenceData].sort((a, b) => {
          if (orderBy.order === DIRECTION.ASC) {
            return a[orderBy.field] > b[orderBy.field] ? 1 : -1;
          }
          return a[orderBy.field] < b[orderBy.field] ? 1 : -1;
        })
        : referenceData,
    );
  }, [orderBy, referenceData]);

  useEffect(() => {
    onPageChangeHandler(page);
  }, [sortedReferenceData]);

  const onFilterApplied = (_, formatedFilters) => {
    dispatch(
      setFilter(formatedFilters?.name?.value || ''),
    );
  };

  const onSetPageSize = useCallback((newSize) => {
    setPaginatedData(sortedReferenceData.slice(0, newSize));
    dispatch(setPagination({
      page: 1,
      size: newSize,
      orderBy,
    }));
  }, [sortedReferenceData, orderBy]);

  useEffect(() => {
    setPaginatedData(sortedReferenceData.slice(0, size));
  }, [sortedReferenceData]);

  const columnsDynamicTable = useMemo(() => [
    ...(sort(sortColumns, columnsReferenceAttributes || [])),
    ...((edit === true && show) ? [{
      code: 'actions',
      editable: false,
      name: 'actions',
      vesselRefernceDataCode: 'booleanType',
      width: '10%',
    }] : []),
  ],
  [columnsReferenceAttributes, edit, show]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && handleViewRow(row), // Double Click
    [handleViewRow],
  );

  const handleCloseModalAddEditReferenceAttribute = useCallback(() => {
    setShowModalRefAttribute(false);
  }, []);

  const handleSubmitFormModalAddEditReferenceAttribute = useCallback((values) => {
    dispatch(createReferenceAttribute(sortedReferenceData[index]?.code, values));
    setShowModalRefAttribute(false);
  }, [sortedReferenceData, index]);

  const changeFileHandler = useCallback((e) => {
    if (e.target.files[0] != null) {
      if (getTypeOfReference(referenceDataInfo.name) === 'XFA'
                               && getTypeOfReference(e.target.files[0].name) !== 'XFA') {
        dispatchErrorMessage('MDR files are not allowed in XFA Reference');
      } else {
        onUpload(e.target.files[0]);
      }
    }
  }, [referenceDataInfo, onUpload]);

  return (
    <div id="referenceViewer">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Filters
              searchOptions={searchOptions}
              filters={filtersList}
              initialFilters={initialFilters}
              onUpdateSearchFilters={onFilterApplied}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <DataTable
              actions={actions}
              tableId="referenceData"
              totalResults={sortedReferenceData.length}
              tableConfig={tableConfig}
              orderBy={orderBy}
              onTableHeaderClick={onTableHeaderClick}
              rowData={paginatedData}
              pageSize={size}
              onPageSizeChangeHandler={onSetPageSize}
              onPageChangeHandler={onPageChangeHandler}
              onRowSelected={handleRowSelected}
              loading={isLoading}
              maxPages={totalPages}
              pageRequest={page}
              setPageRequest={() => {}}
              tableActions={false}
              error={error}
              errorMessageEmptyArray="No reference data found"
              errorMessageLoadingData="An error has occurred loading the reference data"
            />
          </Col>
        </Row>
      </Container>
      {show && (
        <>
          <ModalGridReferencesAttributes
            show={show}
            edit={edit}
            referenceData={referenceData}
            index={index}
            referenceDataInfo={referenceDataInfo}
            onChangeReferenceDataInfo={changeReferenceDataInfo}
            columns={columnsDynamicTable}
            columnsReferenceAttributes={columnsReferenceAttributes}
            onChangeFile={changeFileHandler}
            showModalAddNewLine={() => { setShowModalRefAttribute(true); }}
            onSave={onClickSave}
            closeModalGrid={() => { setShow(false); }}
          />
        </>
      )}
      <ModalUploadReference
        reports={uploadReference.reports}
        onHideModal={onHideModal}
        show={uploadReference.error != null || uploadReference.reports != null}
        error={uploadReference.error}
      />
      <ModalAddEditReferenceAttribute
        formFields={columnsReferenceAttributes}
        isLoading={isLoading}
        onClose={handleCloseModalAddEditReferenceAttribute}
        onSubmitForm={handleSubmitFormModalAddEditReferenceAttribute}
        showModal={showModalRefAttribute}
        title="Add new line"
      />
    </div>
  );
};

export default ReferenceViewer;
