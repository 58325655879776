import Filters from './filters';
import SectionAttachFile from './SectionAttachFile';
import SectionDeleteReport from './SectionDeleteReport';
import SectionRemoveObjRef from './SectionRemoveObjRef';
import SectionReportViewer from './SectionReportViewer';
import SectionViewMessage from './SectionViewMessage';
import SectionViewReport from './SectionViewReport';

const REPORT_VIEWER = {
  TABLE: {
    title: 'Report Grid',
    body: <SectionReportViewer />,
  },
  FILTER: {
    title: 'Filters',
    body: <Filters />,
  },
  VIEW: {
    title: 'View Report',
    body: <SectionViewReport />,
  },
  DELETE: {
    title: 'Delete Report',
    body: <SectionDeleteReport />,
  },
  REMOVE_OBJ_REF: {
    title: 'Remove Object Reference',
    body: <SectionRemoveObjRef />,
  },
  ATTACH_FILE: {
    title: 'Attach File',
    body: <SectionAttachFile />,
  },
  VIEW_MESSAGE: {
    title: 'View Message',
    body: <SectionViewMessage />,
  },
};

export default REPORT_VIEWER;
