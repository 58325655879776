// import { fullfilled } from '../../../utils';
import { Actions } from '../../actions/vessel';

const getVersionsFromVesselFullfilled = (state, { payload }) => ({
  ...state,
  loadingHistoric: false,
  vesselHistoric: { ...payload },
});

const getVersionsFromVesselPending = (state) => ({
  ...state,
  loadingHistoric: true,
});

const getVersionsFromVesselRejected = (state, { payload }) => ({
  ...state,
  loadingHistoric: false,
  error: payload,
});

const getVersionsFromVesselChangesFullfilled = (state, { payload }) => ({
  ...state,
  loadingHistoric: false,
  vesselHistoric: {
    ...state.vesselHistoric,
    content: payload,
  },
});

const getVersionsFromVesselChangesPending = (state) => ({
  ...state,
  loadingHistoric: true,
});

const getVersionsFromVesselChangesRejected = (state, { payload }) => ({
  ...state,
  loadingHistoric: false,
  error: payload,
});

const cleanHistoric = (state) => ({
  ...state,
  vesselHistoric: {
    ...state.vesselHistoric,
    content: [],
  },
});

const getPreviousVersionFullfilled = (state, { payload }) => ({
  ...state,
  previousVersion: payload.length > 1 ? payload[1] : null,
});
const getPreviousVersionRejected = (state) => ({
  ...state,
  previousVersion: null,
});

const Historic = {
  [Actions.GET_VERSIONS_FROM_VESSEL.FULFILLED]: getVersionsFromVesselFullfilled,
  [Actions.GET_VERSIONS_FROM_VESSEL.REJECTED]: getVersionsFromVesselRejected,
  [Actions.GET_VERSIONS_FROM_VESSEL.PENDING]: getVersionsFromVesselPending,
  [Actions.GET_VERSIONS_FROM_VESSEL_CHANGES.FULFILLED]: getVersionsFromVesselChangesFullfilled,
  [Actions.GET_VERSIONS_FROM_VESSEL_CHANGES.REJECTED]: getVersionsFromVesselChangesRejected,
  [Actions.GET_VERSIONS_FROM_VESSEL_CHANGES.PENDING]: getVersionsFromVesselChangesPending,
  [Actions.CLEAN_HISTORIC]: cleanHistoric,
  [Actions.GET_PREVIOUS_VERSION.FULFILLED]: getPreviousVersionFullfilled,
  [Actions.GET_PREVIOUS_VERSION.REJECTED]: getPreviousVersionRejected,
};

export default Historic;
