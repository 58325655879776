import { any, propEq } from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import { fetchTypes as fetchTypesNameReportView } from '../components/ReportViewSection/services/api';
import { fetchTypes as fetchTypesNameTripView } from '../components/TripViewSection/services/api';
import { getTypeNames } from '../components/viewConfig';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';

const useLogbookTypes = ({ setLoading, isTripView = false }) => {
  const { userPreferences } = useUserPreferences();
  const [types, setTypes] = useState([]);
  const fetchTypes = isTripView ? fetchTypesNameTripView : fetchTypesNameReportView;
  const typeNames = getTypeNames(isTripView);
  const dispatch = useDispatch();
  const requestWithLoading = useRequestWithLoading();
  const ALL_SCENARIOS_ID = 0;

  useEffect(() => {
    if (userPreferences.userScenariosFa.length !== 0) {
      requestWithLoading(async () => {
        const promises = await Promise.all(typeNames.map(
          fetchTypes(userPreferences.lastActiveFa?.id
            ? userPreferences.lastActiveFa.id : ALL_SCENARIOS_ID),
        ));

        const allOk = !any(propEq('ok', false))(promises);

        if (allOk) {
          const responses = await Promise.all(promises.map((x) => x.json()));
          const options = {};
          responses.forEach((x, i) => {
            options[typeNames[i]] = x;
          });
          setTypes(options);
        } else {
          setTypes([]);
          dispatch(showErrorNotification('An error while loading the filter options has occurred'));
        }
      }, types && setLoading);
    }
  }, [requestWithLoading, userPreferences.userScenariosFa, userPreferences.lastActiveFa]);

  return types;
};

export default useLogbookTypes;
