import PropTypes from 'prop-types';
import {
  propEq, map, when, assoc, compose, equals,
} from 'ramda';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Container,
  Col,
  Row,
  Modal,
  Alert,
  Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import { useKeyCloak } from '@services/authentication/useKeyCloakContext';
import {
  apiFetchUserScenarios,
  apiAssignScenario,
} from '@services/scenarios/api';
import { apiFetchFilteredUsers } from '@services/ums/api';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import tableConfig from './config';

import { BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';
import { useRequestWithLoading } from '@common/hooks/request';
import {
  Permissions, isIsrDataAdmin, hasPermission, isDataOrSystemAdmin,
} from '@common/keycloakFunctions';
import {
  requestsWithMessage, requestWithMessage, showErrorNotification,
} from '@common/utilities/Notification';
import './style.scss';
import { DOMAIN } from '@common/utils';

const schema = tSchema.dmaAdmin.scenarioMGMT.users;

const getErrors = (modifiedScenarios, t) => modifiedScenarios && modifiedScenarios.map((e) => {
  const mandatoryError = e.isAssigned && (!e.startAccessPeriod || e.startAccessDate === 'Invalid Date');
  const dateError = (e.isAssigned && e.startAccessPeriod > e.endAccessPeriod);
  return (mandatoryError || dateError) ? {
    name: e.name,
    errors: [
      ...(mandatoryError ? [t(schema.validation.startAccessDateRequired)] : []),
      ...(dateError ? [t(schema.validation.fromBeforeTo)] : []),
    ],
  } : null;
}).filter(Boolean);

const scenarioChangeMapper = (e) => (
  <li>
    <span>
      Scenario
      <b>
        {' '}
        {e.name}
      </b>
    </span>
    {' will be '}
    <span>
      {e.isAssigned ? (
        <>
          <b>assigned</b>
          {' from '}
          <b>
            {e.startAccessPeriod}
          </b>
          {' to '}
          <b>
            {e.endAccessPeriod || 'indefinite'}
          </b>
        </>
      ) : <b>unassigned</b>}
    </span>
  </li>
);

export const UsersSection = ({ domain }) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userScenarios, setUserScenarios] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [modifiedScenarios, setModifiedScenarios] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const dataTableRef = useRef();
  const pageRequest = useRef(1);
  const totalResults = useRef(0);
  const maxPages = useRef(0);
  const defaultFilter = 'all';
  const { userPreferences } = useUserPreferences();
  const domainCode = userPreferences && userPreferences.domain;
  const permissions = useSelector((state) => state.authentication.permissions);
  const triggerDataFetch = () => setForceUpdateFlag({});

  const { keycloak } = useKeyCloak();
  const requestWithLoading = useRequestWithLoading();

  const fetchUsers = useCallback(async () => {
    await requestWithMessage({
      request: () => apiFetchFilteredUsers(defaultFilter),
      errorMessage: t(tSchema.notifications.dmaAdmin.scenarioMGMT.users.fetchUsers.error),
      onSuccess: async (response) => {
        setUsers(await response.json());
      },
      setLoading,
    });
  },
  [defaultFilter]);

  useEffect(() => {
    fetchUsers(defaultFilter);
  }, [defaultFilter]);

  const fetchUserScenarios = useCallback(() => {
    requestWithLoading(async () => {
      const validUser = selectedUserId && selectedUserId > 0;
      const fetchedData = validUser ? await apiFetchUserScenarios(
        selectedUserId,
        {
          page: pageRequest.current - 1,
          size: pageSize,
        },
        domain,
      ) : null;

      if (fetchedData && fetchedData.ok) {
        const data = await fetchedData.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setScenarios(data.content || []);
        const formattedData = data && data.content && data.content.map((e) => ({
          ...e,
          ...(e.startAccessPeriod && {
            startAccessPeriod: formatDate(e.startAccessPeriod, BACK_DATE_FORMAT),
          }),
          ...(e.endAccessPeriod && {
            endAccessPeriod: formatDate(e.endAccessPeriod, BACK_DATE_FORMAT),
          }),
        }));
        setUserScenarios(formattedData || []);
        setScenarios(formattedData || []);
      } else {
        setUserScenarios([]);
        setScenarios([]);
        validUser && showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  }, [selectedUserId, pageSize, pageRequest.current]);

  useEffect(() => {
    fetchUserScenarios();
  }, [
    forceUpdateFlag,
    selectedUserId,
    pageSize,
  ]);

  const isRowModified = useCallback((e) => {
    const originalUserScenario = scenarios.find(propEq('id', e.id));
    if (!e.touched) {
      return false;
    }
    return e.isAssigned
      ? originalUserScenario.isAssigned !== e.isAssigned
      || originalUserScenario.startAccessPeriod !== e.startAccessPeriod
      || originalUserScenario.endAccessPeriod !== e.endAccessPeriod
      : originalUserScenario.isAssigned !== e.isAssigned;
  }, [scenarios]);

  const onRowChangeHandler = useCallback(
    (value, id, field) => {
      setUserScenarios(map(
        when(propEq('id', id), compose(assoc('touched', true), assoc(field, value))),
        userScenarios,
      ));
    }, [scenarios, userScenarios],
  );

  const tableColumns = tableConfig(onRowChangeHandler);

  const isDataAdmin = isDataOrSystemAdmin(keycloak);
  const isISRDataAdmin = isIsrDataAdmin(keycloak);

  const saveButtonClickHandler = useCallback(() => {
    setShowConfirm(true);
  }, []);
  const cancelButtonClickHandler = useCallback(() => {
    setUserScenarios(scenarios);
  }, [scenarios]);

  const isChanged = useMemo(
    () => !equals(userScenarios, scenarios), [userScenarios, scenarios],
  );

  const actionButtonsDisabled = useMemo(
    () => loading
    || !isChanged, [loading, isChanged],
  );

  const isHidden = useMemo(
    () => {
      switch (domainCode) {
        case DOMAIN.ISR:
          return !hasPermission(permissions, Permissions.EDIT_SCENARIO_USERS)
            && !(isDataAdmin || isISRDataAdmin);
        default:
          return !(isDataAdmin || isISRDataAdmin);
      }
    }, [domainCode, permissions, isDataAdmin],
  );

  const saveButton = useMemo(() => (
    <Button
      id="save-users"
      className="symbol-tick"
      onClick={saveButtonClickHandler}
      disabled={actionButtonsDisabled}
      hidden={isHidden}
    >
      {t(tSchema.common.save)}
    </Button>
  ), [saveButtonClickHandler, actionButtonsDisabled, isDataAdmin, domainCode]);
  const cancelButton = useMemo(() => (
    <Button
      id="save-users"
      className="symbol-cross"
      onClick={cancelButtonClickHandler}
      disabled={actionButtonsDisabled}
      hidden={isHidden}
    >
      {t(schema.discardChanges)}
    </Button>
  ), [cancelButtonClickHandler, actionButtonsDisabled, isDataAdmin, domainCode]);

  useEffect(() => {
    if (showConfirm) {
      const newModifiedScenarios = userScenarios.filter(isRowModified);
      setModifiedScenarios(newModifiedScenarios);
      setValidationErrors(getErrors(newModifiedScenarios, t));
    }
  }, [showConfirm, userScenarios, isRowModified]);

  const saveHandler = useCallback(async () => {
    if (!modifiedScenarios || modifiedScenarios.length < 1) {
      return;
    }

    setShowConfirm(false);

    const apiScenarios = modifiedScenarios.map((scenario) => ({
      isAssigned: scenario.isAssigned,
      userId: selectedUserId,
      scenarioId: scenario.id,
      ...(scenario.startAccessPeriod !== 'Invalid Date' && { startAccessDate: scenario.startAccessPeriod }),
      ...(scenario.endAccessPeriod !== 'Invalid Date' && { endAccessDate: scenario.endAccessPeriod }),
    }));

    await requestsWithMessage({
      requests: apiScenarios.map((scenarioItem) => apiAssignScenario(scenarioItem, domain)),
      successMessage: t(tSchema.notifications.dmaAdmin.scenarioMGMT.users.assignScenario.success),
      errorMessage: t(tSchema.notifications.dmaAdmin.scenarioMGMT.users.assignScenario.error),
      setLoading,
      onFinish: triggerDataFetch,
    });
  }, [selectedUserId, isRowModified, modifiedScenarios]);

  const cancelSaveHandler = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };

  const selectUserHandler = (option) => {
    resetPagination();
    setSelectedUserId((option && option.value) || -1);
    setSelectedUser(option);
  };

  const userOptions = useMemo(
    () => users?.map((user) => ({ label: user.name, value: user.id })), users,
  );

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
  }, []);
  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    triggerDataFetch();
  }, []);

  return (
    <Container fluid id="scenariosViewer">
      <>
        <Form.Row>
          <Form.Group as={Col} controlId="typeOfEntity">
            <Form.Label>{t(schema.selectUser)}</Form.Label>
            <ReactSelect
              isMulti={false}
              closeMenuOnSelect
              options={userOptions || []}
              value={selectedUser}
              onChange={selectUserHandler}
              isLoading={loading}
            />
            <Form.Control.Feedback type="invalid">
              {t(schema.selectUser)}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Row>
          <Col>
            {selectedUserId && (
            <DataTable
              ref={dataTableRef}
              tableId="scenarios"
              totalResults={totalResults.current}
              tableConfig={tableColumns}
              rowData={userScenarios}
              loading={loading}
              defaultPageSize={pageSize}
              onPageSizeChangeHandler={pageSizeChangeHandler}
              onPageChangeHandler={pageChangeHandler}
              maxPages={maxPages.current}
              tableActions={(
                <>
                  {cancelButton}
                  {saveButton}
                </>
                )}
            />
            )}
          </Col>
        </Row>

        <Modal
          backdrop="static"
          show={showConfirm}
          onHide={cancelSaveHandler}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t(schema.assignScenarios)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {validationErrors.length || (!modifiedScenarios || modifiedScenarios.length < 1)
              ? (
                <>
                  <Alert variant="danger">
                    {t(schema.validation.validationErrors)}
                  </Alert>
                  {(!modifiedScenarios || modifiedScenarios.length < 1)
                    && t(schema.validation.noChanges)}
                  {validationErrors && validationErrors.map((e) => (
                    <>
                      <b>{`Scenario ${e.name}`}</b>
                      <ul>
                        {e.errors.map((error) => <li>{error}</li>)}
                      </ul>
                    </>
                  ))}
                </>
              )
              : (
                <>
                  <Alert variant="secondary">
                    {modifiedScenarios.map(scenarioChangeMapper)}
                  </Alert>
                </>
              )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="symbol-cross"
              variant="primary"
              onClick={cancelSaveHandler}
            >
              {t(tSchema.common.cancel)}
            </Button>
            {(!validationErrors.length && modifiedScenarios && modifiedScenarios.length > 0) && (
              <Button
                className="symbol-tick"
                variant="success"
                onClick={saveHandler}
              >
                {t(tSchema.common.confirm)}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <br />
      </>
    </Container>
  );
};

UsersSection.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default UsersSection;
