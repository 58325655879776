import importButton from './img/import_from_button.png';
import modalImport from './img/modal_import.png';
import modalReport from './img/modal_report.png';

const SectionImportFrom = () => (
  <div className="subSection">
    <p>
      Using the &quot;Import from&quot; button (located below the vessels grid,
      at the bottom right corner of the screen), the user can create or edit at
      once multiple vessels from a file. By clicking the button, a list of valid
      types of files appears.
    </p>
    <p>
      <img src={importButton} alt="importButton" />
    </p>
    <p>
      While the file is uploading, the modal is blocked.
    </p>
    <p>
      <img src={modalImport} alt="modalImportFile" />
    </p>
    <p>
      And when the upload is finished,
      a report containing the result of the import
      (including both successful imports and errors and warnings) can be downloaded.
      And when the upload is finished, a report with the errors and
      warnings can be downloaded.
    </p>
    <p>
      <img src={modalReport} alt="modalReport" />
    </p>
  </div>
);

export default SectionImportFrom;
