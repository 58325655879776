import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import deleteImage from './img/delete.png';
import deleteImageISR from './img/deleteISR.png';

import { DOMAIN } from '@common/utils';

const SectionDeleteQueryAcceptance = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="SectionDeleteAcceptance">
      <p>
        To delete an acceptance query,
        in the results grid the user must click on the &quot;Actions&quot; button corresponding
        to the row of that specific acceptance rule, and then click on &quot;Delete&quot; button.
      </p>
      <p>
        A modal asks for confirmation on the removal.
      </p>
      {domain === DOMAIN.FA
        ? (
          <p>
            <img src={deleteImage} alt="Delete Acceptance" />
          </p>
        )
        : (
          <p>
            <img src={deleteImageISR} alt="Delete Acceptance" />
          </p>
        )}
      <p>
        On confirming the deletion,
        the query acceptance is automatically removed from the
        list displayed in the results grid.
      </p>
    </div>
  );
};

export default SectionDeleteQueryAcceptance;
