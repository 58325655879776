import actions from './img/actions.png';
import actionsVersion from './img/actions_version.JPG';
import versions from './img/versions.JPG';
import viewVersion from './img/view_version.JPG';

const SectionViewVessel = () => (
  <div className="subSection">
    <p>
      From the grid of vessels, in the column ‘Actions’, selecting the &quot;View&quot; action.
    </p>
    <p>
      <img src={actions} alt="actions" />
    </p>
    <p>
      opens a new window containing the versions of the
      vessel selected
    </p>
    <p>
      <img src={versions} alt="Versions list" />
    </p>
    <p>
      For each version, there are some actions available from the ‘Actions’ column
    </p>
    <p>
      <img src={actionsVersion} alt="Versions actions" />
    </p>
    <p>
      The &quot;View&quot; action opens a new window that allows
      a user to see the detailed information of the version of the vessel,
      but without the capability to edit it.
    </p>
    <p>
      <img src={viewVersion} alt="View Version" />
    </p>
  </div>
);

export default SectionViewVessel;
