import { fetchLogbookResourceData } from '@services/tripLogbook/api';
import { get } from '@services/utils';

export function fetchTypes(scenarioId) {
  return (option) => (option === 'vesselGroups' ? get()(`vrService/groupRecord/getGroups/list/${scenarioId}`)
    : get()(`logbook/options/${option}`));
}

/**
 * @param {{ filters: String, sort: String, direction: String,
 *    page: Number, limit: Number }} query The query params
 * @returns {Promise<Response>}
 */
export function fetchDeclarationList(filters, query) {
  return fetchLogbookResourceData('declarations', filters, query);
}

export function exportData() {
  return (filters, type) => get()(`logbook/declarations/${filters}/export?as=${type}`.replace('//', '/'));
}

export default {
  fetchTypes,
};
