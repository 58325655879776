import PropTypes from 'prop-types';
import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { isReportCancelled, isReportDeleted } from '@pages/FishingTripsLogbook/common/utils';

import DataTable from '@components/DataTable';

import tSchema from '@lang/schema';

import { useBaseMessageService } from '@services/messages/BaseMessageContext';

import ReportMessageModal from './components/ReportMessageModal';
import { fetchReportViewSectionMessage } from './services/api';

import { tableConfig } from './config';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { isNilOrEmpty } from '@common/utils';

const ReportViewTable = ({
  tripsData,
  loading,
  dataTableRef = {},
  filterOptions,
  rowSelected,
  onViewReportDetails,
  setLoading,
  onRowSelected: handleRowSelected,
  ...rest
}) => {
  const { t } = useTranslation();
  const { totalElements, totalPages, content: contentTrips } = tripsData;
  const { services: Services } = useBaseMessageService();
  const [toggleValue, setToggleValue] = useState(false);
  const [loadingXml, setLoadingXml] = useState(false);
  const [xmlMessageCache, setXmlMessageCache] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reportMessage, setReportMessage] = useState(null);
  const requestWithLoading = useRequestWithLoading();
  const handleReportMessageLoaded = useCallback((message) => setReportMessage(message), []);
  // TODO this functionality needs to be defined by client
  const handleRelatedFishingTrips = useCallback(
    () => null, [],
  );
    // TODO this functionality needs to be defined by client
  const relatedFishingTripsConditionallyDisabled = useCallback(
    () => true, [],
  );
  // TODO this functionality needs to be defined by client
  const handleViewMessage = useCallback((row) => {
    !loading && !loadingXml
      && setSelectedMessage((prev) => ({
        id: row.xmlIncoming,
        useCached: prev && prev.id === row.xmlIncoming,
      }));
  }, [loading, loadingXml]);

  useEffect(() => {
    if (selectedMessage !== null) {
      selectedMessage.useCached
        ? handleReportMessageLoaded({ message: xmlMessageCache.message })
        : requestWithLoading(async () => {
          const query = await fetchReportViewSectionMessage(selectedMessage.id);

          if (query.ok) {
            const data = await query.json();
            if (data.isTooLarge) {
              setLoading(true);
              const query1 = await Services.apiDownloadXML(data.id, (data.isIncoming ? 'incoming' : 'outcoming'));
              if (query1.ok) {
                const data1 = await query1.json();
                const url = window.URL.createObjectURL(new Blob([data1]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Message.xml');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false);
              } else {
                setLoading(false);
                showErrorNotification();
              }
            } else {
              handleReportMessageLoaded({ message: data.message });
              setXmlMessageCache(data);
            }
          } else {
            showErrorNotification(t(tSchema.notifications.common.fetchData));
          }
        }, setLoadingXml);
    }
  }, [selectedMessage]);

  // TODO this functionality needs to be defined by client
  const viewMessageConditionallyDisabled = useCallback(
    () => false, [],
  );
  const handleViewReportDetails = useCallback(
    (row) => onViewReportDetails(
      row?.fishingTripId,
      row?.faType,
      row?.reportType,
    ), [onViewReportDetails],
  );
  const viewReportDetailsConditionallyDisabled = useCallback(
    (row) => isNilOrEmpty(row?.fishingTripId), [],
  );
  const actions = useMemo(() => [
    { label: 'View Catch Composition', action: handleRelatedFishingTrips, conditionallyDisabled: relatedFishingTripsConditionallyDisabled },
    { label: 'View Report Details', action: handleViewReportDetails, conditionallyDisabled: viewReportDetailsConditionallyDisabled },
    { label: 'View Message', action: handleViewMessage, conditionallyDisabled: viewMessageConditionallyDisabled },
  ], [
    handleRelatedFishingTrips,
    relatedFishingTripsConditionallyDisabled,
    handleViewReportDetails,
    viewReportDetailsConditionallyDisabled,
    handleViewMessage,
    viewMessageConditionallyDisabled,
  ]);

  const isDeletedOrCancelled = useCallback(
    (row) => isReportCancelled({ version: row.version, purposeVersion: row.purposeVersion })
    || isReportDeleted({ version: row.version, purposeVersion: row.purposeVersion }), [],
  );

  const getTableConfig = useMemo(() => tableConfig(t, setToggleValue), []);
  const tableColumns = useMemo(() => getTableConfig(toggleValue), [toggleValue]);

  const preHandleRowSelected = useCallback(
    (row, event) => {
      switch (event.detail) {
        case 1:
          handleRowSelected && handleRowSelected(row, event);
          break;
        case 2:
          // Double click
          !viewReportDetailsConditionallyDisabled(row) && handleViewReportDetails(row);
          break;
        default:
      }
    },
    [handleRowSelected, handleViewReportDetails, viewReportDetailsConditionallyDisabled],
  );

  const rowRender = ({
    row,
    id,
    cols: selectedColumns,
    subRowId,
    setSubRowId,
  }) => (
    <tr
      key={`row-${id}`}
      className={isDeletedOrCancelled(row) ? 'cancelled-or-deleted-row' : null}
      onClick={(event) => preHandleRowSelected(row, event)}
    >
      {selectedColumns.map((columnConfig) => (
        <td key={`row-${id}-column-${columnConfig.text}`} className={[columnConfig.code, columnConfig.className].filter(Boolean).join(' ')}>
          {columnConfig.render
            ? columnConfig.render(row, id, subRowId, setSubRowId)
            : row[columnConfig.code]}

        </td>
      ))}
    </tr>
  );

  return (
    <>
      <DataTable
        actions={actions}
        ref={dataTableRef}
        tableId="report-view"
        totalResults={totalElements}
        tableConfig={tableColumns}
        rowData={contentTrips || []}
        loading={loading}
        maxPages={totalPages || 1}
        isLogbookViewer
        onToggleValueCallback={(value) => setToggleValue(value)}
        onRowSelected={preHandleRowSelected}
        rowRender={rowRender}
        {...rest}
      />
      {!loading && !loadingXml && reportMessage
        && (
        <ReportMessageModal
          data={reportMessage}
          onClose={() => {
            setReportMessage(null);
          }}
        />
        ) }
    </>
  );
};

ReportViewTable.propTypes = {
  tripsData: PropTypes.shape({
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  filterOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  rowSelected: PropTypes.shape({}),
  onViewReportDetails: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  dataTableRef: PropTypes.shape({}).isRequired,
  setLoading: PropTypes.func,
};

ReportViewTable.defaultProps = {
  filterOptions: null,
  rowSelected: null,
  setLoading: null,
};

export default ReportViewTable;
