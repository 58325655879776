import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { RELOCATION_PROPTYPES, RELOCATION_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const TranshipmentOrRelocationNotification = (
  { transhipmentOrRelocationNotification, setCurrentVersionActivity },
) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(RELOCATION_SCHEMA);
  const [currentTranshipmentOrRelocationNotification,
    setCurrentTranshipmentOrRelocationNotification] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => setCurrentTranshipmentOrRelocationNotification(
    (transhipmentOrRelocationNotification && transhipmentOrRelocationNotification[pageNumber - 1])
    || RELOCATION_SCHEMA,
  ),
  [transhipmentOrRelocationNotification, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        versions={currentTranshipmentOrRelocationNotification}
        onSelectVersion={setCurrentVersion}
        pagination={pageNumber}
        reports={transhipmentOrRelocationNotification}
        onSelectPagination={setPageNumber}
      />

      <Row>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t(schema.type)} value={currentVersion?.type} />
          <TextInputField label={t(schema.identification)} value={currentVersion?.identification} />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t('Ocurrence')} value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`} />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField label={t('FLAP Document')} value={currentVersion?.flapDocument} />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

TranshipmentOrRelocationNotification.propTypes = {
  transhipmentOrRelocationNotification: RELOCATION_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default TranshipmentOrRelocationNotification;
