import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import {
  BACK_DATE_FORMAT,
  BACK_DATE_TIME_FORMAT,
  dateInRange,
  DATE_FORMAT,
  formatDate,
  getValidDateOrNull,
  isValidDate,
  MAX_AVAILABLE_DATE,
  MIN_AVAILABLE_DATE,
  TIME_FORMAT,
} from '@common/dateUtils';

export const DATE_TYPE = {
  DATE: 'date',
  DATE_TIME: 'datetime-local',
};

const reactDatePickerDate = (d, defaultDate) => {
  const date = d && new Date(d);
  return isValidDate(date) ? date : defaultDate;
};

const InputDate = ({
  id,
  value,
  readOnly,
  onChange,
  isValid = true,
  setIsValid,
  required,
  className,
  complex = false,
  type = DATE_TYPE.DATE,
  hasAction = true,
  maxDate,
  minDate,
  ...props
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isDateTime = useMemo(() => type === DATE_TYPE.DATE_TIME, [type]);

  const handleOnChange = (val) => {
    if (!val) {
      onChange(id, val);
      return;
    }
    const date = formatDate(
      (maxDate || minDate)
        ? dateInRange(val, minDate, maxDate)
        : val,
      isDateTime ? BACK_DATE_TIME_FORMAT : BACK_DATE_FORMAT,
    );
    if (hasAction) {
      if (!complex) {
        dispatch(onChange(id, date === '' ? null : date));
      } else {
        dispatch(onChange(props.complexName, id, props.index, `${date}T00:00:00.000`));
      }
      if (required && !isValid && date !== '') {
        dispatch(setIsValid(id, true));
      }
    } else {
      onChange(id, date === '' ? null : date);
    }
  };

  const selected = useMemo(() => getValidDateOrNull(value),
    [value]);
  const startDate = useMemo(() => getValidDateOrNull(props.startDate),
    [props.startDate]);
  const endDate = useMemo(() => getValidDateOrNull(props.endDate),
    [props.endDate]);

  const datePickerMinDate = useMemo(
    () => minDate && reactDatePickerDate(minDate, new Date(MIN_AVAILABLE_DATE)),
    [minDate],
  );
  const datePickerMaxDate = useMemo(
    () => maxDate && reactDatePickerDate(maxDate, new Date(MAX_AVAILABLE_DATE)),
    [maxDate],
  );

  const dateFormat = isDateTime ? `dd/MM/yyyy ${TIME_FORMAT}` : 'dd/MM/yyyy' || DATE_FORMAT;
  const timeFormat = isDateTime && TIME_FORMAT;

  return (
    <div className={['react-input-date', className, !isValid && 'is-invalid'].filter(Boolean).join(' ')}>
      <ReactDatePicker
        className={[
          'datepicker-input',
          'form-control',
          // className,
          // isValid && 'is-valid',
          !isValid && 'is-invalid',
        ].filter(Boolean).join(' ')}
        selected={selected}
        required={required}
        startDate={startDate}
        endDate={endDate}
        onChange={handleOnChange}
        maxDate={datePickerMaxDate}
        minDate={datePickerMinDate}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        locale="es"
        disabled={readOnly}
        showTimeInput={isDateTime}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={15}
        todayButton={t('Today')}
        {...props}
      />
      <span className="material-symbols-outlined">calendar_today </span>
    </div>
  );
};

InputDate.defaultProps = {
  id: undefined,
  value: undefined,
  endDate: undefined,
  startDate: undefined,
  readOnly: false,
  onChange: undefined,
  isValid: undefined,
  setIsValid: undefined,
  required: undefined,
  className: undefined,
  complex: false,
  complexName: undefined,
  index: undefined,
  type: DATE_TYPE.DATE,
  hasAction: true,
  maxDate: MAX_AVAILABLE_DATE,
  minDate: MIN_AVAILABLE_DATE,
};

InputDate.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  complex: PropTypes.bool,
  complexName: PropTypes.string,
  index: PropTypes.number,
  type: PropTypes.string,
  hasAction: PropTypes.bool,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasValidateDate: PropTypes.bool.isRequired,
};

export default InputDate;
