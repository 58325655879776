/* eslint-disable import/order */
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import './style.scss';
import {
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';
import tSchema from '@lang/schema';
import { formatDate, DATE_FORMAT, DATE_TIME_FORMAT } from '@common/dateUtils';
import { apiGetVersion } from '@services/vessel_historic/api';

const tabs = ['VESSEL IDENTIFICATION', 'VESSEL TECHNICAL DETAILS', 'COMPANY INFORMATION'];

const TabPanel = ({
  children, value, index, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={index}
    {...other}
  >
    {value === index && (
    <Box p={3}>
      <Typography component="span">{children}</Typography>
    </Box>
    )}
  </div>
);
TabPanel.defaultProps = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};
TabPanel.propTypes = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};

const schema = tSchema.vesselMGMT.historical;

const VesselHistoric = ({ etalonId }) => {
  const { t } = useTranslation();
  const historicData = useSelector((state) => state.vessel.vesselHistoric.content || []);
  const loadingHistoric = useSelector((state) => state.vessel.loadingHistoric);
  const template = useSelector(
    (state) => state.vessel.template.registerEntities[0].simpleAttributes,
  );
  const formData = useSelector((state) => state.vessel.template.formData);
  const [showCompareTable, setShowCompareTable] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedCompareRecord, setSelectedCompareRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVersion, setIsLoadingVersion] = useState(false);

  const handleChange = React.useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const historicSortedByDate = React.useMemo(() => historicData.sort((a, b) => {
    const dateA = new Date(a.updateDate);
    const dateB = new Date(b.updateDate);
    return dateA - dateB;
  }), [historicData]);

  const getVersionVessel = (version) => apiGetVersion(etalonId, version);

  const moveToNextVersion = React.useCallback(() => {
    // Solo se puede si no es la última versión
    if (selectedCompareRecord?.updateDate
      !== historicSortedByDate[historicSortedByDate.length - 1].updateDate) {
      setIsLoading(true);
      const index = historicSortedByDate.findIndex(
        (h) => h?.updateDate === selectedCompareRecord?.updateDate,
      );
      getVersionVessel(historicSortedByDate[index + 1].updateDate)
        .then((resp) => {
          setSelectedRecord(selectedCompareRecord);
          setSelectedCompareRecord(resp.data);
          setIsLoading(false);
        });
    }
  }, [selectedRecord, selectedCompareRecord, historicSortedByDate]);

  const moveToPreviousVersion = React.useCallback(() => {
    // Solo se puede si no es la primera versión
    if (selectedRecord?.updateDate !== historicSortedByDate[0].updateDate) {
      setIsLoading(true);
      const index = historicSortedByDate.findIndex(
        (h) => h.updateDate === selectedRecord.updateDate,
      );
      getVersionVessel(historicSortedByDate[index - 1].updateDate)
        .then((resp) => {
          setSelectedRecord(resp.data);
          setSelectedCompareRecord(selectedRecord);
          setIsLoading(false);
        });
    }
  }, [selectedRecord, historicSortedByDate]);

  useEffect(() => {
    if (showCompareTable) {
      const getVersions = [];
      setIsLoading(true);
      if (selectedRecord === null) {
        // Si no hay ninguna version seleccionada,
        // se seleccionan las dos primeras
        getVersions.push(getVersionVessel(historicSortedByDate[0].updateDate)
          .then((resp) => setSelectedRecord(resp.data)));
        getVersions.push(
          getVersionVessel(historicSortedByDate[1].updateDate)
            .then((resp) => setSelectedCompareRecord(resp.data)),
        );
      } else if (selectedRecord?.updateDate
         === historicSortedByDate[historicSortedByDate.length - 1]?.updateDate) {
        // Si la version seleccionada es la última, se seleccionan
        // las dos últimas
        getVersions.push(
          getVersionVessel(historicSortedByDate[historicData.length - 2].updateDate)
            .then((resp) => setSelectedRecord(resp.data)),
        );
        getVersions.push(getVersionVessel(historicSortedByDate[historicData.length - 1].updateDate)
          .then((resp) => setSelectedCompareRecord(resp.data)));
      } else {
        // Si la version seleccionada está por el medio, se selecciona
        // esa y la siguiente
        const index = historicSortedByDate.findIndex(
          (h) => h?.updateDate === selectedRecord?.updateDate,
        );
        getVersions.push(getVersionVessel(historicSortedByDate[index + 1].updateDate)
          .then((resp) => setSelectedCompareRecord(resp.data)));
      }
      Promise.all(getVersions).then(() => setIsLoading(false));
    } else {
      setSelectedCompareRecord(null);
    }
  }, [showCompareTable]);

  const onChangeVersion = (event) => {
    if (event.target.value !== 'Select a version') {
      setIsLoadingVersion(true);
      const record = historicSortedByDate?.find(
        (h) => h?.updateDate
        === event.target.value,
      );
      getVersionVessel(record.updateDate)
        .then((resp) => {
          setSelectedRecord(resp.data);
          setIsLoadingVersion(false);
        });
    } else {
      setSelectedRecord(null);
    }
  };

  return (
    <div id="vesselHistoric">
      {loadingHistoric
        ? (
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
          </div>
        )
        : (
          <>
            <div className="title-row">
              <div style={{ width: '265px' }} />
              <Typography component="span" variant="subtitle1">{t(schema.selectHistorical)}</Typography>
              <div style={{ width: '240px', marginRight: '1.563rem' }}>
                <Button
                  style={{ width: '240px', display: historicSortedByDate.length > 1 ? 'block' : 'none' }}
                  onClick={() => setShowCompareTable(!showCompareTable)}
                >
                  {
            showCompareTable ? 'Hide second version' : 'Compare to another version'
            }
                </Button>
              </div>
            </div>
            <div className="select-version-row">
              {showCompareTable
                ? (
                  <div className="select-version-row">
                    <div className="compare-versions-col">
                      <Button
                        className="button-move-versions"
                        onClick={moveToPreviousVersion}
                        disabled={selectedRecord?.updateDate === historicData[0]?.updateDate}
                      >
                        <IconSvg name={ICON.CHEVRON_LEFT} />
                      </Button>
                    </div>
                    <div className="compare-versions-col">
                      <div className="compare-versions-row">
                        <div className="label">Version</div>
                        {formatDate(selectedRecord?.updateDate, DATE_FORMAT, undefined, true)}
                      </div>
                      <div className="compare-versions-row">
                        <div className="label">Source</div>
                        {selectedRecord?.source || 'Unknown'}
                      </div>
                    </div>
                    <div className="compare-versions-col" style={{ marginRight: '0.938rem', marginLeft: '0.938rem' }}>
                      Compare to
                    </div>
                    <div className="compare-versions-col">
                      <div className="compare-versions-row">
                        <div className="label">Version</div>
                        {formatDate(selectedCompareRecord?.updateDate,
                          DATE_FORMAT, undefined, true)}
                      </div>
                      <div className="compare-versions-row">
                        <div className="label">Source</div>
                        {selectedCompareRecord?.source || 'Unknown'}
                      </div>
                    </div>
                    <div className="compare-versions-col">
                      <Button
                        className="button-move-versions"
                        onClick={moveToNextVersion}
                        disabled={
                    selectedCompareRecord?.updateDate
                    === historicData[historicData.length - 1]?.updateDate
                  }
                      >
                        <IconSvg name={ICON.CHEVRON_RIGHT} />
                      </Button>
                    </div>
                  </div>
                )
                : (
                  <div className="select-version-col">
                    <select
                      className="selectpicker"
                      onChange={onChangeVersion}
                      value={selectedRecord?.updateDate}
                      style={{ width: '150px' }}
                    >
                      <option>Select a version</option>
                      {historicSortedByDate?.map((item) => (
                        <option key={item?.updateDate} value={item?.updateDate}>
                          {formatDate(item?.updateDate, DATE_TIME_FORMAT)}
                        </option>
                      ))}
                    </select>
                    {
                selectedRecord !== null && (
                  <div className="select-version-row">
                    <div className="label">Version</div>
                    {formatDate(selectedRecord?.updateDate, DATE_FORMAT)}
                    <div className="label">Source</div>
                    {selectedRecord?.source || 'Unknown'}
                  </div>
                )
              }
                  </div>
                )}
            </div>
            {
        isLoadingVersion
          ? (
            <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
              <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
            </div>
          )
          : null
      }
            {
        selectedRecord !== null && (
          <div style={{ marginTop: '1.125rem' }}>
            <Tabs
              className="vessel"
              value={selectedTab}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
              centered
            >
              {
            tabs.map((tab) => (
              <Tab label={tab} />
            ))
          }
            </Tabs>
            {
          tabs.map((tab, indexTab) => (
            <TabPanel value={selectedTab} index={indexTab}>
              <div className="column">
                {isLoading
                  ? (
                    <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
                    </div>
                  )
                  : (template.map((attr) => {
                    if (attr.customProperties.tab === tab) {
                      return (
                        <div className="row">
                          <div className="label" style={{ width: '33%' }}>{attr?.displayName}</div>
                          <div
                            className={
                            showCompareTable
                            && selectedCompareRecord
                            && selectedRecord
                            && selectedRecord[attr.name] !== selectedCompareRecord[attr.name]
                            && 'highlighted-attribute'
                          }
                            style={{ width: '33%' }}
                          >
                            {
                            attr.customProperties.formData === null
                            && selectedRecord
                            && selectedRecord[attr?.name]
                          }
                            {
                            attr.customProperties.formData !== null
                            && selectedRecord
                            && (formData[attr.customProperties.formData]?.find(
                              (f) => f.code === selectedRecord[attr.customProperties.formData],
                            )?.description || selectedRecord[attr.name])
                          }
                          </div>
                          <div
                            className={
                            showCompareTable
                            && selectedRecord
                            && selectedCompareRecord
                            && selectedRecord[attr?.name] !== selectedCompareRecord[attr?.name]
                            && 'highlighted-attribute'
                          }
                            style={{ width: '33%' }}
                          >
                            {
                            showCompareTable
                            && attr.customProperties.formData !== null
                            && selectedCompareRecord
                            && (formData[attr.customProperties.formData]?.find(
                              (f) => f.code
                              === selectedCompareRecord[attr.customProperties.formData],
                            )?.description || selectedCompareRecord[attr.name])
                          }
                          </div>
                        </div>
                      );
                    }
                    return <></>;
                  }))}
              </div>

            </TabPanel>
          ))
        }
          </div>
        )
      }

          </>
        )}
    </div>
  );
};

VesselHistoric.defaultProps = {
  etalonId: PropTypes.string,
};

VesselHistoric.propTypes = {
  etalonId: PropTypes.string,
};

export default VesselHistoric;
