import axios from 'axios';

import { showErrorSessionNotification } from '@common/utilities/Notification';

export default {
  setupInterceptors: (store, kcInstance = {}) => {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        const configWithToken = () => ({ ...config, headers: { ...config.headers, Authorization: `Bearer ${kcInstance.token}` } });
        if (kcInstance.isTokenExpired()) {
          return kcInstance.updateToken().then(() => configWithToken()).catch(() => {
            console.error('Failed to refresh token');
            showErrorSessionNotification();
            return config;
          });
        }
        return configWithToken();
      },
      (error) => {
        Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response) => ({
        ...response, ok: true, json: () => response.data, text: () => response.data,
      }),
      (error) => {
        if (error.response && error.response.status === 401 && store) {
          console.error('Session ended', error);
          showErrorSessionNotification();
        }
        return error;
      },
    );
  },
};
