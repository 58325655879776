import i18n from 'i18next';
import Keycloak from 'keycloak-js';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';

import App from '@pages/App';

import ConfigLoaderProvider from '@services/app/ConfigLoaderProvider';
import { KeyCloakContextProvider } from '@services/authentication/useKeyCloakContext';
import { UserContextProvider } from '@services/userPreferences/UserPreferencesContext';

import en from './lang/en.json';
import es from './lang/es.json';
import httpService from './services/interceptors';
// import * as serviceWorker from './serviceWorker';
import store from './store';

const kcInstance = Keycloak('/keycloak.json');
httpService.setupInterceptors(store, kcInstance);

const locale = navigator.language;
let translation = en;
let lng = 'en';
if (locale === 'en') {
  translation = en;
  lng = 'en';
} else if (locale === 'es') {
  translation = es;
  lng = 'es';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation,
      },
    },
    lng, // if you're using a language detector, do not define the lng option
    fallbackLng: lng,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.render(
  <Provider store={store}>
    <KeyCloakContextProvider kcInstance={kcInstance}>
      <UserContextProvider>
        <ConfigLoaderProvider>
          <App />
        </ConfigLoaderProvider>
      </UserContextProvider>
    </KeyCloakContextProvider>
  </Provider>,
  document.getElementById('root'),
);
