import PropTypes from 'prop-types';
import React, {
  useRef, useState, useCallback, useMemo, useEffect,
} from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import getVersionData from '@pages/IsrViewer/commons/utils';

import DataTable from '@components/DataTable';
import FieldGroup from '@components/Form';

import tSchema from '@lang/schema';

import { getReportTabs } from '@services/isr/reports/api';

import formatChecked from '../utils';

import {
  getGearCharactsTableConfig,
  getAttachCharactsTableConfig,
} from './utils';

import { requestWithMessage } from '@common/utilities/Notification';
import { selectUtils } from '@common/utilities/selectUtils';
import { formatSelectOptions } from '@common/utils';

const IsrViewerDetailsTabGear = ({
  selectedUuid, selectedVersion, selectedRecipient, isHiddenISRTab,
}) => {
  const attachDataTableRef = useRef();
  const gearDataTableRef = useRef();
  const [attachLoading] = useState(false);
  const [gearLoading] = useState(false);
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const [selectedGear, setSelectedGear] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const gearCharactsTableConfig = useMemo(() => getGearCharactsTableConfig(t), [t]);
  const attachCharactsTableConfig = useMemo(() => getAttachCharactsTableConfig(t), [t]);

  const getAttachOptions = useCallback(() => {
    if (tabData && tabData.gears) {
      const gear = tabData.gears.find((gearItem) => gearItem.code === selectedGear?.value);
      if (gear && gear.gearAttachments) {
        return selectUtils.mapOptions(
          {
            options: formatSelectOptions(gear.gearAttachments, 'code', 'name'),
            labelPropName: 'description',
            idName: 'code',
          },
        );
      }
    }
    return [];
  }, [tabData.gears, selectedGear]);

  const getAttachCharacteristics = useCallback(() => {
    const gear = tabData?.gears?.find((gearItem) => gearItem.code === selectedGear?.value);
    if (gear) {
      return selectedAttachment
    && gear.gearAttachments?.find(
      (gearAttach) => gearAttach.code === selectedAttachment.value,
    )?.atachmentsCharacteristics;
    }
    return [];
  }, [selectedGear, selectedAttachment, tabData.gears]);

  const {
    gearCharacteristics,
  } = useMemo(() => ({
    gearCharacteristics:
    selectedGear && tabData.gears
      ?.find((gear) => gear.code === selectedGear.value)?.gearCharacteristics,
  }), [selectedGear]);

  const {
    gearOptions,
  } = useMemo(() => ({
    gearOptions:
    selectUtils.mapOptions({ options: formatSelectOptions(tabData.gears, 'code', 'name'), labelPropName: 'description', idName: 'code' }),

  }), [tabData.gears]);

  const { attachOptions } = useMemo(() => ({
    attachOptions: getAttachOptions(),
  }), [selectedGear, tabData.gears]);

  const {
    attachCharacteristics,
  } = useMemo(() => ({
    attachCharacteristics: getAttachCharacteristics(),
  }), [selectedAttachment]);

  const triggerDataFetch = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['gearTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.gearTab);
        const versionData = getVersionData(data.gearTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (selectedUuid && selectedRecipient && !isHiddenISRTab) { triggerDataFetch(); }
  }, [
    selectedUuid, selectedRecipient, isHiddenISRTab,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  useEffect(() => {
    if (attachOptions) {
      setSelectedAttachment(attachOptions[0]);
    }
  }, [attachOptions]);

  useEffect(() => {
    if (gearOptions) {
      setSelectedGear(gearOptions[0]);
    }
  }, [gearOptions]);

  const selectGearHandler = (option) => {
    setSelectedGear(option);
  };

  const selectAttachHandler = (option) => {
    setSelectedAttachment(option);
  };

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      )
        : (
          <div>
            <Col>
              <Row>
                <Col>
                  <div className="header-columns">
                    <Form.Label>
                      {t(tSchema.isrViewer.gearChecked)}
                      :
                    </Form.Label>
                    <p>
                      {formatChecked(tabData.gearChecked, t)}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="header-columns">
                    <Form.Label>
                      {t(tSchema.isrViewer.ecGaugeChecked)}
                      :
                    </Form.Label>
                    <p>
                      {formatChecked(tabData.ecGaugeChecked, t)}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="header-columns">
                    <Form.Label>
                      {t(tSchema.isrViewer.details)}
                      :
                    </Form.Label>
                    <p>{tabData.ecGaugeDetails || ''}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="header-columns">
                    <Form.Label>
                      {t(tSchema.isrViewer.gearMarkingChecked)}
                      :
                    </Form.Label>
                    <p>
                      {formatChecked(tabData.gearMarkingChecked, t)}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="header-columns">
                    <Form.Label>
                      {t(tSchema.isrViewer.details)}
                      :
                    </Form.Label>
                    <p>{tabData.gearMarkingDetails || ''}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Row
              style={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Col md={3}>
                <FieldGroup label={t(tSchema.isrViewer.gear)}>
                  <ReactSelect
                    options={gearOptions}
                    name="gear"
                    value={selectedGear}
                    onChange={selectGearHandler}
                    isDisabled={false}
                  />
                </FieldGroup>
              </Col>
              <Col>
                <FieldGroup label={t(tSchema.isrViewer.gearCharacteristics)}>
                  <Container fluid className="isrGearCharacts">
                    <DataTable
                      rowData={gearCharacteristics}
                      idColumn="validityDate"
                      tableId="isrGearCharactsTable"
                      ref={gearDataTableRef}
                      tableConfig={gearCharactsTableConfig}
                      loading={gearLoading}
                      showPagination={false}
                    />
                  </Container>
                </FieldGroup>
              </Col>
            </Row>
            <Row
              style={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Col md={3}>
                <FieldGroup label={t(tSchema.isrViewer.gearAttachments)}>
                  <ReactSelect
                    key={`${selectedGear?.value}-${selectedAttachment}`}
                    options={attachOptions}
                    name="attach"
                    value={selectedAttachment}
                    onChange={selectAttachHandler}
                    isDisabled={false}
                    defaultValue={attachOptions[0]}
                  />
                </FieldGroup>
              </Col>
              <Col className="w-70">
                <FieldGroup label={t(tSchema.isrViewer.attachmentCharacteristics)}>
                  <Container fluid className="isrAttachCharacts">
                    <DataTable
                      rowData={attachCharacteristics}
                      idColumn="validityDate"
                      tableId="isrAttachCharactsTable"
                      ref={attachDataTableRef}
                      tableConfig={attachCharactsTableConfig}
                      loading={attachLoading}
                      showPagination={false}
                    />
                  </Container>
                </FieldGroup>
              </Col>
            </Row>
            <Row
              style={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.observationsComments)}
                    :
                  </Form.Label>
                  <p>{tabData.observationAndComments || ''}</p>
                </div>
              </Col>
            </Row>
          </div>
        )}
    </>
  );
};

IsrViewerDetailsTabGear.propTypes = {
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
  isHiddenISRTab: PropTypes.bool.isRequired,
};

export default IsrViewerDetailsTabGear;
