import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import actions from './img/actions.JPG';
import editScenarioModal from './img/editScenarioModal.JPG';
import editScenarioModalISR from './img/editScenarioModalISR.png';

import { DOMAIN } from '@common/utils';

const SectionScenariosEdit = () => {
  const { userPreferences } = useUserPreferences();
  const domain = userPreferences && userPreferences.domain;
  return (
    <div className="SectionScenariosEdit">
      <p>
        Once the form is filled and the user clicks the &quot;Save&quot; button,
        if there are any errors in the form, they are highlighted in red.
      </p>
      <p>
        <img src={actions} alt="actions" />
      </p>
      <p>
        This button opens a modal with the information about the scenario in editable mode.
      </p>
      <p>
        {domain === DOMAIN.FA
          ? <img src={editScenarioModal} alt="Edit Scenario Modal" />
          : <img src={editScenarioModalISR} alt="ISR Edit Scenario Modal" />}
      </p>
      <p>
        Once the user has finished making the desired modifications,
        the changes can be saved clicking on the &quot;Save&quot; button at the
        bottom of the modal window. Or discarded, clicking the &quot;Cancel&quot; button.
      </p>
    </div>
  );
};

export default SectionScenariosEdit;
