import { Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';

import {
  checkMandatoryFields,
  validateDrools,
  cleanVesselForm,
  addComplexRow,
} from '../../services/vessel_record/actions/vessel';
import { filterEmptyComplexAttr } from '../../services/vessel_record/api';
import FormFooter from '../Footer/FormFooter';

import './style.scss';
import TabCompanyInformation from './components/tabs/TabCompanyInformation';
import TabVesselIdentification from './components/tabs/TabVesselIdentification';
import TabVesselTechnicalDetails from './components/tabs/TabVesselTechnicalDetails';

import { showErrorNotification } from '@common/utilities/Notification';

function exclamationPicker(level) {
  if (level === 'error') {
    return <ExclamationTriangleFill color="#ff6161" />;
  }
  if (level === 'warning') {
    return <ExclamationTriangleFill color="orange" />;
  }

  return <ExclamationTriangleFill hidden />;
}

const TabPanel = ({
  children, value, index, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={index}
    {...other}
  >
    {value === index && (
    <Box p={3}>
      <Typography component="span">{children}</Typography>
    </Box>
    )}
  </div>
);
TabPanel.defaultProps = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};
TabPanel.propTypes = {
  children: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  other: PropTypes.instanceOf(Object),
};

const VesselEditor = ({
  visualization,
  onSavedEdition,
  onClose,
}) => {
  const dispatch = useDispatch();
  const vesselEntity = useSelector((state) => state.vessel.entity);
  const isLoadingDrools = useSelector((state) => state.vessel.isLoadingDrools);
  const isLoadingVessel = useSelector((state) => state.vessel.isLoadingVessel);
  const tabs = useSelector((state) => state.vessel.tabs);
  const formatedVessel = useSelector((state) => state.vessel.vessel);
  const listDrools = useSelector((state) => state.vessel.listDrools);
  const template = useSelector((state) => state.vessel.template);
  const modified = useSelector((state) => state.vessel.modified);
  const previousVersion = useSelector((state) => state.vessel.previousVersion);
  const [selectedTab, setSelectedTab] = useState(0);
  const [deletedThirdPartys, setDeletedThirdPartys] = useState([]);
  const [savingData, setSavingData] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getDeletedThirdPartys = () => {
    const deletedThirdPartysArray = [];
    if (previousVersion.thirdPartyVessel?.length > formatedVessel.thirdPartyVessel?.length) {
      previousVersion.thirdPartyVessel.map((thirdParty) => {
        if (formatedVessel.thirdPartyVessel.find(
          (th) => (th.identifier === thirdParty.identifier && th.code === thirdParty.code),
        ) === undefined) {
          deletedThirdPartysArray.push(thirdParty);
        }
        return thirdParty;
      });
    }
    return deletedThirdPartysArray;
  };

  useEffect(() => {
    if (formatedVessel !== {} && previousVersion !== null) {
      setDeletedThirdPartys(getDeletedThirdPartys());
    }
  }, [formatedVessel, previousVersion]);

  useEffect(() => {
    if (visualization !== 'CREATE' && Object.keys(formatedVessel).length > 2) {
      dispatch(validateDrools(filterEmptyComplexAttr(formatedVessel)));
    }
  }, [visualization]);

  const errorsId = (id) => listDrools?.filter((x) => x.referencedItem === id);

  const errorLevel = () => {
    if (modified) {
      return null;
    }
    if (listDrools.some((drool) => drool.resultState === 'ERROR' || drool.resultState === 'EXCEPTION')) {
      const exceptions = listDrools.filter((drool) => drool.resultState === 'EXCEPTION');
      exceptions.length && showErrorNotification(exceptions.map(
        (error) => (
          <p>
            Result State:
            {' '}
            {error.resultState}
            <br />
            Rule Name:
            {' '}
            {error.ruleName}
          </p>
        ),
      ));
      return 'ERROR';
    }
    if (listDrools.some((drool) => drool.resultState === 'WARNING')) {
      return 'WARNING';
    }
    return null;
  };

  const attributeExists = (attrName) => formatedVessel[attrName]
    && formatedVessel[attrName].length !== 0;

  const switchTabs = (tab) => {
    switch (tab.tab) {
      case 'VESSEL IDENTIFICATION':
        return (
          <TabVesselIdentification
            attributes={tab.attributes}
            addComplexRow={addComplexRow}
            visualization={visualization}
            deletedThirdPartys={deletedThirdPartys}
            template={template}
            errorsId={errorsId}
            attributeExists={attributeExists}
          />
        );
      case 'VESSEL TECHNICAL DETAILS':
        return (
          <TabVesselTechnicalDetails
            attributes={tab.attributes}
            addComplexRow={addComplexRow}
            visualization={visualization}
            addGear={formatedVessel.subsidiaryFishingGears
              ? formatedVessel.subsidiaryFishingGears.length < 5 : true}
            errorsId={errorsId}
            template={template}
            attributeExists={attributeExists}
          />
        );
      case 'COMPANY INFORMATION':
        return (
          <TabCompanyInformation
            attributes={tab.attributes}
            visualization={visualization}
          />
        );
      default:
        return null;
    }
  };

  if (visualization !== 'CREATE') {
    return (isLoadingDrools || savingData || isLoadingVessel)
      ? (
        <div>
          <div className="row mt-3" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="spinner-border" id="loading-data" style={{ width: '40px', height: '40px' }} />
          </div>
          <div className="row mt-3" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="label" style={{ fontSize: '1.188rem' }}>Loading Data</div>
          </div>
        </div>
      )
      : (
        <div id="vesselEditor">
          <Tabs
            className="vessel"
            value={selectedTab}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs && tabs?.map((t) => (
              <Tab
                key={t.name}
                label={(
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  >
                    <span>{t.name}</span>
                    {exclamationPicker(t.level)}
                  </div>
                    )}
              />
            ))}
          </Tabs>
          {selectedTab != null && vesselEntity.map((tab, indexTab) => (
            <TabPanel value={selectedTab} index={indexTab}>
              {switchTabs(tab)}
            </TabPanel>
          ))}
          {tabs.length > 0 && selectedTab != null
              && (
              <FormFooter
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                checkMandatoryFields={visualization !== 'VIEW' && checkMandatoryFields}
                cleanVesselForm={cleanVesselForm}
                visualization={visualization}
                formatedVessel={formatedVessel}
                errorLevel={errorLevel()}
                validateDrools={validateDrools}
                listDrools={listDrools}
                onCompleted={onSavedEdition}
                template={template}
                setSavingData={setSavingData}
                onClose={onClose}
              />
              )}
        </div>
      );
  }

  return (savingData)
    ? (
      <div>
        <div className="row mt-3" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '40px', height: '40px' }} />
        </div>
        <div className="row mt-3" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="label" style={{ fontSize: '1.188rem' }}>Loading Data</div>
        </div>
      </div>
    )
    : (
      <div id="vesselEditor">
        <Tabs
          className="vessel"
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs && tabs?.map((t) => (
            <Tab label={(
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              >
                <span>{t.name}</span>
                {exclamationPicker(t.level)}
              </div>
                    )}
            />
          ))}
        </Tabs>
        {selectedTab != null && vesselEntity.map((tab, indexTab) => (
          <TabPanel value={selectedTab} index={indexTab}>
            {switchTabs(tab)}
          </TabPanel>
        ))}
        {tabs.length > 0 && selectedTab != null
              && (
              <FormFooter
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                checkMandatoryFields={visualization !== 'VIEW' && checkMandatoryFields}
                cleanVesselForm={cleanVesselForm}
                visualization={visualization}
                formatedVessel={formatedVessel}
                errorLevel={errorLevel()}
                validateDrools={validateDrools}
                listDrools={listDrools}
                onCompleted={onSavedEdition}
                template={template}
                setSavingData={setSavingData}
                onClose={onClose}
              />
              )}
      </div>
    );
};

VesselEditor.defaultProps = {
  onClose: null,

};

VesselEditor.propTypes = {
  visualization: PropTypes.string.isRequired,
  onSavedEdition: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default VesselEditor;
