import { isEmpty, isNil } from 'ramda';

import tSchema from '@lang/schema';

export const MAX_MB_SIZE_FILES = 10;

export const isNilOrEmpty = (value) => isNil(value) || isEmpty(value);

export const sortCountries = (a, b) => {
  if (a.groupBy > b.groupBy) return 1;
  if (a.groupBy < b.groupBy) return -1;
  if (a.description > b.description) return 1;
  if (a.description < b.description) return -1;

  return 0;
};

export const clearEventTargetValue = (event) => {
  // eslint-disable-next-line no-param-reassign
  event.target.value = null;
};

export const formatSelectOption = (option, firstProp, secondProp) => (
  { ...option, description: `${option[firstProp]} - ${option[secondProp]}` }
);

export const formatSelectOptions = (options, firstProp = 'id', secondProp = 'description') => (options || []).map((
  option,
) => formatSelectOption(option, firstProp, secondProp));

export const DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const getNextDirection = (direction) => {
  switch (direction) {
    case DIRECTION.ASC:
      return DIRECTION.DESC;
    case DIRECTION.DESC:
      return undefined;
    default:
      return DIRECTION.ASC;
  }
};
export const formatHeaderSort = (header, orderBy, defaultSort = {}) => {
  const isSame = orderBy && header && header.code === orderBy.field;
  const isDefault = header.code === defaultSort.field;
  const newDirection = isSame ? getNextDirection(orderBy.order) : DIRECTION.ASC;

  return {
    field: newDirection ? header.code : defaultSort.field,
    order: (isDefault && !newDirection) ? DIRECTION.ASC : newDirection || defaultSort.order,
  };
};

export const fromKBToMB = (kb) => (kb / (1024 * 1024)).toFixed(2);

export const APPROVER_STATUS = {
  CONFIRMED: 'CONFIRMED',
  PENDING_CONFIRMATION: 'PENDING CONFIRMATION',
};
export const getApproverStatus = (status) => APPROVER_STATUS[status] || 'Status unknown';

const RS_HEIGHT = '2rem';
const RS_HEIGHT_DEFAULT = 'calc(1.5em + .75rem + 0.188rem)';
export const REACT_SELECT = {
  customStyles: {
    groupHeading: (provided) => ({ ...provided, margin: '0px', padding: '0px' }),
    group: (provided) => ({ ...provided, margin: '0px', padding: '0px' }),
    control: (provided) => ({
      ...provided,
      minHeight: RS_HEIGHT,
      height: RS_HEIGHT,
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: RS_HEIGHT,
      // padding: '0 0.375rem',
    }),

    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      height: '1.2rem',
      marginTop: '0.4rem',
      marginBottom: '0.4rem',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // width: '1.8rem',
      height: '1.8rem',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0.25rem',
      svg: {
        width: '1.3rem',
      },
      // width: RS_HEIGHT,
    }),

  },

  customStylesDefault: (isValid, isInvalid) => ({
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0.25rem',
      svg: {
        width: '1.3rem',
      },
    }),
    groupHeading: (provided) => ({ ...provided, margin: '0px', padding: '0px' }),
    group: (provided) => ({ ...provided, margin: '0px', padding: '0px' }),
    control: (provided, state) => ({
      ...provided,
      minHeight: RS_HEIGHT_DEFAULT,
      ...(state.isDisabled && {
        backgroundColor: '#e9ecef',
        opacity: 1,
        color: '#495057',
        border: '1px solid #ced4da',
      }),
      borderColor: (isInvalid && '#dc3545') || (isValid && '#28a745') || '#ced4da',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      ...(state.isDisabled && {
        color: '#495057',
      }),
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...(state.isDisabled && {
        color: '#495057',
      }),
    }),

    placeholder: (provided, state) => ({
      ...provided,
      ...(state.isDisabled && {
        color: '#495057',
      }),
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: '0.4rem',
      marginBottom: '0.4rem',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0.1rem',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0.25rem',
      svg: {
        width: '1.5rem',
      },
    }),

  }),
};

export const DOMAIN = {
  FA: 'FA',
  VESSEL: 'VESSEL',
  ISR: 'ISR',
};

const schema = tSchema.isrViewer;

export const fromDDToDMD = (deg, isLatitude, t) => {
  const directionLat = (deg >= 0) ? t(schema.north) : t(schema.south);
  const directionLon = (deg >= 0) ? t(schema.east) : t(schema.west);
  const d = Math.floor(deg);
  const minfloat = (deg - d) * 60;
  return (`${d}º ${Math.round(minfloat * 100) / 100}' ${isLatitude ? directionLat : directionLon}`);
};

export const convertSizeStringToBytes = (sizeString) => {
  const sizeParts = sizeString.split(' ');
  const size = parseFloat(sizeParts[0]);
  const unit = sizeParts[1].toUpperCase();

  let multiplier = 1;
  if (unit === 'KB') {
    multiplier = 1024;
  } else if (unit === 'MB') {
    multiplier = 1024 * 1024;
  } else if (unit === 'GB') {
    multiplier = 1024 * 1024 * 1024;
  }

  return size * multiplier;
};
