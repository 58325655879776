import { prop } from 'ramda';
import { Badge } from 'react-bootstrap';

import tSchema from '@lang/schema';

import { formatDate } from '@common/dateUtils';
import { formatSelectOption } from '@common/utils';

const statusBadge = (status) => (status ? <Badge className="status" variant={status === 1 ? 'success' : 'danger'} pill /> : '');

const schema = tSchema.dmaAdmin.queryReportMGMT.report;

export const getTableConfig = (t) => [
  { text: t(schema.name), code: 'name', sortable: true },
  {
    text: t(schema.sender),
    code: 'originalSender',
    sortable: true,
    render: (row) => row.originalSender,
  },
  { text: t(schema.receiver), code: 'receiver', sortable: true },
  {
    text: t(schema.startDate),
    code: 'startingDate',
    sortable: true,
    render: (row) => (formatDate(row.schedulingOptions?.startDate, undefined, undefined, true)),
  },
  {
    text: t(schema.endDate),
    code: 'endDate',
    sortable: true,
    render: (row) => (formatDate(row.schedulingOptions?.endDate, undefined, undefined, true)),
  },

  {
    text: t(schema.type),
    code: 'recurrence',
    sortable: true,
    render: (row) => (row.schedulingOptions?.recurrence?.description || row.type?.description || '-'),
  },
  {
    text: t(schema.active),
    code: 'status',
    sortable: true,
    render: (row) => statusBadge(row.schedulingOptions?.status?.id),
  },
];

export const configureFilters = (options, defaultValues, t) => [
  {
    code: 'name',
    label: t(schema.name),
    type: 'text',
  },
  {
    code: 'receiver',
    label: t(schema.receiver),
    type: 'select',
    idProp: 'code',
    options: prop('fluxParties')(options).map((option) => formatSelectOption(option, 'code', 'name')),
    defaultValue: prop('fluxParties')(defaultValues),
  },
  {
    code: 'subscriptionType',
    label: t(schema.type),
    type: 'select',
    options: prop('type')(options),
    defaultValue: prop('type')(defaultValues),
  },
];

export const searchOptions = [
  { label: 'contains', value: '=-', type: 'text' },
  { label: 'equal', value: '==' },
  { label: 'not contains', value: '!-', type: 'text' },
  { label: 'begins with', value: '*=', type: 'text' },
  { label: 'ends with', value: '=*', type: 'text' },
  {
    label: 'is null', value: '=null', type: 'text', single: true,
  },
  { label: 'before', value: '<', type: 'date' },
  { label: 'after', value: '>', type: 'date' },
  { label: 'not equals', value: '!-', type: 'complex-select' },
  { label: 'contains', value: '=-', type: 'complex-select' },
];

export const valueOptions = [
  { option: 'fluxParties', entity: 'businessrules' },
  { option: 'vesselGroups', entity: 'logbook' },
  { option: 'vesselFlags', entity: 'logbook' },
  { option: 'trips', entity: 'logbook' },
  { option: 'type' },
  { option: 'status' },
  { option: 'vesselStatus' },
  { option: 'queryType' },
  { option: 'vesselIdType' },
  { option: 'recurrence' },
];
