import * as Yup from 'yup';

import { DOMAIN } from '@common/utils';

export const schemaValidation = (domain) => {
  const isISRDomain = domain === DOMAIN.ISR;
  const isFADomain = domain === DOMAIN.FA;
  return Yup.object().shape(
    {
      name: Yup.string().required('Name can\'t be an empty value'),
      isActive: Yup.string().required('Status is required'),
      ...(isFADomain && {
        vesselGroups: Yup.array().min(1, 'At least 1 vessel group must be selected').of(Yup.object().shape(
          {
            label: Yup.string(),
            value: Yup.string(),
          },
        )).required(),
      }),
      startDataPeriod: Yup.date().nullable().required('Start Date is a required field'),
      endDataPeriod: Yup.date().nullable().min(
        Yup.ref('startDataPeriod'),
        "End date can't be before start date",
      ),
      ...(isFADomain && { vesselGroup: Yup.string().nullable().required('A data visualization scope vessel group is required') }),
      ...(isISRDomain && {
        programmes: Yup.array().min(1, 'At least a programme must be selected').of(Yup.object().shape(
          {
            label: Yup.string(),
            value: Yup.string(),
          },
        )).required(),
      }),
    },
  );
};

export default { schemaValidation };
