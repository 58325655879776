import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import {
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { M1, M3 } from '@pages/IsrViewer/commons/constants';
import getVersionData from '@pages/IsrViewer/commons/utils';

import tSchema from '@lang/schema';

import {
  getReportTabs,
} from '@services/isr/reports/api';

import formatChecked from '../tabs/utils';

import { formatDateTime } from '@common/dateUtils';
import { requestWithMessage } from '@common/utilities/Notification';

const IsrViewerDetailsHeader = ({
  selectedUuid, module, selectedVersion, selectedRecipient,
}) => {
  const { t } = useTranslation();
  const [tabData, setTabData] = useState({});
  const [tabDataArray, setTabDataArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchReportTab = useCallback(async () => {
    setLoading(true);
    await requestWithMessage({
      request: () => getReportTabs(
        selectedUuid,
        selectedRecipient,
        ['generalTab'],
      ),
      errorMessage: t(tSchema.notifications.common.fetchData),
      onSuccess: async (response) => {
        const data = await response.json();
        setTabDataArray(data.generalTab);
        const versionData = getVersionData(data.generalTab, selectedVersion);
        if (versionData) {
          setTabData(versionData);
        }
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    fetchReportTab();
  }, [
    selectedUuid,
    selectedRecipient,
  ]);

  useEffect(() => {
    const versionData = getVersionData(tabDataArray, selectedVersion);
    if (versionData) {
      setTabData(versionData);
    }
  }, [
    selectedVersion, tabDataArray,
  ]);

  return (
    <>
      {loading ? (
        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '60px' }}>
          <div className="spinner-border" id="loading-data" style={{ width: '20px', height: '20px' }} />
        </div>
      )
        : (
          <Container
            fluid
            style={{
              paddingBottom: '0.625rem',
            }}
            id="isrViewer-header"
          >
            <Row
              style={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Col md={4}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.reportReference)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.reportReference ? tabData.reportReference : ''}</p>
                </div>
              </Col>
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.context)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.context ? tabData.context : ''}</p>
                </div>
              </Col>
              <Col md={5}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.controlParty)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.controlParty ? tabData.controlParty : ''}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.date)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.date ? `${formatDateTime(tabData.date.split('+')[0])}, UTC` : ''}</p>
                </div>
              </Col>
              <Col>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.programme)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.programme ? tabData.programme : ''}</p>
                </div>
              </Col>
              <Col md={5}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.controlAuthority)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.controlAuthority ? tabData.controlAuthority : ''}</p>
                </div>
              </Col>
            </Row>
            <Row>
              { module === M1 && (
              <Col md={4}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.arrivalOnBoard)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.arrivalOnBoard ? `${formatDateTime(tabData.arrivalOnBoard.split('+')[0])}, UTC` : ''}</p>
                </div>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.departure)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.departure ? `${formatDateTime(tabData.departure.split('+')[0])}, UTC` : ''}</p>
                </div>
              </Col>
              )}

              <Col md={4}>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.inspectionStart)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.inspectionStart ? `${formatDateTime(tabData.inspectionStart.split('+')[0])}, UTC` : ''}</p>
                </div>
                <div className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.inspectionEnd)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.inspectionEnd ? `${formatDateTime(tabData.inspectionEnd.split('+')[0])}, UTC` : ''}</p>
                </div>
              </Col>
              { module === M3 && (
              <>
                <Col md={3} className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.portOfInspection)}
                    :
                  </Form.Label>
                  <p>{tabData && tabData.portOfInspection ? tabData.portOfInspection : ''}</p>
                </Col>
                <Col md={3} className="header-columns">
                  <Form.Label>
                    {t(tSchema.isrViewer.designatedPort)}
                    :
                  </Form.Label>
                  <p>{tabData && (formatChecked(tabData.designatedPort, t))}</p>
                </Col>
              </>
              )}
            </Row>
          </Container>
        )}
    </>
  );
};

IsrViewerDetailsHeader.propTypes = {
  module: PropTypes.instanceOf(Object).isRequired,
  selectedUuid: PropTypes.instanceOf(Object).isRequired,
  selectedVersion: PropTypes.instanceOf(Object).isRequired,
  selectedRecipient: PropTypes.instanceOf(Object).isRequired,
};

export default IsrViewerDetailsHeader;
