/* eslint-disable import/order */
/* eslint-disable no-shadow */
import './style.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSend,
  validateDrools,
  cleanVesselForm,
} from '../../services/vessel_record/actions/vessel';
import {
  postVessel, filterEmptyComplexAttr, editCurrentVersion, createNewVersion,
} from '../../services/vessel_record/api';

import hasCallNewVersion, { REPORTED_EVENT } from './utils';
import { formatDate } from '@common/dateUtils';
import { createExcelFromUniqueVessel } from '@common/exportFileFunctions';
import { hasPermission, Permissions } from '@common/keycloakFunctions';
import IconSvg from '@components/IconSvg';
import { ICON } from '@components/IconSvg/IconSvg';
import tSchema from '@lang/schema';

const schema = tSchema.vesselMGMT.createEditView;

const FormFooter = ({
  isValidForm = true,
  visualization,
  formatedVessel,
  errorLevel,
  listDrools,
  onAccept,
  onCompleted,
  onCancel,
  template,
  setSavingData,
  onClose,
}) => {
  const { t } = useTranslation();

  const send = useSelector((state) => state.vessel.send);
  const vesselEntity = useSelector((state) => state.vessel.entity);
  const isLoadingDrools = useSelector((state) => state.vessel.isLoadingDrools);
  const savingType = useSelector((state) => state.vessel.savingType);
  const validated = useSelector((state) => state.vessel.validated);
  const permissions = useSelector((state) => state.authentication.permissions);
  const oldVessel = useSelector((state) => state.vessel.oldVessel);
  const username = useSelector((state) => state.authentication.userName);

  const dispatch = useDispatch();

  const SOURCE_NAME = 'EFCA';

  function addSourceToVessel() {
    return {
      ...formatedVessel,
      source: `${SOURCE_NAME} (${username})`,
    };
  }

  // eslint-disable-next-line no-unused-vars
  const changeArrayFinish = useCallback(async (visualization, savingType) => {
    const vessel = addSourceToVessel();

    const vesselFormated = filterEmptyComplexAttr(vessel);
    const oldVesselFormated = filterEmptyComplexAttr(
      {
        ...oldVessel,
        source: `${SOURCE_NAME} (${username})`,
      },
    );

    const isNewVersion = hasCallNewVersion(vesselFormated, oldVesselFormated);

    switch (visualization) {
      case 'EDIT':
        setSavingData(true);
        isNewVersion
          ? await createNewVersion({
            vessel: {
              ...vessel,
              reportedEvent: REPORTED_EVENT.MOD,
            },
            confirm: savingType,
          })
            .then(() => {
              if (onCompleted) {
                onCompleted();
              }
              if (onClose) {
                onClose();
              }
              dispatch(cleanVesselForm());
              setSavingData(false);
            })
          : await editCurrentVersion({ vessel, confirm: savingType })
            .then(() => {
              if (onCompleted) {
                onCompleted();
              }
              if (onClose) {
                onClose();
              }
              dispatch(cleanVesselForm());
              setSavingData(false);
            });
        break;

      case 'CREATE':
        setSavingData(true);
        await postVessel({
          vessel: {
            ...vesselFormated,
            reportedEvent: REPORTED_EVENT.NEW,
          },
          confirm: savingType,
        })
          .then(() => {
            if (onCompleted) {
              onCompleted();
            }
            if (onClose) {
              onClose();
            }
            dispatch(cleanVesselForm());
            setSavingData(false);
          });
        break;

      default:
        break;
    }
  }, [oldVessel, formatedVessel]);

  useEffect(() => {
    if (send && validated && !listDrools.some((drool) => drool.resultState === 'ERROR' || drool.resultState === 'WARNING' || drool.resultState === 'EXCEPTION')) {
      changeArrayFinish(visualization, savingType);
    }
  }, [listDrools, validated]);

  const validate = async (savingType) => {
    dispatch(setSend(true, savingType));
    dispatch(validateDrools(filterEmptyComplexAttr(formatedVessel)));
  };
  const checkValidityDate = useCallback(() => {
    let hasValidateDateError = false;

    if (vesselEntity[0].tab === 'VESSEL IDENTIFICATION') {
      hasValidateDateError = vesselEntity[0].attributes.find(
        (attr) => attr.name === 'validityDate',
      ).isValid;
    }
    return hasValidateDateError;
  }, [vesselEntity]);

  const acceptHandler = useCallback(async () => {
    if (checkValidityDate()) {
      errorLevel !== 'WARNING' ? await validate(false) : await changeArrayFinish(visualization, false);

      if (onAccept) {
        onAccept();
      }
    }
  }, [errorLevel, visualization, formatedVessel, changeArrayFinish, validate, checkValidityDate]);

  const confirmHandler = useCallback(async () => {
    if (checkValidityDate()) {
      errorLevel !== 'WARNING' ? await validate(true) : await changeArrayFinish(visualization, true);

      if (onAccept) {
        onAccept();
      }
    }
  }, [errorLevel, visualization, formatedVessel, changeArrayFinish, validate, checkValidityDate]);

  const cancelHandler = useCallback(() => {
    dispatch(cleanVesselForm());
    if (onCancel) {
      onCancel();
    }
    if (onClose) { onClose(); }
  }, [onCancel]);

  const canApprove = useMemo(() => hasPermission(permissions, Permissions.APPROVE_MODIFICATIONS),
    [permissions]);

  return (
    <div>
      {visualization !== 'VIEW' && isValidForm === false && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <b style={{ color: 'red' }}>
            {t(schema.validation.invalidForm)}
          </b>
        </div>
      )}
      {visualization !== 'VIEW' && errorLevel === 'ERROR' && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <b style={{ color: '#ad2828' }}>
            {t(schema.validation.errors)}
          </b>
        </div>
      )}
      {visualization !== 'VIEW' && errorLevel === 'WARNING' && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <b style={{ color: 'orange' }}>
            {t(schema.validation.warnings)}
          </b>
        </div>
      )}
      <div className="bottomFormButton modal-action-buttons">
        <div
          style={{
            marginTop: '1.188rem',
          }}
          className="formerValue"
        >
          {
            visualization !== 'CREATE' && t(schema.lastEdited,
              {
                lastModifier: formatedVessel.source || 'Unknown',
                lastUpdate: formatDate(formatedVessel.updateDate, undefined, true),
              })
          }
        </div>
        <div className="bottomFormButton">
          {visualization !== 'VIEW' && errorLevel !== 'ERROR' && canApprove ? (
            <Button
              style={{ marginRight: '0.375rem' }}
              onClick={confirmHandler}
              disabled={isLoadingDrools}
            >
              {errorLevel !== 'WARNING' ? t(schema.buttons.saveAndConfirm) : t(schema.buttons.saveAndConfirmWithWarnings)}
            </Button>
          ) : null}
          {visualization !== 'VIEW' && errorLevel !== 'ERROR' && (
            <Button
              onClick={acceptHandler}
              disabled={isLoadingDrools}
            >
              {errorLevel !== 'WARNING' ? t(schema.buttons.save) : t(schema.buttons.saveWithWarnings)}
            </Button>
          ) }
          <Button
            style={{ marginLeft: '0.375rem' }}
            onClick={cancelHandler}
          >
            {visualization !== 'VIEW' ? t(schema.buttons.cancel) : t(tSchema.common.close)}
          </Button>
          {visualization !== 'CREATE' && (
            <Button
              style={{ marginLeft: '0.375rem' }}
              id="export-to-excel"
              className="symbol-download"
              onClick={() => {
                createExcelFromUniqueVessel(
                  formatedVessel,
                  template.registerEntities[0]?.simpleAttributes,
                  `${SOURCE_NAME} (${username})`,
                );
              }}
            >
              <IconSvg name={ICON.DOWNLOAD} />
              Export to Excel
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
FormFooter.defaultProps = {
  isValidForm: PropTypes.bool,
  visualization: PropTypes.instanceOf(Object),
  formatedVessel: PropTypes.instanceOf(Object),
  errorLevel: PropTypes.string,
  listDrools: PropTypes.arrayOf(PropTypes.object),
  onCompleted: null,
  onAccept: null,
  onCancel: null,
  setSavingData: PropTypes.func,
  template: PropTypes.instanceOf(Object),
  onClose: null,
};
FormFooter.propTypes = {
  isValidForm: PropTypes.bool,
  visualization: PropTypes.instanceOf(Object),
  formatedVessel: PropTypes.instanceOf(Object),
  errorLevel: PropTypes.string,
  listDrools: PropTypes.arrayOf(PropTypes.object),
  onCompleted: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  setSavingData: PropTypes.func,
  template: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
};

export default FormFooter;
