import PropTypes from 'prop-types';
import { equals, find, propEq } from 'ramda';

import CheckAll from './components/CheckAll';
import CheckBoxComponent from './components/CheckBoxComponent';

const TableCheckColumn = ({
  rows,
  selectedRows,
  onSelectedRows: setSelectedRows,
  checkAdded,
  idName,
  attributes,
  label,
}) => {
  const basicRowIdData = (
    row,
  ) => {
    const rowObj = {
      row_id: row[idName],
    };
    if (attributes && attributes.length > 0) {
      for (let i = 0; i < attributes.length; i += 1) {
        rowObj[attributes[i]] = row[attributes[i]];
      }
    }
    return rowObj;
  };

  const basicDataRows = () => rows.map(basicRowIdData);

  const isSelected = (row) => Boolean(find(propEq('row_id', row.row_id))(selectedRows));

  const selectedAll = () => {
    if (basicDataRows().length === 0) {
      return false;
    }
    return basicDataRows().every((e) => isSelected(e));
  };

  const handleSelectRow = (row, checked) => {
    setSelectedRows((prevState) => (checked
      ? [...prevState, ...([row])]
      : prevState.filter((item) => !equals(item, row))));
  };

  const handleSelectAll = (checked) => {
    setSelectedRows(
      (prevState) => (
        checked
          ? [...prevState, ...(basicDataRows()?.filter((row) => !isSelected(row)))]
          : prevState.filter((prevRow) => !basicDataRows().some((row) => equals(row, prevRow)))
      ),
    );
  };

  return {
    text: '',
    header: <CheckAll
      isSelected={selectedAll()}
      onSelect={handleSelectAll}
      label={label}
    />,
    disabled: true,
    code: 'check',
    render: (row) => {
      const basicRowDataRow = basicRowIdData(row);
      const rowSelected = isSelected(basicRowDataRow);
      const disabled = checkAdded(row);
      return (
        <CheckBoxComponent
          row={basicRowDataRow}
          onSelectRowCheck={handleSelectRow}
          isSelected={disabled || rowSelected}
          disabled={disabled}
        />
      );
    },
  };
};

TableCheckColumn.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({ })).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({ })).isRequired,
  onSelectedRows: PropTypes.func.isRequired,
  checkAdded: PropTypes.func.isRequired,
  idName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({ })).isRequired,
};

export default TableCheckColumn;
