const SectionValidationBRActivate = () => (
  <div className="subSection">
    <p>
      To activate a business rule, the user must click on the &quot;Actions&quot;
      button corresponding to the row of that specific business rule,
      and then click on &quot;Activate&quot; button.
    </p>
  </div>
);

export default SectionValidationBRActivate;
