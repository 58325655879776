import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ValidationWarning from '@components/Form/ValidationWarning';

import { INPUT_TYPES } from '../inputs/constants';
import VesselComplexInput from '../inputs/VesselComplexInput';
import VesselFormGroup from '../inputs/VesselFormGroup';
import LineSeparator from '../LineSeparator';

import { getMinMaxValidityDates } from './utils';

import { formatCurrentDate, BACK_DATE_FORMAT, MIN_AVAILABLE_DATE } from '@common/dateUtils';

const getFormatedTodayDate = () => {
  const today = new Date();
  return today.toISOString();
};

const TabVesselIdentification = ({
  attributes,
  addComplexRow,
  visualization,
  errorsId,
  attributeExists,
}) => {
  const dispatch = useDispatch();

  const historic = useSelector((state) => state.vessel.vesselHistoric?.content);
  const oldVessel = useSelector((state) => state.vessel.oldVessel);

  const { maxValidityDate, minValidityDate } = useMemo(
    () => getMinMaxValidityDates(historic, oldVessel),
    [historic, oldVessel],
  );

  const enableMinMaxDate = useMemo(
    () => ['EDIT', 'CREATE'].includes(visualization),
    [visualization],
  );

  return (
    <>
      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="countryOfRegistration"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="name"
          classSpace="input-half"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={70}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="vesselType"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
      </div>
      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="cfr"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={12}
          minLength={12}
          visualization={(visualization === 'EDIT' && oldVessel.cfr) ? 'VIEW' : visualization}
        />
        <VesselFormGroup
          attrName="uvi"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={15}
          visualization={(visualization === 'EDIT' && oldVessel.uvi) ? 'VIEW' : visualization}
        />
        <VesselFormGroup
          attrName="ircs"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={7}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="mmsi"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={9}
          minLength={9}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="externalMarking"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={14}
          visualization={visualization}
        />
      </div>
      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="nationalRegistrationNumber"
          classSpace="input-one-third"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={32}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="portOfRegistration"
          classSpace="input-one-third"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="placeOfRegistration"
          classSpace="input-one-third"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={80}
          visualization={visualization}
        />
      </div>

      {(visualization !== 'VIEW' || (visualization === 'VIEW' && attributeExists('thirdPartyVessel'))) && (
        <LineSeparator name="Third Party Vessel Identifiers" position="top">
          &nbsp;
          {
            errorsId('thirdPartyVessel').length !== 0
            && <ValidationWarning messages={errorsId('thirdPartyVessel')} />
          }
        </LineSeparator>
      )}
      <VesselComplexInput
        attrName="thirdPartyVessel"
        classSpace={[visualization !== 'VIEW' ? 'input-60' : 'input-two-thirds', 'input-one-third']}
        attributes={attributes}
        visualization={visualization}
      />
      {visualization !== 'VIEW' ? (
        <div className="bottomFormButton left mb-3">
          <Button
            onClick={() => dispatch(addComplexRow('thirdPartyVessel'))}
          >
            Add a Third Party Vessel Identifier
          </Button>
        </div>
      ) : null}
      {(visualization !== 'VIEW' || (visualization === 'VIEW' && attributeExists('thirdPartyVessel'))) && (
        <LineSeparator position="bottom" />
      )}

      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="ircsIndicator"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="ersIndicator"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="vmsIndicator"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="aisIndicator"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="licenseIndicator"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
      </div>

      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="typeOfExport"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="countryOfImportExport"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="satphoneNumber"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          maxLength={15}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="segment"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          visualization={visualization}
        />
      </div>

      <div className="row row-form-text text-modal" style={{ alignItems: 'baseline' }}>
        <VesselFormGroup
          attrName="reportedEvent"
          classSpace="input-one-quarter"
          inputType={INPUT_TYPES.STRING}
          attributes={attributes}
          defaultValue={visualization === 'CREATE' ? 'NEW' : null}
          visualization={visualization}
        />
        <VesselFormGroup
          attrName="validityDate"
          classSpace="input-one-fifth"
          inputType={INPUT_TYPES.DATE}
          minDate={enableMinMaxDate && (minValidityDate || MIN_AVAILABLE_DATE)}
          maxDate={enableMinMaxDate && (maxValidityDate || formatCurrentDate(BACK_DATE_FORMAT))}
          attributes={attributes}
          defaultValue={visualization === 'CREATE' ? getFormatedTodayDate() : null}
          visualization={visualization}
          hasValidateDate
        />
        {
          visualization !== 'CREATE' && (
            <VesselFormGroup
              attrName="status"
              classSpace="input-one-fifth"
              inputType={INPUT_TYPES.STRING}
              attributes={attributes}
              visualization={visualization}
            />
          )
        }
      </div>
    </>
  );
};

TabVesselIdentification.defaultProps = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  addComplexRow: PropTypes.func,
  visualization: PropTypes.string,
  errorsId: PropTypes.func,
  attributeExists: PropTypes.func,
};
TabVesselIdentification.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.object),
  addComplexRow: PropTypes.func,
  visualization: PropTypes.string,
  errorsId: PropTypes.func,
  attributeExists: PropTypes.func,
};

export default TabVesselIdentification;
