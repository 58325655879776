import i18next from 'i18next';
import { identity } from 'ramda';
import React from 'react';

import VesselSearchInput from '@pages/VesselSearchGrid/VesselSearchInput';

import tSchema from '@lang/schema';

import BaseFilter from './components/BaseFilter';
import Checkbox from './components/Checkbox';
import DateRange from './components/DateRange';
import InputAdd from './components/InputAdd';
import Quantity from './components/Quantity';
import Select from './components/Select';
import VesselSearchButton from './components/VesselSearchButton';

const { t } = i18next;

export const filterTypes = {
  checkbox: {
    render: (filter, values, handleChange, handleFieldChecked) => (
      <Checkbox
        name={filter.code}
        label={filter.label}
        allwaysTrue={filter.allwaysTrue}
        onChange={handleChange}
        value={values[filter.code]}
        checked={filter.checked}
        onFieldChecked={handleFieldChecked}
      />
    ),
    value: identity,
  },
  'date-range': {
    render: (filter, values, handleChange, handleFieldChecked) => (
      <DateRange
        label={filter.label}
        name={filter.code}
        onChange={handleChange}
        values={values}
        checked={filter.checked}
        onFieldChecked={handleFieldChecked}
      />
    ),
    value: identity,
  },
  'input-add': {
    render: (filter, values, handleChange, handleFieldChecked, errors, setFieldValue) => (
      <InputAdd
        label={filter.label}
        placeholder={filter.placeholder}
        name={`${filter.code}Input`}
        nameCheckbox={filter.code}
        onChange={handleChange}
        value={values[`${filter.code}Input`]}
        error={errors[`${filter.code}Input`]}
        setFieldValue={setFieldValue}
        arrayAddedName={filter.code}
        arrayAddedValues={values[filter.code]}
        classNameControl="side-filters-control"
        checked={filter.checked}
        onFieldChecked={handleFieldChecked}
      />
    ),
    value: identity,
  },
  'select-vessel': {
    render: (filter, values, handleChange, handleFieldChecked, errors, setFieldValue) => {
      const onChangeHandler = (value) => setFieldValue(filter.code, value.map((e) => (
        { ...e, value: e.etalon_id }
      )));
      return (
        <BaseFilter
          label={filter.label}
          name={`${filter.code}`}
          nameCheckbox={filter.code}
          onChange={handleChange}
          error={errors[`${filter.code}`]}
          checked={filter.checked}
          onFieldChecked={handleFieldChecked}
        >
          <VesselSearchInput
            values={values[filter.code]}
            onChange={onChangeHandler}
            placeholder={t(tSchema.common.selectPlaceholder)}
            isInvalid={errors[filter.code]}
            isValid={!errors[filter.code]}
            minRequired={1}
          />
        </BaseFilter>
      );
    },
    value: identity,
  },
  select: {
    render: (filter, values, handleChange, handleFieldChecked, errors, setFieldValue) => {
      const options = filter.options?.map((e) => {
        switch (filter.code) {
          case 'species':
            return { value: e.id, label: [e.id, e.englishName || e.scientificName].join(' - ') };
          case 'vesselGroup':
            return { value: e.id, label: e.name };
          case 'flagState':
            return { value: e.id, label: e.description, groupBy: e.groupBy };
          default:
            return { value: e.id, label: e.description };
        }
      });
      return (
        <Select
          controlId={filter.code}
          label={filter.label}
          name={filter.code}
          value={values[filter.code]}
          options={options}
          setFieldValue={setFieldValue}
          checked={filter.checked}
          onFieldChecked={handleFieldChecked}
          isMulti={filter.isMulti}
        />
      );
    },
    value(filter) {
      return {
        ...filter,
        comparation: '=',
      };
    },
  },
  quantity: {
    render: (filter, values, handleChange, handleFieldChecked) => (
      <Quantity
        label={filter.label}
        name={filter.code}
        onChange={handleChange}
        values={values}
        checked={filter.checked}
        onFieldChecked={handleFieldChecked}
      />
    ),
    value: identity,
  },
  button: {
    render: (filter, values, handleChange,
      handleFieldChecked, errors, setFieldValue, handleClick) => (
        <VesselSearchButton
          name={filter.code}
          label={filter.label}
          textButton={filter.textButton}
          checked={filter.checked}
          onFieldChecked={handleFieldChecked}
          onClick={handleClick}
        />
    ),

  },
};

export default { filterTypes };
