import groupActions from './img/group_actions.JPG';
import groupTable from './img/group_table.JPG';

const SectionVesselGroupTable = () => (
  <div className="SectionVesselGroupTable">
    <p>
      The grid of Vessel Groups
      displays the following columns:
      <ul>
        <li>Name</li>
        <li>Description</li>
        <li>Active</li>
        <li>Type</li>
        <li>Actions</li>
      </ul>
    </p>
    <p>
      <img src={groupTable} alt="Group Grid" />
    </p>
    <p>
      In the &quot;Actions&quot; column, by clicking on the button
      available for each row, the list of available actions are displayed:
    </p>
    <p>
      <img src={groupActions} alt="Actions group" />
    </p>
  </div>
);

export default SectionVesselGroupTable;
