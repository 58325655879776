/* eslint-disable import/order */

import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import TripReportsTable from './components/TripReportsTable';
import { fetchTripReportsMessage, fetchTripReports } from './services/api';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import tSchema from '@lang/schema';
import { useBaseMessageService } from '@services/messages/BaseMessageContext';
import './style.scss';

const TripReports = ({
  tripId,
  onReportMessageLoadedHandler: handleReportMessageLoaded,
  onViewReportDetails: handleViewReportDetails,
}) => {
  const { t } = useTranslation();
  const { services: Services } = useBaseMessageService();
  const [sort, setSort] = useState({ field: 'activityDate', order: 'ASC' });
  const [size, setSize] = useState(5);

  const [page, setPage] = useState(0);
  const [loadingXml, setLoadingXml] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [xmlMessageCache, setXmlMessageCache] = useState(null);
  const [tripReports, setTripReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const totalResults = useRef(0);
  const maxPages = useRef(0);

  const requestWithLoading = useRequestWithLoading();

  useEffect(() => {
    setSelectedMessage(null);
    requestWithLoading(async () => {
      const query = await fetchTripReports(tripId, {
        page,
        size,
        sort: sort && sort.field,
        direction: sort && sort.order,
      });

      if (query.ok) {
        const data = await query.json();
        totalResults.current = data.totalElements;
        maxPages.current = data.totalPages || 1;
        setTripReports(data.content || []);
      } else {
        showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  }, [size, sort, tripId, page]);

  useEffect(() => {
    if (selectedMessage !== null) {
      selectedMessage.useCached
        ? handleReportMessageLoaded({ message: xmlMessageCache.message })
        : requestWithLoading(async () => {
          const query = await fetchTripReportsMessage(selectedMessage.id);

          if (query.ok) {
            const data = await query.json();
            if (data.isTooLarge) {
              const typeMessage = data.isIncoming ? 'incoming' : 'outcoming';
              setLoading(true);
              const query1 = await Services.apiDownloadXML(data.id, typeMessage);
              if (query1.ok) {
                const data1 = await query1.json();
                const url = window.URL.createObjectURL(new Blob([data1]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Message.xml');
                document.body.appendChild(link);
                link.click();
                link.remove();
                setLoading(false);
              } else {
                setLoading(false);
                showErrorNotification();
              }
            } else {
              handleReportMessageLoaded({ message: data.message });
              setXmlMessageCache(data);
            }
          } else {
            showErrorNotification(t(tSchema.notifications.common.fetchData));
          }
        }, setLoadingXml);
    }
  }, [selectedMessage]);

  const handleHeaderClick = useCallback((header) => {
    if (header.code && !loading) {
      setPage(0);
      setSort({
        field: header.code,
        order: sort && (sort.field === header.code && sort.order) === 'asc' ? 'desc' : 'asc',
      });
    }
  }, [loading, sort]);

  const handlePageSizeChange = useCallback((limit) => {
    setPage(0);
    setSize(Number.parseInt(limit, 10));
  }, []);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage - 1);
  }, []);

  const handleViewMessage = useCallback((row) => {
    !loading && !loadingXml
      && setSelectedMessage((prev) => ({
        id: row.xmlIncoming,
        useCached: prev && prev.id === row.xmlIncoming,
      }));
  }, [loading, loadingXml]);

  return (
    <div id="trip-reports">
      <TripReportsTable
        tripId={tripId}
        tripReportsData={tripReports}
        onReportMessageLoadedHandler={handleReportMessageLoaded}
        onViewReportDetails={handleViewReportDetails}
        onViewMessage={handleViewMessage}
        onTableHeaderClick={handleHeaderClick}
        orderBy={sort}
        defaultPageSize={size}
        onPageSizeChangeHandler={handlePageSizeChange}
        onPageChangeHandler={handlePageChange}
        loading={loading}
        totalResults={totalResults.current}
        totalPages={maxPages.current}
      />
    </div>
  );
};

TripReports.propTypes = {
  tripId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onReportMessageLoadedHandler: PropTypes.func.isRequired,
  onViewReportDetails: PropTypes.func.isRequired,
};

export default TripReports;
