/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import {
  useEffect, useState, useMemo,
} from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import VesselViewerModal from '@pages/VesselsViewer/components/VesselViewerModal';

import FieldGroup from '@components/Form';
import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import './style.scss';

import { fetchFAReportDetails } from '@services/tripLogbook/api';
import { fetchVesselById, fetchEntityVessel, getSearchableAttributes } from '@services/vessel_record/actions/vessel';

import ContactInfoModal from './components/Modals/ContactInfoModal';
import ReportDetailsSections from './components/ReportDetailsSections';
import { REPORT_DETAILS_SCHEMA } from './constants';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { isNilOrEmpty } from '@common/utils';

const ReportDetailsSection = ({
  tripId,
  loading: parentLoading,
  activeSection,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(parentLoading);
  const [currentVersion, setCurrentVersion] = useState({});
  const [reportDetails, setReportDetails] = useState(REPORT_DETAILS_SCHEMA);
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  const [showVesselViewerModal, setShowVesselViewerModal] = useState(false);

  const requestWithLoading = useRequestWithLoading();

  const disabledVesselInfo = useMemo(
    () => isNilOrEmpty(currentVersion?.etalonId), [currentVersion],
  );

  useEffect(() => {
    setLoading(parentLoading);
  }, [parentLoading]);

  useEffect(() => {
    if (tripId !== null) {
      // setReportDetails(DUMMY_REPORT_JSON());
      requestWithLoading(async () => {
        const response = await fetchFAReportDetails(tripId);
        if (response.ok) {
          const data = await response.json();
          setReportDetails(data);
        } else {
          setReportDetails({});
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setLoading);
    }
  }, [tripId]);

  const prepareForm = (idVessel, callback) => {
    dispatch(fetchEntityVessel());
    dispatch(fetchVesselById(idVessel, null, callback, 'Vessel does not exist'));
  };

  const handleClickContactInfo = () => setShowContactInfoModal(!showContactInfoModal);
  const handleClickVesselViewer = () => {
    prepareForm(
      currentVersion.etalonId,
      (data) => data && setShowVesselViewerModal(!showVesselViewerModal),
    );
  };

  return !loading ? (
    <>
      <div id="report-details">
        {
          reportDetails && currentVersion && reportDetails.contactInfo ? (
            <>
              <Row>
                <Col lg="8" md="12">
                  <FieldGroup label="Vessel">
                    <Row>
                      <Col className="first-vessel-column">
                        <TextInputField label="Vessel Name" value={currentVersion.vesselName} />
                        <TextInputField label="Vessel Flag" value={currentVersion.vesselFlag} />
                      </Col>
                      <Col>
                        <TextInputField label="CFR" value={currentVersion.cfr} />
                        <TextInputField label="UVI" value={currentVersion.uvi} />
                        <TextInputField label="EXT MARK" value={currentVersion.extMark} />
                      </Col>
                      <Col>
                        <TextInputField label="GFCM" value={currentVersion.gfcm} />
                        <TextInputField label="ICCAT" value={currentVersion.iccat} />
                        <div className="vesselInfoBtn">
                          <Button
                            disabled={disabledVesselInfo}
                            onClick={handleClickVesselViewer}
                          >
                            Vessel Info
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </FieldGroup>
                </Col>

                <Col lg="4" md="12">
                  <FieldGroup label="Contact">
                    <Row>
                      <Col xs="12">

                        <Row>
                          <Col xs="6">
                            <TextInputField label="Name" value={reportDetails.contactInfo.givenName}>
                              <Form.Control type="text" disabled value={reportDetails.contactInfo.givenName} />
                            </TextInputField>
                          </Col>
                          <Col xs="6">
                            <TextInputField label="Surname" value={reportDetails.contactInfo.middleName}>
                              <Form.Control type="text" disabled value={reportDetails.contactInfo.middleName} />
                            </TextInputField>
                          </Col>
                        </Row>
                        <TextInputField label="Role" value={reportDetails.contactInfo.role}>
                          <Row>
                            <Col xs="6">
                              <Form.Control type="text" disabled value={reportDetails.contactInfo.role} />
                            </Col>
                            <Col xs="6">
                              <Button onClick={handleClickContactInfo}>See Contact Info</Button>
                            </Col>
                          </Row>
                        </TextInputField>
                      </Col>
                    </Row>
                  </FieldGroup>
                </Col>
              </Row>
              <ContactInfoModal
                show={showContactInfoModal}
                setShow={setShowContactInfoModal}
                data={reportDetails.contactInfo}
              />
              <VesselViewerModal
                showModal={showVesselViewerModal}
                visualizationForm="VIEW"
                vessel={currentVersion}
                onClose={() => setShowVesselViewerModal(false)}
              />
            </>
          ) : (<h5>There are no report details</h5>)
        }
        <ReportDetailsSections
          reportDetails={reportDetails}
          activeSection={activeSection}
          setCurrentVersionActivity={setCurrentVersion}
        />
      </div>
    </>

  ) : (
    <>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Spinner animation="border" role="status" />
        <div className="visually-hidden">Loading report details...</div>
      </div>
    </>
  );
};

ReportDetailsSection.propTypes = {
  tripId: PropTypes.number,
  loading: PropTypes.bool,
  activeSection: PropTypes.string,
};

ReportDetailsSection.defaultProps = {
  tripId: null,
  loading: false,
  activeSection: null,
};

export default ReportDetailsSection;
