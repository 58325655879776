import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, {
  useMemo,
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CustomTabs from '@components/CustomTabs';

import tSchema from '@lang/schema';

import Catches from './Catches';
import DiscardReasons from './DiscardReasons';
import FishingGears from './FishingGears';
import GearProblems from './GearProblems';
import Location from './Location';
import OtherCharacteristics from './OtherCharacteristics';
import RelatedVessels from './RelatedVessels';
import Storage from './Storage';
import SubActivities from './SubActivities';

const schema = tSchema.fishingActivities.logbookViewer.details.subsections;

const SubSections = ({ section, reportType = '' }) => {
  const {
    location,
    catches,
    gears,
    gearProblems,
    subActivities,
    relatedVessels,
    characteristics,
    discardReasons,
    sourceVesselStorage,
    destinationVesselStorage,
    otherCharacteristics,
  } = section;

  const { t } = useTranslation();

  const subSections = useMemo(() => [
    ...(location && !isEmpty(location) ? [{
      label: t(schema.location),
      content: <Location location={location} />,
    }] : []),
    ...(catches && !isEmpty(catches) ? [{
      label: t(schema.catch),
      content: <Catches catches={catches} />,
    }] : []),
    ...(gears && !isEmpty(gears) ? [{
      label: t(schema.fishingGear),
      content: <FishingGears gears={gears} />,
    }] : []),
    ...(gearProblems && !isEmpty(gearProblems) ? [{
      label: t(schema.gearProblems),
      content: <GearProblems gearProblems={gearProblems} />,
    }] : []),
    ...(relatedVessels && !isEmpty(relatedVessels) ? [{
      label: t(schema.relatedVessels),
      content: <RelatedVessels relatedVessels={relatedVessels} />,
    }] : []),
    ...(subActivities && !isEmpty(subActivities) ? [{
      label: t(schema.subActivities),
      content: <SubActivities subActivities={subActivities} reportType={reportType} />,
    }] : []),
    ...(characteristics && !isEmpty(characteristics) ? [{
      label: t(schema.otherCharacteristics),
      content: <OtherCharacteristics characteristics={characteristics} />,
    }] : []),
    ...(discardReasons && !isEmpty(discardReasons) ? [{
      label: t(schema.discardReasonDescription),
      content: <DiscardReasons discardReasons={discardReasons} />,
    }] : []),
    ...(
      (
        (sourceVesselStorage && !isEmpty(sourceVesselStorage))
        || (destinationVesselStorage && !isEmpty(destinationVesselStorage))
      ) ? [{
          label: t(schema.storage),
          content: <Storage
            sourceVesselStorage={sourceVesselStorage}
            destinationVesselStorage={destinationVesselStorage}
          />,
        }] : []),
    ...(otherCharacteristics && !isEmpty(otherCharacteristics) ? [{
      label: t(schema.otherCharacteristics),
      content: <OtherCharacteristics characteristics={otherCharacteristics} />,
    }] : []),
  ], [section]);

  return (
    <Row className="mt-3">
      <Col xs={12} className="p-0">
        <CustomTabs sections={subSections} />
      </Col>
    </Row>
  );
};

SubSections.propTypes = {
  section: PropTypes.shape(
    {
      location: PropTypes.shape({}),
      catches: PropTypes.arrayOf(PropTypes.shape({})),
      gears: PropTypes.arrayOf(PropTypes.shape({})),
      gearProblems: PropTypes.arrayOf(PropTypes.shape({})),
      subActivities: PropTypes.arrayOf(PropTypes.shape({})),
      relatedVessels: PropTypes.arrayOf(PropTypes.shape({})),
      characteristics: PropTypes.arrayOf(PropTypes.shape({})),
      discardReasons: PropTypes.arrayOf(PropTypes.shape({})),
      sourceVesselStorage: PropTypes.arrayOf(PropTypes.shape({})),
      destinationVesselStorage: PropTypes.arrayOf(PropTypes.shape({})),
      otherCharacteristics: PropTypes.arrayOf(PropTypes.shape({})),
    },
  ).isRequired,
  reportType: PropTypes.string,
};

SubSections.defaultProps = {
  reportType: '',
};

export default SubSections;
