import PropTypes from 'prop-types';
import { any, prop, propEq } from 'ramda';
import { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import getRoutePaths from '@pages/App/utils/routePaths';

import BaseMessagePage from '@components/BaseMessage';
import Section from '@components/Section';

import services from '@services/messages/api';
import { BaseMessageProvider } from '@services/messages/BaseMessageContext';
import useDomainId from '@services/userPreferences/hooks/useDomainId';

import './style.scss';

const TrafficMonitoringView = ({ path, title }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { sectionId } = useParams();
  const domain = useDomainId();
  const routePaths = useMemo(() => getRoutePaths(t), [t]);

  const sections = [
    {
      id: routePaths.trafficMonitoring.sections.INCOMING.url,
      key: routePaths.trafficMonitoring.sections.INCOMING.url,
      title: routePaths.trafficMonitoring.sections.INCOMING.title,
      render: () => <BaseMessagePage typeSection="incoming" domain={domain} />,
    },
    {
      id: routePaths.trafficMonitoring.sections.OUTGOING.url,
      key: routePaths.trafficMonitoring.sections.OUTGOING.url,
      title: routePaths.trafficMonitoring.sections.OUTGOING.title,
      render: () => <BaseMessagePage typeSection="outgoing" domain={domain} />,
    },
  ];

  const currentSection = sections.find((s) => s.id === sectionId);

  useEffect(() => {
    document.title = `${window.env.REACT_APP_TITLE} - ${
      currentSection ? currentSection.title : ''
    } ${title}`;
  }, [title, currentSection]);

  if (!any(propEq('id', sectionId), sections)) {
    return <Redirect to={`${path}/${sections[0].id}`} />;
  }

  return (
    <Container fluid id="message-viewer">
      <BaseMessageProvider services={services('trafficMonitoring')}>
        <Section
          tabTitleGetter={prop('title')}
          sections={sections}
          defaultSection={currentSection}
          className="main"
          onCurrentSectionChangeCallback={(section) => history.push(`${path}/${section.id}`)}
        />
      </BaseMessageProvider>
    </Container>
  );
};

TrafficMonitoringView.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TrafficMonitoringView;
