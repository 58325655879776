/* eslint-disable import/order */
import tSchema from '@lang/schema';
import useLogbookTypes from '@pages/FishingTripsLogbook/hooks/useLogbookTypes';
import { useSideFilterContext } from '@pages/FishingTripsLogbook/SideFilterContext/SideFilterContext';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';

import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SearchFavouriteSections from '../SearchFavouriteSections';
import { configureFilters } from '../viewConfig';

import ReportMessageModal from './components/ReportMessageModal';
import TripReports from './components/TripReports';
import TripViewTable from './components/TripViewTable';
import { fetchTripList } from './services/api';

import './style.scss';

import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';

const TripViewSection = ({
  onViewReportDetails: handleViewReportDetails,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [tripsData, setTripsData] = useState({});
  const [sort, setSort] = useState({ field: 'lastActivityDate', order: 'DESC' });
  const [size, setSize] = useState(10);
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});
  const [initialChoosedFilters, setInitialChoosedFilters] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [reportMessage, setReportMessage] = useState(null);

  const dataTableRef = useRef();
  const page = useRef(1);
  const {
    setSideFilterState,
    sideFilterState: { resetFilters, submitedFilters },
  } = useSideFilterContext();

  const triggerDataFetch = () => setForceUpdateFlag({});
  const [submitedFiltersState, setSubmitedFiltersState] = useState(submitedFilters);

  const { userPreferences } = useUserPreferences();
  const idScenarioLastActive = userPreferences.lastActiveFa.id;

  const requestWithLoading = useRequestWithLoading();

  const resetPagination = useCallback(() => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    page.current = 1;
  }, [dataTableRef, page]);

  const types = useLogbookTypes({ isTripView: true, setLoadingTypes });

  const fetchTrips = useCallback(({ pageSearch }) => {
    setSelectedTrip(null);
    const filterWithScenario = idScenarioLastActive
      ? { scenario: idScenarioLastActive, ...submitedFilters } : submitedFilters;
    requestWithLoading(async () => {
      const query = await fetchTripList(filterWithScenario,
        {
          page: pageSearch,
          size,
          sort: sort && sort.field,
          direction: sort && sort.order,
        });

      if (query.ok) {
        const data = await query.json();
        setTripsData(data || {});
      } else {
        showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  }, [
    forceUpdateFlag,
    size,
    sort,
    idScenarioLastActive,
    requestWithLoading,
    page,
    submitedFilters,
    showErrorNotification]);

  useEffect(() => {
    if (submitedFiltersState !== submitedFilters) {
      resetPagination();
      fetchTrips({ pageSearch: 0 });
      setSubmitedFiltersState(submitedFilters);
    } else { fetchTrips({ pageSearch: page.current - 1 }); }
  }, [
    forceUpdateFlag,
    size,
    sort,
    idScenarioLastActive,
    requestWithLoading,
    page,
    submitedFilters,
  ]);

  const toggleVesselModal = useCallback(() => {
    setSideFilterState((prevState) => ({
      ...prevState,
      isShowVesselsModal: !prevState.isShowVesselsModal,
    }));
  }, [setSideFilterState]);

  useEffect(() => {
    if (!isEmpty(types)) {
      setInitialChoosedFilters(configureFilters(types, null, true));
      setSideFilterState((prevState) => ({
        ...prevState,
        choosedFilters: prevState.activeFilters.length
          ? prevState.choosedFilters
          : configureFilters(types, toggleVesselModal, true)
            .map((filter) => ({ ...filter, checked: true })),
      }));
    }
  }, [types]);

  useEffect(() => {
    if (resetFilters) {
      if (!isEmpty(types)) {
        setInitialChoosedFilters(configureFilters(types, null, true));
        page.current > 1 && resetPagination();
        setSideFilterState(
          {
            choosedFilters: configureFilters(types, toggleVesselModal, true)
              .map((filter) => ({ ...filter, checked: true })),
            activeFilters: [],
            submitedFilters: {},
            resetFilters: false,
          },
        );
      } else {
        setSideFilterState((prevState) => ({ ...prevState, resetFilters: false }));
      }
    }
  }, [resetFilters, page.current]);

  const handleReportMessageLoaded = useCallback((message) => setReportMessage(message), []);

  const handleRowSelected = useCallback(
    (row) => {
      selectedTrip !== row.id && setSelectedTrip(row.id);
    },
    [selectedTrip],
  );

  const handlePageSizeChange = useCallback((limit) => {
    page.current = 1;
    setReportMessage(null);
    setSize(Number.parseInt(limit, 10));
  }, []);

  const handlePageChange = useCallback((newPage) => {
    page.current = newPage;
    setReportMessage(null);
    triggerDataFetch();
  }, [triggerDataFetch]);

  const handleHeaderClick = useCallback((header) => {
    if (header.code && !loading) {
      page.current = 1;
      setSort({
        field: header.code,
        order: sort && (sort.field === header.code && sort.order) === 'asc' ? 'desc' : 'asc',
      });
      resetPagination();
    }
  }, [loading, sort, resetPagination]);

  return (
    <Container fluid className="trip-view-section p-0">
      <Row
        className="w-100 m-0 pb-3 d-flex flex-nowrap"
        style={{
          minHeight: '100%',
          height: 'auto',
        }}
      >
        <SearchFavouriteSections
          types={types}
          isUIBlocked={loadingTypes}
          initialChoosedFilters={initialChoosedFilters}
          viewSection="tripView"
        />
        <Col className="p-0 pl-4 h-100">
          <TripViewTable
            dataTableRef={dataTableRef}
            tripsData={tripsData}
            filterOptions={types}
            onViewReportDetails={handleViewReportDetails}
            rowSelected={selectedTrip}
            onRowSelected={handleRowSelected}
            onTableHeaderClick={handleHeaderClick}
            orderBy={sort}
            defaultPageSize={size}
            onPageSizeChangeHandler={handlePageSizeChange}
            onPageChangeHandler={handlePageChange}
            loading={loading}
            subRowRender={(row) => (
              <>
                <TripReports
                  tripId={row.id}
                  onReportMessageLoadedHandler={handleReportMessageLoaded}
                  onViewReportDetails={handleViewReportDetails}
                />
                <ReportMessageModal data={reportMessage} onClose={() => setReportMessage(null)} />
              </>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};

TripViewSection.propTypes = {
  onViewReportDetails: PropTypes.func.isRequired,
};

export default TripViewSection;
