import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FieldGroup from '@components/Form';
import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import WrapperModal from '../components/WrapperModal';

import './style.scss';

const schema = tSchema.fishingActivities.logbookViewer.contactInfo;

const ContactInfoModal = ({
  show, setShow, data: {
    givenName,
    middleName,
    role,
    alias,
    familyNamePrefix,
    nameSuffix,
    gender,
    country,
    countrySubdivision,
    cityName,
    citySubdivision,
    streetName,
    blockName,
    buildingName,
    plotIdentification,
    postOfficeBox,
    postalArea,
  },
}) => {
  const { t } = useTranslation();
  return (
    <WrapperModal
      id="wrapper-modal-contact-info"
      showModal={show}
      setShowModal={setShow}
      headerTitle="Contact Info"
      centered
      animation={false}
      size="lg"
    >
      <Container>
        <Row>
          <Col lg="6" md="12" className="pl-0 pr-2">
            <FieldGroup label={t(schema.Person)}>
              <TextInputField label={t(schema.givenName)} value={givenName} />
              <TextInputField label={t(schema.middleName)} value={middleName} />
              <TextInputField label={t(schema.role)} value={role} />
              <TextInputField label={t(schema.alias)} value={alias} />
              <TextInputField label={t(schema.familyNamePrefix)} value={familyNamePrefix} />
              <TextInputField label={t(schema.nameSuffix)} value={nameSuffix} />
              <TextInputField label={t(schema.gender)} value={gender} />
            </FieldGroup>
          </Col>
          <Col lg="6" md="12" className="pl-2 pr-0">
            <FieldGroup label={t(schema.address)}>
              <TextInputField label={t(schema.country)} value={country} />
              <TextInputField label={t(schema.countryDivision)} value={countrySubdivision} />
              <TextInputField label={t(schema.cityName)} value={cityName} />
              <TextInputField label={t(schema.citySubdivision)} value={citySubdivision} />
              <TextInputField label={t(schema.streetName)} value={streetName} />
              <TextInputField label={t(schema.blockName)} value={blockName} />
              <TextInputField label={t(schema.buildingName)} value={buildingName} />
              <TextInputField label={t(schema.plotIdentification)} value={plotIdentification} />
              <TextInputField label={t(schema.postOfficeBox)} value={postOfficeBox} />
              <TextInputField label={t(schema.postalArea)} value={postalArea} />
            </FieldGroup>
          </Col>
        </Row>
      </Container>

    </WrapperModal>
  );
};

ContactInfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  data: PropTypes.shape({
    givenName: PropTypes.string,
    middleName: PropTypes.string,
    role: PropTypes.string,
    alias: PropTypes.string,
    familyNamePrefix: PropTypes.string,
    nameSuffix: PropTypes.string,
    gender: PropTypes.string,
    country: PropTypes.string,
    countrySubdivision: PropTypes.string,
    cityName: PropTypes.string,
    citySubdivision: PropTypes.string,
    streetName: PropTypes.string,
    blockName: PropTypes.string,
    buildingName: PropTypes.string,
    plotIdentification: PropTypes.string,
    postOfficeBox: PropTypes.string,
    postalArea: PropTypes.string,
  }).isRequired,
};

export default ContactInfoModal;
