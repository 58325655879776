import PropTypes from 'prop-types';
import { prop } from 'ramda';
import {
  useMemo, useState, useEffect, useCallback,
} from 'react';

import { LOGBOOK_SECTIONS } from '@pages/FishingTripsLogbook/config';

import Section from '@components/Section';

import { REPORT_DETAILS_PROPTYPES } from '../constants';

import Arrival from './sections/Arrival';
import ReportDetailsDeparture from './sections/Departure';
import DiscardAtSea from './sections/DiscardAtSea';
import ReportDetailsEntryIntoArea from './sections/EntryIntoArea';
import ExitFromArea from './sections/ExitFromArea';
import ReportDetailsFishingOperation from './sections/FishingOperation';
import JoinFishingOperation from './sections/JoinFishingOperation';
import Landing from './sections/Landing';
import PriorNotificationOfArrival from './sections/PriorNotificationOfArrival';
import './style.scss';
import Relocation from './sections/Relocation';
import Transhipment from './sections/Transhipment';
import TranshipmentOrRelocationNotification from './sections/TranshipmentOrRelocationNotification';
import VersionSelector from './versionSelector/VersionSelector';

const ReportDetailsSections = ({ reportDetails, activeSection, setCurrentVersionActivity }) => {
  const [currentVersion, setCurrentVersion] = useState(null);
  const [sortedSections, setSortedSections] = useState([]);
  useEffect(() => setCurrentVersion((reportDetails.content && reportDetails.content)),
    [reportDetails]);

  const getOlderDate = useCallback((reports) => {
    let olderDate = null;
    const reverseReports = reports.reverse();
    for (let i = 0; i < reverseReports.length; i += 1) {
      for (let j = 0; j < reverseReports[i].length; j += 1) {
        const reportDate = reverseReports[i][j].activityDate || reverseReports[i][j].startDate;
        const statusOk = reverseReports[i][j].status.description === 'CURRENT' || reverseReports[i][j].status.description === 'DELETED' || reverseReports[i][j].status.description === 'CANCELLED';
        if ((!olderDate || new Date(olderDate) > new Date(reportDate)) && statusOk) {
          olderDate = reportDate;
        }
      }
    }
    return olderDate;
  }, []);

  const sortReports = useCallback((reports) => reports.sort((a, b) => {
    const dateA = a[a.length - 1].activityDate;
    const dateB = b[b.length - 1].activityDate;
    return new Date(dateA) - new Date(dateB);
  }), []);

  const sections = useMemo(() => [
    ...(currentVersion?.departure?.length ? [{
      id: 'departure',
      key: LOGBOOK_SECTIONS.DEPARTURE,
      title: `Departure (${currentVersion.departure?.length})`,
      render: () => (
        <ReportDetailsDeparture
          departure={sortReports(currentVersion?.departure)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.departure),
    }] : []),
    ...(currentVersion?.entryIntoArea?.length ? [{
      id: 'entryIntoArea',
      key: LOGBOOK_SECTIONS.AREA_ENTRY,
      title: `Entry into area (${currentVersion.entryIntoArea?.length})`,
      render: () => (
        <ReportDetailsEntryIntoArea
          entryIntoArea={sortReports(currentVersion?.entryIntoArea)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.entryIntoArea),
    }] : []),
    ...(currentVersion?.fishingOperation?.length ? [{
      id: 'fishingOperation',
      key: LOGBOOK_SECTIONS.FISHING_OPERATION,
      title: `Fishing operation (${currentVersion.fishingOperation?.length})`,
      render: () => (
        <ReportDetailsFishingOperation
          fishingOperation={sortReports(currentVersion?.fishingOperation)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.fishingOperation),
    }] : []),
    ...(currentVersion?.joinFishingOperation?.length ? [{
      id: 'joinFishingOperation',
      key: LOGBOOK_SECTIONS.JOINT_FISHING_OPERATION,
      title: `Joint fishing operation (${currentVersion.joinFishingOperation?.length})`,
      render: () => (
        <JoinFishingOperation
          joinFishingOperations={sortReports(currentVersion?.joinFishingOperation)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.joinFishingOperation),
    }] : []),
    ...(currentVersion?.discardAtSea?.length ? [{
      id: 'discardAtSea',
      key: LOGBOOK_SECTIONS.DISCARD,
      title: `Discard at sea (${currentVersion.discardAtSea?.length})`,
      render: () => (
        <DiscardAtSea
          declarations={sortReports(currentVersion?.discardAtSea)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.discardAtSea),
    }] : []),
    ...(currentVersion?.relocation?.length ? [{
      id: 'relocation',
      key: LOGBOOK_SECTIONS.RELOCATION,
      title: `Relocation (${currentVersion.relocation?.length})`,
      render: () => (
        <Relocation
          relocation={sortReports(currentVersion?.relocation)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.relocation),
    }] : []),
    ...(currentVersion?.exitFromArea?.length ? [{
      id: 'exitFromArea',
      key: LOGBOOK_SECTIONS.AREA_EXIT,
      title: `Exit from area (${currentVersion.exitFromArea?.length})`,
      render: () => (
        <ExitFromArea
          exitFromArea={sortReports(currentVersion?.exitFromArea)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.exitFromArea),
    }] : []),
    ...(currentVersion?.arrival?.length ? [{
      id: 'arrival',
      key: LOGBOOK_SECTIONS.ARRIVAL,
      title: `Arrival (${currentVersion.arrival?.length})`,
      render: () => (
        <Arrival
          arrival={sortReports(currentVersion?.arrival)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.arrival),
    }] : []),
    ...(currentVersion?.landing?.length ? [{
      id: 'landing',
      key: LOGBOOK_SECTIONS.LANDING,
      title: `Landing (${currentVersion.landing?.length})`,
      render: () => (
        <Landing
          landing={sortReports(currentVersion?.landing)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.landing),
    }] : []),
    ...(currentVersion?.transhipment?.length ? [{
      id: 'transhipment',
      key: LOGBOOK_SECTIONS.TRANSHIPMENT,
      title: `Transhipment (${currentVersion.transhipment?.length})`,
      render: () => (
        <Transhipment
          transhipment={sortReports(currentVersion?.transhipment)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.transhipment),
    }] : []),
    ...(currentVersion?.arrivalNotification?.length ? [{
      id: 'priorNotificationOfArrival',
      key: LOGBOOK_SECTIONS.PRIOR_NOTIFICATION_OF_ARRIVAL,
      title: `Prior notification of arrival (${currentVersion.arrivalNotification?.length})`,
      render: () => (
        <PriorNotificationOfArrival
          priorNotificationOfArrival={sortReports(currentVersion?.arrivalNotification)}
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.arrivalNotification),
    }] : []),
    ...(currentVersion?.transhipmentOrRelocationNotification?.length ? [{
      id: 'transhipmentOrRelocationNotification',
      key: LOGBOOK_SECTIONS.TRANSHIPMENT_OR_RELOCATION_NOTIFICATION,
      title: `Prior notification of transhipment or relocation (${currentVersion.transhipmentOrRelocationNotification?.length})`,
      render: () => (
        <TranshipmentOrRelocationNotification
          transhipmentOrRelocationNotification={
            sortReports(currentVersion?.transhipmentOrRelocationNotification)
          }
          setCurrentVersionActivity={setCurrentVersionActivity}
        />
      ),
      olderActivityDate: getOlderDate(currentVersion?.transhipmentOrRelocationNotification),
    }] : []),
  ], [currentVersion]);

  const sortSectionsByOlderOccurrenceFirst = useCallback(() => {
    setSortedSections([...sections].sort(
      (a, b) => {
        if (a.olderActivityDate === b.olderActivityDate || !a.olderActivityDate) {
          return -1;
        }
        if (!b.olderActivityDate) {
          return 1;
        }
        return new Date(a.olderActivityDate) - new Date(b.olderActivityDate);
      },
    ));
  }, [sections]);

  useEffect(() => {
    sortSectionsByOlderOccurrenceFirst();
  }, [sections]);

  return (
    (
      <>
        {reportDetails && reportDetails?.reports && reportDetails?.reports !== undefined && (
        <VersionSelector
          versions={reportDetails.reports}
          onSelectVersion={setCurrentVersion}
          controlId={reportDetails.fishingTripId}
        />
        )}
        {sortedSections && sortedSections.length > 0 && (
        <Section
          tabTitleGetter={prop('title')}
          sections={sortedSections}
          defaultSection={sortedSections.find(
            (section) => section.key === activeSection,
          )
             || sortedSections[0]}
          className="report-details rounded-top mt-2"
        />
        )}
      </>
    )
  );
};

ReportDetailsSections.propTypes = {
  reportDetails: REPORT_DETAILS_PROPTYPES.isRequired,
  activeSection: PropTypes.string,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

ReportDetailsSections.defaultProps = {
  activeSection: null,

};

export default ReportDetailsSections;
