import * as R from 'ramda';

import { BACK_DATE_FORMAT, formatDate } from '@common/dateUtils';

export const REPORTED_EVENT = {
  NEW: 'NEW',
  MOD: 'MOD',
};

const formatVesselValidityDate = (vessel) => {
  const formatValidity = formatDate(vessel.validityDate, BACK_DATE_FORMAT, undefined, true);

  return { ...vessel, validityDate: formatValidity };
};

const hasCallNewVersion = (oldVessel, vessel) => {
  const formatedOldVessel = formatVesselValidityDate(oldVessel);
  const formatedNewVessel = formatVesselValidityDate(vessel);
  const isVesselsEqual = R.equals(formatedOldVessel, formatedNewVessel);

  const old = { ...formatedOldVessel };
  const newVessel = { ...formatedNewVessel };
  delete old.validityDate;
  delete newVessel.validityDate;

  if (!isVesselsEqual) {
    // vessel no son iguales
    const isValidityDateEqual = R.equals(
      formatedOldVessel.validityDate, formatedNewVessel.validityDate,
    );
    const hasChange = Object.keys(old).filter((key) => old[key] !== newVessel[key]).length > 0;
    // los validity date no son iguales
    if (!isValidityDateEqual && hasChange) {
      return true;
      // llama a una nueva version.
    }
  }
  return false;
};

export default hasCallNewVersion;
