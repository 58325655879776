import tSchema from '@lang/schema';

const schema = tSchema.dmaAdmin.vesselGroupMGMT.createEditView;

export const getTableConfig = (t) => [
  {
    text: t(schema.name),
    code: 'name',
    sortable: true,
  },
  {
    text: t(schema.cfr),
    code: 'cfr',
    sortable: true,
  },
  {
    text: t(schema.flagState),
    code: 'countryOfRegistration',
    sortable: true,
  },
  {
    text: t(schema.uvi),
    code: 'uvi',
    sortable: true,
  },
  {
    text: t(schema.extMark),
    code: 'externalMarking',
    sortable: true,
  },
  {
    text: t(schema.ircs),
    code: 'ircs',
    sortable: true,
  },
  {
    text: t(schema.mmsi),
    code: 'mmsi',
    sortable: true,
  },
  {
    text: t(schema.mainFishingGear),
    code: 'mainFishingGear',
    sortable: true,
  },
];

export const configureFilters = [
  { code: 'name', label: 'Name', type: 'text' },
  {
    code: 'isActive',
    label: 'Status',
    type: 'select',
    options: [{ id: true, description: 'Active' }, { id: false, description: 'Inactive' }],
  },
];

export const searchOptions = [
  { label: 'contains', value: '-=', type: 'text' },
  { label: 'equal', value: '==' },
  { label: 'not contains', value: '!=', type: 'text' },
  { label: 'begins with', value: '*=', type: 'text' },
  { label: 'ends with', value: '=*', type: 'text' },
  {
    label: 'is null', value: '=null', type: 'text', single: true,
  },
];

export const businessRulesValueOptions = [
  'brdomain',
  'brlevel',
  'brsubgroup',
  'brsublevel',
  'brstatus',
  'brseverity',
  'field',
  'executionorder',
  'brcontext',
  'browner',
  'brtypeofentity',
];
