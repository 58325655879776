import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import TextInputField from '@components/TextInputField';

import tSchema from '@lang/schema';

import { ARRIVAL_PROPTYPES, ARRIVAL_SCHEMA } from '../../constants';
import ReportHeader from '../reportHeader/ReportHeader';
import SubSections from '../subSections/SubSections';

import { formatDateTime } from '@common/dateUtils';

const schema = tSchema.fishingActivities.logbookViewer.details;

const Arrival = ({ arrival, setCurrentVersionActivity }) => {
  const { t } = useTranslation();
  const [currentVersion, setCurrentVersion] = useState(ARRIVAL_SCHEMA);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentArrival, setCurrentArrival] = useState([]);

  useEffect(() => setCurrentArrival((arrival && arrival[pageNumber - 1]) || ARRIVAL_SCHEMA),
    [arrival, pageNumber]);

  useEffect(() => setCurrentVersionActivity(currentVersion), [currentVersion]);

  return (
    <Container fluid id="report-details-departure">
      <ReportHeader
        title="Departure"
        versions={currentArrival}
        onSelectVersion={setCurrentVersion}
        reports={arrival}
        pagination={pageNumber}
        onSelectPagination={setPageNumber}
      />

      <Row>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.ocurrence)}
            value={currentVersion?.occurrence && `${formatDateTime(currentVersion?.occurrence.split('+')[0], 'DD/MM/YYYY HH:mm:ss')}, UTC`}
          />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.reasonCode)}
            value={currentVersion?.reason}
          />
        </Col>
        <Col md={4} sm={4} xs={12}>
          <TextInputField
            label={t(schema.reasonDescription)}
            value={currentVersion?.reasonDescription}
          />
        </Col>
      </Row>
      <SubSections section={currentVersion || []} />
    </Container>
  );
};

Arrival.propTypes = {
  arrival: ARRIVAL_PROPTYPES.isRequired,
  setCurrentVersionActivity: PropTypes.func.isRequired,
};

export default Arrival;
