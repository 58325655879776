import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import VesselSearchInput from '@pages/VesselSearchGrid/VesselSearchInput';

import tSchema from '@lang/schema';

const schema = tSchema.dmaAdmin.queryReportMGMT.createEditView;

const VesselSelectsForm = ({
  isTouched, disabled, values, errors, selectStyles,
  vesselGroupsOptions, setFieldValue,
}) => {
  const { t } = useTranslation();
  const onChangeVesselHandler = useCallback((vessels) => {
    setFieldValue('queryObject.vessel', vessels);
  }, []);

  const vesselValueMapper = useCallback((e) => (e.cfr || (e.uvi && `UVI: ${e.uvi}`) || 'Invalid Id'), [values]);

  return (
    <>
      <Form.Row className="mb-1">
        <Form.Group as={Col} controlId="formVesselFlags" className="mb-0">
          <Form.Label>
            {t(schema.vessel)}
          </Form.Label>
          <VesselSearchInput
            label={null}
            values={values.queryObject.vessel}
            valueMap={vesselValueMapper}
            onChange={onChangeVesselHandler}
            disabled={disabled}
            placeholder={t(tSchema.common.selectPlaceholder)}
            isInvalid={isTouched && errors.queryObject?.vessel}
            isValid={isTouched && !errors.queryObject?.vessel}
            minRequired={1}
            selectedRowsLabelMapper={vesselValueMapper}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formVesselGroups" className="mb-0">
          <Form.Label>
            {t(schema.vesselGroups)}
          </Form.Label>
          <ReactSelect
            name="queryObject.groups"
            styles={isTouched
                             && selectStyles(!errors.queryObject?.groups)}
            className={isTouched && (errors.queryObject?.groups ? 'is-invalid' : 'is-valid')}
            isDisabled={disabled}
            isMulti
            closeMenuOnSelect={false}
            value={values.queryObject?.groups}
            options={vesselGroupsOptions}
            onChange={(option) => setFieldValue('queryObject.groups', option)}
            placeholder={t(tSchema.common.selectPlaceholder)}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row as={Col} className="pl-2 mt-1">
        {isTouched && errors.queryObject?.vessel && (
        <Form.Control.Feedback style={{ display: 'block' }} className="invalid-feedback mt-0">
          {typeof errors.queryObject.vessel === 'string' ? errors.queryObject.vessel : 'Error'}
        </Form.Control.Feedback>
        )}
      </Form.Row>
    </>
  );
};

VesselSelectsForm.propTypes = {
  errors: PropTypes.shape({
    queryObject: PropTypes.shape({
      groups: PropTypes.string,
      vessel: PropTypes.string,
    }),
  }).isRequired,
  values: PropTypes.shape({
    vesselIdType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    queryObject: PropTypes.shape({
      groups: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      })),
      vessel: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      })),
    }),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isTouched: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectStyles: PropTypes.func.isRequired,
  vesselGroupsOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,

};

export default VesselSelectsForm;
