/* eslint-disable import/order */

import PropTypes from 'prop-types';
import {
  find, isEmpty, nth, pick, reject, uniqBy,
} from 'ramda';
import { configureFilters, searchOptions, typeMessage } from '@components/BaseMessage/config';
import Filters from '@components/Filters';
import Section from '@components/Section';
import tSchema from '@lang/schema';
import { useBaseMessageService } from '@services/messages/BaseMessageContext';
import {
  useCallback, useEffect, useMemo, useRef, useState, useLayoutEffect,
} from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RealmRole } from '@common/keycloakFunctions';
import './style.scss';
import MessageList from './components/MessageList';
import MessageOverview from './components/MessageOverview';
import useMessageOptions from './hooks/useMessageOptions/useMessageOptions';
import useWidthContainer from './hooks/useWidthContainer';
import { useUserPreferences } from '@services/userPreferences/UserPreferencesContext';
import { useRequestWithLoading } from '@common/hooks/request';
import { showErrorNotification } from '@common/utilities/Notification';
import { useKeyCloak } from '@services/authentication/useKeyCloakContext';

const pageSize = 15;

const BaseMessagePage = ({
  typeSection,
  domain,
}) => {
  const { t } = useTranslation();

  const [messages, setMessages] = useState(null);
  const [relatedMessages, setRelatedMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRelatedMessages, setLoadingRelatedMessages] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [primaryMessage, setPrimaryMessage] = useState();
  const [openMessages, setOpenMessages] = useState([]);
  const [sections, setSections] = useState([]);
  const [currentMessage, setCurrentMessage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [queryRelatedMessages, setQueryRelatedMessages] = useState(0);
  const [responseRelatedMessages, setResponseRelatedMessages] = useState(0);
  const [reportRelatedMessages, setReportRelatedMessages] = useState(0);
  const { userPreferences } = useUserPreferences();
  const totalCount = useRef(0);
  const currentPageNumber = useRef(0);

  const requestWithLoading = useRequestWithLoading();

  const { services: Services } = useBaseMessageService();

  const types = useMessageOptions({ setLoading, services: Services });
  const [refRowWith, refRowContainer] = useWidthContainer();
  const domainCode = userPreferences && userPreferences.domain;
  const { keycloak } = useKeyCloak();

  useEffect(() => () => {
    setPageNumber(0);
    totalCount.current = 0;
    currentPageNumber.current = 0;
    setFilters(null);
    setMessages(null);
    setPrimaryMessage();
    setOpenMessages([]);
    setSections([]);
  }, [typeSection, domain]);

  useEffect(() => {
    requestWithLoading(async () => {
      const filtersWithDomain = filters ? `domain==${domain},${filters}` : `domain==${domain}`;
      const query = await Services.apiFetchMessages(typeSection, filtersWithDomain, {
        page: 0,
        size: pageSize,
      });

      if (query.ok) {
        const data = await query.json();
        currentPageNumber.current = data.pageNumber || 0;
        totalCount.current = data.totalElements || 0;
        setMessages(data.page.content || []);
        setIsLastPage(data.page.totalElements >= data.totalElements);
      } else {
        currentPageNumber.current = 0;
        totalCount.current = 0;
        setPageNumber(0);
        setMessages([]);
        showErrorNotification(t(tSchema.notifications.common.fetchData));
      }
    }, setLoading);
  }, [filters, typeSection, domain]);

  useLayoutEffect(() => {
    const firstMessage = messages && messages[0];
    if (pageNumber === 0 && firstMessage) {
      setPrimaryMessage(firstMessage);
      setCurrentMessage(pick(['id', 'isIncoming'], firstMessage));
      setOpenMessages([]);
    }
    if (!firstMessage) {
      setPrimaryMessage();
      setCurrentMessage({});
      setPageNumber(0);
    }
  }, [messages, pageNumber]);

  const loadMoreData = useCallback(() => {
    const newPageNumber = pageNumber + 1;
    if (newPageNumber > currentPageNumber.current) {
      requestWithLoading(async () => {
        const filtersWithDomain = filters ? `domain==${domain},${filters}` : `domain==${domain}`;
        const query = await Services.apiFetchMessages(typeSection, filtersWithDomain, {
          size: pageSize,
          page: newPageNumber,
        });

        if (query.ok) {
          const data = await query.json();
          setMessages((current) => [...(current || []), ...data.page.content]);
          currentPageNumber.current = newPageNumber;
          setIsLastPage(data.page.totalElements >= data.totalElements);
        } else {
          showErrorNotification(t(tSchema.notifications.common.fetchData));
        }
      }, setMessagesLoading);
      setPageNumber(newPageNumber);
    }
  }, [
    pageNumber,
    setMessages,
    filters,
    currentPageNumber,
    requestWithLoading,
    typeSection,
    domain,
  ]);

  useEffect(() => {
    primaryMessage
        && setSections(
          uniqBy(pick(['id', 'isIncoming']), [primaryMessage, ...openMessages]).map((message) => ({
            id: message.id,
            identification: message.identification,
            isIncoming: message.isIncoming,
            key: `${message.isIncoming ? 'inc' : 'out'}-${message.id}`,
            render: () => (
              <MessageOverview
                messageType={message.isIncoming ? 'incoming' : 'outgoing'}
                messageId={message.id}
                loading={loadingRelatedMessages}
                onLoad={setLoadingRelatedMessages}
                onMessageLoaded={(messageData) => {
                  if
                  (primaryMessage.id === messageData.id
                    && (primaryMessage.incoming === messageData.incoming)
                    && (messageData?.relatedMessages[0] !== null)) {
                    setRelatedMessages(messageData.relatedMessages);
                    setResponseRelatedMessages(
                      messageData.relatedMessages.filter(
                        (relatedMessage) => relatedMessage.type.id === typeMessage.FAResponse
                        || relatedMessage.type.id === typeMessage.VesselResponse
                        || relatedMessage.type.id === typeMessage.ISResponse,
                      ).length,
                    );
                    setQueryRelatedMessages(
                      messageData.relatedMessages.filter(
                        (relatedMessage) => relatedMessage.type.id === typeMessage.FAQuery
                        || relatedMessage.type.id === typeMessage.VesselQuery
                        || relatedMessage.type.id === typeMessage.ISQuery,
                      ).length,
                    );
                    setReportRelatedMessages(
                      messageData.relatedMessages.filter(
                        (relatedMessage) => relatedMessage.type.id === typeMessage.FAReport
                        || relatedMessage.type.id === typeMessage.VesselReport
                        || relatedMessage.type.id === typeMessage.ISReport,
                      ).length,
                    );
                  }
                  if (messageData.relatedMessages && messageData.relatedMessages[0] === null) {
                    setRelatedMessages([]);
                  }
                }}
              />
            ),
          })),
        );
  }, [primaryMessage, openMessages, typeSection, loadingRelatedMessages, domain]);

  const isSystemAdmin = useMemo(() => keycloak.token
  && keycloak.hasRealmRole(RealmRole.SYSTEM_ADMIN), [keycloak]);

  const filtersConfig = useMemo(() => !isEmpty(types)
  && configureFilters(types, typeSection, domainCode, isSystemAdmin),
  [types, typeSection, domainCode]);

  return (
    <Container fluid className="message-viewer-page">
      <Filters
        key={`${typeSection}-${domain}`}
        filters={filtersConfig || []}
        searchOptions={searchOptions}
        onUpdateSearchFilters={(newFilters) => {
          setFilters(newFilters);
          setPageNumber(0);
          setPrimaryMessage();
          setOpenMessages([]);
          setSections([]);
        }}
        isUIBlocked={loading}
      />
      <Row ref={refRowContainer}>
        <Col
          className="message-viewer-page-section"
          sx={12}
          lg={refRowWith >= 900 ? 6 : 12}
          xl={refRowWith >= 1100 ? 3 : 6}
        >

          <Row style={{ height: 'calc(100% - 1.563rem)' }}>
            <Col xs={12} style={{ height: '100%' }}>
              <div
                id="scrollableDiv"
                style={{
                  height: '100%',
                  overflow: 'auto',
                }}
                className={['data-box-list', !(messages || []).length && 'loading'].filter(Boolean).join(' ')}
              >
                <InfiniteScroll
                  dataLength={(messages || []).length}
                  scrollableTarget="scrollableDiv"
                  next={loadMoreData}
                  hasMore={!isLastPage}
                  loader={(
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        ...((messages || []).length ? {
                          borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                          background: 'rgba(0, 0, 0, 0.05)',
                          padding: '0.5rem',
                        } : {
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          zIndex: 1,
                        }),
                      }}
                    >
                      <div>
                        <div
                          style={{
                            height: '20px',
                            width: '20px',
                            overflow: 'hidden',
                          }}
                        >
                          <div
                            className="spinner-border"
                            role="status"
                            style={{
                              height: '20px',
                              width: '20px',
                            }}
                          >
                            <span className="sr-only">{t(tSchema.common.loading)}</span>
                          </div>
                        </div>
                      </div>
                      {((messages || []).length > 0) && <span className="ml-2">{t(tSchema.common.loading)}</span>}
                    </div>
                   )}
                >
                  <MessageList
                    messages={messages || []}
                    onMessageSelectCallback={(message) => {
                      if (
                        !primaryMessage
                                        || primaryMessage.id !== message.id
                                        || primaryMessage.isIncoming !== message.isIncoming
                      ) {
                        setPrimaryMessage(message);
                        setCurrentMessage(pick(['id', 'isIncoming'], message));
                        setOpenMessages([]);
                      }
                    }}
                    activeMessages={primaryMessage && [pick(['id', 'isIncoming'], primaryMessage)]}
                    loading={loading}
                    disableScroll={messagesLoading}
                    typeSection={typeSection}
                    isRelatedMessage={false}
                    domain={domain}
                  />
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
          <Row style={{ height: '1.563rem' }}>
            <Col xs={12} className="message-list-info">
              <div>
                {t(tSchema.common.results, { count: totalCount.current })}
              </div>
              <div hidden>
                Current:
                {' '}
                {(messages || []).length}
              </div>
            </Col>
          </Row>
        </Col>
        {(primaryMessage) && (
        <>
          <Col
            className="message-viewer-page-section"
            sx={12}
            lg={refRowWith >= 900 ? 6 : 12}
            xl={6}
          >
            <Section
              tabTitleGetter={(item, index) => (index === 0
                ? t(tSchema.dem.trafficMonitoring.overview)
                : item.identification)}
              isSameSection={(a, b) => a.id === b.id && a.isIncoming === b.isIncoming}
              closeableTabs
              sections={sections}
              defaultSection={find(
                (section) => section.id === currentMessage.id
                                        && section.isIncoming === currentMessage.isIncoming,
                sections,
              )}
              onCloseSectionCallback={(message) => {
                setOpenMessages(
                  reject(
                    (m) => m.id === message.id && m.isIncoming === message.isIncoming,
                    openMessages,
                  ),
                );
                currentMessage.id === message.id
                                    && currentMessage.isIncoming === message.isIncoming
                                    && setCurrentMessage(
                                      pick(['id', 'isIncoming'], nth(sections.length - 2, sections)),
                                    );
              }}
              onCurrentSectionChangeCallback={(section) => {
                setCurrentMessage(pick(['id', 'isIncoming'], section));
              }}
              loading={loading}
              preserveTabContent
            />
          </Col>
          <Col
            className="message-viewer-page-section"
            sx={12}
            lg={12}
            xl={refRowWith >= 1100 ? 3 : 12}
          >
            <div style={{ height: 'calc(100% - 1.563rem)' }} className="related-messages">
              <Section title={t(tSchema.dem.trafficMonitoring.relatedMessages)} preserveTabContent>
                <MessageList
                  messages={relatedMessages || []}
                  onMessageSelectCallback={(message) => {
                    find(
                      (m) => message.id === m.id && message.isIncoming === m.isIncoming,
                      openMessages,
                    ) || setOpenMessages([...openMessages, message]);
                    setCurrentMessage(pick(['id', 'isIncoming'], message));
                  }}
                  activeMessages={[currentMessage]}
                  loading={loadingRelatedMessages}
                  typeSection={typeSection}
                  isRelatedMessage
                  domain={domain}
                />
              </Section>
            </div>
            <div style={{ height: '1.563rem' }}>
              {t(tSchema.common.results, { count: relatedMessages?.length })}
              {' ('}
              {queryRelatedMessages}
              {' '}
              query,
              {' '}
              {responseRelatedMessages}
              {' '}
              responses,
              {' '}
              {reportRelatedMessages}
              {' '}
              reports)
            </div>
          </Col>
        </>
        )}
      </Row>

    </Container>
  );
};

BaseMessagePage.propTypes = {
  typeSection: PropTypes.string.isRequired,
  domain: PropTypes.number.isRequired,
};

export default BaseMessagePage;
