import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  Button,
  Col, Form, OverlayTrigger, Popover,
} from 'react-bootstrap';
import './columnsManagerStyle.scss';

const ColumnsManager = ({
  handleChangeCheckColumn, columnsCheck,
}) => {
  const popover = useMemo(() => (
    <Popover id="popover-columns-manager" rootClose="false" className="">
      <div className="columns-manager-popover-content">
        {columnsCheck.filter(({ hideOnManageColumns }) => !hideOnManageColumns).map(({
          code, text, disabled, hidden,
        }) => (
          <Form.Row className="ml-1" key={code}>
            <Form.Group as={Col} controlId={`checkbox-${code}-column`} className="justify-content-center p-0">
              <Form.Row controlId={`column-selector-${code}`} key={code}>
                <Form.Check
                  disabled={disabled}
                  type="checkbox"
                  name={text}
                  checked={!hidden}
                  label={text}
                  onChange={handleChangeCheckColumn(code)}
                />
              </Form.Row>
            </Form.Group>
          </Form.Row>
        ))}
      </div>
    </Popover>
  ), [columnsCheck]);

  return (
    <div className="columns-manager">
      <OverlayTrigger trigger="click" rootClose overlay={popover} placement="bottom">
        <Button size="sm">...</Button>
      </OverlayTrigger>
    </div>
  );
};

ColumnsManager.propTypes = {
  columnsCheck: PropTypes.arrayOf({}).isRequired,
  handleChangeCheckColumn: PropTypes.func.isRequired,
};

export default ColumnsManager;
