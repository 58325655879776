/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';

import { ReactComponent as Add } from './icons/add.svg';
import { ReactComponent as Assignment } from './icons/assignment.svg';
import { ReactComponent as CalendarSvg } from './icons/calendar.svg';
import { ReactComponent as CancelSvg } from './icons/cancel.svg';
import { ReactComponent as CaretDown } from './icons/caretDown.svg';
import { ReactComponent as CaretUp } from './icons/caretUp.svg';
import { ReactComponent as CheckThin } from './icons/check-thin.svg';
import { ReactComponent as CheckedSvg } from './icons/checked.svg';
import { ReactComponent as ChecklistSvg } from './icons/checklist.svg';
import { ReactComponent as ChevronDown } from './icons/chevronDown.svg';
import { ReactComponent as ClipboardCheckSolid } from './icons/clipboardCheckSolid.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as CogSolid } from './icons/cogSolid.svg';
import { ReactComponent as ComputerSvg } from './icons/computer.svg';
import { ReactComponent as DangerTriangle } from './icons/dangerTriangle.svg';
import { ReactComponent as DatabaseSvg } from './icons/database.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as Document } from './icons/document.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as EmailSvg } from './icons/email.svg';
import { ReactComponent as ErrorSvg } from './icons/error.svg';
import { ReactComponent as EyeSvg } from './icons/eye.svg';
import { ReactComponent as FishSvg } from './icons/fish.svg';
import { ReactComponent as Help } from './icons/help.svg';
import { ReactComponent as LeftArrow } from './icons/left-arrow.svg';
import { ReactComponent as Loupe } from './icons/loupe.svg';
import { ReactComponent as MenuSvg } from './icons/menu.svg';
import { ReactComponent as Paperclip } from './icons/paperclip-solid.svg';
import { ReactComponent as Person } from './icons/person.svg';
import { ReactComponent as RightArrow } from './icons/right-arrow.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as ServerSvg } from './icons/server.svg';
import { ReactComponent as TaskThinSvg } from './icons/task-thin.svg';
import { ReactComponent as TrafficLightSolid } from './icons/trafficLightSolid.svg';
import { ReactComponent as UploadFile } from './icons/upload-file.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as VesselSvg } from './icons/vessel.svg';
import { ReactComponent as WarnSvg } from './icons/warn.svg';
import { ReactComponent as WarningTriangle } from './icons/warningTriangle.svg';

export const ICON = {
  EMAIL: 'email',
  COMPUTER: 'computer',
  CLOSE: 'close',
  TASK_THIN: 'taskThin',
  MENU: 'menu',
  FISH: 'fish',
  CALENDAR: 'calendar',
  DATABASE: 'database',
  CHECKLIST: 'checklist',
  SERVER: 'server',
  WARN: 'warn',
  ERROR: 'error',
  CANCEL: 'cancel',
  CHECKED: 'checked',
  DANGER_TRIANGLE: 'danger-triangle',
  WARNING_TRIANGLE: 'warning-triangle',
  CARET_DOWN: 'caretDown',
  CARET_UP: 'caretUp',
  LOUPE: 'loupe',
  EDIT: 'edit',
  RIGHT_ARROW: 'right-arrow',
  LEFT_ARROW: 'left-arrow',
  ADD: 'add',
  COG_SOLID: 'cog-solid',
  CLIPBOARD_CHECK_SOLID: 'clipboard-check-solid',
  TRAFFIC_LIGHT_SOLID: 'traffic-light-solid',
  VESSEL: 'vessel',
  EYE: 'eye',
  CHECK_THIN: 'check-thin',
  DELETE: 'delete',
  UPLOAD_FILE: 'uploadFile',
  SEARCH: 'search',
  PERSON: 'person',
  ASSIGNMENT: 'assignment',
  DOCUMENT: 'document',
  CHEVRON_DOWN: 'chevronDown',
  CHEVRON_UP: 'chevronUp',
  CHEVRON_RIGHT: 'chevronRight',
  CHEVRON_LEFT: 'chevronLeft',
  HELP: 'help',
  DOWNLOAD: 'download',
  UPLOAD: 'upload',
  PAPERCLIP: 'paperclip',
};

const IconSvg = ({ name, className, viewBox }) => {
  const cn = `icon${className ? ` ${className}` : ''}`;
  switch (name) {
    case ICON.EMAIL:
      return <EmailSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.COMPUTER:
      return <ComputerSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CLOSE:
      return <Close className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.TASK_THIN:
      return <TaskThinSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.MENU:
      return <MenuSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.FISH:
      return <FishSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CALENDAR:
      return <CalendarSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.DATABASE:
      return <DatabaseSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CHECKLIST:
      return <ChecklistSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.SERVER:
      return <ServerSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.WARN:
      return <WarnSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.ERROR:
      return <ErrorSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CANCEL:
      return <CancelSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CHECKED:
      return <CheckedSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.DANGER_TRIANGLE:
      return <DangerTriangle className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.WARNING_TRIANGLE:
      return <WarningTriangle className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CARET_DOWN:
      return <CaretDown className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CARET_UP:
      return <CaretUp className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.LOUPE:
      return <Loupe className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.EDIT:
      return <Edit className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.RIGHT_ARROW:
      return <RightArrow className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.LEFT_ARROW:
      return <LeftArrow className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.ADD:
      return <Add className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.COG_SOLID:
      return <CogSolid className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CLIPBOARD_CHECK_SOLID:
      return <ClipboardCheckSolid className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.TRAFFIC_LIGHT_SOLID:
      return <TrafficLightSolid className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.VESSEL:
      return <VesselSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.EYE:
      return <EyeSvg className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CHECK_THIN:
      return <CheckThin className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.DELETE:
      return <Delete className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.UPLOAD_FILE:
      return <UploadFile className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.SEARCH:
      return <Search className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.PERSON:
      return <Person className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.ASSIGNMENT:
      return <Assignment className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.DOCUMENT:
      return <Document className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CHEVRON_DOWN:
      return <ChevronDown className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.CHEVRON_UP:
      return <ChevronDown className={[cn, 'rotate180'].filter(Boolean).join(' ')} {... viewBox ? { viewBox } : {}} />;
    case ICON.CHEVRON_RIGHT:
      return (
        <ChevronDown
          className={cn}
          style={{ transform: 'rotate(-90deg)' }}
          {... viewBox ? { viewBox } : {}}
        />
      );
    case ICON.CHEVRON_LEFT:
      return (
        <ChevronDown
          className={cn}
          style={{ transform: 'rotate(90deg)' }}
          {... viewBox ? { viewBox } : {}}
        />
      );
    case ICON.HELP:
      return <Help className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.DOWNLOAD:
      return <Download className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.UPLOAD:
      return <Upload className={cn} {... viewBox ? { viewBox } : {}} />;
    case ICON.PAPERCLIP:
      return (
        <Paperclip
          className={cn}
          style={{ transform: 'rotate(-45deg)' }}
          {... viewBox ? { viewBox } : {}}
        />
      );
    default:
      return <></>;
  }
};

IconSvg.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  viewBox: PropTypes.string,
};

IconSvg.defaultProps = {
  className: null,
  viewBox: null,
};

export default IconSvg;
