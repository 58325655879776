const PAGES = {
  VALIDATION: 'VALIDATION',
  BR: 'BR',
  MESSAGE_VIEWER: 'MESSAGE_VIEWER',
  SCENARIOS: 'SCENARIOS',
  TRAFFIC: 'TRAFFIC',
  DMA: 'DMA',
};

export default PAGES;
