import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState, useRef, useCallback } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import IsrViewerDetails from '@pages/IsrViewer/components/details/IsrViewerDetails';
import IsrViewerFilter from '@pages/IsrViewer/components/filter/IsrViewerFilter';
import IsrViewerTable from '@pages/IsrViewer/components/table/IsrViewerTable';

import services from '@services/messages/api';
import { BaseMessageProvider } from '@services/messages/BaseMessageContext';
import './style.scss';

const IsrViewer = () => {
  const [searchFilters, setSearchFilters] = useState(null);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(false);
  const [loading, setLoading] = useState(false);
  const pageRequest = useRef(1);
  const dataTableRef = useRef();
  const [forceUpdateFlag, setForceUpdateFlag] = useState({});

  const [tabs, setTabs] = useState([{
    isDetail: false,
    label: 'Results',
  }]);

  const [selectedTabResults, setSelectedTabResults] = useState(0);

  const handleCloseTab = useCallback((uuid) => {
    const newTabs = tabs.filter((tab) => tab.uuid !== uuid);
    setTabs(newTabs);
    setSelectedTabResults(0);
  }, [tabs]);

  const detailTabLabelMap = useCallback((tab) => (
    (
      <div className="detail-tab-label">
        <div className="detail-tab-label-title isr-viewer-tabs">
          <div className="detail-tab-label-id">{tab.label || tab.id}</div>
          {tab.isDetail
          && (
          <Button
            className="close-button"
            variant="link"
            style={{ fontSize: '20px' }}
            title={`close ${tab.id}`}
            onClick={(ev) => {
              ev.stopPropagation();
              handleCloseTab(tab.uuid);
            }}
            tabIndex={0}
          >
            &times;
          </Button>
          )}
        </div>

      </div>
    )
  ), [handleCloseTab]);

  const onViewReportDetailsHandler = useCallback((id, recipient, moduleSelected, uuid) => {
    let indexSelectedTab = 0;
    setTabs((prev) => {
      const prevTabIndex = prev.findIndex((tab) => tab.uuid === uuid);
      const isAlreadySelected = prevTabIndex >= 0;
      indexSelectedTab = isAlreadySelected ? prevTabIndex : prev && prev.length;
      setSelectedTabResults(indexSelectedTab);
      return [...prev, ...(!isAlreadySelected ? [{
        isDetail: true,
        id,
        label: null,
        recipient,
        uuid,
        module: moduleSelected,
      }] : [])];
    });
  }, []);

  const handleChange = useCallback((event, newValue) => {
    setSelectedTabResults(newValue);
  }, []);

  const setPageRequest = (page) => {
    pageRequest.current = page;
  };
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <BaseMessageProvider services={services('trafficMonitoring')}>
      <div id="isrViewer">

        <Container fluid>
          <Row>
            <Col xs={12}>
              <IsrViewerFilter
                setSearchFilters={setSearchFilters}
                searchFilters={searchFilters}
                loading={loading}
                pageRequest={pageRequest}
                setPageRequest={setPageRequest}
                dataTableRef={dataTableRef}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>

              <Container fluid className="trip-view-section-tabs p-0" style={{ height: '100%', marginBottom: '20px' }}>
                <Card>
                  {tabs.length > 1 && (
                    <Tabs
                      className="simple"
                      value={selectedTabResults}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {tabs.map((t) => (
                        <Tab key={`tab-${t.label}-${t.uuid || 'main'}`} label={detailTabLabelMap(t)} />
                      ))}
                    </Tabs>
                  )}

                  {tabs.map((t, index) => {
                    const isHidden = selectedTabResults !== index;
                    return (
                      <div hidden={isHidden} key={`content-${t.label}-${t.uuid || 'main'}`} style={{ height: '100%' }}>
                        {t.isDetail
                          ? (
                            <Col xs={12} style={{ padding: '20px' }}>
                              <IsrViewerDetails
                                key={`${t.uuid}-${t.recipient}`}
                                selectedRecipient={t.recipient}
                                selectedUuid={t.uuid}
                                module={t.module}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                isHiddenISRTab={isHidden}
                              />
                            </Col>
                          )
                          : (
                            <Col>
                              <IsrViewerTable
                                onViewReportDetailsHandler={onViewReportDetailsHandler}
                                setSelectedTabResults={setSelectedTabResults}
                                searchFilters={searchFilters}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                selectedRecipient={selectedRecipient}
                                setSelectedRecipient={setSelectedRecipient}
                                loading={loading}
                                setLoading={setLoading}
                                pageRequest={pageRequest}
                                setPageRequest={setPageRequest}
                                dataTableRef={dataTableRef}
                                setTab={setSelectedTab}
                                forceUpdateFlag={forceUpdateFlag}
                                setForceUpdateFlag={setForceUpdateFlag}
                              />
                            </Col>
                          )}
                      </div>
                    );
                  })}
                </Card>
              </Container>
            </Col>
          </Row>
        </Container>

      </div>
    </BaseMessageProvider>
  );
};

export default IsrViewer;
