/* eslint-disable import/order */

import * as propTypes from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useCallback,
} from 'react';
import {
  validateDrools,
  getVersionsFromVessel,
  fetchEntityVessel,
  fetchVesselById,
  getPreviousVersion,
} from '../../../../services/vessel_record/actions/vessel';
import {
  Button, Container, Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Permissions, hasPermission, hasRole, Roles,
} from '@common/keycloakFunctions';
import { formatHeaderSort } from '@common/utils';
import When from '@components/Conditionals/When';
import DataTable from '@components/DataTable';
import tSchema from '@lang/schema';
import VesselViewerModal from '@pages/VesselsViewer/components/VesselViewerModal';
import { filterEmptyComplexAttr } from '../../../../services/vessel_record/api';
import * as styles from './VesselHistoricModal.module.scss';

import getTableConfig from './config';

const VesselHistoricModal = ({
  vessel, show, onHide, visualizationForm, setVisualizationForm,
}) => {
  const { t } = useTranslation();

  const tableConfig = useMemo(() => getTableConfig(t), [t]);

  const {
    content, totalPages, totalElements,
  } = useSelector(
    (state) => state.vessel.vesselHistoric,
  );
  const loading = useSelector((state) => state.vessel.loadingHistoric);
  const permissions = useSelector((state) => state.authentication.permissions);
  const roles = useSelector((state) => state.authentication.roles);
  const dataTableRef = useRef();
  const pageRequest = useRef(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [orderBy, setOrderBy] = React.useState({ order: 'desc', field: 'updateDate' });
  const [forceUpdateFlag, setForceUpdateFlag] = React.useState({});
  const [showVesselViewerModal, setShowVesselViewerModal] = React.useState(false);
  const { etalon_id: id, cfr, uvi } = vessel;
  const dispatch = useDispatch();

  const triggerDataFetch = () => setForceUpdateFlag({});

  const prepareForm = (idVessel, dateFrom, callback) => {
    const dateFromFormatted = dateFrom?.replace(/Z/g, '');
    dispatch(fetchEntityVessel());
    dispatch(fetchVesselById(idVessel, dateFromFormatted, callback));
  };

  const resetPagination = () => {
    dataTableRef.current && dataTableRef.current.resetPagination(1, false);
    pageRequest.current = 1;
  };

  const prepareHistoric = useCallback(() => {
    const options = {
      page: pageRequest.current - 1,
      size: pageSize,
      ...(orderBy.field ? { sort: `${orderBy.field},${orderBy.order}` } : {}),
    };
    dispatch(getVersionsFromVessel(id, options));
  }, [pageSize, pageRequest.current, id, orderBy]);

  useEffect(() => {
    prepareHistoric();
  }, [forceUpdateFlag, showVesselViewerModal, show]);

  const isApprover = useMemo(() => hasRole(roles, Roles.VR_USER_APPROVER), [roles]);
  const hasWritePermission = useMemo(
    () => hasPermission(permissions, Permissions.WRITE_DATA), [permissions],
  );
  const hasReadPermission = useMemo(
    () => hasPermission(permissions, Permissions.READ_DATA), [permissions],
  );
  const editionDisabled = useCallback(
    (row) => !(
      hasWritePermission
      && (isApprover || row.lastVersion)
    ),
    [isApprover, hasWritePermission],
  );

  const editRow = useCallback((row) => {
    prepareForm(row.$etalon_id, row.$from,
      (vesselData) => dispatch(validateDrools(filterEmptyComplexAttr(vesselData))));

    setVisualizationForm('EDIT');
    setShowVesselViewerModal(true);
  }, [prepareForm, permissions]);
  const viewRow = useCallback(
    (row) => {
      prepareForm(row.$etalon_id, row.$from,
        (vesselData) => dispatch(validateDrools(filterEmptyComplexAttr(vesselData))));
      setVisualizationForm('VIEW');
      setShowVesselViewerModal(true);
      hasPermission(
        permissions,
        Permissions.APPROVE_MODIFICATIONS,
      ) && dispatch(getPreviousVersion(row.$etalon_id));
    }, [prepareForm, permissions],
  );

  const actions = useMemo(() => [
    {
      label: t(tSchema.common.edit),
      action: (row) => {
        editRow(row);
      },
      conditionallyDisabled: editionDisabled,
    },
    {
      label: t(tSchema.common.view),
      action: (row) => {
        viewRow(row);
      },
      disabled: !hasReadPermission,
    },
  ], [
    editRow,
    viewRow,
    permissions,
  ]);

  const pageSizeChangeHandler = useCallback((size) => {
    pageRequest.current = 1;
    setPageSize(Number.parseInt(size, 10));
    triggerDataFetch();
  }, []);

  const pageChangeHandler = useCallback((page) => {
    pageRequest.current = page;
    triggerDataFetch();
  }, []);

  const tableHeaderClickHandler = useCallback((header) => {
    if (header.code && !loading) {
      pageRequest.current = 1;
      setOrderBy(formatHeaderSort(header, orderBy));
      resetPagination();
      triggerDataFetch();
    }
  }, [loading, pageRequest, orderBy, resetPagination]);

  const handleRowSelected = useCallback(
    (row, event) => event.detail === 2 && viewRow(row), // Double Click
    [viewRow],
  );

  return (
    <>
      <Modal
        backdrop="static"
        show={show}
        onHide={onHide}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>
              <When condition={cfr}>
                <span className="mr-1" style={{ color: '#003882', fontWeight: 'bold' }}>
                  CFR:
                </span>
                {'  '}
                {cfr}
              </When>
              <When condition={uvi}>
                <span className="ml-3 mr-1" style={{ color: '#003882', fontWeight: 'bold' }}>
                  UVI:
                </span>
                {' '}
                {uvi}
              </When>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className={styles.historicModalContainer}>
            <DataTable
              rowData={content || []}
              idColumn="validityDate"
              tableId="vessel-historic-table"
              ref={dataTableRef}
              tableConfig={tableConfig}
              loading={loading}
              onPageSizeChangeHandler={pageSizeChangeHandler}
              onPageChangeHandler={pageChangeHandler}
              onTableHeaderClick={tableHeaderClickHandler}
              orderBy={orderBy}
              maxPages={totalPages}
              totalResults={totalElements}
              onRowSelected={handleRowSelected}
              showTotalResults
              pageRequest={pageRequest.current}
              actions={actions}
              tableActions={(
                <Button
                  id="new-scenario"
                  onClick={onHide}
                >
                  {t(tSchema.common.close)}
                </Button>
            )}
            />
          </Container>
        </Modal.Body>
      </Modal>
      <VesselViewerModal
        showModal={showVesselViewerModal}
        visualizationForm={visualizationForm}
        onClose={() => setShowVesselViewerModal(false)}
      />
    </>
  );
};

VesselHistoricModal.propTypes = {
  vessel: propTypes.shape({
    etalon_id: propTypes.string.isRequired,
    cfr: propTypes.string.isRequired,
    uvi: propTypes.string.isRequired,
  }).isRequired,
  show: propTypes.bool.isRequired,
  onHide: propTypes.func.isRequired,
  visualizationForm: propTypes.bool.isRequired,
  setVisualizationForm: propTypes.func.isRequired,
};

export default VesselHistoricModal;
