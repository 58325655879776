import {
  Button,
} from 'react-bootstrap';
import './style.scss';

const ActionsButton = ({ ...props }) => (
  <Button {...props}>
    <div className="show-actions d-flex align-items-center justify-content-center">
      <svg height="20" width="13" style={{ fill: 'currentColor' }}>
        <circle cx="1.5" cy="10" r="1.5" />
        <circle cx="6.5" cy="10" r="1.5" />
        <circle cx="11.5" cy="10" r="1.5" />
      </svg>
    </div>
  </Button>
);

export default ActionsButton;
