import Crud from './crud';

const initialState = {
  dataActivity: [],
  loadingDataActivity: false,
  errorDataActivity: false,
  pagination: {
    page: 1,
    size: 10,
    totalCount: 0,
    maxPages: 1,
    filtersBody: {},
  },
  sourcesList: [],
  attributeFilters: [],
};

const Managers = {
  ...Crud,
};

export default (state = initialState, action = { type: '' }) => {
  const finded = Managers[action.type];
  return finded ? finded(state, action) : { ...state };
};
