import actions from './img/actions.JPG';
import viewModal from './img/view_modal.JPG';

const SectionVesselGroupView = () => (
  <div className="SectionVesselGroupView">
    <p>
      To visualize the detail of a Vessel Group,
      click on the &quot;Actions&quot; button of the corresponding
      row and then select the &quot;View&quot; action.
    </p>
    <p>
      <img src={actions} alt="Actions" />
    </p>
    <p>
      A modal window shows the vessel
      group information without the possibility for the user to edit that information.
    </p>
    <p>
      <img src={viewModal} alt="View Modal" />
    </p>
  </div>
);

export default SectionVesselGroupView;
